import CustomAddUserIcon from '@/components/Common/CustomIcons/CustomAddUserIcon';
import CustomDisableIcon from '@/components/Common/CustomIcons/CustomDisableIcon';
import CustomUpdateIcon from '@/components/Common/CustomIcons/CustomUpdateIcon';
import CustomViewIcon from '@/components/Common/CustomIcons/CustomViewIcon';
import GenericTable from '@/components/Common/CustomizeTable';
import ActionDropdown from '@/components/Common/Dropdownactions';
import { DEFAULT_DATE_FORMAT } from '@/constants';
import { checkAccess } from '@/routes/acl';
import { IHRHoliday, IHRHolidayList } from '@/services/hr/types';
import { convertUTCStringtoLocalString } from '@/utils/convertToUTC';
import { FormInstance, Menu, message, Modal, TableProps, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { SorterResult } from 'antd/lib/table/interface';
import { useState } from 'react';
import { Link } from 'react-router-dom';

interface Props {
  isLoading?: boolean;
  data: IHRHolidayList;
  form: FormInstance;
  pagination: { page: number; size: number };
  onPagination: (page?: number, size?: number, isSize?: boolean) => Promise<unknown>;
  handleDisabled: (holiday: IHRHoliday) => Promise<void>;
}

function Table({ isLoading, data, pagination, form, onPagination, handleDisabled }: Props) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [sortedInfo, setSortedInfo] = useState<SorterResult<IHRHoliday>>({});
  const [selectedHoliday, setSelectedHoliday] = useState<IHRHoliday>();

  function handleArchiveClick(holiday: IHRHoliday) {
    setIsModalVisible(true);
    setSelectedHoliday(holiday);
  }

  async function handleOk() {
    if (!selectedHoliday) {
      return message.error('Please select the holiday');
    }

    setIsModalVisible(false);
    await handleDisabled(selectedHoliday);
    setSelectedHoliday(undefined);
  }

  const handleChange: TableProps<IHRHoliday>['onChange'] = (_, __, s) => {
    setSortedInfo(s as SorterResult<IHRHoliday>);
  };

  const columns: ColumnsType<IHRHoliday> = [
    {
      title: 'S.N.',
      key: 'id',
      width: 5,
      render: (_, __, index) => (pagination.page - 1) * pagination.size + index + 1
    },
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
      width: 15,
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null,
      render: (name: string, record) => <Link to={`/hr/holiday/${record.id}`}>{name}</Link>
    },
    {
      title: 'Start',
      key: 'startDate',
      width: 15,
      dataIndex: 'startDate',
      sorter: (a, b) => a.startDate.localeCompare(b.startDate),
      sortOrder: sortedInfo.columnKey === 'date' ? sortedInfo.order : null,
      render: (startDate: string) => {
        return convertUTCStringtoLocalString(startDate, DEFAULT_DATE_FORMAT);
      }
    },
    {
      title: 'End',
      key: 'endDate',
      width: 15,
      dataIndex: 'endDate',
      sorter: (a, b) => a.endDate.localeCompare(b.endDate),
      sortOrder: sortedInfo.columnKey === 'date' ? sortedInfo.order : null,
      render: (endDate: string) => {
        return convertUTCStringtoLocalString(endDate, DEFAULT_DATE_FORMAT);
      }
    },
    {
      title: 'Description',
      key: 'description',
      width: 30,
      dataIndex: 'description'
    },
    {
      title: 'Last Updated',
      key: 'lastUpdated',
      width: 15,
      dataIndex: 'updatedAt',
      sorter: (a, b) => a.updatedAt.localeCompare(b.updatedAt),
      sortOrder: sortedInfo.columnKey === 'lastUpdated' ? sortedInfo.order : null,
      render: (_, record) => convertUTCStringtoLocalString(record.updatedAt, DEFAULT_DATE_FORMAT)
    },
    {
      title: 'Status',
      key: 'isDisabled',
      width: 10,
      dataIndex: 'isDisabled',
      sorter: (a, b) => Number(a.isDisabled) - Number(b.isDisabled),
      sortOrder: sortedInfo.columnKey === 'isDisabled' ? sortedInfo.order : null,
      render: (isDisabled) => (
        <Tag color={isDisabled ? 'red' : 'green'}>{isDisabled ? 'Disabled' : 'Active'}</Tag>
      )
    },
    {
      title: 'Actions',
      key: 'actions',
      width: 6,
      fixed: 'right',
      render: (record: IHRHoliday) => {
        const menuItems: (
          | { key: string; label: JSX.Element; onClick?: undefined }
          | { key: string; label: JSX.Element; onClick: () => void }
        )[] = [
          {
            key: 'view',
            label: <CustomViewIcon link={`/hr/holiday/${record.id}`} />
          }
        ];

        if (checkAccess('UPDATE_HR_GROUPS')) {
          menuItems.push({
            key: 'assign',
            label: (
              <CustomAddUserIcon
                link={`/hr/holiday/assign/${record.id}`}
                label="Assign to Groups"
              />
            )
          });
        }

        if (checkAccess('UPDATE_HOLIDAY')) {
          menuItems.push(
            ...[
              {
                key: 'update',
                label: <CustomUpdateIcon link={`/hr/holiday/details/${record.id}`} />
              },
              {
                key: 'disabled',
                label: (
                  <CustomDisableIcon
                    currentState={record.isDisabled}
                    onClick={() => handleArchiveClick(record)}
                  />
                )
              }
            ]
          );
        }

        const menu = <Menu items={menuItems} />;
        return <ActionDropdown menu={menu} />;
      }
    }
  ];

  return (
    <div>
      <Modal visible={isModalVisible} onOk={handleOk} onCancel={() => setIsModalVisible(false)}>
        <p>
          Are you sure you want to {selectedHoliday?.isDisabled ? 'enable' : 'disable'} this
          holiday?
        </p>
      </Modal>
      <GenericTable
        form={form}
        columns={columns}
        data={data.results}
        isLoading={isLoading}
        scroll={{ x: 1300 }}
        hideDefaultPagination
        tableName={'hr-holiday-list'}
        pagination={{ ...pagination, total: data.total, onPagination, scrollToTop: true }}
        toSort={handleChange}
      />
    </div>
  );
}

export default Table;
