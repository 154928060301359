import moment from 'moment';

import { convert_string_to_nepali_date_object } from '@/utils/nepaliDateConverter';
import { get_balance_sheet_list, get_child_from_parent_list } from '@/services/accounts/queries';

import {
  IAccountTypeResponseData,
  IBalanceSheetWithTransaction,
  IPnLResponseData,
  ITrialBalanceResponseData
} from '@/services/accounts/types';

import {
  addLocationIfAbsent,
  calculateStartAndEndBalance,
  flattenData,
  flattenDataExportAll,
  getExportAllData,
  getSumAndCalculateTotal
} from '@/services/accounts/services';

interface CommonProps {
  locationId: number | string;
  parentIds?: number[];
  date: { startDate: string; endDate: string };
}

interface Props extends CommonProps {
  currentBalanceSortBy: string;
  skip?: number;
  count?: number;
}

interface IBalanceSheetData extends Props {
  data: IPnLResponseData[];
  currentFinancialStart: string;
}

interface IFlattenData extends CommonProps {
  valueExport: number;
  currentData: IBalanceSheetWithTransaction[];
}

export const addTransactionToBalanceSheet = async (options: Props) => {
  const {
    locationId,
    date: { startDate, endDate },
    currentBalanceSortBy,
    parentIds,
    skip,
    count
  } = options;

  // Get balance sheet list
  const response = await get_balance_sheet_list(
    locationId,
    startDate,
    endDate,
    currentBalanceSortBy,
    parentIds,
    skip,
    count
  );

  const results = await getSumAndCalculateTotal<IPnLResponseData>(
    startDate,
    endDate,
    response.results
  );

  return { results, count: response.total };
};

export const addTransactionToBalanceChildren = async (options: Props) => {
  const {
    locationId,
    date: { startDate, endDate },
    currentBalanceSortBy,
    parentIds,
    skip,
    count
  } = options;

  // Get profit loss list
  const response = await get_child_from_parent_list(
    locationId,
    startDate,
    endDate,
    currentBalanceSortBy,
    parentIds,
    skip,
    count
  );

  const results = await getSumAndCalculateTotal<ITrialBalanceResponseData>(
    startDate,
    endDate,
    response.results
  );

  return { results, count: response.total };
};

export async function getBalanceSheetData<T>({ locationId, data, ...rest }: IBalanceSheetData) {
  if (locationId === '') {
    return await addLocationIfAbsent<T>({
      data: data as any,
      allowChildren: true
    });
  }

  // Get child from parent list
  const { results: responseChildResults } = await addTransactionToBalanceChildren({
    locationId,
    date: rest.date,
    currentBalanceSortBy: rest.currentBalanceSortBy,
    parentIds: rest.parentIds,
    skip: rest.skip,
    count: rest.count
  });

  const results = await calculateStartAndEndBalance<typeof responseChildResults>(
    rest.date.startDate,
    rest.parentIds as number[],
    responseChildResults,
    rest.currentFinancialStart
  );

  const responseData = results.map((item) => {
    return { ...item, children: [] };
  });

  return responseData as unknown as T;
}

export async function getFlattenData(options: IFlattenData) {
  const { date, valueExport, locationId, parentIds } = options;

  if (valueExport === 1) {
    if (locationId !== undefined) {
      const exportAllData = await getExportAllData(
        locationId,
        parentIds as number[],
        date.startDate,
        date.endDate
      );

      return flattenDataExportAll<IAccountTypeResponseData>(exportAllData).map((item) => {
        return {
          ...item,
          transaction: {
            ...item.transaction,
            totalCredit: item.transaction ? item.transaction.totalCredit || 0 : 0,
            totalDebit: item.transaction ? item.transaction.totalDebit || 0 : 0
          },
          start: {
            debit: item.startBalance && item.startBalance > 0 ? item.startBalance : 0,
            credit: item.startBalance && item.startBalance < 0 ? Math.abs(item.startBalance) : 0
          },
          end: {
            debit: item.endBalance && item.endBalance > 0 ? item.endBalance : 0,
            credit: item.endBalance && item.endBalance < 0 ? Math.abs(item.endBalance) : 0
          },
          date: moment(item.createdAt).format('YYYY-MM-DD'),
          miti: convert_string_to_nepali_date_object(item.createdAt).format('MM/DD/YYYY')
        };
      });
    }
  } else {
    return flattenData<IBalanceSheetWithTransaction>(options.currentData)
      .filter((val) => !val.hasMore)
      .map((item) => {
        return {
          ...item,
          transaction: {
            ...item.transaction,
            totalCredit: item.transaction ? item.transaction.totalCredit || 0 : 0,
            totalDebit: item.transaction ? item.transaction.totalDebit || 0 : 0
          },
          start: {
            debit: item.startBalance && item.startBalance > 0 ? item.startBalance : 0,
            credit: item.startBalance && item.startBalance < 0 ? Math.abs(item.startBalance) : 0
          },
          end: {
            debit: item.endBalance && item.endBalance > 0 ? item.endBalance : 0,
            credit: item.endBalance && item.endBalance < 0 ? Math.abs(item.endBalance) : 0
          },
          date: moment(item.createdAt).format('MM/DD/YYYY'),
          miti: convert_string_to_nepali_date_object(item.createdAt).format('MM/DD/YYYY')
        };
      });
  }
}
