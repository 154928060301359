import { ACLTypes } from '../../routes/acl';

export enum AuthErrorEnum {
  ACCESS_TOKEN = 'Invalid Access Token!',
  REFRESH_TOKEN_EXPIRED = 'Refresh Token Expired!',
  REFRESH_TOKEN = 'Invalid Refresh Token!',
  HEALTH_TOKEN = 'Invalid Health Token!',
  CREDENTIALS = 'Invalid Credentials!'
}

export interface ILoginRequest {
  email: string;
  password: string;
  remember?: boolean;
}
// Generated by https://quicktype.io

export interface ILoginResponse {
  permissions: ACLTypes[];
  token: IToken;
  user: IUser;
}

export interface IToken {
  userId: number;
  accessToken: string;
  refreshToken: string;
  refreshExpiresAt: string;
  expiresAt: string;
  id: number;
  createdAt: string;
  updatedAt: string;
}

export interface IUser {
  id: number;
  name: string;
  typeId: number;
  email: string;
  phone: string;
  secondaryPhone: string | null;
  verified: boolean;
  disabled: boolean;
  avatarId: number;
  isStaff: boolean;
  createdAt: string;
  updatedAt: string;
}

export interface ISettings {
  content: string;
  id: number;
  timezone: string;
}

export interface IPreferences {
  createdAt: string;
  id: number;
  preferences: string;
  updatedAt: string;
  userId: number;
}

export interface IAuthResponse {
  permissions: string[];
  preferences: IPreferences;
  settings: ISettings;
  token: IToken;
  user: IUser;
}
