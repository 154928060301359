import {
  Button,
  Checkbox,
  Divider,
  Form,
  Radio,
  RadioChangeEvent,
  Space,
  Spin,
  Tooltip,
  message
} from 'antd';
import { useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  IAccountTypeResponseData,
  ITrialBalanceResponseWithTransaction,
  ITrialBalanceTableData,
  ITrialBalanceTableDataForIndexing
} from '@/services/accounts/types';
import Table, { ColumnsType } from 'antd/lib/table';
import TableCell from '@/components/Common/CustomizeTable/CustomCell';
import { EllipsisOutlined, EnterOutlined } from '@ant-design/icons';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';
import AppContent from '@/components/Common/Content/Content';
import CustomButton from '@/components/Common/CustomButton/CustomButton';

import {
  get_account_latest_history,
  get_account_list_by_location
} from '@/services/accounts/queries';
import { randomRGBDarkVer } from '@/utils/randomColors.utils';
import moment from 'moment';
import { CustomModal } from '@/components/Common/CustomModal';
import { AccountSwitchParent } from '@/components/Common/CustomModal/AccountSwitchParent';
import { AccountType } from '@/services/accounts/enums';
import LocationsDB from '@/store/localstorage/LocationsDB';
import { get_location_list_for_ids } from '@/services/locations/queries';
import {
  addTransactionForExportAll,
  addTransactionToTrialBalance
} from '../trial-transaction.service';
import { convert_string_to_nepali_date_object } from '@/utils/nepaliDateConverter';
import * as ExcelJS from 'exceljs';
import { ReusableDrawer } from '@/components/Common/Drawer/ReusableDrawer';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import AccountFilterTable from '@/components/AccountFilterTable/v2';
import { calculateStartAndEndBalance } from '@/services/accounts/services';
import LocationSearchV2 from '@/components/Common/CustomSearch/Location';
import { useFinancialYearStore } from '@/store/zustand/financial-year';
import { useFilterStore } from '@/store/zustand';
import useWindowScrollRestoration from '@/hooks/useWindowScrollRestoration';

interface ITotal {
  debitStart: number;
  creditStart: number;
  debitEnd: number;
  creditEnd: number;
  creditTransaction: number;
  debitTransaction: number;
}

interface ISavedData {
  currParentIds: number[];
  tableData: ITrialBalanceTableData[];
  total: ITotal;
  locationId?: number;
  shownColumns: CheckboxValueType[];
  expandedRows: number[];
}

const TrialBalanceList = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [openExportDrawer, setOpenExportDrawer] = useState(false);
  const [currParentIds, setCurrParentIds] = useState<number[]>([]);
  const [valueExport, setValueExport] = useState(1);
  const [tableData, setTableData] = useState<ITrialBalanceTableData[]>([]);
  const [expandedRows, setExpandedRows] = useState<number[]>([]);
  const [total, setTotal] = useState<ITotal>({
    debitStart: 0,
    creditStart: 0,
    debitEnd: 0,
    creditEnd: 0,
    creditTransaction: 0,
    debitTransaction: 0
  });
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const [locationId, setLocationId] = useState<number | string>();
  const [shownColumns, setShownColumns] = useState<CheckboxValueType[]>(['C']);
  const [selectedRows, setselectedRows] = useState<ITrialBalanceTableData[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [openModalForSwitchParent, setOpenModalForSwitchParent] = useState(false);
  const colorPalette = ['B0B0B0', 'D3D3D3', 'FFFFFF'];

  const tableRef = useRef<HTMLDivElement>(null);

  useWindowScrollRestoration({
    totalLength: tableData.length,
    tableRef
  });

  const zustandFilter = useFilterStore();
  const zustandFinancialYear = useFinancialYearStore();
  const currentFinancialStart = zustandFinancialYear.currentFinancialYear?.startDate as string;

  const breadcrumbItems = [
    {
      label: 'Trial Balance',
      link: '/trial-balance'
    }
  ];

  const columns: ColumnsType<ITrialBalanceTableData> = [
    {
      title: 'Name',
      key: 'name',
      width: 40,
      render: (a, record) => {
        return (
          <>
            <TableCell>
              {record.hasMore ? (
                <Tooltip title="Load More . . .">
                  <Button
                    type="primary"
                    style={{ borderRadius: '9999px', padding: '0px 8px', height: '20px' }}
                    onClick={async (event) => {
                      event.stopPropagation();
                      await searchMore(record);
                    }}>
                    <EllipsisOutlined style={{ fontSize: '20px' }} />
                  </Button>
                </Tooltip>
              ) : record.parentId ? (
                <div>
                  <EnterOutlined
                    className="origin-center -scale-x-100 text-lg"
                    style={{ color: record.iconColor }}
                  />{' '}
                  <Link
                    to={`/accounts/detailed-ledger?accountId=${
                      record.id
                    }&startDate=${form.getFieldValue(['startDate'])}&endDate=${form.getFieldValue([
                      'endDate'
                    ])}`}
                    onClick={(event) => event.stopPropagation()}
                    target="_blank">
                    {record.locationName
                      ? `${record.name} ${record.locationName}`
                      : `${record.name}`}
                  </Link>
                </div>
              ) : (
                <Link
                  to={`/accounts/detailed-ledger?accountId=${
                    record.id
                  }&startDate=${form.getFieldValue(['startDate'])}&endDate=${form.getFieldValue([
                    'endDate'
                  ])}`}
                  onClick={(event) => event.stopPropagation()}
                  target="_blank">
                  {record.locationName ? `${record.name} ${record.locationName}` : `${record.name}`}
                </Link>
              )}
            </TableCell>
          </>
        );
      }
    },
    {
      title: 'Type',
      key: 'type',
      width: 15,
      render: (a, record) => {
        return (
          <>
            <TableCell>{record.type}</TableCell>
          </>
        );
      }
    },
    {
      title: 'Debit (Start)',
      key: 'debitStart',
      width: 15,
      render: (a, record) => {
        return (
          <>
            <TableCell>
              {record.hasMore
                ? ''
                : record.startBalance !== null
                ? nepaliNumberFormatter(record.startBalance >= 0 ? record.startBalance : 0)
                : 'N/A'}
            </TableCell>
          </>
        );
      }
    },
    {
      title: 'Credit (Start)',
      key: 'creditStart',
      width: 15,
      render: (a, record) => {
        return (
          <>
            <TableCell>
              {record.hasMore
                ? ''
                : record.startBalance !== null
                ? nepaliNumberFormatter(
                    record.startBalance <= 0 ? Math.abs(record.startBalance) : 0
                  )
                : 'N/A'}
            </TableCell>
          </>
        );
      }
    },
    {
      title: 'Debit (Transaction)',
      key: 'transactionDebitStart',
      width: 15,
      render: (a, record) => {
        return (
          <>
            <TableCell>
              {record.hasMore
                ? ''
                : record.transaction
                ? nepaliNumberFormatter(record.transaction.totalDebit)
                : 'N/A'}
            </TableCell>
          </>
        );
      }
    },
    {
      title: 'Credit (Transaction)',
      key: 'transactionCreditStart',
      width: 15,
      render: (a, record) => {
        return (
          <>
            <TableCell>
              {record.hasMore
                ? ''
                : record.transaction
                ? nepaliNumberFormatter(record.transaction.totalCredit)
                : 'N/A'}
            </TableCell>
          </>
        );
      }
    },
    {
      title: 'Debit (End)',
      key: 'debitEnd',
      width: 15,
      render: (a, record) => {
        return (
          <>
            <TableCell>
              {record.hasMore
                ? ''
                : record.endBalance !== null
                ? nepaliNumberFormatter(record.endBalance >= 0 ? record.endBalance : 0)
                : 'N/A'}
            </TableCell>
          </>
        );
      }
    },
    {
      title: 'Credit (End)',
      key: 'creditEnd',
      width: 15,
      render: (a, record) => {
        return (
          <>
            <TableCell>
              {record.hasMore
                ? ''
                : record.endBalance !== null
                ? nepaliNumberFormatter(record.endBalance <= 0 ? Math.abs(record.endBalance) : 0)
                : 'N/A'}
            </TableCell>
          </>
        );
      }
    }
  ];
  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys: React.Key[], selectedRows: ITrialBalanceTableData[]) => {
      // console.log('selectedRows', selectedRows);
      if (selectedRows.length <= 10) {
        if (selectedRows.length === 0) {
          setselectedRows(selectedRows);
          setSelectedRowKeys(selectedRowKeys);
        } else {
          if (areAllEqual(selectedRows)) {
            setselectedRows(selectedRows);
            setSelectedRowKeys(selectedRowKeys);
          } else {
            message.error('Selected values cannot have different parents!');
          }
        }
      } else {
        message.error('Only 10 data can be approved at a time!');
        setselectedRows(selectedRows.slice(0, 10));
        setSelectedRowKeys(selectedRowKeys.slice(0, 10));
      }
    },
    getCheckboxProps: (record: ITrialBalanceTableData) => {
      if (
        !record.parentId &&
        record.type !== AccountType.TRANSFER_INITIATE &&
        record.type !== AccountType.USER_PARENT &&
        record.type !== AccountType.TRANSFER_RECEIVE &&
        record.type !== AccountType.VENDOR_PARENT &&
        record.type !== AccountType.CUSTOMER_PARENT
      ) {
        return {
          disabled: true
        };
      } else {
        return {
          disabled: false
        };
      }
    }
  };

  const areAllEqual = (data: ITrialBalanceTableData[]) => {
    const refValue = data[0].parentId;

    for (const val of data) {
      if (val.parentId !== refValue) return false;
    }

    return true;
  };

  const handleSwitchParent = () => {
    setOpenModalForSwitchParent(true);
  };

  const handleSwitchParentModalClose = async () => {
    setOpenModalForSwitchParent(false);
    setselectedRows([]);
    setSelectedRowKeys([]);
    await onSubmitFilter();
  };

  const getTrialBalanceList = async (
    locationId: number | string,
    date?: { start?: string; end?: string }
  ) => {
    setIsLoading(true);
    const savedData = {} as ISavedData;
    let totalDebitStart = 0;
    let totalCreditStart = 0;
    let totalDebitEnd = 0;
    let totalCreditEnd = 0;
    let totalDebitTransaction = 0;
    let totalCreditTransaction = 0;
    const startDate = date?.start || form.getFieldValue(['startDate']);
    const endDate = date?.end || form.getFieldValue(['endDate']);
    let hasMore = false;
    const response = await addTransactionToTrialBalance(
      locationId,
      startDate,
      endDate,
      'DESC',
      undefined,
      0,
      100
    );
    const parentIds: number[] = response.results.map((data) => {
      return data.id;
    });

    setCurrParentIds(parentIds);
    savedData.currParentIds = parentIds;

    response.results = await calculateStartAndEndBalance(
      startDate,
      parentIds,
      response.results,
      currentFinancialStart
    );

    let TrialBalanceData;
    if (locationId == '') {
      // for all Location
      TrialBalanceData = await Promise.all(
        response.results.map(async (data) => {
          let locationName = '';
          if (data.locationId) {
            let location = await LocationsDB.getLocation(data.locationId);
            if (!location) {
              const locationIds = new Set<number>();
              response.results.forEach((value) => {
                if (value.locationId) locationIds.add(value.locationId);
              });
              const allLocations = await get_location_list_for_ids([...locationIds]);
              await LocationsDB.addLocations(allLocations);
              location = await LocationsDB.getLocation(data.locationId);
            }
            locationName = `(${location.code})`;
          }
          return { ...data, locationName: locationName, children: [] };
        })
      );
    } else {
      // for single location
      const responseChildTrial = await addTransactionToTrialBalance(
        locationId,
        startDate,
        endDate,
        'DESC',
        parentIds,
        0,
        100
      );

      responseChildTrial.results = await calculateStartAndEndBalance(
        startDate,
        parentIds,
        responseChildTrial.results,
        currentFinancialStart
      );

      if (responseChildTrial.results.length >= 100) hasMore = true;
      TrialBalanceData = responseChildTrial.results.map((data) => {
        return { ...data, children: [] };
      });
    }

    // total
    TrialBalanceData.forEach((value) => {
      if (value.transaction) {
        totalDebitTransaction += value.transaction.totalDebit;
        totalCreditTransaction += value.transaction.totalCredit;
      }
      if (value.startBalance) {
        if (value.startBalance > 0) totalDebitStart += value.startBalance;
        if (value.startBalance < 0) totalCreditStart += Math.abs(value.startBalance);
      }
      if (value.endBalance) {
        if (value.endBalance > 0) totalDebitEnd += value.endBalance;
        if (value.endBalance < 0) totalCreditEnd += Math.abs(value.endBalance);
      }
    });

    // console.log('TrialBalanceData', TrialBalanceData);
    if (!hasMore) {
      setTableData(TrialBalanceData);
      savedData.tableData = TrialBalanceData;
    } else {
      const obj = {} as ITrialBalanceTableData;
      const newData = {
        ...obj,
        parentIds: parentIds,
        skip: TrialBalanceData.length,
        hasMore: true
      };
      setTableData([...TrialBalanceData, newData]);
      savedData.tableData = [...TrialBalanceData, newData];
    }

    const total = {
      debitStart: totalDebitStart,
      creditStart: totalCreditStart,
      debitEnd: totalDebitEnd,
      creditEnd: totalCreditEnd,
      debitTransaction: totalDebitTransaction,
      creditTransaction: totalCreditTransaction
    };

    setTotal(total);
    savedData.total = total;
    setIsLoading(false);
    return savedData;
  };

  const getChildTrialBalanceList = async (
    parentData: ITrialBalanceTableData,
    locationId: number | string
  ) => {
    const startDate = form.getFieldValue(['startDate']);
    const endDate = form.getFieldValue(['endDate']);
    let hasMore = false;
    if (parentData) {
      const parentMapIds: Map<number, number> = new Map();
      parentMapIds.set(parentData.id, parentData.id);
      const parentIds = [parentData.id];
      const response = await addTransactionToTrialBalance(
        locationId,
        startDate,
        endDate,
        'DESC',
        parentIds,
        0,
        100
      );
      if (response.results.length === 0) return;
      if (response.results.length >= 100) hasMore = true;

      const start = moment(startDate).format('YYYY-MM-DD');
      const latestStartBalance = await get_account_latest_history(start, parentIds);
      response.results = response.results.map((value) => {
        const currAccStartBal = latestStartBalance.results.find(
          (val) => val.accountId === value.id
        );
        const startBal = currAccStartBal ? currAccStartBal.balance : 0;
        const endBal = startBal + value.transaction.totalDebit - value.transaction.totalCredit;
        return {
          ...value,
          startBalance: startBal,
          endBalance: endBal
        };
      });

      if (locationId == '') {
        response.results = await Promise.all(
          response.results.map(async (data) => {
            let locationName = '';
            if (data.locationId) {
              let location = await LocationsDB.getLocation(data.locationId);
              if (!location) {
                const locationIds = new Set<number>();
                response.results.forEach((value) => {
                  if (value.locationId) locationIds.add(value.locationId);
                });
                const allLocations = await get_location_list_for_ids([...locationIds]);
                await LocationsDB.addLocations(allLocations);
                location = await LocationsDB.getLocation(data.locationId);
              }
              locationName = `(${location.code})`;
            }

            return { ...data, locationName: locationName };
          })
        );
      }

      const cloneData = [...tableData];
      let childData: ITrialBalanceTableData[] = [...response.results];
      if (hasMore) {
        const obj = {} as ITrialBalanceTableData;
        childData = [
          ...response.results,
          {
            ...obj,
            parentId: parentData.id,
            parentIds: parentIds,
            skip: response.results.length,
            hasMore: true
          }
        ];
      }
      if (parentIds) {
        const newTableData = convertIdToIndex(
          parentMapIds,
          cloneData as ITrialBalanceTableDataForIndexing[],
          childData as ITrialBalanceTableDataForIndexing[]
        );
        setTableData(newTableData);
        return { tableData: newTableData };
      }
    }
  };

  const searchMore = async (record: ITrialBalanceTableData) => {
    if (locationId !== undefined && record.parentIds) {
      const savedData = {} as ISavedData;
      const startDate = form.getFieldValue(['startDate']);
      const endDate = form.getFieldValue(['endDate']);
      const response = await addTransactionToTrialBalance(
        locationId,
        startDate,
        endDate,
        'DESC',
        record.parentIds,
        record.skip,
        100
      );

      if (locationId == '') {
        response.results = await Promise.all(
          response.results.map(async (data) => {
            let locationName = '';
            if (data.locationId) {
              let location = await LocationsDB.getLocation(data.locationId);
              if (!location) {
                const locationIds = new Set<number>();
                response.results.forEach((value) => {
                  if (value.locationId) locationIds.add(value.locationId);
                });
                const allLocations = await get_location_list_for_ids([...locationIds]);
                await LocationsDB.addLocations(allLocations);
                location = await LocationsDB.getLocation(data.locationId);
              }
              locationName = `(${location.code})`;
            }

            return { ...data, locationName: locationName };
          })
        );
      }

      let totalDebitStart = 0;
      let totalCreditStart = 0;
      let totalDebitEnd = 0;
      let totalCreditEnd = 0;
      let totalDebitTransaction = 0;
      let totalCreditTransaction = 0;

      setTableData((prev) => {
        if (record.parentIds) {
          if (record.parentIds.length > 1) {
            const prevItem = [...prev];
            response.results.forEach((data) => {
              prevItem.splice(prev.length - 1, 0, { ...data, children: [] });
            });
            if (response.results.length < 100) {
              prevItem.pop();
            } else {
              prevItem[prevItem.length - 1].skip = prevItem.filter((val) => !val.hasMore).length;
            }

            // total
            prevItem.forEach((value) => {
              if (value.transaction) {
                totalDebitTransaction += value.transaction.totalDebit;
                totalCreditTransaction += value.transaction.totalCredit;
              }
              if (value.startBalance) {
                if (value.startBalance > 0) totalDebitStart += value.startBalance;
                if (value.startBalance < 0) totalCreditStart += Math.abs(value.startBalance);
              }
              if (value.endBalance) {
                if (value.endBalance > 0) totalDebitEnd += value.endBalance;
                if (value.endBalance < 0) totalCreditEnd += Math.abs(value.endBalance);
              }
            });

            savedData.tableData = prevItem;
            return prevItem;
          } else {
            const newTableData = searchTableAndAdd(record.parentIds[0], response, prev);

            // total
            newTableData.forEach((value) => {
              if (value.transaction) {
                totalDebitTransaction += value.transaction.totalDebit;
                totalCreditTransaction += value.transaction.totalCredit;
              }
              if (value.startBalance) {
                if (value.startBalance > 0) totalDebitStart += value.startBalance;
                if (value.startBalance < 0) totalCreditStart += Math.abs(value.startBalance);
              }
              if (value.endBalance) {
                if (value.endBalance > 0) totalDebitEnd += value.endBalance;
                if (value.endBalance < 0) totalCreditEnd += Math.abs(value.endBalance);
              }
            });

            savedData.tableData = newTableData;
            return newTableData;
          }
        } else {
          savedData.tableData = prev;
          return prev;
        }
      });

      const total = {
        debitStart: totalDebitStart,
        creditStart: totalCreditStart,
        debitEnd: totalDebitEnd,
        creditEnd: totalCreditEnd,
        debitTransaction: totalDebitTransaction,
        creditTransaction: totalCreditTransaction
      };
      setTotal(total);
      savedData.total = total;
      zustandFilter.updatePartialData(location.pathname, { ...savedData });
    }
  };

  const searchTableAndAdd = (
    parentId: number,
    data: ITrialBalanceResponseWithTransaction,
    tableData: ITrialBalanceTableData[]
  ): ITrialBalanceTableData[] => {
    return tableData.map((item) => {
      if (item.id === parentId) {
        if (item.children) {
          data.results.forEach((x) => {
            item.children?.splice(item.children.length - 1, 0, {
              ...x,
              iconColor: item.children[0].iconColor,
              children: []
            });
          });
          if (data.results.length < 100) {
            item.children?.pop();
          } else {
            item.children[item.children?.length - 1].skip = item.children?.filter(
              (val) => !val.hasMore
            ).length;
          }
        }
        return { ...item };
      } else if (item.children) {
        const childItem = searchTableAndAdd(parentId, data, item.children);
        return { ...item, children: childItem };
      } else {
        return { ...item };
      }
    });
  };

  function convertIdToIndex(
    id: Map<number, number>,
    data: ITrialBalanceTableDataForIndexing[],
    responseData: ITrialBalanceTableDataForIndexing[]
  ) {
    for (let i = 0; i < data.length; i++) {
      if (id.has(data[i].id)) {
        let filteredData = responseData.filter((value) => value.parentId === data[i].id);
        const randomColor = randomRGBDarkVer();
        filteredData = filteredData.map((value) => {
          value.iconColor = randomColor;
          if (value.hasMore) {
            return { ...value };
          } else {
            return { ...value, children: [] };
          }
        });
        if (filteredData.length > 0) {
          data[i].children = filteredData;
        }
      }
      if (data[i].children !== undefined) {
        if (data[i].children.length > 0) {
          const childIndex = convertIdToIndex(id, data[i].children, responseData);
          if (childIndex.length > 0) {
            data[i].children = childIndex.filter((value) => value.parentId === data[i].id);
          }
        }
      }
    }
    return data;
  }

  const onSubmitFilter = async (vals?: string) => {
    setselectedRows([]);
    setSelectedRowKeys([]);
    const locationId = form.getFieldValue(['locationId']);

    setExpandedRows([]);
    setLocationId(locationId);
    const shownColumns = form.getFieldValue(['shownColumns']);
    setShownColumns(shownColumns);
    const initialStat = { expandedRows: [] as number[], locationId, shownColumns } as ISavedData;
    if (vals) {
      const params = new URLSearchParams(vals);
      const startDate = params.get('startDate') || undefined;
      const endDate = params.get('endDate') || undefined;

      const data = await getTrialBalanceList(locationId, { start: startDate, end: endDate });
      return { ...initialStat, ...data };
    } else {
      const data = await getTrialBalanceList(locationId);
      return { ...initialStat, ...data };
    }
  };

  const getExportAllData = async (locationId: number | string, parentIds: number[]) => {
    const startDate = form.getFieldValue('startDate');
    const endDate = form.getFieldValue('endDate');
    // const dateNow = moment().add(1, 'day').format('YYYY-MM-DD');
    if (locationId === '') {
      let allAccounts = await get_account_list_by_location(0, 1);
      const totalAccounts = allAccounts.total;
      allAccounts = await get_account_list_by_location(0, totalAccounts);
      const ids = allAccounts.results.map((val) => {
        return val.id;
      });

      allAccounts.results = await addTransactionForExportAll(
        allAccounts.results,
        startDate,
        endDate,
        ids
      );

      const start = moment(startDate).format('YYYY-MM-DD');
      // const end = moment(endDate).add(1, 'day').format('YYYY-MM-DD');
      const startBalanceData = await get_account_latest_history(start, ids);
      // const endBalanceData = await get_account_latest_history(end, ids);
      const updatedAllAccounts = allAccounts.results.map((val) => {
        const selectedStartData = startBalanceData.results.find(
          (valStart) => val.id === valStart.accountId
        );
        // const selectedEndData = endBalanceData.results.find(
        //   (valEnd) => val.id === valEnd.accountId
        // );
        const startBal = selectedStartData ? selectedStartData.balance : 0;
        let transactionTotalDebit = 0;
        let transactionTotalCredit = 0;
        if (val.transaction) {
          transactionTotalDebit = val.transaction.totalDebit;
          transactionTotalCredit = val.transaction.totalCredit;
        }
        const endBal = startBal + transactionTotalDebit - transactionTotalCredit;
        return {
          ...val,
          startBalance: startBal,
          endBalance: endBal
        };
      });
      const top = updatedAllAccounts
        .filter((val) => parentIds.includes(val.id))
        .sort((a, b) => b.balance - a.balance);
      const exportData = getExportData(updatedAllAccounts, top);
      return exportData;
    } else {
      locationId = typeof locationId === 'string' ? parseInt(locationId) : locationId;
      let allAccounts = await get_account_list_by_location(0, 1, locationId);
      const totalAccounts = allAccounts.total;
      allAccounts = await get_account_list_by_location(0, totalAccounts, locationId);
      const ids = allAccounts.results.map((val) => {
        return val.id;
      });

      allAccounts.results = await addTransactionForExportAll(
        allAccounts.results,
        startDate,
        endDate,
        ids
      );

      const start = moment(startDate).format('YYYY-MM-DD');
      const end = moment(endDate).add(1, 'day').format('YYYY-MM-DD');
      const startBalanceData = await get_account_latest_history(start, ids);
      // const endBalanceData = await get_account_latest_history(end, ids);
      const updatedAllAccounts = allAccounts.results
        .filter((value) => value.locationId)
        .map((val) => {
          const selectedStartData = startBalanceData.results.find(
            (valStart) => val.id === valStart.accountId
          );
          const startBal = selectedStartData ? selectedStartData.balance : 0;
          let transactionTotalDebitX = 0;
          let transactionTotalCreditX = 0;
          if (val.transaction) {
            transactionTotalDebitX = val.transaction.totalDebit;
            transactionTotalCreditX = val.transaction.totalCredit;
          }
          const endBal = startBal + transactionTotalDebitX - transactionTotalCreditX;
          return {
            ...val,
            startBalance: startBal,
            endBalance: endBal
          };
        });
      const top = updatedAllAccounts
        .filter((val) => parentIds.includes(val.parentId))
        .sort((a, b) => b.balance - a.balance);
      const exportData = getExportData(updatedAllAccounts, top);
      return exportData;
    }
  };

  const getExportData = (allData: IAccountTypeResponseData[], main: IAccountTypeResponseData[]) => {
    main = main.map((item) => {
      let childData: IAccountTypeResponseData[] = [];
      if (allData.filter((val) => val.parentId === item.id).length > 0) {
        childData = getExportData(
          allData,
          allData.filter((val) => val.parentId === item.id)
        );
      }
      return {
        ...item,
        children: childData.sort((a, b) => b.balance - a.balance)
      };
    });

    return main;
  };

  const showExportDrawer = () => {
    setOpenExportDrawer(true);
  };

  const onChangeExport = (e: RadioChangeEvent) => {
    setValueExport(e.target.value);
  };

  const onExportTreeView = async () => {
    setIsLoading(true);
    try {
      const startDate = form.getFieldValue('startDate');
      const endDate = form.getFieldValue('endDate');

      let flattenedData: {
        name: string;
        debitStart: number;
        creditStart: number;
        debitTrans: number;
        creditTrans: number;
        debitEnd: number;
        creditEnd: number;
        date: string;
        miti: string;
        node?: number;
      }[] = [];

      if (valueExport === 1) {
        if (locationId !== undefined) {
          const exportAllData = await getExportAllData(locationId, currParentIds);
          flattenedData = flattenDataExportAll(exportAllData).map((item) => {
            return {
              ...item,
              date: moment(item.createdAt).format('MM/DD/YYYY'),
              miti: convert_string_to_nepali_date_object(item.createdAt).format('MM/DD/YYYY'),
              debitStart: item.startBalance && item.startBalance > 0 ? item.startBalance : 0,
              creditStart:
                item.startBalance && item.startBalance < 0 ? Math.abs(item.startBalance) : 0,
              debitTrans: item.transaction ? item.transaction.totalDebit : 0,
              creditTrans: item.transaction ? item.transaction.totalCredit : 0,
              debitEnd: item.endBalance && item.endBalance > 0 ? item.endBalance : 0,
              creditEnd: item.endBalance && item.endBalance < 0 ? Math.abs(item.endBalance) : 0
            };
          });
        }
      } else {
        flattenedData = flattenData(tableData)
          .filter((val) => !val.hasMore)
          .map((item) => {
            return {
              ...item,
              date: moment(item.createdAt).format('MM/DD/YYYY'),
              miti: convert_string_to_nepali_date_object(item.createdAt).format('MM/DD/YYYY'),
              debitStart: item.startBalance && item.startBalance > 0 ? item.startBalance : 0,
              creditStart:
                item.startBalance && item.startBalance < 0 ? Math.abs(item.startBalance) : 0,
              debitTrans: item.transaction ? item.transaction.totalDebit : 0,
              creditTrans: item.transaction ? item.transaction.totalCredit : 0,
              debitEnd: item.endBalance && item.endBalance > 0 ? item.endBalance : 0,
              creditEnd: item.endBalance && item.endBalance < 0 ? Math.abs(item.endBalance) : 0
            };
          });
      }

      if (flattenedData.length === 0) {
        message.error('No Data to Export');
        return;
      }

      let totalDStart = 0,
        totalCStart = 0,
        totalDTrans = 0,
        totalCTrans = 0,
        totalDEnd = 0,
        totalCEnd = 0;

      flattenedData.forEach((item) => {
        if (item.node === 0) {
          totalDStart += item.debitStart;
          totalCStart += item.creditStart;
          totalDTrans += item.debitTrans;
          totalCTrans += item.creditTrans;
          totalDEnd += item.debitEnd;
          totalCEnd += item.creditEnd;
        }
      });

      const workbook = new ExcelJS.Workbook();
      const sheet = workbook.addWorksheet('Sheet 1');

      let header = [
        'Particular',
        'Debit (Start)',
        'Credit (Start)',
        'Debit (Transaction)',
        'Credit (Transaction)',
        'Debit (End)',
        'Credit (End)',
        'Date',
        'Miti'
      ];

      let footer = [
        'Total',
        totalDStart.toFixed(2),
        totalCStart.toFixed(2),
        totalDTrans.toFixed(2),
        totalCTrans.toFixed(2),
        totalDEnd.toFixed(2),
        totalCEnd.toFixed(2),
        '',
        ''
      ];

      const rows: (string | number)[][] = flattenedData.map((item) => {
        if (shownColumns.length > 0 && shownColumns.length < 3) {
          return [
            item.name,
            item.debitStart,
            item.creditStart,
            item.debitTrans,
            item.creditTrans,
            item.debitEnd,
            item.creditEnd,
            item.date,
            item.miti
          ].filter((_, index) => {
            if (index >= 1 && index <= 6) {
              if (shownColumns.includes('O') && (index === 1 || index === 2)) return true;
              if (shownColumns.includes('T') && (index === 3 || index === 4)) return true;
              if (shownColumns.includes('C') && (index === 5 || index === 6)) return true;
            } else {
              return true;
            }
          });
        } else {
          return [
            item.name,
            item.debitStart,
            item.creditStart,
            item.debitTrans,
            item.creditTrans,
            item.debitEnd,
            item.creditEnd,
            item.date,
            item.miti
          ];
        }
      });

      if (shownColumns.length > 0 && shownColumns.length < 3) {
        header = header.filter((item) => {
          if (item.includes('Debit') || item.includes('Credit')) {
            if (shownColumns.includes('O') && item.includes('Start')) return true;
            if (shownColumns.includes('T') && item.includes('Trans')) return true;
            if (shownColumns.includes('C') && item.includes('End')) return true;
          } else {
            return true;
          }
        });

        footer = footer.filter((_, index) => {
          if (index >= 1 && index <= 6) {
            if (shownColumns.includes('O') && (index === 1 || index === 2)) return true;
            if (shownColumns.includes('T') && (index === 3 || index === 4)) return true;
            if (shownColumns.includes('C') && (index === 5 || index === 6)) return true;
          } else {
            return true;
          }
        });
      }

      const excelData = [header, ...rows];

      let dummySpace = [];
      if (shownColumns.length == 0 || shownColumns.length === 3) {
        dummySpace = ['', '', '', '', '', ''];
      } else {
        for (let i = 0; i < Math.pow(2, shownColumns.length); i++) {
          dummySpace.push('');
        }
      }
      sheet.spliceRows(
        1,
        0,
        ['Trial Balance'],
        [`From: ${startDate}`, ...dummySpace, '', `To: ${endDate}`]
      );
      if (shownColumns.length == 0 || shownColumns.length === 3) {
        sheet.mergeCells('A1:I1');
      } else if (shownColumns.length === 1) {
        sheet.mergeCells('A1:E1');
      } else if (shownColumns.length === 2) {
        sheet.mergeCells('A1:G1');
      }
      const mainHeadingCell = sheet.getCell('A1');
      mainHeadingCell.alignment = { horizontal: 'center' };
      mainHeadingCell.font = { bold: true };

      // Add data and format header row
      excelData.forEach((rowData, rowIndex) => {
        const row = sheet.addRow(rowData);
        // Make header row bold
        if (rowIndex === 0) {
          row.eachCell((cell) => {
            cell.font = { bold: true };
          });
        } else {
          const item = flattenedData[rowIndex - 1];

          row.eachCell((cell) => {
            const node = item.node ? item.node : 0;
            const colorIndex = node % colorPalette.length;
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: colorPalette[colorIndex] }
            };

            cell.border = {
              top: { style: 'thin' },
              left: { style: 'thin' },
              bottom: { style: 'thin' },
              right: { style: 'thin' }
            };
          });
        }
      });

      // Fill the first 3 rows with green color
      for (let i = 1; i <= 3; i++) {
        sheet.getRow(i).eachCell((cell) => {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '50C878' } // Green color
          };
        });
      }

      // Set cell widths
      const columnWidths = [30, 20, 20, 20, 20, 20, 20, 20, 20];
      columnWidths.forEach((width, index) => {
        sheet.getColumn(index + 1).width = width;
      });

      // Footer
      const lastRowIndex = sheet.rowCount;
      const footerRow = sheet.getRow(lastRowIndex + 1);

      footer.forEach((value, index) => {
        footerRow.getCell(index + 1).value = value;
      });

      footerRow.eachCell((cell) => {
        cell.font = { bold: true };
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '50C878' }
        };
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' }
        };
      });

      // Generate the Excel file
      workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = valueExport === 1 ? 'Trial_Balance_All.xlsx' : 'Trial_Balance.xlsx';
        a.click();
        window.URL.revokeObjectURL(url);
      });

      setIsLoading(false);
    } catch (err: any) {
      setIsLoading(false);
      console.log(err);
    }
  };

  // Function to flatten hierarchical data
  const flattenData = (data: ITrialBalanceTableData[], prefix = '', node = 0) => {
    const result: ITrialBalanceTableData[] = [];
    const traverse = (
      item: ITrialBalanceTableData,
      parent: ITrialBalanceTableData | null,
      prefix: string,
      node: number
    ) => {
      const newItem = { ...item, parent, node };
      newItem.name = prefix + newItem.name;
      delete newItem.children;
      result.push(newItem);
      if (item.children) {
        const childPrefix = prefix + '\u00A0'.repeat(3);
        const childNode = node + 1;
        item.children.forEach((child) => traverse(child, newItem, childPrefix, childNode));
      }
    };
    data.forEach((item) => traverse(item, null, prefix, node));
    return result;
  };

  // Function to flatten hierarchical data (Export All)
  const flattenDataExportAll = (data: IAccountTypeResponseData[], prefix = '', node = 0) => {
    const result: IAccountTypeResponseData[] = [];
    const traverse = (
      item: IAccountTypeResponseData,
      parent: IAccountTypeResponseData | null,
      prefix: string,
      node: number
    ) => {
      const newItem = { ...item, parent, node };
      newItem.name = prefix + newItem.name;
      delete newItem.children;
      result.push(newItem);
      if (item.children) {
        const childPrefix = prefix + '\u00A0'.repeat(3);
        const childNode = node + 1;
        item.children.forEach((child) => traverse(child, newItem, childPrefix, childNode));
      }
    };
    data.forEach((item) => traverse(item, null, prefix, node));
    return result;
  };

  const filterColumns = () => {
    if (shownColumns.length === 3) {
      return columns;
    } else {
      return columns.filter((item) => {
        if (
          item.title &&
          (item.title.toString().includes('Debit') || item.title.toString().includes('Credit'))
        ) {
          if (shownColumns.includes('O') && item.title && item.title.toString().includes('Start')) {
            return true;
          }
          if (shownColumns.includes('T') && item.title && item.title.toString().includes('Trans')) {
            return true;
          }
          if (shownColumns.includes('C') && item.title && item.title.toString().includes('End')) {
            return true;
          }
        } else {
          return true;
        }
      });
    }
  };

  return (
    <Spin spinning={isLoading}>
      <CustomModal
        width={'30%'}
        footer={false}
        maskClosable={false}
        isModalOpen={openModalForSwitchParent}
        setIsModalOpen={setOpenModalForSwitchParent}
        isSticky={true}
        title="Switch Parent">
        <AccountSwitchParent
          data={selectedRows}
          locationId={typeof locationId === 'number' ? locationId : undefined}
          handleModalClose={handleSwitchParentModalClose}
        />
      </CustomModal>
      <AppContent
        breadcrumbItems={breadcrumbItems}
        withfilter={true}
        button={
          <>
            <div>
              <AccountFilterTable
                form={form}
                hasHours={false}
                showFilterInitial
                onParentLoading={setIsLoading}
                onInitialLoad={({ data }) => {
                  if (data) {
                    const typedData = data as ISavedData;

                    if (typedData.currParentIds) setCurrParentIds(typedData.currParentIds);
                    if (typedData.tableData) setTableData(typedData.tableData);
                    if (typedData.total) setTotal(typedData.total);
                    if (typedData.locationId !== undefined) setLocationId(typedData.locationId);
                    if (typedData.shownColumns) setShownColumns(typedData.shownColumns);
                    if (typedData.expandedRows) setExpandedRows(typedData.expandedRows);
                    setIsLoading(false);
                  }
                }}
                defaultValues={{
                  locationId: '',
                  shownColumns: ['C']
                }}
                initial={false}
                onSearch={onSubmitFilter}
                buttonParentStyle={'flex justify-end items-center'}
                buttons={
                  <>
                    {selectedRows.length > 0 && (
                      <CustomButton
                        onClick={() => {
                          handleSwitchParent();
                        }}
                        text="Switch Parent"
                        backgroundColor="#1890ff"
                      />
                    )}
                    <CustomButton
                      onClick={() => navigate('/accounts/balance/customer/trialbalance')}
                      text="Customer Balance"
                      backgroundColor="#1890ff"
                      Linkto="/accounts/balance/customer/trialbalance"
                    />
                    <CustomButton
                      onClick={() => navigate('/accounts/balance/vendor/trialbalance')}
                      text="Vendor Balance"
                      backgroundColor="#1890ff"
                      Linkto="/accounts/balance/vendor/trialbalance"
                    />
                  </>
                }>
                <LocationSearchV2 hasParentFormItem={false} name={'locationId'} showAll />

                <Divider orientation="left">Show Columns</Divider>
                <Form.Item name={['shownColumns']}>
                  <Checkbox.Group>
                    <Checkbox value="O">Opening Balance</Checkbox>
                    <br />
                    <Checkbox value="T">Transaction</Checkbox>
                    <br />
                    <Checkbox value="C">Closing Balance</Checkbox>
                  </Checkbox.Group>
                </Form.Item>
              </AccountFilterTable>
            </div>
          </>
        }>
        <ReusableDrawer
          title="Export"
          setShowDrawer={setOpenExportDrawer}
          showDrawer={openExportDrawer}
          footer={
            <Space>
              <Button type="primary" onClick={onExportTreeView}>
                Download
              </Button>
            </Space>
          }>
          <Radio.Group onChange={onChangeExport} value={valueExport}>
            <Space direction="vertical">
              <Radio value={1}>Export All</Radio>
              <Radio value={2}>Export Current</Radio>
            </Space>
          </Radio.Group>
        </ReusableDrawer>
        <div className="my-2 flex justify-end">
          <Button type="primary" onClick={showExportDrawer}>
            Export
          </Button>
        </div>
        <div ref={tableRef}>
          <Table
            columns={shownColumns.length > 0 ? filterColumns() : columns}
            rowKey={'id'}
            rowClassName={'cursor-pointer'}
            rowSelection={{
              hideSelectAll: true,
              type: 'checkbox',
              ...rowSelection,
              columnWidth: '5px'
            }}
            scroll={{ x: 1750, y: '75vh' }}
            dataSource={tableData}
            expandable={{
              onExpand: async (expanded, record) => {
                const savedData = {} as ISavedData;
                if (expanded) {
                  if (locationId !== undefined) {
                    setExpandedRows((prev) => {
                      const newRow = [...prev, record.id];
                      savedData.expandedRows = newRow;
                      return newRow;
                    });
                    const data = await getChildTrialBalanceList(record, locationId);
                    if (data) savedData.tableData = data.tableData;
                  }
                } else {
                  setExpandedRows((prev) => {
                    const newRow = prev.filter((key) => key !== record.id);
                    savedData.expandedRows = newRow;
                    return newRow;
                  });
                }

                zustandFilter.updatePartialData(location.pathname, { ...savedData });
              },
              expandedRowKeys: expandedRows
            }}
            onRow={(record) => {
              return {
                onClick: async () => {
                  if (!record.hasMore) {
                    const savedData = {} as ISavedData;

                    if (expandedRows.find((key) => key === record.id)) {
                      setExpandedRows((prev) => {
                        const newRow = prev.filter((key) => key !== record.id);
                        savedData.expandedRows = newRow;
                        return newRow;
                      });
                    } else {
                      if (locationId !== undefined) {
                        setExpandedRows((prev) => {
                          const newRow = [...prev, record.id];
                          savedData.expandedRows = newRow;
                          return newRow;
                        });
                        const data = await getChildTrialBalanceList(record, locationId);
                        if (data) savedData.tableData = data.tableData;
                      }
                    }

                    zustandFilter.updatePartialData(location.pathname, { ...savedData });
                  }
                }
              };
            }}
            summary={() => (
              <Table.Summary fixed={true}>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0} className="font-bold"></Table.Summary.Cell>
                  <Table.Summary.Cell index={1}>Total</Table.Summary.Cell>
                  <Table.Summary.Cell index={2}></Table.Summary.Cell>
                  {shownColumns.includes('O') && (
                    <>
                      <Table.Summary.Cell index={3} className="font-bold">
                        {nepaliNumberFormatter(total.debitStart)}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={4} className="font-bold">
                        {nepaliNumberFormatter(total.creditStart)}
                      </Table.Summary.Cell>
                    </>
                  )}
                  {shownColumns.includes('T') && (
                    <>
                      <Table.Summary.Cell index={5} className="font-bold">
                        {nepaliNumberFormatter(total.debitTransaction)}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={6} className="font-bold">
                        {nepaliNumberFormatter(total.creditTransaction)}
                      </Table.Summary.Cell>
                    </>
                  )}
                  {shownColumns.includes('C') && (
                    <>
                      <Table.Summary.Cell index={7} className="font-bold">
                        {nepaliNumberFormatter(total.debitEnd)}
                      </Table.Summary.Cell>

                      <Table.Summary.Cell index={7} className="font-bold">
                        {nepaliNumberFormatter(total.creditEnd)}
                      </Table.Summary.Cell>
                    </>
                  )}
                </Table.Summary.Row>
              </Table.Summary>
            )}
            pagination={false}
          />
        </div>
      </AppContent>
    </Spin>
  );
};

export default TrialBalanceList;
