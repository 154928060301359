import { Column, DataGridHandle, RenderCellProps } from 'react-data-grid';
import { IRow } from './grid.types';

export function focusPrevEditableCell(
  props: RenderCellProps<IRow>,
  flattenColumns: Column<IRow>[],
  gridRef: React.RefObject<DataGridHandle>
) {
  const { column, rowIdx } = props;

  // React Data Grid consider headers row as well, and there are 2 header rows
  const rowColumnLength = 2;
  const currentExactRow = rowColumnLength + rowIdx + 1;

  // Find the prev editable cell in the same row
  for (let i = column.idx - 1; i >= 0; i--) {
    const prevColumn = flattenColumns[i];
    if (!prevColumn.editable) continue;
    scrollToAndSelectCell(gridRef, { colIdx: i, rowIdx: currentExactRow });
    return;
  }
}

export function focusNextEditableCell(
  props: RenderCellProps<IRow>,
  flattenColumns: Column<IRow>[],
  gridRef: React.RefObject<DataGridHandle>
) {
  const { column, rowIdx } = props;
  const rowColumnLength = 2;
  const currentExactRow = rowColumnLength + rowIdx + 1;
  const firstEditableCol = flattenColumns.findIndex((a) => a.editable);

  // Find the next editable cell in the same row
  for (let i = column.idx + 1; i < flattenColumns.length; i++) {
    const nextColumn = flattenColumns[i];
    if (!nextColumn.editable) continue;
    scrollToAndSelectCell(gridRef, { colIdx: i, rowIdx: currentExactRow });
    return;
  }

  // Focus the first editable cell in the next row
  if (firstEditableCol !== -1) {
    const colIdx = firstEditableCol;
    const nextRowIdx = currentExactRow + 1;
    scrollToAndSelectCell(gridRef, { colIdx, rowIdx: nextRowIdx });
  }
}

function scrollToAndSelectCell(
  gridRef: React.RefObject<DataGridHandle>,
  position: { colIdx: number; rowIdx: number }
) {
  if (!gridRef.current) return;
  const { colIdx, rowIdx } = position;

  gridRef.current.scrollToCell({ idx: colIdx, rowIdx });

  requestAnimationFrame(() => {
    focusInput(rowIdx, colIdx);
  });
}

function focusInput(rowIdx: number, colIdx: number) {
  const rowSelector = `[aria-rowindex="${rowIdx}"]`;
  const cellSelector = `[role="gridcell"][aria-colindex="${colIdx + 1}"] input`;
  const input = document.querySelector<HTMLInputElement>(`${rowSelector} ${cellSelector}`);

  if (input) {
    input.focus();
    if (input.value === '0') input.select(); // Select if value is 0
  }
}
