import { shortNameHiearchy } from '@/pages/sell/sell-order/view';
import roundNumber from './round.utils';

export function convertTotalQtyString(totalQty: Record<string, number>) {
  const totalQtyArray = Object.entries(totalQty).map(([unit, quantity]) => ({ unit, quantity }));

  if (totalQtyArray.length == 0) return '0';
  totalQtyArray.sort((a, b) => {
    const aIndex = shortNameHiearchy.indexOf(a.unit);
    const bIndex = shortNameHiearchy.indexOf(b.unit);

    return aIndex - bIndex;
  });

  return totalQtyArray.reduce((acc, { unit, quantity }) => {
    if (quantity === 0) return acc;
    return `${acc} ${roundNumber(quantity)} ${unit}`;
  }, '');
}
