import { DatePicker, Form, Input, InputNumber, TableProps } from 'antd';
import { useEffect, useState } from 'react';
import type { ColumnsType } from 'antd/es/table';
import { CustomDatePresets } from '../utils/datePresets';
import { RangePickerProps } from 'antd/lib/date-picker';
import moment from 'moment';

import CustomReport from './report';

import { nepaliNumberFormatter } from '@/utils/numberFormatter';
const { RangePicker } = DatePicker;
import { SorterResult } from 'antd/lib/table/interface';
import { find_date_preference } from '@/store/localstorage/preferences';
import {
  convert_nepali_to_english_date,
  convert_string_to_nepali_date_string
} from '@/utils/nepaliDateConverter';
import { NepaliDatePicker } from 'nepali-datepicker-reactjs';
import { getUserData } from '@/utils/auth.utils';
import LocationSearchV2 from '@/components/Common/CustomSearch/Location';
import { ITopSellProductsByQuantity } from '@/services/report/types';

const TopSaleProductReport = () => {
  const [form] = Form.useForm();
  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({});
  const handleChange: TableProps<any>['onChange'] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<any>);
  };
  const [nepaliType] = useState(find_date_preference());
  const { preferences } = getUserData();
  const preferenceLocationId = preferences?.preferences
    ? JSON.parse(preferences?.preferences)?.locationId
    : undefined;
  useEffect(() => {
    const nepaliDateStart = convert_string_to_nepali_date_string(
      CustomDatePresets.Today[0].format('YYYY-MM-DD HH:mm')
    );
    const nepaliDateEnd = convert_string_to_nepali_date_string(
      CustomDatePresets.Today[0].format('YYYY-MM-DD HH:mm')
    );
    form.setFieldValue('constraints', {
      page: 1,
      size: 100,
      locationId: preferenceLocationId ? preferenceLocationId : '',
      dateCustom: [...CustomDatePresets.Today],
      startDate: CustomDatePresets.Today[0].format('YYYY-MM-DD HH:mm'),
      endDate: CustomDatePresets.Today[1].format('YYYY-MM-DD HH:mm'),
      startDateNepali: nepaliDateStart,
      endDateNepali: nepaliDateEnd
    });
  }, []);

  const breadcrumbItems = [
    { label: 'Reports', link: '/reports' },
    { label: 'Top Sale Product Report', link: '/reports/top-sale-product' }
  ];
  const onChange: RangePickerProps['onChange'] = (dates, dateStrings) => {
    if (dateStrings.length == 2) {
      form.setFieldValue(['constraints', 'startDate'], dateStrings[0]);
      form.setFieldValue(['constraints', 'endDate'], dateStrings[1]);
    }
  };

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    return current && current > moment().endOf('day');
  };

  const onChangeNepali = (val: string, isStart: boolean) => {
    if (isStart)
      form.setFieldValue(['constraints', 'startDate'], convert_nepali_to_english_date(val));
    else form.setFieldValue(['constraints', 'endDate'], convert_nepali_to_english_date(val));
  };

  const columns: ColumnsType<ITopSellProductsByQuantity> = [
    {
      title: 'S.N',
      key: 'SN',
      width: 25,
      render: (text, record, index) => {
        return <div color="black">{index + 1}</div>;
      }
    },
    {
      title: 'Product',
      key: 'product_name',
      sorter: (a, b) => a.product_name.localeCompare(b.product_name),
      sortOrder: sortedInfo.columnKey === 'product_name' ? sortedInfo.order : null,
      render: (record) => <div>{record.product_name}</div>,
      width: 100
    },
    {
      title: 'Location',
      key: 'location_name',
      sorter: (a, b) => a.location_name.localeCompare(b.location_name),
      sortOrder: sortedInfo.columnKey === 'location_name' ? sortedInfo.order : null,
      render: (record) => <div>{record.location_name}</div>,
      width: 100
    },

    {
      title: 'Purchase Qty',
      key: 'purchase_qty',
      align: 'right',
      sorter: (a, b) =>
        parseFloat(a.purchase_total_quantity) - parseFloat(b.purchase_total_quantity),
      sortOrder: sortedInfo.columnKey === 'purchase_qty' ? sortedInfo.order : null,
      render: (record) => {
        return (
          <div className="text-right mr-2">
            {parseFloat(record.purchase_total_quantity)} {record.unit}
          </div>
        );
      },
      width: 50
    },
    {
      title: 'Purchase Return Qty',
      key: 'purchase_return_qty',
      align: 'right',
      sorter: (a, b) =>
        parseFloat(a.purchase_return_total_quantity) - parseFloat(b.purchase_return_total_quantity),
      sortOrder: sortedInfo.columnKey === 'purchase_return_qty' ? sortedInfo.order : null,
      render: (record) => {
        return (
          <div className="text-right mr-2">
            {parseFloat(record.purchase_return_total_quantity)} {record.unit}
          </div>
        );
      },
      width: 50
    },

    {
      title: 'Sell Qty',
      key: 'sell_qty',
      align: 'right',
      sorter: (a, b) => parseFloat(a.sell_total_quantity) - parseFloat(b.sell_total_quantity),
      sortOrder: sortedInfo.columnKey === 'sell_qty' ? sortedInfo.order : null,
      render: (record) => {
        return (
          <div className="text-right mr-2">
            {parseFloat(record.sell_total_quantity)} {record.unit}
          </div>
        );
      },
      width: 50
    },
    {
      title: 'Sell Return Qty',
      key: 'sell_return_qty',
      align: 'right',
      sorter: (a, b) =>
        parseFloat(a.sell_return_total_quantity) - parseFloat(b.sell_return_total_quantity),
      sortOrder: sortedInfo.columnKey === 'sell_return_qty' ? sortedInfo.order : null,
      render: (record) => {
        return (
          <div className="text-right mr-2">
            {parseFloat(record.sell_return_total_quantity)} {record.unit}
          </div>
        );
      },
      width: 50
    },
    {
      title: 'Qty Diff',
      key: 'qty_diff',
      align: 'right',
      sorter: (a, b) => parseFloat(a.quantity_difference) - parseFloat(b.quantity_difference),
      sortOrder: sortedInfo.columnKey === 'qty_diff' ? sortedInfo.order : null,
      render: (record) => {
        return (
          <div className="text-right mr-2">
            {parseFloat(record.quantity_difference)} {record.unit}
          </div>
        );
      },
      width: 50
    },
    {
      title: 'Sell',
      key: 'sell',
      align: 'right',
      sorter: (a, b) => parseFloat(a.sell_sum) - parseFloat(b.sell_sum),
      sortOrder: sortedInfo.columnKey === 'sell' ? sortedInfo.order : null,
      render: (record) => {
        return <div className="text-right mr-2">{nepaliNumberFormatter(record.sell_sum)}</div>;
      },
      width: 50
    },
    {
      title: 'Sell Return',
      key: 'sell_return',
      align: 'right',
      sorter: (a, b) => parseFloat(a.sell_return_sum) - parseFloat(b.sell_return_sum),
      sortOrder: sortedInfo.columnKey === 'sell_return' ? sortedInfo.order : null,
      render: (record) => {
        return (
          <div className="text-right mr-2">{nepaliNumberFormatter(record.sell_return_sum)}</div>
        );
      },
      width: 50
    },
    {
      title: 'Purchase',
      key: 'purchase',
      align: 'right',
      sorter: (a, b) => parseFloat(a.purchase_sum) - parseFloat(b.purchase_sum),
      sortOrder: sortedInfo.columnKey === 'purchase' ? sortedInfo.order : null,
      render: (record) => {
        return <div className="text-right mr-2">{nepaliNumberFormatter(record.purchase_sum)}</div>;
      },
      width: 50
    },
    {
      title: 'Purchase Return',
      key: 'purchase_return',
      align: 'right',
      sorter: (a, b) => parseFloat(a.purchase_return_sum) - parseFloat(b.purchase_return_sum),
      sortOrder: sortedInfo.columnKey === 'purchase_return' ? sortedInfo.order : null,
      render: (record) => {
        return (
          <div className="text-right mr-2">{nepaliNumberFormatter(record.purchase_return_sum)}</div>
        );
      },
      width: 50
    }
  ];

  return (
    <CustomReport
      breadcrumbItems={breadcrumbItems}
      state={{ id: [43], name: 'Top Sale Products Ordered By Quantity.' }}
      form={form}
      toSort={handleChange}
      footerData={{ sell: true, purchase: true }}
      columnsData={[columns]}>
      <Form.Item label={'page'} name={['constraints', 'page']} hidden={true}>
        <InputNumber controls={false} />
      </Form.Item>
      <Form.Item label={'size'} name={['constraints', 'size']} hidden={true}>
        <InputNumber controls={false} />
      </Form.Item>
      <Form.Item label={'size'} name={['constraints', 'identifier']} hidden={true}>
        <InputNumber controls={false} />
      </Form.Item>

      <LocationSearchV2 showAll hasParentFormItem={false} name={['constraints', 'locationId']} />

      <Form.Item
        label={'Pick a date'}
        name={['constraints', 'dateCustom']}
        rules={[{ required: true, message: 'Please select date!' }]}
        hidden={nepaliType}>
        <RangePicker
          showTime
          ranges={CustomDatePresets}
          disabledDate={disabledDate}
          onChange={onChange}
          format={'YYYY-MM-DD HH:mm'}
          allowClear={false}
        />
      </Form.Item>
      <Form.Item label={'Start Date'} name={['constraints', 'startDate']} hidden>
        <Input />
      </Form.Item>
      <Form.Item label={'End Date'} name={['constraints', 'endDate']} hidden>
        <Input />
      </Form.Item>
      <Form.Item label={'Select Start Date'} name={['startDateNepali']} hidden={!nepaliType}>
        <NepaliDatePicker
          onChange={(val) => onChangeNepali(val, true)}
          options={{ calenderLocale: 'ne', valueLocale: 'en' }}
        />
      </Form.Item>
      <Form.Item label={'Select End Date'} name={['endDateNepali']} hidden={!nepaliType}>
        <NepaliDatePicker
          onChange={(val) => onChangeNepali(val, false)}
          options={{ calenderLocale: 'ne', valueLocale: 'en' }}
        />
      </Form.Item>
    </CustomReport>
  );
};

export default TopSaleProductReport;
