import AppContent from '@/components/Common/Content/Content';
import { get_hr_leave_list } from '@/services/hr/queries';
import { IHRLeaves, IHRLeavesList } from '@/services/hr/types';
import { ConvertObjectToURL } from '@/utils/converturl';
import getErrorMessage from '@/utils/getError';
import handlePagination from '@/utils/handlePagination';
import { Form, Input, message, Select } from 'antd';
import { useState } from 'react';
import Table from './Table';
import TableFilter from '@/components/FliterTable';
import moment from 'moment';
import CustomButton from '@/components/Common/CustomButton/CustomButton';
import { update_hr_leaves_mutation } from '@/services/hr/mutations';
import { checkAccess } from '@/routes/acl';
import UserSearchV2 from '@/components/Common/CustomSearch/Users';
import UsersDB from '@/store/localstorage/UsersDB';
import { getUser } from '@/services';
import { HRDatePresets } from '@/pages/sqlsource/report/utils/datePresets';
import { useFilterStore } from '@/store/zustand';
import { useLocation } from 'react-router-dom';

function HRLeavesList() {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(true);
  const [pagination, setPagination] = useState({ page: 1, size: 100 });
  const [data, setData] = useState<IHRLeavesList>({ total: 0, results: [] });

  const location = useLocation();
  const zustandFilter = useFilterStore();

  async function onSubmitFilter(filter: string) {
    const data = await getInfo(filter);
    setPagination({ page: 1, size: 100 });
    return data;
  }

  async function handleDisabled(leave: IHRLeaves) {
    try {
      setIsLoading(true);
      const { id, ...payload } = leave;
      await update_hr_leaves_mutation(id, {
        ...payload,
        isDisabled: !leave.isDisabled
      });

      message.success(`Leave ${leave.isDisabled ? 'rejected' : 'approved'} Successfully`);
      zustandFilter.refetchData(location.pathname, getInfo);
    } catch (error) {
      getErrorMessage(error, true);
    } finally {
      setIsLoading(false);
    }
  }

  async function getInfo(filter = '') {
    try {
      setIsLoading(true);
      const response = await get_hr_leave_list(filter);
      const leaves = response.results;

      const allUserIds = Array.from(new Set(leaves.map((l) => l.userId)));
      await UsersDB.addUsersIfAbsent(allUserIds);

      const data = await Promise.all(
        leaves.map(async (leave) => {
          const user = await getUser(leave.userId);
          return { ...leave, userName: user.name };
        })
      );

      setData({ total: response.total, results: data });
      return { total: response.total, results: data };
    } catch (error) {
      getErrorMessage(error, true);
    } finally {
      setIsLoading(false);
    }
  }

  const onPagination = async (page = 1, size = 100, isSize = false) => {
    setIsLoading(true);
    const { values, ...pagination } = handlePagination({
      formValues: form.getFieldsValue(),
      page,
      size,
      isSize
    });

    setPagination((prev) => {
      prev.page = pagination.page;
      if (isSize) prev.size = pagination.size;
      return prev;
    });

    const url = ConvertObjectToURL(values);
    return await getInfo(url);
  };

  return (
    <div>
      <AppContent
        breadcrumbItems={[{ label: 'HR', link: '/hr' }, { label: 'Leaves' }]}
        withfilter
        button={
          <TableFilter
            initial
            form={form}
            datePresets={HRDatePresets}
            buttons={
              <>
                {checkAccess('CREATE_LEAVES') && (
                  <CustomButton text="Add" backgroundColor="#1890ff" Linkto="/hr/leaves/new" />
                )}
              </>
            }
            onSubmit={onSubmitFilter}
            onInitialLoad={({ data, pagination }) => {
              setPagination(pagination);

              if (data) {
                setData(data);
                setIsLoading(false);
              }
            }}
            defaultValues={{
              dateCustom: [moment(0, 'HH'), moment(0, 'HH').add(1, 'days')],
              value: '',
              skip: 0,
              count: 100,
              archiveStatus: 'ACTIVE',
              userId: ''
            }}
            styleforbuttons={'flex justify-end items-center'}>
            <UserSearchV2 hasParentFormItem={false} name={'userId'} showAll />

            <Form.Item name="archiveStatus" label="Status">
              <Select placeholder="Select a Status">
                <Select.Option value="ALL">All</Select.Option>
                <Select.Option value="ACTIVE">Approved</Select.Option>
                <Select.Option value="INACTIVE">Rejected</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item name="value" label="Name">
              <Input />
            </Form.Item>
          </TableFilter>
        }>
        <Table
          form={form}
          data={data}
          isLoading={isLoading}
          pagination={pagination}
          onPagination={onPagination}
          handleDisabled={handleDisabled}
        />
      </AppContent>
    </div>
  );
}

export default HRLeavesList;
