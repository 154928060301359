import { Form, Input, Select, Spin } from 'antd';
import { useState } from 'react';
import TableFilter from '@/components/FliterTable';
import AppContent from '@/components/Common/Content/Content';
import { ColumnsType, TableProps } from 'antd/lib/table';
import TableCell from '@/components/Common/CustomizeTable/CustomCell';
import { ConvertObjectToURL } from '@/utils/converturl';
import { SorterResult } from 'antd/lib/table/interface';
import { get_customers_list_v2 } from '@/services/users/queries';
import { ICustomer, IcustomerData } from '@/services/users/types';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';
import { useParams } from 'react-router-dom';
import GenericTable from '@/components/Common/CustomizeTable';

const CustomerBalanceList = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [form] = Form.useForm();
  const { id } = useParams();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(100);
  const [customerList, setCustomerList] = useState<IcustomerData>({ total: 0, results: [] });
  const [sortedInfo, setSortedInfo] = useState<SorterResult<ICustomer>>({});
  const handleChange: TableProps<ICustomer>['onChange'] = (_, __, sorter) => {
    setSortedInfo(sorter as SorterResult<ICustomer>);
  };

  const breadcrumbItems = [
    {
      label: id === 'trialbalance' ? 'Trial Balance' : 'Ledger',
      link: id === 'trialbalance' ? '/trial-balance' : '/ledger'
    },
    {
      label: 'Customer Balance',
      link: '/accounts/balance/customer'
    }
  ];

  const columns: ColumnsType<ICustomer> = [
    {
      title: 'S.N',
      key: 'SN',
      width: 5,
      render: (txt, record, index) => {
        return <TableCell>{(page - 1) * size + (index + 1)}</TableCell>;
      }
    },
    {
      title: 'Name',
      width: 20,
      key: 'name',
      sorter: (a, b) => {
        return a.user.name.localeCompare(b.user.name);
      },
      sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null,
      render: (a, record) => {
        return <TableCell>{record.user.name}</TableCell>;
      }
    },
    {
      title: 'Email',
      key: 'email',
      width: 10,
      sorter: (a, b) => {
        return a.user.email.localeCompare(b.user.email);
      },
      sortOrder: sortedInfo.columnKey === 'email' ? sortedInfo.order : null,
      render: (a, record) => {
        return <TableCell> {record.user.email}</TableCell>;
      }
    },
    {
      title: 'Phone',
      key: 'phone',
      width: 7,
      sorter: (a, b) => {
        return a.user.phone.localeCompare(b.user.phone);
      },
      sortOrder: sortedInfo.columnKey === 'phone' ? sortedInfo.order : null,
      render: (a, record) => {
        return <TableCell>{record.user.phone}</TableCell>;
      }
    },
    {
      title: 'Balance',
      key: 'accountBalance',
      width: 7,
      dataIndex: 'accountBalance',
      sorter: (a, b) => {
        return a.accountBalance - b.accountBalance;
      },
      sortOrder: sortedInfo.columnKey === 'accountBalance' ? sortedInfo.order : null,
      render: (a, record) => {
        return (
          <TableCell className="text-right mr-2 text-xs">
            {nepaliNumberFormatter(record.accountBalance)}
          </TableCell>
        );
      }
    }
  ];

  const onSubmitFilter = async (val: string) => {
    const data = await getInfo(val);
    setPage(1);
    setSize(100);
    return data;
  };

  const getInfo = async (filter = '') => {
    setIsLoading(true);
    const response = await get_customers_list_v2(filter);
    setCustomerList(response.data);
    setIsLoading(false);
    return response.data;
  };

  const onPagination = async (pageNo = 1, totalSize = 100, isSize = false) => {
    setIsLoading(true);
    const values = form.getFieldsValue();
    if (isSize) {
      values.skip = 0;
      values.count = totalSize;
      setPage(1);
      setSize(totalSize);
    } else {
      values.skip = (pageNo - 1) * totalSize;
      values.count = totalSize;
      setPage(pageNo);
    }
    const url = ConvertObjectToURL(values);
    return await getInfo(url);
  };

  return (
    <Spin spinning={isLoading}>
      <AppContent
        breadcrumbItems={breadcrumbItems}
        withfilter={false}
        button={
          <>
            <div>
              <TableFilter
                dateCustom={false}
                onInitialLoad={({ data, pagination }) => {
                  setPage(pagination.page);
                  setSize(pagination.size);

                  if (data) {
                    setCustomerList(data);
                    setIsLoading(false);
                  }
                }}
                defaultValues={{
                  value: '',
                  skip: 0,
                  count: 100,
                  disabled: false
                }}
                initial={true}
                onSubmit={onSubmitFilter}
                form={form}
                styleforbuttons="flex justify-end items-center"
                style={
                  'grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3  xl:grid-cols-4 gap-2 items-center justify-center'
                }>
                <Form.Item name="value" label="Search">
                  <Input placeholder="Search" />
                </Form.Item>
                <Form.Item label="Status" name="disabled">
                  <Select allowClear>
                    <Select.Option value={true}>Disabled</Select.Option>
                    <Select.Option value={false}>Enabled</Select.Option>
                  </Select>
                </Form.Item>
              </TableFilter>
            </div>
            {/* </div> */}
          </>
        }>
        <GenericTable
          form={form}
          columns={columns}
          data={customerList.results}
          hideDefaultPagination={true}
          scroll={{ x: 600, y: '75vh' }}
          pagination={{
            page: page,
            total: customerList.total,
            size: size,
            onPagination
          }}
          toSort={handleChange}
          generateSummary
        />
      </AppContent>
    </Spin>
  );
};

export default CustomerBalanceList;
