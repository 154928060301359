import { get_current_page_filter } from '@/services/users/services.users';
import { useFilterStore, FilterType } from '@/store/zustand';
import { DeleteOutlined, SaveOutlined, SyncOutlined } from '@ant-design/icons';
import { Button, FormInstance, message } from 'antd';
import { useLocation } from 'react-router-dom';
import { filterColor } from './FilterInfo';
import { CSSProperties } from 'react';

interface Props {
  form: FormInstance;
  isDisabled?: boolean;
  onSaveReset?: () => void;
  onSaveFilter?: () => void;
  onFinish?: (values: Record<string, unknown>, filterType?: FilterType) => void;
}

function FilterButton({ form, isDisabled, ...handlers }: Props) {
  const location = useLocation();
  const zustandFilter = useFilterStore();

  const savedFilter = get_current_page_filter(location.pathname);
  const filterType = zustandFilter.getCurrentFilterType(location.pathname);

  const saveStyle: CSSProperties = {
    backgroundColor: filterColor.saved,
    borderColor: filterColor.saved,
    color: 'white'
  };

  const onLoadSavedFilter = () => {
    if (!savedFilter) {
      return message.error('No saved filter found.');
    }

    form.setFieldsValue(savedFilter);
    handlers.onFinish?.(savedFilter, 'saved');
  };

  if (filterType === 'local') {
    return (
      <>
        {savedFilter && (
          <Button
            disabled={isDisabled}
            style={saveStyle}
            onClick={onLoadSavedFilter}
            className="!flex justify-center items-center !rounded-md"
            icon={<SyncOutlined />}>
            Load Saved
          </Button>
        )}

        <Button
          disabled={isDisabled}
          style={
            !savedFilter ? saveStyle : { borderColor: filterColor.saved, color: filterColor.saved }
          }
          onClick={handlers.onSaveFilter}
          className="!flex justify-center items-center !rounded-md"
          icon={!savedFilter && <SaveOutlined />}>
          Save
        </Button>
      </>
    );
  }

  if (filterType === 'default' && savedFilter) {
    return (
      <>
        <Button
          disabled={isDisabled}
          type="primary"
          style={saveStyle}
          onClick={onLoadSavedFilter}
          className="!rounded-md text-base"
          icon={<SaveOutlined size={20} className="[&>svg]:m-0" />}>
          Load Saved
        </Button>

        <Button
          disabled={isDisabled}
          type="default"
          style={{ borderColor: filterColor.saved, color: filterColor.saved }}
          onClick={handlers.onSaveFilter}
          className="!rounded-md text-base"
          icon={<SaveOutlined size={20} className="[&>svg]:m-0" />}>
          Update
        </Button>
      </>
    );
  }

  if (filterType === 'saved' || savedFilter) {
    return (
      <>
        <Button
          disabled={isDisabled}
          type="primary"
          style={saveStyle}
          onClick={handlers.onSaveFilter}
          className="!rounded-md text-base"
          icon={<SaveOutlined size={20} className="[&>svg]:m-0" />}>
          Update Filter
        </Button>

        <Button
          disabled={isDisabled}
          type="default"
          onClick={handlers.onSaveReset}
          style={{ borderColor: filterColor.saved, color: filterColor.saved }}
          className="!flex justify-center items-center !rounded-md"
          icon={<DeleteOutlined size={20} className="[&>svg]:m-0" />}>
          Reset
        </Button>
      </>
    );
  }

  return (
    <Button
      disabled={isDisabled}
      type="primary"
      style={saveStyle}
      onClick={handlers.onSaveFilter}
      className="!rounded-md text-base"
      icon={<SaveOutlined size={20} className="[&>svg]:m-0" />}>
      Save
    </Button>
  );
}

export default FilterButton;
