import { getDateRangeFromSingle } from '@/utils/getDateRangeFromSingle';
import { get_products_lines_latest_price_qty_by_range } from '../products/queries';
import { LineType } from '../report/enums';
import ProductsDB from '@/store/localstorage/ProductsDB';
import { getCategoryById, getProductById, getUnit } from '..';
import { IProductDetails, IUnits } from '@/services/products/types';
import { IProductRow, IWooCommerceProduct } from './types';

export async function fetchSoldProductByRange(range: { startDate: string; endDate: string }) {
  const product = await get_products_lines_latest_price_qty_by_range(range, [LineType.SALE]);
  const uniqueProductIds = Array.from(new Set(product.map((p) => p.productId)).values());

  await ProductsDB.addProductsIfAbsent(uniqueProductIds);
  const productList: IProductDetails[] = [];

  for (const productId of uniqueProductIds) {
    const product = await getProductById(productId);
    productList.push(product);
  }

  return productList;
}

export async function fetchSoldProductByDate(date: string) {
  const range = getDateRangeFromSingle(date);
  return fetchSoldProductByRange(range);
}

export async function formatToGrid(products: IProductDetails[]) {
  const rows: IProductRow[] = [];
  const cacheCategory: Record<number, string> = {};
  const cacheUnit: Record<number, IUnits> = {};

  for (let i = 0; i < products.length; i++) {
    const product = products[i];
    const defaultUnit = product.productUnits.find((u) => u.isDefault);
    const currentUnitId = defaultUnit?.unitId;
    const categoryId = product.categoryId;

    if (currentUnitId) {
      const row: IProductRow = {
        id: Number(product.id),
        name: product.name?.trim(),
        image: '',
        imgId: product.imgId,
        sku: product.sku,
        limit_qty: 100,
        sell_price: 0,
        priority: i + 1,
        categoryId,
        categoryName: '',
        unitId: currentUnitId,
        unitName: '',
        baseUnitMultiplier: 1
      };

      if (categoryId) {
        if (cacheCategory[categoryId]) {
          row.categoryName = cacheCategory[categoryId];
        } else {
          const category = await getCategoryById(categoryId);
          cacheCategory[categoryId] = category.name;
          row.categoryName = category.name;
        }
      }

      let unit = cacheUnit[currentUnitId];
      if (!unit) {
        unit = await getUnit(currentUnitId);
        cacheUnit[currentUnitId] = unit;
      }

      row.unitName = unit.name;
      row.baseUnitMultiplier = unit.baseUnitMultiplier;

      rows.push(row);
    }
  }

  return { rows };
}

export async function formatCreatedProductToGrid(data: IWooCommerceProduct[]) {
  const rows: IProductRow[] = [];
  const cacheCategory: Record<number, string> = {};
  const cacheUnit: Record<number, IUnits> = {};

  const uniqueProductIds = Array.from(new Set(data.map((p) => p.erpProdId)));
  await ProductsDB.addProductsIfAbsent(uniqueProductIds);
  for (let i = 0; i < data.length; i++) {
    const item = data[i];
    const product = await getProductById(item.erpProdId);

    const row: IProductRow = {
      id: item.erpProdId,
      name: product.name?.trim(),
      image: '',
      imgId: product.imgId,
      sku: product.sku,
      limit_qty: item.orderQtyLimit ?? 0,
      sell_price: item.sellPrice ? parseFloat(item.sellPrice) : 0,
      priority: i + 1,
      categoryId: product.categoryId,
      categoryName: '',
      unitId: item.unitId,
      unitName: '',
      baseUnitMultiplier: 1
    };

    let unit = cacheUnit[item.unitId];
    if (!unit) {
      unit = await getUnit(item.unitId);
      cacheUnit[item.unitId] = unit;
    }

    row.unitName = unit.name;
    row.baseUnitMultiplier = unit.baseUnitMultiplier;

    let category = cacheCategory[product.categoryId];
    if (!category) {
      const detail = await getCategoryById(product.categoryId);
      cacheCategory[product.categoryId] = detail.name;
      category = detail.name;
    }

    row.categoryName = category;

    rows.push(row);
  }

  return { rows };
}
