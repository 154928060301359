import { IFinancialYear } from '@/services/financial-year/types';

type EventMap = {
  FINANCIAL_YEAR_CHANGE: {
    selectedFinancialYear: IFinancialYear;
    defaultRange: [string, string];
    defaultSingleDate: string;
  };
  RESET_CURRENT_PAGE_SAVED_FILTERS: { reset: 'all' | 'current' };
};

type EventKey = keyof EventMap;

class EventEmitter {
  private static instance: EventEmitter;
  private constructor() {} // eslint-disable-line @typescript-eslint/no-empty-function

  static getInstance(): EventEmitter {
    if (!EventEmitter.instance) {
      EventEmitter.instance = new EventEmitter();
    }
    return EventEmitter.instance;
  }

  emit<K extends EventKey>(event: K, detail: EventMap[K]): void {
    const customEvent = new CustomEvent(event, { detail });
    window.dispatchEvent(customEvent);
  }

  on<K extends EventKey>(event: K, callback: (detail: EventMap[K]) => void): () => void {
    const listener = (e: Event): void => {
      if (e instanceof CustomEvent) {
        callback(e.detail);
      }
    };

    window.addEventListener(event as string, listener);

    // Return a cleanup function
    return () => {
      window.removeEventListener(event as string, listener);
    };
  }
}

const eventEmitter = EventEmitter.getInstance();
export default eventEmitter;
