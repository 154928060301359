import { Button, Divider, Form, Input, PageHeader, Switch, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useMutation } from '@tanstack/react-query';
import AppContent from '../../../../../components/Common/Content/Content';
import { checkAccess } from '../../../../../routes/acl';
import ReuseChannel from '../../../../channel/Reuse';
import { get_vendor_type_details } from '../../../../../services/vendor-types/queries';
import { update_vendor_type_mutation } from '../../../../../services/vendor-types/mutations';
import { IVendorType } from '../../../../../services/vendor-types/types';
import { VendorTypeSearch } from '../../../../../components/Common/VendorTypeSearch';
import { useFilterStore } from '@/store/zustand';
import { ListPage } from '@/constants/list.enum';

const EditVendorType = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { id } = useParams();
  const zustandFilter = useFilterStore();

  const getData = async () => {
    if (id) {
      const data = await get_vendor_type_details(parseInt(id));
      console.log(data);
      form.setFieldsValue({
        name: data.name,
        hidden: data.hidden,
        vendorTypeId: data?.vendorTypeId
      });
      setIsLoading(false);
    }
  };

  const breadcrumbItems = [
    {
      label: 'Supplier Category',
      link: '/vendor-type'
    },
    {
      label: 'Details',
      link: `/vendor-type/${id}`
    }
  ];

  const updateVendorTypeMutation = useMutation(update_vendor_type_mutation, {
    onSuccess: (data) => {
      setIsLoading(false);
      message.success('Supplier Category updated successfully');
      zustandFilter.removeData(ListPage.VENDOR_TYPE);
      navigate('/vendor-type');
    },
    onError: () => {
      setIsLoading(false);
      message.error('message');
    }
  });

  const onFinish = (values: IVendorType) => {
    setIsLoading(true);
    if (id) values.id = parseInt(id);
    updateVendorTypeMutation.mutate(values);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <AppContent breadcrumbItems={breadcrumbItems}>
        <Form
          form={form}
          initialValues={{}}
          onFinish={onFinish}
          disabled={isLoading}
          layout="vertical"
          validateTrigger={'onChange'}
          autoComplete="off">
          <PageHeader
            title="Category Information"
            style={{
              padding: '8px 0px'
            }}
          />
          <div className={'grid grid-cols-1 gap-3 mb-3 sm:grid-cols-3 gap-5 mb-5'}>
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: 'Please add name!' }]}>
              <Input />
            </Form.Item>

            <Form.Item label="Hidden?" name="hidden" valuePropName="checked">
              <Switch />
            </Form.Item>
          </div>
          <div className="flex justify-end mt-5">
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={isLoading}>
                Submit
              </Button>
            </Form.Item>
            <Button
              type="default"
              className="ml-5"
              htmlType="button"
              onClick={() => navigate('/vendor-type')}>
              Cancel
            </Button>
          </div>
        </Form>
        <Divider />
        {checkAccess('READ_CHANNEL') && (
          <ReuseChannel
            slug={`vendor_type_${id}`}
            withReferenceandId={{
              reference: 'vendor_type',
              referenceId: id ? id.toString() : ''
            }}
          />
        )}
      </AppContent>
    </div>
  );
};

export default EditVendorType;
