import moment from 'moment';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { Button, Form, InputNumber, message, PageHeader, Spin } from 'antd';

import { AccountsTypeSearch } from '@/components/Common/AccountsTypeSearch';
import AppContent from '@/components/Common/Content/Content';
import { get_current_financial_year } from '@/services/financial-year/queries';
import { get_account_all_parents, get_account_history_filter } from '@/services/accounts/queries';
import { IAccountHistoryResponse, IAccountTypeResponseData } from '@/services/accounts/types';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';
import { create_account_opening } from '@/services/accounts/mutations';
import getErrorMessage from '@/utils/getError';
import { useFilterStore } from '@/store/zustand';
import { ListPage } from '@/constants/list.enum';

function CreateFinancialHistory() {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const [isDisabled, setIsDisabled] = useState(false);
  const [newCurrentBalance, setNewCurrentBalance] = useState(0);
  const [selectedAccount, setSelectedAccount] = useState<number | null>(null);
  const [affectedAccounts, setAffectedAccounts] = useState<{ id: number; name: string }[]>([]);
  const [selectedAccountDetails, setSelectedAccountDetails] = useState<IAccountTypeResponseData>();

  const zustandFilter = useFilterStore();

  const { data: currentFinancialYear } = useQuery(
    ['current_financial_year'],
    async () => {
      setIsLoading(true);
      const { startDate } = await get_current_financial_year();
      const nextDayofCurrent = moment(startDate).add(1, 'day').startOf('day').toISOString();
      const nextDayEndofCurrent = moment(startDate).add(1, 'day').endOf('day').toISOString();

      return { startDate: nextDayofCurrent, endDate: nextDayEndofCurrent };
    },
    {
      onSuccess: () => {
        setIsLoading(false);
      }
    }
  );

  async function handleAccountSelect(accountId: number) {
    try {
      setIsLoading(true);
      setSelectedAccount(accountId);
      form.setFieldValue('newBalance', undefined);

      if (!accountId) return;

      if (!currentFinancialYear) {
        setIsDisabled(true);
        return message.error('Failed to get starting Date of account history');
      }

      const filter = `ids=${accountId}&ids=0&startDate=${currentFinancialYear.startDate}&endDate=${currentFinancialYear.endDate}`;
      const history = (await get_account_history_filter(filter)) as IAccountHistoryResponse[];

      const financialHistory = history.find((item) => item.historyType === 'financial_year');
      setIsDisabled(!financialHistory);

      if (!financialHistory) {
        return message.error('This account has no financial history');
      }

      const allAccounts = await get_account_all_parents(accountId);
      setAffectedAccounts(allAccounts.map((item) => ({ id: item.id, name: item.name })));

      form.setFieldsValue({
        historyId: financialHistory.id,
        historyBalance: financialHistory.balance
      });
    } catch (error) {
      message.error(getErrorMessage(error));
    } finally {
      setIsLoading(false);
    }
  }

  async function handleFormSubmit() {
    try {
      setIsLoading(true);
      const values = await form.validateFields();
      const response = await create_account_opening({
        historyId: values.historyId,
        newBalance: values.newBalance
      });

      zustandFilter.resetState(ListPage.ACCOUNTS_OPENING);
      message.success('Account Opening created successfully');
      navigate(`/accounts/opening/${response.id}`);
    } catch (error) {
      message.error(getErrorMessage(error));
    } finally {
      setIsLoading(false);
    }
  }

  function handleHistoryBalanceChange(newHistoryBalance = 0) {
    const historyBalance = form.getFieldValue('historyBalance') || 0;
    const currentBalance = selectedAccountDetails?.balance || 0;

    const updatedBalance = currentBalance - (historyBalance - newHistoryBalance);
    setNewCurrentBalance(updatedBalance);
  }

  return (
    <Spin spinning={isLoading}>
      <AppContent
        breadcrumbItems={[
          { label: 'Accounts', link: '/accounts' },
          { label: 'Accounts Opening', link: '/accounts/opening' },
          { label: 'Create', link: '/accounts/opening/new' }
        ]}>
        <Form
          form={form}
          onFinish={handleFormSubmit}
          layout="vertical"
          autoComplete="off"
          disabled={isLoading}
          validateTrigger={'onChange'}>
          <PageHeader title="Account Information" style={{ padding: '8px 0px' }} />
          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-4">
            <Form.Item label="History" name="historyId" hidden>
              <InputNumber controls={false} />
            </Form.Item>

            <AccountsTypeSearch
              isAll={false}
              required
              setSelectedAccountDetails={setSelectedAccountDetails}
              formData={{ label: 'Account', formName: 'accountId' }}
              onSelect={handleAccountSelect}
            />

            <Form.Item label="History Balance" name="historyBalance" hidden={!selectedAccount}>
              <InputNumber
                controls={false}
                readOnly
                disabled
                addonBefore="Rs"
                className="!w-full"
                formatter={(value) => nepaliNumberFormatter(Number(value))}
              />
            </Form.Item>

            <Form.Item hidden={!selectedAccount} label="Current Balance">
              <InputNumber
                controls={false}
                readOnly
                disabled
                addonBefore="Rs"
                className="!w-full"
                formatter={(value) => nepaliNumberFormatter(Number(value))}
                value={selectedAccountDetails?.balance}
              />
            </Form.Item>

            <Form.Item
              label="New History Balance"
              name="newBalance"
              hidden={!selectedAccount}
              rules={[{ required: true, message: 'New Balance is required' }]}>
              <InputNumber
                controls={false}
                type="number"
                className="!w-full"
                addonBefore="Rs"
                onChange={(value: number) => handleHistoryBalanceChange(value)}
              />
            </Form.Item>

            <Form.Item
              label="New Current Balance"
              hidden={!selectedAccount}
              rules={[{ required: true, message: 'New Balance is required' }]}>
              <InputNumber
                controls={false}
                readOnly
                disabled
                addonBefore="Rs"
                className="!w-full"
                formatter={(value) => nepaliNumberFormatter(Number(value))}
                value={newCurrentBalance}
              />
            </Form.Item>
          </div>

          <div className="flex sm:justify-end gap-4 mt-8 flex-wrap">
            <Button type="primary" htmlType="submit" disabled={isDisabled}>
              Create Account Opening
            </Button>

            <Button onClick={() => navigate(-1)}>Back</Button>
          </div>
        </Form>

        {affectedAccounts.length > 0 && (
          <>
            <PageHeader title="Affected Accounts" style={{ padding: '2px 0px' }} />
            <p className="export-paragraph">
              The following accounts will be affected by this transaction:
            </p>

            <div className="flex flex-wrap items-center gap-4">
              {affectedAccounts.map((item) => (
                <Link
                  key={item.id}
                  to={`/accounts/detailed-ledger?accountId=${item.id}`}
                  style={{ border: '1px solid #c3c4c7' }}
                  className="px-4 py-2 rounded-md hover:bg-gray-50 cursor-pointer">
                  <span className="text-blue-500">{item.name}</span>
                </Link>
              ))}
            </div>
          </>
        )}
      </AppContent>
    </Spin>
  );
}

export default CreateFinancialHistory;
