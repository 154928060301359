import moment from 'moment';

import { Form } from 'antd';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { ColumnsType } from 'antd/lib/table';

import AppContent from '@/components/Common/Content/Content';
import CustomizeTable from '@/components/Common/CustomizeTable/CustomizeTable';
import TableFilter from '@/components/FliterTable';
import { CustomDatePresets } from '@/pages/sqlsource/report/utils/datePresets';
import { get_sell_manual_price } from '@/services/sell/queries';
import { ISellManualPriceTable, ISellManualPriceTableResponse } from '@/services/sell/types';

import { ConvertObjectToURL } from '@/utils/converturl';
import getGroupedData from '@/utils/getGroupedData';
import handlePagination from '@/utils/handlePagination';

import ProductsDB from '@/store/localstorage/ProductsDB';
import { get_product_list_ids } from '@/services/products/queries';
import LocationsDB from '@/store/localstorage/LocationsDB';
import { get_location_list } from '@/services/locations/queries';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';

import { DEFAULT_DATE_FORMAT } from '@/constants';
import LocationSearchV2 from '@/components/Common/CustomSearch/Location';

function SellManualPricePage() {
  const [form] = Form.useForm();
  const [isLoading, setIsloading] = useState(false);
  const [pagination, setPagination] = useState({ page: 1, size: 100 });
  const [sellManualPrice, setSellManualPrice] = useState<ISellManualPriceTableResponse>({
    total: 0,
    results: []
  });

  async function onSubmitFilter(filter: string) {
    const data = await getInfo(filter);
    setPagination({ page: 1, size: 100 });
    return data;
  }

  async function getLocationDetails(locationId: number) {
    let locationDetails = await LocationsDB.getLocation(locationId);
    if (!locationDetails) {
      const allLocations = await get_location_list();
      await LocationsDB.addLocations(allLocations.data.results);
      locationDetails = await LocationsDB.getLocation(locationId);
    }

    return locationDetails;
  }

  async function getInfo(filter = '') {
    try {
      setIsloading(true);
      const response = await get_sell_manual_price(filter);
      const sellManualPriceList = response.results as ISellManualPriceTable[];

      const absentProductIds: number[] = [];

      for (let i = 0; i < sellManualPriceList.length; i++) {
        const sellManualPrice = sellManualPriceList[i];
        const location = await getLocationDetails(sellManualPrice.__line__.locationId);
        const product = (await ProductsDB.getProduct(sellManualPrice.__line__.productId)) as any;

        if (!product) {
          absentProductIds.push(sellManualPrice.__line__.productId);
        }

        sellManualPriceList[i] = {
          ...sellManualPrice,
          location: location.name,
          product: product?.name,
          sn: (pagination.page - 1) * pagination.size + (i + 1)
        };
      }

      if (absentProductIds.length > 0) {
        const allProducts = await get_product_list_ids([...new Set(absentProductIds)]);
        await ProductsDB.addProducts(allProducts.data.results);
      }

      for (let i = 0; i < sellManualPriceList.length; i++) {
        const sellManualPrice = sellManualPriceList[i];
        const product = (await ProductsDB.getProduct(sellManualPrice.__line__.productId)) as any;

        sellManualPriceList[i] = {
          ...sellManualPrice,
          product: product?.name
        };
      }

      // Calculate row for serial number and sell for same sell id
      const groupedData = getGroupedData(sellManualPriceList, 'sellId'); // return object
      // Add row to 1st element and make other of same group 0
      Object.keys(groupedData).forEach((key) => {
        groupedData[key].forEach((element, index) => {
          if (index === 0) {
            element.row = groupedData[key].length;
          } else {
            element.row = 0;
          }
        });
      });

      const updatedResponse = { ...response, results: sellManualPriceList };
      setSellManualPrice(updatedResponse);
      return updatedResponse;
    } catch (error) {
      console.log(error);
    } finally {
      setIsloading(false);
    }
  }

  const columns: ColumnsType<ISellManualPriceTable> = [
    {
      title: 'S.N.',
      width: 3,
      key: 'sn',
      dataIndex: 'sn',
      onCell: (record) => {
        return { rowSpan: record.row };
      }
    },
    {
      title: 'Sell Id',
      dataIndex: 'sellId',
      key: 'sellId',
      width: 4,
      onCell: (record) => {
        return { rowSpan: record.row };
      },
      render: (id: number) => <Link to={`/sell/${id}`}>{id}</Link>
    },
    {
      title: 'Product',
      dataIndex: 'product',
      key: 'product',
      width: 10
    },
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
      width: 10
    },
    {
      title: 'Original Price',
      dataIndex: 'originalPrice',
      key: 'originalPrice',
      width: 4,
      render: (text) => nepaliNumberFormatter(text)
    },
    {
      title: 'Manual Price',
      dataIndex: 'customPrice',
      key: 'customPrice',
      width: 4,
      render: (text) => nepaliNumberFormatter(text)
    },
    {
      title: 'Created At',
      width: 8,
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text) => moment(text).format(DEFAULT_DATE_FORMAT)
    }
  ];

  const onPagination = async (page = 1, size = 100, isSize = false) => {
    setIsloading(true);
    const { values, ...pagination } = handlePagination({
      formValues: form.getFieldsValue(),
      page,
      size,
      isSize
    });

    setPagination((prev) => {
      prev.page = pagination.page;
      if (isSize) prev.size = pagination.size;
      return prev;
    });

    const url = ConvertObjectToURL(values);
    return await getInfo(url);
  };

  return (
    <AppContent
      breadcrumbItems={[
        { label: 'Sell', link: '/sell' },
        { label: 'Manual Price', link: '/sell/manual-price' }
      ]}
      withfilter
      button={
        <TableFilter
          initial
          form={form}
          onInitialLoad={({ data, pagination }) => {
            setPagination(pagination);

            if (data) {
              setSellManualPrice(data);
              setIsloading(false);
            }
          }}
          onSubmit={onSubmitFilter}
          defaultValues={{
            dateCustom: CustomDatePresets.Today,
            skip: 0,
            count: 100,
            locationId: ''
          }}
          styleforbuttons={'flex justify-end items-center'}>
          <LocationSearchV2 hasParentFormItem={false} name="locationId" showAll />
        </TableFilter>
      }>
      <CustomizeTable
        form={form}
        columns={columns}
        data={sellManualPrice.results}
        usersLoading={isLoading}
        notshowPagination={true}
        customScroll={{ x: 1300, y: '75vh' }}
        paginationDatas={{
          page: pagination.page,
          total: sellManualPrice.total,
          size: pagination.size,
          onPagination,
          scrollToTop: true
        }}
        tableName={'sell-manual-price-list'}
      />
    </AppContent>
  );
}

export default SellManualPricePage;
