export const tableExportColumns = [
  { label: 'Date', dataIndex: 'date' },
  { label: 'Location', dataIndex: 'location' },
  { label: 'Purchase', dataIndex: 'userPurchaseTotal', isNumber: true },
  { label: 'Wastage', dataIndex: 'userWastageTotal', isNumber: true }
];

export const tableCurrentExportColumns = [
  { label: 'Location', dataIndex: 'location' },
  { label: 'Purchase', dataIndex: 'userPurchaseTotal', isNumber: true },
  { label: 'Wastage', dataIndex: 'userWastageTotal', isNumber: true }
];
