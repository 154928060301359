import AppContent from '@/components/Common/Content/Content';
import { PageHeader, Spin, Tabs, Alert } from 'antd';
import { useState } from 'react';
import SyncCategory from './SyncCategory';
import SyncMetadata from './SyncMetadata';
import UpdateProductQtyPrice from './UpdateProductQtyPrice';

function SyncLocalToWooCommerce() {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Spin spinning={isLoading}>
      <AppContent
        breadcrumbItems={[
          { label: 'WooCommerce', link: '/woo-commerce/products' },
          { label: 'Update' }
        ]}>
        <Alert
          message="Update Information"
          description={
            <ul style={{ paddingLeft: 20, margin: 0 }}>
              <li>
                <strong>Sync Category:</strong> Synchronizes product categories from the ERP system
                to WooCommerce.
              </li>
              <li>
                <strong>Update Qty and Price:</strong> Adjust product limits and selling prices in
                WooCommerce
              </li>
              <li>
                <strong>Update Product Details:</strong> Synchronizes product metadata (e.g. name,
                images, description, category, etc.) from the ERP system to WooCommerce.
              </li>
            </ul>
          }
          type="info"
          showIcon
          style={{ marginBottom: 16 }}
        />

        {/* Page Header */}
        <PageHeader title="Sync Local To WooCommerce" style={{ padding: '8px 0' }} />

        {/* Tabs */}
        <Tabs defaultActiveKey="sync-metadata" destroyInactiveTabPane>
          <Tabs.TabPane tab="Update Product Details" key="sync-metadata">
            <SyncMetadata setIsLoading={setIsLoading} />
          </Tabs.TabPane>

          <Tabs.TabPane tab="Sync Category" key="category">
            <SyncCategory isLoading={isLoading} setIsLoading={setIsLoading} />
          </Tabs.TabPane>

          <Tabs.TabPane tab="Update Qty and Price" key="update-qty-price">
            <UpdateProductQtyPrice setIsLoading={setIsLoading} />
          </Tabs.TabPane>
        </Tabs>
      </AppContent>
    </Spin>
  );
}

export default SyncLocalToWooCommerce;
