import { StoreState } from './types';

export const parseFilter = (params: Record<string, unknown>) => {
  //  Get skip and count from params
  const skip = Number(params.skip) || 0;
  const count = Number(params.count) || 100;

  // Calculate page from skip and count
  const page = Math.floor(skip / count) + 1;
  const size = count;

  return { filter: params, pagination: { page, size } };
};

export const parseState = (value: string) => {
  const { state, version } = JSON.parse(value) as {
    state: StoreState;
    version: number;
  };

  return { ...state, version };
};

export function normalizePath(path: string): string {
  return path.replace(/\/+$/, ''); // Removes trailing slashes
}
