import moment from 'moment-timezone';

export function removeUndefined(obj: Record<string, unknown>): Record<string, unknown> {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (value !== undefined) {
      if (typeof value === 'object' && value !== null && !moment.isMoment(value)) {
        acc[key] = removeUndefined(value as Record<string, unknown>);
      } else {
        acc[key] = value;
      }
    }
    return acc;
  }, {} as Record<string, unknown>);
}

export function removeFalsy(obj: Record<string, unknown>): Record<string, unknown> {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (value !== undefined && value !== null && value !== '') {
      if (typeof value === 'object' && value !== null && !moment.isMoment(value)) {
        acc[key] = removeFalsy(value as Record<string, unknown>);
      } else {
        acc[key] = value;
      }
    }
    return acc;
  }, {} as Record<string, unknown>);
}
