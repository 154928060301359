import { useQuery } from '@tanstack/react-query';
import { Button, Form, InputNumber, PageHeader, Pagination, Spin } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import AppContent from '@/components/Common/Content/Content';
import { CustomModal } from '@/components/Common/CustomModal';
import TableFilter from '@/components/FliterTable';
import { download_report } from '@/services/report/queries';
import { get_vendor_details, get_vendor_ledger } from '@/services/users/queries';
import { convertLocalToUTCString } from '@/utils/convertToUTC';
import { ConvertObjectToURL } from '@/utils/converturl';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';

import TableCell from '@/components/Common/CustomizeTable/CustomCell';
import { IVendorLedger, IVendorLedgerResponse } from '@/services/users/types';
import { CustomDatePresets } from '../../../sqlsource/report/utils/datePresets';
import ReusablePurchaseView from '../../../purchases/view/ReusableView';
import { DEFAULT_DATE_FORMAT } from '@/constants';

const VendorLedger: React.FC<{ id?: string }> = ({ id = useParams().id }) => {
  const [form] = Form.useForm();
  const [data, setData] = useState<IVendorLedgerResponse>({ count: '0', data: [] });
  const [dataList, setDataList] = useState<any>();
  const [page, setPage] = useState<number>(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [purchaseID, setPurchaseID] = useState(0);
  const [totalInfo, setTotalinfo] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [size, setSize] = useState(10);

  const columns: ColumnsType<any> = [
    {
      title: 'ID',
      key: 'id',
      align: 'center',
      width: 20,
      render: (record: IVendorLedger) => {
        return <TableCell>{record.id}</TableCell>;
      }
    },
    {
      title: 'Reference Number',
      width: 40,
      key: 'reference_number',
      align: 'center',
      render: (record: IVendorLedger) => {
        return (
          <a
            color="black"
            onClick={() => {
              setIsModalOpen(true);
              setPurchaseID(record.id);
            }}>
            {record.reference_number}
          </a>
        );
      }
    },
    {
      title: 'Credit',
      align: 'right',
      width: 40,
      key: 'amount_paid',
      render: (record: IVendorLedger) => {
        return (
          <TableCell className="text-xs text-right">
            {nepaliNumberFormatter(parseFloat(record.amount_paid))}
          </TableCell>
        );
      }
    },
    {
      title: 'Debit',
      align: 'right',
      width: 40,
      key: 'total_amount',
      render: (record: IVendorLedger) => {
        return (
          <TableCell className="text-xs text-right">
            {nepaliNumberFormatter(parseFloat(record.total_amount))}
          </TableCell>
        );
      }
    },
    {
      title: 'Balance',
      width: 40,
      align: 'right',
      key: 'balance',
      render: (record: IVendorLedger) => {
        return (
          <TableCell className="text-xs text-right">
            {record.debit_accumulation < 0
              ? `${nepaliNumberFormatter(record.debit_accumulation).slice(1)} CR`
              : `${nepaliNumberFormatter(record.debit_accumulation)} DR`}
            {/* {record.amount_paid > record.total_amount
              ? `${nepaliNumberFormatter(record.amount_paid - record.total_amount)} CR `
              : `${nepaliNumberFormatter(record.total_amount - record.amount_paid)} DR`} */}
          </TableCell>
        );
      }
    },
    {
      title: 'Returned',
      key: 'amount_returned',
      align: 'right',
      width: 25,
      render: (record: IVendorLedger) => {
        return (
          <TableCell className="text-xs text-right">
            {record?.amount_returned && nepaliNumberFormatter(parseFloat(record.amount_returned))}
          </TableCell>
        );
      }
    },
    {
      title: 'Date',
      width: 50,
      align: 'center',
      key: 'date',
      render: (record: IVendorLedger) => {
        return (
          <TableCell className="text-center text-xs">
            {record.date && moment(record.date).local().format(DEFAULT_DATE_FORMAT)}
          </TableCell>
        );
      }
    }
  ];

  useQuery(['customer-details'], async () => {
    const response = await get_vendor_details(parseInt(id ? id : '0'));

    const data = {
      name: response.user.user.name,
      email: response.user.user.email,
      phone: response.user.user.phone,
      address: response.user.addresses[0].city
    };
    // console.log(data);
    setDataList(data);
    return response;
  });

  const getInfo = async (filter = '') => {
    setIsLoading(true);
    if (filter == '') {
      const value = form.getFieldsValue();
      delete value.dateCustom;
      filter = ConvertObjectToURL(value);
    }
    const response = await get_vendor_ledger(filter);

    for (let index = 0; index < response.data.length; index++) {
      response.data[index].key = index + 1;
      response.data[index].children = [
        {
          key: `${index} 1`,
          amount_paid: 0,
          total_amount: response.data[index].total_amount,
          debit_accumulation:
            index == 0
              ? parseFloat(response.data[index].total_amount)
              : response.data[index - 1].debit_accumulation +
                parseFloat(response.data[index].total_amount)
        },
        {
          key: `${index} 2`,
          amount_paid: response.data[index].amount_paid,
          total_amount: 0,
          debit_accumulation: response.data[index].debit_accumulation
        }
      ];
    }

    setData(response);
    calculateTotal(response);
    setIsLoading(false);
    return response;
  };

  function calculateTotal(response: IVendorLedgerResponse) {
    let tamount_paid = 0,
      tamount_returned = 0,
      ttotalamount = 0;

    for (let index = 0; index < response.data.length; index++) {
      tamount_paid += parseFloat(response.data[index].amount_paid);
      tamount_returned += parseFloat(response.data[index].amount_returned);
      ttotalamount += parseFloat(response.data[index].total_amount);
    }

    setTotalinfo({ tamount_paid, tamount_returned, ttotalamount });
  }

  const breadcrumbItems = [
    { label: 'Suppliers', link: '/users/vendors' },
    { label: 'Ledger View' }
  ];

  if (dataList && dataList.name) {
    breadcrumbItems.push({ label: dataList.name });
  }

  const handleExport = async () => {
    // console.log('Clicked handle export');
    const values = form.getFieldsValue();
    // console.log('Values', values);
    values.endDate = convertLocalToUTCString(values.endDate);
    values.startDate = convertLocalToUTCString(values.startDate);
    const response = await download_report(
      {
        startDate: values.startDate,
        endDate: values.endDate,
        page: values.page,
        size: values.size,
        customerId: values.customerId,
        paymentStatus: values.paymentStatus
      },
      20
    );
    window.open(response.data, '_blank');
  };
  // console.log('Datalist-->', data.data);

  const addPage = async (value: number, sizeVal = 0) => {
    //check total pages
    try {
      await form.validateFields();
      const allValues = form.getFieldsValue();
      delete allValues.startDateNepali;
      delete allValues.endDateNepali;
      delete allValues.dateCustom;
      allValues.endDate = convertLocalToUTCString(allValues.endDate);
      allValues.startDate = convertLocalToUTCString(allValues.startDate);
      if (sizeVal !== 0) {
        allValues.page = 1;
        allValues.size = sizeVal;
        setPage(1);
        setSize(sizeVal);
      } else {
        allValues.page = value;
        allValues.size = size;
        setPage(value);
      }
      return getInfo(ConvertObjectToURL(allValues));
    } catch (e: any) {
      console.log('validate error', e);
    }
  };

  return (
    <Spin spinning={isLoading}>
      <AppContent
        breadcrumbItems={breadcrumbItems}
        button={
          <TableFilter
            onSubmit={getInfo}
            initial={true}
            form={form}
            onInitialLoad={({ data, pagination }) => {
              setPage(pagination.page);
              setSize(pagination.size);

              if (data) {
                setData(data);
                calculateTotal(data);
                setIsLoading(false);
              }
            }}
            defaultValues={{
              // paymentStatus: 'all',
              dateCustom: [...CustomDatePresets.Today],
              vendorId: id,
              page: 1,
              size: 10
            }}
            // outsidestyle="flex items-end gap-2"
            styleforbuttons="flex items-center justify-end">
            <Form.Item label={'page'} name={['page']} hidden={true}>
              <InputNumber controls={false} />
            </Form.Item>
            <Form.Item label={'size'} name={['size']} hidden={true}>
              <InputNumber controls={false} />
            </Form.Item>
            <Form.Item label={'size'} name={['vendorId']} hidden={true}>
              <InputNumber controls={false} />
            </Form.Item>
          </TableFilter>
        }>
        <CustomModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          title={'Purchase View'}>
          <ReusablePurchaseView id={purchaseID} />
        </CustomModal>
        <PageHeader
          title={'Ledger Details'}
          style={{
            padding: '8px 0px'
          }}
        />

        <div className="flex justify-between items-center gap-2 mb-5">
          {/* <div className="text-sm font-bold">
            All time total Sales :{' '}
            {nepaliNumberFormatter(dataList?.totalSales ? dataList?.totalSales : 0)}
          </div> */}
          {/* <div>
            <div>Total: {data.count}</div>
            <Button
              icon={<ArrowLeftOutlined />}
              disabled={1 == page}
              onClick={() => addPage(false)}
            />
            <Button>
              {Math.ceil(parseInt(data.count) / 10) === 0 ? (
                <>0/0</>
              ) : (
                <>
                  {page}/{Math.ceil(parseInt(data.count) / 10)}
                </>
              )}
            </Button>
            <Button
              icon={<ArrowRightOutlined />}
              disabled={
                Math.ceil(parseInt(data.count) / 10) == page ||
                Math.ceil(parseInt(data.count) / 10) == 0
              }
              onClick={() => addPage(true)}
            />
          </div> */}
        </div>
        {/* <CustomizeTable notshowPagination={true} data={data.data} columns={columns} /> */}
        <Table
          pagination={false}
          // loading={isloading}
          scroll={{ x: 1000, y: '75vh' }}
          size="small"
          columns={columns}
          dataSource={data.data.length > 0 ? data.data : []}
          summary={(pageData) => {
            return (
              totalInfo && (
                <Table.Summary fixed>
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                    <Table.Summary.Cell index={1}></Table.Summary.Cell>
                    <Table.Summary.Cell index={2} className="text-xs text-right">
                      {nepaliNumberFormatter(totalInfo.tamount_paid)}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={3} className="text-xs text-right">
                      {nepaliNumberFormatter(totalInfo.ttotalamount)}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={4} className="text-xs text-right">
                      {nepaliNumberFormatter(totalInfo.tamount_returned)}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={5}> </Table.Summary.Cell>
                    <Table.Summary.Cell index={6} className="text-xs text-right">
                      {nepaliNumberFormatter(totalInfo.tamount_returned)}{' '}
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </Table.Summary>
              )
            );
          }}
        />
        <div className="flex justify-end mt-4">
          <Pagination
            current={page}
            total={parseFloat(data.count)}
            pageSize={size}
            pageSizeOptions={[10, 100, 500, parseFloat(data.count)]}
            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
            onChange={(pageNo, pageSize) => {
              // addPage(pageNo);
              if (pageSize == size) {
                //code for page change
                addPage(pageNo);
              } else {
                //code for size change
                addPage(pageNo, pageSize);
              }
            }}
          />
        </div>
        <div className="flex justify-end">
          <Button type="primary" onClick={handleExport}>
            Export
          </Button>
        </div>
      </AppContent>
    </Spin>
  );
};

export default VendorLedger;
