import { AxiosResponse } from 'axios';
import http from '../../utils/http.utils';
import {
  IFonepayCredentialsCreate,
  IFonepayCredentialsResponse,
  IFonepayCredentialsUpdate
} from './types';

export const create_fonepay_credentials_mutation = async (values: IFonepayCredentialsCreate) => {
  const response: AxiosResponse<IFonepayCredentialsResponse, any> = await http.store(
    'fone-pay/credentials',
    values
  );
  return response;
};

export const update_fonepay_credentials_mutation = async (values: IFonepayCredentialsUpdate) => {
  const response: AxiosResponse<IFonepayCredentialsResponse, any> = await http.update(
    'fone-pay/credentials/' + values.id,
    values
  );
  return response;
};
