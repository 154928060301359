import { AxiosResponse } from 'axios';
import http from '@/utils/http.utils';
import { IFeatureRequest, IFeatureRequestList } from './types';

export const get_feature_request_list = async (filter = '') => {
  const response: AxiosResponse<IFeatureRequestList> = await http.get(
    'support-feature-request/?' + filter
  );
  return response.data;
};

export const get_feature_request_detail = async (id: number) => {
  const response: AxiosResponse<IFeatureRequest> = await http.get('support-feature-request/' + id);
  return response.data;
};
