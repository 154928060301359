import { get_active_location_list, get_location_list_only } from '@/services/locations/queries';
import CustomSearch, { SelectProps } from '..';
import LocationsDB from '@/store/localstorage/LocationsDB';
import { ILocations } from '@/services/locations/types';

type MultipleProps =
  | {
      defaultValue?: number;
      isMultiple?: false;
      setSelected?: (selected?: ILocations) => void;
      onSelect?: (selected: number) => void;
    }
  | {
      defaultValue?: number[];
      isMultiple: true;
      setSelected?: (selected?: ILocations[]) => void;
      onSelect?: (selected: number[]) => void;
    };

type Props = SelectProps & MultipleProps & { showHidden?: boolean };

function LocationSearchV2({ showHidden = false, ...props }: Props) {
  async function searchActiveLocationOnly(skip = 0, count = 1000, value = '', filter = '') {
    const response = await get_active_location_list(skip, count, value, filter);
    return response.data.results;
  }

  return (
    <CustomSearch
      count={100}
      formFor="location"
      addCallback={LocationsDB.addLocations}
      dbSearchById={LocationsDB.getLocation}
      serverCallback={showHidden ? get_location_list_only : searchActiveLocationOnly}
      dbSearchCallback={
        showHidden ? LocationsDB.searchLocationByName : LocationsDB.searchActiveLocationByName
      }
      {...props}
    />
  );
}

export default LocationSearchV2;
