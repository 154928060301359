import { useQuery } from '@tanstack/react-query';
import { PageHeader, Button, Spin, Divider } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import AppContent from '@/components/Common/Content/Content';
import type { ColumnsType } from 'antd/es/table';
import {
  get_purchase_details,
  get_purchase_return_lines_details
} from '@/services/purchases/queries';
import { get_user_details, get_vendor_details } from '@/services/users/queries';

import { Ilocationdetails, IPurchaseDetails } from '@/services/purchases/types';
import { get_invoices_list } from '@/services/settings/queries';
import LocationsDB from '@/store/localstorage/LocationsDB';
import { get_location_details } from '@/services/locations/queries';
import ProductsDB from '@/store/localstorage/ProductsDB';
import { get_product_list_ids, get_units_list } from '@/services/products/queries';
import ReuseChannel from '../../channel/Reuse';
import UnitsDB from '@/store/localstorage/UnitsDB';
import CustomizeTable from '@/components/Common/CustomizeTable/CustomizeTable';
import { checkAccess } from '@/routes/acl';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';
import { convertUTCStringtoLocalString } from '@/utils/convertToUTC';
import CopyButton from '@/components/Common/CopyButton';
import { IProductType } from '@/services/products/types';
import { ICreateInvoiceResponse } from '@/services/settings/types';
import { getPurchasePrintData } from '@/components/Common/InvoicePrint/PurchaseInvoice/services';
import { IPurchaseInvoice } from '@/services/invoice/types';
import PurchaseInvoice from '@/components/Common/InvoicePrint/PurchaseInvoice/PurchaseInvoice';
import { CustomModal } from '@/components/Common/CustomModal';
import InvoicePrintButton from '@/components/Common/InvoicePrintButton/InvoicePrintButton';
import UsersDB from '@/store/localstorage/UsersDB';
import { DEFAULT_DATE_FORMAT } from '@/constants';
import { getImagesFromServer } from '@/services/upload/queries';
import { onPreviewImageURL } from '@/services/upload/services';
import { getUser } from '@/services';

const PurchaseDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [purchaseDetails, setPurchaseDetails] = useState<IPurchaseDetails>();
  const [locationDetails, setLocationDetails] = useState<Ilocationdetails>();
  const [vendorDetails, setVendorDetails] = useState<any>();
  const [isLoading, setIsloading] = useState<boolean>(true);
  const [invoiceLayouts, setinvoiceLayouts] = useState<any>();
  const [printCount, setPrintCount] = useState<number>(0);
  const [invoiceLists, setInvoiceLists] = useState<ICreateInvoiceResponse[]>([]);
  const [payList, setPayList] = useState<any[]>([]);
  const [purchaseReturnList, setPurchaseReturnList] = useState<any[]>([]);
  const [invoiceData, setInvoiceData] = useState<IPurchaseInvoice>(Object);
  const [openModalForInvoicePrint, setOpenModalForInvoicePrint] = useState<boolean>(false);
  useQuery(['invoiceLayouts'], async () => {
    const response = await get_invoices_list();
    setinvoiceLayouts(response.data[0]);
    setInvoiceLists(
      response.data.filter((curr: any) => {
        const content = JSON.parse(curr.content);
        return content.design == 'classic';
      })
    );
    return response;
  });

  const [invoiceMediaURL, setInvoiceMediaURL] = useState<string | null>(null);

  const breadcrumbItems = [
    { label: 'Purchases', link: '/purchase' },
    { label: 'Details', link: `/purchase/${id}` }
  ];

  const { refetch } = useQuery(
    ['purchase', id],
    async () => get_purchase_details((id && parseInt(id)) || 0),
    {
      retry: false,
      onSuccess: async (data: IPurchaseDetails) => {
        const mediaId = data.purchase.mediaId;
        if (mediaId) {
          const media = await getImagesFromServer([mediaId]);
          const currentMedia = media[0];
          setInvoiceMediaURL(currentMedia.url);
        }

        const purchaseCreatedBy = await getUser(data.purchase.createdBy);
        data.purchase.createdByName = purchaseCreatedBy.name;

        for (const payment of data.payments) {
          if (!payment.addedBy) continue;
          const addedByUser = await getUser(payment.addedBy);
          payment.addedByName = addedByUser.name;
        }

        for (const returnLine of data.returns) {
          if (!returnLine.createdBy) continue;
          const addedByUser = await getUser(returnLine.createdBy);
          returnLine.createdByName = addedByUser.name;
        }

        setPurchaseDetails(data);
        setPrintCount(data.purchase.printCount);
      }
    }
  );

  const columns: ColumnsType<{ name: string }> = [
    {
      title: 'S.N',
      key: 'sn',
      width: 30,
      render: (a, b, c) => <div>{c + 1}.</div>
    },
    {
      title: `${
        invoiceLayouts?.content.productLabel ? invoiceLayouts.content.productLabel : 'Product Name'
      }`,
      dataIndex: 'productName',
      width: 150,
      render: (text: string) => <div>{text}</div>
    },
    {
      title: `${
        invoiceLayouts?.content.unitPriceLabel
          ? invoiceLayouts.content.unitPriceLabel
          : 'Unit Price'
      }`,
      dataIndex: 'unitPrice',
      width: 100,
      render: (text: number) => <div>{nepaliNumberFormatter(text)}</div>
    },
    {
      title: 'HS Code',
      dataIndex: 'hsCode',
      width: 100,
      render: (text: string) => <div>{text || 'N/A'}</div>
    },
    {
      title: `${
        invoiceLayouts?.content.quantityLabel ? invoiceLayouts.content.quantityLabel : 'Quantity'
      }`,
      dataIndex: 'quantityWithUnit',
      width: 100,
      render: (text: number) => <div>{text}</div>
    },
    {
      title: 'Misc',
      dataIndex: 'misc',
      width: 100,
      render: (text: number) => <div>{text}</div>
    },
    {
      title: `${
        invoiceLayouts?.content.discountLabel ? invoiceLayouts.content.discountLabel : 'Discount'
      }`,
      dataIndex: 'discount',
      width: 100,
      render: (text: number) => <div>{nepaliNumberFormatter(text)}</div>
    },
    {
      title: `${'VAT'}`,
      dataIndex: 'vat',
      width: 100,
      render: (text: number) => <div>{nepaliNumberFormatter(text)}</div>
    },
    {
      title: `${
        invoiceLayouts?.content.totalLabel ? invoiceLayouts.content.totalLabel : 'TotalAmount'
      }`,

      dataIndex: 'totalAmount',
      width: 100,
      render: (text: number) => <div>{nepaliNumberFormatter(text)}</div>
    }
  ];

  const columnspay: ColumnsType<{ name: string }> = [
    {
      title: `S.N`,
      key: 'sn',
      width: 30,
      render: (a, b, c) => <div>{c + 1}.</div>
    },
    {
      title: 'Date',
      dataIndex: 'date',
      width: 100,
      render: (text: string) => (
        <div>{convertUTCStringtoLocalString(text, DEFAULT_DATE_FORMAT)}</div>
      )
    },
    // {
    //   title: 'Reference No',
    //   dataIndex: 'reference',
    //   width: 100,
    //   render: (text: string) => <div>{text}</div>
    // },
    {
      title: 'Amount',
      dataIndex: 'amount',
      width: 100,
      render: (text: number) => <div>{nepaliNumberFormatter(text)}</div>
    },
    {
      title: 'Payment Mode',
      dataIndex: 'paymentMethod',
      width: 100,
      render: (text: string) => <div>{text}</div>
    },
    {
      title: 'Payment Note',
      dataIndex: 'note',
      width: 100,
      render: (text: string) => <div>{text}</div>
    },
    {
      title: 'Added By',
      dataIndex: 'addedByName',
      width: 100,
      render: (text: string) => <div>{text}</div>
    }
  ];

  const columnsPurchaseReturn: ColumnsType<{ name: string }> = [
    {
      title: 'S.N',
      key: 'sn',
      width: 50,
      render: (a, b, c) => <div>{c + 1}.</div>
    },
    {
      title: 'Date',
      dataIndex: 'date',
      width: 100,
      render: (text: string) => (
        <div>{convertUTCStringtoLocalString(text, DEFAULT_DATE_FORMAT)}</div>
      )
    },

    {
      title: 'Amount',
      dataIndex: 'totalAmount',
      width: 100,
      render: (text: number) => <div>{nepaliNumberFormatter(text)}</div>
    },

    {
      title: 'Tax',
      dataIndex: 'totalTax',
      width: 100,
      render: (text: number) => <div>{nepaliNumberFormatter(text)}</div>
    },

    {
      title: 'Return Note',
      dataIndex: 'note',
      width: 100,
      render: (text: string) => <div>{text}</div>
    },
    {
      title: 'Return By',
      dataIndex: 'createdByName',
      width: 100,
      render: (text: string) => <div>{text}</div>
    }
  ];

  const fetchData = async () => {
    if (purchaseDetails?.purchase.locationId) {
      let locData: any = await LocationsDB.getLocation(purchaseDetails.purchase.locationId);
      if (!locData) {
        locData = await get_location_details(purchaseDetails.purchase.locationId);
        LocationsDB.addLocations([locData]);
      }
      const searchProducts: any = {};
      for (let index = 0; index < purchaseDetails.lines.length; index++) {
        const product: any = await ProductsDB.getProduct(purchaseDetails.lines[index].productId);
        if (!product) {
          // product = await get_product_details(purchaseDetails.lines[index].productId);
          // await ProductsDB.addProducts([product]);
          if (purchaseDetails.lines[index].productId in searchProducts) {
            searchProducts[purchaseDetails.lines[index].productId] = [
              ...searchProducts[purchaseDetails.lines[index].productId],
              index
            ];
          } else {
            searchProducts[purchaseDetails.lines[index].productId] = [index];
          }
        } else {
          purchaseDetails.lines[index].productName = product.name;
        }

        let findUnit: any = await UnitsDB.getUnit(purchaseDetails.lines[index].unitId);
        if (!findUnit) {
          const allUnits = await get_units_list();
          await UnitsDB.addUnits(allUnits);
          findUnit = await UnitsDB.getUnit(purchaseDetails.lines[index].unitId);
        }
        // purchaseDetails.lines[index].unitName = findUnit.name;
        purchaseDetails.lines[
          index
        ].quantityWithUnit = `${purchaseDetails.lines[index].quantity} ${findUnit.shortName}`;
      }
      const searchProductslength = Object.entries(searchProducts).length;
      if (searchProductslength > 0) {
        const productsresponse = await get_product_list_ids([...Object.keys(searchProducts)]);
        for (const key in searchProducts) {
          const findproduct = productsresponse?.data?.results.find(
            (currProduct: IProductType) => currProduct.id == key
          );
          if (findproduct) {
            for (let i = 0; i < searchProducts[key].length; i++) {
              purchaseDetails.lines[searchProducts[key][i]].productName = findproduct.name;
            }
            await ProductsDB.addProducts([findproduct]);
          }
        }
      }

      const searchProductsReturn: any = {};
      for (let index = 0; index < purchaseDetails.returnLines.length; index++) {
        const product: any = await ProductsDB.getProduct(
          purchaseDetails.returnLines[index].productId
        );
        if (!product) {
          if (purchaseDetails.returnLines[index].productId in searchProductsReturn) {
            searchProductsReturn[purchaseDetails.returnLines[index].productId] = [
              ...searchProductsReturn[purchaseDetails.returnLines[index].productId],
              index
            ];
          } else {
            searchProductsReturn[purchaseDetails.returnLines[index].productId] = [index];
          }
        } else {
          purchaseDetails.returnLines[index].productName = product.name;
        }

        let findUnit: any = await UnitsDB.getUnit(purchaseDetails.returnLines[index].unitId);
        if (!findUnit) {
          const allUnits = await get_units_list();
          await UnitsDB.addUnits(allUnits);
          findUnit = await UnitsDB.getUnit(purchaseDetails.returnLines[index].unitId);
        }
        // purchaseDetails.lines[index].unitName = findUnit.name;
        purchaseDetails.returnLines[
          index
        ].quantityWithUnit = `${purchaseDetails.returnLines[index].quantity} ${findUnit.shortName}`;
      }

      const searchProductsReturnslength = Object.entries(searchProductsReturn).length;

      if (searchProductsReturnslength > 0) {
        const productsresponse = await get_product_list_ids([...Object.keys(searchProductsReturn)]);
        for (const key in searchProductsReturn) {
          const findproduct = productsresponse?.data?.results.find(
            (currProduct: IProductType) => currProduct.id == key
          );
          if (findproduct) {
            for (let i = 0; i < searchProductsReturn[key].length; i++) {
              purchaseDetails.returnLines[searchProductsReturn[key][i]].productName =
                findproduct.name;
            }
            await ProductsDB.addProducts([findproduct]);
          }
        }
      }

      if (purchaseDetails?.purchase.agentId) {
        const agentInfo = await UsersDB.getUser(purchaseDetails?.purchase.agentId);
        if (agentInfo) {
          purchaseDetails.purchase.agentName = agentInfo.name;
          purchaseDetails.purchase.agentPhone = agentInfo.phone;
        } else {
          const agentInfo = await get_user_details(purchaseDetails?.purchase.agentId);
          await UsersDB.addUsers([agentInfo.user]);
          purchaseDetails.purchase.agentName = agentInfo.user.name;
          purchaseDetails.purchase.agentPhone = agentInfo.user.phone;
        }
      }

      setPurchaseDetails(purchaseDetails);
      setPayList(purchaseDetails.payments);
      setPurchaseReturnList(purchaseDetails.returns);
      setLocationDetails(locData);
      const venData = await get_vendor_details(purchaseDetails?.purchase.vendorId);
      setVendorDetails(venData);
      setIsloading(false);
    }
    const purchase_return = await get_purchase_return_lines_details(
      purchaseDetails?.purchase.id || 0
    );
  };
  useEffect(() => {
    if (purchaseDetails) {
      fetchData();
    }
  }, [purchaseDetails]);

  const generateInvoiceForPrint = async (curr: any) => {
    if (purchaseDetails) {
      setIsloading(true);
      const invoiceModalData = await getPurchasePrintData(
        purchaseDetails.purchase.id,
        purchaseDetails.purchase.locationId,
        curr
      );
      setIsloading(false);

      setInvoiceData(invoiceModalData);

      setOpenModalForInvoicePrint(true);
    }
  };

  const handleInvoicePrintModalClose = () => {
    setOpenModalForInvoicePrint(false);
    refetch();
  };

  return (
    <Spin spinning={isLoading}>
      <CustomModal
        footer={false}
        isModalOpen={openModalForInvoicePrint}
        setIsModalOpen={setOpenModalForInvoicePrint}
        title="Purchase Invoice Print">
        <PurchaseInvoice
          purchaseDetails={invoiceData.purchaseDetails}
          vendorDetails={invoiceData.vendorDetails}
          lines={invoiceData.lines}
          locationDetails={invoiceData.locationDetails}
          invoiceLayouts={invoiceData.invoiceLayouts}
          handleModalClose={handleInvoicePrintModalClose}
        />
      </CustomModal>
      <AppContent breadcrumbItems={breadcrumbItems}>
        <Spin spinning={isLoading}>
          <PageHeader
            title="Purchase Information"
            style={{
              padding: '8px 0px'
            }}
          />

          <div className="grid grid-cols-1 md:grid-cols-3 gap-5 mb-5">
            <div>
              <PageHeader
                subTitle="Supplier"
                style={{
                  padding: '8px 0px'
                }}
              />
              {vendorDetails ? (
                <>
                  <div style={{ color: 'black' }}>
                    {vendorDetails?.user?.user?.name ? vendorDetails.user.user.name : ''}
                    {`${
                      vendorDetails?.user?.user?.phone ? `,${vendorDetails.user.user.phone}` : ''
                    } ${
                      vendorDetails?.user?.user?.email ? `,${vendorDetails.user.user.email}` : ''
                    }`}
                  </div>
                </>
              ) : (
                <></>
              )}
              {purchaseDetails && (
                <>
                  <div style={{ color: 'name' }}>
                    Buyer Type : <b>{purchaseDetails.purchase.purchaseBuyerType}</b>
                  </div>
                  <div style={{ color: 'name' }}>
                    Purchase Type : <b>{purchaseDetails.purchase.purchaseType}</b>
                  </div>
                </>
              )}
            </div>
            <div>
              <PageHeader
                subTitle="Location"
                style={{
                  padding: '8px 0px'
                }}
              />
              {locationDetails ? (
                <>
                  <div style={{ color: 'black' }}>
                    {locationDetails?.name ? locationDetails.name : ''}

                    {`${locationDetails?.zip ? `${locationDetails.zip},` : ''} ${
                      locationDetails?.address ? `${locationDetails.address},` : ''
                    } ${locationDetails?.city ? `${locationDetails.city},` : ''} ${
                      locationDetails?.country ? `${locationDetails.country}` : ''
                    }`}

                    {`${locationDetails?.phone ? `${locationDetails.phone},` : ''} ${
                      locationDetails?.email ? `${locationDetails.email}` : ''
                    }`}
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
            {purchaseDetails?.purchase.agentName && (
              <div>
                <PageHeader
                  subTitle="Agent"
                  style={{
                    padding: '8px 0px'
                  }}
                />
                {purchaseDetails?.purchase.agentName ? (
                  <>
                    <div style={{ color: 'black' }}>
                      {purchaseDetails?.purchase.agentName}, {purchaseDetails?.purchase.agentPhone}
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            )}

            <div>
              <PageHeader
                subTitle="Details"
                style={{
                  padding: '8px 0px'
                }}
              />
              {purchaseDetails ? (
                <>
                  {purchaseDetails.purchase.invoiceNumber ? (
                    <span className="block">
                      Invoice No: {purchaseDetails.purchase.invoiceNumber}{' '}
                      <CopyButton text={purchaseDetails.purchase.invoiceNumber} />
                    </span>
                  ) : (
                    <></>
                  )}

                  <div style={{ color: 'black' }}>
                    {purchaseDetails.purchase.financialReference ? (
                      <span className="block">
                        Financial Reference : {purchaseDetails.purchase.financialReference}{' '}
                        <CopyButton text={purchaseDetails.purchase.financialReference} />
                      </span>
                    ) : (
                      <></>
                    )}

                    <span className="block">
                      Created At:{' '}
                      {convertUTCStringtoLocalString(
                        purchaseDetails.purchase.createdAt,
                        'YYYY-MM-DD'
                      )}
                    </span>

                    <span className="block">
                      Created By: {purchaseDetails.purchase?.createdByName}
                    </span>
                  </div>
                </>
              ) : (
                <></>
              )}

              {invoiceMediaURL && (
                <div
                  onClick={() => onPreviewImageURL(invoiceMediaURL)}
                  className="cursor-pointer underline hover:text-blue-500">
                  View Invoice
                </div>
              )}
            </div>
          </div>
          <CustomizeTable
            columns={columns}
            data={purchaseDetails?.lines ? purchaseDetails.lines : []}
            notshowPagination={true}
            customScroll={{ x: 1300 }}
          />
          {payList.length > 0 && (
            <>
              <Divider />
              <PageHeader subTitle="Payment Info" style={{ padding: 0 }} />
              <CustomizeTable columns={columnspay} notshowPagination={true} data={payList} />
            </>
          )}

          {purchaseReturnList.length > 0 && (
            <>
              <PageHeader title="Purchase Return Info" style={{ padding: '8px 0 0' }} />

              <div>
                <PageHeader subTitle="Return Products" style={{ padding: 0 }} />
                <CustomizeTable
                  columns={columns}
                  data={purchaseDetails?.returnLines ? purchaseDetails.returnLines : []}
                  notshowPagination={true}
                  customScroll={{ x: 1300 }}
                />
              </div>

              <div className="mt-4">
                <PageHeader subTitle="Return Info" style={{ padding: 0 }} />
                <CustomizeTable
                  customScroll={{ x: 1000 }}
                  columns={columnsPurchaseReturn}
                  notshowPagination={true}
                  data={purchaseReturnList}
                />
              </div>
            </>
          )}
          <div className="grid grid-cols-3 gap-5 my-5">
            <div>
              {purchaseDetails ? (
                <div style={{ color: 'black' }}>
                  <span className="block">
                    Total Amount : {nepaliNumberFormatter(purchaseDetails.purchase.totalAmount)}
                  </span>
                  <span className="block">
                    Total Tax : {nepaliNumberFormatter(purchaseDetails.purchase.totalVat)}
                  </span>
                  <span className="block">
                    Manual Discount :{' '}
                    {nepaliNumberFormatter(purchaseDetails.purchase.manualDiscount)}
                  </span>
                  <span className="block">
                    Amount Returned:{' '}
                    {nepaliNumberFormatter(purchaseDetails.purchase.amountReturned as number)}
                  </span>
                  <span className="block">
                    Shipping Amount:{' '}
                    {nepaliNumberFormatter(
                      purchaseDetails.purchase.shippingAmount -
                        purchaseDetails.purchase.shippingTax!
                    )}
                  </span>
                  <span className="block">
                    Labour Cost : {nepaliNumberFormatter(purchaseDetails.purchase.labourCost!)}
                  </span>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>

          <div className="flex justify-end items-center gap-5 mt-5">
            <span className="font-bold">Print Count : {printCount}</span>
            <div
              style={{
                width: '3rem',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
              <InvoicePrintButton
                getInvoice={(curr) => {
                  generateInvoiceForPrint(curr);
                }}
              />
              {/* <ActionDropdown
                trigger={'click'}
                insideaction={true}
                menu={
                  <Menu
                    items={invoiceLists?.map((curr, ind) => {
                      return {
                        key: ind,
                        label: (
                          <Tooltip title="Print receipt" color="blue">
                            <div className="text-center">{curr.name}</div>
                          </Tooltip>
                        ),
                        onClick: async () => {
                          generateInvoiceForPrint(curr);
                        }
                      };
                    })}
                  />
                }
              /> */}
            </div>
            {/* <Button
              type="primary"
              onClick={() => {
                createPDF({
                  invoiceLayouts,
                  purchaseDetails,
                  vendorDetails,
                  locationDetails,
                  columns: [
                    `${invoiceLayouts ? invoiceLayouts.content.productLabel : 'Product'}`,
                    `${invoiceLayouts ? invoiceLayouts.content.unitPriceLabel : 'unit price'}`,
                    'unitPrice',
                    `${invoiceLayouts ? invoiceLayouts.content.quantityLabel : 'quantity'}`,
                    'Misc',
                    `${invoiceLayouts ? invoiceLayouts.content.discountLabel : 'discount'}`,
                    `${invoiceLayouts ? invoiceLayouts.content.totalLabel : 'total'}`
                  ],
                  columnspay: ['Date', 'Reference No', 'Amount', 'Payment Mode', 'Note']
                });
              }}>
              Print
            </Button> */}
            <Button type="default" onClick={() => navigate('/purchase')}>
              Back
            </Button>
          </div>
          <Divider />
          {checkAccess('READ_CHANNEL') && (
            <ReuseChannel
              slug={`purchase_${id}`}
              fromSellorPurchaseId={vendorDetails?.vendor.userId as string}
              withReferenceandId={{ reference: 'sell_order', referenceId: id || 0 }}
            />
          )}
        </Spin>
      </AppContent>
    </Spin>
  );
};

export default PurchaseDetails;
