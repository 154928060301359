import { AxiosResponse } from 'axios';
import http from '@/utils/http.utils';
import {
  IFeatureReqDevMultiPayload,
  IFeatureRequest,
  IFeatureRequestDevPayload,
  IFeatureRequestPayload
} from './types';

export const create_feature_request_mutation = async (payload: IFeatureRequestPayload) => {
  const response: AxiosResponse<IFeatureRequest> = await http.store(
    'support-feature-request',
    payload
  );
  return response.data;
};

export const update_feature_request_user_mutation = async (
  id: number,
  payload: IFeatureRequestPayload
) => {
  const response: AxiosResponse<IFeatureRequest> = await http.update(
    'support-feature-request/user/' + id,
    payload
  );
  return response.data;
};

export const update_feature_request_dev_mutation = async (
  id: number,
  payload: IFeatureRequestDevPayload
) => {
  const response: AxiosResponse<IFeatureRequest> = await http.update(
    'support-feature-request/dev/' + id,
    payload
  );
  return response.data;
};

export const update_feature_request_dev_multi_mutation = async (
  payload: IFeatureReqDevMultiPayload[]
) => {
  const response: AxiosResponse<IFeatureRequest[]> = await http.update(
    'support-feature-request/dev-multi/',
    { features: payload }
  );
  return response.data;
};
