import { AxiosResponse } from 'axios';
import http from '@/utils/http.utils';
import {
  IWooCategory,
  IWooCategoryResponse,
  IWooCommerceCustomer,
  IWooCommerceCustomerResponse,
  IWooCommerceOrder,
  IWooCommerceOrderResponse,
  IWooCommerceProduct,
  IWooCommerceProductsResponse
} from './types';
import { getDataForIdsByDynamicField } from '@/utils/queries.utils';

export const get_woo_products_list = async (filter: string) => {
  const response: AxiosResponse<IWooCommerceProductsResponse> = await http.get(
    'woo-commerce-products/?' + filter
  );
  return response.data;
};

export const get_woo_products_list_only = async (page = 1, count = 0, value = '', filter = '') => {
  const response: AxiosResponse<IWooCommerceProductsResponse> = await http.get(
    'woo-commerce-products/?' + filter,
    { page, count, value }
  );
  return response.data.results;
};

export const get_woo_products_ids = async (ids: number[]) => {
  return await getDataForIdsByDynamicField<IWooCommerceProduct>(ids, 'woo-commerce-products/?', {
    resultsField: 'results',
    totalField: 'totalCount'
  });
};

export const get_woo_all_products_list = async () => {
  const response: AxiosResponse<IWooCommerceProduct[]> = await http.get(
    'woo-commerce-products/all-products'
  );
  return response.data;
};

export const get_woo_commerce_customers = async (filter: string) => {
  const response: AxiosResponse<IWooCommerceCustomerResponse> = await http.get(
    'woo-commerce-customers/?' + filter
  );
  return response.data;
};

export const get_woo_commerce_customers_only = async (
  page = 1,
  count = 0,
  value = '',
  filter = ''
) => {
  const response: AxiosResponse<IWooCommerceProductsResponse> = await http.get(
    'woo-commerce-customers/?' + filter,
    { page, count, value }
  );
  return response.data.results;
};

export const get_woo_customers_ids = async (ids: number[]) => {
  return await getDataForIdsByDynamicField<IWooCommerceCustomer>(ids, 'woo-commerce-customers/?', {
    resultsField: 'results',
    totalField: 'totalCount'
  });
};

export const get_woo_commerce_customer_details = async (id: number) => {
  const response: AxiosResponse<IWooCommerceCustomer> = await http.get(
    'woo-commerce-customers/' + id
  );
  return response.data;
};

export const get_woo_commerce_orders = async (filter: string) => {
  const response: AxiosResponse<IWooCommerceOrderResponse> = await http.get(
    'woo-commerce-orders/?' + filter
  );
  return response.data;
};

export const get_woo_commerce_order_details = async (id: number) => {
  const response: AxiosResponse<IWooCommerceOrder> = await http.get('woo-commerce-orders/' + id);
  return response.data;
};

export const get_woo_commerce_categories = async () => {
  const response: AxiosResponse<IWooCategoryResponse[]> = await http.get(
    'woo-commerce-category/all-categories'
  );
  return response.data;
};

export const get_woo_commerce_category_details_by_erpId = async (id: number) => {
  const response: AxiosResponse<IWooCategory> = await http.get(
    'woo-commerce-category/one-category/' + id
  );
  return response.data;
};

export const get_woo_commerce_category_details_by_wooId = async (id: number) => {
  const response: AxiosResponse<IWooCategory> = await http.get('woo-commerce-category/' + id);
  return response.data;
};
