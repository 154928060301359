import React, { useState, useRef } from 'react';

import { Button, Input, message, Form, TableProps, Spin, Tooltip, Menu } from 'antd';
import type { ColumnsType } from 'antd/es/table';

import { Link, useNavigate } from 'react-router-dom';
import AppContent from '@/components/Common/Content';

import LocationsDB from '@/store/localstorage/LocationsDB';
import { get_location_details } from '@/services/locations/queries';
import { get_transfer_in_list, get_transfers_by_id } from '@/services/transfer/queries';
import moment from 'moment';
import TableFilter from '@/components/FliterTable';
import CustomButton from '@/components/Common/CustomButton/CustomButton';
import { ConvertObjectToURL } from '@/utils/converturl';
import { convertLocalToUTCString, convertUTCStringtoLocalString } from '@/utils/convertToUTC';
import { SorterResult } from 'antd/lib/table/interface';
import CopyButton from '@/components/Common/CopyButton';
import { getUserData } from '@/utils/auth.utils';
import TableCell from '@/components/Common/CustomizeTable/CustomCell';
import { useReactToPrint } from 'react-to-print';
import { CustomModal } from '@/components/Common/CustomModal';
import CustomTable from '@/components/Common/CustomResuableInvoice/CustomTable';
import { ITransferData } from '@/services/transfer/types';
import ActionDropdown from '@/components/Common/Dropdownactions';
import { CustomDatePresets } from '../../sqlsource/report/utils/datePresets';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';
import { optionalNumberSorter, optionalStringSorter } from '@/utils/sorter.utils';
import UsersDB from '@/store/localstorage/UsersDB';
import { get_user_list_ids } from '@/services/users/queries';
import { DEFAULT_DATE_FORMAT } from '@/constants';
import GenericTable from '@/components/Common/CustomizeTable';
import LocationSearchV2 from '@/components/Common/CustomSearch/Location';
import ProductCategorySearchV2 from '@/components/Common/CustomSearch/ProductCategory';
import CategoryDB from '@/store/localstorage/CategoryDB';
import { getCategoryById } from '@/services';
import { exportExcelAutoWidth } from '@/utils/exportExcelAutoWidth';

interface DataType {
  id: number;
  name: string;
  age: number;
  address: string;
  description: string;
}

const TransfersInList: React.FC = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const printPDFRef = useRef<any>();
  const [isLoading, setIsloading] = useState(true);

  const [alltransferList, setalltransferList] = useState<ITransferData>({ total: 0, results: [] });
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(100);
  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({});
  const handleChange: TableProps<any>['onChange'] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<any>);
  };
  const [openmodalforExport, setOpenModalforexport] = useState<boolean>(false);
  const { preferences } = getUserData();
  const preferenceLocationId = preferences?.preferences
    ? JSON.parse(preferences?.preferences)?.locationId
    : undefined;

  const onSubmitFilter = async (val: string) => {
    const response = await getInfo(val);
    setPage(1);
    setSize(100);
    return response.data;
  };
  const getInfo = async (filter = '') => {
    setIsloading(true);
    const response = await get_transfer_in_list(filter);
    const ids = [
      ...new Set(
        response.data.results.map((data) => {
          return data.transferId;
        })
      )
    ];

    const transfers =
      ids.length > 0
        ? (await get_transfers_by_id(ids, { hideTransferred: 'UNHIDE' })).data
        : { results: [], total: 0 };

    if (response?.data.results) {
      for (let index = 0; index < response.data.results.length; index++) {
        const currentTransferIn = response.data.results[index];

        // console.log(transfers.data);
        let location: any = await LocationsDB.getLocation(currentTransferIn.from);
        if (!location) {
          location = await get_location_details(currentTransferIn.from);
          await LocationsDB.addLocations([location]);
        }
        currentTransferIn.fromName = location.name;
        //to
        location = await LocationsDB.getLocation(currentTransferIn.to);
        if (!location) {
          location = await get_location_details(currentTransferIn.to);
          await LocationsDB.addLocations([location]);
        }
        currentTransferIn.toName = location.name;
        currentTransferIn.date = convertUTCStringtoLocalString(
          currentTransferIn.createdAt,
          'YYYY-MM-DD'
        );

        // created By
        if (currentTransferIn.createdBy) {
          let userDetails = await UsersDB.getUser(currentTransferIn.createdBy);
          if (!userDetails) {
            const allUsers = await get_user_list_ids([
              ...new Set(
                response.data.results
                  .filter((val) => val.createdBy !== null)
                  .map((value) => {
                    return value.createdBy;
                  })
              )
            ]);
            await UsersDB.addUsers(allUsers.data.results);
            userDetails = await UsersDB.getUser(currentTransferIn.createdBy);
            currentTransferIn.createdByName = userDetails.name;
          }
          currentTransferIn.createdByName = userDetails.name;
        }

        // Create Current transfer date
        const transfer = transfers.results.find(
          (transfer) => transfer.id === currentTransferIn.transferId
        );

        if (transfer) {
          currentTransferIn.createDate = transfer.createdAt;
        } else {
          currentTransferIn.createDate = '';
        }
      }
    }

    const allCategoryIds = response.data.results.map((c) => c.categoryId);
    await CategoryDB.addCategoryIfAbsent(allCategoryIds);

    for (let index = 0; index < response.data.results.length; index++) {
      const currentTransferIn = response.data.results[index];
      const category = await getCategoryById(currentTransferIn.categoryId);
      currentTransferIn.categoryName = category.name;
    }

    setalltransferList(response.data);
    setIsloading(false);
    return response;
  };

  const columns: ColumnsType<any> = [
    {
      title: 'S.N',
      key: 'SN',
      width: 10,
      render: (text, record, index) => {
        return <TableCell>{(page - 1) * size + (index + 1)}</TableCell>;
      }
    },
    {
      title: 'ID',
      key: 'id',
      width: 10,
      sorter: (a, b) => a.id - b.id,
      sortOrder: sortedInfo.columnKey === 'id' ? sortedInfo.order : null,
      render: (record) => {
        return (
          <TableCell>
            <Link to={`/transfers/view/${record.id}?type=in`}>{record.id}</Link>
          </TableCell>
        );
      }
    },
    {
      title: 'Financial Reference',
      key: 'financialReference',
      className: 'invoice',
      width: 20,
      sorter: (a, b) => a.financialReference.localeCompare(b.financialReference),
      sortOrder: sortedInfo.columnKey === 'financialReference' ? sortedInfo.order : null,
      render: (record) => {
        if (!record.financialReference) return;
        return (
          <TableCell className="flex flex-row justify-between items-center text-xs">
            <Link to={`/transfers/view/${record.id}?type=in`}>{record.financialReference}</Link>
            <CopyButton text={record.financialReference} />
          </TableCell>
        );
      }
    },
    {
      title: 'Category',
      key: 'categoryName',
      width: 20,
      sorter: (a, b) => a.categoryName.localeCompare(b.categoryName),
      sortOrder: sortedInfo.columnKey === 'categoryName' ? sortedInfo.order : null,
      render: (record) => {
        return <TableCell>{record.categoryName}</TableCell>;
      }
    },
    {
      title: 'From',
      key: 'fromName',
      width: 20,
      sorter: (a, b) => a.fromName.localeCompare(b.fromName),
      sortOrder: sortedInfo.columnKey === 'fromName' ? sortedInfo.order : null,
      render: (record) => {
        return (
          // <Link to={`/transfers/view/${record.id}?type=in`}>
          <TableCell>{record.fromName}</TableCell>
          // </Link>;
        );
      }
    },
    {
      title: 'To',
      key: 'toName',
      width: 20,
      sorter: (a, b) => a.toName.localeCompare(b.toName),
      sortOrder: sortedInfo.columnKey === 'toName' ? sortedInfo.order : null,
      render: (record) => {
        return (
          // <Link to={`/transfers/view/${record.id}?type=in`}>
          <TableCell>{record.toName}</TableCell>
          // </Link>
        );
      }
    },
    {
      title: 'Total Amount',
      key: 'totalAmount',
      className: 'highlight',
      width: 20,
      dataIndex: 'totalAmount',
      align: 'right',
      sorter: (a, b) => optionalNumberSorter(a.totalAmount, b.totalAmount),
      sortOrder: sortedInfo.columnKey === 'totalAmount' ? sortedInfo.order : null,
      render: (totalAmount) => {
        return <TableCell>{nepaliNumberFormatter(totalAmount)}</TableCell>;
      }
    },
    {
      title: 'Approved By',
      key: 'createdBy',
      width: 20,
      sorter: (a, b) => optionalStringSorter(a.createdByName, b.createdByName),
      sortOrder: sortedInfo.columnKey === 'createdBy' ? sortedInfo.order : null,
      render: (record) => {
        return <TableCell>{record.createdByName}</TableCell>;
      }
    },
    // {
    //   title: 'Date',
    //   key: 'transferDate',
    //   sorter: (a, b) => moment(a.transferDate).unix() - moment(b.transferDate).unix(),
    //   sortOrder: sortedInfo.columnKey === 'transferDate' ? sortedInfo.order : null,
    //   render: (record) => {
    //     return (
    //       // <Link to={`/transfers/view/${record.id}?type=in`}>
    //       <TableCell>{convertUTCStringtoLocalString(record.createdAt, 'YYYY-MM-DD')}</TableCell>

    //       // </Link>
    //     );
    //   }
    // },
    {
      title: 'Created Date',
      key: 'createDate',
      width: 20,
      sorter: (a, b) => moment(a.createDate).unix() - moment(b.createDate).unix(),
      sortOrder: sortedInfo.columnKey === 'createDate' ? sortedInfo.order : null,
      render: (record) => {
        if (record.createDate !== '') {
          return (
            // <Link to={`/transfers/view/${record.id}?type=in`}>
            <TableCell>
              {convertUTCStringtoLocalString(record.createDate, DEFAULT_DATE_FORMAT)}
            </TableCell>
            // </Link>
          );
        }
      }
    },
    {
      title: 'Approved Date',
      key: 'createdAt',
      width: 20,
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      sortOrder: sortedInfo.columnKey === 'createdAt' ? sortedInfo.order : null,
      render: (record) => {
        return (
          // <Link to={`/transfers/view/${record.id}?type=in`}>
          <TableCell>
            {convertUTCStringtoLocalString(record.createdAt, DEFAULT_DATE_FORMAT)}
          </TableCell>

          // </Link>
        );
      }
    }
  ];

  const breadcrumbItems = [{ label: 'Transfer In', link: '/transfer-in' }];

  const onPagination = async (pageNo = 1, totalSize = 10, isSize = false) => {
    setIsloading(true);
    const values = form.getFieldsValue();
    values.endDate = convertLocalToUTCString(values.endDate);
    values.startDate = convertLocalToUTCString(values.startDate);
    delete values.dateCustom;
    delete values.startDateNepali;
    delete values.endDateNepali;
    if (isSize) {
      values.skip = 0;
      values.count = totalSize;
      setPage(1);
      setSize(totalSize);
    } else {
      values.skip = (pageNo - 1) * totalSize;
      values.count = totalSize;
      setPage(pageNo);
    }
    const url = ConvertObjectToURL(values);
    const data = await getInfo(url);
    return data.data;
  };

  const handleExport = () => {
    setIsloading(true);
    try {
      const columns = [
        { title: 'S.N', dataIndex: 'SN', width: 50 },
        { title: 'ID', dataIndex: 'id', width: 50 },
        { title: 'Financial Reference', dataIndex: 'financialReference', width: 250 },
        { title: 'Category', dataIndex: 'categoryName', width: 150 },
        { title: 'From', dataIndex: 'fromName', width: 150 },
        { title: 'To', dataIndex: 'toName', width: 150 },
        { title: 'Total Amount', dataIndex: 'totalAmount', width: 100 },
        { title: 'Approved By', dataIndex: 'createdByName', width: 150 },
        { title: 'Created Date', dataIndex: 'createDate', width: 250 },
        { title: 'Approved Date', dataIndex: 'createdAt', width: 250 }
      ];
      if (alltransferList.results.length === 0) {
        message.error('No Data to Export');
        return;
      }

      const dataUpdated = alltransferList.results.map((item: any, index: number) => {
        return {
          ...item,
          SN: index + 1,
          date: convertUTCStringtoLocalString(item.transferDate, DEFAULT_DATE_FORMAT),
          createdAt: convertUTCStringtoLocalString(item.createdAt, DEFAULT_DATE_FORMAT)
        };
      });

      exportExcelAutoWidth(columns, dataUpdated, 'Transfer In List');
    } catch (err: any) {
      console.log(err);
    } finally {
      setIsloading(false);
    }
  };

  const handlePDFExport = useReactToPrint({
    content: () => printPDFRef.current
  });

  const columsforPrint: any = [
    {
      label: 'ID',
      dataIndex: 'id',
      render: (text: string) => {
        return <div className="text-center">{text}</div>;
      }
    },
    {
      label: 'Financial Reference',
      dataIndex: 'financialReference',
      render: (text: string) => {
        return <div className="text-center">{text}</div>;
      }
    },
    {
      label: 'Category',
      dataIndex: 'categoryName',
      render: (text: string) => {
        return <div className="text-center">{text}</div>;
      }
    },
    {
      label: 'From',
      dataIndex: 'fromName',
      render: (text: string) => {
        return <div className="text-center">{text}</div>;
      }
    },
    {
      label: 'To',
      dataIndex: 'toName',
      render: (text: string) => {
        return <div className="text-center">{text}</div>;
      }
    },
    {
      label: 'Total Amount',
      dataIndex: 'totalAmount',
      render: (text: string) => {
        return <div className="text-center">{text}</div>;
      }
    },
    {
      label: 'Approved By',
      dataIndex: 'createdByName',
      render: (text: string) => {
        return <div className="text-center">{text}</div>;
      }
    },

    {
      label: 'Created Date',
      dataIndex: 'createDate',
      render: (text: string) => {
        return <div className="text-center">{text}</div>;
      }
    },
    {
      label: 'Approved Date',
      dataIndex: 'createdAt',
      render: (text: string) => {
        return (
          <div className="text-center">
            {convertUTCStringtoLocalString(text, DEFAULT_DATE_FORMAT)}
          </div>
        );
      }
    }
  ];

  return (
    <Spin spinning={isLoading}>
      <CustomModal
        footer={false}
        isModalOpen={openmodalforExport}
        setIsModalOpen={setOpenModalforexport}
        title="Transfer In View For PDF print">
        <div style={{ maxHeight: '80vh', overflow: 'scroll' }}>
          <CustomTable
            columns={columsforPrint}
            datas={alltransferList.results}
            reff={printPDFRef}
            title={'Transfer In List'}
          />
          <div className="flex justify-end mt-3">
            <Button type="primary" onClick={handlePDFExport}>
              Print Pdf
            </Button>
          </div>
        </div>
      </CustomModal>
      <AppContent
        breadcrumbItems={breadcrumbItems}
        withfilter={true}
        button={
          <>
            <div>
              <TableFilter
                onInitialLoad={({ data, pagination }) => {
                  setPage(pagination.page);
                  setSize(pagination.size);

                  if (data) {
                    setalltransferList(data);
                    setIsloading(false);
                  }
                }}
                defaultValues={{
                  dateCustom: [...CustomDatePresets.Today],
                  value: '',
                  locationId: preferenceLocationId ? preferenceLocationId : '',
                  skip: 0,
                  count: 100
                }}
                initial={true}
                onSubmit={onSubmitFilter}
                // outsidestyle={'flex flex-wrap items-end gap-2'}
                styleforbuttons={'flex items-center justify-end'}
                style={
                  'grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3  xl:grid-cols-4 gap-2 items-center justify-center'
                }
                buttons={
                  <>
                    <div>
                      <CustomButton
                        onClick={() => navigate('/transfers/new')}
                        text="Add"
                        backgroundColor="#1890ff"
                        Linkto="/transfers/new"
                      />
                    </div>
                  </>
                }>
                <LocationSearchV2
                  hasParentFormItem={false}
                  name={'locationId'}
                  label="Location(To)"
                />

                <ProductCategorySearchV2 hasParentFormItem={false} name={'categoryId'} showAll />

                <Form.Item name="value" label="Search">
                  <Input placeholder="Search" />
                </Form.Item>
              </TableFilter>
            </div>
            {/* </div> */}
          </>
        }>
        {/* <Modal
          width={`80%`}
          title={'Approve Details'}
          visible={isModalOpenApp}
          onOk={handleOk}
          okText={'Approve'}
          onCancel={handleCancel}>
          <Form
            form={form}
            layout="vertical"
            validateTrigger={'onChange'}
            autoComplete="off"
            disabled={isLoading}>
            <Form.Item
              label={'Crate'}
              name={['crate']}
              rules={[
                {
                  required: true,
                  message: `Can't be empty`
                }
              ]}>
              <InputNumber controls={false} min={0} />
            </Form.Item>
            <Form.Item
              label={'Sack'}
              name={['sack']}
              rules={[
                {
                  required: true,
                  message: `Can't be empty`
                }
              ]}>
              <InputNumber controls={false} min={0} />
            </Form.Item>
            <Form.Item
              label={'Bags'}
              name={['bags']}
              rules={[
                {
                  required: true,
                  message: `Can't be empty`
                }
              ]}>
              <InputNumber controls={false} min={0} />
            </Form.Item>
            <Form.Item label={'Others'} name={['others']}>
              <Input />
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          width={`80%`}
          title={'Acknowledge Details'}
          visible={isModalOpenAck}
          onOk={handleOk2}
          okText={'Acknowledge'}
          onCancel={handleCancel2}>
          {displayAcknowledge()}
        </Modal> */}
        {/* <TableFilter
          defaultValues={{
            dateCustom: [...CustomDatePresets.Today],
            test: 'aaaa'
          }}
          initial={true}
          onSubmit={onSubmitFilter}></TableFilter>
        <Button
          htmlType={'button'}
          size={'small'}
          type={'primary'}
          className={'me-2'}
          onClick={() => navigate('/transfers/new')}>
          <PlusCircleFilled />
          <span> Add</span>
        </Button> */}

        {/* <Table
          // rowSelection={{
          //   ...rowSelection
          // }}
          scroll={{ y: '65vh', x: true }}
          columns={columns}
          dataSource={transferList?.results}
          rowKey={'id'}
          // expandable={{
          //   expandedRowRender: (record) => <p style={{ margin: 0 }}>{record.description}</p>
          // }}
          loading={isLoading}
        /> */}
        <div className="flex justify-end mb-2">
          {/* <CustomButton text="Export" backgroundColor="#1890ff" onClick={handleExport} />
          <CustomButton
            text="PDF Export"
            backgroundColor="#1890ff"
            onClick={() => setOpenModalforexport(true)}
          /> */}
          <div>
            <ActionDropdown
              button={true}
              menu={
                <Menu
                  items={[
                    {
                      key: '1',
                      label: (
                        <Tooltip title="Export Excel" color="blue">
                          <div className="text-center">Excel</div>
                        </Tooltip>
                      ),
                      onClick: () => {
                        handleExport();
                      }
                    },
                    {
                      key: '2',
                      label: (
                        <Tooltip title="Export PDF" color="blue">
                          <div className="text-center">PDF</div>
                        </Tooltip>
                      ),
                      onClick: () => {
                        setOpenModalforexport(true);
                      }
                    }
                  ]}
                />
              }
            />
          </div>
        </div>
        <GenericTable
          form={form}
          columns={columns}
          data={alltransferList.results}
          hideDefaultPagination={true}
          scroll={{ x: 1000, y: '75vh' }}
          generateSummary
          summaryClassName="!pr-2"
          pagination={{
            page: page,
            total: alltransferList.total,
            size: size,
            onPagination
          }}
          toSort={handleChange}
        />

        {/* <ProductFilterDrawers visible={visible} onClose={onClose} /> */}
      </AppContent>
    </Spin>
  );
};

export default TransfersInList;
