import type { ColumnOrColumnGroup } from 'react-data-grid';
import { IRow } from '../grid.types';
import { Tooltip } from 'antd';

const snCol: ColumnOrColumnGroup<IRow> = {
  key: 'id',
  name: '',
  frozen: true,
  width: 40,
  minWidth: 40,
  renderCell(props) {
    return <strong>{props.rowIdx + 1}</strong>;
  }
};

const outletColumns: ColumnOrColumnGroup<IRow> = {
  name: 'FreshKtm Outlet',
  headerCellClass: 'text-center text-white bg-red-500',
  children: [
    {
      key: 'sellPurchasePrice',
      name: 'Purchase Price',
      headerCellClass: 'text-wrap-header',
      minWidth: 60,
      cellClass: 'text-right',
      renderHeaderCell: (props) => (
        <Tooltip title="Purchase Price">
          <div>{props.column.name}</div>
        </Tooltip>
      )
    },
    {
      key: 'isPurchasePriceChanged',
      name: 'Price Chngd?',
      minWidth: 60,
      cellClass: 'p-0',
      headerCellClass: 'text-wrap-header',
      renderCell: ({ row }) => (
        <div className="flex justify-center">{row.isPurchasePriceChanged ? '✅' : '❌'}</div>
      ),
      renderHeaderCell: (props) => (
        <Tooltip title="Purchase Price Changed?">
          <div>{props.column.name}</div>
        </Tooltip>
      )
    },
    {
      key: 'sellYesterdaySoldQty',
      name: 'Yesterday Sold Qty',
      minWidth: 60,
      cellClass: 'text-right',
      renderHeaderCell: (props) => (
        <Tooltip title="Yesterday Sold Quantity">
          <div>{props.column.name}</div>
        </Tooltip>
      ),
      headerCellClass: 'text-wrap-header'
    },
    {
      key: 'sellYesterdayPrice',
      minWidth: 60,
      name: 'Yest S. Rate',
      headerCellClass: 'text-wrap-header',
      cellClass: 'text-right',
      renderHeaderCell(props) {
        return (
          <Tooltip title="Yesterday Selling Rate">
            <div>{props.column.name}</div>
          </Tooltip>
        );
      }
    },
    {
      key: 'sellTodayPrice',
      name: 'Today Sell Rate',
      minWidth: 67,
      headerCellClass: 'text-wrap-header',
      cellClass: 'text-right',
      renderHeaderCell(props) {
        return <Tooltip title="Today Selling Rate">{<div>{props.column.name}</div>}</Tooltip>;
      }
    },
    {
      key: 'isSellRateChanged',
      name: 'Sell Chngd?',
      minWidth: 60,
      cellClass: 'p-0',
      headerCellClass: 'text-wrap-header',
      renderHeaderCell: (props) => (
        <Tooltip title="Selling Rate Changed?">
          <div>{props.column.name}</div>
        </Tooltip>
      ),
      renderCell: ({ row }) => (
        <div className="flex justify-center">{row.isSellRateChanged ? '✅' : '❌'}</div>
      )
    },
    {
      key: 'marginSellAmount',
      minWidth: 68,
      name: 'Margin (Rs)',
      headerCellClass: 'text-wrap-header',
      cellClass: 'text-right',
      renderHeaderCell(props) {
        return (
          <Tooltip title="Margin Amount (Rs)">
            <div>{props.column.name}</div>
          </Tooltip>
        );
      }
    },
    {
      key: 'marginSellPercentage',
      minWidth: 68,
      name: 'Margin (%)',
      headerCellClass: 'text-wrap-header',
      cellClass: 'text-right',
      renderHeaderCell(props) {
        return (
          <Tooltip title="Margin Percentage">
            <div>{props.column.name}</div>
          </Tooltip>
        );
      }
    },
    {
      key: 'outletForecast',
      name: 'Forecasted Profit/Loss',
      cellClass: 'text-right',
      minWidth: 100,
      renderCell: ({ row }) => {
        const value = row.outletForecast || 0;
        return (
          <div style={{ color: value === 0 ? 'black' : value > 0 ? 'green' : 'red' }}> {value}</div>
        );
      },
      renderHeaderCell: (props) => (
        <Tooltip title="Forecasted Profit or Loss">
          <div>{props.column.name}</div>
        </Tooltip>
      ),
      headerCellClass: 'text-wrap-header'
    }
  ]
};

export const columnsB2BOnly: ColumnOrColumnGroup<IRow>[] = [
  snCol,
  {
    name: 'Purchase',
    headerCellClass: 'text-center',
    children: [
      {
        key: 'productName',
        name: 'Product',
        width: 250,
        minWidth: 200,
        frozen: true,
        headerCellClass: 'text-wrap-header',
        renderHeaderCell: (props) => (
          <Tooltip title="Product Name">
            <div>{props.column.name}</div>
          </Tooltip>
        ),
        renderCell: ({ row }) => (
          <div title={row.productName} className="inline">
            {row.productName}
          </div>
        )
      },
      {
        key: 'unitName',
        name: 'Unit',
        width: 50,
        frozen: true,
        minWidth: 50,
        headerCellClass: 'text-wrap-header',
        renderHeaderCell(props) {
          return (
            <Tooltip title="Unit">
              <div>{props.column.name}</div>
            </Tooltip>
          );
        }
      },
      {
        key: 'purchasePrice',
        name: 'Purchase Price',
        width: 77,
        minWidth: 77,
        frozen: true,
        cellClass: 'text-right',
        headerCellClass: 'text-wrap-header',
        renderHeaderCell(props) {
          return (
            <Tooltip title="Purchase Price">
              <div>{props.column.name}</div>
            </Tooltip>
          );
        }
      },
      {
        key: 'kalimatiRate',
        name: 'Kalimati Rate',
        headerCellClass: 'text-wrap-header',
        minWidth: 60,
        cellClass: 'text-right',
        renderHeaderCell(props) {
          return (
            <Tooltip title="Kalimati Rate">
              <div>{props.column.name}</div>
            </Tooltip>
          );
        }
      },
      {
        key: 'morningPurchase',
        name: 'Morning Purchase',
        minWidth: 60,
        cellClass: 'text-right',
        headerCellClass: 'text-wrap-header',
        renderHeaderCell(props) {
          return (
            <Tooltip title="Morning Purchase">
              <div>{props.column.name}</div>
            </Tooltip>
          );
        }
      },
      {
        key: 'kalimatiSurveyRate',
        name: 'Kali Surv Rate',
        renderHeaderCell: (props) => (
          <Tooltip title="Kalimati Survey Rate">
            <div>{props.column.name}</div>
          </Tooltip>
        ),
        minWidth: 70,
        headerCellClass: 'text-wrap-header'
      },
      {
        key: 'yesterdaySellingRate',
        name: 'Yest. Sell Rate',
        minWidth: 77,
        cellClass: 'text-right',
        renderHeaderCell: (props) => (
          <Tooltip title="Yesterday Selling Rate">
            <div>{props.column.name}</div>
          </Tooltip>
        ),
        headerCellClass: 'text-wrap-header'
      },
      {
        key: 'yesterdaySoldQty',
        name: 'Yesterday Sold Qty',
        minWidth: 60,
        cellClass: 'text-right',
        renderHeaderCell: (props) => (
          <Tooltip title="Yesterday Sold Quantity">
            <div>{props.column.name}</div>
          </Tooltip>
        ),
        headerCellClass: 'text-wrap-header'
      },
      {
        key: 'totalStock',
        name: 'Total Stock',
        cellClass: 'text-right',
        minWidth: 74,
        headerCellClass: 'text-wrap-header',
        renderHeaderCell: (props) => (
          <Tooltip title="Total Stock">
            <div>{props.column.name}</div>
          </Tooltip>
        )
      },
      {
        key: 'todayPrice',
        name: 'Today Price',
        cellClass: 'text-right',
        minWidth: 60,
        headerCellClass: 'text-wrap-header',
        renderHeaderCell: (props) => (
          <Tooltip title="Today Price">
            <div>{props.column.name}</div>
          </Tooltip>
        )
      },
      {
        key: 'isPriceChanged',
        name: 'Price Chngd?',
        minWidth: 60,
        headerCellClass: 'text-wrap-header',
        renderCell: ({ row }) => (
          <div className="flex justify-center">{row.isPriceChanged ? '✅' : '❌'}</div>
        ),
        renderHeaderCell: (props) => (
          <Tooltip title="Is Price Changed">
            <div>{props.column.name}</div>
          </Tooltip>
        )
      },
      {
        key: 'marginAmount',
        minWidth: 68,
        name: 'Margin (Rs)',
        cellClass: 'text-right',
        headerCellClass: 'text-wrap-header',
        renderHeaderCell: (props) => (
          <Tooltip title="Margin Amount">
            <div>{props.column.name}</div>
          </Tooltip>
        )
      },
      {
        key: 'marginPercentage',
        minWidth: 68,
        name: 'Margin (%)',
        cellClass: 'text-right',
        headerCellClass: 'text-wrap-header',
        renderHeaderCell: (props) => (
          <Tooltip title="Margin Percentage">
            <div>{props.column.name}</div>
          </Tooltip>
        )
      },
      {
        key: 'b2bForecast',
        name: 'Forecasted Profit/Loss',
        cellClass: 'text-right',
        minWidth: 100,
        renderCell: ({ row }) => {
          const value = row.b2bForecast || 0;
          return (
            <div style={{ color: value === 0 ? 'black' : value > 0 ? 'green' : 'red' }}>
              {value}
            </div>
          );
        },
        renderHeaderCell: (props) => (
          <Tooltip title="Forecasted Profit or Loss">
            <div>{props.column.name}</div>
          </Tooltip>
        ),
        headerCellClass: 'text-wrap-header'
      },
      {
        key: 'openingStock',
        minWidth: 74,
        name: 'Opening Stock',
        headerCellClass: 'text-wrap-header',
        cellClass: 'text-right',
        renderHeaderCell: (props) => (
          <Tooltip title="Opening Stock">
            <div>{props.column.name}</div>
          </Tooltip>
        )
      },
      {
        key: 'totalOrder',
        name: 'Total Order',
        minWidth: 60,
        headerCellClass: 'text-wrap-header',
        cellClass: 'text-right',
        renderHeaderCell: (props) => (
          <Tooltip title="Total Order">
            <div>{props.column.name}</div>
          </Tooltip>
        )
      },
      {
        key: 'estimatedData',
        name: 'Estimated Data',
        cellClass: 'text-right',
        minWidth: 60,
        headerCellClass: 'text-wrap-header',
        renderHeaderCell: (props) => (
          <Tooltip title="Estimated Data">
            <div>{props.column.name}</div>
          </Tooltip>
        )
      },
      {
        key: 'totalYesterdaySoldQty',
        name: 'Total Qty Sold Yest.',
        minWidth: 80,
        headerCellClass: 'text-wrap-header',
        cellClass: 'text-right',
        renderHeaderCell: (props) => (
          <Tooltip title="Total quantity of items sold from both DC and Outlet yesterday.">
            <div>{props.column.name}</div>
          </Tooltip>
        )
      }
    ]
  }
];

export const columnsB2BAndOutlet: ColumnOrColumnGroup<IRow>[] = [...columnsB2BOnly, outletColumns];

export const columnsOutletOnly: ColumnOrColumnGroup<IRow>[] = [
  snCol,
  {
    name: 'Product',
    key: 'productName',
    width: 250,
    minWidth: 200,
    frozen: true,
    headerCellClass: 'text-wrap-header',
    renderHeaderCell: (props) => (
      <Tooltip title="Product Name">
        <div>{props.column.name}</div>
      </Tooltip>
    ),
    renderCell: ({ row }) => (
      <div title={row.productName} className="inline">
        {row.productName}
      </div>
    )
  },
  {
    name: 'Unit',
    key: 'unitName',
    minWidth: 50,
    frozen: true,
    headerCellClass: 'text-wrap-header',
    renderHeaderCell(props) {
      return (
        <Tooltip title="Unit">
          <div>{props.column.name}</div>
        </Tooltip>
      );
    }
  },
  outletColumns
];
