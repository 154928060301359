import { Breadcrumb, Button, message } from 'antd';
import { CustomModal } from '@/components/Common/CustomModal';
import { useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Link } from 'react-router-dom';

import { get_location_list } from '@/services/locations/queries';
import { get_user_details } from '@/services/users/queries';
import LocationsDB from '@/store/localstorage/LocationsDB';

import UsersDB from '@/store/localstorage/UsersDB';
import { find_locationId_preference } from '@/store/localstorage/preferences';

import { ILocations } from '@/services/locations/types';
import { checkAccess } from '@/routes/acl';
import { create_session_mutation } from '@/services/pos-session/mutations';
import PosClosingModal from '@/components/Common/CustomModal/PosClosingModal';
import { get_session_info } from '@/services/pos-session/queries';
import { IPosSessionResponse } from '@/services/pos-session/types';
import { IPurchaseSellSummation } from '@/pages/sqlsource/report/purchase-sell-summation-report';
import { get_report } from '@/services/report/queries';
import { convertLocalToUTCString } from '@/utils/convertToUTC';
import moment from 'moment';
import NotificationV2 from '../notification/v2';
import LatestFeatureShowCase from '@/components/LatestFeature';
import UserProfile from '@/components/Common/UserProfile/UserProfile';
import getErrorMessage from '@/utils/getError';

interface IPosHeader {
  isSessionStart?: boolean;
  setIsSessionStart?: React.Dispatch<React.SetStateAction<boolean>>;
  isFullScreen: boolean;
  onFullScreenClick: () => void;
  onExitFullScreenClick: () => void;
}

const PosHeader: React.FC<IPosHeader> = ({
  isSessionStart,
  setIsSessionStart,
  isFullScreen,
  onFullScreenClick,
  onExitFullScreenClick
}) => {
  const [locationInfo, setLocationInfo] = useState<ILocations>();
  const [isPosClosingModalOpen, setIsPosClosingModalOpen] = useState<boolean>(false);
  const [session, setSession] = useState<IPosSessionResponse>();
  const [data, setData] = useState<IPurchaseSellSummation[]>([]);

  const fetchLocation = async () => {
    const preferenceLocationId = find_locationId_preference();
    try {
      if (typeof preferenceLocationId === 'number') {
        const response = await LocationsDB.getLocation(preferenceLocationId);
        if (!response) {
          message.info('Cannot find any location with that name in cache, searching in server...');
          const allLocation = await get_location_list(0, 100);
          if (allLocation.data.results.length == 0) {
            message.error('Cannot find any location with that name in server!');
            setLocationInfo(undefined);
          } else {
            LocationsDB.addLocations(allLocation.data.results);
            setLocationInfo(
              allLocation.data.results.find((value) => value.id === preferenceLocationId)
            );
          }
        } else {
          setLocationInfo(response);
          return response;
        }
      }
    } catch (error) {
      message.error(error as string);
    }
  };

  const locationData = useQuery(['locationInfo'], async () => fetchLocation());

  const handleSessionStart = async () => {
    try {
      await startSessionMutation.mutateAsync();
    } catch (errors) {
      getErrorMessage(message, true);
    }
  };

  const startSessionMutation = useMutation(create_session_mutation, {
    onSuccess: () => {
      message.success('Session started successfully!');
      if (setIsSessionStart) setIsSessionStart(true);
    },

    onError: (e) => {
      getErrorMessage(e, true);
    }
  });

  const handleSessionClose = async () => {
    const response = await get_session_info();
    if (response) {
      let userDetails = await UsersDB.getUser(response.userId);
      if (!userDetails) {
        const allUsers = await get_user_details(response.userId);
        await UsersDB.addUsers([allUsers.user]);
        userDetails = await UsersDB.getUser(response.userId);
      }

      if (typeof userDetails === 'object') response.userName = userDetails.name;
      setSession(response);

      // Fetch Pos Closing Report
      const values = {
        startDate: convertLocalToUTCString(response.startTime),
        endDate: convertLocalToUTCString(moment().local().add(1, 'minute')),
        // paymentType: 'cash',
        userId: response.userId,
        page: 1,
        size: -1,
        locationId: locationInfo?.id
      };
      const report = await get_report(values, 49);
      if (report) setData(report.data);
    }

    setIsPosClosingModalOpen(true);
  };

  const handlePosClosingModalClose = () => {
    if (setIsSessionStart) setIsSessionStart(false);
    setIsPosClosingModalOpen(false);
  };

  return (
    <>
      <CustomModal
        title={'POS Closing'}
        isModalOpen={isPosClosingModalOpen}
        setIsModalOpen={setIsPosClosingModalOpen}
        footer={false}>
        <PosClosingModal
          sessionInfo={session}
          data={data}
          locationId={locationInfo?.id}
          handleModalClose={handlePosClosingModalClose}
        />
      </CustomModal>
      <div className="flex gap-x-4">
        <div className="grid grid-rows-2">
          <Breadcrumb style={{ margin: '10px 0px 0px 0px' }}>
            {(checkAccess('ADMIN') || checkAccess('B2B')) && (
              <Breadcrumb.Item>
                <Link to={'/sell'}>Sell</Link>
              </Breadcrumb.Item>
            )}
            <Breadcrumb.Item>Pos</Breadcrumb.Item>
          </Breadcrumb>
          <div className="text-lg font-extrabold">
            {locationData.isLoading ? 'Loading...' : locationInfo?.name}
          </div>
        </div>
        <div className="flex-1 flex gap-4 items-end">
          <Button
            type="primary"
            style={{ borderRadius: '6px' }}
            className="w-40"
            disabled={isSessionStart}
            onClick={async () => {
              await handleSessionStart();
            }}>
            Start Session
          </Button>
          <Button
            type="primary"
            danger
            style={{ borderRadius: '6px' }}
            className="w-40"
            disabled={!isSessionStart}
            onClick={() => {
              handleSessionClose();
            }}>
            End Session
          </Button>
          <LatestFeatureShowCase />
          <Button
            type="primary"
            style={{ borderRadius: '6px' }}
            className="w-40"
            onClick={() => {
              if (!isFullScreen) {
                onFullScreenClick();
              } else {
                onExitFullScreenClick();
              }
            }}>
            {isFullScreen ? 'Exit FullScreen' : 'Enter FullScreen'}
          </Button>
        </div>
        <div className="flex justify-end items-center gap-5">
          <NotificationV2 />
          {'|'}
          <UserProfile />
        </div>
      </div>
    </>
  );
};

export default PosHeader;
