import React, { useEffect, useState } from 'react';
import { Modal, Radio, Select, Typography, Spin } from 'antd';

const { Text } = Typography;

export interface ResetFilterModalProps {
  visible: boolean;
  isLoading?: boolean;
  onCancel: () => void;
  onReset: (type: 'local' | 'saved', scope?: 'all' | 'current') => void;
}

const ResetFilterModal: React.FC<ResetFilterModalProps> = ({
  visible,
  isLoading,
  onCancel,
  onReset
}) => {
  const [filterType, setFilterType] = useState<'local' | 'saved'>('local');
  const [savedScope, setSavedScope] = useState<'all' | 'current'>('all');

  // Reset selections when modal is closed
  useEffect(() => {
    if (!visible) {
      setFilterType('local');
      setSavedScope('all');
    }
  }, [visible]);

  return (
    <Modal
      title="Reset Filters"
      visible={visible}
      onCancel={onCancel}
      onOk={() => onReset(filterType, filterType === 'saved' ? savedScope : undefined)}
      okText="Reset"
      cancelText="Cancel"
      okButtonProps={{ disabled: isLoading }} // Disable reset button when loading
      className="custom-modal">
      <Spin spinning={isLoading}>
        <div className="space-y-6">
          {/* Filter Type Selection */}
          <div>
            <Text className="block font-medium text-gray-700 mb-2">Select Filter Type</Text>
            <Radio.Group
              value={filterType}
              onChange={(e) => setFilterType(e.target.value)}
              className="flex flex-col space-y-2 mt-2"
              disabled={isLoading} // Disable input when loading
            >
              <Radio value="local">
                <span className="font-medium">Local Filters</span>
                <Text type="secondary" className="block text-sm">
                  Removes filters applied only in this session.
                </Text>
              </Radio>
              <Radio value="saved">
                <span className="font-medium">Saved Filters</span>
                <Text type="secondary" className="block text-sm">
                  Clears filters that were previously saved.
                </Text>
              </Radio>
            </Radio.Group>
          </div>

          {/* Scope Selection for Saved Filters */}
          {filterType === 'saved' && (
            <div>
              <Text className="block font-medium text-gray-700">Select Scope</Text>
              <Select
                value={savedScope}
                onChange={setSavedScope}
                className="w-full mt-2"
                options={[
                  { value: 'all', label: 'All Pages - Removes filters across all pages' },
                  { value: 'current', label: 'Current Page - Removes filters only from this page' }
                ]}
                disabled={isLoading} // Disable input when loading
              />
            </div>
          )}
        </div>
      </Spin>
    </Modal>
  );
};

export default ResetFilterModal;
