import { Button, Divider, Form, Input, PageHeader, Switch, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useMutation } from '@tanstack/react-query';
import AppContent from '../../../../../components/Common/Content/Content';
import { checkAccess } from '../../../../../routes/acl';
import ReuseChannel from '../../../../channel/Reuse';
import { get_user_type_details } from '../../../../../services/user-types/queries';
import { update_user_type_mutation } from '../../../../../services/user-types/mutations';
import { IUserType } from '../../../../../services/user-types/types';
import { AxiosError, AxiosResponse } from 'axios';
import { IServerError } from '../../../../../services/response/types';
import { useFilterStore } from '@/store/zustand';
import { ListPage } from '@/constants/list.enum';

const EditUserType = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { id } = useParams();

  const zustandFilter = useFilterStore();

  const getData = async () => {
    if (id) {
      const data = await get_user_type_details(parseInt(id));
      form.setFieldsValue({
        name: data.name,
        typeId: data?.typeId
      });
      setIsLoading(false);
    }
  };

  const breadcrumbItems = [
    {
      label: 'User Category',
      link: '/users/user-type'
    },
    {
      label: 'Details',
      link: `/users/user-type/${id}`
    }
  ];

  const updateUserTypeMutation = useMutation<
    AxiosResponse<IUserType>,
    AxiosError<IServerError>,
    IUserType
  >(update_user_type_mutation, { onSuccess: () => zustandFilter.removeData(ListPage.USER_TYPE) });

  const onFinish = async (values: IUserType) => {
    try {
      setIsLoading(true);
      if (id) values.id = parseInt(id);
      await updateUserTypeMutation.mutateAsync(values, {
        onSuccess: () => {
          setIsLoading(false);
          message.success('User Category updated successfully');
          navigate('/users/user-type');
        },
        onError: (error) => {
          setIsLoading(false);
          message.error(`${error.response?.data.message}`);
        }
      });
    } catch (errors) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <AppContent breadcrumbItems={breadcrumbItems}>
        <Form
          form={form}
          initialValues={{}}
          onFinish={onFinish}
          disabled={isLoading}
          layout="vertical"
          validateTrigger={'onChange'}
          autoComplete="off">
          <PageHeader
            title="Category Information"
            style={{
              padding: '8px 0px'
            }}
          />
          <div className={'grid grid-cols-1 gap-3 mb-3 sm:grid-cols-3 gap-5 mb-5'}>
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: 'Please add name!' }]}>
              <Input />
            </Form.Item>
          </div>
          <div className="flex justify-end mt-5">
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={isLoading}>
                Submit
              </Button>
            </Form.Item>
            <Button
              type="default"
              className="ml-5"
              htmlType="button"
              onClick={() => navigate('/users/user-type')}>
              Cancel
            </Button>
          </div>
        </Form>
        <Divider />
        {checkAccess('READ_CHANNEL') && (
          <ReuseChannel
            slug={`user_type_${id}`}
            withReferenceandId={{
              reference: 'user_type',
              referenceId: id ? id.toString() : ''
            }}
          />
        )}
      </AppContent>
    </div>
  );
};

export default EditUserType;
