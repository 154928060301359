import { Button, message } from 'antd';
import { useReactToPrint } from 'react-to-print';
import { useEffect, useRef, useState } from 'react';

import { CustomModal } from '@/components/Common/CustomModal';
import ExportDrawer from '@/components/Common/Drawer/ExportDrawer';
import TableExport from './TableExport';

import { ICustomViewAccountTableData } from '@/services/accounts/types';
import TableCell from '@/components/Common/CustomizeTable/CustomCell';
import { convertUTCStringtoLocalString } from '@/utils/convertToUTC';
import { detailedLedgerColumns } from './constant.ledger';
import exportLedgerDynamic from '@/utils/exportExcelLedgerDynamic';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';
import { DEFAULT_DATE_FORMAT } from '@/constants';
import getErrorMessage from '@/utils/getError';

const exportOptions = [
  { key: 'export-detailed-ledger-all', label: 'Export All', value: 'all' },
  { key: 'export-detailed-ledger-current', label: 'Export Current', value: 'current' }
];

interface GetIntoOptions {
  filter: string;
  pageNo?: number;
  isExport?: boolean;
  isResetPageRunningBalance?: boolean;
}

interface Props {
  page: number;
  closingBalance: Record<number, number>;
  total: { credit: number; debit: number };
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  data: ICustomViewAccountTableData[];
  selectedAccount: string;
  dateRange: { startDate: string; endDate: string };
  opening: number;
  fullExportURL: string;
  getInfo: (options: GetIntoOptions) => Promise<{
    total: number;
    transaction: { debit: number; credit: number };
    data: ICustomViewAccountTableData[];
  }>;
}

function LedgerExport({
  data,
  selectedAccount,
  closingBalance,
  opening,
  total,
  page,
  dateRange,
  fullExportURL,
  setIsLoading,
  getInfo
}: Props) {
  const printPDFRef = useRef(null);
  const [openExportDrawer, setOpenExportDrawer] = useState(false);
  const [openModalForPdfExport, setOpenModalForPdfExport] = useState(false);
  const [exportData, setExportData] = useState<ICustomViewAccountTableData[]>([]);

  const [totalBalance, setTotalBalance] = useState(total);
  const [selectedColumns, setSelectedColumns] = useState<string[]>([]);

  const columsforPrint = [
    {
      label: 'Journal',
      dataIndex: 'journalName',
      width: 100,
      hasRowSpan: true,
      render: (text: string) => {
        return <TableCell>{text}</TableCell>;
      }
    },
    {
      label: 'Account',
      dataIndex: 'accountName',
      width: 100,
      hasRowSpan: false,
      render: (text: string) => {
        return <TableCell>{text}</TableCell>;
      }
    },
    {
      label: 'Debit',
      dataIndex: 'debit',
      width: 100,
      hasRowSpan: false,
      render: (text: number) => {
        return <TableCell>{text}</TableCell>;
      }
    },
    {
      label: 'Credit',
      dataIndex: 'credit',
      width: 100,
      hasRowSpan: false,
      render: (text: number) => {
        return <TableCell>{text}</TableCell>;
      }
    },
    {
      label: 'Running Balance',
      dataIndex: 'runningBalance',
      width: 100,
      hasRowSpan: false,
      render: (text: number) => {
        return <TableCell>{text}</TableCell>;
      }
    },
    {
      label: 'Reference',
      dataIndex: 'ledgerType',
      width: 100,
      hasRowSpan: false,
      render: (text: string) => {
        return <TableCell>{text}</TableCell>;
      }
    },
    {
      label: 'Ref Number',
      dataIndex: 'refNumber',
      width: 100,
      hasRowSpan: false,
      render: (text: string) => {
        return <TableCell>{text}</TableCell>;
      }
    },
    {
      label: 'Location',
      dataIndex: 'locationName',
      width: 150,
      hasRowSpan: true,
      render: (text: string) => {
        return <TableCell>{text}</TableCell>;
      }
    },
    {
      label: 'Trn. Date',
      dataIndex: 'transactionDate',
      width: 130,
      hasRowSpan: true,
      render: (text: string) => {
        return <TableCell>{convertUTCStringtoLocalString(text, DEFAULT_DATE_FORMAT)}</TableCell>;
      }
    },
    {
      label: 'Created Date',
      dataIndex: 'createdAt',
      width: 130,
      hasRowSpan: true,
      render: (text: string) => {
        return <TableCell>{convertUTCStringtoLocalString(text, DEFAULT_DATE_FORMAT)}</TableCell>;
      }
    }
  ];

  useEffect(() => {
    let columnNames = selectedColumns;

    if (selectedColumns.length === 0) {
      columnNames = columsforPrint.map((column) => column.label);
      if (!columnNames.includes('SN')) {
        columnNames.unshift('SN');
      }
    }

    const isRunningBalance = columnNames.includes('Running Balance');
    const hasAccount = selectedAccount !== '' && selectedAccount !== '--';

    if (!hasAccount) {
      columnNames = columnNames.filter((column) => column !== 'Running Balance');
    }

    if (hasAccount && !isRunningBalance) {
      columnNames.push('Running Balance');
    }

    setSelectedColumns(columnNames);
  }, [selectedAccount]);

  const startDateParam = dateRange.startDate;
  const endDateParam = dateRange.endDate;

  async function excelExportHandler(type: 'all' | 'current') {
    try {
      setIsLoading(true);
      if (data.length === 0) {
        message.error('No Data to Export');
        return;
      }

      let filteredData = data;
      let transaction = total;
      if (type === 'all') {
        const exportData = await getInfo({ filter: fullExportURL, isExport: true });
        filteredData = exportData.data;
        transaction = exportData.transaction;
      }

      const dataUpdated = filteredData.map((item) => {
        return {
          ...item,
          sn: item.rows && item.rows > 0 ? item.sn : undefined,
          journalName: item.rows && item.rows > 0 ? item.journalDescription : '',
          locationName: item.rows && item.rows > 0 ? item.locationName : '',
          ledgerType: item.rows && item.rows > 0 ? item.journalType : '',
          refNumber: item.rows && item.rows > 0 ? item.financialReference : '',
          createdAt:
            item.rows && item.rows > 0
              ? convertUTCStringtoLocalString(item.createdAt, 'YYYY-MM-DD')
              : '',
          transactionDate:
            item.journalDate && item.rows && item.rows > 0
              ? convertUTCStringtoLocalString(item.journalDate, 'YYYY-MM-DD')
              : ''
        };
      });

      exportLedgerDynamic({
        data: dataUpdated,
        from: startDateParam as string,
        to: endDateParam as string,
        balance: typeof opening === 'number' ? nepaliNumberFormatter(opening) : opening,
        account: selectedAccount,
        selectedColumns,
        total: {
          credit: transaction.credit,
          debit: transaction.debit,
          runningBalance: closingBalance[page]
        }
      });
    } catch (error) {
      getErrorMessage(error, true);
    } finally {
      setIsLoading(false);
    }
  }

  const pdfExportHandler = async (type: 'all' | 'current') => {
    setIsLoading(true);

    if (data.length === 0) {
      message.error('No Data to Export');
      setIsLoading(false);
      return;
    }

    try {
      let filteredData = data;

      if (type === 'all') {
        const exportData = await getInfo({ filter: fullExportURL, isExport: true });
        filteredData = exportData.data;
        setTotalBalance(exportData.transaction);
      }

      setExportData(filteredData);
      setOpenModalForPdfExport(true);
    } catch (error) {
      getErrorMessage(error, true);
    } finally {
      setIsLoading(false);
    }
  };

  async function handleExport(options: { type: string; file: string }) {
    const { type, file } = options;
    const optionType = type === 'all' ? 'all' : 'current';

    if (file === 'excel') {
      await excelExportHandler(optionType);
      setOpenExportDrawer(false);
      return;
    }

    await pdfExportHandler(optionType);
    setOpenExportDrawer(false);
  }

  const handlePDFExport = useReactToPrint({
    content: () => printPDFRef.current,
    onAfterPrint: () => setOpenModalForPdfExport(false)
  });

  return (
    <div>
      <CustomModal
        footer={false}
        isModalOpen={openModalForPdfExport}
        setIsModalOpen={setOpenModalForPdfExport}
        title="PDF print">
        <div className="flex justify-end mb-3">
          <Button type="primary" onClick={handlePDFExport}>
            Print Pdf
          </Button>
        </div>

        <div style={{ maxHeight: '80vh', overflow: 'scroll' }}>
          <TableExport
            selectedColumns={selectedColumns}
            data={exportData}
            ref={printPDFRef}
            title="Party Ledger"
            account={selectedAccount}
            from={startDateParam as string}
            to={endDateParam as string}
            balance={typeof opening === 'number' ? nepaliNumberFormatter(opening) : opening}
            total={{
              credit: totalBalance.credit,
              debit: totalBalance.debit,
              runningBalance: closingBalance[page]
            }}
          />
        </div>
      </CustomModal>
      <div className="flex">
        {selectedColumns.length > 0 && (
          <ExportDrawer
            selectedColumns={selectedColumns}
            allColumns={detailedLedgerColumns.filter((column) => {
              // remove running balance if selectedAccount name empty
              if (selectedAccount === '' || selectedAccount === '--') {
                return column !== 'Running Balance';
              }
              return true;
            })}
            open={openExportDrawer}
            options={exportOptions}
            currentSelected={{ type: 'all', file: 'excel' }}
            onOpen={setOpenExportDrawer}
            onExport={handleExport}
            setSelectedColumns={setSelectedColumns}
          />
        )}
      </div>
    </div>
  );
}

export default LedgerExport;
