import { Alert, Button, Form, Input, PageHeader, Select, Spin, message } from 'antd';
import AppContent from '../../../../components/Common/Content/Content';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AccountRulesEvent, ruleInformation } from '../../../../services/accounts/enums';
import { AccountsTypeSearch } from '../../../../components/Common/AccountsTypeSearch';
import { IAccountTypeResponseData, ICreateAccountsRule } from '../../../../services/accounts/types';
import { useMutation } from '@tanstack/react-query';
import { create_account_rule_mutation } from '../../../../services/accounts/mutations';
import { LocationSearch } from '../../../../components/Common/LocationSearch/LocationSearch';
import { getUserData } from '../../../../utils/auth.utils';
import { useFilterStore } from '@/store/zustand';
import { ListPage } from '@/constants/list.enum';

const CreateAccountsRule = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [debitAccountList, setDebitAccountList] = useState<IAccountTypeResponseData[]>([]);
  const [creditAccountList, setCreditAccountList] = useState<IAccountTypeResponseData[]>([]);
  const [locationId, setLocationId] = useState<number>();
  const [isLocationRequired, setIsLocationRequired] = useState<boolean>(true);
  const [isLocationShow, setIsLocationShow] = useState<boolean>(true);
  const [information, setInformation] = useState<string | undefined>();

  const zustandFilter = useFilterStore();

  const breadcrumbItems = [
    {
      label: 'Rule',
      link: '/accounts-rule'
    },
    {
      label: 'New',
      link: '/accounts-rule/new'
    }
  ];

  const { preferences } = getUserData();
  const preferenceLocationId = preferences?.preferences
    ? JSON.parse(preferences?.preferences)?.locationId
    : undefined;

  useEffect(() => {
    setInitialValues();
  }, []);

  const setInitialValues = () => {
    handleLocationChange();
  };

  const onFinish = async (values: ICreateAccountsRule) => {
    setIsLoading(true);
    //console.log('Values', values);
    await createAccountsRuleMutation.mutate(values);
  };

  const createAccountsRuleMutation = useMutation(create_account_rule_mutation, {
    onSuccess: () => {
      setIsLoading(false);
      zustandFilter.resetState(ListPage.ACCOUNTS_RULES);
      message.success('Account rule added successfully');
      navigate('/accounts-rule');
    },
    onError: (e: any) => {
      setIsLoading(false);
      message.error(`${e.response.data.message}`);
    }
  });

  const handleLocationChange = () => {
    // console.log('locationId', form.getFieldValue(['locationId']));
    form.resetFields(['debitAccountId', 'creditAccountId']);
    setLocationId(form.getFieldValue(['locationId']));
  };

  return (
    <Spin spinning={isLoading}>
      <AppContent breadcrumbItems={breadcrumbItems} withfilter={false}>
        {information && (
          <Alert message="Information!" description={information} type="info" closable showIcon />
        )}
        <Form
          form={form}
          initialValues={{
            debitType: 'NONE',
            creditType: 'NONE',
            locationId: preferenceLocationId ? preferenceLocationId : 1
          }}
          onFinish={onFinish}
          disabled={isLoading}
          layout="vertical"
          validateTrigger={'onChange'}
          autoComplete="off">
          <PageHeader
            title="Rule Information"
            style={{
              padding: '8px 0px'
            }}
          />
          <div className={'grid grid-cols-1 sm:grid-cols-3 gap-5 mb-5'}>
            <Form.Item
              label="Rule Name"
              name="name"
              rules={[{ required: true, message: 'Please add Rule Name!' }]}>
              <Input />
            </Form.Item>
            <Form.Item
              label="Description"
              name="description"
              rules={[{ required: true, message: 'Please add Description!' }]}>
              <Input />
            </Form.Item>
            <Form.Item
              label="Event Type"
              name="eventType"
              rules={[{ required: true, message: 'Please add Event Type!' }]}>
              <Select
                placeholder="Select event type"
                dropdownMatchSelectWidth={false}
                onChange={(val) => {
                  const ruleInfo = ruleInformation.find((value) => value.key === val);
                  if (ruleInfo) {
                    setInformation(ruleInfo.info);
                  } else {
                    setInformation(undefined);
                  }
                  if (val === AccountRulesEvent.SELL_PAYMENT_BANK) {
                    setIsLocationRequired(false);
                    setIsLocationShow(true);
                  } else if (val === AccountRulesEvent.PURCHASE_PAYMENT_USER) {
                    setIsLocationRequired(false);
                    setIsLocationShow(true);
                  } else if (
                    val === AccountRulesEvent.VEHICLE_FUEL_CREATE ||
                    val === AccountRulesEvent.VEHICLE_MAINTENANCE_CREATE
                  ) {
                    setIsLocationRequired(false);
                    setIsLocationShow(false);
                  } else {
                    setIsLocationRequired(true);
                    setIsLocationShow(true);
                  }
                }}>
                {(Object.keys(AccountRulesEvent) as Array<keyof typeof AccountRulesEvent>).map(
                  (key) => (
                    <Select.Option key={key} value={key}>
                      {AccountRulesEvent[key]}
                    </Select.Option>
                  )
                )}
              </Select>
            </Form.Item>
          </div>
          <div className={'grid grid-cols-1 sm:grid-cols-3 gap-5 mb-5'}>
            <AccountsTypeSearch
              formData={{ formName: 'debitAccountId', label: 'Debit Account' }}
              required={true}
              isAll={false}
              accountList={debitAccountList}
              setAccountList={setDebitAccountList}
              locationId={locationId}
            />
            <AccountsTypeSearch
              formData={{ formName: 'creditAccountId', label: 'Credit Account' }}
              required={true}
              isAll={false}
              accountList={creditAccountList}
              setAccountList={setCreditAccountList}
              locationId={locationId}
            />
            {isLocationShow && (
              <LocationSearch
                formData={{ formLabel: 'Location', formName: 'locationId' }}
                required={isLocationRequired}
                notAll={true}
                onSelect={() => handleLocationChange()}
              />
            )}
          </div>
          <div className="flex justify-end mt-5">
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={isLoading}>
                Submit
              </Button>
            </Form.Item>
            <Button
              type="default"
              className="ml-5"
              htmlType="button"
              onClick={() => navigate('/accounts-rule')}>
              Cancel
            </Button>
          </div>
        </Form>
      </AppContent>
    </Spin>
  );
};

export default CreateAccountsRule;
