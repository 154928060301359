import { AxiosResponse } from 'axios';
import http from '../../utils/http.utils';
import { IPayment, IPaymentQr, IPaymentQrResponse, IPaymentResult } from './types';
import { getDataForIds } from '../../utils/queries.utils';

export const get_fonepay_qr_for_pos = async (data: IPaymentQr) => {
  const response: AxiosResponse<IPaymentQrResponse, any> = await http.store('fone-pay/qr', data, {
    showErrorModal: false
  });
  return response.data;
};

export const get_fonepay_status_by_prn = async (prn: string) => {
  const response: AxiosResponse<IPaymentQrResponse, any> = await http.get(
    'fone-pay/qr-status/' + prn
  );
  return response.data;
};

export const get_payment_details = async (id: number) => {
  const response: AxiosResponse<IPayment, any> = await http.get('payment/' + id);
  return response.data;
};

export const get_payment_list = async (filter = '') => {
  const response: AxiosResponse<IPaymentResult, any> = await http.get('payment/?' + filter);
  return response.data;
};

export const get_payment_details_ids = async (ids: number[]) => {
  return await getDataForIds<IPayment>(ids, 'payment/?');
};
