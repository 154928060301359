import moment from 'moment';
import AppContent from '@/components/Common/Content/Content';
import { Alert, DatePicker, message, PageHeader, Spin } from 'antd';
import { useEffect, useState } from 'react';
import PriceAnalysisGrid from './grid';
import getErrorMessage from '@/utils/getError';
import { get_outlet_pricing_by_date } from '@/services/products/queries';
import { IOutletPricingByDate } from '@/services/products/types';
import { useNavigate } from 'react-router-dom';
import useBeforeUnloadListener from '@/hooks/useBeforeUnloadListener';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';

function PriceAnalysisCreate() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [selectedDate, setSelectedDate] = useState<string>();
  const [currentPriceAnalysis, setCurrentPriceAnalysis] = useState<IOutletPricingByDate>();
  const [totalForecastValue, setTotalForecastValue] = useState({ b2b: 0, outlet: 0 });

  useBeforeUnloadListener();

  async function handleDateChange(date: moment.Moment | null) {
    try {
      const dateString = date ? date.format('YYYY-MM-DD') : '';
      if (date) {
        const prevDate = moment(date).subtract(1, 'days').format('YYYY-MM-DD');
        const todayPriceAnalysis = await get_outlet_pricing_by_date(dateString);

        if (todayPriceAnalysis) {
          message.error(
            `Price analysis has already been created for the selected date: ${dateString}`
          );
          navigate('/price-analysis');
          return;
        }

        const yesterdayPriceAnalysis = await get_outlet_pricing_by_date(prevDate);
        setCurrentPriceAnalysis(yesterdayPriceAnalysis);
        setSelectedDate(dateString);
      } else {
        setSelectedDate(undefined);
        setCurrentPriceAnalysis(undefined);
      }
    } catch (error) {
      setSelectedDate(undefined);
      getErrorMessage(error, true);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (!selectedDate) {
      handleDateChange(moment());
      // handleDateChange(moment('2024-02-14'));
    }
  }, []);

  return (
    <Spin spinning={isLoading}>
      <AppContent
        breadcrumbItems={[{ label: 'Price Analysis', link: '/price-analysis' }, { label: 'New' }]}>
        <Alert
          message="Important Information"
          description="Date will be set to today by default and it can't be changed."
          type="info"
          showIcon
          closable
        />

        <div className="mt-2 space-x-2">
          <label htmlFor="date-picker">Select Date:</label>
          <DatePicker
            id="date-picker"
            format={'YYYY-MM-DD'}
            showTime
            allowClear={false}
            disabled
            value={selectedDate ? moment(selectedDate) : undefined}
            disabledDate={(current) => current && current > moment().endOf('day')}
            onChange={(date) => handleDateChange(date)}
          />
        </div>

        <PageHeader
          subTitle="Price Analysis"
          style={{ padding: '8px 0' }}
          extra={
            <div className="flex gap-2 flex-wrap">
              <span>
                <strong>B2B:</strong> {nepaliNumberFormatter(totalForecastValue.b2b)},
              </span>
              <span>
                <strong>Outlet:</strong> {nepaliNumberFormatter(totalForecastValue.outlet)}
              </span>
            </div>
          }
        />
        <PriceAnalysisGrid
          date={selectedDate}
          setIsLoading={setIsLoading}
          priceAnalysis={currentPriceAnalysis}
          setTotalForecastValue={setTotalForecastValue}
        />
      </AppContent>
    </Spin>
  );
}

export default PriceAnalysisCreate;
