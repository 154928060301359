import { useState, useEffect } from 'react';
import { Modal, Progress, Button, Typography, Tooltip, Tag } from 'antd';
import { CheckCircleTwoTone, CloseCircleTwoTone, SyncOutlined } from '@ant-design/icons';

const { Text } = Typography;

export interface SyncOperation {
  name: string;
  func: () => Promise<unknown>;
  hasPermission?: boolean;
}

export interface SyncResult {
  name: string;
  status: 'fulfilled' | 'rejected' | 'syncing';
  error?: unknown;
}

interface Props {
  isOpen: boolean;
  onClose: () => void;
  percentComplete: number;
  syncOperations: SyncOperation[];
  currentResults: SyncResult[];
  onRetryFailed?: () => void;
}

const SyncProgressUI = ({
  isOpen,
  onClose,
  percentComplete,
  syncOperations = [],
  currentResults = [],
  onRetryFailed
}: Props) => {
  const [animatedProgress, setAnimatedProgress] = useState(0);

  const isCompleted = percentComplete >= 100;

  useEffect(() => {
    if (isOpen) {
      setAnimatedProgress(0);
    }
  }, [isOpen]);

  useEffect(() => {
    const interval = setInterval(() => {
      setAnimatedProgress((prev) =>
        prev < percentComplete ? Math.min(prev + 1, percentComplete) : prev
      );
      return () => clearInterval(interval);
    }, 15);

    return () => clearInterval(interval);
  }, [percentComplete]);

  const getOperationStatus = (opName: string) => currentResults.find((r) => r.name === opName);

  const getStatusIcon = (status: string, error?: unknown) => {
    switch (status) {
      case 'fulfilled':
        return (
          <Tooltip title="Success">
            <CheckCircleTwoTone twoToneColor="#52c41a" style={{ fontSize: 16 }} />
          </Tooltip>
        );
      case 'rejected':
        return (
          <Tooltip title={String(error) || 'Failed'}>
            <CloseCircleTwoTone twoToneColor="#f5222d" style={{ fontSize: 16 }} />
          </Tooltip>
        );
      case 'syncing':
        return (
          <Tooltip title="Syncing...">
            <SyncOutlined
              spin
              className="[&>svg]:!mt-0"
              style={{ fontSize: 16, color: '#1890ff' }}
            />
          </Tooltip>
        );
      default:
        return (
          <Tooltip title="Pending">
            <Tag color="default" style={{ margin: 0 }}>
              Pending
            </Tag>
          </Tooltip>
        );
    }
  };

  return (
    <Modal
      visible={isOpen}
      footer={
        <div className="flex justify-end items-center gap-2">
          {currentResults.some((r) => r.status === 'rejected') && (
            <Button danger onClick={onRetryFailed} disabled={!isCompleted}>
              Retry Failed
            </Button>
          )}
          <Button type="primary" onClick={onClose} disabled={!isCompleted}>
            Close
          </Button>
        </div>
      }
      closable={false}
      maskClosable={false}
      title={null}
      width={600}
      centered
      destroyOnClose>
      <div className="space-y-6">
        {/* Header and progress bar */}
        <div className="flex justify-between items-center">
          <Text className="text-base font-semibold text-gray-800">Syncing Data</Text>
          {!isCompleted && (
            <SyncOutlined
              spin
              style={{ color: '#1890ff', fontSize: 16 }}
              className="[&>svg]:!mt-0"
            />
          )}
          {isCompleted && <Text className="text-sm font-medium text-green-600">Completed</Text>}
        </div>

        <div className="w-full">
          <Progress
            percent={animatedProgress}
            status={isCompleted ? 'success' : 'active'}
            showInfo={false}
          />
        </div>

        {/* Grid layout */}
        <div className="grid grid-cols-3 gap-4">
          {syncOperations.map((op) => {
            const option = getOperationStatus(op.name);
            return (
              <div
                style={{ border: '1px solid #e5e7eb' }}
                key={op.name}
                className="flex items-center justify-between px-3 py-2 rounded-md shadow-sm bg-white min-h-[44px]">
                <span className="text-sm font-medium text-gray-800 truncate">{op.name}</span>
                {getStatusIcon(option?.status || 'pending', option?.error)}
              </div>
            );
          })}
        </div>
      </div>
    </Modal>
  );
};

export default SyncProgressUI;
