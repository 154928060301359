import { AxiosResponse } from 'axios';
import http from '../../utils/http.utils';
import { ILocations, ILocationsData } from './types';
import { getDataForIds } from '@/utils/queries.utils';

/* Get Location lists
 * @param {number} skip
 * @param {number} count
 * @params {value} string
 * @returns {Promise<any>}
 */
export const get_location_list = async (skip = 0, count = 1000, value = '', filter = '') => {
  const response: AxiosResponse<ILocationsData, any> = await http.get('locations/?' + filter, {
    skip,
    count,
    value
  });
  return response;
};

export const get_active_location_list = async (skip = 0, count = 1000, value = '', filter = '') => {
  const params = new URLSearchParams(filter);
  params.set('archiveStatus', 'ACTIVE');

  const response: AxiosResponse<ILocationsData, any> = await http.get(
    'locations/?' + params.toString(),
    { skip, count, value }
  );

  return response;
};

export const get_location_list_only = async (skip = 0, count = 1000, value = '', filter = '') => {
  const response: AxiosResponse<ILocationsData> = await http.get('locations/?' + filter, {
    skip,
    count,
    value
  });
  return response.data.results;
};

export const get_location_list_for_filter = async (filter = '') => {
  const response: AxiosResponse<ILocationsData, any> = await http.get('locations/?' + filter);
  return response;
};
/* Get single location details
 * @param {number} id
 */
export const get_location_details = async (id: number) => {
  const response: AxiosResponse<ILocations, any> = await http.get('locations/' + id);
  return response.data;
};

export const get_location_list_for_ids = async (ids: number[]) => {
  const data = await getDataForIds<ILocations>(ids, 'locations/?');
  return data.results;
};
