import moment from 'moment';

import AppContent from '@/components/Common/Content/Content';
import CustomErrorModal from '@/components/Common/CustomErrorModal';
import { ListPage } from '@/constants/list.enum';
import { useFilterStore } from '@/store/zustand';
import getErrorMessage from '@/utils/getError';
import isAxiosError from '@/utils/isAxiosError';
import { Button, DatePicker, Descriptions, Form, InputNumber, message, Modal, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { find_leafy_category, find_locationId_preference } from '@/store/localstorage/preferences';
import { ILocations } from '@/services/locations/types';
import { getCategoryById, getLocationByList } from '@/services';
import {
  get_leafy_purchase_wastage_list,
  get_leafy_purchase_wastage_total
} from '@/services/leafy-items/queries';
import { getDateRangeFromSingle } from '@/utils/getDateRangeFromSingle';
import { ILeafyPayload } from '@/services/leafy-items/types';
import { create_leafy_items_mutation } from '@/services/leafy-items/mutation';
import { round } from 'lodash';
import { ICategory } from '@/services/category/types';

interface FormValues {
  date: moment.Moment;
  purchaseTotal: number;
  wastageTotal: number;
}

type ICachedData = { isPresent: false } | { isPresent: true; id: number };

function LeafyItemsCreate() {
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm<FormValues>();
  const navigate = useNavigate();

  const [selectedLocation, setSelectedLocation] = useState<ILocations>();
  const [category, setCategory] = useState<ICategory>();
  const [checkedDates, setCheckedDates] = useState<Record<string, ICachedData>>({});

  async function checkForDate(date: moment.Moment, locationId: number) {
    const formattedDate = date.format('YYYY-MM-DD');

    // Check cache for existing data
    const cachedValue = checkedDates[formattedDate];
    if (cachedValue !== undefined) {
      return cachedValue;
    }

    const query = new URLSearchParams({
      exactDate: formattedDate,
      locationId: locationId.toString()
    });

    const existingData = await get_leafy_purchase_wastage_list(query.toString());

    const value = (
      existingData.result.length > 0
        ? { isPresent: true, id: existingData.result[0].id }
        : { isPresent: false }
    ) as ICachedData;

    // Cache the result
    setCheckedDates((prev) => ({ ...prev, [formattedDate]: value }));

    return value;
  }

  function showErrorModal(id: number) {
    return CustomErrorModal({
      message: (
        <div>
          <p>The leafy detail is already recorded in the system for the selected date.</p>
          <p>If you need to make any changes, you will be redirected to the details page.</p>
        </div>
      ),
      onOkClick: () => navigate(`/leafy-items/details/${id}`),
      onCancel: () => {
        form.resetFields(['date']);
      },
      closable: true
    });
  }

  async function fetchLatestData(date: moment.Moment, location?: number, isInitial?: boolean) {
    try {
      setIsLoading(true);
      const locationId = location || selectedLocation?.id;
      if (!locationId) {
        message.error(
          'Default Preference has not been set! Please set it in Settings/Preferences first.'
        );
        navigate(ListPage.LEAFY_ITEMS);
        return;
      }

      const status = await checkForDate(date, locationId);
      if (status.isPresent && isInitial) {
        form.resetFields(['date']);
        return;
      } else if (status.isPresent) {
        showErrorModal(status.id);
        return;
      }

      // Get latest data
      const range = getDateRangeFromSingle(date);
      const { purchaseTotal, adjustmentTotal } = await get_leafy_purchase_wastage_total({
        ...range,
        locationId
      });

      const purchaseAmount = round(
        purchaseTotal.totalAmount - purchaseTotal.totalAmountReturned,
        2
      );

      const wastageAmount = adjustmentTotal.totalAmount;

      form.setFieldsValue({ purchaseTotal: purchaseAmount, wastageTotal: wastageAmount });
    } catch (error) {
      getErrorMessage(error, true);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    const preferenceLocation = find_locationId_preference() as number;
    if (!preferenceLocation) {
      message.error(
        'Default Preference has not been set! Please set it in Settings/Preferences first.'
      );
      navigate(ListPage.LEAFY_ITEMS);
      return;
    }

    const leafyCategoryId = find_leafy_category();
    if (!leafyCategoryId) {
      message.error('Please set default leafy category in Global Settings');
      navigate(ListPage.GLOBAL_SETTINGS);
      return;
    }

    Promise.all([getLocationByList(preferenceLocation), getCategoryById(leafyCategoryId)]).then(
      ([loc, cat]) => {
        const todayDate = moment();
        setSelectedLocation(loc);
        setCategory(cat);
        form.setFieldsValue({ date: todayDate });
        fetchLatestData(todayDate, loc.id, true);
      }
    );
  }, []);

  const zustandFilter = useFilterStore();

  async function onFinish(values: FormValues) {
    try {
      setIsLoading(true);
      if (!selectedLocation) {
        message.error(
          'Default Preference has not been set! Please set it in Settings/Preferences first.'
        );
        navigate(ListPage.LEAFY_ITEMS);
        return;
      }

      const payload: ILeafyPayload = {
        ...values,
        locationId: selectedLocation.id,
        date: values.date.format('YYYY-MM-DD')
      };

      await create_leafy_items_mutation(payload);
      zustandFilter.resetState(ListPage.LEAFY_ITEMS);
      navigate(ListPage.LEAFY_ITEMS);
    } catch (error) {
      if (isAxiosError(error)) return;
      CustomErrorModal({ message: getErrorMessage(error) });
    } finally {
      setIsLoading(false);
    }
  }

  function onConfirmation() {
    setIsModalOpen(false);
    form.submit();
  }

  async function onCreate() {
    await form.validateFields();
    setIsModalOpen(true);
  }

  async function handleDateChange(date: moment.Moment | null) {
    if (!date) return;
    form.resetFields(['purchaseTotal', 'wastageTotal']);
    await fetchLatestData(date, selectedLocation?.id);
  }

  return (
    <Spin spinning={isLoading}>
      <Modal visible={isModalOpen} onCancel={() => setIsModalOpen(false)} onOk={onConfirmation}>
        <p>Are you sure you want to create?</p>
      </Modal>

      <AppContent
        breadcrumbItems={[{ label: 'Leafy Items', link: '/leafy-items' }, { label: 'Create' }]}>
        <Descriptions
          bordered
          column={1}
          labelStyle={{ width: '150px' }}
          size="small"
          title="Purchase and Wastage"
          style={{ marginBottom: '16px' }}>
          <Descriptions.Item label="Location">{selectedLocation?.name}</Descriptions.Item>
          <Descriptions.Item label="Category">{category?.name}</Descriptions.Item>
        </Descriptions>

        <Form form={form} layout="vertical" onFinish={onFinish}>
          <div className="grid sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4">
            <Form.Item
              label="Date"
              name="date"
              rules={[{ required: true, message: 'Please select a date' }]}>
              <DatePicker
                style={{ width: '100%' }}
                onChange={handleDateChange}
                disabledDate={(d) => d > moment().endOf('day')}
              />
            </Form.Item>

            <Form.Item
              label="Purchase Amount"
              name="purchaseTotal"
              rules={[{ required: true, message: 'Please enter purchase amount' }]}>
              <InputNumber
                min={0}
                style={{ width: '100%' }}
                addonBefore="Rs"
                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              />
            </Form.Item>

            <Form.Item
              label="Wastage Amount"
              name="wastageTotal"
              rules={[{ required: true, message: 'Please enter wastage amount' }]}>
              <InputNumber
                min={0}
                style={{ width: '100%' }}
                addonBefore="Rs"
                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              />
            </Form.Item>
          </div>

          <div className="flex justify-end mt-4">
            <Button type="primary" onClick={onCreate} disabled={isLoading}>
              Submit
            </Button>
          </div>
        </Form>
      </AppContent>
    </Spin>
  );
}

export default LeafyItemsCreate;
