import { OpeningStockHistoryLine } from '@/services/stock/types';
import { ColumnsType } from 'antd/lib/table';

export const OpeningStockExcelColumns = [
  { title: 'Location Name', dataIndex: 'col0' },
  { title: 'Location Code', dataIndex: 'col1' },
  { title: 'Product Name', dataIndex: 'col2' },
  { title: 'SKU', dataIndex: 'col3' },
  { title: 'Unit Name', dataIndex: 'col4' },
  { title: 'Quantity', dataIndex: 'col5' },
  { title: 'Unit Price', dataIndex: 'col6' },
  { title: 'Qty Available', dataIndex: 'col7' },
  { title: 'Wastage', dataIndex: 'col8' }
];

export const transformedColumns: ColumnsType<OpeningStockHistoryLine> = [
  { title: 'Product', dataIndex: 'productName', key: 'productName', width: 100 },
  { title: 'Unit', dataIndex: 'unitName', key: 'unitName', width: 50 },
  { title: 'Unit Price', dataIndex: 'unitPrice', key: 'unitPrice', width: 50 },
  { title: 'Final Quantity', dataIndex: 'quantity', key: 'quantity', width: 50 },
  { title: 'Qty Available', dataIndex: 'qtyAvailable', key: 'qtyAvailable', width: 50 },
  { title: 'Opening Stock', dataIndex: 'openingQuantity', key: 'openingQuantity', width: 50 },
  {
    title: 'Wastage',
    dataIndex: 'accountedQuantity',
    key: 'accountedQuantity',
    width: 50
  },
  {
    title: 'Miscellaneous Wastage',
    dataIndex: 'unAccountedQuantity',
    key: 'unAccountedQuantity',
    width: 50
  }
];
