import AppContent from '@/components/Common/Content/Content';
import { ConvertObjectToURL } from '@/utils/converturl';
import getErrorMessage from '@/utils/getError';
import handlePagination from '@/utils/handlePagination';
import { Form, Input } from 'antd';
import { useState } from 'react';
import Table from './Table';
import TableFilter from '@/components/FliterTable';
import { get_woo_commerce_customers } from '@/services/woocommerce/queries';
import { IWooCommerceCustomerResponse } from '@/services/woocommerce/types';
import { WooCommerceCustomersDB } from '@/store/localstorage/WooCommerceCustomersDB';

const DEFAULT_COUNT = 100;

function WooCommerceCustomerList() {
  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState(true);
  const [pagination, setPagination] = useState({ page: 1, size: DEFAULT_COUNT });
  const [data, setData] = useState<IWooCommerceCustomerResponse>({ results: [] });

  async function onSubmitFilter(filter: string) {
    const data = await getInfo(filter.toString());
    setPagination({ page: 1, size: DEFAULT_COUNT });
    return { data };
  }

  function convertSkipToPage(filter: string) {
    const params = new URLSearchParams(filter);
    const paramSkip = params.get('skip');
    const paramsCount = params.get('count');

    const skip = paramSkip ? parseInt(paramSkip) : 0;
    const count = paramsCount ? parseInt(paramsCount) : DEFAULT_COUNT;

    // Calculate page from skip and count
    const page = Math.floor(skip / count) + 1;
    params.delete('skip');
    params.set('page', page.toString());
    return params.toString();
  }

  async function getInfo(filter = '') {
    try {
      setIsLoading(true);
      const params = convertSkipToPage(filter);
      const response = await get_woo_commerce_customers(params);
      await WooCommerceCustomersDB.add(response.results);
      setData(response);
      return response;
    } catch (error) {
      getErrorMessage(error, true);
    } finally {
      setIsLoading(false);
    }
  }

  const onPagination = async (page = 1, size = DEFAULT_COUNT, isSize = false) => {
    setIsLoading(true);
    const { values, ...pagination } = handlePagination({
      formValues: form.getFieldsValue(),
      page,
      size,
      isSize
    });

    setPagination((prev) => {
      prev.page = pagination.page;
      if (isSize) prev.size = pagination.size;
      return prev;
    });

    const url = ConvertObjectToURL(values);
    const data = await getInfo(url);
    return { data };
  };

  return (
    <div>
      <AppContent
        breadcrumbItems={[
          { label: 'WooCommerce', link: '/woo-commerce/products' },
          { label: 'Customers' }
        ]}
        withfilter
        button={
          <TableFilter
            initial
            form={form}
            dateCustom={false}
            onInitialLoad={({ data, pagination }) => {
              setPagination(pagination);
              if (data) {
                if (data.data) setData(data.data);
                setIsLoading(false);
              }
            }}
            onSubmit={onSubmitFilter}
            defaultValues={{ value: '', skip: 0, count: DEFAULT_COUNT }}
            styleforbuttons={'flex justify-end items-center'}>
            <Form.Item name="value" label="Search">
              <Input placeholder="Search" />
            </Form.Item>
          </TableFilter>
        }>
        <Table
          form={form}
          data={data}
          isLoading={isLoading}
          pagination={pagination}
          onPagination={onPagination}
        />
      </AppContent>
    </div>
  );
}

export default WooCommerceCustomerList;
