import moment from 'moment';
import AppContent from '@/components/Common/Content/Content';
import { Alert, DatePicker, Form, message, Modal, PageHeader, Select, Spin } from 'antd';
import { useEffect, useState } from 'react';
import Grid from './Grid';
import { IProductRow } from '@/services/woocommerce/types';
import { fetchSoldProductByRange, formatToGrid } from '@/services/woocommerce/services';
import getErrorMessage from '@/utils/getError';
import useIgnoreGlobalTab from '@/hooks/useIgnoreGlobalTab';
import { emptyRow } from '../constant';
import { ICreatePriceGroup } from '@/services/products/types';
import { get_all_price_groups, get_price_by_groupId } from '@/services/products/queries';
import useCache from '@/hooks/useCache';
import { CustomDatePresets } from '@/pages/sqlsource/report/utils/datePresets';

export interface IWooFormCreate {
  date: string;
  dateMoment: string;
  priceGroupId?: number;
}

function CreateWooProducts() {
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm<IWooFormCreate>();
  const [rows, setRows] = useState<IProductRow[]>([emptyRow]);

  const { get: getPriceGroupDetails } = useCache(get_price_by_groupId);

  useIgnoreGlobalTab();

  // MediaId -> url mapping
  const [priceGroups, setPriceGroups] = useState<ICreatePriceGroup[]>([]);

  async function onPriceGroupChange(priceGroupId: number) {
    try {
      setIsLoading(true);
      if (!priceGroupId) return;
      const currentPrice = await getPriceGroupDetails(priceGroupId);

      setRows((prevRows) => {
        return prevRows.map((row) => {
          if (row.id && row.id > 0) {
            const price = currentPrice.find(
              (p) => p.productId === row.id && p.unitId === row.unitId
            );

            row.sell_price = price?.sellingPrice || 0;
          }

          return row;
        });
      });
    } catch (error) {
      getErrorMessage(error, true);
    } finally {
      setIsLoading(false);
    }
  }

  async function fetchProductAndPriceGroups() {
    try {
      setIsLoading(true);
      const [priceGroups] = await Promise.all([get_all_price_groups()]);

      setPriceGroups(priceGroups.results);
    } catch (error) {
      getErrorMessage(error, true);
    } finally {
      setIsLoading(false);
    }
  }

  const handleDateChange = async (date: moment.Moment[]) => {
    if (date && date.length === 2) {
      const startDate = date[0].clone().utc().format();
      const endDate = date[1].clone().utc().format();
      const range = { startDate, endDate };

      await populateProducts(range);
    }
  };

  function addEmptyRow() {
    setRows((prevRows) => [...prevRows, emptyRow]);
  }

  useEffect(() => {
    fetchProductAndPriceGroups();
  }, []);

  async function populateProducts(range: { startDate: string; endDate: string }) {
    try {
      Modal.destroyAll();
      setIsLoading(true);
      const products = await fetchSoldProductByRange(range);
      const { rows } = await formatToGrid(products);
      if (rows.length === 0) return addEmptyRow();

      // Add Selling Price to Product
      const priceGroupId = form.getFieldValue('priceGroupId');
      if (priceGroupId) {
        const currentPrice = await getPriceGroupDetails(priceGroupId);
        rows.forEach((row) => {
          if (row.id && row.id > 0) {
            const price = currentPrice.find(
              (p) => p.productId === row.id && p.unitId === row.unitId
            );
            row.sell_price = price?.sellingPrice || 0;
          }
        });
      }

      setRows(rows);
      message.success(`Successfully prefilled ${rows.length} sold product(s) from selected range.`);
    } catch (error) {
      getErrorMessage(error, true);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Spin spinning={isLoading}>
      <AppContent
        breadcrumbItems={[
          { label: 'WooCommerce', link: '/woo-commerce/products' },
          { label: 'Add Products' }
        ]}>
        <Alert
          message="Prefilling Products"
          description={
            <ul className="pl-4">
              <li>
                Sold products from selected range will be automatically prefilled into the grid. You
                can add more products if needed.
              </li>
              <li>Ensure that each products have a default unit.</li>
            </ul>
          }
          type="info"
          showIcon
          closable
        />

        <Form
          form={form}
          layout="vertical"
          validateTrigger={'onChange'}
          disabled={isLoading}
          autoComplete="off">
          <div className="grid sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4 mt-8">
            <Form.Item label="Prefill Products By Sold Date" name="dateMoment">
              <DatePicker.RangePicker
                id="date-picker"
                className="w-full"
                format={'YYYY-MM-DD HH:mm'}
                showTime
                allowClear={false}
                ranges={CustomDatePresets}
                onChange={(values) => handleDateChange(values as unknown as moment.Moment[])}
              />
            </Form.Item>

            <Form.Item name={['priceGroupId']} label="Price Group">
              <Select
                placeholder="Select price group"
                dropdownMatchSelectWidth={false}
                showSearch
                optionFilterProp="label"
                optionLabelProp="label"
                onChange={(value: number) => onPriceGroupChange(value)}
                options={priceGroups.map((price) => ({ label: price.name, value: price.id }))}
              />
            </Form.Item>
          </div>
        </Form>

        <div className="mt-2 space-y-2">
          <PageHeader title="Add Products" style={{ padding: '8px 0' }} />
          <Grid
            rows={rows}
            form={form}
            isLoading={isLoading}
            // Dispatch SetActions
            setRows={setRows}
            addEmptyRow={addEmptyRow}
            setIsLoading={setIsLoading}
            getPriceGroupDetails={getPriceGroupDetails}
          />
        </div>
      </AppContent>
    </Spin>
  );
}

export default CreateWooProducts;
