import { IExportNestedColumn } from '@/utils/exportNestedExcel';
import { IRow } from '../grid.types';

const exportOutletColumns: IExportNestedColumn<IRow> = {
  title: 'FreshKtm Outlet',
  dataIndex: '',
  height: 45,
  style: { background: 'FFef4444', color: 'FFFFFFFF' },
  children: [
    { title: 'Purchase Price', dataIndex: 'sellPurchasePrice', alignment: { horizontal: 'right' } },
    { title: 'Price Changed?', dataIndex: 'isPurchasePriceChanged' },
    {
      title: 'Yesterday Sold Qty',
      dataIndex: 'sellYesterdaySoldQty',
      alignment: { horizontal: 'right' }
    },
    {
      title: 'Yesterday Selling Rate',
      dataIndex: 'sellYesterdayPrice',
      alignment: { horizontal: 'right' }
    },
    { title: 'Today Sell Rate', dataIndex: 'sellTodayPrice', alignment: { horizontal: 'right' } },
    { title: 'Sell Changed?', dataIndex: 'isSellRateChanged' },
    { title: 'Margin (Rs)', dataIndex: 'marginSellAmount', alignment: { horizontal: 'right' } },
    { title: 'Margin (%)', dataIndex: 'marginSellPercentage', alignment: { horizontal: 'right' } },
    {
      title: 'Forecasted Profit/Loss',
      dataIndex: 'outletForecast',
      alignment: { horizontal: 'right' }
    }
  ]
};

export const exportB2bColumnsOnly: IExportNestedColumn<IRow>[] = [
  {
    title: 'Purchase',
    dataIndex: '',
    children: [
      { title: 'Product', dataIndex: 'productName', width: 50 },
      { title: 'Unit', dataIndex: 'unitName', width: 10 },
      { title: 'Purchase Price', dataIndex: 'purchasePrice', alignment: { horizontal: 'right' } },
      { title: 'Kalimati Rate', dataIndex: 'kalimatiRate', alignment: { horizontal: 'right' } },
      {
        title: 'Morning Purchase',
        dataIndex: 'morningPurchase',
        alignment: { horizontal: 'right' }
      },
      { title: 'Kalimati Survey Rate', dataIndex: 'kalimatiSurveyRate' },
      {
        title: 'Yesterday Selling Rate',
        dataIndex: 'yesterdaySellingRate',
        alignment: { horizontal: 'right' }
      },
      {
        title: 'Yesterday Sold Qty',
        dataIndex: 'yesterdaySoldQty',
        alignment: { horizontal: 'right' }
      },
      { title: 'Total Stock', dataIndex: 'totalStock', alignment: { horizontal: 'right' } },
      { title: 'Today Price', dataIndex: 'todayPrice', alignment: { horizontal: 'right' } },
      { title: 'Price Changed?', dataIndex: 'isPriceChanged' },
      { title: 'Margin (Rs)', dataIndex: 'marginAmount', alignment: { horizontal: 'right' } },
      { title: 'Margin (%)', dataIndex: 'marginPercentage', alignment: { horizontal: 'right' } },
      {
        title: 'Forecasted Profit/Loss',
        dataIndex: 'b2bForecast',
        alignment: { horizontal: 'right' }
      },
      { title: 'Opening Stock', dataIndex: 'openingStock', alignment: { horizontal: 'right' } },
      { title: 'Total Order', dataIndex: 'totalOrder', alignment: { horizontal: 'right' } },
      { title: 'Estimated Data', dataIndex: 'estimatedData', alignment: { horizontal: 'right' } },
      {
        title: 'Total Qty Sold Yesterday',
        dataIndex: 'totalYesterdaySoldQty',
        alignment: { horizontal: 'right' }
      }
    ]
  }
];

export const exportB2BAndOutletColumns = [...exportB2bColumnsOnly, exportOutletColumns];

export const exportOutletColumnsOnly: IExportNestedColumn<IRow>[] = [
  { title: 'Product', dataIndex: 'productName', width: 50 },
  { title: 'Unit', dataIndex: 'unitName', width: 10 },
  exportOutletColumns
];
