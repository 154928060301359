import { Select, Spin } from 'antd';
import { useFinancialYearStore } from '@/store/zustand/financial-year';
import { cn } from '@/utils';

const FinancialYearSelector = () => {
  const { financialYearList, selectedFinancialYear, isLoading, ...handlers } =
    useFinancialYearStore();

  return (
    <Spin spinning={isLoading}>
      <Select
        showSearch
        placeholder="Select financial year"
        optionFilterProp="children"
        className="w-full"
        onChange={handlers.handleFinancialYearChange}
        value={selectedFinancialYear?.name}
        filterOption={false}>
        {financialYearList.map((year) => {
          return (
            <Select.Option
              key={`financial-year-${year.id}`}
              value={year.name}
              title={
                year.isCurrent
                  ? 'Current Financial Year'
                  : year.isClosed
                  ? 'Closed Financial Year'
                  : year.name
              }
              className={cn({
                '!text-blue-500': year.name === selectedFinancialYear?.name
              })}>
              {year.name}
            </Select.Option>
          );
        })}
      </Select>
    </Spin>
  );
};

export default FinancialYearSelector;
