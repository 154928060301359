import { IProductRow } from '@/services/woocommerce/types';

export const emptyRow: IProductRow = {
  name: '',
  sku: '',
  image: '',
  imgId: 0,
  limit_qty: 0,
  priority: 0,
  categoryId: 0,
  categoryName: '',
  unitId: 0,
  unitName: '',
  baseUnitMultiplier: 1,
  sell_price: 0
};
