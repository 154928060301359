import { useMutation } from '@tanstack/react-query';
import { Input, message, Menu, Form, TableProps } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import LocationFilterDrawer from '../../../components/@Locations/FilterDrawer';
import AppContent from '../../../components/Common/Content';
import CustomButton from '../../../components/Common/CustomButton/CustomButton';
import CustomizeTable from '../../../components/Common/CustomizeTable/CustomizeTable';
import DeleteConfirmModal from '../../../components/Common/Deleteconfirm/DeleteConfirmModal';
import DiscussionChatModal from '../../../components/Common/DiscussionChatModal';
import TableFilter from '../../../components/FliterTable';
import useFilterDrawers from '../../../hooks/useFilterDrawers';
import { checkAccess } from '../../../routes/acl';
import { delete_category } from '../../../services/category/mutations';
import { get_category_list_filter } from '../../../services/category/queries';
import { ICategory, ICategoryListResponse } from '../../../services/category/types';
import CategoryDB from '../../../store/localstorage/CategoryDB';
import { AppDispatch } from '../../../store/store';
import { ConvertObjectToURL } from '../../../utils/converturl';
import { SorterResult } from 'antd/lib/table/interface';
import { convertLocalToUTCString } from '../../../utils/convertToUTC';
import ActionDropdown from '../../../components/Common/Dropdownactions';
import TableCell from '../../../components/Common/CustomizeTable/CustomCell';
import CustomUpdateIcon from '../../../components/Common/CustomIcons/CustomUpdateIcon';
import { useFilterStore } from '@/store/zustand';

const CategoryList: React.FC = () => {
  const [form] = Form.useForm();

  const navigate = useNavigate();
  const { visible, onClose } = useFilterDrawers();
  const [isLoading, setIsloading] = useState<boolean>(true);
  const [allCategoryList, setallCategoryList] = useState<ICategoryListResponse>({
    total: 0,
    results: []
  });
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(100);
  let selectedID = 0;
  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({});
  const handleChange: TableProps<any>['onChange'] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<any>);
  };
  const onSubmitFilter = async (val: string) => {
    const data = await getInfo(val);
    setPage(1);
    setSize(100);
    return data;
  };

  const zustandFilter = useFilterStore();
  const location = useLocation();

  const getInfo = async (filter = '') => {
    setIsloading(true);
    const response = await get_category_list_filter(filter);
    setallCategoryList(response.data);
    setIsloading(false);
    CategoryDB.addCategory(response.data.results);
    return response.data;
  };

  const deleteCategoryMutation = useMutation(delete_category, {
    onSuccess: (data: any) => {
      message.success('category deleted successfully');
      setIsloading(false);
      CategoryDB.deleteCategory(selectedID);
      zustandFilter.refetchData(location.pathname, getInfo);
    },
    onError: (e: any) => {
      message.error(`${e.response.data.message}`, 5);
    }
  });

  const columns: ColumnsType<ICategory> = [
    {
      title: 'S.N',
      key: 'SN',
      width: 2,
      sorter: (a, b) => a.id - b.id,
      sortOrder: sortedInfo.columnKey === 'id' ? sortedInfo.order : null,
      render: (text, record, index) => {
        return <TableCell>{(page - 1) * size + (index + 1)}</TableCell>;
      }
    },
    {
      title: 'Name',
      key: 'name',
      width: 15,
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null,
      render: (record) => {
        return (
          <>
            <TableCell>{record.name}</TableCell>
          </>
        );
      }
    },
    {
      title: 'Description',
      key: 'description',
      width: 15,
      sorter: (a, b) => a.description.localeCompare(b.description),
      sortOrder: sortedInfo.columnKey === 'description' ? sortedInfo.order : null,
      render: (record) => {
        return (
          // <Link to={`/products/category/${record.id}`} color="black">
          <TableCell> {record.description}</TableCell>
          // </Link>
        );
      }
    },
    {
      title: 'Actions',
      key: 'actions',
      width: 3,
      fixed: 'right',
      render: (record) => {
        const confirmFunction = () => {
          selectedID = record.id;
          deleteCategoryMutation.mutate(record.id);
        };
        const menuItems: (
          | {
              key: string;
              label: JSX.Element;
              onClick?: undefined;
            }
          | {
              key: string;
              label: JSX.Element;
              onClick: () => void;
            }
        )[] = [];

        if (checkAccess('UPDATE_PRODUCT_CATEGORY')) {
          menuItems.push({
            key: '1',
            label: <CustomUpdateIcon link={`/products/category/${record.id}`} />
          });
        }
        if (checkAccess('DELETE_PRODUCT_CATEGORY')) {
          menuItems.push({
            key: '2',
            label: <DeleteConfirmModal FunctionAfterOk={confirmFunction} />
          });
        }
        if (checkAccess('READ_CHANNEL')) {
          menuItems.push({
            key: '3',
            label: <DiscussionChatModal slug="category" id={record.id} />
          });
        }
        const menu = <Menu items={menuItems} />;
        return <ActionDropdown menu={menu} />;
      }
    }
  ];

  const breadcrumbItems = [
    {
      label: 'Category',
      link: '/products/category'
    }
  ];

  const onPagination = async (pageNo = 1, totalSize = 100, isSize = false) => {
    setIsloading(true);
    const values = form.getFieldsValue();
    values.endDate = convertLocalToUTCString(values.endDate);
    values.startDate = convertLocalToUTCString(values.startDate);
    delete values.dateCustom;
    delete values.startDateNepali;
    delete values.endDateNepali;
    if (isSize) {
      values.skip = 0;
      values.count = totalSize;
      setPage(1);
      setSize(totalSize);
    } else {
      values.skip = (pageNo - 1) * totalSize;
      values.count = totalSize;
      setPage(pageNo);
    }
    const url = ConvertObjectToURL(values);
    return await getInfo(url);
  };

  return (
    <div>
      <AppContent
        breadcrumbItems={breadcrumbItems}
        withfilter={true}
        button={
          <>
            <div>
              <TableFilter
                onInitialLoad={({ data, pagination }) => {
                  setPage(pagination.page);
                  setSize(pagination.size);

                  if (data) {
                    setallCategoryList(data);
                    setIsloading(false);
                  }
                }}
                defaultValues={{
                  dateCustom: [moment(0, 'HH'), moment(0, 'HH').add(1, 'days')],
                  value: '',
                  skip: 0,
                  count: 100
                }}
                initial={true}
                onSubmit={onSubmitFilter}
                form={form}
                styleforbuttons={'flex justify-end items-center'}
                style={
                  'grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3  xl:grid-cols-4 gap-2 items-center justify-center'
                }
                buttons={
                  <>
                    {checkAccess('CREATE_PRODUCT_CATEGORY') && (
                      <div>
                        <CustomButton
                          onClick={() => navigate(`/products/category/new`)}
                          text="Add"
                          backgroundColor="#1890ff"
                          Linkto="/products/category/new"
                          // marginTop="1.7rem"
                        />
                      </div>
                    )}
                  </>
                }>
                <Form.Item name="value" label="Search">
                  <Input placeholder="Search" />
                </Form.Item>
              </TableFilter>
            </div>
          </>
        }>
        {/* <TableFilter
          defaultValues={{
            dateCustom: [moment(), moment()]
          }}
          initial={true}
          onSubmit={onSubmitFilter}></TableFilter>

        <Button
          htmlType={'button'}
          size={'small'}
          type={'primary'}
          className={'me-2'}
          onClick={() => navigate('/products/category/new')}>
          <PlusCircleFilled />
          <span> Add</span>
        </Button> */}

        {/* <Table
          rowSelection={{
            ...rowSelection
          }}
          // scroll={{ x: true }}
          scroll={{ y: '65vh', x: true }}
          columns={columns}
          dataSource={categoryList ? categoryList : []}
          rowKey={'id'}
          loading={isLoading}
          // loading={locationsLoading}
        /> */}
        <CustomizeTable
          form={form}
          columns={columns}
          data={allCategoryList.results}
          usersLoading={isLoading}
          notshowPagination={true}
          // toshowcustomPagintion={true}
          // onPagination={onPagination}
          customScroll={{ x: 600, y: '75vh' }}
          paginationDatas={{
            page: page,
            total: allCategoryList.total,
            size: size,
            onPagination
          }}
          toSort={handleChange}
          tableName={'category-list'}
        />
        <LocationFilterDrawer visible={visible} onClose={onClose} />
      </AppContent>
    </div>
  );
};

export default CategoryList;
