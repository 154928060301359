import { Button, Card, Form, message, PageHeader, Select } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';

import AppContent from '@/components/Common/Content/Content';
import UserWithNoAgent from '@/components/Common/UserWithNoAgent';
import GoBackButton from '@/components/Common/GoBackButton/GoBackButton';
import { LocationSearch } from '@/components/Common/LocationSearch/LocationSearch';
import { create_agents_mutation, update_agents_mutation } from '@/services/agents/mutation';
import { User } from '@/services/users/types';
import { AgentsDB } from '@/store/localstorage/AgentsDB';
import { get_user_details } from '@/services/users/queries';
import { useFilterStore } from '@/store/zustand';
import { ListPage } from '@/constants/list.enum';

interface FormValues {
  userId: number;
  locationId: number;
}

type UserType = 'User' | 'Customer' | 'Vendor';
type BreadCrumb = { label: string; link: string }[];

interface EditDataProps extends FormValues {
  id: number;
  user: string;
  isDisabled: boolean;
}

type Props =
  | { mode: 'create'; breadcrumb: BreadCrumb }
  | {
      mode: 'edit';
      data: EditDataProps;
      breadcrumb: BreadCrumb;
    };

function AgentForm(props: Props) {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [type, setType] = useState<UserType>('User');

  const [isLoading, setIsLoading] = useState(false);
  const zustandFilter = useFilterStore();

  useEffect(() => {
    if (props.mode === 'edit') {
      const initialValue = {
        userId: props.data.userId,
        locationId: props.data.locationId
      };

      form.setFieldsValue(initialValue);
    }
  }, [props.mode]);

  const createUserMutation = useMutation(create_agents_mutation, {
    onSuccess: async (agent) => {
      message.success('Agent created successfully');

      const userdetails = (await get_user_details(agent.userId)).user as User & {
        id: number;
        disabled: boolean;
      };

      zustandFilter.resetState(ListPage.AGENTS);
      const agentWithUser = { ...agent, user: userdetails };

      // Add Agents to AgentsDB.
      AgentsDB.addAgents([agentWithUser]);
    }
  });

  const updateUserMutation = useMutation(update_agents_mutation, {
    onSuccess: () => {
      message.success('Agent updated successfully');
      zustandFilter.removeData(ListPage.AGENTS);
    }
  });

  function handleTypeChange(type: UserType) {
    setType(type);
    form.setFieldValue('userId', '');
  }

  async function onSubmit(values: FormValues) {
    try {
      setIsLoading(true);
      if (props.mode === 'create') {
        const payload = { ...values, isDisabled: false };
        await createUserMutation.mutate(payload);
      }

      if (props.mode === 'edit') {
        const { id, isDisabled } = props.data;
        const payload = { id, isDisabled, locationId: values.locationId };

        const isLocationChanged = values.locationId !== props.data.locationId;
        if (!isLocationChanged) {
          message.error('Please change the location to update the agent.');
          return;
        }

        await updateUserMutation.mutate(payload);
      }

      navigate('/users/agents');
    } catch (error) {
      const msg = `Failed to ${props.mode === 'edit' ? 'update' : 'create'} agent.`;
      message.error(msg);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <AppContent
      breadcrumbItems={props.breadcrumb}
      backgroundWhite
      button={<GoBackButton onClick={() => navigate(-1)} />}>
      <Card style={{ margin: '15px 0px', borderRadius: '9px' }}>
        <PageHeader title="Agent Details" style={{ padding: '8px 0px' }} />

        <Form
          form={form}
          layout="vertical"
          className="main-div"
          validateTrigger="onChange"
          initialValues={{
            type
          }}
          autoComplete="off"
          disabled={isLoading}
          onFinish={onSubmit}>
          <div className="grid gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
            {props.mode === 'create' && (
              <>
                <Form.Item
                  label="Select Type"
                  name="type"
                  rules={[{ required: true, message: 'Please select user type!' }]}>
                  <Select onChange={handleTypeChange}>
                    <Select.Option value="User">Users</Select.Option>
                    <Select.Option value="Customer">Customers</Select.Option>
                    <Select.Option value="Vendor">Vendors</Select.Option>
                  </Select>
                </Form.Item>
                <UserWithNoAgent
                  key={type}
                  type={type}
                  label={`Select ${type}`}
                  onSelect={(val) => form.setFieldValue('userId', val)}
                />
              </>
            )}

            {props.mode === 'edit' && (
              <Form.Item
                label={'Select User'}
                name="userId"
                rules={[{ required: true, message: 'Please select user!' }]}>
                <Select disabled>
                  <Select.Option value={props.data.userId}>{props.data.user}</Select.Option>
                </Select>
              </Form.Item>
            )}

            <LocationSearch
              formData={{ formName: 'locationId', formLabel: 'Location' }}
              notAll={true}
              required={true}
            />
          </div>
          <Form.Item>
            <Button
              htmlType="submit"
              className="mt-4"
              loading={isLoading}
              style={{ borderRadius: '5px', color: 'white', backgroundColor: '#1890ff' }}>
              {props.mode === 'edit' ? 'Update' : 'Add'} Agent
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </AppContent>
  );
}

export default AgentForm;
