import { CustomModal } from '@/components/Common/CustomModal';
import ActionDropdown from '@/components/Common/Dropdownactions';
import { Button, Menu, message, Tooltip } from 'antd';
import { useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { IRow } from '../grid.types';
import { IExportNestedColumn } from '@/utils/exportNestedExcel';
import { exportOutletToExcel, exportPriceAnalysisAsExcel } from './services';
import NestedTableExport from '@/components/Common/CustomResuableInvoice/NestedTableExport';

interface Props {
  date?: string;
  data: IRow[];
  columns: IExportNestedColumn<IRow>[];
  isDisabled?: boolean;
  total: string;
}

function ExportPriceAnalysis({ columns, isDisabled, data, date, total }: Props) {
  const printPDFRef = useRef(null);
  const [openPDFExportModal, setOpenPDFExportModal] = useState(false);

  const handlePDFExport = useReactToPrint({
    content: () => printPDFRef.current
  });

  const handleOutletExportOnly = (exportChangedOnly = false) => {
    if (data.length === 0) {
      message.error('No data to export');
      return;
    }

    if (!exportChangedOnly) {
      exportOutletToExcel(data, date);
      return;
    }

    const changedData = data.filter((d) => d.isSellRateChanged);
    if (changedData.length === 0) {
      message.error('No changed data to export');
      return;
    }
    exportOutletToExcel(changedData, date);
  };

  const handleExport = (type: 'excel' | 'pdf') => {
    const isDataEmpty = data.length === 0;
    if (isDataEmpty) {
      message.error('No data to export');
      return;
    }

    if (type === 'pdf') {
      setOpenPDFExportModal(true);
      return;
    }

    exportPriceAnalysisAsExcel({ data, columns, date, total });
  };

  return (
    <>
      <div className="flex justify-end">
        <div className="flex gap-2">
          <ActionDropdown
            marginLeft="0px"
            button={true}
            buttonJSX={
              <Button type="primary" className="!rounded-md" disabled={isDisabled}>
                Export All
              </Button>
            }
            menu={
              <Menu
                items={[
                  {
                    key: '1',
                    label: (
                      <Tooltip title="Export Excel" color="blue">
                        <div className="text-center">Excel</div>
                      </Tooltip>
                    ),
                    onClick: () => handleExport('excel')
                  }
                ]}
              />
            }
          />

          <ActionDropdown
            marginLeft="0px"
            button={true}
            buttonJSX={
              <Button type="primary" className="!rounded-md" disabled={isDisabled}>
                Export Outlet
              </Button>
            }
            menu={
              <Menu
                items={[
                  {
                    key: 'all',
                    label: (
                      <Tooltip title="Export All (Both Same and Changed)" color="blue">
                        <div className="text-center">Export All</div>
                      </Tooltip>
                    ),
                    onClick: () => handleOutletExportOnly()
                  },
                  {
                    key: 'unchanged',
                    label: (
                      <Tooltip title="Export Changed Only" color="blue">
                        <div className="text-center">Changed</div>
                      </Tooltip>
                    ),
                    onClick: () => handleOutletExportOnly(true)
                  }
                ]}
              />
            }
          />
        </div>
      </div>

      <CustomModal
        footer={false}
        isModalOpen={openPDFExportModal}
        setIsModalOpen={setOpenPDFExportModal}
        title="PDF print">
        <div className="flex justify-end mb-3">
          <Button type="primary" onClick={handlePDFExport}>
            Print Pdf
          </Button>
        </div>
        <div style={{ maxHeight: '80vh', overflow: 'scroll' }}>
          <NestedTableExport
            data={data}
            ref={printPDFRef}
            columns={columns}
            title="Price Analysis"
            subTitle={date}
          />
        </div>
      </CustomModal>
    </>
  );
}

export default ExportPriceAnalysis;
