import AppContent from '@/components/Common/Content/Content';
import withReportInitialization, {
  ReportDownloadInitialValues,
  WithReportInitializationProps
} from '@/components/Common/Report/withReportInitialization';
import { showReportConditionalDownloadMessage } from '@/utils/reportConditionalDownloadMessage';
import { Collapse, message, PageHeader, Spin } from 'antd';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import { useState } from 'react';
import FilterForm from './Form';
import getErrorMessage from '@/utils/getError';
import { check_date_cap, check_export_report_date_cap } from '@/utils/common';
import { convertLocalToUTCString } from '@/utils/convertToUTC';
import { download_report, get_report } from '@/services/report/queries';
import TableData from './Table';
import { IAverageSellReport } from '@/services/report/types';

export type ExtendedInitialValues = ReportDownloadInitialValues & {
  constraints: {
    customerId: string | number;
    routeId: string | number;
    categoryId: string | number;
    type: string;
    locationId: string | number;
  };
};

function SaleAverageReport({ form, initialValues, ...handlers }: WithReportInitializationProps) {
  const report = { id: 81, name: 'Average Sell Report' };
  const breadcrumbItems = [{ label: 'Reports', link: '/reports' }, { label: 'Average Sells' }];

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<IAverageSellReport[]>([]);
  const [isEmpty, setIsEmpty] = useState(false);

  const extendedInitialValues = {
    ...initialValues,
    constraints: {
      ...initialValues.constraints,
      customerId: '',
      routeId: '',
      locationId: '',
      categoryId: '',
      type: '',
      page: 1,
      size: -1
    }
  };

  async function onSearch(values: ExtendedInitialValues) {
    setIsLoading(true);

    try {
      const constraints = { ...values.constraints };
      // Check if the date cap is valid or not
      const hasDateCap = check_date_cap(constraints.startDate, constraints.endDate);
      if (hasDateCap) {
        await onDownload();
        return;
      }

      if ('dateCustom' in constraints) {
        delete constraints.dateCustom;
      }

      constraints.startDate = convertLocalToUTCString(constraints.startDate);
      constraints.endDate = convertLocalToUTCString(constraints.endDate);

      const { data } = await get_report(constraints, report.id);
      setData(data);
      setIsEmpty(!data.length);
    } catch (error) {
      message.error(getErrorMessage(error));
    } finally {
      setIsLoading(false);
    }
  }

  async function onDownload() {
    if (isEmpty) {
      showReportConditionalDownloadMessage();
      return;
    }

    setIsLoading(true);
    try {
      // Get the form values and validate them
      const values = form.getFieldsValue() as ExtendedInitialValues;
      await form.validateFields();

      // Make shallow copy of values.constraints
      const constraints = { ...values.constraints };
      if ('dateCustom' in constraints) {
        delete constraints.dateCustom;
      }

      // Convert the date to UTC string
      constraints.startDate = convertLocalToUTCString(constraints.startDate);
      constraints.endDate = convertLocalToUTCString(constraints.endDate);

      // Check if the date cap is valid or not
      const hasExportReportCap = check_export_report_date_cap(
        constraints.startDate,
        constraints.endDate
      );

      if (!hasExportReportCap) return;
      message.info('Report is being generated. Download will start soon.');
      await download_report(constraints, report.id);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Spin spinning={isLoading}>
      <AppContent breadcrumbItems={breadcrumbItems}>
        <Collapse>
          <CollapsePanel header="Filters" key="1">
            <FilterForm
              form={form}
              isLoading={isLoading}
              initialValues={extendedInitialValues}
              setIsEmpty={setIsEmpty}
              // This is the function that is called when the form is submitted
              onSearch={onSearch}
              onChange={handlers.onChange}
              onDownload={onDownload}
              onDisabledDate={handlers.onDisabledDate}
              onChangeNepali={handlers.onChangeNepali}
            />
          </CollapsePanel>
        </Collapse>

        <PageHeader subTitle="Table" style={{ padding: '8px 0px' }} />
        <TableData data={data} />
      </AppContent>
    </Spin>
  );
}

export default withReportInitialization(SaleAverageReport);
