// components/UpdateProgressSection.tsx

import { useEffect, useMemo, useState } from 'react';
import { Alert, Button, List, Progress, Tabs } from 'antd';
import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  ExclamationCircleTwoTone,
  LoadingOutlined
} from '@ant-design/icons';
import type { IWooCommerceMap } from '@/services/web-sockets/types';
import { cn } from '@/utils';
import { IProductDetails } from '@/services/products/types';
import ProductsDB from '@/store/localstorage/ProductsDB';

const { TabPane } = Tabs;

type Props = {
  label?: string;
  dataKey: 'stock-update' | 'product-create-update';
  wooDataMap: Partial<IWooCommerceMap>;
  visible: boolean;
  setLoading: (loading: boolean) => void;
  getTotalItems: () => number;
  onClose: () => void;
  onRetry?: (failedProducts: IProductDetails[]) => Promise<void>;
  showTotalItems: boolean;
};

export default function UpdateProgressSection({
  label,
  dataKey,
  wooDataMap,
  visible,
  setLoading,
  getTotalItems,
  onClose,
  onRetry,
  showTotalItems
}: Props) {
  const socketData = wooDataMap?.[dataKey];
  const [error, setError] = useState<string | null>(null);
  const [productMap, setProductMap] = useState<Record<string, IProductDetails>>({});
  const [absentSkus, setAbsentSkus] = useState<Set<string>>(new Set());

  useEffect(() => {
    if (!socketData) return;

    const allSkus = new Set<string>();
    socketData.data.forEach((entry) => {
      entry.productsSku?.forEach((sku) => allSkus.add(sku));
    });

    const alreadyFetched = new Set([...Object.keys(productMap), ...Array.from(absentSkus)]);
    const skusToFetch = Array.from(allSkus).filter((sku) => !alreadyFetched.has(sku));

    if (skusToFetch.length > 0) {
      ProductsDB.getProductBySKU(skusToFetch).then(({ present, absentSkus }) => {
        // Update productMap
        const newMap = { ...productMap };
        present.forEach((p) => {
          newMap[p.sku] = p;
        });
        setProductMap(newMap);
        setAbsentSkus((prev) => new Set([...prev, ...absentSkus]));
      });
    }
  }, [socketData]);

  const successMessages = useMemo(
    () => [...(socketData?.messages.success ?? [])].reverse(),
    [socketData]
  );
  const errorMessages = useMemo(
    () => [...(socketData?.messages.error ?? [])].reverse(),
    [socketData]
  );

  const likelyFailedProducts = useMemo(() => {
    return Object.values(productMap).filter(
      (product) =>
        !successMessages.some((successName) =>
          successName.toLowerCase().includes(product.name.toLowerCase())
        )
    );
  }, [productMap, successMessages]);

  const totalProcessed = successMessages.length + errorMessages.length;
  const totalItems = getTotalItems?.() ?? 0;

  const hasFinished = useMemo(() => {
    return socketData?.data?.some((d) => d.message === 'Finished processing.');
  }, [socketData]);

  const progress = useMemo(() => {
    if (hasFinished) return 100;
    if (!totalItems) return 0;
    return Math.min(Math.round((totalProcessed / totalItems) * 100), 100);
  }, [hasFinished, totalProcessed, totalItems, showTotalItems]);

  useEffect(() => {
    if (!visible || !socketData) return;
    if (dataKey === 'stock-update') {
      setLoading(!hasFinished);
    }
  }, [socketData, visible, setLoading, hasFinished]);

  useEffect(() => {
    const batchWithError = socketData?.data.find(
      (d) => d.wooError && Object.keys(d.wooError).length > 0
    );

    if (batchWithError?.wooError?.message) {
      setError(batchWithError.wooError.message);
      return;
    }

    if (errorMessages.length) {
      const last = errorMessages.at(-1);
      if (typeof last === 'object' && last?.error?.message) {
        setError(last.error.message);
      } else if (typeof last === 'string') {
        setError(last);
      } else {
        setError(JSON.stringify(last));
      }
      return;
    }

    setError(null);
  }, [socketData, errorMessages]);

  return (
    <div className="space-y-4">
      <div className="flex justify-between gap-2">
        {label && <h3 className="font-semibold text-lg">{label}</h3>}
        {showTotalItems && (
          <div
            className={cn('text-sm text-gray-600 mt-2 flex gap-2', {
              'justify-between flex-1': !label
            })}>
            <span>Items Processed:</span>
            <span>
              {totalProcessed}/{totalItems}
            </span>
          </div>
        )}
      </div>

      <div>
        {!hasFinished && <LoadingOutlined style={{ fontSize: 18 }} />}
        <Progress
          percent={progress}
          status={error ? 'exception' : progress === 100 ? 'success' : 'active'}
        />
      </div>

      {error && (
        <Alert
          message={`An error occurred during processing`}
          description={
            <div>
              <div>{error}</div>

              {likelyFailedProducts.length > 0 && (
                <div className="text-[13px] text-gray-500 mt-2">
                  <strong>Possible Failed Products:</strong>
                  <ul className="list-disc pl-5">
                    {likelyFailedProducts.map((p) => (
                      <li key={p.sku}>
                        {p.name} ({p.sku})
                      </li>
                    ))}
                  </ul>
                </div>
              )}

              {absentSkus.size > 0 && (
                <div className="text-xs text-yellow-600 mt-2">
                  <strong>Missing SKUs:</strong> {Array.from(absentSkus).join(', ')}
                </div>
              )}
            </div>
          }
          type="error"
          showIcon={false}
          icon={<ExclamationCircleTwoTone twoToneColor="#ff4d4f" />}
        />
      )}

      <Tabs defaultActiveKey="success">
        <TabPane tab={`Success (${successMessages.length})`} key="success">
          <List
            size="small"
            bordered
            dataSource={successMessages}
            className="max-h-64 overflow-auto"
            renderItem={(item) => (
              <List.Item>
                <CheckCircleTwoTone twoToneColor="#52c41a" className="mr-2" />
                {item}
              </List.Item>
            )}
          />
        </TabPane>
        <TabPane tab={`Failed (${errorMessages.length})`} key="failed">
          <List
            size="small"
            bordered
            dataSource={errorMessages}
            className="max-h-64 overflow-auto"
            renderItem={(item) => {
              const msg =
                typeof item === 'string' ? item : item?.error?.message ?? JSON.stringify(item);
              return (
                <List.Item>
                  <CloseCircleTwoTone twoToneColor="#ff4d4f" className="mr-2" />
                  {msg}
                </List.Item>
              );
            }}
          />
        </TabPane>
      </Tabs>

      {errorMessages.length > 0 && onRetry && hasFinished && (
        <div className="flex justify-end pt-2">
          <Button
            type="primary"
            onClick={() => {
              setLoading(true);
              onClose();
              onRetry(likelyFailedProducts);
            }}>
            Retry
          </Button>
        </div>
      )}
    </div>
  );
}
