export enum OrderStatus {
  // All = 'any',
  Pending = 'pending',
  Processing = 'processing',
  OnHold = 'on-hold',
  Completed = 'completed',
  Cancelled = 'cancelled',
  Refunded = 'refunded',
  Failed = 'failed',
  Trash = 'trash'
}

export const OrderStatusColor = {
  [OrderStatus.Pending]: 'orange',
  [OrderStatus.Processing]: 'blue',
  [OrderStatus.Completed]: 'green',
  [OrderStatus.OnHold]: 'purple',
  [OrderStatus.Cancelled]: 'red',
  [OrderStatus.Refunded]: 'cyan',
  [OrderStatus.Failed]: 'magenta',
  [OrderStatus.Trash]: 'red'
} as Record<OrderStatus, string>;
