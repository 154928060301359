import { useState } from 'react';

function useCache<K extends string | number, T>(fetcher: (key: K) => Promise<T>) {
  const [data, setData] = useState<Record<K, T>>({} as Record<K, T>);

  const cache = (key: K, value: T) => {
    setData((prev) => ({ ...prev, [key]: value }));
  };

  const get = async (key: K): Promise<T> => {
    if (key in data) {
      return data[key];
    }
    const value = await fetcher(key);
    cache(key, value);
    return value;
  };

  return { cache, get };
}

export default useCache;
