import { getUser } from '@/services';
import { get_logs_support_detail } from '@/services/logs/support/queries';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Form, Spin, PageHeader, Tag, Button, Modal, Switch, Input, message } from 'antd';
import { useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import AppContent from '@/components/Common/Content/Content';
import { getParsedJSON } from '@/utils';
import { ILogSupportUpdate } from '@/services/logs/support/types';
import { update_logs_support_mutaion } from '@/services/logs/support/mutation';
import { useFilterStore } from '@/store/zustand';
import { ListPage } from '@/constants/list.enum';

interface FormValues {
  isSolved: boolean;
  solvedMessage: string;
}

function ViewLogSupport() {
  const [isLoading, setIsLoading] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [form] = Form.useForm<FormValues>();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const zustandFilter = useFilterStore();

  const params = useParams();
  const logSupportId = Number(params.id);

  if (isNaN(logSupportId)) return <Navigate to="/logs/support" />;

  const {
    data,
    isLoading: isDetailLoading,
    isFetching
  } = useQuery(['get_logs_support_details', logSupportId], async () => {
    const response = await get_logs_support_detail(logSupportId);

    if (response.userId) {
      const user = await getUser(response.userId);
      response.requestByName = user.name;
    }

    const parsedBody = response.requestBody ? getParsedJSON(response.requestBody) : {};
    const parsedResponse = response.content ? getParsedJSON(response.content) : {};

    return { ...response, parsedBody, parsedResponse };
  });

  const mutation = useMutation(
    (payload: ILogSupportUpdate) => {
      return update_logs_support_mutaion(logSupportId, payload);
    },
    {
      onSuccess: () => zustandFilter.removeData(ListPage.SUPPORT_LOG)
    }
  );

  const handleEdit = () => {
    form.setFieldsValue({
      isSolved: data?.isSolved,
      solvedMessage: data?.solvedMessage || ''
    });
    setOpenEditModal(true);
  };

  const handleSave = async () => {
    try {
      setIsLoading(true);
      const values = await form.validateFields();
      await mutation.mutateAsync(values);

      message.success('Log updated successfully');
      queryClient.invalidateQueries(['get_logs_support_details', logSupportId]);
      setOpenEditModal(false);
    } catch (error) {
      console.error('Validation failed:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Spin spinning={isLoading || isDetailLoading || isFetching}>
      <Modal
        title="Edit Log Support"
        visible={openEditModal}
        onCancel={() => setOpenEditModal(false)}
        onOk={handleSave}
        okText="Save"
        cancelText="Cancel">
        <Spin spinning={isLoading || isDetailLoading || isFetching}>
          <Form form={form} layout="vertical">
            <Form.Item name="isSolved" label="Is Solved" valuePropName="checked">
              <Switch />
            </Form.Item>
            <Form.Item
              name="solvedMessage"
              label="Solved Message"
              rules={[{ required: true, message: 'Please enter a solved message' }]}>
              <Input.TextArea rows={4} />
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
      <AppContent
        breadcrumbItems={[
          { label: 'Log', link: '/logs' },
          { label: 'Support', link: '/logs/support' },
          { label: 'View' }
        ]}>
        <PageHeader
          className="site-page-header"
          style={{ padding: '8px 0' }}
          title="Log Details"
          extra={
            <>
              <Tag color={data?.isSolved ? 'green' : 'red'}>
                {data?.isSolved ? 'Solved' : 'Unsolved'}
              </Tag>
            </>
          }
        />
        <div className="mb-6">
          <h4 className="text-lg font-semibold mb-2">Endpoint Details</h4>
          <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-2 [&>p]:my-0">
            <p>Url: {data?.url}</p>
            <p>Method: {data?.method}</p>
            <p>Status Code: {data?.statusCode}</p>
          </div>
        </div>

        <div className="mb-6">
          <h4 className="text-lg font-semibold mb-2">Requested Details</h4>
          <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-2 [&>p]:my-0">
            <p>Requested By: {data?.requestByName}</p>
            <p>Front Version: {data?.frontVersion}</p>
          </div>
        </div>

        <div>
          <h4 className="text-lg font-semibold mb-2">Details</h4>
          <div className="grid grid-cols-1 sm:grid-cols-2">
            <div>
              <p className="text-md font-semibold mb-2">Request Body</p>
              <pre
                style={{
                  padding: '10px',
                  background: '#f2f2f2',
                  borderRadius: '5px',
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                  overflowX: 'auto',
                  height: 'calc(100% - 30px)',
                  maxHeight: '400px',
                  border: '1px solid black',
                  borderRightWidth: 0,
                  marginBottom: 0
                }}>
                <code>
                  {typeof data?.parsedBody === 'object'
                    ? JSON.stringify(data?.parsedBody, null, 2)
                    : data?.parsedBody || 'No request body'}
                </code>
              </pre>
            </div>
            <div>
              <p className="text-md font-semibold mb-2">Response</p>
              <pre
                style={{
                  background: '#fff',
                  padding: '10px',
                  borderRadius: '5px',
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                  overflowX: 'auto',
                  height: 'calc(100% - 30px)',
                  maxHeight: '400px',
                  border: '1px solid black',
                  borderLeftWidth: 0,
                  marginBottom: 0
                }}>
                <code>
                  {typeof data?.parsedResponse === 'object'
                    ? JSON.stringify(data?.parsedResponse, null, 2)
                    : data?.parsedResponse || 'No response content'}
                </code>
              </pre>
            </div>
          </div>
        </div>

        {data?.solvedMessage && (
          <div className="mt-5">
            <h4 className="text-lg font-semibold mb-2">Feedback</h4>
            <p>{data?.solvedMessage}</p>
          </div>
        )}

        <div className="flex justify-end mt-5 gap-1.5">
          <Button type="primary" onClick={handleEdit}>
            Give Feedback
          </Button>

          <Button type="primary" onClick={() => navigate(-1)} ghost>
            Go Back
          </Button>
        </div>
      </AppContent>
    </Spin>
  );
}

export default ViewLogSupport;
