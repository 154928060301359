import { Button, DatePicker, Form, Input, InputNumber, PageHeader } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import { FormValues } from '.';
import { useState } from 'react';
import moment from 'moment';
import ProductCategorySearchV2 from '@/components/Common/CustomSearch/ProductCategory';
import ProductbyCategory from '@/components/Common/CustomSearch/Products/ProductsByCategory';
import UnitSearch, { IProductUnitId } from '@/components/Common/CustomSearch/Units';
import { IProductDetails } from '@/services/products/types';
import LocationSearchV2 from '@/components/Common/CustomSearch/Location';
import { find_date_preference } from '@/store/localstorage/preferences';
import { NepaliDatePicker } from 'nepali-datepicker-reactjs';
import { convert_nepali_to_english_date } from '@/utils/nepaliDateConverter';
import VendorSearchV2 from '@/components/Common/CustomSearch/Vendors';

interface Props {
  form: FormInstance;
  isLoading: boolean;
  onSearch: (values: FormValues) => void;
  onDownload: () => void;
  setIsEmpty: React.Dispatch<React.SetStateAction<boolean>>;
}

function FilterForm({ isLoading, form, ...handler }: Props) {
  const nepaliType = find_date_preference();
  const [categoryId, setCategoryId] = useState<number | null>(null);
  const [productUnitIds, setProductUnitIds] = useState<IProductUnitId[]>([]);

  const locationId = Form.useWatch('locationId', form);

  function resetEmpty() {
    handler.setIsEmpty(false);
  }

  function onProductChange(productDetails?: IProductDetails) {
    if (!productDetails) {
      form.setFieldValue(['unitId'], '');
      return setProductUnitIds([]);
    }

    const units = productDetails.productUnits.map((value) => ({
      id: value.unitId,
      isDefault: value.isDefault
    }));

    setProductUnitIds(units);
    form.setFieldValue(['unitId'], '');
  }

  function onDisabledDate(current: moment.Moment | null) {
    return current ? current.isAfter(moment()) : false;
  }

  const onChangeNepali = (val: string) => {
    form.setFieldValue('endDate', convert_nepali_to_english_date(val));
  };

  return (
    <Form
      form={form}
      layout="vertical"
      autoComplete="off"
      disabled={isLoading}
      onFieldsChange={resetEmpty}
      validateTrigger="onChange"
      onFinish={handler.onSearch}>
      <PageHeader subTitle="Constraints" style={{ padding: '0px 0px' }} />

      <div className="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 ">
        <Form.Item label={'page'} name={['page']} hidden>
          <InputNumber controls={false} />
        </Form.Item>

        <Form.Item label={'size'} name={['size']} hidden>
          <InputNumber controls={false} />
        </Form.Item>

        <VendorSearchV2 name={['vendorId']} showAll hasParentFormItem={false} />

        <Form.Item
          label={'Before Date'}
          name={['endDateMoment']}
          rules={[{ required: true, message: 'Please add date!' }]}
          hidden={nepaliType}>
          <DatePicker
            showTime
            className="w-full"
            disabledDate={onDisabledDate}
            format={'YYYY-MM-DD HH:mm'}
            onChange={(date, dateString) => form.setFieldValue('endDate', dateString)}
            allowClear={false}
          />
        </Form.Item>

        <Form.Item label={'Before Date'} name={['endDate']} hidden>
          <Input />
        </Form.Item>

        <Form.Item label={'Before Date'} name={['endDateNepali']} hidden={!nepaliType}>
          <NepaliDatePicker
            className="w-full"
            onChange={onChangeNepali}
            options={{ calenderLocale: 'ne', valueLocale: 'en' }}
          />
        </Form.Item>

        <LocationSearchV2 hasParentFormItem={false} showAll name={['locationId']} />

        <ProductCategorySearchV2
          showAll
          hasParentFormItem={false}
          name={['categoryId']}
          onSelect={(val: number | string) => {
            val === '' ? setCategoryId(null) : setCategoryId(val as number);
            form.setFieldValue(['productId'], '');
            onProductChange();
          }}
        />

        <ProductbyCategory
          categoryId={categoryId}
          hasParentFormItem={false}
          showAll
          locationId={locationId}
          setSelected={onProductChange}
          name={['productId']}
        />

        <UnitSearch
          hasParentFormItem={false}
          showAll
          name={['unitId']}
          selectedProductUnitIds={productUnitIds}
        />

        <div className="flex mt-7 gap-1">
          <Form.Item>
            <Button type="primary" htmlType="submit" style={{ borderRadius: '6px' }}>
              Search
            </Button>
          </Form.Item>

          <Form.Item>
            <Button
              className="secondary-button"
              style={{ borderRadius: '6px' }}
              onClick={handler.onDownload}>
              Download
            </Button>
          </Form.Item>
        </div>
      </div>
    </Form>
  );
}

export default FilterForm;
