import { Form, Input, InputNumber } from 'antd';
import { getUserData } from '@/utils/auth.utils';
import { useEffect, useState } from 'react';
import { find_date_preference } from '@/store/localstorage/preferences';
import {
  convert_nepali_to_english_date,
  convert_string_to_nepali_date_string
} from '@/utils/nepaliDateConverter';
import { CustomDatePresets } from '../utils/datePresets';
import DatePicker, { RangePickerProps } from 'antd/lib/date-picker';
import { ColumnsType } from 'antd/lib/table';
import { convertUTCStringtoLocalString } from '@/utils/convertToUTC';
import moment from 'moment';
import CustomReport from './report';
import { LocationSearch } from '@/components/Common/LocationSearch/LocationSearch';
import { NepaliDatePicker } from 'nepali-datepicker-reactjs';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';

import { TableProps } from 'antd/es/table';
import { SorterResult } from 'antd/lib/table/interface';
import ProductCategorySearchV2 from '@/components/Common/CustomSearch/ProductCategory';
import ProductbyCategory from '@/components/Common/CustomSearch/Products/ProductsByCategory';

const { RangePicker } = DatePicker;

const StockHistoryReport = () => {
  const [form] = Form.useForm();
  const nepaliType = find_date_preference();
  const { preferences } = getUserData();
  const preferenceLocationId = preferences?.preferences
    ? JSON.parse(preferences?.preferences)?.locationId
    : undefined;

  const locationId = Form.useWatch(['constraints', 'locationId'], form);

  const [categoryId, setCategoryId] = useState<number | null>(null);

  const [_, setSortedInfo] = useState<SorterResult<any>>({});

  const handleChange: TableProps<any>['onChange'] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<any>);
  };

  useEffect(() => {
    const nepaliDateStart = convert_string_to_nepali_date_string(
      CustomDatePresets.Today[0].format('YYYY-MM-DD HH:mm')
    );
    const nepaliDateEnd = convert_string_to_nepali_date_string(
      CustomDatePresets.Today[0].format('YYYY-MM-DD HH:mm')
    );
    form.setFieldValue('constraints', {
      page: 1,
      size: 100,
      categoryId: '',
      productId: '',
      locationId: preferenceLocationId ? preferenceLocationId : '',
      dateCustom: [...CustomDatePresets.Today],
      startDate: CustomDatePresets.Today[0].format('YYYY-MM-DD HH:mm'),
      endDate: CustomDatePresets.Today[1].format('YYYY-MM-DD HH:mm'),
      startDateNepali: nepaliDateStart,
      endDateNepali: nepaliDateEnd
    });
  }, []);

  const breadcrumbItems = [
    {
      label: 'Reports',
      link: '/reports'
    },
    {
      label: 'Stock History Report',
      link: '/reports/stock-history'
    }
  ];

  const onChange: RangePickerProps['onChange'] = (dates, dateStrings) => {
    if (dateStrings.length == 2) {
      form.setFieldValue(['constraints', 'startDate'], dateStrings[0]);
      form.setFieldValue(['constraints', 'endDate'], dateStrings[1]);
    }
  };

  const columns: ColumnsType = [
    {
      title: 'S.N',
      key: 'SN',
      width: 20,
      render: (text, record, index) => {
        return <div color="black">{index + 1}</div>;
      }
    },
    {
      title: 'Category',
      dataIndex: 'category_name',
      width: 50,
      render: (text: string) => <div>{text}</div>
    },
    {
      title: 'Name',
      dataIndex: 'product_name',
      width: 50,
      render: (text: string) => <div>{text}</div>
    },
    {
      title: 'Location',
      dataIndex: 'location_name',
      width: 40,
      render: (text: string) => <div>{text}</div>
    },
    {
      title: 'Date',
      key: 'date',
      width: 30,
      render: (record) => <div>{convertUTCStringtoLocalString(record.date)}</div>
    },
    {
      title: 'Opening',
      key: 'qty_opening',
      width: 30,
      render: (record) => (
        <div>
          {nepaliNumberFormatter(record.qty_opening)} {record.unit}
        </div>
      )
    },
    {
      title: 'Closing',
      key: 'qty_closing',
      width: 30,
      render: (record) => (
        <div>
          {nepaliNumberFormatter(record.qty_closing)} {record.unit}
        </div>
      )
    },

    {
      title: 'Sold',
      key: 'qty_sold',
      width: 30,
      render: (record) => (
        <div>
          {nepaliNumberFormatter(record.qty_sold)} {record.unit}
        </div>
      )
    },
    {
      title: 'Sold Avg Price',
      key: 'qty_sold_price',
      width: 30,
      render: (record) => <div>{nepaliNumberFormatter(record.qty_sold_price)}</div>
    },
    {
      title: 'Returned Sell',
      key: 'qty_returned_sell',
      width: 30,
      render: (record) => (
        <div>
          {nepaliNumberFormatter(record.qty_returned_sell)} {record.unit}
        </div>
      )
    },
    {
      title: 'Returned Sell Avg Price',
      key: 'qty_returned_sell_price',
      width: 30,
      render: (record) => <div>{nepaliNumberFormatter(record.qty_returned_sell_price)}</div>
    },
    {
      title: 'Purchased',
      key: 'qty_purchased',
      width: 30,
      render: (record) => (
        <div>
          {nepaliNumberFormatter(record.qty_purchased)} {record.unit}
        </div>
      )
    },
    {
      title: 'Purchased Avg Price',
      key: 'qty_purchased_price',
      width: 30,
      render: (record) => <div>{record.qty_purchased_price}</div>
    },
    {
      title: 'Returned Purchased',
      key: 'qty_returned_purchase',
      width: 30,
      render: (record) => (
        <div>
          {record.qty_returned_purchase} {record.unit}
        </div>
      )
    },
    {
      title: 'Returned Purchased Avg Price',
      key: 'qty_returned_purchase_price',
      width: 30,
      render: (record) => <div>{record.qty_returned_purchase_price}</div>
    },

    {
      title: 'Transferred',
      key: 'qty_transferred',
      width: 30,
      render: (record) => (
        <div>
          {record.qty_transferred} {record.unit}
        </div>
      )
    },
    {
      title: 'Transferred Avg Price',
      key: 'qty_transferred_price',
      width: 30,
      render: (record) => <div>{record.qty_transferred_price}</div>
    },
    {
      title: 'Transferred Out',
      key: 'qty_transferred_out',
      width: 30,
      render: (record) => (
        <div>
          {record.qty_transferred_out} {record.unit}
        </div>
      )
    },
    {
      title: 'Transferred Out Avg Price',
      key: 'qty_transferred_out_price',
      width: 30,
      render: (record) => <div>{record.qty_transferred_out_price}</div>
    },
    {
      title: 'Qty Adjusted',
      key: 'qty_adjusted',
      width: 30,
      render: (record) => (
        <div>
          {record.qty_adjusted} {record.unit}
        </div>
      )
    },
    {
      title: 'Qty Adjusted Avg Price',
      key: 'qty_adjusted_price',
      width: 30,
      render: (record) => <div>{record.qty_adjusted_price}</div>
    },
    {
      title: 'Qty Opening Received',
      key: 'qty_opening_received',
      width: 30,
      render: (record) => (
        <div>
          {record.qty_opening_received} {record.unit}
        </div>
      )
    },
    {
      title: 'Qty Opening Received Avg Price',
      key: 'qty_opening_received_price',
      width: 30,
      render: (record) => <div>{record.qty_opening_received_price}</div>
    },
    {
      title: 'Profit Per Unit',
      key: 'profit_per_unit',
      width: 30,
      render: (record) => <div>{nepaliNumberFormatter(record.profit_per_unit)}</div>
    },
    {
      title: 'Total Profit',
      key: 'total_profit',
      width: 30,
      render: (record) => <div>{nepaliNumberFormatter(record.total_profit)}</div>
    }
  ];

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    // Can not select days before today and today
    return current && current > moment().endOf('day');
  };

  const onChangeNepali = (val: string, isStart: boolean) => {
    if (isStart)
      form.setFieldValue(['constraints', 'startDate'], convert_nepali_to_english_date(val));
    else form.setFieldValue(['constraints', 'endDate'], convert_nepali_to_english_date(val));
  };

  return (
    <CustomReport
      breadcrumbItems={breadcrumbItems}
      state={{ id: 10, name: 'Stock History Report' }}
      form={form}
      toSort={handleChange}
      columnsData={columns as ColumnsType<object>}>
      <Form.Item label={'page'} name={['constraints', 'page']} hidden={true}>
        <InputNumber controls={false} />
      </Form.Item>
      <Form.Item label={'size'} name={['constraints', 'size']} hidden={true}>
        <InputNumber controls={false} />
      </Form.Item>
      <Form.Item label={'size'} name={['constraints', 'identifier']} hidden={true}>
        <InputNumber controls={false} />
      </Form.Item>
      <Form.Item label={'categoryId'} name={['constraints', 'categoryId']} hidden={true}>
        <InputNumber controls={false} />
      </Form.Item>
      <ProductCategorySearchV2
        showAll
        hasParentFormItem={false}
        name={['constraints', 'categoryId']}
        onSelect={(val: any) => {
          val === '' ? setCategoryId(null) : setCategoryId(val as number);
          form.setFieldValue(['constraints', 'productId'], '');
        }}
      />

      <ProductbyCategory
        categoryId={categoryId}
        hasParentFormItem={false}
        showAll
        locationId={locationId}
        name={['constraints', 'productId']}
      />
      <LocationSearch
        formData={{ formName: ['constraints', 'locationId'], formLabel: 'Location' }}
        notAll={false}
      />
      <Form.Item
        label={'Pick a date'}
        name={['constraints', 'dateCustom']}
        rules={[
          {
            required: true,
            message: 'Please select date!'
          }
        ]}
        hidden={nepaliType}>
        <RangePicker
          showTime
          ranges={CustomDatePresets}
          disabledDate={disabledDate}
          onChange={onChange}
          format={'YYYY-MM-DD HH:mm'}
          allowClear={false}
        />
      </Form.Item>
      <Form.Item label={'Start Date'} name={['constraints', 'startDate']} hidden>
        <Input />
      </Form.Item>
      <Form.Item label={'End Date'} name={['constraints', 'endDate']} hidden>
        <Input />
      </Form.Item>
      <Form.Item label={'Select Start Date'} name={['startDateNepali']} hidden={!nepaliType}>
        <NepaliDatePicker
          onChange={(val) => onChangeNepali(val, true)}
          options={{ calenderLocale: 'ne', valueLocale: 'en' }}
        />
      </Form.Item>
      <Form.Item label={'Select End Date'} name={['endDateNepali']} hidden={!nepaliType}>
        <NepaliDatePicker
          onChange={(val) => onChangeNepali(val, false)}
          options={{ calenderLocale: 'ne', valueLocale: 'en' }}
        />
      </Form.Item>
    </CustomReport>
  );
};

export default StockHistoryReport;
