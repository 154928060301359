import AppContent from '@/components/Common/Content/Content';
import { Button, DatePicker, Form, Input, message, Modal, PageHeader, Spin } from 'antd';
import { useState } from 'react';
import moment from 'moment';
import { IHRHolidayPayload } from '@/services/hr/types';
import { update_hr_holiday_mutation } from '@/services/hr/mutations';
import CustomErrorModal from '@/components/Common/CustomErrorModal';
import getErrorMessage from '@/utils/getError';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { get_hr_holiday_details } from '@/services/hr/queries';
import isAxiosError from '@/utils/isAxiosError';
import { useFilterStore } from '@/store/zustand';
import { ListPage } from '@/constants/list.enum';

interface FormValues {
  name: string;
  dates: [moment.Moment, moment.Moment];
  description: string;
}

function HRHolidayUpdate() {
  const [form] = Form.useForm<FormValues>();
  const params = useParams();
  const [isLoading, setIsLoading] = useState(false);

  const zustandFilter = useFilterStore();

  const holidayID = Number(params.id);
  if (isNaN(holidayID)) return <Navigate to="/hr/holiday" />;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  const { isLoading: isParentLoading, isFetching } = useQuery(['holiday', holidayID], async () => {
    const { holiday } = await get_hr_holiday_details(holidayID);

    const startDateMoment = moment(holiday.startDate);
    const endDateMoment = moment(holiday.endDate);

    form.setFieldsValue({
      name: holiday.name,
      description: holiday.description,
      dates: [startDateMoment, endDateMoment]
    });
  });

  async function onFinish(values: FormValues) {
    try {
      setIsLoading(true);
      const [start, end] = values.dates;

      const payload: IHRHolidayPayload = {
        name: values.name,
        description: values.description,
        isDisabled: false,
        startDate: moment(start).toISOString(),
        endDate: moment(end).toISOString()
      };

      await update_hr_holiday_mutation(holidayID, payload);
      zustandFilter.removeData(ListPage.HR_HOLIDAY);
      message.success('Holiday updated successfully');
      navigate('/hr/holiday');
    } catch (error) {
      if (isAxiosError(error)) return;
      CustomErrorModal({ message: getErrorMessage(error) });
    } finally {
      setIsLoading(false);
    }
  }

  async function onConfirmation() {
    setIsModalOpen(false);
    form.submit();
  }

  async function onUpdate() {
    await form.validateFields();
    setIsModalOpen(true);
  }

  const onDisabledDate = (current: moment.Moment | null) => {
    if (!current) return false;
    return current < moment().endOf('day');
  };

  return (
    <Spin spinning={isLoading || isFetching || isParentLoading}>
      <Modal visible={isModalOpen} onCancel={() => setIsModalOpen(false)} onOk={onConfirmation}>
        <p>Are you sure you want to update this holiday?</p>
      </Modal>
      <AppContent
        breadcrumbItems={[{ label: 'Holiday', link: '/hr/holiday' }, { label: 'Update' }]}>
        <PageHeader title="Update Holiday" style={{ padding: '8px 0' }} />
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <div className="grid sm:grid-cols-2 gap-3 md:grid-cols-3 lg:grid-cols-4">
            <Form.Item
              name="name"
              label="Name"
              rules={[{ required: true, message: 'Please enter the holiday name' }]}>
              <Input placeholder="Enter holiday name" />
            </Form.Item>

            <Form.Item
              name="dates"
              label="Date"
              rules={[{ required: true, message: 'Please select the date range' }]}>
              <DatePicker.RangePicker
                className="w-full"
                disabledDate={onDisabledDate}
                showTime
                format="YYYY-MM-DD HH:mm"
              />
            </Form.Item>
          </div>

          <Form.Item
            name="description"
            label="Description"
            rules={[{ required: true, message: 'Please enter a description' }]}>
            <Input.TextArea placeholder="Enter description" rows={4} />
          </Form.Item>

          <div className="flex justify-end mt-4">
            <Button
              type="primary"
              onClick={onUpdate}
              disabled={isLoading || isFetching || isParentLoading}>
              Update Holiday
            </Button>
          </div>
        </Form>
      </AppContent>
    </Spin>
  );
}

export default HRHolidayUpdate;
