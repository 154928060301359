import { IWidgetConfiguration } from '@/services/dashboard/v3/types';
import { get_location_details } from '../../services/locations/queries';
import { ILocations } from '../../services/locations/types';
import { ITableFilter, IUserPreferences } from '../../services/settings/types';
import { getUserData } from '../../utils/auth.utils';
import LocationsDB from './LocationsDB';

const find_check_in_preference = () => {
  const userData = getUserData();
  if (!userData) return false;
  if ('preferences' in userData && userData.preferences) {
    const pref = JSON.parse(userData.preferences.preferences);
    if (pref && 'isCheckIn' in pref) {
      return pref.isCheckIn as boolean;
    }
    return false;
  }
  return false;
};

export const find_dashboard_filter = () => {
  const emptyData = [] as IWidgetConfiguration[];

  const userData = getUserData();
  if (!userData) return emptyData;

  const hasUserPreferences = 'preferences' in userData && userData.preferences;
  if (!hasUserPreferences) return emptyData;

  const userPref = JSON.parse(userData.preferences.preferences);
  const hasDashboard = userPref && userPref['userDashboard'];
  if (!hasDashboard) return emptyData;

  return userPref.userDashboard as IWidgetConfiguration[];
};

export const find_feature_showcase_date = () => {
  const userData = getUserData();
  if (!userData) return;

  const hasUserPreferences = 'preferences' in userData && userData.preferences;
  if (!hasUserPreferences) return;

  try {
    const userPref: IUserPreferences = JSON.parse(userData.preferences.preferences);
    return userPref?.lastFeatureModalShown;
  } catch (error) {
    return;
  }
};

const find_date_preference = () => {
  const userData = getUserData();
  if (!userData) return false;

  if ('preferences' in userData && userData.preferences) {
    const pref = JSON.parse(userData.preferences.preferences);
    if (pref && 'nepaliDate' in pref) {
      return pref.nepaliDate as boolean;
    }
    return false;
  }
  return false;
};

export const find_persist_scroll_preference = () => {
  const userData = getUserData();
  if (!userData) return false;

  const hasUserPreferences = 'preferences' in userData && userData.preferences;
  if (!hasUserPreferences) return false;

  const userPref: IUserPreferences = JSON.parse(userData.preferences.preferences);
  return userPref?.persistScrollPagination || false;
};

export const find_location_id_preference = () => {
  const userData = getUserData();
  if (!userData) return;

  const hasUserPreferences = 'preferences' in userData && userData.preferences;
  if (!hasUserPreferences) return;

  const userPref: IUserPreferences = JSON.parse(userData.preferences.preferences);
  return userPref?.locationId;
};

const find_locationId_preference = () => {
  const userData = getUserData();
  if (!userData) return false;
  if ('preferences' in userData && userData.preferences) {
    const pref = JSON.parse(userData.preferences.preferences);
    if (pref && 'locationId' in pref) {
      return pref.locationId as number;
    }
    return false;
  }
  return false;
};

const find_addressId_preference = () => {
  const userData = getUserData();
  if (!userData) return false;
  if ('preferences' in userData && userData.preferences) {
    const pref = JSON.parse(userData.preferences.preferences);
    if (pref && 'addressId' in pref) {
      return pref.addressId as number;
    }
    return false;
  }
  return false;
};

const find_sell_margin_preference = () => {
  const userData = getUserData();
  if (!userData) return 100000;

  if ('global' in userData && userData.global) {
    const pref = JSON.parse(userData.global.content);
    if (pref && 'sellMargin' in pref) {
      return pref.sellMargin as number;
    }
    return 100000;
  }
  return 100000;
};
const find_purchase_margin_preference = () => {
  const userData = getUserData();
  if (!userData) return 100000;

  if ('global' in userData && userData.global) {
    const pref = JSON.parse(userData.global.content);
    if (pref && 'purchaseMargin' in pref) {
      return pref.purchaseMargin as number;
    }
    return 100000;
  }
  return 100000;
};
const find_wastage_margin_preference = () => {
  const userData = getUserData();
  if (!userData) return 100000;

  if ('global' in userData && userData.global) {
    const pref = JSON.parse(userData.global.content);
    if (pref && 'wastageMargin' in pref) {
      return pref.wastageMargin as number;
    }
    return 100000;
  }
  return 100000;
};
const find_profit_margin_preference = () => {
  const userData = getUserData();
  if (!userData) return 100000;

  if ('global' in userData && userData.global) {
    const pref = JSON.parse(userData.global.content);
    if (pref && 'profitMargin' in pref) {
      return pref.profitMargin as number;
    }
    return 100000;
  }
  return 100000;
};

const find_default_shipping_cost = () => {
  const userData = getUserData();
  if (!userData) return 100000;

  if ('global' in userData && userData.global) {
    const pref = JSON.parse(userData.global.content);
    if (pref && 'defaultShippingCost' in pref) {
      return pref.defaultShippingCost as number;
    }
    return 100;
  }
  return 100;
};

const find_zero_total_shipping = () => {
  const userData = getUserData();
  if (!userData) return 5000;

  if ('global' in userData && userData.global) {
    const pref = JSON.parse(userData.global.content);
    if (pref && 'totalZeroShipping' in pref) {
      return pref.totalZeroShipping as number;
    }
    return 5000;
  }
  return 5000;
};

const find_shipping_cost = (total: number) => {
  if (total < find_zero_total_shipping()) {
    return find_default_shipping_cost();
  }
  return 0;
};

const find_shipping_cost_from_location = async (locationId: number) => {
  let locationDetails: ILocations = (await LocationsDB.getLocation(locationId)) as ILocations;
  if (!locationDetails) {
    locationDetails = await get_location_details(locationId);
    await LocationsDB.addLocations([locationDetails]);
  }

  return locationDetails.shipping as number;
};

const find_time_limit = () => {
  const userData = getUserData();
  if (!userData) return 30;

  if ('global' in userData && userData.global) {
    const pref = JSON.parse(userData.global.content);
    if (pref && 'defaultTime' in pref) {
      return pref.defaultTime as number;
    }
    return 30;
  }
  return 30;
};

const find_report_date_limit = () => {
  const userData = getUserData();
  if (!userData) return 32;

  if ('global' in userData && userData.global) {
    const pref = JSON.parse(userData.global.content);
    if (pref && 'reportDateLimit' in pref) {
      return pref.reportDateLimit as number;
    }
    return 32;
  }
  return 32;
};

const find_fixed_decimal_limit = () => {
  const userData = getUserData();
  if (!userData) return 2;

  if ('global' in userData && userData.global) {
    const pref = JSON.parse(userData.global.content);
    if (pref && 'fixedDecimal' in pref) {
      return pref.fixedDecimal as number;
    }
    return 2;
  }
  return 2;
};

const find_sell_default_grade = () => {
  const userData = getUserData();
  if (!userData) return 'A';

  if ('global' in userData && userData.global) {
    const pref = JSON.parse(userData.global.content);
    if (pref && 'sellGrade' in pref) {
      return pref.sellGrade as string;
    }
    return 'A';
  }
  return 'A';
};

const find_default_product_category = () => {
  const userData = getUserData();
  if (!userData) return null;

  if ('global' in userData && userData.global) {
    const pref = JSON.parse(userData.global.content);
    if (pref && 'categoryId' in pref) {
      return pref.categoryId as number;
    }
    return null;
  }
  return null;
};

const find_invoice_due_limit = () => {
  const userData = getUserData();
  if (!userData) return 1;

  if ('global' in userData && userData.global) {
    const pref = JSON.parse(userData.global.content);
    if (pref && 'invoiceDueLimit' in pref) {
      return pref.invoiceDueLimit as number;
    }
    return 50;
  }
  return 50;
};

const find_invoice_number = () => {
  const userData = getUserData();
  if (!userData) return 1;

  if ('global' in userData && userData.global) {
    const pref = JSON.parse(userData.global.content);
    if (pref && 'invoiceNumber' in pref) {
      return pref.invoiceNumber as number;
    }
    return 0;
  }
  return 0;
};

const find_default_invoice = () => {
  const userData = getUserData();
  if (!userData) return null;

  if ('global' in userData && userData.global) {
    const pref = JSON.parse(userData.global.content);
    if (pref && 'defaultInvoice' in pref) {
      return pref.defaultInvoice as number;
    }
    return null;
  }
  return null;
};

const find_default_pos_invoice = () => {
  const userData = getUserData();
  if (!userData) return null;

  if ('global' in userData && userData.global) {
    const pref = JSON.parse(userData.global.content);
    if (pref && 'defaultPosPrint' in pref) {
      return pref.defaultPosPrint as number;
    }
    return null;
  }
  return null;
};

const find_to_use_NPR = () => {
  const userData = getUserData();
  if (!userData) return 1;

  if ('global' in userData && userData.global) {
    const pref = JSON.parse(userData.global.content);
    if (pref && 'NPR' in pref) {
      return pref.NPR;
    }
    return false;
  }
  return false;
};

const is_invoice_due = (quantity: number) => {
  return quantity > find_invoice_due_limit();
};

const find_financial_year = () => {
  const userData = getUserData();
  if (!userData) return 1;

  if ('global' in userData && userData.global) {
    const pref = JSON.parse(userData.global.content);

    if (pref && 'financialYear' in pref) {
      return pref.financialYear as string;
    }
    return false;
  }
  return false;
};

const find_date = () => {
  const userData = getUserData();
  const currDate = new Date().toISOString().split('T')[0];
  if (!userData) return currDate;

  if ('global' in userData && userData.global) {
    const pref = JSON.parse(userData.global.content);
    if (pref && 'financialYearSchedule' in pref) {
      return pref.financialYearSchedule.date as string;
    }
    return currDate;
  }
  return currDate;
};

const find_value = () => {
  const userData = getUserData();
  const defYear = new Date().getFullYear() + 1;
  if (!userData) return defYear;

  if ('global' in userData && userData.global) {
    const pref = JSON.parse(userData.global.content);
    if (pref && 'financialYearSchedule' in pref) {
      return pref.financialYearSchedule.value as string;
    }
    return defYear;
  }
  return defYear;
};

const find_sell_confirmation = () => {
  const userData = getUserData();
  if (!userData) return 1;

  if ('global' in userData && userData.global) {
    const pref = JSON.parse(userData.global.content);
    if (pref && 'confirmationSell' in pref) {
      return pref.confirmationSell;
    }
    return false;
  }
  return false;
};

const find_purchase_confirmation = () => {
  const userData = getUserData();
  if (!userData) return 1;

  if ('global' in userData && userData.global) {
    const pref = JSON.parse(userData.global.content);
    if (pref && 'confirmationPurchase' in pref) {
      return pref.confirmationPurchase;
    }
    return false;
  }
  return false;
};

const find_expense_confirmation = () => {
  const userData = getUserData();
  if (!userData) return 1;

  if ('global' in userData && userData.global) {
    const pref = JSON.parse(userData.global.content);
    if (pref && 'confirmationExpense' in pref) {
      return pref.confirmationExpense;
    }
    return false;
  }
  return false;
};

const find_adjustment_confirmation = () => {
  const userData = getUserData();
  if (!userData) return 1;

  if ('global' in userData && userData.global) {
    const pref = JSON.parse(userData.global.content);
    if (pref && 'confirmationAdjustment' in pref) {
      return pref.confirmationAdjustment;
    }
    return false;
  }
  return false;
};

const find_column_filter = (tableKey?: string) => {
  const userData = getUserData();
  if (!userData) return [] as string[];
  if ('preferences' in userData && userData.preferences) {
    const userPref = JSON.parse(userData.preferences.preferences);
    if (userPref && 'tableFilters' in userPref && userPref.tableFilters) {
      const pref = userPref.tableFilters.find((value: ITableFilter) => value.tableKey === tableKey);
      if (pref) {
        return pref.filteredKey as string[];
      } else {
        return [] as string[];
      }
    }
  }
  return [] as string[];
};

const find_expense_account = () => {
  const userData = getUserData();
  if (!userData) return null;
  if ('global' in userData && userData.global) {
    const pref = JSON.parse(userData.global.content);
    if (pref && 'expenseAccountId' in pref) {
      return pref.expenseAccountId;
    }
    return null;
  }
  return null;
};

const find_export_report_days_limit = () => {
  const userData = getUserData();
  if (!userData) return 365;
  if ('global' in userData && userData.global) {
    const pref = JSON.parse(userData.global.content);
    if (pref && 'exportReportDaysLimit' in pref) {
      return pref.exportReportDaysLimit as number;
    }
    return 365;
  }
  return 365;
};

export const find_customer_balance_limit = () => {
  const userData = getUserData();
  if (!userData || !userData.global) return 10000;

  const pref = JSON.parse(userData.global.content);
  return pref?.customerAccountBalanceLimitForOffer || 10000;
};

export const find_customer_payment_days = () => {
  const userData = getUserData();
  if (!userData || !userData.global) return 30;

  const pref = JSON.parse(userData.global.content);
  return pref?.customerPaymentDaysForOffer || 30;
};

export const find_email_for_vehicle = () => {
  const userData = getUserData();
  if (!userData || !userData.global) return '';

  const pref = JSON.parse(userData.global.content);
  return pref?.emailForVehicle || '';
};

export const find_vendor_product_url = () => {
  const userData = getUserData();
  if (!userData || !userData.global) return;

  const pref = JSON.parse(userData.global.content);
  return pref?.vendorProductUrl as string | undefined;
};

export const find_scheduler_time_gap = () => {
  const userData = getUserData();
  if (!userData || !userData.global) return;

  const pref = JSON.parse(userData.global.content);
  return pref?.timeGapForSchedulerInMinutes as number;
};

export const find_timezone = () => {
  const userData = getUserData();
  if (!userData || !userData.global) return 'Asia/Kathmandu';

  const pref = JSON.parse(userData.global.content);
  return pref?.timezone || 'Asia/Kathmandu';
};

export const find_accounted_reason = () => {
  const userData = getUserData();
  if (!userData || !userData.global) return;

  const pref = JSON.parse(userData.global.content);
  return pref?.accountedReasonId as number | undefined;
};

export const find_unaccounted_reason = () => {
  const userData = getUserData();
  if (!userData || !userData.global) return;

  const pref = JSON.parse(userData.global.content);
  return pref?.unAccountedReasonId as number | undefined;
};

export const find_leafy_category = () => {
  const userData = getUserData();
  if (!userData || !userData.global) return;

  const pref = JSON.parse(userData.global.content);
  return pref?.leafyItemsCategoryId as number | undefined;
};

export {
  find_check_in_preference,
  find_date_preference,
  find_locationId_preference,
  find_addressId_preference,
  find_sell_margin_preference,
  find_purchase_margin_preference,
  find_wastage_margin_preference,
  find_profit_margin_preference,
  find_default_shipping_cost,
  find_zero_total_shipping,
  find_shipping_cost,
  find_sell_default_grade,
  find_default_product_category,
  find_invoice_due_limit,
  find_invoice_number,
  find_default_invoice,
  find_default_pos_invoice,
  is_invoice_due,
  find_time_limit,
  find_report_date_limit,
  find_fixed_decimal_limit,
  find_to_use_NPR,
  find_shipping_cost_from_location,
  find_financial_year,
  find_date,
  find_value,
  find_sell_confirmation,
  find_purchase_confirmation,
  find_expense_confirmation,
  find_adjustment_confirmation,
  find_column_filter,
  find_expense_account,
  find_export_report_days_limit
};
