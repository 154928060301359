import { useState } from 'react';
import { FormInstance, Menu, TableProps, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { SorterResult } from 'antd/lib/table/interface';

import { IPurchaseAgentBillPurchase } from '@/services/purchases/types';
import CustomizeTable from '@/components/Common/CustomizeTable/CustomizeTable';
import { convertUTCStringtoLocalString } from '@/utils/convertToUTC';
import { DEFAULT_DATE_FORMAT } from '@/constants';
import { Link, useLocation } from 'react-router-dom';
import CopyButton from '@/components/Common/CopyButton';
import TableCell from '@/components/Common/CustomizeTable/CustomCell';

import moment from 'moment';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';
import CustomViewIcon from '@/components/Common/CustomIcons/CustomViewIcon';
import { RollbackOutlined } from '@ant-design/icons';
import { PurchasePaymentModal } from '@/pages/purchases/lists/PaymentModal';
import { checkAccess } from '@/routes/acl';
import DiscussionChatModal from '@/components/Common/DiscussionChatModal';
import ActionDropdown from '@/components/Common/Dropdownactions';
import AgentPurchaseInvoiceModal from './AgentPurchaseInvoice';
import { useFilterStore } from '@/store/zustand';

interface Props {
  isLoading: boolean;
  filter: string;
  form: FormInstance;
  data: { total: number; results: IPurchaseAgentBillPurchase[] };
  pagination: { page: number; size: number };
  getInfo: (filter?: string) => Promise<unknown>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  onPagination: (page?: number, size?: number, isSize?: boolean) => Promise<unknown>;
}

function Table({
  data,
  pagination,
  isLoading,
  filter,
  form,
  onPagination,
  getInfo,
  setIsLoading
}: Props) {
  const [sortedInfo, setSortedInfo] = useState<SorterResult<IPurchaseAgentBillPurchase>>({});

  const handleChange: TableProps<IPurchaseAgentBillPurchase>['onChange'] = (_, __, s) => {
    setSortedInfo(s as SorterResult<IPurchaseAgentBillPurchase>);
  };

  const location = useLocation();
  const zustandFilter = useFilterStore();

  const total = data.results.reduce(
    (acc, current) => {
      acc.total += current.purchase.totalTaxable + current.purchase.totalNonTaxable;
      acc.discount += current.purchase.manualDiscount;
      acc.shipping += current.purchase.shippingAmount;
      acc.shippingTax += current.purchase.shippingTax;
      acc.tax += current.purchase.totalVat;
      acc.grandTotal += current.purchase.totalAmount;
      acc.paid += current.purchase.amountPaid;
      acc.return += current.purchase.amountReturned;
      return acc;
    },
    {
      total: 0,
      discount: 0,
      shipping: 0,
      shippingTax: 0,
      tax: 0,
      grandTotal: 0,
      paid: 0,
      return: 0
    }
  );

  const columns: ColumnsType<IPurchaseAgentBillPurchase> = [
    {
      title: 'S.N.',
      key: 'id',
      width: 3,
      render: (_, __, index) => (pagination.page - 1) * pagination.size + index + 1
    },
    {
      title: 'Bill ID',
      dataIndex: 'billRangeId',
      key: 'billRangeId',
      width: 3,
      render: (id: number) => (
        <TableCell className="flex flex-row justify-between items-center text-xs px-1">
          <Link to={`/purchases/agents-bill-range/view/${id}`}>{id}</Link>
          <CopyButton text={id.toString()} />
        </TableCell>
      )
    },
    {
      title: 'Bill Number',
      dataIndex: 'billNumber',
      key: 'billNumber',
      width: 5,
      sortOrder: sortedInfo.columnKey === 'billNumber' ? sortedInfo.order : null,
      sorter: (a, b) => a.billNumber - b.billNumber
    },
    {
      title: 'Financial Reference',
      key: 'financialReference',
      width: 10,
      sorter: (a, b) => a.purchase.financialReference.localeCompare(b.purchase.financialReference),
      sortOrder: sortedInfo.columnKey === 'financialReference' ? sortedInfo.order : null,
      render: (record) => {
        return (
          <TableCell className="flex flex-row justify-between items-center text-xs px-1">
            <Link to={`/purchase/${record.purchase.id}`}>{record.purchase.financialReference}</Link>
            {record.purchase.financialReference && (
              <CopyButton text={record.purchase.financialReference} />
            )}
          </TableCell>
        );
      }
    },
    {
      title: 'Invoice Number',
      key: 'invoiceNumber',
      className: 'invoice',
      width: 8,
      sorter: (a, b) =>
        (a.purchase?.invoiceNumber || '').localeCompare(b.purchase?.invoiceNumber || ''),
      sortOrder: sortedInfo.columnKey === 'invoiceNumber' ? sortedInfo.order : null,
      render: (record: IPurchaseAgentBillPurchase) => {
        return (
          <TableCell className="flex flex-row justify-between items-center text-xs">
            <div>{record.purchase?.invoiceNumber}</div>
            {record.purchase?.invoiceNumber && <CopyButton text={record.purchase?.invoiceNumber} />}
          </TableCell>
        );
      }
    },
    {
      title: 'Supplier',
      key: 'vendorName',
      width: 8,
      sorter: (a, b) => (a.purchase?.vendorName || '').localeCompare(b.purchase?.vendorName || ''),
      sortOrder: sortedInfo.columnKey === 'vendorName' ? sortedInfo.order : null,
      render: (record) => {
        return <TableCell>{record.purchase?.vendorName}</TableCell>;
      }
    },
    {
      title: 'Supplier Contact',
      key: 'supplierContact',
      width: 5,
      sorter: (a, b) =>
        (a.purchase?.userContact || '').localeCompare(b.purchase?.userContact || ''),
      sortOrder: sortedInfo.columnKey === 'supplierContact' ? sortedInfo.order : null,
      render: (record) => {
        return <TableCell>{record.purchase?.userContact}</TableCell>;
      }
    },
    {
      title: 'Agent',
      key: 'agentName',
      width: 8,
      sorter: (a, b) => (a.purchase?.agentName || '').localeCompare(b.purchase?.agentName || ''),
      sortOrder: sortedInfo.columnKey === 'agentName' ? sortedInfo.order : null,
      render: (record) => {
        return <TableCell>{record.purchase?.agentName}</TableCell>;
      }
    },
    {
      title: 'Buyer Type',
      key: 'purchaseBuyerType',
      width: 5,
      sorter: (a, b) =>
        (a.purchase?.purchaseBuyerType || '').localeCompare(b.purchase?.purchaseBuyerType || ''),
      sortOrder: sortedInfo.columnKey === 'purchaseBuyerType' ? sortedInfo.order : null,
      render: (record) => {
        return <TableCell>{record.purchase?.purchaseBuyerType}</TableCell>;
      }
    },
    {
      title: 'Purchase Type',
      key: 'purchaseType',
      width: 5,
      sorter: (a, b) =>
        (a.purchase?.purchaseType || '').localeCompare(b.purchase?.purchaseType || ''),
      sortOrder: sortedInfo.columnKey === 'purchaseType' ? sortedInfo.order : null,
      render: (record) => {
        return <TableCell>{record.purchase?.purchaseType}</TableCell>;
      }
    },
    {
      title: 'Created By',
      key: 'createdBy',
      width: 7,
      sorter: (a, b) => a.purchase.createdByName.localeCompare(b.purchase.createdByName),
      sortOrder: sortedInfo.columnKey === 'createdBy' ? sortedInfo.order : null,
      render: (record: IPurchaseAgentBillPurchase) => {
        return <TableCell>{record.purchase.createdByName}</TableCell>;
      }
    },

    {
      title: 'Location',
      key: 'locationName',
      width: 7,
      sorter: (a, b) => a.purchase.locationName.localeCompare(b.purchase.locationName),
      sortOrder: sortedInfo.columnKey === 'locationName' ? sortedInfo.order : null,
      render: (record: IPurchaseAgentBillPurchase) => {
        return <TableCell>{record.purchase.locationName}</TableCell>;
      }
    },
    {
      title: 'LCC Location',
      key: 'lccLocationName',
      width: 7,
      sorter: (a, b) =>
        (a.purchase?.lccLocationName || '').localeCompare(b.purchase?.lccLocationName || ''),
      sortOrder: sortedInfo.columnKey === 'lccLocationName' ? sortedInfo.order : null,
      render: (record: IPurchaseAgentBillPurchase) => {
        return <TableCell>{record.purchase.lccLocationName}</TableCell>;
      }
    },
    {
      title: 'Date',
      key: 'date',
      width: 5,
      sorter: (a, b) => moment(a.purchase.date).unix() - moment(b.purchase.date).unix(),
      sortOrder: sortedInfo.columnKey === 'date' ? sortedInfo.order : null,
      render: (record: IPurchaseAgentBillPurchase) => {
        return (
          <TableCell>
            {convertUTCStringtoLocalString(record.purchase.createdAt, DEFAULT_DATE_FORMAT)}
          </TableCell>
        );
      }
    },
    {
      title: 'Total',
      key: 'totalAmount',
      width: 4,
      sorter: (a, b) =>
        a.purchase.totalTaxable -
        b.purchase.totalTaxable +
        a.purchase.totalNonTaxable -
        b.purchase.totalNonTaxable,
      sortOrder: sortedInfo.columnKey === 'totalAmount' ? sortedInfo.order : null,
      render: (record: IPurchaseAgentBillPurchase) => {
        return (
          <TableCell className="text-xs text-right mr-2">
            {nepaliNumberFormatter(record.purchase.totalTaxable + record.purchase.totalNonTaxable)}
          </TableCell>
        );
      }
    },
    {
      title: 'Discount',
      key: 'manualDiscount',
      width: 4,
      sorter: (a, b) => a.purchase.manualDiscount - b.purchase.manualDiscount,
      sortOrder: sortedInfo.columnKey === 'manualDiscount' ? sortedInfo.order : null,
      render: (record: IPurchaseAgentBillPurchase) => {
        return (
          <TableCell className="text-xs text-right mr-2">
            {nepaliNumberFormatter(record.purchase.manualDiscount)}
          </TableCell>
        );
      }
    },
    {
      title: 'Shipping',
      key: 'shippingAmount',
      width: 4,
      sorter: (a, b) => a.purchase.shippingAmount - b.purchase.shippingAmount,
      sortOrder: sortedInfo.columnKey === 'shippingAmount' ? sortedInfo.order : null,
      render: (record: IPurchaseAgentBillPurchase) => {
        return (
          <TableCell className="text-xs text-right mr-2">
            {nepaliNumberFormatter(record.purchase.shippingAmount)}
          </TableCell>
        );
      }
    },
    {
      title: 'Shipping Tax',
      key: 'shippingTax',
      width: 4,
      sorter: (a, b) => a.purchase.shippingTax - b.purchase.shippingTax,
      sortOrder: sortedInfo.columnKey === 'shippingTax' ? sortedInfo.order : null,
      render: (record: IPurchaseAgentBillPurchase) => {
        return (
          <TableCell className="text-xs text-right mr-2">
            {nepaliNumberFormatter(record.purchase.shippingTax)}
          </TableCell>
        );
      }
    },
    {
      title: 'TAX',
      key: 'totalVat',
      width: 4,
      sorter: (a, b) => a.purchase.totalVat - b.purchase.totalVat,
      sortOrder: sortedInfo.columnKey === 'totalVat' ? sortedInfo.order : null,
      render: (record: IPurchaseAgentBillPurchase) => {
        return (
          <TableCell className="text-xs text-right mr-2">
            {nepaliNumberFormatter(record.purchase.totalVat)}
          </TableCell>
        );
      }
    },
    {
      title: 'Grand Total',
      key: 'grandtotalAmount',
      className: 'highlight',
      width: 4,
      sorter: (a, b) => a.purchase.totalAmount - b.purchase.totalAmount,
      sortOrder: sortedInfo.columnKey === 'grandtotalAmount' ? sortedInfo.order : null,
      render: (record: IPurchaseAgentBillPurchase) => {
        return (
          // <Link to={`/purchase/${record.purchase.id}`} color="black">
          <TableCell className="text-xs text-right mr-2">
            {nepaliNumberFormatter(record.purchase.totalAmount)}
          </TableCell>
          // </Link>
        );
      }
    },
    {
      title: 'Paid',
      key: 'amountPaid',
      width: 4,
      sorter: (a, b) => a.purchase.amountPaid - b.purchase.amountPaid,
      sortOrder: sortedInfo.columnKey === 'amountPaid' ? sortedInfo.order : null,
      render: (record: IPurchaseAgentBillPurchase) => {
        return (
          // <Link to={`/purchase/${record.purchase.id}`} color="black">
          <TableCell className="text-xs text-right mr-2">
            {nepaliNumberFormatter(record.purchase.amountPaid)}
          </TableCell>
          // </Link>
        );
      }
    },
    {
      title: 'Returned',
      key: 'amountReturned',
      width: 4,
      sorter: (a, b) => a.purchase.totalTax - b.purchase.totalTax,
      sortOrder: sortedInfo.columnKey === 'amountReturned' ? sortedInfo.order : null,
      render: (record: IPurchaseAgentBillPurchase) => {
        return (
          // <Link to={`/purchase/${record.purchase.id}`} color="black">
          <TableCell className="text-xs text-right mr-2">
            {nepaliNumberFormatter(record.purchase.amountReturned)}
          </TableCell>
          // </Link>
        );
      }
    },

    {
      title: 'Print Count',
      width: 3,
      key: 'printcount',
      sorter: (a, b) => a.purchase.printCount - b.purchase.printCount,
      sortOrder: sortedInfo.columnKey === 'printcount' ? sortedInfo.order : null,
      render: (record: IPurchaseAgentBillPurchase) => {
        return <TableCell className="text-xs text-center">{record.purchase.printCount}</TableCell>;
      }
    },

    {
      title: 'Action',
      key: 'actions',
      width: 3,
      fixed: 'right',
      render: (record: IPurchaseAgentBillPurchase) => {
        const menuItems = [
          {
            key: '1',
            label: <CustomViewIcon link={`/purchase/${record.purchase.id}`} />
          },
          {
            key: '2',
            label: (
              <Tooltip title="Return" color="blue">
                <Link to={`/purchase/return/${record.purchase.id}`}>
                  <RollbackOutlined
                    type="danger"
                    style={{ transform: 'scale(1.4)', width: '100%' }}
                  />
                </Link>
              </Tooltip>
            )
          },
          {
            key: '3',
            label: (
              <PurchasePaymentModal
                getInfo={async (val) => {
                  const data = await getInfo(val);
                  zustandFilter.updateState(location.pathname, { data });
                }}
                record={record.purchase}
                key={record.purchase.id}
              />
            )
          },
          {
            key: '4',
            label: (
              <AgentPurchaseInvoiceModal
                filter={filter}
                getInfo={async (val) => {
                  const data = await getInfo(val);
                  zustandFilter.updateState(location.pathname, { data });
                }}
                setIsLoading={setIsLoading}
                record={record.purchase}
                key={record.purchase.id}
              />
            )
          }
        ];
        if (checkAccess('READ_CHANNEL')) {
          menuItems.push({
            key: '5',
            label: (
              <DiscussionChatModal
                slug="purchase"
                id={record.id}
                fromSellorPurahcaseid={record.purchase.userData.userId}
              />
            )
          });
        }

        const menu = <Menu items={menuItems} />;
        return <ActionDropdown menu={menu} />;
      }
    }
  ];

  return (
    <CustomizeTable
      columns={columns}
      data={data.results}
      form={form}
      usersLoading={isLoading}
      tableName="agent-bill-range-purchase-list"
      toSort={handleChange}
      notshowPagination={true}
      tableSummaryForFilter={[
        { index: 14, render: nepaliNumberFormatter(total.total) },
        { index: 15, render: nepaliNumberFormatter(total.discount) },
        { index: 16, render: nepaliNumberFormatter(total.shipping) },
        { index: 17, render: nepaliNumberFormatter(total.shippingTax) },
        { index: 18, render: nepaliNumberFormatter(total.tax) },
        { index: 19, render: nepaliNumberFormatter(total.grandTotal) },
        { index: 20, render: nepaliNumberFormatter(total.paid) },
        { index: 21, render: nepaliNumberFormatter(total.return) }
      ]}
      customScroll={{ x: 3000, y: '75vh' }}
      paginationDatas={{
        page: pagination.page,
        total: data.total,
        size: pagination.size,
        onPagination
      }}
    />
  );
}

export default Table;
