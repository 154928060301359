import { Button, Form, Input, Menu, Spin, TableProps, Tooltip, message } from 'antd';
import { useRef, useState } from 'react';
import AppContent from '@/components/Common/Content/Content';

import { ColumnsType, SorterResult } from 'antd/lib/table/interface';
import TableCell from '@/components/Common/CustomizeTable/CustomCell';
import { convertLocalToUTCString, convertUTCStringtoLocalString } from '@/utils/convertToUTC';
import moment from 'moment';
import {
  get_account_detail_by_ids,
  get_account_history_ids,
  get_journal_details,
  get_journal_details_ids,
  get_journal_lines_list
} from '@/services/accounts/queries';
import {
  IAccountHistoryResponse,
  IJournalLinesListResponse,
  IJournalLinesViewResponseTableData,
  IJournalListResponse
} from '@/services/accounts/types';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';
import { ConvertObjectToURL } from '@/utils/converturl';
import { getUserData } from '@/utils/auth.utils';
import LocationsDB from '@/store/localstorage/LocationsDB';
import { get_location_list_for_ids } from '@/services/locations/queries';
import ActionDropdown from '@/components/Common/Dropdownactions';
import { ExportColumnType, exportExcel } from '@/utils/exportExcel';
import { checkAccess } from '@/routes/acl';
import JournalInvoice from '@/components/Common/CustomResuableInvoice/JournalInvoice';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import CustomViewIcon from '@/components/Common/CustomIcons/CustomViewIcon';
import { CustomModal } from '@/components/Common/CustomModal';
import { useReactToPrint } from 'react-to-print';
import { AccountRulesEvent, AccountType, redirectData } from '@/services/accounts/enums';
import { get_payment_details, get_payment_details_ids } from '@/services/payments/queries';
import { PaymentAgainst } from '@/services/payments/enums';
import {
  get_sell_details_ids,
  get_sell_return_details_by_id,
  get_sell_return_details_ids
} from '@/services/sell/queries';
import {
  get_purchase_details_ids,
  get_purchase_return_details_ids,
  get_purchase_reutrn_details_by_id
} from '@/services/purchases/queries';
import CustomErrorModal from '@/components/Common/CustomErrorModal';
import CustomTableRowSpan from '@/components/Common/CustomResuableInvoice/CustomTableRowSpan';
import { get_adjustments_details_ids } from '@/services/adjustments/queries';
import { get_expense_details_ids } from '@/services/expense/queries';
import { get_transfer_details_ids, get_transfer_in_details_ids } from '@/services/transfer/queries';
import { ISellFromServer, ISellReturn } from '@/services/sell/types';
import { IAdjustmentList } from '@/services/adjustments/types';
import { IExpenseList } from '@/services/expense/types';
import { IPurchaseList, IPurchaseReturn2 } from '@/services/purchases/types';
import { IPayment } from '@/services/payments/types';
import { ITransfer } from '@/services/transfer/types';
import { optionalDateSorter } from '@/utils/sorter.utils';
import getAccountInitialDate from '@/utils/getAccountInitialDate';
import AccountFilterTable from '@/components/AccountFilterTable/v2';
import { DEFAULT_DATE_FORMAT } from '@/constants';
import getOpeningBalance from '../../detailedLedger/services/openingBalance.service';
import LocationSearchV2 from '@/components/Common/CustomSearch/Location';
import AccountSearchV2 from '@/components/Common/CustomSearch/Accounts/AccountSearchV2';
import { useFinancialYearStore } from '@/store/zustand/financial-year';
import GenericTable from '@/components/Common/CustomizeTable';

interface ISavedData {
  isRunningBalance: boolean;
  journalLinesList: IJournalLinesListResponse;
  total: { totalCredit: number; totalDebit: number };
  pageRunningBalance: { [key: number]: { [key: number]: number } };
  closingBalanceForNext: Record<number, number>;

  lines: IJournalLinesViewResponseTableData[];
  exportURL: string;
  allExportUrl: string;
}

const NewJournalLinesList = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isRunningBalance, setIsRunningBalance] = useState(false);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const accountId = form.getFieldValue(['accountId']);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(100);
  const [pageRunningBalance, setPageRunningBalance] = useState<{
    [key: number]: { [key: number]: number };
  }>(
    {} as {
      [key: number]: { [key: number]: number };
    }
  );
  const [closingBalanceForNext, setClosingBalanceForNext] = useState<{ [key: number]: number }>(
    {} as { [key: number]: number }
  );
  const printPDFRef = useRef<any>();
  const [journalLinesList, setJournalLinesList] = useState<IJournalLinesListResponse>({
    opening: [],
    results: [],
    total: 0
  });
  const [lines, setLines] = useState<IJournalLinesViewResponseTableData[]>([]);
  const [exportData, setExportData] = useState<IJournalLinesViewResponseTableData[]>([]);
  const [allExportUrl, setAllExportUrl] = useState('');
  const [exportUrl, setExportUrl] = useState('');

  const { preferences } = getUserData();
  const preferenceLocationId = preferences?.preferences
    ? JSON.parse(preferences?.preferences)?.locationId
    : undefined;

  const [locationId, setLocationId] = useState<number>(preferenceLocationId);
  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({});
  const [openModalForPdfExport, setOpenModalForPdfExport] = useState<boolean>(false);
  const handleChange: TableProps<any>['onChange'] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<any>);
  };
  const [total, setTotal] = useState<any>({
    totalCredit: 0,
    totalDebit: 0
  });
  const [openModalForPrint, setOpenModalForPrint] = useState<boolean>(false);
  const [modalData] = useState<IJournalLinesViewResponseTableData[]>();

  const zustandFinancialYear = useFinancialYearStore();

  const breadcrumbItems = [
    {
      label: 'Ledger Flow',
      link: '/accounts/new-journal-lines'
    }
  ];

  const onSubmitFilter = async (val: string) => {
    setPageRunningBalance(() => {
      return {};
    });
    const { response, savedData } = await getInfo(val, false);
    const data = response as IJournalLinesListResponse;
    const total = data.total;
    const urlParams = new URLSearchParams(val);

    urlParams.set('count', total.toString());
    setAllExportUrl(urlParams.toString());
    setPage(1);
    setSize(100);
    return { ...savedData, allExportUrl: urlParams.toString() };
  };

  const getTransactionIdForEndDay = (
    tableData: IJournalLinesViewResponseTableData[],
    transDate: string,
    accountId: number
  ) => {
    const targetDate = new Date(transDate);

    const validData = tableData.filter(
      (val) =>
        moment(val.journalDate).format('YYYY-MM-DD') === transDate && val.accountId === accountId
    );

    const maxDateTrans = validData.reduce((max, item) => {
      if (item.journalDate && max.journalDate) {
        const currentDate = new Date(item.journalDate);
        const maxDate = new Date(max.journalDate);

        if (currentDate >= targetDate && currentDate > maxDate) {
          return item;
        } else if (currentDate.getTime() === maxDate.getTime()) {
          const currentCreatedDate = new Date(item.createdAt);
          const maxCreatedDate = new Date(max.createdAt);
          return currentCreatedDate > maxCreatedDate ? item : max;
        } else {
          return max;
        }
      } else {
        return max;
      }
    }, validData[0]);

    return maxDateTrans.id;
  };

  const getInfo = async (
    filter = '',
    isExport = false,
    pageNo = 1,
    isResetPageRunningBalance = false
  ) => {
    setIsLoading(true);
    setIsRunningBalance(false);

    const savedData = {} as ISavedData;

    const urlParams = new URLSearchParams(filter);
    const accountIds = urlParams.get('accountId');
    const startDate = urlParams.get('startDate');
    const endDate = urlParams.get('endDate');
    urlParams.set('isAsc', 'true');
    if (accountIds) {
      const accountIdList = accountIds.split(',');

      const updatedParams = accountIdList.map((id) => `accountIds[]=${id}`).join('&');

      urlParams.delete('accountId');
      const updatedURL = `${urlParams.toString()}&${updatedParams}`;
      filter = updatedURL;
    } else {
      urlParams.delete('accountId');
      const updatedURL = `${urlParams.toString()}`;
      filter = updatedURL;
    }

    const mergeValue = new Set();

    const response = await get_journal_lines_list(filter);

    let ctotalCredit = 0,
      ctotalDebit = 0;
    // let opBalance = '--';
    if (response) {
      let allJournals: IJournalListResponse = { results: [], total: 0 };
      setJournalLinesList(response);
      savedData.journalLinesList = response;
      if (response.results.length > 0) {
        allJournals = await get_journal_details_ids([
          ...new Set(
            response.results.map((value) => {
              return value.journalId;
            })
          )
        ]);
      }

      const referenceByType = {
        sellReferenceIds: [] as number[],
        paymentReferenceIds: [] as number[],
        sellReturnReferenceIds: [] as number[],
        purchaseReferenceIds: [] as number[],
        purchaseReturnReferenceIds: [] as number[],
        expenseReferenceIds: [] as number[],
        adjustmentReferenceIds: [] as number[],
        vatReferenceIds: [] as number[],
        transferFromReferenceIds: [] as number[],
        transferToReferenceIds: [] as number[]
      };

      if (allJournals.results.length > 0) {
        for (let ind = 0; ind < allJournals.results.length; ind++) {
          switch (allJournals.results[ind].type) {
            case AccountRulesEvent.SELL_CREATE:
              {
                referenceByType.sellReferenceIds.push(allJournals.results[ind].referenceId);
              }
              break;
            case AccountRulesEvent.SELL_PAYMENT_CASH:
            case AccountRulesEvent.SELL_PAYMENT_BANK:
            case AccountRulesEvent.SELL_PAYMENT_OTHER:
            case AccountRulesEvent.PURCHASE_PAYMENT_CASH:
            case AccountRulesEvent.PURCHASE_PAYMENT_USER:
            case AccountRulesEvent.PURCHASE_PAYMENT_OTHER:
              {
                referenceByType.paymentReferenceIds.push(allJournals.results[ind].referenceId);
              }
              break;
            case AccountRulesEvent.SELL_RETURN:
              {
                referenceByType.sellReturnReferenceIds.push(allJournals.results[ind].referenceId);
              }
              break;
            case AccountRulesEvent.PURCHASE_CREATE:
              {
                referenceByType.purchaseReferenceIds.push(allJournals.results[ind].referenceId);
              }
              break;
            case AccountRulesEvent.PURCHASE_RETURN:
              {
                referenceByType.purchaseReturnReferenceIds.push(
                  allJournals.results[ind].referenceId
                );
              }
              break;
            case AccountRulesEvent.EXPENSE_CREATE:
              {
                referenceByType.expenseReferenceIds.push(allJournals.results[ind].referenceId);
              }
              break;
            case AccountRulesEvent.ADJUSTMENT_CREATE:
              {
                referenceByType.adjustmentReferenceIds.push(allJournals.results[ind].referenceId);
              }
              break;
            case AccountRulesEvent.VAT_CREATE:
              {
                referenceByType.vatReferenceIds.push(allJournals.results[ind].referenceId);
              }
              break;
            case AccountRulesEvent.TRANSFER_FROM:
              {
                referenceByType.transferFromReferenceIds.push(allJournals.results[ind].referenceId);
              }
              break;
            case AccountRulesEvent.TRANSFER_TO:
              {
                referenceByType.transferToReferenceIds.push(allJournals.results[ind].referenceId);
              }
              break;
            default:
              break;
          }
        }

        const apiPromises = {
          sell:
            referenceByType.sellReferenceIds.length > 0
              ? get_sell_details_ids(referenceByType.sellReferenceIds)
              : null,
          sellReturn:
            referenceByType.sellReturnReferenceIds.length > 0
              ? get_sell_return_details_ids(referenceByType.sellReturnReferenceIds)
              : null,
          adjustment:
            referenceByType.adjustmentReferenceIds.length > 0
              ? get_adjustments_details_ids(referenceByType.adjustmentReferenceIds)
              : null,
          expense:
            referenceByType.expenseReferenceIds.length > 0
              ? get_expense_details_ids(referenceByType.expenseReferenceIds)
              : null,
          purchase:
            referenceByType.purchaseReferenceIds.length > 0
              ? get_purchase_details_ids(referenceByType.purchaseReferenceIds)
              : null,
          purchaseReturn:
            referenceByType.purchaseReturnReferenceIds.length > 0
              ? get_purchase_return_details_ids(referenceByType.purchaseReturnReferenceIds)
              : null,
          payment:
            referenceByType.paymentReferenceIds.length > 0
              ? get_payment_details_ids(referenceByType.paymentReferenceIds)
              : null,
          vat:
            referenceByType.vatReferenceIds.length > 0
              ? get_sell_details_ids(referenceByType.vatReferenceIds)
              : null,
          transferFrom:
            referenceByType.transferFromReferenceIds.length > 0
              ? get_transfer_details_ids(referenceByType.transferFromReferenceIds)
              : null,
          transferTo:
            referenceByType.transferToReferenceIds.length > 0
              ? get_transfer_in_details_ids(referenceByType.transferToReferenceIds)
              : null
        };

        const filteredApiPromises = Object.fromEntries(
          Object.entries(apiPromises).filter(([key, value]) => value !== null)
        );

        const allApiResults = await Promise.all(Object.values(filteredApiPromises));

        Object.entries(filteredApiPromises).forEach(([key, promise], index) => {
          const result = allApiResults[index];

          if (result !== null) {
            switch (key) {
              case 'sell':
                if ('results' in result && result.results.length > 0) {
                  const sellResults = result.results as ISellFromServer[];
                  for (let ind = 0; ind < allJournals.results.length; ind++) {
                    const sellResult = sellResults.find(
                      (res) =>
                        res.id === allJournals.results[ind].referenceId &&
                        allJournals.results[ind].type === AccountRulesEvent.SELL_CREATE
                    );

                    if (sellResult && 'financialReference' in sellResult) {
                      allJournals.results[ind].refNumber = sellResult.financialReference;
                    }
                  }
                }
                break;
              case 'sellReturn':
                if ('results' in result && result.results.length > 0) {
                  const sellReturnResults = result.results as ISellReturn[];
                  for (let ind = 0; ind < allJournals.results.length; ind++) {
                    const sellReturnResult = sellReturnResults.find(
                      (res) =>
                        res.id === allJournals.results[ind].referenceId &&
                        allJournals.results[ind].type === AccountRulesEvent.SELL_RETURN
                    );

                    if (sellReturnResult && 'financialReference' in sellReturnResult) {
                      allJournals.results[ind].refNumber = sellReturnResult.financialReference;
                    }
                  }
                }
                break;
              case 'adjustment':
                if ('results' in result && result.results.length > 0) {
                  const adjustmentResults = result.results as IAdjustmentList[];
                  for (let ind = 0; ind < allJournals.results.length; ind++) {
                    const adjustmentResult = adjustmentResults.find(
                      (res) =>
                        res.id === allJournals.results[ind].referenceId &&
                        allJournals.results[ind].type === AccountRulesEvent.ADJUSTMENT_CREATE
                    );

                    if (adjustmentResult && 'referenceNumber' in adjustmentResult) {
                      allJournals.results[ind].refNumber = adjustmentResult.referenceNumber;
                    }
                  }
                }
                break;
              case 'expense':
                if ('results' in result && result.results.length > 0) {
                  const expenseResults = result.results as IExpenseList[];
                  for (let ind = 0; ind < allJournals.results.length; ind++) {
                    const expenseResult = expenseResults.find(
                      (res) =>
                        res.id === allJournals.results[ind].referenceId &&
                        allJournals.results[ind].type === AccountRulesEvent.EXPENSE_CREATE
                    );

                    if (expenseResult && 'referenceNumber' in expenseResult) {
                      allJournals.results[ind].refNumber = expenseResult.referenceNumber;
                    }
                  }
                }
                break;
              case 'purchase':
                if ('results' in result && result.results.length > 0) {
                  const purchaseResults = result.results as IPurchaseList[];
                  for (let ind = 0; ind < allJournals.results.length; ind++) {
                    const purchaseResult = purchaseResults.find(
                      (res) =>
                        res.id === allJournals.results[ind].referenceId &&
                        allJournals.results[ind].type === AccountRulesEvent.PURCHASE_CREATE
                    );

                    if (purchaseResult && 'financialReference' in purchaseResult) {
                      allJournals.results[ind].refNumber = purchaseResult.financialReference;
                    }
                  }
                }
                break;
              case 'purchaseReturn':
                if ('results' in result && result.results.length > 0) {
                  const purchaseReturnResults = result.results as IPurchaseReturn2[];
                  for (let ind = 0; ind < allJournals.results.length; ind++) {
                    const purchaseReturnResult = purchaseReturnResults.find(
                      (res) =>
                        res.id === allJournals.results[ind].referenceId &&
                        allJournals.results[ind].type === AccountRulesEvent.PURCHASE_RETURN
                    );

                    if (purchaseReturnResult && 'financialReference' in purchaseReturnResult) {
                      allJournals.results[ind].refNumber = purchaseReturnResult.financialReference;
                    }
                  }
                }
                break;
              case 'payment':
                if ('results' in result && result.results.length > 0) {
                  const paymentResults = result.results as IPayment[];
                  for (let ind = 0; ind < allJournals.results.length; ind++) {
                    const paymentResult = paymentResults.find((res) => {
                      if (
                        res.id === allJournals.results[ind].referenceId &&
                        (allJournals.results[ind].type === AccountRulesEvent.SELL_PAYMENT_CASH ||
                          allJournals.results[ind].type === AccountRulesEvent.SELL_PAYMENT_BANK ||
                          allJournals.results[ind].type === AccountRulesEvent.SELL_PAYMENT_OTHER ||
                          allJournals.results[ind].type ===
                            AccountRulesEvent.PURCHASE_PAYMENT_CASH ||
                          allJournals.results[ind].type ===
                            AccountRulesEvent.PURCHASE_PAYMENT_USER ||
                          allJournals.results[ind].type ===
                            AccountRulesEvent.PURCHASE_PAYMENT_OTHER)
                      ) {
                        return true;
                      }
                      return false;
                    });

                    if (paymentResult && 'reference' in paymentResult) {
                      allJournals.results[ind].refNumber = paymentResult.reference;
                    }
                  }
                }
                break;
              case 'vat':
                if ('results' in result && result.results.length > 0) {
                  const vatResults = result.results as ISellFromServer[];
                  for (let ind = 0; ind < allJournals.results.length; ind++) {
                    const vatResult = vatResults.find(
                      (res) =>
                        res.id === allJournals.results[ind].referenceId &&
                        allJournals.results[ind].type === AccountRulesEvent.VAT_CREATE
                    );

                    if (vatResult && 'financialReference' in vatResult) {
                      allJournals.results[ind].refNumber = vatResult.financialReference;
                    }
                  }
                }
                break;
              case 'transferFrom':
                if ('results' in result && result.results.length > 0) {
                  const transferFromResults = result.results as ITransfer[];
                  for (let ind = 0; ind < allJournals.results.length; ind++) {
                    const transferFromResult = transferFromResults.find(
                      (res) =>
                        res.id === allJournals.results[ind].referenceId &&
                        allJournals.results[ind].type === AccountRulesEvent.TRANSFER_FROM
                    );

                    if (transferFromResult && 'referenceNo' in transferFromResult) {
                      allJournals.results[ind].refNumber = transferFromResult.referenceNo;
                    }
                  }
                }
                break;
              case 'transferTo':
                if ('results' in result && result.results.length > 0) {
                  const transferToResults = result.results as ITransfer[];
                  for (let ind = 0; ind < allJournals.results.length; ind++) {
                    const transferToResult = transferToResults.find(
                      (res) =>
                        res.id === allJournals.results[ind].referenceId &&
                        allJournals.results[ind].type === AccountRulesEvent.TRANSFER_TO
                    );

                    if (transferToResult && 'referenceNo' in transferToResult) {
                      allJournals.results[ind].refNumber = transferToResult.referenceNo;
                    }
                  }
                }
                break;
              default:
                break;
            }
          }
        });
      }

      let tableData: IJournalLinesViewResponseTableData[] = [];
      let allAccountsHistory: IAccountHistoryResponse[];
      const runningBalanceObj: { [key: number]: number } = {} as {
        [key: number]: number;
      };
      // Fetch All Accounts For Balance
      const allAccountIds = [
        ...new Set(
          response.results.map((value) => {
            return value.accountId;
          })
        )
      ];
      const allAccounts = await get_account_detail_by_ids(allAccountIds);

      // Fetch All Accounts History
      if (allAccountIds.length > 0) {
        allAccountsHistory = await get_account_history_ids(startDate, endDate, allAccountIds);
      } else {
        allAccountsHistory = [];
      }

      for (let ind = 0; ind < response.results.length; ind++) {
        let filteredAcc;
        if (response.results[ind].accountId !== 0) {
          filteredAcc = allAccounts.results.find(
            (val) => val.id === response.results[ind].accountId
          );
        }
        let locationDetails;
        if (response.results[ind].locationId !== null) {
          locationDetails = await LocationsDB.getLocation(response.results[ind].locationId);
          if (!locationDetails) {
            const allLocations = await get_location_list_for_ids([
              ...new Set(
                response.results
                  .filter((value) => value.locationId !== null)
                  .map((value) => {
                    return value.locationId;
                  })
              )
            ]);
            await LocationsDB.addLocations(allLocations);
            locationDetails = await LocationsDB.getLocation(response.results[ind].locationId);
          }
        }
        const journal = allJournals.results.find(
          (journal) => journal.id == response.results[ind].journalId
        );

        if (
          filteredAcc?.type === AccountType.TRANSFER_INITIATE ||
          filteredAcc?.type === AccountType.TRANSFER_RECEIVE
        ) {
          let refLocation = await LocationsDB.getLocation(filteredAcc.referenceId);
          if (!refLocation) {
            const allLocations = await get_location_list_for_ids([
              ...new Set(
                allAccounts.results
                  .filter(
                    (value) =>
                      value.type === AccountType.TRANSFER_INITIATE ||
                      value.type === AccountType.TRANSFER_RECEIVE
                  )
                  .map((value) => {
                    return value.referenceId;
                  })
              )
            ]);
            await LocationsDB.addLocations(allLocations);
            refLocation = await LocationsDB.getLocation(filteredAcc.referenceId);
          }
          if (locationDetails) {
            locationDetails.name = `${locationDetails.name} >>> ${refLocation.name}`;
          }
        }

        tableData.push({
          ...response.results[ind],
          accountName: typeof filteredAcc === 'object' ? filteredAcc?.name : '',
          accountBalance: typeof filteredAcc === 'object' ? filteredAcc?.balance : 0,
          journalName: journal?.description,
          journalReferenceId: journal?.referenceId,
          refNumber: journal?.refNumber,
          locationName: typeof locationDetails === 'object' ? locationDetails.name : '',
          ledgerType: journal?.type
        });

        ctotalCredit += response.results[ind].credit;
        ctotalDebit += response.results[ind].debit;
      }
      // tableData.sort((a, b) => a.journalId - b.journalId);

      let sn = 0;
      tableData = tableData.map((value) => {
        if (mergeValue.has(`${value.journalId}`)) {
          return { ...value, rows: 0, sn: 0 };
        } else {
          const rowCount = tableData.filter((data) => data.journalId === value.journalId).length;
          mergeValue.add(`${value.journalId}`);
          sn += 1;
          return { ...value, rows: rowCount, sn: sn };
        }
      });

      setTotal({ totalCredit: ctotalCredit, totalDebit: ctotalDebit });
      savedData.total = { totalCredit: ctotalCredit, totalDebit: ctotalDebit };

      const journalWithTransfer = tableData.map((value) => {
        if (value.locationName?.includes('>>>'))
          return { journalId: value.journalId, locationName: value.locationName };
      });
      tableData = tableData.map((value) => {
        if (journalWithTransfer.some((x) => x?.journalId === value.journalId))
          return {
            ...value,
            locationName: journalWithTransfer.find((val) => val?.journalId === value.journalId)
              ?.locationName
          };
        else return { ...value };
      });

      let updatedLines;
      const accountIds = [...new Set(tableData.map((value) => value.accountId))];

      if (response.results.length > 0) {
        let closingBalance = nepaliNumberFormatter(0);

        let openingBalance = nepaliNumberFormatter(0);

        if (accountIds.length === 1) {
          setIsRunningBalance(true);
          savedData.isRunningBalance = true;
          // For Next
          let filterNext = '';
          const urlParamsNext = new URLSearchParams(filter);
          const accountIdsNext = urlParamsNext.get('accountId');
          const count = urlParamsNext.get('count');
          if (count) urlParamsNext.set('skip', count);
          urlParamsNext.set('count', '1');
          if (accountIdsNext) {
            const accountIdList = accountIdsNext.split(',');
            const updatedParams = accountIdList.map((id) => `accountIds[]=${id}`).join('&');
            urlParamsNext.delete('accountId');
            const updatedURL = `${urlParamsNext.toString()}&${updatedParams}`;
            filterNext = updatedURL;
          } else {
            urlParamsNext.delete('accountId');
            const updatedURL = `${urlParamsNext.toString()}`;
            filterNext = updatedURL;
          }
          const responseNext = await get_journal_lines_list(filterNext);

          tableData.sort((a, b) => optionalDateSorter(a.journalDate, b.journalDate));
          if (allAccountsHistory) {
            const currentFinancialStart = zustandFinancialYear.currentFinancialYear
              ?.startDate as string;

            const opBal = getOpeningBalance({
              data: tableData,
              accountId: accountIds[0],
              history: allAccountsHistory,
              pageNo,
              closingBalance: closingBalanceForNext,
              currentFinancialStart
            });

            const pageRunBal = isResetPageRunningBalance ? {} : pageRunningBalance;

            // Running Balance
            tableData = tableData.map((g, index) => {
              if (pageNo === 1 && index === 0) {
                const startTrans = opBal;
                runningBalanceObj[g.accountId] = startTrans + g.debit - g.credit;
                pageRunBal[pageNo] = runningBalanceObj;
              } else {
                if (pageRunBal[pageNo] !== undefined || pageRunBal[pageNo] !== null) {
                  if (
                    pageRunBal[pageNo]?.[g.accountId] !== undefined ||
                    pageRunBal[pageNo]?.[g.accountId] !== null
                  ) {
                    const currentRunningBalance = pageRunBal?.[pageNo]?.[g.accountId] ?? 0;
                    runningBalanceObj[g.accountId] = currentRunningBalance + g.debit - g.credit;
                  }
                } else {
                  for (let ind = pageNo - 1; ind > 0; ind--) {
                    if (
                      pageRunBal[ind][g.accountId] !== undefined ||
                      pageRunBal[pageNo][g.accountId] !== null
                    ) {
                      runningBalanceObj[g.accountId] =
                        pageRunBal[ind][g.accountId] + g.debit - g.credit;
                      break;
                    }
                  }
                  pageRunBal[pageNo] = runningBalanceObj;
                }
              }

              const transDate = moment(g.journalDate).format('YYYY-MM-DD');
              const selectedAccountsHistory = allAccountsHistory.find(
                (val) => val.accountId === g.accountId && val.date === transDate
              );

              let hasNextSameDate = false;
              if (responseNext.results.length > 0) {
                const nextStartDate = moment(responseNext.results[0].journalDate).format(
                  'YYYY-MM-DD'
                );
                if (transDate === nextStartDate) hasNextSameDate = true;
              }

              if (selectedAccountsHistory && !hasNextSameDate) {
                const transactionId = getTransactionIdForEndDay(tableData, transDate, g.accountId);
                if (transactionId === g.id) {
                  runningBalanceObj[g.accountId] = selectedAccountsHistory.balance;
                }
              }

              pageRunBal[pageNo] = runningBalanceObj;

              return { ...g, runningBalance: runningBalanceObj[g.accountId] };
            });

            setPageRunningBalance((prev) => {
              prev[pageNo] = pageRunBal[pageNo];
              savedData.pageRunningBalance = prev;
              return { ...prev };
            });

            const clBal = tableData[tableData.length - 1].runningBalance;
            openingBalance = nepaliNumberFormatter(opBal ? opBal : 0);
            closingBalance = nepaliNumberFormatter(clBal ? clBal : 0);
            setClosingBalanceForNext((prev) => {
              prev[pageNo] = clBal ? clBal : 0;
              savedData.closingBalanceForNext = prev;
              return prev;
            });
          } else {
            CustomErrorModal({ message: 'Internal Error Occured!' });
          }
          const openingBalanceRowData = {
            accountName: 'Opening Balance',
            runningBalance: parseFloat(openingBalance.replace(/,/g, '')) || '0.0',
            rows: 1,
            createdAt: '',
            isBold: true
          };

          const closingBalanceRowData = {
            accountName: 'Closing Balance',
            runningBalance: parseFloat(closingBalance.replace(/,/g, '')) || '0.0',
            rows: 1,
            createdAt: '',
            isBold: true
          };

          updatedLines = [
            openingBalanceRowData,
            ...tableData,
            closingBalanceRowData
          ] as IJournalLinesViewResponseTableData[];
        } else {
          // Group and sort
          const groupedData = tableData.reduce<
            Record<number, IJournalLinesViewResponseTableData[]>
          >((acc, item) => {
            const key = item.journalId;

            if (!acc[key]) {
              acc[key] = [];
            }

            acc[key].push(item);

            return acc;
          }, {});

          const sortedKeys = Object.keys(groupedData).sort((a, b) => {
            const journalDateA = groupedData[parseInt(a)][0].journalDate;
            const journalDateB = groupedData[parseInt(b)][0].journalDate;
            return optionalDateSorter(journalDateA, journalDateB);
          });

          const sortedGroupedData: [number, IJournalLinesViewResponseTableData[]][] =
            sortedKeys.map((key) => [parseInt(key), groupedData[parseInt(key)]]);

          tableData = sortedGroupedData.flatMap((group) => {
            return group[1];
          });
          updatedLines = tableData;
        }

        if (isExport) {
          return { response: updatedLines, savedData };
        } else {
          setLines(updatedLines);
          setExportUrl(filter);
          savedData.lines = updatedLines;
          savedData.exportURL = filter;
        }
      } else {
        setLines(tableData);
        savedData.lines = tableData;

        setExportUrl(filter);
        savedData.exportURL = filter;
      }
    }
    setIsLoading(false);
    return { response, savedData };
  };

  const onPagination = async (pageNo = 1, totalSize = 100, isSize = false) => {
    setIsLoading(true);
    let resetPageRunBal = false;
    const values = form.getFieldsValue();
    values.endDate = convertLocalToUTCString(values.endDate);
    values.startDate = convertLocalToUTCString(values.startDate);
    delete values.dateCustom;
    delete values.startDateNepali;
    delete values.endDateNepali;
    if (isSize) {
      values.skip = 0;
      values.count = totalSize;
      setPage(1);
      setSize(totalSize);
      setPageRunningBalance(() => {
        return {};
      });
      resetPageRunBal = true;
      pageNo = 1;
    } else {
      values.skip = (pageNo - 1) * totalSize;
      values.count = totalSize;
      setPage(pageNo);
    }
    const url = ConvertObjectToURL(values);
    const { savedData } = await getInfo(url, false, pageNo, resetPageRunBal);
    return { ...savedData, allExportUrl };
  };

  const fetchReferenceAndRedirect = async (journalId: number) => {
    setIsLoading(true);
    //redirect
    const response = await get_journal_details(journalId);
    const redirectObj = redirectData.find((val) => val.key === response.type);
    if (redirectObj && response) {
      // set referenceId
      switch (response.type) {
        case AccountRulesEvent.SELL_PAYMENT_CASH:
        case AccountRulesEvent.SELL_PAYMENT_BANK:
        case AccountRulesEvent.SELL_PAYMENT_OTHER: {
          const paymentDetails = await get_payment_details(response.referenceId);
          if (paymentDetails.against === PaymentAgainst.Sell)
            response.redirectId = paymentDetails.againstId;
          break;
        }
        case AccountRulesEvent.SELL_RETURN: {
          const returnDetails = await get_sell_return_details_by_id(response.referenceId);
          response.redirectId = returnDetails.sellId;
          break;
        }
        case AccountRulesEvent.PURCHASE_PAYMENT_CASH:
        case AccountRulesEvent.PURCHASE_PAYMENT_USER:
        case AccountRulesEvent.PURCHASE_PAYMENT_OTHER: {
          const paymentDetails = await get_payment_details(response.referenceId);
          if (paymentDetails.against === PaymentAgainst.Purchase)
            response.redirectId = paymentDetails.againstId;
          break;
        }
        case AccountRulesEvent.PURCHASE_RETURN: {
          const returnDetails = await get_purchase_reutrn_details_by_id(response.referenceId);
          response.redirectId = returnDetails.purchaseId;
          break;
        }
        default: {
          response.redirectId = response.referenceId;
        }
      }
      navigate(`${redirectObj.link}${response.redirectId}`);
    } else {
      setIsLoading(false);
      CustomErrorModal({ message: 'Cannot redirect! Page not found.' });
    }
  };

  const columns: ColumnsType<IJournalLinesViewResponseTableData> = [
    {
      title: 'S.N',
      key: 'SN',
      width: 8,
      onCell: (record) => {
        return { rowSpan: record.rows };
      },
      render: (txt, record) => {
        return <TableCell>{record.sn ? (page - 1) * size + record.sn : ''}</TableCell>;
      }
    },
    {
      title: 'Journal',
      key: 'journalName',
      width: 15,
      rowSpan: 1,
      onCell: (record) => {
        return { rowSpan: record.rows };
      },
      render: (a, record) => (
        <TableCell>
          <Link to={'/accounts/journal/view/' + record.journalId}>{record.journalName}</Link>
        </TableCell>
      )
    },
    {
      title: 'Account',
      key: 'accountName',
      width: 15,
      render: (a, record) => (
        <TableCell className={record.isBold ? 'font-bold' : ''}>{record.accountName}</TableCell>
      )
    },
    {
      title: 'Debit',
      key: 'debit',
      dataIndex: 'debit',
      width: 15,
      render: (a, record) => (
        <TableCell>
          {record.debit !== undefined ? nepaliNumberFormatter(record.debit) : ''}
        </TableCell>
      )
    },
    {
      title: 'Credit',
      key: 'credit',
      dataIndex: 'credit',
      width: 15,
      render: (a, record) => (
        <TableCell>
          {record.credit !== undefined ? nepaliNumberFormatter(record.credit) : ''}
        </TableCell>
      )
    },
    {
      title: 'Reference',
      key: 'ledgerType',
      width: 20,
      onCell: (record) => {
        return { rowSpan: record.rows };
      },
      render: (a, record) => (
        <TableCell>
          <Button
            type="link"
            block
            style={{
              padding: '0px',
              textAlign: 'left',
              wordBreak: 'break-all',
              whiteSpace: 'normal'
            }}
            onClick={async () => await fetchReferenceAndRedirect(record.journalId)}>
            {record.ledgerType}
          </Button>
        </TableCell>
      )
    },
    {
      title: 'Reference Number',
      key: 'refNumber',
      width: 15,
      className: 'invoice',
      onCell: (record) => {
        return { rowSpan: record.rows };
      },
      render: (a, record) => <TableCell>{record.refNumber}</TableCell>
    },
    {
      title: 'Running Balance',
      key: 'runningBalance',
      className: 'highlight',
      width: 18,
      render: (a, record) => (
        <TableCell className={record.isBold ? 'font-bold' : ''}>
          {record.runningBalance !== null && record.runningBalance !== undefined
            ? nepaliNumberFormatter(record.runningBalance)
            : ''}
        </TableCell>
      )
    },
    {
      title: 'Location',
      key: 'locationName',
      width: 15,
      onCell: (record) => {
        return { rowSpan: record.rows };
      },
      render: (a, record) => {
        return (
          <>
            <TableCell>{record.locationName}</TableCell>
          </>
        );
      }
    },
    {
      title: 'Transaction Date',
      key: 'transactionDate',
      width: 15,
      onCell: (record) => {
        return { rowSpan: record.rows };
      },
      render: (a, record) => {
        return (
          <>
            <TableCell>
              {record.journalDate
                ? convertUTCStringtoLocalString(record.journalDate, DEFAULT_DATE_FORMAT)
                : null}
            </TableCell>
          </>
        );
      }
    },
    {
      title: 'Created Date',
      key: 'createdAt',
      width: 15,
      onCell: (record) => {
        return { rowSpan: record.rows };
      },
      render: (a, record) => {
        return (
          <>
            <TableCell>
              {record.createdAt
                ? convertUTCStringtoLocalString(record.createdAt, DEFAULT_DATE_FORMAT)
                : ''}
            </TableCell>
          </>
        );
      }
    },
    {
      title: 'Actions',
      key: 'actions',
      width: 10,
      fixed: 'right',
      onCell: (record) => {
        return { rowSpan: record.rows };
      },
      render: (record) => {
        const menuItems: (
          | {
              key: string;
              label: JSX.Element;
              onClick?: undefined;
            }
          | {
              key: string;
              label: JSX.Element;
              onClick: () => void;
            }
        )[] = [];
        if (checkAccess('READ_ACCOUNT')) {
          menuItems.push({
            key: '1',
            label: <CustomViewIcon link={'/accounts/journal/view/' + record.journalId} />
          });
        }

        const menu = <Menu items={menuItems} />;

        return <ActionDropdown menu={menu} />;
      }
    }
  ];

  const columsforPrint = [
    {
      label: 'Journal',
      dataIndex: 'journalName',
      width: 100,
      hasRowSpan: true,
      render: (text: string) => {
        return <TableCell>{text}</TableCell>;
      }
    },
    {
      label: 'Account',
      dataIndex: 'accountName',
      width: 100,
      hasRowSpan: false,
      render: (text: string) => {
        return <TableCell>{text}</TableCell>;
      }
    },
    {
      label: 'Debit',
      dataIndex: 'debit',
      width: 100,
      hasRowSpan: false,
      render: (text: number) => {
        return <TableCell>{text}</TableCell>;
      }
    },
    {
      label: 'Credit',
      dataIndex: 'credit',
      width: 100,
      hasRowSpan: false,
      render: (text: number) => {
        return <TableCell>{text}</TableCell>;
      }
    },
    {
      label: 'Reference',
      dataIndex: 'ledgerType',
      width: 100,
      hasRowSpan: false,
      render: (text: string) => {
        return <TableCell>{text}</TableCell>;
      }
    },
    {
      label: 'Reference Number',
      dataIndex: 'refNumber',
      width: 100,
      hasRowSpan: false,
      render: (text: string) => {
        return <TableCell>{text}</TableCell>;
      }
    },
    {
      label: 'Running Balance',
      dataIndex: 'runningBalance',
      width: 100,
      hasRowSpan: false,
      render: (text: number) => {
        return <TableCell>{text}</TableCell>;
      }
    },
    {
      label: 'Location',
      dataIndex: 'locationName',
      width: 150,
      hasRowSpan: true,
      render: (text: string) => {
        return <TableCell>{text}</TableCell>;
      }
    },
    {
      label: 'Transaction Date',
      dataIndex: 'transactionDate',
      width: 130,
      hasRowSpan: true,
      render: (text: string) => {
        return <TableCell>{convertUTCStringtoLocalString(text, DEFAULT_DATE_FORMAT)}</TableCell>;
      }
    },
    {
      label: 'Created Date',
      dataIndex: 'createdAt',
      width: 130,
      hasRowSpan: true,
      render: (text: string) => {
        return <TableCell>{convertUTCStringtoLocalString(text, DEFAULT_DATE_FORMAT)}</TableCell>;
      }
    }
  ];

  const handleLocationChange = () => {
    // console.log('locationId', form.getFieldValue(['locationId']));
    form.resetFields(['accountId']);
    setLocationId(form.getFieldValue(['locationId']));
  };

  const handleExport = async (exportType: string) => {
    setIsLoading(true);
    try {
      const columns: ExportColumnType[] = [
        {
          title: `Report`,
          width: 1500,
          children: [
            {
              title: 'S.N',
              dataIndex: 'sn',
              width: 50
            },
            {
              title: 'Journal',
              dataIndex: 'journalName',
              width: 100
            },
            {
              title: 'Account',
              dataIndex: 'accountName',
              width: 100
            },
            {
              title: 'Running Balance',
              dataIndex: 'runningBalance',
              width: 120
            },
            {
              title: 'Debit',
              dataIndex: 'debit',
              width: 100
            },
            {
              title: 'Credit',
              dataIndex: 'credit',
              width: 100
            },
            {
              title: 'Reference',
              dataIndex: 'ledgerType',
              width: 100
            },
            {
              title: 'Reference Number',
              dataIndex: 'refNumber',
              width: 100
            },
            {
              title: 'Location',
              dataIndex: 'locationName',
              width: 150
            },
            {
              title: 'Transaction Date',
              dataIndex: 'transactionDate',
              width: 100
            },
            {
              title: 'Created Date',
              dataIndex: 'createdAt',
              width: 100
            }
          ]
        }
      ];
      if (lines.length === 0) {
        message.error('No Data to Export');
        return;
      }
      let filteredData;

      if (exportType === 'all') {
        const exportData = await getInfo(allExportUrl, true);
        filteredData = exportData.response as IJournalLinesViewResponseTableData[];
      } else {
        filteredData = lines;
      }

      const dataUpdated = filteredData.map((item) => {
        return {
          ...item,
          sn: item.rows && item.rows > 0 ? item.sn : '',
          journalName: item.rows && item.rows > 0 ? item.journalName : '',
          locationName: item.rows && item.rows > 0 ? item.locationName : '',
          ledgerType: item.rows && item.rows > 0 ? item.ledgerType : '',
          refNumber: item.rows && item.rows > 0 ? item.refNumber : '',
          createdAt:
            item.rows && item.rows > 0
              ? convertUTCStringtoLocalString(item.createdAt, DEFAULT_DATE_FORMAT)
              : '',
          transactionDate:
            item.journalDate && item.rows && item.rows > 0
              ? convertUTCStringtoLocalString(item.journalDate, DEFAULT_DATE_FORMAT)
              : ''
        };
      });

      exportExcel(columns, dataUpdated, 'Journal Lines');
    } catch (err: any) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePDFExportAll = async (exportType: string) => {
    setIsLoading(true);

    try {
      if (lines.length === 0) {
        message.error('No Data to Export');
        return;
      }
      let x;

      if (exportType === 'all') {
        const data = await getInfo(allExportUrl, true);
        x = data.response as IJournalLinesViewResponseTableData[];
      } else {
        const data = await getInfo(exportUrl, true);
        x = data.response as IJournalLinesViewResponseTableData[];
      }

      setExportData(x);
      setOpenModalForPdfExport(true);
    } catch (err: any) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePDFExport = useReactToPrint({
    content: () => printPDFRef.current
  });

  return (
    <Spin spinning={isLoading}>
      {modalData && (
        <JournalInvoice
          openModal={openModalForPrint}
          setOpenModal={setOpenModalForPrint}
          modalData={modalData}
        />
      )}
      <CustomModal
        footer={false}
        isModalOpen={openModalForPdfExport}
        setIsModalOpen={setOpenModalForPdfExport}
        title="PDF print">
        <div className="flex justify-end mb-3">
          <Button type="primary" onClick={handlePDFExport}>
            Print Pdf
          </Button>
        </div>
        <div style={{ maxHeight: '80vh', overflow: 'scroll' }}>
          <CustomTableRowSpan
            columns={columsforPrint}
            datas={exportData}
            reff={printPDFRef}
            title={'Accounts Journal'}
          />
        </div>
      </CustomModal>
      <AppContent
        breadcrumbItems={breadcrumbItems}
        withfilter={true}
        button={
          <>
            <div>
              <AccountFilterTable
                showFilterInitial
                onParentLoading={setIsLoading}
                onInitialLoad={({ data, pagination }) => {
                  setPage(pagination.page);
                  setSize(pagination.size);

                  if (data) {
                    const typedData = data as ISavedData;
                    if (typedData.journalLinesList) setJournalLinesList(typedData.journalLinesList);
                    if (typedData.total) setTotal(typedData.total);
                    if (typedData.isRunningBalance) setIsRunningBalance(typedData.isRunningBalance);
                    if (typedData.pageRunningBalance)
                      setPageRunningBalance(typedData.pageRunningBalance);

                    if (typedData.closingBalanceForNext)
                      setClosingBalanceForNext(typedData.closingBalanceForNext);

                    if (typedData.lines) setLines(typedData.lines);
                    if (typedData.exportURL) setExportUrl(typedData.exportURL);
                    if (typedData.allExportUrl) setAllExportUrl(typedData.allExportUrl);

                    setIsLoading(false);
                  }
                }}
                defaultValues={{
                  value: '',
                  skip: 0,
                  count: 100,
                  locationId: preferenceLocationId ? preferenceLocationId : 1,
                  accountId: accountId?.length > 0 ? [accountId] : []
                }}
                initial={false}
                onSearch={onSubmitFilter}
                form={form}
                buttonParentStyle={'flex justify-end items-center'}>
                <LocationSearchV2
                  hasParentFormItem={false}
                  name={'locationId'}
                  onOptionClick={handleLocationChange}
                  showAll
                />
                <AccountSearchV2
                  isMultiple
                  name={'accountId'}
                  locationId={locationId}
                  hasParentFormItem={false}
                />
                <Form.Item name="value" label="Search">
                  <Input placeholder="Search" />
                </Form.Item>
              </AccountFilterTable>
            </div>
            {/* </div> */}
          </>
        }>
        <div className="flex items-center justify-end mb-4">
          <div className="flex flex-row">
            <ActionDropdown
              button={true}
              text="Export All"
              buttonJSX={<Button className="secondary-button !rounded-md">Export All</Button>}
              menu={
                <Menu
                  items={[
                    {
                      key: '1',
                      label: (
                        <Tooltip title="Export Excel (All Pages)" color="blue">
                          <div className="text-center">Excel</div>
                        </Tooltip>
                      ),

                      onClick: () => {
                        handleExport('all');
                      }
                    },
                    {
                      key: '4',
                      label: (
                        <Tooltip title="Export PDF (All Pages)" color="blue">
                          <div className="text-center">PDF</div>
                        </Tooltip>
                      ),

                      onClick: () => {
                        handlePDFExportAll('all');
                      }
                    }
                  ]}
                />
              }
            />
            <ActionDropdown
              button={true}
              menu={
                <Menu
                  items={[
                    {
                      key: '1',
                      label: (
                        <Tooltip title="Export Excel" color="blue">
                          <div className="text-center">Excel</div>
                        </Tooltip>
                      ),

                      onClick: () => {
                        handleExport('current');
                      }
                    },
                    {
                      key: '4',
                      label: (
                        <Tooltip title="Export PDF" color="blue">
                          <div className="text-center">PDF</div>
                        </Tooltip>
                      ),

                      onClick: () => {
                        handlePDFExportAll('current');
                      }
                    }
                  ]}
                />
              }
            />
          </div>
        </div>
        <GenericTable
          form={form}
          generateSummary
          summaryClassName="text-left"
          columns={
            isRunningBalance ? columns : columns.filter((val) => val.key !== 'runningBalance')
          }
          data={lines}
          hideDefaultPagination={true}
          showPager={false}
          scroll={{ x: 1750, y: '75vh' }}
          pagination={{
            page: page,
            total: journalLinesList.total,
            size: size,
            onPagination,
            scrollToTop: true
          }}
          toSort={handleChange}
        />
      </AppContent>
    </Spin>
  );
};

export default NewJournalLinesList;
