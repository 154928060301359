import { Input as AntdInput, InputNumber as AntdInputNumber } from 'antd';
import { CSSProperties, useEffect, useState, forwardRef } from 'react';
import DataGrid, {
  Column,
  RenderCellProps,
  type DataGridHandle,
  type DataGridProps
} from 'react-data-grid';
import { focusNextEditableCell, focusPrevEditableCell } from './focus.service';

export const GRID_MIN_HEIGHT = 280;
export const GRID_MAX_HEIGHT = 600;
export const GRID_ROW_HEIGHT = 35;
export const DEFAULT_HEADER_ROW_HEIGHT = 60;
export const EXTRA_HEIGHT = 126.8; // Column header + padding/margins

interface ReactDataGridProps<R, SR, K extends React.Key>
  extends Omit<DataGridProps<R, SR, K>, 'renderers' | 'onCellKeyDown'> {
  emptyRowFallback?: React.ReactNode;
  rowHeight?: number;
}

type InputCommonProps<T> = {
  props: RenderCellProps<T>;
  disabled?: boolean;
  rowColumnLength?: number;
};

type KeyboardProps<T> =
  | { keyboard?: false }
  | { keyboard: true; columns: Column<T>[]; gridRef: React.RefObject<DataGridHandle> };

type InputProps<T> = InputCommonProps<T> & KeyboardProps<T>;

type IRenderInputNumberProps<T> = InputProps<T> & {
  onChange?: (value: number) => void;
};

type IRenderInputProps<T> = InputProps<T> & {
  onChange?: (value: string) => void;
};

function handleKeyDown<T>(
  event: React.KeyboardEvent,
  props: RenderCellProps<T>,
  flattenColumns: Column<T>[],
  gridRef: React.RefObject<DataGridHandle>,
  rowColumnLength?: number
) {
  event.stopPropagation();
  if (event.key === 'Tab') {
    event.preventDefault(); // Prevent default tabbing behavior
    event.shiftKey
      ? focusPrevEditableCell<T>(props, flattenColumns, gridRef, rowColumnLength)
      : focusNextEditableCell<T>(props, flattenColumns, gridRef, rowColumnLength);
  }
}

export function ReadOnlyInput(props: { value?: string | number; style?: CSSProperties }) {
  return (
    <div
      className="w-full h-full custom-grid-input"
      onClick={(e) => e.stopPropagation()}
      title={props.value ? String(props.value) : undefined}>
      <AntdInput value={props.value} className="antd-grid-input" style={props.style} readOnly />
    </div>
  );
}

export function Input<R>(inputProps: IRenderInputProps<R>) {
  const { props, disabled, onChange, ...restProps } = inputProps;
  const rowValue = props.row as unknown as Record<string, string>;

  return (
    <div
      className="w-full h-full custom-grid-input"
      onClick={(e) => e.stopPropagation()}
      onDoubleClick={(e) => e.stopPropagation()}>
      <AntdInput
        className="antd-grid-input"
        onKeyDown={(event) => {
          if (restProps.keyboard)
            handleKeyDown(
              event,
              props,
              restProps.columns,
              restProps.gridRef,
              restProps.rowColumnLength
            );
        }}
        value={rowValue[props.column.key]}
        disabled={disabled}
        onChange={(event) => onChange?.(event.target.value || '')}
      />
    </div>
  );
}

export function InputNumber<T>(inputProps: IRenderInputNumberProps<T>) {
  const { props, disabled, onChange, ...restProps } = inputProps;
  const rowValue = props.row as unknown as Record<string, number>;

  return (
    <div
      className="w-full h-full custom-grid-input"
      onClick={(e) => e.stopPropagation()}
      onDoubleClick={(e) => e.stopPropagation()}>
      <AntdInputNumber
        keyboard={false}
        disabled={disabled}
        controls={false}
        onKeyDown={(event) => {
          if (restProps.keyboard)
            handleKeyDown(
              event,
              props,
              restProps.columns,
              restProps.gridRef,
              restProps.rowColumnLength
            );
        }}
        className="antd-grid-input"
        value={rowValue[props.column.key]}
        onChange={(value) => onChange?.(typeof value === 'number' ? value : 0)}
        min={0}
      />
    </div>
  );
}

function ReactDataGridComponent<R, SR = unknown, K extends React.Key = React.Key>(
  {
    style,
    rows,
    className = 'rdg-light',
    rowHeight = GRID_ROW_HEIGHT,
    headerRowHeight = DEFAULT_HEADER_ROW_HEIGHT,
    emptyRowFallback = 'No rows have been added yet.',
    enableVirtualization = true,
    ...props
  }: ReactDataGridProps<R, SR, K>,
  ref: React.Ref<DataGridHandle>
) {
  const [gridHeight, setGridHeight] = useState(GRID_MIN_HEIGHT);

  useEffect(() => {
    const rowsTotalHeight = rows.length * rowHeight + EXTRA_HEIGHT;
    const height = Math.min(rowsTotalHeight, GRID_MAX_HEIGHT);
    if (height > GRID_MIN_HEIGHT) {
      setGridHeight(height);
    }
  }, [rows.length, rowHeight]);

  return (
    <DataGrid
      ref={ref}
      className={className}
      rows={rows}
      rowHeight={rowHeight}
      onCellKeyDown={(_, e) => e.stopPropagation()}
      enableVirtualization={enableVirtualization}
      headerRowHeight={headerRowHeight}
      style={{ ...style, height: style?.height || gridHeight }}
      renderers={{
        noRowsFallback: (
          <div className="relative col-span-full">
            <div className="sticky top-0 h-full left-0 flex items-center justify-center">
              <p>{emptyRowFallback}</p>
            </div>
          </div>
        )
      }}
      {...props}
    />
  );
}

const ReactDataGrid = forwardRef(ReactDataGridComponent) as unknown as {
  <R, SR, K extends React.Key>(
    props: ReactDataGridProps<R, SR, K> & { ref?: React.RefObject<DataGridHandle> }
  ): React.ReactElement;
  displayName?: string;
};

ReactDataGrid.displayName = 'ReactDataGrid';
export default ReactDataGrid;
