import { Button, Divider, message, Select } from 'antd';
import { useState, useEffect } from 'react';
import useDebounce from '@/hooks/useDebounce';
import { get_product_active_list_only, get_product_list_only } from '@/services/products/queries';
import ProductsDB from '@/store/localstorage/ProductsDB';
import { getUserData } from '@/utils/auth.utils';
import { IProductDetails } from '@/services/products/types';
import getErrorMessage from '@/utils/getError';
import { NamePath } from 'antd/lib/form/interface';

interface Iprops {
  add2: any;
  name?: NamePath;
  locationId?: number;
  showHidden?: boolean;
}

const ProductSearchForOffer = ({ add2, locationId, showHidden }: Iprops) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const debouncedSearchValue = useDebounce(searchValue, 500);
  const [isCacheResponse, setIsCacheResponse] = useState<boolean>(false);
  const [productList, setProductList] = useState<IProductDetails[]>([]);
  const [productSearch, setProductSearch] = useState<IProductDetails[]>([]);
  const { Option } = Select;
  const [skip, setSkip] = useState<number>(0);
  const [isMore, setIsMore] = useState<boolean>(true);

  async function fetchProducts(skip = 0, count = 1000, value = '', filter = '') {
    const params = new URLSearchParams(filter);
    if (locationId) params.set('locationId', locationId.toString());

    const updatedFilter = params.toString();
    return showHidden
      ? get_product_list_only(skip, count, value, filter)
      : get_product_active_list_only(skip, count, value, updatedFilter);
  }

  async function fetchProductsViaIndexDB(name = '', limit = 10) {
    return showHidden
      ? ProductsDB.searchProduct(name, limit)
      : ProductsDB.searchActiveProduct(name, limit, locationId);
  }

  if (!locationId) {
    const { preferences } = getUserData();
    locationId = preferences?.preferences
      ? JSON.parse(preferences?.preferences)?.locationId
      : message.error('Please choose a location from preferences!');
  }

  const searchProductIndexDB = async (value: string) => {
    try {
      const response = await fetchProductsViaIndexDB(value);
      if (!response || response.length == 0) {
        message.info('Cannot find any product with that name in cache, searching in server...');
        searchProduct(value);
      } else {
        setProductSearch(response);
        checkProductAndAdd(response);
        setIsCacheResponse(true);
      }
    } catch (error) {
      message.error(error as string);
    }
  };
  const searchProduct = async (value: string) => {
    let response;
    try {
      response = await fetchProducts(0, 10, value);
    } catch (e) {
      getErrorMessage(e);
    }

    if (!response || response.length == 0) {
      message.error('Cannot find any product with that name!');
      setProductSearch([]);
      setIsMore(false);
    } else {
      setProductSearch(response);
      checkProductAndAdd(response);
      ProductsDB.addProducts(response);
      if (response.length < 10) {
        setIsMore(false);
      } else {
        setIsMore(true);
      }
    }
    setIsCacheResponse(false);
    setSkip(10);
  };

  const searchMoreProduct = async (value: string) => {
    let response;
    try {
      response = await fetchProducts(skip, 10, value);
    } catch (e) {
      getErrorMessage(e);
    }

    if (!response || response.length == 0) {
      message.info('Cannot find more products with that value in server!');
      setIsMore(false);
    } else {
      setSkip(skip + 10);
      setProductSearch([...productSearch, ...response]);
      checkProductAndAdd(response);
      ProductsDB.addProducts(response);

      if (response.length < 10) {
        setIsMore(false);
      }
    }
  };
  useEffect(() => {
    searchProductIndexDB(debouncedSearchValue);
  }, [debouncedSearchValue]);
  const checkProductAndAdd = (products: IProductDetails[]) => {
    if (productList.length > 0) {
      products = products.filter((value) => {
        const searchProduct = productSearch.find((val) => val.id == value.id);
        if (searchProduct) return false;
        return true;
      });

      if (products.length > 0) setProductList((prevValue) => [...prevValue, ...products]);
    } else {
      setProductList(products);
    }
  };
  const options = productSearch.map((d) => (
    <Option key={d.id} value={d.id}>
      {`${d.name} (${d.sku})`}
    </Option>
  ));

  return (
    <>
      <Select
        id="productSearch-custom"
        showSearch
        placeholder={'Search product'}
        defaultActiveFirstOption={false}
        style={{ borderRadius: '10px' }}
        autoClearSearchValue={true}
        showArrow={false}
        filterOption={false}
        onSearch={(val) => {
          setSearchValue(val);
        }}
        onSelect={async (val: number) => {
          const selectedProduct = productSearch.find((value) => value.id == val);
          const hasDefaultUnit = selectedProduct?.productUnits?.find((unit) => unit.isDefault);
          if (!hasDefaultUnit) {
            message.warning('This product does not have a default unit', 5);
          }

          add2({
            productId: val,
            productName: selectedProduct?.name,
            type: 'percentage',
            minimumQuantity: 0,
            minimumAmount: 0,
            percentage: 0,
            flat: 0
          });
        }}
        notFoundContent={null}
        dropdownRender={(menu) => (
          <>
            {menu}
            <Divider style={{ margin: '8px 0' }} />
            {isCacheResponse ? (
              <div className="flex flex-col" style={{ padding: '0 8px 4px' }}>
                <Button
                  type="text"
                  style={{
                    color: 'blue',
                    width: '100%'
                  }}
                  onClick={() => {
                    searchProduct(searchValue);
                  }}>
                  {'Pull More & Sync'}
                </Button>
              </div>
            ) : (
              <div className="flex flex-col" style={{ padding: '0 8px 4px' }}>
                {isMore ? (
                  <Button
                    type="text"
                    style={{
                      color: 'blue',
                      width: '100%'
                    }}
                    onClick={() => {
                      searchMoreProduct(searchValue);
                    }}>
                    {/* {'Sync & Search on Server'} */}
                    {'Get More...'}
                  </Button>
                ) : (
                  <div
                    style={{
                      width: '100%',
                      textAlign: 'center'
                    }}>
                    No more data...
                  </div>
                )}
              </div>
            )}
          </>
        )}>
        {options}
      </Select>
    </>
  );
};

export default ProductSearchForOffer;
