import isBetween from './isBetween';

export function isTodayIncluded(val: string) {
  const query = new URLSearchParams(val);
  const startDate = query.get('startDate') as string;
  const endDate = query.get('endDate') as string;

  if (startDate && endDate) {
    return isBetween(startDate, endDate);
  }

  return false;
}
