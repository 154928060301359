import moment from 'moment';
import { MinusCircleOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { useMutation } from '@tanstack/react-query';
import {
  Form,
  Button,
  Select,
  InputNumber,
  message,
  PageHeader,
  Divider,
  Checkbox,
  Input,
  Card,
  Spin,
  DatePicker,
  UploadFile,
  UploadProps,
  Upload
} from 'antd';
import { useEffect, useState, useRef, useContext } from 'react';
import { useNavigate } from 'react-router';
import AppContent from '@/components/Common/Content/Content';
import {
  create_procurement_mutation,
  create_tasks_mutation
} from '@/services/procurement/mutations';
import {
  get_unexpired_lots_details_bylocationId_productId,
  get_product_list,
  get_units_list,
  prices_by_groupId,
  get_product_list_ids,
  get_unexpired_lots_details_bylocationId_productIds
} from '@/services/products/queries';
import { IProductType, IUnits } from '@/services/products/types';
import UnitsDB from '@/store/localstorage/UnitsDB';
import { ILines, IProductUnits, Line } from '@/services/sell/types';
import CustomButton from '@/components/Common/CustomButton/CustomButton';
import { LocationSearch } from '@/components/Common/LocationSearch/LocationSearch';
import { VendorSearch } from '@/components/Common/VendorSearch/VendorSearch';
import ProductSearchForLines from '@/components/Common/ProductSearch2';
import {
  find_default_product_category,
  find_financial_year,
  find_purchase_confirmation
} from '@/store/localstorage/preferences';
import ReusableQuantity from '@/components/Common/ReusableQuantity';
import { getUserData } from '@/utils/auth.utils';
import CustomErrorModal from '@/components/Common/CustomErrorModal';
import { IProcurementCreateRequest, IPurchaseConfirmationData } from '@/services/procurement/types';
import { CustomModal } from '@/components/Common/CustomModal';
import ReusablePurchaseConfirm from '@/components/Common/CustomModal/ReusablePurchaseConfirm';
import ProductsDB from '@/store/localstorage/ProductsDB';
import { WebSocketContext } from '@/contexts/websocket.context';
import { SocketEvents, SystemNotificationType } from '@/constants/websocketConfig';
import { PurchaseBuyerType, PurchaseType } from '@/services/purchases/enums';
import ProductCategorySearch from '@/components/Common/ProductCategorySearch/ProductCategorySearch';
import useBeforeUnloadListener from '@/hooks/useBeforeUnloadListener';
import { AccountRulesEvent, AccountType } from '@/services/accounts/enums';
import { checkHasAccountRule } from '@/services/accounts/services';
import CustomInfoModal from '@/components/Common/CustomInfoModal';
import AgentsSearch from '@/components/Common/AgentsSearch';
import DebounceButton from '@/components/Common/DebounceButton';
import { IPurchaseAgentBillRange } from '@/services/purchases/types';
import {
  get_purchase_agent_bill_cancel_list,
  get_purchase_agent_bill_list
} from '@/services/purchases/queries';
import { AgentsDB } from '@/store/localstorage/AgentsDB';
import { onPreviewImage } from '@/services/upload/services';
import { uploadImagesToServer } from '@/services/upload/queries';
import { getVendors } from '@/pages/expense/view/services.expense';
import { get_account_details_by_userid_type } from '@/services/accounts/queries';
import DebouncedInputNumber from '@/components/Common/InputNumber';
import isAxiosError from '@/utils/isAxiosError';
import { useFilterStore } from '@/store/zustand';
import { ListPage } from '@/constants/list.enum';

const { Option } = Select;

const CreateProcurement = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const { socket } = useContext(WebSocketContext);
  const [isTask, setIsTask] = useState<boolean>(true);
  const [hasRule, setHasRule] = useState<boolean>(true);
  // const { data: locationsList } = useQuery(['locations'], async () => get_location_list());
  const [allLocalUnits, setAllLocalUnits] = useState<IUnits[]>([]);
  const [totalLots, settotalLots] = useState<any[]>([]);
  // const { data: vendorsList } = useQuery(['vendors'], async () => get_vendor_list());
  // This is needed to get the product details update as its written
  const [productDetails, setProductDetails] = useState<any>();
  const [productSearch, setProductSearch] = useState<any[]>([]);
  const [productList, setProductList] = useState<any[]>([]);
  // const [searchproductList, setsearchProductList] = useState<any[]>([]);
  // const debouncedSearchValue = useDebounce(searchValue, 500);
  const [isLoading, setisLoading] = useState<boolean>(false);
  const [pricegroupDropdown] = useState<any>([]);
  const [pricegroupsgroupId, setpricegroupsId] = useState<any>({});
  const [confirmModalValues, setConfirmModalValues] = useState<IPurchaseConfirmationData>();
  const [modalOpenForConfirmation, setModalOpenForConfirmation] = useState<boolean>(false);
  const [createValues, setCreateValues] = useState<IProcurementCreateRequest>();
  const autofocusRef: React.Ref<any> = useRef(null);
  const preferenceFinancialYear = find_financial_year();
  const loggedInUser = getUserData();
  const [productCategory, setProductCategory] = useState<Map<number, number | string | undefined>>(
    new Map()
  );

  const [invoiceNumber, setInvoiceNumber] = useState('');
  const [fileLists, setFileLists] = useState<(UploadFile | undefined)[]>([]);

  // BillNoId with min and max
  const [disabledBillNumber, setDisabledBillNumber] = useState<Record<number, number[]>>({});
  const [billMinMax, setBillMinMax] = useState<
    Record<number, { min: number; max: number; current: number }>
  >({});

  const [allPurchaseAgentBill, setAllPurchaseAgentBill] = useState<
    Record<number, IPurchaseAgentBillRange[]>
  >({});

  const [allSelectedVendor, setAllSelectedVendor] = useState<
    Array<{ vendorId: number; isArchived: boolean; accountName: string; vendorName: string }>
  >([]);

  async function getAgentIdFromUserId(userId: number) {
    const agent = await AgentsDB.getAgentsByUserIds([userId]);
    return agent[0].id;
  }

  const handleFileChange = (newFileList: UploadFile[], name: number) => {
    const currentFile = newFileList[0];
    fileLists[name] = currentFile;
    setFileLists([...fileLists]);
  };

  async function getAgentPurchaseBillRange(userId: number) {
    const agentId = await getAgentIdFromUserId(userId);

    if (allPurchaseAgentBill[userId]) {
      const current = allPurchaseAgentBill[userId];
      setAllPurchaseAgentBill((prev) => ({ ...prev, [userId]: current }));
      return current;
    }

    const params = new URLSearchParams();
    params.set('agentId', agentId.toString());
    params.set('archiveStatus', 'ACTIVE');
    params.set('page', '1');
    params.set('size', '1000');

    const filter = params.toString();

    const { results: agentBillList } = await get_purchase_agent_bill_list(filter);
    setAllPurchaseAgentBill((prev) => ({ ...prev, [userId]: agentBillList }));

    return agentBillList;
  }

  useEffect(() => {
    socket?.on('connect', async () => {
      // console.log('Socket Reconnected');
      const locationId = form.getFieldValue(['locationId']);
      const purchases = form.getFieldValue(['purchases']);
      const productIds = new Set<number>();
      purchases.forEach((value: any) => {
        value.lines.forEach((line: ILines) => {
          productIds.add(line.productId);
        });
      });
      await fetchLotsOnLocationandProductChange([...productIds], locationId, 'lotsupdate');
    });

    socket?.on(SocketEvents.SYSTEM_NOTIFICATION, async (data) => {
      if (data.type === SystemNotificationType.LOTS_ZERO) {
        const locationId = form.getFieldValue(['locationId']);
        const socketData = data.data as { locationId: number };
        if (socketData.locationId === locationId) {
          settotalLots((prev) => {
            return prev.map((a) => ({ ...a, qtyAvailable: 0 }));
          });
        }
      }

      if (data.type === SystemNotificationType.LOTS_UPDATE) {
        const purchases = form.getFieldValue(['purchases']);
        const locationId = form.getFieldValue(['locationId']);

        const productIds = new Set<number>();

        purchases.forEach((value: any) => {
          value.lines.forEach((line: ILines) => {
            productIds.add(line.productId);
          });
        });

        let updatedProducts = data.data as { productId: number; locationId: number }[];
        if (locationId) {
          updatedProducts = updatedProducts.filter(
            (value) =>
              value.locationId === locationId && Array.from(productIds).includes(value.productId)
          );
        }

        if (updatedProducts.length > 0) {
          const updatedProductIds = updatedProducts.map((value) => value.productId);
          await fetchLotsOnLocationandProductChange(updatedProductIds, locationId, 'lotsupdate');
        }
      }
    });

    return () => {
      socket?.off(SocketEvents.SYSTEM_NOTIFICATION);
    };
  }, [socket?.connected]);

  const createTaskMutation = useMutation(create_tasks_mutation, {
    onSuccess: (data: any) => {
      message.success('Task added successfully');
    }
  });

  const zustandFilter = useFilterStore();
  const createProcurementsMutation = useMutation(create_procurement_mutation, {
    onSuccess: () => zustandFilter.resetState(ListPage.PURCHASE)
  });

  const onFinish = async (values: any) => {
    // Check Account Archived
    const vendorWithArchivedAccount = allSelectedVendor.filter((vendor) => vendor.isArchived);
    if (vendorWithArchivedAccount.length > 0) {
      CustomErrorModal({
        title: 'Procurement',
        width: 500,
        message: (
          <span>
            Unable to create procurement though this vendors.
            <div>
              <strong>Reason:</strong> <span className="text-red-500">Account Archived</span>.
            </div>
            <div>
              <strong>Supplier Names:</strong>{' '}
              <ul className="text-blue-500 mb-0">
                {vendorWithArchivedAccount.map((vendor) => (
                  <li key={vendor.vendorId}>{vendor.vendorName}</li>
                ))}
              </ul>
            </div>
          </span>
        )
      });
      return;
    }

    const uploadImagesResponse = await uploadImagesToServer(fileLists);

    values.purchases = values.purchases.map((val: any, index: number) => {
      // Delete agentPurchase if it is not self invoice
      if (!val.isSelfInvoice) delete val.agentPurchase;
      const mediaId = uploadImagesResponse[index]?.id;
      if (!mediaId) return { ...val, createdBy: loggedInUser.id };
      return { ...val, mediaId, createdBy: loggedInUser.id };
    });

    // values.createdBy = loggedInUser.id;
    try {
      // validate lines
      let linesValidation = true;
      for (let i = 0; i < values.purchases.length; i++) {
        if (values.purchases[i].lines === undefined) {
          linesValidation = false;
        } else {
          if (values.purchases[i].lines.length === 0) {
            linesValidation = false;
          }
        }
      }
      if (!linesValidation) {
        throw {
          name: 'LinesError',
          message: 'Please select atleast one Product.'
        };
      }

      // validate category
      let categoryValidation = true;
      for (let i = 0; i < values.purchases.length; i++) {
        const categoryId = values.purchases[i].categoryId;
        if (categoryId && productCategory.get(i) !== 'default') {
          for (let ind = 0; ind < values.purchases[i].lines.length; ind++) {
            let productDetails = await ProductsDB.getProduct(
              values.purchases[i].lines[ind].productId
            );
            if (!productDetails) {
              const allProduct = await get_product_list_ids([
                ...new Set<number>(
                  values.purchases[i].lines.forEach((val: any) => {
                    return val.productId;
                  })
                )
              ]);
              await ProductsDB.addProducts(allProduct.data.results);
              productDetails = await ProductsDB.getProduct(
                values.purchases[i].lines[ind].productId
              );
            }
            // console.log('categoryId', categoryId);
            if (typeof productDetails === 'object') {
              // console.log('categoryValidation', productDetails.categoryId);
              if (categoryId !== productDetails.categoryId) {
                categoryValidation = false;
              }
            }
          }
        }
      }
      // console.log('categoryValidation', categoryValidation);
      if (!categoryValidation) {
        throw {
          name: 'CategoryError',
          message: 'Category does not match with its product. Please check and try again!'
        };
      }
      let totalDiscount = 0;
      values.purchases.map((data: any) => {
        totalDiscount = totalDiscount + data.discount;
      });

      for (let i = 0; i < values.purchases.length; i++) {
        let total = 0;
        for (let j = 0; j < values.purchases[i].lines.length; j++) {
          total = total + values.purchases[i].lines[j].total + values.purchases[i].lines[j].vat;
        }
        if (values.purchases[i].discount > total) {
          form.setFields([
            {
              name: ['purchases', i, 'discount'],
              errors: ['Discount cannot be greater than total']
            }
          ]);
          return;
        }
      }

      values.purchases = values.purchases.map((val: any) => {
        const expiry = [];
        // Append Expiry with productId and date, then remove from line
        for (const line of val.lines) {
          const expiryDate = line.expiry;
          delete line.expiry;

          if (!expiryDate) continue;
          expiry.push({
            productId: line.productId,
            expiryDate: moment(expiryDate).format('YYYY-MM-DD')
          });
        }

        if (expiry.length > 0) {
          val.expiry = expiry;
        }

        return val;
      });

      const grandTotal = values.grandtotal;
      setisLoading(true);
      values.date = JSON.stringify(new Date()).slice(1, -1);
      setCreateValues(values);
      if (find_purchase_confirmation()) {
        setisLoading(false);
        const modalValues = { ...values, grandTotal: grandTotal };
        confirmationPopup(modalValues);
      } else {
        createProcurementsMutation.mutateAsync(values, {
          onSuccess: async ({ data }: { data: any }) => {
            if (data) {
              if (isTask) {
                for (let index = 0; index < data.length; index++) {
                  createTaskMutation.mutate({
                    name: ' ',
                    description: ' ',
                    assignee: null,
                    reference: 'purchase',
                    referenceId: data[index].id,
                    status: 'assigned',
                    dueDate: new Date(new Date().getTime() + 10 * 24 * 60 * 60 * 1000)
                  });
                }
              }
              message.success('Procurement added successfully');
              setisLoading(false);
              navigate('/purchase');
            }
          },
          onError: (e: any) => {
            setisLoading(false);
          }
        });
      }
    } catch (errors: any) {
      if (isAxiosError(errors)) return;
      if ('name' in errors) {
        CustomErrorModal({
          title: 'Error! Cannot create procurement',
          message: errors.message
        });
      } else {
        CustomErrorModal({
          title: 'Error! Cannot create procurement',
          message: errors.message
        });
      }
    }
  };

  const checkAccountRule = async (locationId: number) => {
    if (
      (await checkHasAccountRule(locationId, AccountRulesEvent.PURCHASE_CREATE)) &&
      (await checkHasAccountRule(locationId, AccountRulesEvent.VAT_CREATE))
    ) {
      setHasRule(true);
    } else {
      setHasRule(false);
      CustomInfoModal({
        title: 'Info',
        message: `"${AccountRulesEvent.PURCHASE_CREATE}" or "${AccountRulesEvent.VAT_CREATE}" rule has not been created!`
      });
    }
  };

  const confirmationPopup = (values: IPurchaseConfirmationData) => {
    setConfirmModalValues(values);
    setModalOpenForConfirmation(true);
  };

  const handleConfirmationModalSubmit = async () => {
    try {
      setisLoading(true);
      if (createValues) {
        await createProcurementsMutation.mutateAsync(createValues, {
          onSuccess: async ({ data }: { data: any }) => {
            if (data) {
              if (isTask) {
                for (let index = 0; index < data.length; index++) {
                  createTaskMutation.mutate({
                    name: ' ',
                    description: ' ',
                    assignee: null,
                    reference: 'purchase',
                    referenceId: data[index].id,
                    status: 'assigned',
                    dueDate: new Date(new Date().getTime() + 10 * 24 * 60 * 60 * 1000)
                  });
                }
              }
              message.success('Procurement added successfully');
              setisLoading(false);
              navigate('/purchase');
            }
          },
          onError: (e: any) => {
            setisLoading(false);
          }
        });
      }
    } catch (errors: any) {
      if (isAxiosError(errors)) return;
      if ('name' in errors) {
        CustomErrorModal({
          title: 'Error! Cannot create procurement',
          message: errors.response.data.message
        });
      } else {
        CustomErrorModal({
          title: 'Error! Cannot create procurement',
          message: errors.message
        });
      }
    }
  };

  const fetchLotsOnLocationandProductChange = async (
    productsIdArray: number[],
    locationId: number,
    from: string
  ) => {
    try {
      if (!locationId) {
        throw {
          name: 'Location Error',
          message: 'Please select Location!'
        };
      }

      if (productsIdArray.length === 0) {
        return [];
      }

      const currenttotalLots = [];
      if (from === 'productchange') {
        const filterLots = totalLots.find((value: Line) => value.productId == productsIdArray[0]);
        if (!filterLots) {
          const response = await get_unexpired_lots_details_bylocationId_productId(
            locationId,
            productsIdArray[0]
          );
          settotalLots([...totalLots, ...response]);
          return [...totalLots, ...response];
        }
      } else if (from === 'lotsupdate') {
        const result = await get_unexpired_lots_details_bylocationId_productIds(locationId, [
          ...new Set(productsIdArray)
        ]);

        settotalLots((prev) => {
          const filterLots = prev.filter((value) => !productsIdArray.includes(value.productId));
          return [...filterLots, ...result];
        });
      } else {
        const result = await get_unexpired_lots_details_bylocationId_productIds(locationId, [
          ...new Set(productsIdArray)
        ]);
        currenttotalLots.push(...result);
        settotalLots([...currenttotalLots]);
      }
    } catch (err: any) {
      message.error(err.message);
    }
  };

  const onLocationChange = async (value: number) => {
    form.setFieldValue('location', value);
    await checkAccountRule(value);
    const mySet = new Set<number>();
    const data = form.getFieldValue(['purchases']);
    if (data.length > 0) {
      data.map((currSell: any, ind1: number) => {
        if (currSell?.lines?.length > 0) {
          currSell.lines.map((currSellline: any, ind2: number) => {
            mySet.add(currSellline.productId);
            form.setFieldValue(['purchases', ind1, 'lines', ind2, 'lotId'], null);
          });
        }
      });
    }
    // console.log('Lines-->', data);
    // console.log('my set', mySet);
    fetchLotsOnLocationandProductChange(Array.from(mySet), value, 'location');
    // let defaultPrice: any = [];
    // if (defaultPriceBylocationId[value]) {
    //   defaultPrice = defaultPriceBylocationId[value];
    //   setpricegroupDropdown(pricegrouplocationId[value]);
    // } else {
    //   const response = await get_price_groups_by_location(value);
    //   // console.log('resposne===>', response);
    //   if (response?.data) {
    //     setpricegroupDropdown(response.data);
    //     setpricegrouplocationId({ ...pricegrouplocationId, [value]: response.data });
    //   }
    //   if (response.data[0]?.id) {
    //     const response2 = await prices_by_groupId(response.data[0].id);
    //     // console.log('response 2-->', response2);
    //     defaultPrice = [...response2.data];
    //     setpricegroupsId({ ...pricegroupsgroupId, [response.data[0].id]: response2.data });
    //     setDefaultPriceBylocationId({ ...defaultPriceBylocationId, [value]: response2.data });
    //   } else {
    //     data.map((curr: any, ind: number) => {
    //       curr.lines.map((currlines: any, ind2: number) => {
    //         form.setFieldValue(['purchases', ind, 'lines', ind2, 'unitPrice'], undefined);
    //         form.setFieldValue(['purchases', ind, 'lines', ind2, 'sellingPrice'], undefined);
    //       });
    //     });
    //   }
    // }
    // // console.log('defaultPrice', defaultPrice);
    // if (defaultPrice.length > 0) {
    //   data.map((curr: any, ind: number) => {
    //     if (curr.lines.length > 0) {
    //       curr.lines.map((currline: any, ind2: number) => {
    //         const findone = defaultPrice.find(
    //           (res2elem: any) => res2elem.productId === currline.productId
    //         );
    //         // console.log('findOne', findone);
    //         if (findone) {
    //           const alreadySelectedUnitId = form.getFieldValue([
    //             'purchases',
    //             ind,
    //             'lines',
    //             ind2,
    //             'unitId'
    //           ]);
    //           // console.log('alreadySelectedUnitId', alreadySelectedUnitId);
    //           if (alreadySelectedUnitId !== undefined && alreadySelectedUnitId !== null) {
    //             const findUnit: any = allLocalUnits.find(
    //               (curr: any) => curr.id === alreadySelectedUnitId
    //             );
    //             // console.log('findUnit', findUnit);
    //             form.setFieldValue(
    //               ['purchases', ind, 'lines', ind2, 'unitPrice'],
    //               findone.sellingPrice
    //             );
    //           } else {
    //             form.setFieldValue(
    //               ['purchases', ind, 'lines', ind2, 'unitPrice'],
    //               findone.sellingPrice
    //             );
    //           }
    //           form.setFieldValue(
    //             ['purchases', ind, 'lines', ind2, 'sellingPrice'],
    //             findone.sellingPrice
    //           );
    //         }
    //       });
    //     }
    //   });
    // } else {
    //   data.map((curr: any, ind: number) => {
    //     curr.lines.map((currlines: any, ind2: number) => {
    //       form.setFieldValue(['purchases', ind, 'lines', ind2, 'unitPrice'], undefined);
    //       form.setFieldValue(['purchases', ind, 'lines', ind2, 'sellingPrice'], undefined);
    //     });
    //   });
    // }
  };

  const onVendorChange = async (vendorId: number, name: number) => {
    form.setFieldValue(['purchases', name, 'vendorId'], vendorId);
    const financialYear = preferenceFinancialYear;

    if (vendorId && financialYear) {
      const formattedInvoiceNumber = `${vendorId}-${financialYear}-`;
      setInvoiceNumber(formattedInvoiceNumber);
    }

    if (vendorId) {
      const vendorDetails = await getVendors(vendorId);
      if (typeof vendorDetails !== 'string') {
        const userId = vendorDetails.userId;
        const userAccount = await get_account_details_by_userid_type(userId, AccountType.USER);
        setAllSelectedVendor((prev) => {
          const updatedValue = {
            vendorId,
            isArchived: userAccount?.isArchived,
            accountName: userAccount?.name,
            vendorName: vendorDetails.user?.name
          };

          prev[name] = updatedValue;
          return [...prev];
        });

        if (userAccount?.isArchived) {
          CustomErrorModal({
            title: 'Procurement',
            width: 500,
            message: (
              <span>
                Unable to create procurement though this vendor.
                <div>
                  <strong>Reason:</strong> <span className="text-red-500">Account Archived</span>.
                </div>
                {vendorDetails.user?.name && (
                  <div>
                    <strong>Supplier Name:</strong>{' '}
                    <span className="text-blue-500">{vendorDetails.user?.name}</span>
                  </div>
                )}
                {!vendorDetails.user?.name && (
                  <div>
                    <strong>Account Name:</strong>{' '}
                    <span className="text-blue-500">{userAccount?.name}</span>
                  </div>
                )}
              </span>
            )
          });
          return;
        }
      }
    }
  };

  const onProductChanges = async (value: number, name: number) => {
    const data = form.getFieldValue(['purchases', name, 'lines']);
    // console.log('data', data);
    form.setFieldValue(['purchases', name, 'lines', data.length - 1, 'productId'], value);
    form.setFieldValue(['purchases', name, 'lines', data.length - 1, 'unitId'], undefined);
    const currentLocation = form.getFieldValue(['locationId']);
    // console.log('Product change-->', value);
    const totalLots = await fetchLotsOnLocationandProductChange(
      [value],
      currentLocation,
      'productchange'
    );
    // await checkAndGetLot(value);
    if (productList) {
      const selectedProduct = productList.find((val: IProductType) => val.id == value);
      const unitIdList: IProductUnits[] = selectedProduct.productUnits;
      const defaultUnit = unitIdList.find((currUnit: any) => currUnit.isDefault === true);
      form.setFieldValue(
        ['purchases', name, 'lines', data.length - 1, 'unitId'],
        defaultUnit?.unitId
      );
      form.setFieldValue(
        ['purchases', name, 'lines', data.length - 1, 'unitPrice'],
        selectedProduct.purchasePrice
      );
      let fetch = false;
      for (const value of unitIdList) {
        const unitData: any = await UnitsDB.getUnit(value.unitId);
        if (!unitData) {
          const response = await get_units_list();
          await UnitsDB.addUnits(response);
          fetch = true;
        }
      }
      if (fetch) {
        setAllLocalUnits((await UnitsDB.getAllUnits()) as IUnits[]);
      }
    }

    if (value && currentLocation && totalLots) {
      let filteredLots: any = [];
      if (totalLots.length !== 0) {
        filteredLots = totalLots.filter((currLot: any) => currLot.productId === value);
      }

      // sort lots by grade and qty
      const sortedLot = [...filteredLots].sort((a, b) => {
        if (a.grade === b.grade) {
          return b.qtyAvailable - a.qtyAvailable;
        } else {
          return a.grade.localeCompare(b.grade);
        }
      });

      if (sortedLot.length > 0) {
        const defaultSelectedLot = sortedLot[0];

        form.setFieldValue(
          ['purchases', name, 'lines', data.length - 1, 'lotId'],
          defaultSelectedLot.id
        );

        form.setFieldValue(
          ['purchases', name, 'lines', data.length - 1, 'hsCode'],
          defaultSelectedLot.hsCode
        );
      } else {
        form.setFieldValue(['purchases', name, 'lines', data.length - 1, 'lotId'], undefined);
      }
    }
  };

  function onLotChange(name: [number, number], value: number, productId: number) {
    if (!productId) {
      return message.error('Failed to get product id');
    }

    let filteredLots: any = [];
    if (totalLots.length !== 0) {
      filteredLots = totalLots.filter((currLot: any) => currLot.productId === productId);
    }

    const currentSelectedLot = filteredLots.find((currLot: any) => currLot.id === value);

    if (!currentSelectedLot) {
      return message.error('Failed to assign hs code based on selected lot');
    }

    form.setFieldValue(
      ['purchases', name[0], 'lines', name[1], 'hsCode'],
      currentSelectedLot?.hsCode
    );
  }

  const FilterLot = (name: number[]) => {
    // console.log('This is called..');
    // const checkCurrentProduct = form.getFieldValue(['lines', name, 'productId']);
    const checkCurrentProduct = form.getFieldValue([
      'purchases',
      name[0],
      'lines',
      name[1],
      'productId'
    ]);
    const currentLocation = form.getFieldValue(['locationId']);
    if (checkCurrentProduct && currentLocation) {
      let filteredLots: any = [];
      if (totalLots.length !== 0) {
        filteredLots = totalLots.filter(
          (currLot: any) => currLot.productId === checkCurrentProduct
        );
      }
      // console.log('filteredLots', filteredLots);

      const unitId = form.getFieldValue(['purchases', name[0], 'lines', name[1], 'unitId']);
      const unitInfo: any = allLocalUnits.find((val: any) => unitId == val.id);
      return (
        <>
          {filteredLots?.map((value: any) => (
            <Option
              value={value.id}
              key={value.id}
              style={{ color: value.qtyAvailable > 0 ? 'green' : 'red' }}>
              {`(${value.qtyAvailable / (unitInfo?.baseUnitMultiplier || 1)} ${
                unitInfo?.shortName || ''
              }) ${value.lotNumber} Grade-${value.grade} Expiry-${
                value?.expirationDate ? new Date(value.expirationDate).toLocaleDateString() : 'N/A'
              }`}
            </Option>
          ))}
        </>
      );
    }
  };

  const FilterUnits = (name: number[]) => {
    const checkCurrentProduct = form.getFieldValue([
      'purchases',
      name[0],
      'lines',
      name[1],
      'productId'
    ]);
    // console.log(name);
    // console.log(checkCurrentProduct);
    // console.log(form.getFieldsValue());
    if (checkCurrentProduct && productList) {
      const selectedProduct = productList.find(
        (val: IProductType) => val.id == checkCurrentProduct
      );
      const filteredUnits = selectedProduct.productUnits.map((value: IProductUnits) => {
        const data = allLocalUnits.find((val: IUnits) => value.unitId == val.id);
        return data;
      });
      return (
        <>
          {filteredUnits.map((value: any) => {
            if (!value) return null;
            return (
              <Option value={value.id} key={value.id}>
                {`${value.name}`}
              </Option>
            );
          })}
        </>
      );
    }
  };

  const onAgentInvoiceChange = async (billId: number, name: number) => {
    setisLoading(true);
    const agentId = form.getFieldValue(['purchases', name, 'agentId']);
    const agentBillInvoices = allPurchaseAgentBill[agentId];

    const value = agentBillInvoices.find((val) => val.id == billId);
    if (!value) {
      setisLoading(false);
      console.error('Agent Invoice not found!');
      return;
    }

    const actualAgentId = await getAgentIdFromUserId(agentId);

    const query = new URLSearchParams();
    query.set('agentId', actualAgentId.toString());
    query.set('billRangeIds[]', billId.toString());
    query.set('skip', '0');
    query.set('count', '100');

    const { results } = await get_purchase_agent_bill_cancel_list(query.toString());

    const { min, max, id, current } = value;
    const disabledBillNumber = results.map((val) => val.billNumber);

    // Clear Bill Number
    form.setFieldValue(['purchases', name, 'agentPurchase', 'billNo'], undefined);

    setBillMinMax((prev) => ({ ...prev, [id]: { min, max, current } }));
    setDisabledBillNumber((prev) => ({ ...prev, [id]: disabledBillNumber }));
    setisLoading(false);
  };

  useEffect(() => {
    const initData = [
      {
        vendorId: null,
        lines: [],
        discount: 0,
        labourCost: 0,
        shippingAmount: 0,
        purchaseBuyerType: PurchaseBuyerType.OTHER,
        isSelfInvoice: false
      }
    ];
    form.setFieldValue('purchases', initData);
    initializeUnits();
  }, []);

  useEffect(() => {
    window.addEventListener('beforeunload', (e) => {
      e.preventDefault();
    });
    return () => {
      window.removeEventListener('beforeunload', (e) => {
        e.preventDefault();
      });
    };
  }, [form.getFieldValue([])]);

  // const checkProductAndAdd = (products: any[]) => {
  //   if (searchproductList.length > 0) {
  //     products = products.filter((value) => {
  //       const searchProduct = productSearch.find((val) => val.id == value.id);
  //       if (searchProduct) return false;
  //       return true;
  //     });
  //     if (products.length > 0)
  //       setsearchProductList((prevValue) => {
  //         return [...prevValue, ...products];
  //       });
  //   } else {
  //     setsearchProductList(products);
  //   }
  // };
  useBeforeUnloadListener();

  const initializeUnits = async () => {
    setAllLocalUnits((await UnitsDB.getAllUnits()) as IUnits[]);
  };
  const options = productSearch.map((d) => (
    <Option key={d.id} value={d.id}>
      {d.name}
    </Option>
  ));

  const onUnitChange = async (name1: number, name2: number) => {
    const selectedUnitId = form.getFieldValue(['purchases', name1, 'lines', name2, 'unitId']);
    const selectedProductId = form.getFieldValue(['purchases', name1, 'lines', name2, 'productId']);
    const currentLocationId = form.getFieldValue(['locationId']);
    if (!currentLocationId) {
      message.error('Please select locationId.');
      return;
    }
    let didfound = false;
    if (pricegroupDropdown.length == 0) {
      form.setFieldValue(['purchases', name1, 'lines', name2, 'unitPrice'], 0);
    } else {
      for (let ind = 0; ind < pricegroupDropdown.length; ind++) {
        if (pricegroupsgroupId[pricegroupDropdown[ind].id]) {
          const findOne = pricegroupsgroupId[pricegroupDropdown[ind].id].find(
            (curr: any) => curr.productId == selectedProductId && curr.unitId == selectedUnitId
          );
          if (findOne) {
            form.setFieldValue(
              ['purchases', name1, 'lines', name2, 'unitPrice'],
              findOne.sellingPrice
            );
            didfound = true;
            break;
          }
        } else {
          const response2 = await prices_by_groupId(pricegroupDropdown[ind].id);
          setpricegroupsId((prev: any) => ({
            ...prev,
            [pricegroupDropdown[ind].id]: response2.data
          }));
          const findOne = response2.data.find(
            (curr: any) => curr.productId == selectedProductId && curr.unitId == selectedUnitId
          );
          if (findOne) {
            form.setFieldValue(
              ['purchases', name1, 'lines', name2, 'unitPrice'],
              findOne.sellingPrice
            );
            didfound = true;
            break;
          }
        }
      }
    }
    if (!didfound) {
      form.setFieldValue(['purchases', name1, 'lines', name2, 'unitPrice'], 0);
    }
  };

  const onUnitandQuantityChange = async (name: number, name2: number) => {
    // console.log('name', name);
    // console.log('number', value);
    const rate = form.getFieldValue(['purchases', name, 'lines', name2, 'unitPrice']);
    const qty = form.getFieldValue(['purchases', name, 'lines', name2, 'quantity']);
    const mis = form.getFieldValue(['purchases', name, 'lines', name2, 'misc']);
    const dis = form.getFieldValue(['purchases', name, 'lines', name2, 'discount']);

    const total = rate * qty - dis + mis;
    form.setFieldValue(['purchases', name, 'lines', name2, 'total'], total);

    const productId = form.getFieldValue(['purchases', name, 'lines', name2, 'productId']);
    let vat = 0;
    let productDetail = await ProductsDB.getProduct(productId);
    if (!productDetail) {
      const allProducts = await get_product_list();
      await ProductsDB.addProducts(allProducts.data.results);
      productDetail = await ProductsDB.getProduct(productId);
    }
    if (typeof productDetail === 'object' && productDetail.vat) {
      vat = (rate * qty - dis) * (productDetail.vat / 100);
    }
    form.setFieldValue(['purchases', name, 'lines', name2, 'vat'], parseFloat(vat.toFixed(2)));

    const purchases = form.getFieldValue(['purchases']);
    // console.log('purchases', purchases);
    // console.log('lines', lines);
    let grandTotal = 0;
    let totalDiscount = 0;

    for (let i = 0; i < purchases.length; i++) {
      totalDiscount = totalDiscount + form.getFieldValue(['purchases', i, 'discount']);
      for (let j = 0; j < purchases[i].lines.length; j++) {
        grandTotal += purchases[i].lines[j].total + purchases[i].lines[j].vat;
      }
    }
    const totalWithDiscount = grandTotal - totalDiscount;

    form.setFieldValue(['grandtotal'], totalWithDiscount);
    // const grandTotall = form.getFieldValue(['lines','grandtotal']);
    // console.log('grandTotall field value', grandTotall);
  };

  const onVatChange = async () => {
    const purchases = form.getFieldValue(['purchases']);
    // console.log('purchases', purchases);
    // console.log('lines', lines);
    let grandTotal = 0;
    let totalDiscount = 0;

    for (let i = 0; i < purchases.length; i++) {
      totalDiscount = totalDiscount + form.getFieldValue(['purchases', i, 'discount']);
      for (let j = 0; j < purchases[i].lines.length; j++) {
        grandTotal += purchases[i].lines[j].total + purchases[i].lines[j].vat;
      }
    }

    const totalWithDiscount = grandTotal - totalDiscount;

    form.setFieldValue(['grandtotal'], totalWithDiscount);
  };

  const onPriceGroupChange = async (name: number, name2: number) => {
    const productInfo = form.getFieldValue(['purchases', name, 'lines', name2]);
    console.log('product Info', productInfo);
    let defaultPrice: any = [];
    const locationId = form.getFieldValue(['locationId']);
    // console.log('pricegroupsgroupId', pricegroupsgroupId);
    if (locationId && productInfo.priceGroupId) {
      if (!pricegroupsgroupId[productInfo.priceGroupId]) {
        const response2 = await prices_by_groupId(productInfo.priceGroupId);
        defaultPrice = [...response2.data];
        // pricegroupsgroupId[productInfo.priceGroupId] = [...response2.data];
        setpricegroupsId({
          ...pricegroupsgroupId,
          [productInfo.priceGroupId]: response2.data
        });
      } else {
        defaultPrice = pricegroupsgroupId[productInfo.priceGroupId];
      }
    }
    // console.log('default Price', defaultPrice);
    // console.log('locationId', locationId);
    // console.log('this is called', val);
    if (defaultPrice.length > 0) {
      const alreadySelectedUnitId = form.getFieldValue([
        'purchases',
        name,
        'lines',
        name2,
        'unitId'
      ]);
      const findone = defaultPrice.find(
        (res2elem: any) =>
          res2elem.productId === productInfo.productId && res2elem.unitId == alreadySelectedUnitId
      );
      //console.log('findOne', findone);
      if (findone) {
        // console.log('alreadySelectedUnitId', alreadySelectedUnitId);
        if (alreadySelectedUnitId !== undefined && alreadySelectedUnitId !== null) {
          // const findUnit = allLocalUnits.find((curr: any) => curr.id === alreadySelectedUnitId);
          // console.log('findUnit', findUnit);
          form.setFieldValue(
            ['purchases', name, 'lines', name2, 'unitPrice'],
            findone.sellingPrice
          );
        } else {
          form.setFieldValue(
            ['purchases', name, 'lines', name2, 'unitPrice'],
            findone.sellingPrice
          );
        }
        form.setFieldValue(
          ['purchases', name, 'lines', name2, 'sellingPrice'],
          findone.sellingPrice
        );
      } else {
        form.setFieldValue(['purchases', name, 'lines', name2, 'unitPrice'], 0);
        form.setFieldValue(['purchases', name, 'lines', name2, 'sellingPrice'], 0);
      }
    } else {
      form.setFieldValue(['purchases', name, 'lines', name2, 'unitPrice'], 0);
      form.setFieldValue(['purchases', name, 'lines', name2, 'sellingPrice'], 0);
    }
  };

  const onDiscountChange = () => {
    const purchases = form.getFieldValue(['purchases']);
    // console.log('lines', lines);
    let grandTotal = 0;
    let totalDiscount = 0;

    for (let i = 0; i < purchases.length; i++) {
      totalDiscount = totalDiscount + form.getFieldValue(['purchases', i, 'discount']);
      if (purchases[i].lines) {
        for (let j = 0; j < purchases[i].lines.length; j++) {
          grandTotal += purchases[i].lines[j].total + purchases[i].lines[j].vat;
        }
      }
    }

    const totalWithDiscount = grandTotal - totalDiscount;

    form.setFieldValue('grandtotal', totalWithDiscount);
  };

  const isProductAlreadyAdded = (index: number, checkproductId: number) => {
    const lines = form.getFieldValue(['purchases', index, 'lines']);
    const findOne = lines?.find((curr: any) => curr.productId == checkproductId);
    if (findOne) {
      return false;
    }
    return true;
  };

  return (
    <Spin spinning={isLoading}>
      <CustomModal
        isModalOpen={modalOpenForConfirmation}
        setIsModalOpen={setModalOpenForConfirmation}
        title={'Confirmation'}
        footer={false}>
        <ReusablePurchaseConfirm
          data={confirmModalValues}
          handleSubmitClick={handleConfirmationModalSubmit}
        />
      </CustomModal>
      <AppContent breadcrumbItems={breadcrumbItems}>
        <PageHeader
          title="Procurement Information"
          style={{
            padding: '8px 0px'
          }}
        />
        <Form
          form={form}
          initialValues={{
            units: [],
            isTraceable: false
          }}
          onFinish={onFinish}
          disabled={isLoading}
          layout="vertical"
          validateTrigger={'onChange'}
          onValuesChange={(_, allFields) => {
            setProductDetails(allFields);
          }}
          autoComplete="off">
          <div className={'grid cols-1 gap-2 md:grid-cols-3 mb-5'}>
            <LocationSearch onSelect={onLocationChange} notAll={true} required={true} />
          </div>
          <Form.List name="purchases">
            {(fields, { add, remove: remove }, { errors }) => (
              <>
                {fields.map(({ key: key, name: name, ...restField }) => {
                  const value = form.getFieldValue(['purchases', name, 'vendorId']);

                  return (
                    <div key={key}>
                      <PageHeader title="Seller" style={{ padding: '8px 0px 8px 10px' }} />
                      <div
                        className={'grid cols-2 gap-2 md:grid-cols-3 xl:grid-cols-4 mb-5'}
                        key={key}>
                        <Form.Item
                          {...restField}
                          name={[name, 'vendorId']}
                          label="Seller"
                          rules={[{ required: true, message: 'Please choose Seller!' }]}>
                          <VendorSearch
                            applyFilter={true}
                            withoutForm={true}
                            onSelect={(val) => {
                              onVendorChange(val, name);
                            }}
                          />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          label="Discount"
                          name={[name, 'discount']}
                          rules={[{ required: true, message: 'Please add Discount!' }]}>
                          <InputNumber controls={false} min={0} onChange={onDiscountChange} />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          label="Shipping"
                          name={[name, 'shippingAmount']}
                          rules={[{ required: true, message: 'Please add shipping amount!' }]}>
                          <InputNumber controls={false} min={0} />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, 'agentId']}
                          label="Agents"
                          rules={[
                            {
                              required: form.getFieldValue(['purchases', name, 'isSelfInvoice']),
                              message: 'Please choose agent!'
                            }
                          ]}>
                          <AgentsSearch
                            hasParentFormItem
                            onSelect={(val) => {
                              const isSelfInvoice = form.getFieldValue([
                                'purchases',
                                name,
                                'isSelfInvoice'
                              ]);

                              form.setFieldValue(['purchases', name, 'agentId'], val);

                              if (isSelfInvoice) {
                                form.setFieldValue(['purchases', name, 'agentPurchase'], undefined);
                                form.setFieldValue(['purchases', name, 'invoiceNumber'], '');
                                getAgentPurchaseBillRange(Number(val));
                              }
                            }}
                          />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          label="Labour Cost"
                          name={[name, 'labourCost']}
                          rules={[{ required: true, message: 'Please add labour cost!' }]}>
                          <InputNumber controls={false} min={0} />
                        </Form.Item>
                        <Form.Item
                          label="Invoice No."
                          name={[name, 'invoiceNumber']}
                          rules={[{ required: true, message: 'Please add invoice no.!' }]}>
                          <Input
                            addonBefore={invoiceNumber}
                            disabled={form.getFieldValue(['purchases', name, 'isSelfInvoice'])}
                          />
                        </Form.Item>
                        <Form.Item
                          label="Purchase Type"
                          name={[name, 'purchaseType']}
                          rules={[{ required: true, message: 'Please add purchase type!' }]}>
                          <Select placeholder="Select purchase type">
                            {(Object.keys(PurchaseType) as Array<keyof typeof PurchaseType>).map(
                              (key) => (
                                <Select.Option key={key} value={key}>
                                  {PurchaseType[key]}
                                </Select.Option>
                              )
                            )}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          label="Buyer Type"
                          initialValue={PurchaseBuyerType.OTHER}
                          name={[name, 'purchaseBuyerType']}
                          rules={[{ required: true, message: 'Please add purchase buyer type!' }]}>
                          <Select placeholder="Select purchase buyer type">
                            {(
                              Object.keys(PurchaseBuyerType) as Array<
                                keyof typeof PurchaseBuyerType
                              >
                            ).map((key) => (
                              <Select.Option key={key} value={key}>
                                {PurchaseBuyerType[key]}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <LocationSearch
                          formData={{
                            formLabel: 'LCC Location',
                            formName: [name, 'locationLCCId']
                          }}
                          notAll={true}
                          required={false}
                        />
                        <Form.Item name={[name, 'categoryId']} hidden={true}>
                          <Input />
                        </Form.Item>

                        <Form.Item
                          name={[name, 'isSelfInvoice']}
                          label="Invoice"
                          valuePropName="checked">
                          <Checkbox
                            onChange={(value) => {
                              const isChecked = value.target.checked;
                              const selectedAgentId = form.getFieldValue([
                                'purchases',
                                name,
                                'agentId'
                              ]);

                              if (selectedAgentId && isChecked) {
                                getAgentPurchaseBillRange(selectedAgentId);
                              }

                              // Reset Fields
                              if (!isChecked) {
                                form.setFieldValue(['purchases', name, 'agentPurchase'], {});
                                form.setFieldValue(['purchases', name, 'invoiceNumber'], '');
                              }
                            }}>
                            Self Invoice
                          </Checkbox>
                        </Form.Item>

                        {form.getFieldValue(['purchases', name, 'isSelfInvoice']) && (
                          <>
                            <Form.Item
                              label="Agent Invoice"
                              name={[name, 'agentPurchase', 'billNoId']}
                              rules={[
                                {
                                  required: form.getFieldValue([
                                    'purchases',
                                    name,
                                    'isSelfInvoice'
                                  ]),
                                  message: 'Please select bill range!'
                                }
                              ]}>
                              <Select
                                key={form.getFieldValue(['purchases', name, 'agentId'])}
                                placeholder="Select invoice"
                                onChange={(id) => onAgentInvoiceChange(id, name)}
                                dropdownMatchSelectWidth={false}>
                                {allPurchaseAgentBill[
                                  form.getFieldValue(['purchases', name, 'agentId'])
                                ]?.map((val) => (
                                  <Select.Option key={val.id} value={val.id}>
                                    <span>
                                      Current: {val.current} ({val.min} - {val.max})
                                    </span>
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>

                            <Form.Item
                              label="Agent Bill No."
                              rules={[
                                {
                                  required: form.getFieldValue([
                                    'purchases',
                                    name,
                                    'isSelfInvoice'
                                  ]),
                                  message: 'Please add bill no.!'
                                }
                              ]}
                              name={[name, 'agentPurchase', 'billNo']}>
                              <DebouncedInputNumber
                                restrictedNumbers={
                                  disabledBillNumber[
                                    form.getFieldValue([
                                      'purchases',
                                      name,
                                      'agentPurchase',
                                      'billNoId'
                                    ])
                                  ] || []
                                }
                                errorMessage="This bill number is cancelled! Please select another one."
                                debouncedOnChange={(value) => {
                                  form.setFieldValue(
                                    ['purchases', name, 'agentPurchase', 'billNo'],
                                    value
                                  );

                                  form.setFieldValue(
                                    ['purchases', name, 'invoiceNumber'],
                                    value ? String(value) : ''
                                  );
                                }}
                                min={
                                  billMinMax[
                                    form.getFieldValue([
                                      'purchases',
                                      name,
                                      'agentPurchase',
                                      'billNoId'
                                    ])
                                  ]?.current || 0
                                }
                                max={
                                  billMinMax[
                                    form.getFieldValue([
                                      'purchases',
                                      name,
                                      'agentPurchase',
                                      'billNoId'
                                    ])
                                  ]?.max
                                }
                              />
                            </Form.Item>

                            <Form.Item
                              label="Upload Media"
                              valuePropName="fileList"
                              getValueFromEvent={(e) => e.fileList}>
                              <Upload
                                accept="image/*"
                                multiple={false}
                                maxCount={1}
                                listType="text"
                                onPreview={onPreviewImage}
                                onChange={({ fileList }) => handleFileChange(fileList, name)}
                                beforeUpload={() => false}>
                                <Button icon={<UploadOutlined />}>Click to Upload</Button>
                              </Upload>
                            </Form.Item>
                          </>
                        )}

                        <div className="flex justify-center items-center">
                          {fields.length > 0 ? (
                            <MinusCircleOutlined
                              className="dynamic-delete-button flex justify-center items-center"
                              onClick={() => {
                                remove(name);

                                // Remove images from state
                                fileLists[name] = undefined;
                                setFileLists([...fileLists]);
                              }}
                            />
                          ) : null}
                        </div>
                      </div>
                      <Form.List name={[name, 'lines']}>
                        {(fields2, { add: add2, remove: remove2 }, { errors: errors2 }) => (
                          <>
                            <div className="grid grid-cols-2 gap-4">
                              <ProductCategorySearch
                                setValue={productCategory.get(name)}
                                onProductCategoryChange={(val) => {
                                  setisLoading(true);
                                  if (val !== null) {
                                    setProductCategory((prev) => new Map(prev.set(name, val)));
                                    if (val === 'default') {
                                      const defaultCategory = find_default_product_category();
                                      if (defaultCategory === null) {
                                        message.error(
                                          'Please select a default Product Category from preferences.'
                                        );
                                        return;
                                      } else {
                                        form.setFieldValue(
                                          ['purchases', name, 'categoryId'],
                                          defaultCategory
                                        );
                                      }
                                    } else {
                                      form.setFieldValue(['purchases', name, 'categoryId'], val);
                                    }
                                  }
                                  form.setFieldValue(['purchases', name, 'lines'], []);
                                  setisLoading(false);
                                }}
                                onClearProduct={() => {
                                  setProductCategory((prev) => new Map(prev.set(name, undefined)));
                                  form.setFieldValue(['purchases', name, 'categoryId'], undefined);
                                }}
                                name={name}
                                isAll={false}
                                allowClear={true}
                              />
                              <ProductSearchForLines
                                add2={add2}
                                onProductChange={onProductChanges}
                                productList={productList}
                                setProductList={setProductList}
                                productSearch={productSearch}
                                setProductSearch={setProductSearch}
                                name={name}
                                autofocusRef={autofocusRef}
                                isProductAlreadyAdded={isProductAlreadyAdded}
                                index={name}
                                locationId={form.getFieldValue(['locationId'])}
                                validation={productCategory.get(name) !== null ? true : false}
                                productCategory={productCategory.get(name)}
                                isMultiple={true}
                              />
                            </div>
                            {fields2.length > 0 && (
                              <PageHeader
                                title="All Products"
                                style={{
                                  padding: '8px 0px 8px 10px'
                                }}
                              />
                            )}
                            <Card
                              style={{
                                maxHeight: '50vh',
                                overflowY: 'scroll',
                                // backgroundColor: 'gray',
                                borderRadius: '9px'
                              }}>
                              {fields2.map(({ key: key2, name: name2, ...restField2 }) => (
                                <div className="flex gap-1 items-center" key={key2}>
                                  <span className="font-bold text-sm mb-5">{name2 + 1}.</span>
                                  <div className="card">
                                    <div
                                      className={
                                        'grid grid-cols-2 gap-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-5 xl:grid-cols-9'
                                      }
                                      key={key2}>
                                      <Form.Item
                                        {...restField2}
                                        name={[name2, 'productId']}
                                        hidden></Form.Item>
                                      <Form.Item
                                        {...restField2}
                                        name={[name2, 'sellingPrice']}
                                        hidden></Form.Item>
                                      <Form.Item
                                        {...restField2}
                                        name={[name2, 'productName']}
                                        label="Name">
                                        <Input
                                          type={'text'}
                                          disabled
                                          style={{
                                            backgroundColor: 'white',
                                            color: 'black',
                                            // border: '0px',
                                            fontWeight: 'bold'
                                          }}
                                        />
                                      </Form.Item>
                                      <Form.Item
                                        {...restField2}
                                        name={[name2, 'unitId']}
                                        label="Unit"
                                        rules={[
                                          {
                                            required: true,
                                            message: 'Please choose a Unit!'
                                          }
                                        ]}>
                                        <Select
                                          placeholder="Select a Unit!"
                                          onChange={() => onUnitChange(name, name2)}
                                          dropdownMatchSelectWidth={false}
                                          allowClear>
                                          {FilterUnits([name, name2])}
                                        </Select>
                                      </Form.Item>
                                      <Form.Item name={[name2, 'isLot']} valuePropName="checked">
                                        <Checkbox
                                          onChange={(value) => {
                                            if (!value.target.checked) {
                                              form.setFieldValue(
                                                ['purchases', name, 'lines', name2, 'lotId'],
                                                null
                                              );
                                            } else {
                                              form.setFieldValue(
                                                ['purchases', name, 'lines', name2, 'expiry'],
                                                undefined
                                              );
                                            }

                                            form.setFieldValue(
                                              ['purchases', name, 'lines', name2, 'hsCode'],
                                              undefined
                                            );
                                          }}>
                                          Existing Lot?
                                        </Checkbox>
                                      </Form.Item>
                                      {form.getFieldValue([
                                        'purchases',
                                        name,
                                        'lines',
                                        name2,
                                        'isLot'
                                      ]) && (
                                        <Form.Item
                                          {...restField2}
                                          name={[name2, 'lotId']}
                                          label="Lot"
                                          rules={[
                                            {
                                              required: true,
                                              message: 'Please choose a Lot!'
                                            }
                                          ]}>
                                          {
                                            <Select
                                              placeholder="Select a Lot!"
                                              dropdownMatchSelectWidth={false}
                                              onChange={(value) =>
                                                onLotChange(
                                                  [name, name2],
                                                  value,
                                                  form.getFieldValue([
                                                    'purchases',
                                                    name,
                                                    'lines',
                                                    name2,
                                                    'productId'
                                                  ])
                                                )
                                              }
                                              // onChange={onLocationChange}
                                              allowClear>
                                              {FilterLot([name, name2])}
                                            </Select>
                                          }
                                        </Form.Item>
                                      )}
                                      <ReusableQuantity
                                        name={name2}
                                        restField={restField2}
                                        allUnits={allLocalUnits}
                                        unitIdPath={['purchases', name, 'lines', name2, 'unitId']}
                                        onChangeData={() => onUnitandQuantityChange(name, name2)}
                                        onPressEnterData={(e) => {
                                          if (autofocusRef.current) {
                                            autofocusRef.current.focus();
                                          }
                                        }}
                                        rules={[
                                          {
                                            required: true,
                                            message: 'Please add Quantity!'
                                          },
                                          () => ({
                                            validator(_: any, value: any) {
                                              if (!value) {
                                                return Promise.reject(
                                                  `Please input valid Quantity!`
                                                );
                                              }
                                              if (value == 0)
                                                return Promise.reject(
                                                  `Please Input valid Quantity!`
                                                );

                                              return Promise.resolve();
                                            }
                                          })
                                        ]}
                                      />
                                      <Form.Item
                                        {...restField2}
                                        label="Rate"
                                        name={[name2, 'unitPrice']}
                                        rules={[
                                          { required: true, message: 'Please add Rate!' },
                                          () => ({
                                            validator(_: any, value: any) {
                                              if (
                                                value == 0 &&
                                                form.getFieldValue([
                                                  'purchases',
                                                  name,
                                                  'purchaseBuyerType'
                                                ]) == PurchaseBuyerType.SELF
                                              )
                                                return Promise.resolve();

                                              if (!value) {
                                                return Promise.reject(`Please input valid Rate!`);
                                              }

                                              if (value == 0)
                                                return Promise.reject(`Please Input valid Rate!`);

                                              return Promise.resolve();
                                            }
                                          })
                                        ]}>
                                        <InputNumber
                                          controls={false}
                                          min={0}
                                          onChange={() => onUnitandQuantityChange(name, name2)}
                                        />
                                      </Form.Item>
                                      <Form.Item
                                        {...restField2}
                                        label="Deductions"
                                        name={[name2, 'discount']}
                                        rules={[
                                          { required: true, message: 'Please add Deductions!' }
                                        ]}>
                                        <InputNumber
                                          controls={false}
                                          min={0}
                                          onChange={() => onUnitandQuantityChange(name, name2)}
                                        />
                                      </Form.Item>
                                      <Form.Item
                                        {...restField2}
                                        label="Misc"
                                        name={[name2, 'misc']}
                                        rules={[{ required: true, message: 'Please add Misc!' }]}>
                                        <InputNumber
                                          controls={false}
                                          min={0}
                                          onChange={() => onUnitandQuantityChange(name, name2)}
                                        />
                                      </Form.Item>
                                      <Form.Item
                                        name={[name2, 'isTraceable']}
                                        valuePropName="checked">
                                        <Checkbox>Traceable?</Checkbox>
                                      </Form.Item>
                                      <Form.Item
                                        {...restField2}
                                        label="VAT"
                                        name={[name2, 'vat']}
                                        rules={[
                                          () => ({
                                            validator(_, value) {
                                              if (value !== null) {
                                                return Promise.resolve();
                                              } else {
                                                form.setFieldValue(
                                                  ['purchases', name, 'lines', name2, 'vat'],
                                                  0
                                                );
                                                return Promise.resolve();
                                              }
                                            }
                                          })
                                        ]}>
                                        <InputNumber
                                          controls={false}
                                          min={0}
                                          style={{ color: 'black' }}
                                          onChange={() => onVatChange()}
                                        />
                                      </Form.Item>
                                      <Form.Item
                                        {...restField2}
                                        name={[name2, 'total']}
                                        label="Total">
                                        <InputNumber
                                          controls={false}
                                          min={0}
                                          disabled
                                          // className="font-bold"
                                          style={{ color: 'black' }}
                                        />
                                      </Form.Item>
                                      <Form.Item
                                        {...restField2}
                                        label="HS Code"
                                        name={[name2, 'hsCode']}>
                                        <Input
                                          disabled={form.getFieldValue([
                                            'purchases',
                                            name,
                                            'lines',
                                            name2,
                                            'isLot'
                                          ])}
                                        />
                                      </Form.Item>
                                      {/* //  Hide Expiry field if existing lot is unchecked */}
                                      <Form.Item
                                        {...restField2}
                                        label="Expiry"
                                        name={[name2, 'expiry']}>
                                        <DatePicker
                                          disabled={form.getFieldValue([
                                            'purchases',
                                            name,
                                            'lines',
                                            name2,
                                            'isLot'
                                          ])}
                                        />
                                      </Form.Item>

                                      {/* <div className="flex justify-center items-center"> */}
                                      {fields2.length > 0 ? (
                                        // <MinusCircleOutlined
                                        //   className="dynamic-delete-button flex justify-center items-center"
                                        //   onClick={() => remove2(name2)}
                                        // />
                                        <div className="flex items-center justify-start mt-5">
                                          <CustomButton
                                            backgroundColor="white"
                                            text="Remove"
                                            textColor="green"
                                            onClick={() => remove2(name2)}
                                          />
                                        </div>
                                      ) : null}
                                      {/* </div> */}
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </Card>
                            <Form.Item>
                              {/* <Button
                              type="dashed"
                              onClick={() =>
                                add2({
                                  productId: null,
                                  unitId: null,
                                  quantity: 0,
                                  unitPrice: 0,
                                  discount: 0,
                                  misc: 0,
                                  lotId: null
                                })
                              }
                              icon={<PlusOutlined />}>
                              Add Product
                            </Button> */}
                              <Form.ErrorList errors={errors2} />
                            </Form.Item>
                          </>
                        )}
                      </Form.List>
                      <Divider>Next Seller</Divider>
                    </div>
                  );
                })}
                <Form.Item>
                  <Button
                    type="default"
                    onClick={() =>
                      add({
                        vendorId: '',
                        discount: 0,
                        shippingAmount: 0,
                        labourCost: 0,
                        isSelfInvoice: false
                      })
                    }
                    icon={<PlusOutlined />}>
                    Add Seller
                  </Button>

                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </>
            )}
          </Form.List>
          {form.getFieldValue(['purchases', 0, 'lines'])?.length > 0 ? (
            <Card
              style={{ borderRadius: '10px', marginBottom: '10px' }}
              className="grid grid-cols-2">
              <Form.Item name={['grandtotal']} label="Grand Total">
                <InputNumber controls={false} min={0} disabled style={{ color: 'black' }} />
              </Form.Item>
            </Card>
          ) : null}
          <Divider />
          {/* <Form.Item name={['isTraceable']} valuePropName="checked">
            <Checkbox>Traceable?</Checkbox>
          </Form.Item> */}

          <PageHeader
            title="Task"
            style={{
              padding: '8px 0px 8px 10px'
            }}
          />
          <Checkbox
            // defaultChecked
            onChange={(value) => {
              setIsTask(value.target.checked);
            }}
            style={{ color: 'green' }}>
            Create Task?
          </Checkbox>
          <div className="flex justify-end mt-5">
            <Form.Item>
              <DebounceButton type="primary" disabled={!hasRule || isLoading} onClick={form.submit}>
                Submit
              </DebounceButton>
              {/* <CustomSubmitButton text="Submit" /> */}
            </Form.Item>
          </div>
        </Form>
      </AppContent>
    </Spin>
  );
};

export default CreateProcurement;
const breadcrumbItems = [
  {
    label: 'Procurements'
  }
];
