import { PlusOutlined } from '@ant-design/icons';
import { useMutation } from '@tanstack/react-query';
import { Form, Input, Button, PageHeader, message, Upload, Select } from 'antd';
import { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useNavigate } from 'react-router';
import AppContent from '../../../components/Common/Content/Content';
import { API_URL, JWT_TOKEN } from '../../../constants/config';
import { create_category_mutation } from '../../../services/category/mutations';
import { get_category_list } from '../../../services/category/queries';
import { ICategory, ICategoryCreateRequest } from '../../../services/category/types';
import CategoryDB from '../../../store/localstorage/CategoryDB';
import { AppDispatch } from '../../../store/store';
import { getLocalStorage } from '../../../utils/storage.utils';
import { useFilterStore } from '@/store/zustand';
import { ListPage } from '@/constants/list.enum';
const { Option } = Select;

const containerStyle = {
  width: '100%',
  height: '400px',
  marginBottom: '20px'
};

const CreateCategory = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [fileList2, setFileList2] = useState<UploadFile[]>([]);
  const [categoryList, setCategoryList] = useState<ICategory[]>([]);
  const [isloading, setIsloading] = useState<boolean>(false);

  const zustandFilter = useFilterStore();

  const getDataFromLC = async () => {
    const allcategory: ICategory[] = (await CategoryDB.getAllCategory()) as ICategory[];
    if (allcategory.length > 0) {
      setCategoryList(allcategory);
    } else {
      const response = await get_category_list();
      setCategoryList(response.data.results);
      CategoryDB.addCategory(response.data.results);
    }
  };
  const onPreview = async (file: UploadFile) => {
    let src = file.url as string;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj as RcFile);
        reader.onload = () => resolve(reader.result as string);
      });
    }

    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };
  const onChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    const updatedFileId = newFileList[0]?.response?.id;
    if (updatedFileId) {
      form.setFieldValue('imgId', updatedFileId);
    }
    setFileList(newFileList);
  };
  const onChange2: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    const updatedFileId = newFileList[0]?.response?.id;
    if (updatedFileId) {
      form.setFieldValue('bannerId', updatedFileId);
    }
    setFileList2(newFileList);
  };

  const uploadProps: UploadProps = {
    name: 'media',
    multiple: false,
    action: `${API_URL}storage/upload`,
    headers: {
      Authorization: `Bearer ${getLocalStorage(JWT_TOKEN)}`
    },
    maxCount: 1,
    listType: 'picture-card',
    fileList: fileList,
    accept: 'image/*',
    onPreview: onPreview,
    onChange: onChange
  };
  const uploadProps2: UploadProps = {
    name: 'media',
    multiple: false,
    action: `${API_URL}storage/upload`,
    headers: {
      Authorization: `Bearer ${getLocalStorage(JWT_TOKEN)}`
    },
    maxCount: 1,
    listType: 'picture-card',
    fileList: fileList2,
    accept: 'image/*',
    onPreview: onPreview,
    onChange: onChange2
  };

  const uploadButton = (
    <div className="rounded-full">
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const breadcrumbItems = [
    {
      label: 'Category',
      link: '/products/category'
    },
    {
      label: 'Create',
      link: '/products/category/new'
    }
  ];

  const createCategoryMutation = useMutation(create_category_mutation, {
    onSuccess: (data: any) => {
      setIsloading(false);
      message.success('Category added successfully');
      zustandFilter.resetState(ListPage.PRODUCT_CATEGORY);
      navigate('/products/category');
    },
    onError: (e: any) => {
      setIsloading(false);
      message.error(`${e.response.data.message}`, 5);
    }
  });
  const onFinish = (values: ICategoryCreateRequest) => {
    setIsloading(true);
    createCategoryMutation.mutate(values);
  };
  useEffect(() => {
    getDataFromLC();
  }, []);

  return (
    <div>
      <AppContent breadcrumbItems={breadcrumbItems}>
        <Form
          form={form}
          initialValues={{}}
          onFinish={onFinish}
          disabled={isloading}
          layout="vertical"
          validateTrigger={'onChange'}
          autoComplete="off">
          <PageHeader
            title="Category Information"
            style={{
              padding: '8px 0px'
            }}
          />
          <div className={'grid grid-cols-1 gap-3 mb-3 sm:grid-cols-2 gap-5 mb-5'}>
            <div className="flex mb-5 justify-center align-center">
              <div>
                <p>Upload an image</p>
                <Upload className="rounded-full" {...uploadProps}>
                  {fileList.length >= 1 ? null : uploadButton}
                </Upload>
              </div>
            </div>

            <Form.Item
              label="Image"
              name="imgId"
              hidden
              rules={[{ required: false, message: 'Please upload image!' }]}>
              <Input />
            </Form.Item>
            <div className="flex mb-5 justify-center align-center">
              <div>
                <p>Upload an banner</p>
                <Upload className="rounded-full" {...uploadProps2}>
                  {fileList2.length >= 1 ? null : uploadButton}
                </Upload>
              </div>
            </div>
            <Form.Item
              label="Image"
              name="bannerId"
              hidden
              rules={[{ required: false, message: 'Please upload banner!' }]}>
              <Input />
            </Form.Item>
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: 'Please add name!' }]}>
              <Input />
            </Form.Item>

            <Form.Item label="Parent Category" name={'parentCategoryId'}>
              <Select defaultValue={'none'}>
                <Option key={'none'} value={undefined}>
                  None
                </Option>
                {categoryList?.map((category: ICategory) => (
                  <Option key={category.id} value={category.id}>
                    {category.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>

          <Form.Item
            label="Description"
            name="description"
            rules={[{ required: true, message: 'Please add description!' }]}>
            <Input.TextArea />
          </Form.Item>
          <div className="flex justify-end mt-5">
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={isloading}>
                Submit
              </Button>
            </Form.Item>
            <Button
              type="default"
              className="ml-5"
              htmlType="button"
              onClick={() => navigate('/products/category')}>
              Cancel
            </Button>
          </div>
        </Form>
      </AppContent>
    </div>
  );
};

export default CreateCategory;
