import moment from 'moment';
import { useState } from 'react';

// Antd imports
import { Form } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { RangePickerProps } from 'antd/lib/date-picker';

// Custom imports
import {
  convert_nepali_to_english_date,
  convert_string_to_nepali_date_string
} from '@/utils/nepaliDateConverter';
import { CustomDatePresets } from '@/pages/sqlsource/report/utils/datePresets';
import { convertLocalToUTCString } from '@/utils/convertToUTC';

export interface PaginatedReportValues {
  constraints: {
    page: number;
    size: number;
    dateCustom?: moment.Moment[];
    startDate: string;
    endDate: string;
    startDateNepali: string;
    endDateNepali: string;
  };
}
/**
 * This HOC initializes the form with the initial values and provides the functionality to handle pagination and date change
 */
export interface WithReportPaginationProps {
  form: FormInstance;
  initialValues: PaginatedReportValues;
  pagination: { page: number; size: number };
  onChange: RangePickerProps['onChange'];
  onDisabledDate: RangePickerProps['disabledDate'];
  onChangeNepali: (val: string, isStart: boolean) => void;
  onPagination: (searchCallback: (values: any) => void, page: number, size?: number) => void;
  getConstraint: () => Promise<Omit<PaginatedReportValues['constraints'], 'dateCustom'>>;
}

function withReportPagination<T extends WithReportPaginationProps>(
  WrappedComponent: React.ComponentType<T>,
  options?: { page?: number; size?: number }
) {
  const ReportInitializationWrapper: React.FC<Omit<T, keyof WithReportPaginationProps>> = (
    props
  ) => {
    const [form] = Form.useForm();
    const [pagination, setPagination] = useState({
      page: options?.page || 1,
      size: options?.size || 100
    });

    const formInitialValue = {
      constraints: {
        page: pagination.page,
        size: pagination.size,
        dateCustom: [...CustomDatePresets.Today],
        startDate: CustomDatePresets.Today[0].format('YYYY-MM-DD HH:mm'),
        endDate: CustomDatePresets.Today[1].format('YYYY-MM-DD HH:mm'),
        startDateNepali: convert_string_to_nepali_date_string(
          CustomDatePresets.Today[0].format('YYYY-MM-DD HH:mm')
        ),
        endDateNepali: convert_string_to_nepali_date_string(
          CustomDatePresets.Today[0].format('YYYY-MM-DD HH:mm')
        )
      }
    };

    const onChange: RangePickerProps['onChange'] = (dates, dateStrings) => {
      if (dateStrings && dateStrings.length === 2) {
        form.setFieldsValue({
          constraints: {
            ...form.getFieldValue('constraints'),
            startDate: dateStrings[0],
            endDate: dateStrings[1]
          }
        });
      }
    };

    const disabledDate: RangePickerProps['disabledDate'] = (current) => {
      return current && current > moment().endOf('day');
    };

    const onChangeNepali = (val: string, isStart: boolean) => {
      const fieldName = isStart ? 'startDate' : 'endDate';
      form.setFieldsValue({
        constraints: {
          ...form.getFieldValue('constraints'),
          [fieldName]: convert_nepali_to_english_date(val)
        }
      });
    };

    const getConstraint = async () => {
      const values = form.getFieldValue('constraints');
      const constraints = { ...values };

      if ('dateCustom' in constraints) {
        delete constraints?.dateCustom;
      }

      constraints.startDate = convertLocalToUTCString(constraints.startDate);
      constraints.endDate = convertLocalToUTCString(constraints.endDate);
      constraints.page = pagination.page;
      constraints.size = pagination.size;

      return constraints;
    };

    async function onPagination(
      searchCallback: (values: PaginatedReportValues) => void,
      page: number,
      size = 0
    ) {
      await form.validateFields();
      const values = form.getFieldsValue();

      const pages = size !== 0 ? 1 : page;
      const sizes = size !== 0 ? size : pagination.size;

      setPagination((prev) => {
        prev.page = pages;
        if (size !== 0) prev.size = size;

        return prev;
      });

      values.constraints.page = pages;
      values.constraints.size = sizes;

      // return updated values
      searchCallback(values);
    }

    return (
      <WrappedComponent
        {...(props as T)}
        form={form}
        pagination={pagination}
        initialValues={formInitialValue}
        onChange={onChange}
        onPagination={onPagination}
        getConstraint={getConstraint}
        onDisabledDate={disabledDate}
        onChangeNepali={onChangeNepali}
      />
    );
  };

  return ReportInitializationWrapper;
}

export default withReportPagination;
