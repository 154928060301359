import LocalStore, { getTransactionError } from '.';
import { IWooCommerceProduct } from '@/services/woocommerce/types';
import { IndexNames, StoreNames } from './storeList.enum';
import { get_woo_products_ids } from '@/services/woocommerce/queries';

const DB_NAME = StoreNames.WooCommerceProducts;
const DB_SECONDARY_INDEX = IndexNames.WooCommerceProductsSecondIndex;

export class WooCommerceProductsDB extends LocalStore {
  static async add(data: IWooCommerceProduct[]) {
    if (!WooCommerceProductsDB.db) await WooCommerceProductsDB.init();

    return new Promise((resolve, reject) => {
      const transaction = WooCommerceProductsDB.db.transaction(DB_NAME, 'readwrite');
      transaction.oncomplete = () => resolve('success');
      transaction.onerror = (event) => getTransactionError(event, reject, 'add');

      const objectStore = transaction.objectStore(DB_NAME);

      data.forEach(async (wooCommerceProduct) => {
        const checkKey = objectStore.count(wooCommerceProduct.wooProductId);
        checkKey.onerror = (event) => getTransactionError(event, reject, 'add');

        checkKey.onsuccess = async () => {
          const data = {
            ...wooCommerceProduct,
            lowercaseName: wooCommerceProduct.name.trim().toLowerCase()
          };

          if (checkKey.result == 0) {
            const request = objectStore.add(data);
            request.onerror = (event) => getTransactionError(event, reject, 'add');
          } else {
            await WooCommerceProductsDB.update(data);
          }
        };
      });
    });
  }

  static async search(name: string, limit = 10): Promise<IWooCommerceProduct[]> {
    if (!WooCommerceProductsDB.db) await WooCommerceProductsDB.init();
    const givenName = name.trim().toLowerCase();

    return new Promise((resolve, reject) => {
      const transaction = WooCommerceProductsDB.db.transaction(DB_NAME, 'readonly');
      transaction.onerror = (event) => getTransactionError(event, reject, 'search');

      const objectStore = transaction.objectStore(DB_NAME);
      const index = objectStore.index(DB_SECONDARY_INDEX);
      const request = index.openCursor();

      const wooCommerceProducts: IWooCommerceProduct[] = [];
      let count = 0;

      request.onsuccess = (event) => {
        const cursor = (event.target as IDBRequest).result;
        if (cursor && count < limit) {
          const wooCommerceProduct = cursor?.value as IWooCommerceProduct;
          if (wooCommerceProduct?.name?.trim()?.toLowerCase()?.includes(givenName)) {
            wooCommerceProducts.push(wooCommerceProduct);
            count++;
          }

          cursor.continue();
        } else {
          resolve(wooCommerceProducts);
        }
      };
    });
  }

  static async getAll(): Promise<IWooCommerceProduct[]> {
    if (!WooCommerceProductsDB.db) await WooCommerceProductsDB.init();
    return new Promise((resolve, reject) => {
      const transaction = WooCommerceProductsDB.db.transaction(DB_NAME, 'readonly');
      transaction.onerror = (event) => getTransactionError(event, reject, 'getwooCommerceProducts');
      const objectStore = transaction.objectStore(DB_NAME);
      const request = objectStore.getAll();
      request.onsuccess = (event) => {
        resolve((event.target as IDBRequest).result);
      };
    });
  }

  static async getByID(id: number): Promise<IWooCommerceProduct> {
    if (!WooCommerceProductsDB.db) await WooCommerceProductsDB.init();
    return new Promise((resolve, reject) => {
      const transaction = WooCommerceProductsDB.db.transaction(DB_NAME, 'readonly');
      transaction.onerror = (event) => getTransactionError(event, reject, 'getwooCommerceProducts');
      const objectStore = transaction.objectStore(DB_NAME);
      const request = objectStore.get(id);

      request.onsuccess = (event) => {
        resolve((event.target as IDBRequest).result);
      };
      request.onerror = (event) => getTransactionError(event, reject, 'get');
    });
  }

  static async update(wooCommerceProduct: IWooCommerceProduct) {
    if (!WooCommerceProductsDB.db) await WooCommerceProductsDB.init();
    return new Promise((resolve, reject) => {
      const transaction = WooCommerceProductsDB.db.transaction(DB_NAME, 'readwrite');
      transaction.oncomplete = () => resolve('success');
      transaction.onerror = (event) => getTransactionError(event, reject, 'update');

      const objectStore = transaction.objectStore(DB_NAME);
      const request = objectStore.get(wooCommerceProduct.wooProductId);

      request.onerror = (event) => getTransactionError(event, reject, 'update');
      request.onsuccess = (event) => {
        if ((event.target as IDBRequest).result) {
          const requestUpdate = objectStore.put(wooCommerceProduct);
          requestUpdate.onerror = (event) => getTransactionError(event, reject, 'update');
          requestUpdate.onsuccess = () => resolve('updated data');
        } else reject('Error, could not find id.');
      };
    });
  }

  static async addIfAbsent(ids: number[]): Promise<void> {
    if (!WooCommerceProductsDB.db) await WooCommerceProductsDB.init();
    return new Promise((resolve, reject) => {
      const transaction = WooCommerceProductsDB.db.transaction(DB_NAME, 'readwrite');
      transaction.onerror = (event) => getTransactionError(event, reject, 'add');

      const objectStore = transaction.objectStore(DB_NAME);
      const request = objectStore.getAll();

      request.onsuccess = async (event) => {
        const wooCommerceProducts: IWooCommerceProduct[] = (event.target as IDBRequest).result;
        const wooCommerceProductsIds = wooCommerceProducts.map(
          (wooCommerceProduct) => wooCommerceProduct.wooProductId
        );

        const idsToAdd = ids.filter((id) => {
          return Boolean(id) && !wooCommerceProductsIds.includes(id);
        });

        if (idsToAdd.length) {
          const response = await get_woo_products_ids(idsToAdd);
          await WooCommerceProductsDB.add(response.results);
          resolve(undefined);
        } else resolve(undefined);
      };
    });
  }
}
