import { useState } from 'react';
import {
  Descriptions,
  FormInstance,
  Menu,
  message,
  Modal,
  PageHeader,
  TableProps,
  Tag
} from 'antd';
import { ColumnsType } from 'antd/es/table';
import { SorterResult } from 'antd/lib/table/interface';

import { IPurchaseAgentBillRange } from '@/services/purchases/types';
import CustomizeTable from '@/components/Common/CustomizeTable/CustomizeTable';
import { convertUTCStringtoLocalString } from '@/utils/convertToUTC';
import { DEFAULT_DATE_FORMAT } from '@/constants';
import ActionDropdown from '@/components/Common/Dropdownactions';
import { Link } from 'react-router-dom';

interface Props {
  isLoading: boolean;
  form: FormInstance;
  data: { total: number; results: IPurchaseAgentBillRange[] };
  pagination: { page: number; size: number };
  onPagination: (page?: number, size?: number, isSize?: boolean) => Promise<unknown>;
  handleArchive: (id: number, status: boolean) => Promise<void>;
}

function Table({ data, pagination, isLoading, onPagination, handleArchive, form }: Props) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [sortedInfo, setSortedInfo] = useState<SorterResult<IPurchaseAgentBillRange>>({});

  const [selectedArchive, setSelectedArchive] = useState<IPurchaseAgentBillRange | null>(null);

  function handleArchiveClick(bill: IPurchaseAgentBillRange) {
    setIsModalVisible(true);
    setSelectedArchive(bill);
  }

  async function handleOk() {
    if (!selectedArchive) {
      return message.error('Please select the bill');
    }

    setIsModalVisible(false);
    await handleArchive(selectedArchive.id, selectedArchive.isArchived);
    setSelectedArchive(null);
  }

  const handleChange: TableProps<IPurchaseAgentBillRange>['onChange'] = (_, __, s) => {
    setSortedInfo(s as SorterResult<IPurchaseAgentBillRange>);
  };

  const columns: ColumnsType<IPurchaseAgentBillRange> = [
    {
      title: 'S.N.',
      key: 'id',
      width: 3,
      render: (_, __, index) => (pagination.page - 1) * pagination.size + index + 1
    },
    {
      title: 'Agent',
      dataIndex: 'agent',
      key: 'agent',
      width: 10,
      sortOrder: sortedInfo.columnKey === 'agent' ? sortedInfo.order : null,
      sorter: (a, b) => a.agent.localeCompare(b.agent)
    },
    // {
    //   title: 'Invoice Number',
    //   dataIndex: 'invoiceNumber',
    //   key: 'invoiceNumber',
    //   width: 10,
    //   sortOrder: sortedInfo.columnKey === 'invoiceNumber' ? sortedInfo.order : null,
    //   sorter: (a, b) => a.invoiceNumber.localeCompare(b.invoiceNumber)
    // },
    {
      title: 'Start',
      dataIndex: 'min',
      key: 'min',
      width: 5,
      sortOrder: sortedInfo.columnKey === 'min' ? sortedInfo.order : null,
      sorter: (a, b) => a.min - b.min
    },
    {
      title: 'End',
      dataIndex: 'max',
      key: 'max',
      width: 5,
      sortOrder: sortedInfo.columnKey === 'max' ? sortedInfo.order : null,
      sorter: (a, b) => a.max - b.max
    },
    {
      title: 'Current',
      dataIndex: 'current',
      key: 'current',
      width: 5,
      sortOrder: sortedInfo.columnKey === 'current' ? sortedInfo.order : null,
      sorter: (a, b) => a.current - b.current
    },

    {
      title: 'Created By',
      dataIndex: 'createdUser',
      key: 'createdUser',
      width: 7,
      sortOrder: sortedInfo.columnKey === 'createdUser' ? sortedInfo.order : null,
      sorter: (a, b) => a.createdUser.localeCompare(b.createdUser)
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 7,
      render: (text: string) => convertUTCStringtoLocalString(text, DEFAULT_DATE_FORMAT),
      sortOrder: sortedInfo.columnKey === 'createdAt' ? sortedInfo.order : null,
      sorter: (a, b) => a.createdAt.localeCompare(b.createdAt)
    },
    {
      title: 'Updated At',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      width: 7,
      render: (text: string) => convertUTCStringtoLocalString(text, DEFAULT_DATE_FORMAT),
      sortOrder: sortedInfo.columnKey === 'updatedAt' ? sortedInfo.order : null,
      sorter: (a, b) => a.updatedAt.localeCompare(b.updatedAt)
    },
    {
      title: 'Status',
      dataIndex: 'isArchived',
      width: 5,
      key: 'isArchived',
      sortOrder: sortedInfo.columnKey === 'isArchived' ? sortedInfo.order : null,
      sorter: (a, b) => (a.isArchived ? 1 : 0) - (b.isArchived ? 1 : 0),
      render: (text: boolean) => (
        <Tag color={text ? 'red' : 'green'}>{text ? 'Archived' : 'Active'}</Tag>
      )
    },
    {
      title: 'Action',
      width: 3,
      key: 'action',
      fixed: 'right',
      render: (record: IPurchaseAgentBillRange) => {
        const menuItems = [
          {
            key: 'view-agent-bill',
            label: <Link to={`/purchases/agents-bill-range/view/${record.id}`}>View</Link>
          },
          {
            key: 'update-agent-bill',
            label: <div>{record.isArchived ? 'Unarchive' : 'Archive'}</div>,
            onClick: () => handleArchiveClick(record)
          },
          {
            key: 'cancel-some-bill',
            label: <Link to={`/purchases/agents-bill-range/cancel/${record.id}`}>Cancel Bill</Link>
          }
        ];

        const menu = <Menu items={menuItems} />;
        return <ActionDropdown menu={menu} />;
      }
    }
  ];

  return (
    <>
      <Modal
        title="Bill Archive"
        width={600}
        visible={isModalVisible && selectedArchive !== null}
        onOk={handleOk}
        onCancel={() => setIsModalVisible(false)}>
        <p>
          Are you sure you want to {selectedArchive?.isArchived ? 'unarchive' : 'archive'} this
          bill?
        </p>
        <PageHeader subTitle="Agent Bill Information" style={{ padding: '8px 0' }} />
        <Descriptions
          bordered
          column={{
            md: 2,
            sm: 1,
            xs: 1
          }}>
          <Descriptions.Item label="Range">
            {selectedArchive?.min} to {selectedArchive?.max}
          </Descriptions.Item>
          <Descriptions.Item label="Current">{selectedArchive?.current}</Descriptions.Item>
          <Descriptions.Item label="Agent">{selectedArchive?.agent}</Descriptions.Item>
        </Descriptions>
      </Modal>
      <CustomizeTable
        form={form}
        columns={columns}
        data={data.results}
        usersLoading={isLoading}
        tableName="agent-bill-range-list"
        toSort={handleChange}
        notshowPagination={true}
        customScroll={{ x: 1600, y: '75vh' }}
        paginationDatas={{
          page: pagination.page,
          total: data.total,
          size: pagination.size,
          onPagination
        }}
      />
    </>
  );
}

export default Table;
