import moment from 'moment';
import { Button, Collapse, Form, FormInstance, message, PageHeader, Spin } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import React, { useEffect, useState } from 'react';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';

import AppContent from '@/components/Common/Content/Content';

import { download_report, get_report } from '@/services/report/queries';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';
import { convertLocalToUTCString } from '@/utils/convertToUTC';
import { getInt } from '../pending-payment-sell-report/report';
import { check_date_cap, check_export_report_date_cap } from '@/utils/common';
import { showReportConditionalDownloadMessage } from '@/utils/reportConditionalDownloadMessage';
import CustomizeTable from '@/components/Common/CustomizeTable/CustomizeTable';
import ExportAllData from '@/components/Common/ExportAll';
import ExportCurrent from '@/components/Common/ExportCurrent';
import { excelExportColumns, tableExportColumns } from './column.export';
import GenericTable from '@/components/Common/CustomizeTable';
import { DEFAULT_DATE_FORMAT } from '@/constants';

interface ReportData {
  count: any;
  data: any;
}

interface State {
  id: number;
  name: string;
}
interface ReportProps {
  state: State;
  breadcrumbItems: any[];
  form: FormInstance<any>;
  columnsData?: any[];
  footerData?: { sell?: boolean; purchase?: boolean };
  toDownload?: boolean;
  toSort: any;
}

const CustomReport: React.FC<ReportProps> = ({
  state,
  breadcrumbItems,
  form,
  columnsData,
  footerData = {
    sell: false,
    purchase: false
  },
  children,
  toDownload,
  toSort
}) => {
  const [columns, setColumns] = useState<any[]>([]);
  const [data, setData] = useState<ReportData>({ count: 0, data: [] });
  const [footer, setFooterData] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [size, setSize] = useState(100);
  const [isloading, setIsloading] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);

  const [fullExportURL, setFullExportURL] = useState<any>(null);

  useEffect(() => {
    if (!columnsData) createColumns();
  }, [data]);
  const createColumns = () => {
    //auto generate columns
    const newColumns: ColumnsType<any> = [];
    for (const key in data.data[0]) {
      newColumns.push({
        title: key,
        key,
        render: (record: any) => {
          return <div className="forless">{record[key]}</div>;
        }
      });
    }
    setColumns(newColumns);
  };
  const onSearch = async (values: any, storePage = false) => {
    // console.log('values', values);
    setIsloading(true);
    if (check_date_cap(values.constraints.startDate, values.constraints.endDate)) {
      await downloadPdf();
      setIsloading(false);
      return;
    }
    if (!storePage) {
      values.constraints['page'] = 1;
      values.constraints['size'] = size;
      setPage(1);
    }

    //remove custom date
    if ('dateCustom' in values.constraints) {
      delete values.constraints.dateCustom;
      delete values.startDateNepali;
      delete values.endDateNepali;
    }

    if ('createdDateRange' in values.constraints) {
      delete values.constraints.createdDateRange;
      delete values.sellStartDateNepali;
      delete values.sellEndDateNepali;
    }

    values.constraints.sellStartDate = convertLocalToUTCString(values.constraints.sellStartDate);
    values.constraints.sellEndDate = convertLocalToUTCString(values.constraints.sellEndDate);

    values.constraints.endDate = convertLocalToUTCString(values.constraints.endDate);
    values.constraints.startDate = convertLocalToUTCString(values.constraints.startDate);
    const response = await get_report(values.constraints, state.id);
    response.data = await calculateTotal(response.data);
    setData(response.data);

    const payload = values.constraints as any;
    payload.size = response.data.count;
    setFullExportURL(payload);

    if (response.data.data.length === 0) {
      setIsEmpty(true);
    } else {
      setIsEmpty(false);
    }
    setIsloading(false);
  };
  const calculateTotal = async (data: any) => {
    let total = 0;
    for (let index = 0; index < data.data.length; index++) {
      total += getInt(data.data[index].amount);
    }
    setFooterData(total);
    return data;
  };
  const addPage = async (value: number, sizeVal = 0) => {
    try {
      await form.validateFields();
      const allValues = form.getFieldsValue();
      if (sizeVal !== 0) {
        allValues.constraints.page = 1;
        allValues.constraints.size = sizeVal;
        setPage(1);
        setSize(sizeVal);
      } else {
        allValues.constraints.page = value;
        allValues.constraints.size = size;
        setPage(value);
      }
      onSearch(allValues, true);
    } catch (e: any) {
      console.log('validate error', e);
    }
  };

  const downloadPdf = async () => {
    if (isEmpty) {
      showReportConditionalDownloadMessage();
      return;
    }
    setIsloading(true);
    const values = form.getFieldsValue();
    await form.validateFields();
    values.constraints.endDate = convertLocalToUTCString(values.constraints.endDate);
    values.constraints.startDate = convertLocalToUTCString(values.constraints.startDate);

    values.constraints.sellStartDate = convertLocalToUTCString(values.constraints.sellStartDate);
    values.constraints.sellEndDate = convertLocalToUTCString(values.constraints.sellEndDate);

    if ('dateCustom' in values.constraints) {
      delete values.constraints.dateCustom;
      delete values.sellStartDateNepali;
      delete values.sellEndDateNepali;
    }

    if ('createdDateRange' in values.constraints) {
      delete values.constraints.createdDateRange;
    }

    try {
      if (check_export_report_date_cap(values.constraints.startDate, values.constraints.endDate)) {
        // const response =
        values.constraints.customReportQueueName = 'Sell Payment Report';
        message.info({
          key: 'downloading',
          content: `${values.constraints.customReportQueueName} Added to Queue. Download will start soon.`
        });
        await download_report(values.constraints, state.id);
        setIsloading(false);
        // window.open(response.data, '_blank');
      }
    } catch (err: any) {
      setIsloading(false);
      message.error(err.message);
    }
  };

  const updatedData = data.data.map((record: any) => {
    return {
      ...record,
      paidOn: moment(record.paidOn).format(DEFAULT_DATE_FORMAT),
      sellCreatedAt: moment(record.sellCreatedAt).format(DEFAULT_DATE_FORMAT),
      amount: nepaliNumberFormatter(record.amount)
    };
  });

  async function onSearchAll() {
    try {
      setIsloading(true);
      if (!fullExportURL) {
        message.error('No URL specified. Please search first.');
        return;
      }
      const response = await get_report(fullExportURL, state.id);
      response.data = await calculateTotal(response.data);
      const updatedData = response.data.data.map((record: any) => {
        return {
          ...record,
          paidOn: moment(record.paidOn).format(DEFAULT_DATE_FORMAT),
          sellCreatedAt: moment(record.sellCreatedAt).format(DEFAULT_DATE_FORMAT),
          amount: nepaliNumberFormatter(record.amount)
        };
      });

      return updatedData;
    } catch (error) {
      console.log(error);
    } finally {
      setIsloading(false);
    }
  }

  return (
    <div>
      <Spin spinning={isloading}>
        <AppContent breadcrumbItems={breadcrumbItems}>
          <Collapse>
            <CollapsePanel header="Filters" key="1">
              <Form
                form={form}
                onFinish={onSearch}
                layout="vertical"
                validateTrigger={'onChange'}
                autoComplete="off"
                disabled={isloading}>
                {React.Children.count(children) > 1 && (
                  <>
                    <PageHeader
                      subTitle="Constraints"
                      style={{
                        padding: '0px 0px',
                        margin: '0px'
                      }}
                    />
                  </>
                )}
                <div className="grid md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 gap-3">
                  {children}
                  <div className="flex justify-start mt-7 gap-1">
                    <Form.Item>
                      <Button type="primary" htmlType="submit" style={{ borderRadius: '6px' }}>
                        Search
                      </Button>
                    </Form.Item>
                    {toDownload ? (
                      <div>
                        <Form.Item>
                          <Button
                            className="secondary-button"
                            style={{ borderRadius: '6px' }}
                            onClick={downloadPdf}>
                            Download
                          </Button>
                        </Form.Item>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </Form>
            </CollapsePanel>
          </Collapse>
          <PageHeader
            subTitle="Table"
            style={{
              padding: '8px 0px'
            }}
          />

          <GenericTable
            columns={columnsData ? columnsData : columns}
            tableName="sell-payment-list-report"
            data={data.data}
            hideDefaultPagination={true}
            generateSummary
            toSort={toSort}
            footer={() => `Total Amount: ${nepaliNumberFormatter(footer)}`}
            scroll={{ x: 1700, y: '75vh' }}
            buttons={
              <>
                <ExportAllData
                  title="Payment Sell (All)"
                  columns={tableExportColumns}
                  excelColumns={excelExportColumns}
                  getInfo={onSearchAll}
                />
                <ExportCurrent
                  data={{ total: data.count, results: updatedData }}
                  columns={tableExportColumns}
                  excelColumns={excelExportColumns}
                  title="Payment Sell"
                />
              </>
            }
            pagination={{
              page,
              total: data.count,
              size,
              onPagination: (pageNo, currentSize) => {
                currentSize == size ? addPage(pageNo) : addPage(pageNo, currentSize);
              }
            }}
          />
        </AppContent>
      </Spin>
    </div>
  );
};

export default CustomReport;
