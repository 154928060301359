import {
  Spin,
  PageHeader,
  Input,
  Select,
  InputNumber,
  Button,
  Form,
  message,
  Checkbox
} from 'antd';
import AppContent from '@/components/Common/Content/Content';
import { MenuTypes } from '@/services/settings/enums';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { IMenuList, IUpdateMenuItem } from '@/services/settings/types';
import { useMutation, useQuery } from '@tanstack/react-query';
import { get_menu_item_details } from '@/services/settings/queries';
import { AxiosError, AxiosResponse } from 'axios';
import { IServerError } from '@/services/response/types';
import { update_menu_item } from '@/services/settings/mutations';
import MenuDB from '@/store/localstorage/MenuDB';
import { MenuTypeSearch } from '@/components/Common/MenuTypeSearch';
import { cleanHTML } from '@/components/TextEditor/textEditor.services';
import TextEditor from '@/components/TextEditor';
import { useFilterStore } from '@/store/zustand';
import { ListPage } from '@/constants/list.enum';

export const AdminEditMenu = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { id } = useParams();
  const [infoContent, setInfoContent] = useState('');

  const zustandFilter = useFilterStore();

  const breadcrumbItems = [
    { label: 'Dev Menu', link: '/admin/menu' },
    { label: 'Details', link: '/admin/menu/:id' }
  ];

  useQuery(['menuDetails', id], async () => await getData());

  const getData = async () => {
    if (id) {
      const response = await get_menu_item_details(parseInt(id));
      if (response) {
        form.setFieldsValue(response);
        if (response.default) form.setFieldValue('isDefault', true);
        setInfoContent(response.content);
        return response;
      }
    }
  };

  const onFinish = async (values: IUpdateMenuItem) => {
    setIsLoading(true);
    values.content = cleanHTML(infoContent);
    if (id) values.id = parseInt(id);
    if (form.getFieldValue('isDefault')) {
      values.default = {
        position: values.position,
        parentId: values.parentId
      };
    }
    await updateMenuItemMutation.mutateAsync(values);
  };

  const updateMenuItemMutation = useMutation<
    AxiosResponse<IMenuList>,
    AxiosError<IServerError>,
    IUpdateMenuItem
  >(update_menu_item, {
    onSuccess: async ({ data }) => {
      setIsLoading(false);
      zustandFilter.removeData(ListPage.MENU_LIST);
      zustandFilter.removeData(ListPage.DEV_MENU);
      const menuItem = await MenuDB.getMenuItem(data.id);
      if (!menuItem) {
        await MenuDB.addMenuItem([data]);
      } else {
        await MenuDB.updateMenuItem(data);
      }
      message.success('Menu item updated successfully');
      navigate('/admin/menu');
    },
    onError: (e) => {
      setIsLoading(false);
      message.error(`${e.response?.data.message}`);
    }
  });

  return (
    <Spin spinning={isLoading}>
      <AppContent breadcrumbItems={breadcrumbItems} withfilter={false}>
        <Form
          form={form}
          onFinish={onFinish}
          initialValues={{ isDefault: false }}
          disabled={isLoading}
          layout="vertical"
          validateTrigger={'onChange'}
          autoComplete="off">
          <PageHeader
            title="Menu Information"
            style={{
              padding: '8px 0px'
            }}
          />
          <div className={'grid grid-cols-1 sm:grid-cols-4 gap-5 mb-5'}>
            <Form.Item
              label="Menu Name"
              name="name"
              rules={[{ required: true, message: 'Please add Menu Name!' }]}>
              <Input />
            </Form.Item>
            <Form.Item
              label="Menu Key"
              name="key"
              rules={[{ required: true, message: 'Please add Menu Key!' }]}>
              <Input />
            </Form.Item>
            <Form.Item
              label="Menu Type"
              name="type"
              rules={[{ required: true, message: 'Please add Menu Type!' }]}>
              <Select placeholder="Select menu type">
                {(Object.keys(MenuTypes) as Array<keyof typeof MenuTypes>).map((key) => (
                  <Select.Option key={key} value={key}>
                    {MenuTypes[key]}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Item Position"
              name="position"
              rules={[{ required: true, message: 'Please add Item Keyword!' }]}>
              <InputNumber controls={false} min={0} />
            </Form.Item>
            <MenuTypeSearch
              formData={{ formName: 'parentId', label: 'Parent Item' }}
              isAll={false}
            />
            <Form.Item
              label="Search Keyword"
              name="keyword"
              rules={[{ required: true, message: 'Please add Item Keyword!' }]}>
              <Input />
            </Form.Item>
            <Form.Item name="isDefault" label="Is Default?" valuePropName="checked">
              <Checkbox />
            </Form.Item>
          </div>

          <div>
            <PageHeader title="Menu Content" style={{ padding: '8px 0px', fontSize: 16 }} />
            <TextEditor getHtml={(html) => setInfoContent(html)} defaultValue={infoContent} />
          </div>

          <div className="flex justify-end mt-5">
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={isLoading}>
                Submit
              </Button>
            </Form.Item>
            <Button
              type="default"
              className="ml-5"
              htmlType="button"
              onClick={() => navigate('/admin/menu')}>
              Cancel
            </Button>
          </div>
        </Form>
      </AppContent>
    </Spin>
  );
};
