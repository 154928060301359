import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { Socket } from 'socket.io-client';
import { configSocket } from '../utils/websocket.utils';
import {
  SocketEvents,
  SocketNameSpaces,
  SystemNotificationType
} from '../constants/websocketConfig';
import { ISystemNotification } from '../services/users/types';
import {
  ws_new_customer,
  ws_new_product,
  ws_new_unit,
  ws_new_user,
  ws_new_vendor,
  ws_update_customer,
  ws_update_product,
  ws_update_unit,
  ws_update_user,
  ws_update_vendor
} from '../services/web-sockets/queries';
import { useLocation } from 'react-router-dom';
import { isAuthenticated } from '@/utils/auth.utils';

interface IWSContext {
  socket: Socket;
}

const WebSocketContext = createContext<Partial<IWSContext>>({});
const WebSocketProvider = ({ children }: { children: React.ReactNode }) => {
  const [socket, setSocket] = useState<Socket>();
  const [isLoggedIn, setIsLoggedIn] = useState(isAuthenticated);
  const location = useLocation();
  const socketRef = useRef<Socket>();

  useEffect(() => {
    const isLoggedIn = isAuthenticated();
    setIsLoggedIn(isLoggedIn);
  }, [location.pathname]);

  const initializeSocket = async () => {
    const newSocket = await configSocket(SocketNameSpaces.NOTIFICATION);
    setSocket(newSocket);
    socketRef.current = newSocket;

    newSocket?.on(SocketEvents.SYSTEM_NOTIFICATION, async (data: ISystemNotification) => {
      // console.log('Args', data);
      switch (data.type) {
        case SystemNotificationType.NEW_CUSTOMER:
          await ws_new_customer(data);
          break;
        case SystemNotificationType.UPDATE_CUSTOMER:
          await ws_update_customer(data);
          break;
        case SystemNotificationType.NEW_VENDOR:
          await ws_new_vendor(data);
          break;
        case SystemNotificationType.UPDATE_VENDOR:
          await ws_update_vendor(data);
          break;
        case SystemNotificationType.NEW_PRODUCT:
          await ws_new_product(data);
          break;
        case SystemNotificationType.UPDATE_PRODUCT:
          await ws_update_product(data);
          break;
        case SystemNotificationType.NEW_USER:
          await ws_new_user(data);
          break;
        case SystemNotificationType.UPDATE_USER:
          await ws_update_user(data);
          break;
        case SystemNotificationType.NEW_UNIT:
          await ws_new_unit(data);
          break;
        case SystemNotificationType.UPDATE_UNIT:
          await ws_update_unit(data);
          break;
      }
    });

    newSocket?.on('connect', () => {
      console.log('WebSocket Connected!');
    });

    newSocket?.on('disconnect', () => {
      console.log('WebSocket Disconnected!');
      setSocket(undefined); // Ensure reconnection happens on next effect
      socketRef.current = undefined;
    });
  };

  useEffect(() => {
    if (!isLoggedIn) {
      socket?.disconnect();
      return;
    }

    if (!socket || !socket.connected) {
      initializeSocket();
    }

    return () => {
      socket?.off(SocketEvents.SYSTEM_NOTIFICATION);
    };
  }, [isLoggedIn]);

  return <WebSocketContext.Provider value={{ socket }}>{children}</WebSocketContext.Provider>;
};

export function useWebSocket() {
  const context = useContext(WebSocketContext);
  if (!context) {
    throw new Error('useWebSocket must be used within a WebSocketProvider');
  }
  return context;
}

export { WebSocketContext, WebSocketProvider };
