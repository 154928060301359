import ExcelJS from 'exceljs';
import getCharFromNumber from './getCharFromNumber';
import { fillWorkSheet } from './exportExcelAutoWidth';
import { exportExcelBuffer } from './exportNestedExcel';

const borderStyle: Partial<ExcelJS.Borders> = {
  top: { style: 'thin', color: { argb: 'FF000000' } },
  left: { style: 'thin', color: { argb: 'FF000000' } },
  bottom: { style: 'thin', color: { argb: 'FF000000' } },
  right: { style: 'thin', color: { argb: 'FF000000' } }
};

export interface ExportColumnType {
  label: string;
  dataIndex: string;
}

export interface IExportHeader {
  value?: string;
  size?: ExcelJS.Font['size'];
  bold?: boolean;
  alignment?: Partial<ExcelJS.Alignment>;
  font?: ExcelJS.Font['name'];
  tableBorder?: boolean;
}

interface IExportOptions {
  columns: ExportColumnType[];
  rows: Record<string, unknown>[];
  fileName: string;
  sheetName?: string;
  header?: IExportHeader[];
}

export async function exportExcelWithHeader({
  columns,
  rows,
  header,
  fileName,
  sheetName = 'Sheet1'
}: IExportOptions) {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet(sheetName);

  const lastColumnChar = getCharFromNumber(Math.max(columns.length - 1, 0));

  header &&
    header.forEach((style, idx) => {
      const row = idx + 1;
      const cells = `A${row}:${lastColumnChar}${row}`;

      worksheet.mergeCells(cells);
      const cell = worksheet.getCell(`A${row}`);

      const currentRow = worksheet.getRow(row);
      currentRow.height = 30;

      cell.value = style.value;
      cell.alignment = style.alignment || { horizontal: 'center', vertical: 'middle' };
      cell.font = { size: style.size, bold: style.bold, name: style.font || 'Calibri' };
      cell.border = borderStyle;
    });

  const excelColumns: Partial<ExcelJS.Column>[] = columns.map((column) => ({
    key: column.dataIndex,
    width: column.label.length
  }));

  worksheet.columns = excelColumns;

  const colRowCell = worksheet.addRow(columns.map(() => ''));
  columns.forEach((col, colIndex) => {
    const cell = colRowCell.getCell(colIndex + 1);
    cell.value = col.label.toUpperCase();
    cell.font = { bold: true };
    cell.border = borderStyle;
    cell.alignment = { vertical: 'middle', horizontal: 'center' };
  });

  fillWorkSheet(rows, columns, worksheet);
  const buffer = await workbook.xlsx.writeBuffer();
  exportExcelBuffer(buffer, fileName);
}
