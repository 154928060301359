export enum ListPage {
  SELL = '/sell',
  SELL_ORDER = '/sell/order',
  SELL_RETURN = '/sell/return',

  PURCHASE = '/purchase',
  PURCHASE_ORDER = '/purchase/order',
  PURCHASE_RETURN = '/purchase/return',
  AGENT_BILL_RANGE = '/purchase/agent-bill-range',
  AGENT_BILL_CANCEL = '/purchase/agent-bill-cancel',

  ADJUSTMENT = '/adjustments',
  ADJUSTMENT_REASONS = '/adjustments/reasons',
  ADJUSTMENT_LINES = '/adjustments/lines',

  TRANSFER = '/transfers',
  TRANSFER_ORDER = '/transfer/order',
  TRANSFER_IN = '/transfer-in',

  VEHICLE = '/vehicle',
  NOTIFICATION = '/notification',
  ACL = '/acl',

  USERS = '/users',
  CUSTOMERS = '/users/customers',
  SUPPLIERS = '/users/vendors',
  USER_TYPE = '/users/user-type',
  VENDOR_TYPE = '/vendor-type',

  AGENTS = '/users/agents',
  CUSTOMER_GROUP = '/offer/customer-group',

  HR = '/hr',
  ATTENDANCE = '/hr/attendance',
  SELF_ATTENDANCE = '/hr/attendance/self',
  HR_GROUPS = '/hr/groups',
  HR_SHIFT = '/hr/shift',
  HR_HOLIDAY = '/hr/holiday',
  HR_LEAVE = '/hr/leaves',
  HR_PAYROLL = '/hr/payroll',

  SCHEDULER = '/scheduler',
  LOCATION = '/locations',
  BANK = '/bank',
  SESSION = '/session',
  SESSION_MONEY = '/session/money',

  ACCOUNTS = '/accounts',
  FINANCIAL_YEAR = '/accounts/financial-year',
  ACCOUNTS_RULES = '/accounts/rule',
  ACCOUNTS_OPENING = '/accounts/opening',

  PRODUCTS = '/products',
  ARCHIVE_PRODUCTS = '/products/archive',
  PRODUCT_CATEGORY = '/products/category',
  CLEAR_STOCKS = '/products/clear-stocks',
  PRICE_ANALYSIS = '/price-analysis',

  CUSTOMER_PRICE_GROUP = '/price-groups/customers',
  PRICE_GROUP = '/price-groups',
  VENDOR_PRODUCTS = '/vendor-products',

  CUSTOMER_DISCOUNT = '/offer/customer-discount',
  CUSTOMER_GROUP_DISCOUNT = '/offer/discount',
  PRODUCT_DISCOUNT = '/offer/discount-product',
  PRODUCT_CATEGORY_DISCOUNT = '/offer/discount-category',

  EXPENSE = '/expense',
  EXPENSE_CATEGORY = '/expense-category',
  LOGS = '/logs',
  TASK = '/tasks',
  ROUTE = '/routes',
  FONEPAY = '/fonepay',
  DAILY_RATE = '/daily-rate',

  MENU_LIST = '/settings/menu-list',
  DEV_MENU = '/admin/menu',
  ADMIN_MENU = '/admin/menu',
  SUPPORT_LOG = '/logs/support',
  OFFER = '/offer',
  OFFER_EXCLUDED_CUSTOMERS = '/offer/excluded-customers',

  FEATURE_REQUEST = '/support/feature-request',
  LEAFY_ITEMS = '/leafy-items',
  WOO_PRODUCT_HISTORY = '/woo-commerce/products',
  WOO_PRODUCT_CATEGORIES = '/woo-commerce/categories',
  GLOBAL_SETTINGS = '/settings/global',

  // Accounts
  JOURNAL = '/accounts/journal'
}
