import {
  ICreatePriceAnalysisPayload,
  ICreatePriceGroup,
  ICreatePriceGroupRequest,
  ILotsConversionData,
  ILotsConversionResponse,
  IPriceGroupByAnalysisCreate,
  IProductCreateRequest,
  IProductCreateResponse,
  IProductGlobalHidden,
  IProductLocationHidden,
  IProductPriceGroupResponse,
  IProductStatusResponse,
  IProductType,
  IProductUpdateRequest,
  IUpdateProductPrice,
  IUpdateProductPriceV2,
  IUpdateProductStatus
} from './types';
import http from '../../utils/http.utils';
import { AxiosResponse } from 'axios';
import { ICreateCommonResponse } from '../category/types';
import { ILot } from '../purchases/types';

export const create_products_mutation = async (values: IProductCreateRequest) => {
  const response: AxiosResponse<IProductCreateResponse> = await http.store('products', values);
  return response;
};

export const update_products_mutation = async (values: IProductUpdateRequest) => {
  const response: AxiosResponse<ICreateCommonResponse> = await http.update(
    'products/' + values.id,
    values
  );
  return response;
};

export const update_products_hidden_mutation = async (id: number | string, hidden: boolean) => {
  const response: AxiosResponse<IProductType> = await http.update('products/hide-status/' + id, {
    hidden
  });
  return response.data;
};

export const merge_products_mutation = async (values: { lotIds: number[]; grade: string }) => {
  const response: AxiosResponse<ILot> = await http.store('products/merge-lots/', values);
  return response;
};

export const diverge_products_mutation = async (values: {
  lotId: number;
  lots: {
    grade: string;
    qty: number;
  }[];
}) => {
  const response: AxiosResponse<ILot[]> = await http.store('products/distribute-lots/', values);
  return response;
};

export const upload_price_group_mutation = async (values: { id: number; file: FormData }) => {
  const response = await http.store('prices/upload/' + values.id, values.file, {
    showErrorModal: false
  });
  return response;
};
export const create_price_group_mutation = async (values: ICreatePriceGroupRequest) => {
  const response: AxiosResponse<ICreatePriceGroup> = await http.store('prices/groups', values);
  return response;
};

export const upload_default_unitId = async (values: {
  productId: number | string;
  unitId: number;
}) => {
  const response = await http.store(
    'products/product/' + values.productId + '/default-unit/' + values.unitId
  );
  return response;
};

export const convert_product_lot = async (values: ILotsConversionData) => {
  const response: AxiosResponse<ILotsConversionResponse> = await http.store(
    '/products/convert-lots',
    values
  );
  return response;
};

export const update_product_status_mutation = async (values: IUpdateProductStatus) => {
  const response: AxiosResponse<IProductStatusResponse> = await http.update(
    '/products-status/' + values.id,
    values
  );
  return response.data;
};

export const update_product_pricegroup_mutation = async (values: IUpdateProductPrice[]) => {
  const response: AxiosResponse<IProductPriceGroupResponse> = await http.update('/prices/prices', {
    prices: values
  });
  return response.data;
};

export const update_product_pricegroup_mutation_v2 = async (values: IUpdateProductPriceV2[]) => {
  const response: AxiosResponse<IProductPriceGroupResponse> = await http.update(
    '/prices/prices-v2',
    { prices: values }
  );
  return response.data;
};

export const create_lots_clear_mutation = async (locationId: number) => {
  const response = await http.store('lot-0', {
    locationId
  });

  return response.data;
};

export const create_price_analysis_mutation = async (data: ICreatePriceAnalysisPayload) => {
  const response = await http.store('price-analysis', data);
  return response.data;
};

export const update_price_analysis_mutation = async (
  id: number,
  data: ICreatePriceAnalysisPayload
) => {
  const response = await http.update('price-analysis/' + id, data);
  return response.data;
};

export const update_price_group_price_by_price_analysis = async (
  props: IPriceGroupByAnalysisCreate
) => {
  const response = await http.update(
    'price-analysis/update-price-group-prices-and-daily-rate',
    props
  );
  return response.data;
};

export const hide_product_globally = async (payload: IProductGlobalHidden) => {
  const response = await http.update('products/hide-status-multiple', payload);
  return response.data;
};

export const hide_product_by_location = async (payload: IProductLocationHidden) => {
  const response = await http.update('products-status/multiple-products', payload);
  return response.data;
};
