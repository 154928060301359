import moment from 'moment';
import AppContent from '@/components/Common/Content/Content';
import { Collapse, Form, message, PageHeader, Spin } from 'antd';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import { useEffect, useState } from 'react';
import FilterForm from './Form';
import getErrorMessage from '@/utils/getError';
import { convertLocalToUTCString } from '@/utils/convertToUTC';
import { convert_string_to_nepali_date_string } from '@/utils/nepaliDateConverter';
import { getDateRangeFromSingle } from '@/utils/getDateRangeFromSingle';
import { get_sell_return_by_sell_date_with_category } from '@/services/sell/queries';
import {
  get_products_identifier_count,
  get_products_lines_sum_amount
} from '@/services/products/queries';
import { LineType } from '@/services/report/enums';
import CategoryDB from '@/store/localstorage/CategoryDB';
import { getCategoryById, getLocationByList } from '@/services';
import getGroupedData from '@/utils/getGroupedData';
import Table, { ColumnsType } from 'antd/lib/table';
import {
  calculateAvgBasketValue,
  calculateGrossProfit,
  calculateNoOfTransaction,
  calculateWastage,
  generateColumns,
  processIdentifier
} from './services';
import ExportSumReport from './ExportSumReport';
import { LocationType } from '@/services/locations/enum';

export interface ISumReportFormValue {
  locationType: LocationType | '';
  date: string;
  dateSingle: moment.Moment | null;
  dateSingleNepali: string;
}

export type ISumReportRow = {
  description: string;
  isHeader: boolean;
} & Record<string, number>;

const defaultColumns: ColumnsType<any> = [
  { title: 'Description', dataIndex: 'description', key: 'description', align: 'left' }
];

function SumReportPage() {
  const [form] = Form.useForm<ISumReportFormValue>();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<ISumReportRow[]>([]);

  const [columns, setColumns] = useState<ColumnsType<any>>(defaultColumns);
  const [columnHeaders, setColumnHeaders] = useState<string[]>([]);

  useEffect(() => {
    const today = moment();
    const date = convertLocalToUTCString(today.clone());
    const nepaliDate = convert_string_to_nepali_date_string(date);

    form.setFieldsValue({
      locationType: '',
      date,
      dateSingle: today,
      dateSingleNepali: nepaliDate
    });
  }, []);

  async function onSearch(values: ISumReportFormValue) {
    try {
      setIsLoading(true);
      if (!values.date) {
        return message.error('Please select a date');
      }

      const locationType = values.locationType || undefined;
      const dateRange = getDateRangeFromSingle(values.date);
      const identifiers: LineType[] = [
        LineType.SALE,
        LineType.PURCHASE,
        LineType.ADJUSTMENT,
        LineType.TRANSFER_IN
      ];

      const [sellReturns, lineSums, identifierCount] = await Promise.all([
        get_sell_return_by_sell_date_with_category({ ...dateRange, locationType }),
        get_products_lines_sum_amount({ ...dateRange, identifiers, locationType }),
        get_products_identifier_count({ ...dateRange, identifiers: [LineType.SALE], locationType })
      ]);

      // Is All Empty
      if (!sellReturns.length && !lineSums.length && !identifierCount.length) {
        setIsLoading(false);
        setData([]);
        setColumnHeaders(['description']);
        setColumns(defaultColumns);
        return message.error('No data found');
      }

      const allUniqueCategoryIds = [...new Set([...lineSums.map((s) => s.categoryId)])];
      await CategoryDB.addCategoryIfAbsent(allUniqueCategoryIds);

      const locationNameSets = new Set<string>();

      // Add Location and Category names to sell returns
      for (const sellReturn of sellReturns) {
        const category = await getCategoryById(sellReturn.categoryId);
        const location = await getLocationByList(sellReturn.locationId);
        sellReturn.categoryName = category?.name ?? '';
        sellReturn.locationName = location?.name ?? '';
        locationNameSets.add(sellReturn.locationName);
      }

      // Add Location and Category names to line sums
      for (const sum of lineSums) {
        const category = await getCategoryById(sum.categoryId);
        const location = await getLocationByList(sum.locationId);
        sum.categoryName = category?.name ?? '';
        sum.locationName = location?.name ?? '';
        locationNameSets.add(sum.locationName);
      }

      // Add Location name to identifier name
      for (const count of identifierCount) {
        const location = await getLocationByList(count.locationId);
        count.locationName = location?.name ?? '';
        locationNameSets.add(count.locationName);
      }

      // Get all location names
      const allLocationName = Array.from(locationNameSets).sort();
      const columnsHeader = ['description', ...allLocationName];
      setColumnHeaders(columnsHeader);
      const columns = generateColumns(columnsHeader);
      setColumns(columns);

      const identifierGroupedSumAmount = getGroupedData(lineSums, 'identifier');
      const identifierRecord = {} as Record<LineType, ISumReportRow[]>;

      const rows = Object.keys(identifierGroupedSumAmount).map((identifier) => {
        const typedIdentifier = identifier as LineType;
        const identifierData = identifierGroupedSumAmount[typedIdentifier];
        const rows = processIdentifier(identifierData, typedIdentifier);
        identifierRecord[typedIdentifier] = rows;
        return rows;
      });

      const identifierRows = rows.flat();
      if (sellReturns.length) {
        const salesReturnRows = processIdentifier(sellReturns, LineType.SALE_RETURN);
        identifierRecord[LineType.SALE_RETURN] = salesReturnRows;
        identifierRows.push(...salesReturnRows);
      }

      const wastagePercentage = calculateWastage(columnsHeader, identifierRecord);
      identifierRows.push(wastagePercentage);

      const grossProfitLoss = calculateGrossProfit(columnsHeader, identifierRecord);
      identifierRows.push(grossProfitLoss);

      const noOfTransaction = calculateNoOfTransaction(columnsHeader, identifierCount);
      identifierRows.push(noOfTransaction);

      const avgBasketValue = calculateAvgBasketValue(
        columnsHeader,
        identifierRecord,
        identifierCount
      );
      identifierRows.push(avgBasketValue);

      setData(identifierRows);
    } catch (error) {
      getErrorMessage(error, true);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Spin spinning={isLoading}>
      <AppContent
        breadcrumbItems={[{ label: 'Reports', link: '/reports' }, { label: 'Sum Report' }]}>
        <Collapse>
          <CollapsePanel header="Filters" key="1">
            <FilterForm form={form} isLoading={isLoading} onFinish={onSearch} />
          </CollapsePanel>
        </Collapse>
        <PageHeader
          subTitle="Table"
          style={{ padding: '8px 0px' }}
          extra={
            <ExportSumReport
              data={data}
              excelColumns={columnHeaders}
              isDisabled={isLoading}
              date={moment(form.getFieldValue('date')).format('YYYY, MMMM DD')}
            />
          }
        />
        <Table
          columns={columns}
          dataSource={data}
          loading={isLoading}
          pagination={false}
          scroll={{ x: data.length > 0 ? columns.length * 100 : 'auto', y: '75vh' }}
          rowClassName={(row) => (row.isHeader ? '!font-bold !bg-[#d9d9d9]' : '')}
        />
      </AppContent>
    </Spin>
  );
}

export default SumReportPage;
