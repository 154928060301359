import { useMutation } from '@tanstack/react-query';
import { Form, Input, message, PageHeader } from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AppContent from '@/components/Common/Content/Content';
import CustomSubmitButton from '@/components/Common/CustomButton/CustomSubmitButton';
import { create_reason_mutation } from '@/services/adjustments/mutations';
import { ICreateReason } from '@/services/adjustments/types';
import { getUserData } from '@/utils/auth.utils';
import useDebounceFunction from '@/hooks/useDebounceFunction';
import { useFilterStore } from '@/store/zustand';
import { ListPage } from '@/constants/list.enum';

function CreateReasons() {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const zustandFilter = useFilterStore();
  const createreasonsMutation = useMutation(create_reason_mutation, {
    onSuccess: () => zustandFilter.resetState(ListPage.ADJUSTMENT_REASONS)
  });

  // This is needed to get the product details update as its written
  const [productDetails, setProductDetails] = useState<any>();
  const [isLoading, setIsloading] = useState<boolean>(false);
  const loggedInUser = getUserData();
  const breadcrumbItems = [
    {
      label: 'Reason',
      link: '/adjustments/reasons'
    },
    {
      label: 'Create',
      link: '/adjustments/reasons/new'
    }
  ];
  const onFinish = useDebounceFunction((values: ICreateReason) => {
    values.createdBy = loggedInUser.id;
    if (!values.isArchived) {
      values.isArchived = false;
    }
    setIsloading(true);
    createreasonsMutation.mutateAsync(values, {
      onSuccess: async ({ data }: { data: any }) => {
        if (data) {
          message.success('Reason created successfully');
          navigate('/adjustments/reasons');
          setIsloading(false);
        }
      },
      onError: (e: any) => {
        message.error(`${e.response.data.message}`, 5);
        setIsloading(false);
      }
    });
  });
  return (
    <div>
      <AppContent breadcrumbItems={breadcrumbItems}>
        <PageHeader
          title="Create Reason"
          style={{
            padding: '8px 0px'
          }}
        />
        <Form
          form={form}
          onFinish={onFinish}
          disabled={isLoading}
          layout="vertical"
          validateTrigger={'onChange'}
          onValuesChange={(_, allFields) => {
            setProductDetails(allFields);
          }}
          autoComplete="off">
          <div className="grid grid-cols-2 gap-5 mb-5">
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: 'Please add name!' }]}>
              <Input />
            </Form.Item>
          </div>
          <div className="flex justify-end mt-5">
            <Form.Item>
              {/* <Button type="primary" htmlType="submit">
                Submit
              </Button> */}
              <CustomSubmitButton text="Submit" />
            </Form.Item>
          </div>
        </Form>
      </AppContent>
    </div>
  );
}

export default CreateReasons;
