import { Button, Form, Input, PageHeader, Switch, message } from 'antd';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import AppContent from '../../../../../components/Common/Content/Content';
import { create_vendor_type_mutation } from '../../../../../services/vendor-types/mutations';
import { IVendorTypeCreateRequest } from '../../../../../services/vendor-types/types';
import { VendorTypeSearch } from '../../../../../components/Common/VendorTypeSearch';
import { useFilterStore } from '@/store/zustand';
import { ListPage } from '@/constants/list.enum';

const CreateVendorType = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [isLoading, setIsloading] = useState<boolean>(false);

  const zustandFilter = useFilterStore();

  const createVehicleTypeMutation = useMutation(create_vendor_type_mutation, {
    onSuccess: (data) => {
      setIsloading(false);
      message.success('Supplier Category added successfully');
      zustandFilter.resetState(ListPage.VENDOR_TYPE);
      navigate('/vendor-type');
    },
    onError: (e: any) => {
      setIsloading(false);
      message.error(`${e.response.data.message}`);
    }
  });

  const onFinish = (values: IVendorTypeCreateRequest) => {
    setIsloading(true);
    createVehicleTypeMutation.mutate(values);
  };

  const breadcrumbItems = [
    {
      label: 'Supplier Category',
      link: '/vendor-type'
    },
    {
      label: 'Create',
      link: '/vendor-type/new'
    }
  ];

  return (
    <div>
      <AppContent breadcrumbItems={breadcrumbItems}>
        <Form
          form={form}
          initialValues={{ hidden: false }}
          onFinish={onFinish}
          disabled={isLoading}
          layout="vertical"
          validateTrigger={'onChange'}
          autoComplete="off">
          <PageHeader
            title="Supplier Category "
            style={{
              padding: '8px 0px'
            }}
          />
          <div className={'grid grid-cols-1 gap-3 mb-3 sm:grid-cols-3 gap-5 mb-5'}>
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: 'Please add name!' }]}>
              <Input />
            </Form.Item>

            <Form.Item label="Hidden?" name="hidden" valuePropName="checked">
              <Switch />
            </Form.Item>
          </div>
          <div className="flex justify-end mt-5">
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={isLoading}>
                Submit
              </Button>
            </Form.Item>
            <Button
              type="default"
              className="ml-5"
              htmlType="button"
              onClick={() => navigate('/vendor-type')}>
              Cancel
            </Button>
          </div>
        </Form>
      </AppContent>
    </div>
  );
};

export default CreateVendorType;
