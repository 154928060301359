import { useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

// ANTD Components
import { ColumnsType } from 'antd/lib/table';
import { EllipsisOutlined, EnterOutlined } from '@ant-design/icons';
import { Button, Form, Radio, Space, Spin, Table, Tooltip, message } from 'antd';

// Custom Components
import exportToExcel from '../export.service';
import AppContent from '@/components/Common/Content/Content';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';
import TableCell from '@/components/Common/CustomizeTable/CustomCell';
import { ReusableDrawer } from '@/components/Common/Drawer/ReusableDrawer';

import {
  IPnLTableData,
  IPnLTableDataForIndexing,
  IPnLTableWithTransactionData
} from '@/services/accounts/types';

import {
  addTransactionToProfitLoss,
  addTransactionToProfitLossChildren,
  getFlattenData,
  getProfitLossBalanceData
} from '../profit-loss.services';

import {
  addLocationIfAbsent,
  calculateStartAndEndBalance,
  convertIdToIndex
} from '@/services/accounts/services';

import AccountFilterTable from '@/components/AccountFilterTable/v2';
import LocationSearchV2 from '@/components/Common/CustomSearch/Location';
import { useFinancialYearStore } from '@/store/zustand/financial-year';
import { useFilterStore } from '@/store/zustand';
import getErrorMessage from '@/utils/getError';
import useWindowScrollRestoration from '@/hooks/useWindowScrollRestoration';

interface ISavedData {
  expandedRows: number[];
  locationId?: number;
  currParentIds: number[];
  tableData: IPnLTableWithTransactionData[];
  totalBalance: { debit: number; credit: number; balance: number };
}

function ProfitLossTemp() {
  const [isLoading, setIsLoading] = useState(true);
  const [valueExport, setValueExport] = useState(1);

  const [expandedRows, setExpandedRows] = useState<number[]>([]);
  const [locationId, setLocationId] = useState<number | string>();
  const [currParentIds, setCurrParentIds] = useState<number[]>([]);
  const [tableData, setTableData] = useState<IPnLTableWithTransactionData[]>([]);

  const tableRef = useRef<HTMLDivElement>(null);

  useWindowScrollRestoration({
    totalLength: tableData.length,
    tableRef
  });

  const [openExportDrawer, setOpenExportDrawer] = useState(false);
  const [totalBalance, setTotalBalance] = useState({ debit: 0, credit: 0, balance: 0 });

  const [form] = Form.useForm();
  const location = useLocation();

  const breadcrumbItems = [{ label: 'Profit & Loss', link: '/profit-loss' }];

  const zustandFilter = useFilterStore();
  const zustandFinancialYear = useFinancialYearStore();
  const currentFinancialStart = zustandFinancialYear.currentFinancialYear?.startDate as string;

  async function onSubmitFilter(vals?: string) {
    let hasMoreData = false;
    setIsLoading(true);

    const locationId = form.getFieldValue(['locationId']);
    setExpandedRows([]);
    setLocationId(locationId);

    const savedData = { expandedRows: [] as number[], locationId } as ISavedData;

    // Save total balance to show in table footer
    const totalBalance = { debit: 0, credit: 0, balance: 0 };
    let date = form.getFieldsValue(['startDate', 'endDate']);

    if (vals) {
      const params = new URLSearchParams(vals);
      const startDate = params.get('startDate');
      const endDate = params.get('endDate');

      if (startDate && endDate) {
        date = { startDate, endDate };
      }
    }

    try {
      const { results } = await addTransactionToProfitLoss({
        locationId,
        date,
        currentBalanceSortBy: 'DESC',
        parentIds: undefined,
        skip: 0,
        count: 100
      });

      const parentIds = results.map((item) => item.id);
      setCurrParentIds(parentIds);
      savedData.currParentIds = parentIds;

      // calculate start and end balance
      const balanceResults = await calculateStartAndEndBalance<typeof results>(
        date.startDate,
        parentIds,
        results,
        currentFinancialStart
      );

      const profitLossData = await getProfitLossBalanceData<typeof balanceResults>({
        locationId,
        data: balanceResults,
        parentIds,
        date,
        currentBalanceSortBy: 'DESC',
        skip: 0,
        count: 100,
        currentFinancialStart
      });

      if (locationId !== '') hasMoreData = profitLossData.length >= 100;

      // Calculate total balance
      profitLossData.forEach((item) => {
        if (item.transaction) {
          totalBalance.debit += item.transaction.totalDebit;
          totalBalance.credit += item.transaction.totalCredit;
          totalBalance.balance += item.transaction.totalDebit - item.transaction.totalCredit;
        }
      });

      if (!hasMoreData) {
        setTableData(profitLossData);
        savedData.tableData = profitLossData;
      } else {
        const obj = {} as IPnLTableData;
        const newData = [
          ...profitLossData,
          { ...obj, parentIds: parentIds, skip: profitLossData.length, hasMore: true }
        ];
        setTableData(newData);
        savedData.tableData = newData;
      }

      setTotalBalance(totalBalance);
      savedData.totalBalance = totalBalance;
      return savedData;
    } catch (error) {
      getErrorMessage(error, true);
    } finally {
      setIsLoading(false);
    }
  }

  async function getSubPnLList(parentData: IPnLTableData, locationId: number | string) {
    const date = form.getFieldsValue(['startDate', 'endDate']);
    let hasMoreData = false;

    if (!parentData) return;
    const parentMapIds: Map<number, number> = new Map();
    parentMapIds.set(parentData.id, parentData.id);

    const parentIds = [parentData.id];

    const response = await addTransactionToProfitLossChildren({
      locationId,
      date,
      currentBalanceSortBy: 'DESC',
      parentIds,
      skip: 0,
      count: 100
    });

    if (response.results.length === 0) return;
    if (response.results.length >= 100) hasMoreData = true;

    if (locationId === '') {
      response.results = await addLocationIfAbsent({
        data: response.results
      });
    }

    const cloneData = [...tableData];
    const childData: IPnLTableWithTransactionData[] = [...response.results];

    if (hasMoreData) {
      const obj = {} as IPnLTableData;
      childData.push({
        ...obj,
        parentId: parentData.id,
        parentIds,
        skip: response.results.length,
        hasMore: true
      });
    }

    if (parentIds) {
      const newTableData = convertIdToIndex(
        parentMapIds,
        cloneData as IPnLTableDataForIndexing[],
        childData as IPnLTableDataForIndexing[]
      );

      setTableData(newTableData);
      return { tableData: newTableData };
    }
  }

  async function searchMore(record: IPnLTableData) {
    if (locationId === undefined || record.parentIds === undefined) return;
    const date = form.getFieldsValue(['startDate', 'endDate']);
    const savedData = {} as ISavedData;

    const response = await addTransactionToProfitLossChildren({
      locationId,
      date,
      currentBalanceSortBy: 'DESC',
      parentIds: record.parentIds,
      skip: record.skip,
      count: 100
    });

    if (locationId === '') {
      response.results = await addLocationIfAbsent({
        data: response.results
      });
    }

    const totalBalance = { debit: 0, credit: 0, balance: 0 };
    setTableData((prev) => {
      if (!record.parentIds) {
        savedData.tableData = prev;
        return prev;
      }

      if (record.parentIds.length > 1) {
        const prevItem = [...prev];

        // Insert new data to the correct position
        response.results.forEach((item) => {
          prevItem.splice(prev.length - 1, 0, { ...item, children: [] });
        });

        if (response.results.length < 100) {
          prevItem.pop();
        } else {
          prevItem[prevItem.length - 1].skip = prevItem.filter((val) => !val.hasMore).length;
        }

        // Calculate total balance
        prevItem.forEach((item) => {
          if (item.transaction) {
            totalBalance.debit += item.transaction.totalDebit || 0;
            totalBalance.credit += item.transaction.totalCredit || 0;
            totalBalance.balance += item.endBalance || 0;
          }
        });

        savedData.tableData = prevItem;
        return prevItem;
      } else {
        const newTableData = searchTableAndAdd(record.parentIds[0], response.results, prev);

        // Calculate total balance
        newTableData.forEach((item) => {
          if (item.transaction) {
            totalBalance.debit += item.transaction.totalDebit || 0;
            totalBalance.credit += item.transaction.totalCredit || 0;
            totalBalance.balance += item.endBalance || 0;
          }
        });

        savedData.tableData = newTableData;
        return newTableData;
      }
    });
    setTotalBalance(totalBalance);
    savedData.totalBalance = totalBalance;

    zustandFilter.updatePartialData(location.pathname, { ...savedData });
  }

  function searchTableAndAdd(
    parentId: number,
    data: IPnLTableWithTransactionData[],
    tableData: IPnLTableWithTransactionData[]
  ): IPnLTableWithTransactionData[] {
    return tableData.map((item) => {
      if (item.id === parentId) {
        if (item.children) {
          data.forEach((d) => {
            item.children?.splice(item.children.length - 1, 0, {
              ...d,
              iconColor: item.children[0].iconColor,
              children: []
            });
          });
          if (data.length < 100) {
            item.children?.pop();
          } else {
            item.children[item.children?.length - 1].skip = item.children?.filter(
              (val) => !val.hasMore
            ).length;
          }
        }
        return { ...item };
      } else if (item.children) {
        const childItem = searchTableAndAdd(parentId, data, item.children);
        return { ...item, children: childItem };
      } else {
        return { ...item };
      }
    });
  }

  const columns: ColumnsType<IPnLTableWithTransactionData> = [
    {
      title: 'Name',
      key: 'name',
      width: 40,
      render: (_, record) => {
        return (
          <TableCell>
            {record.hasMore ? (
              <Tooltip title="Load More . . .">
                <Button
                  type="primary"
                  style={{ borderRadius: '9999px', padding: '0px 8px', height: '20px' }}
                  onClick={async (event) => {
                    event.stopPropagation();
                    await searchMore(record);
                  }}>
                  <EllipsisOutlined style={{ fontSize: '20px' }} />
                </Button>
              </Tooltip>
            ) : record.parentId ? (
              <div>
                <EnterOutlined
                  className="origin-center -scale-x-100 text-lg"
                  style={{ color: record.iconColor }}
                />{' '}
                <Link
                  to={`/accounts/detailed-ledger?accountId=${
                    record.id
                  }&startDate=${form.getFieldValue(['startDate'])}&endDate=${form.getFieldValue([
                    'endDate'
                  ])}`}
                  onClick={(event) => event.stopPropagation()}
                  target="_blank">
                  {record.locationName ? `${record.name} ${record.locationName}` : `${record.name}`}
                </Link>
              </div>
            ) : (
              <Link
                to={`/accounts/detailed-ledger?accountId=${
                  record.id
                }&startDate=${form.getFieldValue(['startDate'])}&endDate=${form.getFieldValue([
                  'endDate'
                ])}`}
                onClick={(event) => event.stopPropagation()}
                target="_blank">
                {record.locationName ? `${record.name} ${record.locationName}` : `${record.name}`}
              </Link>
            )}
          </TableCell>
        );
      }
    },
    {
      title: 'Type',
      key: 'type',
      width: 10,
      render: (_, record) => <TableCell>{record.type}</TableCell>
    },
    {
      title: 'Debit',
      key: 'debit',
      width: 10,
      render: (_, record) => (
        <TableCell>{nepaliNumberFormatter(record?.transaction?.totalDebit || 0)}</TableCell>
      )
    },
    {
      title: 'Credit',
      key: 'credit',
      width: 10,
      render: (_, record) => (
        <TableCell>{nepaliNumberFormatter(record?.transaction?.totalCredit || 0)}</TableCell>
      )
    },
    {
      title: 'Total',
      key: 'Total',
      className: 'highlight',
      width: 7,
      render: (text, record) => {
        const { totalCredit = 0, totalDebit = 0 } = record.transaction || {};

        const total = totalDebit - totalCredit;
        return <TableCell>{nepaliNumberFormatter(total)}</TableCell>;
      }
    }
  ];

  async function onExportTreeView() {
    setIsLoading(true);

    try {
      const date = form.getFieldsValue(['startDate', 'endDate']);

      const flattenedData = (await getFlattenData({
        date,
        locationId: locationId as number | string,
        valueExport,
        parentIds: currParentIds,
        currentData: tableData
      })) as (IPnLTableWithTransactionData & { date: string; miti: string })[];

      if (!flattenedData || flattenedData.length === 0) {
        message.error('No Data to Export');
        return;
      }

      const totalBalance = { debit: 0, credit: 0, balance: 0 };
      flattenedData.forEach((data) => {
        if (data.node === 0 && data.transaction) {
          totalBalance.debit += data.transaction.totalDebit;
          totalBalance.credit += data.transaction.totalCredit;
          totalBalance.balance += data.transaction.totalDebit - data.transaction.totalCredit;
        }
      });

      await exportToExcel({ totalBalance, data: flattenedData, date, valueExport });
    } catch (error) {
      getErrorMessage(error, true);
    } finally {
      setIsLoading(false);
      setOpenExportDrawer(false);
    }
  }

  return (
    <Spin spinning={isLoading}>
      <AppContent
        breadcrumbItems={breadcrumbItems}
        withfilter
        button={
          <div>
            <AccountFilterTable
              form={form}
              hasHours={false}
              showFilterInitial
              onParentLoading={setIsLoading}
              onInitialLoad={({ data }) => {
                if (data) {
                  const typedData = data as ISavedData;

                  if (typedData.currParentIds) setCurrParentIds(typedData.currParentIds);
                  if (typedData.tableData) setTableData(typedData.tableData);
                  if (typedData.totalBalance) setTotalBalance(typedData.totalBalance);
                  if (typedData.locationId !== undefined) setLocationId(typedData.locationId);
                  if (typedData.expandedRows) setExpandedRows(typedData.expandedRows);
                  setIsLoading(false);
                }
              }}
              defaultValues={{
                locationId: ''
              }}
              initial={false}
              onSearch={onSubmitFilter}
              buttonParentStyle={'flex justify-end items-center'}>
              <LocationSearchV2 hasParentFormItem={false} name={'locationId'} showAll />
            </AccountFilterTable>
          </div>
        }>
        <ReusableDrawer
          title="Export"
          setShowDrawer={setOpenExportDrawer}
          showDrawer={openExportDrawer}
          footer={
            <Space>
              <Button type="primary" onClick={onExportTreeView}>
                Download
              </Button>
            </Space>
          }>
          <Radio.Group onChange={(e) => setValueExport(e.target.value)} value={valueExport}>
            <Space direction="vertical">
              <Radio value={1}>Export All</Radio>
              <Radio value={2}>Export Current</Radio>
            </Space>
          </Radio.Group>
        </ReusableDrawer>
        <div className="my-2 flex justify-end">
          <Button type="primary" onClick={() => setOpenExportDrawer(true)}>
            Export
          </Button>
        </div>

        {/* Render Table */}
        <div ref={tableRef}>
          <Table
            columns={columns}
            dataSource={tableData}
            rowKey="id"
            pagination={false}
            expandable={{
              onExpand: async (expanded, record) => {
                const savedData = {} as ISavedData;

                if (!expanded) {
                  setExpandedRows((prev) => {
                    const newRow = prev.filter((key) => key !== record.id);
                    savedData.expandedRows = newRow;
                    return newRow;
                  });

                  zustandFilter.updatePartialData(location.pathname, { ...savedData });
                  return;
                }

                if (locationId === undefined) return;
                setExpandedRows((prev) => {
                  const newRow = [...prev, record.id];
                  savedData.expandedRows = newRow;
                  return newRow;
                });
                const data = await getSubPnLList(record, locationId);
                if (data) savedData.tableData = data.tableData;

                zustandFilter.updatePartialData(location.pathname, { ...savedData });
              },
              expandedRowKeys: expandedRows
            }}
            onRow={(record) => {
              return {
                onClick: async () => {
                  if (!record.hasMore) {
                    const savedData = {} as ISavedData;

                    if (expandedRows.find((key) => key === record.id)) {
                      setExpandedRows((prev) => {
                        const newRow = prev.filter((key) => key !== record.id);
                        savedData.expandedRows = newRow;
                        return newRow;
                      });
                    } else {
                      if (locationId !== undefined) {
                        setExpandedRows((prev) => {
                          const newRow = [...prev, record.id];
                          savedData.expandedRows = newRow;
                          return newRow;
                        });
                        const data = await getSubPnLList(record, locationId);
                        if (data) savedData.tableData = data.tableData;
                      }
                    }

                    zustandFilter.updatePartialData(location.pathname, { ...savedData });
                  }
                }
              };
            }}
            summary={() => (
              <Table.Summary fixed={true}>
                <Table.Summary.Row>
                  <Table.Summary.Cell colSpan={2} index={0} className="font-bold p-1 text-center">
                    Total
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={2} className="font-bold !p-1">
                    {nepaliNumberFormatter(totalBalance.debit)}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={2} className="font-bold !p-1">
                    {nepaliNumberFormatter(totalBalance.credit)}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={2} className="font-bold !p-1 highlight">
                    {nepaliNumberFormatter(totalBalance.balance)}
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            )}
          />
        </div>
      </AppContent>
    </Spin>
  );
}

export default ProfitLossTemp;
