import { Button, Tooltip } from 'antd';

interface Props {
  disabled?: boolean;
  onRefresh: () => Promise<unknown>;
}

function RefreshButton({ onRefresh, disabled }: Props) {
  return (
    <Tooltip title="Applied filter will reset and data will be reloaded">
      <Button
        disabled={disabled}
        className="flex items-center justify-end secondary-button"
        style={{ borderRadius: '5px' }}
        onClick={onRefresh}>
        Refresh
      </Button>
    </Tooltip>
  );
}

export default RefreshButton;
