import { Button, Form, InputNumber, PageHeader, Spin, message } from 'antd';
import { useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import AppContent from '@/components/Common/Content/Content';
import { get_product_pricegroup_list } from '@/services/products/queries';
import { useMutation } from '@tanstack/react-query';
import { update_product_pricegroup_mutation_v2 } from '@/services/products/mutations';

import { useFilterStore } from '@/store/zustand';
import { ListPage } from '@/constants/list.enum';
import ProductSearchV2 from '@/components/Common/CustomSearch/Products';
import UnitSearch, { IProductUnitId } from '@/components/Common/CustomSearch/Units';
import getErrorMessage from '@/utils/getError';
import isAxiosError from '@/utils/isAxiosError';
import { getProductById } from '@/services';

interface FormData {
  productId: number;
  unitId: number;
  sellingPrice: number;
  costPrice: number;
}

const PriceGroupDetails = () => {
  const params = useParams();
  const [form] = Form.useForm<FormData>();
  const [isLoading, setIsLoading] = useState(false);

  const priceGroupId = Number(params.id);
  if (isNaN(priceGroupId) || priceGroupId <= 0) return <Navigate to="/price-groups" />;

  const [productUnitIds, setProductUnitIds] = useState<IProductUnitId[]>([]);

  const zustandFilter = useFilterStore();

  const breadcrumbItems = [
    { label: 'Price Groups', link: '/price-groups' },
    { label: 'Details', link: '/price-groups/details/:id' }
  ];

  async function onProductChange(productId: number | string) {
    try {
      setIsLoading(true);
      if (typeof productId === 'string' || !productId) {
        form.setFieldValue(['unitId'], '');
        return setProductUnitIds([]);
      }

      const product = await getProductById(productId);
      const units = product.productUnits.map((value) => ({
        id: value.unitId,
        isDefault: value.isDefault
      }));

      setProductUnitIds(units);

      // Get existing product price group details
      await fetchProductPrices(productId);
      const defaultUnit = product.productUnits.find((curr) => curr.isDefault);
      form.setFieldValue(['unitId'], defaultUnit?.unitId);
    } catch (error) {
      getErrorMessage(error, true);
    } finally {
      setIsLoading(false);
    }
  }

  const fetchProductPrices = async (productId: number) => {
    try {
      setIsLoading(false);

      const params = new URLSearchParams();
      params.set('priceGroupId', priceGroupId.toString());
      params.set('productId', productId.toString());

      const response = await get_product_pricegroup_list(params.toString());

      if (response.length > 0) {
        form.setFieldValue(['sellingPrice'], response[0].sellingPrice);
        form.setFieldValue(['costPrice'], response[0].costPrice);
      }
    } catch (error) {
      getErrorMessage(error, true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmitClick = async (values: FormData) => {
    try {
      setIsLoading(true);
      await updateProductPriceGroupMutation.mutateAsync([{ priceGroupId, ...values }]);
    } catch (error) {
      if (isAxiosError(error)) return;
      getErrorMessage(error, true);
    } finally {
      setIsLoading(false);
    }
  };

  const updateProductPriceGroupMutation = useMutation(update_product_pricegroup_mutation_v2, {
    onSuccess: async () => {
      message.success('Product Price Group updated successfully');
      zustandFilter.removeData(ListPage.PRICE_GROUP);
      form.resetFields();
      setProductUnitIds([]);
    }
  });

  return (
    <Spin spinning={isLoading}>
      <AppContent breadcrumbItems={breadcrumbItems} withfilter={false}>
        <Form
          form={form}
          initialValues={{ sellingPrice: 0, costPrice: 0 }}
          onFinish={handleSubmitClick}
          disabled={isLoading}
          layout="vertical"
          validateTrigger={'onChange'}
          autoComplete="off">
          <PageHeader title="Price Group" style={{ padding: '8px 0px' }} />
          <div className={'grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-5 mb-5'}>
            <ProductSearchV2
              hasParentFormItem={false}
              name={['productId']}
              required
              onOptionClick={(value) => onProductChange(value)}
            />

            <UnitSearch
              hasParentFormItem={false}
              name={['unitId']}
              selectedProductUnitIds={productUnitIds}
            />
          </div>

          <div className={'grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-5 mb-5'}>
            <Form.Item
              name={['sellingPrice']}
              label="Selling Price"
              rules={[{ required: true, message: 'Please add selling price!' }]}>
              <InputNumber controls={false} min={0} />
            </Form.Item>
            <Form.Item
              name={['costPrice']}
              label="Cost Price"
              rules={[{ required: true, message: 'Please add cost price!' }]}>
              <InputNumber controls={false} min={0} />
            </Form.Item>
          </div>
          <div className="flex justify-end mt-5">
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </div>
        </Form>
      </AppContent>
    </Spin>
  );
};

export default PriceGroupDetails;
