import moment from 'moment';
import {
  Button,
  DatePicker,
  Form,
  InputNumber,
  message,
  Modal,
  PageHeader,
  Select,
  Spin
} from 'antd';
import { useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import AppContent from '@/components/Common/Content/Content';
import CustomErrorModal from '@/components/Common/CustomErrorModal';
import { getUserData } from '@/utils/auth.utils';
import getErrorMessage from '@/utils/getError';
import { IHRAttendancePayload, IHRShift } from '@/services/hr/types';
import { update_hr_attendance_mutation } from '@/services/hr/mutations';
import { useQuery } from '@tanstack/react-query';
import { get_attendance_details, get_hr_shift_by_user_and_date } from '@/services/hr/queries';
import useBroadcast from '@/hooks/useBroadcast';
import VerticalForm from '@/components/FakeForm/VerticalForm';
import { getUser } from '@/services';
import isAxiosError from '@/utils/isAxiosError';
import { useFilterStore } from '@/store/zustand';
import { ListPage } from '@/constants/list.enum';

interface FormValues {
  userId: number;
  shiftId?: number;
  checkIn: moment.Moment;
  checkOut?: moment.Moment;
}

function UpdateAttendance() {
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm<FormValues>();
  const { handleAttendance } = useBroadcast();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const params = useParams();
  const attendanceId = Number(params.id);

  if (isNaN(attendanceId) || attendanceId <= 0) return <Navigate to={'/hr/attendance'} />;

  const navigate = useNavigate();
  const currentUser = getUserData();

  const selectedUserId = Form.useWatch('userId', form);
  const [usersShift, setUsersShift] = useState<IHRShift[]>([]);

  const zustandFilter = useFilterStore();

  const {
    isLoading: isDetailsLoading,
    isFetching,
    data
  } = useQuery(['hr_attendance', attendanceId], async () => {
    const data = await get_attendance_details(attendanceId);
    const user = await getUser(data.userId);

    const checkInMoment = moment(data.startDate);
    await getShiftByUserAndDate(checkInMoment, data.userId, data.shiftId);

    form.setFieldsValue({
      userId: data.userId,
      shiftId: data.shiftId || undefined,
      checkIn: checkInMoment,
      checkOut: data.endDate ? moment(data.endDate) : undefined
    });

    return { attendance: data, user };
  });

  async function onFinish(values: FormValues) {
    try {
      setIsModalOpen(false);
      setIsLoading(true);

      const isSelf = values.userId === currentUser.id;
      const endDate = values.checkOut ? moment(values.checkOut).toISOString() : undefined;

      const payload: IHRAttendancePayload = {
        userId: values.userId,
        shiftId: values.shiftId,
        startDate: moment(values.checkIn).toISOString(),
        endDate
      };

      await update_hr_attendance_mutation(attendanceId, payload);
      zustandFilter.removeData(ListPage.ATTENDANCE);

      message.success('Attendance updated successfully');
      navigate(-1);

      if (isSelf && endDate) handleAttendance('out');
    } catch (error) {
      if (isAxiosError(error)) return;
      const errorMessage = getErrorMessage(error);
      CustomErrorModal({ message: errorMessage });
    } finally {
      setIsLoading(false);
    }
  }

  const onDisabledDate = (current: moment.Moment | null) => {
    if (!current) return false;
    return current > moment().endOf('day');
  };

  function onDisabledCheckOutDate(current: moment.Moment | null) {
    if (!current) return false;

    // Do not allow check out date to be before check in date and day after today
    const checkIn = form.getFieldValue('checkIn');
    return current.isBefore(checkIn) || current.isAfter(moment(), 'day');
  }

  async function onUpdate() {
    await form.validateFields();
    setIsModalOpen(true);
  }

  async function onCheckInChange(date: moment.Moment | null) {
    try {
      setIsLoading(true);
      // Reset Check Out, if new check in is greater than check out
      const checkOut = form.getFieldValue('checkOut');
      if (checkOut && date && checkOut.isBefore(date)) {
        form.setFieldValue('checkOut', undefined);
      }

      if (!selectedUserId) {
        form.setFieldValue('shiftId', undefined);
        return;
      }

      await getShiftByUserAndDate(date, selectedUserId);
    } catch (error) {
      message.error(getErrorMessage(error));
    } finally {
      setIsLoading(false);
    }
  }

  async function getShiftByUserAndDate(
    date: moment.Moment | null,
    userId: number,
    shiftId?: number | null
  ) {
    if (!userId || !date) return;
    const shifts = await get_hr_shift_by_user_and_date(userId, date.toISOString());
    if (shifts.length === 0) {
      message.error('No shifts found for the selected date.');
    }

    const currentShift = form.getFieldValue('shiftId') || shiftId;
    const isPresent = shifts.some((shift) => shift.id === currentShift);
    if (!isPresent) {
      form.setFieldValue('shiftId', undefined);
    }

    setUsersShift(shifts);
  }

  return (
    <Spin spinning={isLoading || isDetailsLoading || isFetching}>
      <Modal visible={isModalOpen} onCancel={() => setIsModalOpen(false)} onOk={form.submit}>
        <p>Are you sure you want to update this attendance?</p>
      </Modal>
      <AppContent
        breadcrumbItems={[
          { label: 'HR', link: '/hr' },
          { label: 'Attendance', link: '/hr/attendance' },
          { label: 'Update' }
        ]}>
        <PageHeader
          title="Update Attendance"
          className="small-title"
          style={{ padding: '8px 0' }}
        />
        <Form
          form={form}
          onFinish={onFinish}
          layout="vertical"
          initialValues={{ attendanceFor: 'self' }}>
          <div className="grid gap-3 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
            <VerticalForm label="Employee" value={data?.user.name} />
            <Form.Item name="userId" hidden>
              <InputNumber controls={false} />
            </Form.Item>
          </div>

          <div className="mt-4 grid gap-3 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
            <Form.Item
              name="checkIn"
              label="Check In"
              hidden={!selectedUserId}
              rules={[{ required: true, message: 'Please select check in time' }]}>
              <DatePicker
                className="w-full max-w-md"
                disabledDate={onDisabledDate}
                showTime
                allowClear={false}
                onChange={onCheckInChange}
                format={'YYYY-MM-DD HH:mm'}
              />
            </Form.Item>

            <Form.Item
              name="shiftId"
              label="Shift"
              hidden={!selectedUserId}
              rules={[{ required: true, message: 'Please select shift' }]}>
              <Select
                className="w-full max-w-md"
                placeholder="Select Shift"
                disabled={!form.getFieldValue('checkIn')}>
                {usersShift.map((shift) => (
                  <Select.Option key={shift.id} value={shift.id}>
                    {shift.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item name="checkOut" label="Check Out">
              <DatePicker
                className="w-full max-w-md"
                disabledDate={onDisabledCheckOutDate}
                allowClear={false}
                showTime
                format={'YYYY-MM-DD HH:mm'}
              />
            </Form.Item>
          </div>

          <div className="flex justify-end mt-4 gap-2">
            <Button type="primary" onClick={onUpdate} disabled={isLoading}>
              Update Attendance
            </Button>
          </div>
        </Form>
      </AppContent>
    </Spin>
  );
}

export default UpdateAttendance;
