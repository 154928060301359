import { DatePicker, Form, Input, InputNumber, Select, TableProps } from 'antd';
import { RangePickerProps } from 'antd/lib/date-picker';
import { useEffect, useState } from 'react';
import { CustomDatePresets } from '../utils/datePresets';
const { RangePicker } = DatePicker;
import moment from 'moment';
import type { ColumnsType } from 'antd/es/table';

import CustomReport from './report';
import { SorterResult } from 'antd/lib/table/interface';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';
import { find_date_preference, find_to_use_NPR } from '@/store/localstorage/preferences';
import { NepaliDatePicker } from 'nepali-datepicker-reactjs';
import {
  convert_nepali_to_english_date,
  convert_string_to_nepali_date_string
} from '@/utils/nepaliDateConverter';
import { getUserData } from '@/utils/auth.utils';
import UserSearchV2 from '@/components/Common/CustomSearch/Users';
import CustomerSearchV2 from '@/components/Common/CustomSearch/Customer';
import LocationSearchV2 from '@/components/Common/CustomSearch/Location';
import RouteSearchV2 from '@/components/Common/CustomSearch/Routes';
import { DEFAULT_DATE_FORMAT } from '@/constants';

interface DataType {
  location_name: string;
  financialRef: string;
  referenceNo: string;
  paidOn: string;
  sellCreatedAt: string;
  addedBy: string;
  amount: number;
  customer: string;
  paymentMethod: string;
  phone: string;
}

const PaymentSellReport = () => {
  const [form] = Form.useForm();
  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({});
  const handleChange: TableProps<any>['onChange'] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<any>);
  };

  const [nepaliType] = useState(find_date_preference());
  const { preferences } = getUserData();
  const preferenceLocationId = preferences?.preferences
    ? JSON.parse(preferences?.preferences)?.locationId
    : undefined;
  useEffect(() => {
    const startDate = CustomDatePresets.Today[0].format('YYYY-MM-DD HH:mm');
    const endDate = CustomDatePresets.Today[1].format('YYYY-MM-DD HH:mm');

    const nepaliDateStart = convert_string_to_nepali_date_string(startDate);
    const nepaliDateEnd = convert_string_to_nepali_date_string(endDate);

    form.setFieldValue('constraints', {
      page: 1,
      size: 100,
      paymentMethod: '',
      userId: '',
      customerId: '',
      routeId: '',
      locationId: preferenceLocationId ? preferenceLocationId : '',
      dateCustom: [...CustomDatePresets.Today],
      startDate,
      endDate,
      sellStartDate: startDate,
      sellEndDate: endDate,
      startDateNepali: nepaliDateStart,
      endDateNepali: nepaliDateEnd,
      createdDateRange: [...CustomDatePresets.Today]
    });

    form.setFieldValue('startDateNepali', nepaliDateStart);
    form.setFieldValue('endDateNepali', nepaliDateEnd);
    form.setFieldValue('sellStartDateNepali', nepaliDateStart);
    form.setFieldValue('sellEndDateNepali', nepaliDateEnd);
  }, []);

  const breadcrumbItems = [
    { label: 'Reports', link: '/reports' },
    { label: 'Payment Sell', link: '/reports/payment-sell' }
  ];
  const onChange: RangePickerProps['onChange'] = (dates, dateStrings) => {
    if (dateStrings.length == 2) {
      form.setFieldValue(['constraints', 'startDate'], dateStrings[0]);
      form.setFieldValue(['constraints', 'endDate'], dateStrings[1]);
    }
  };

  const handleCreatedDateChange: RangePickerProps['onChange'] = (dates, dateStrings) => {
    if (dateStrings.length == 2) {
      form.setFieldValue(['constraints', 'sellStartDate'], dateStrings[0]);
      form.setFieldValue(['constraints', 'sellEndDate'], dateStrings[1]);
    }
  };

  const columns: ColumnsType<DataType> = [
    {
      title: 'S.N',
      key: 'SN',
      width: 30,
      render: (text, record, index) => {
        return <div>{index + 1}</div>;
      }
    },
    {
      title: 'Financial Reference',
      key: 'financialRef',
      sorter: (a, b) => a.financialRef.localeCompare(b.financialRef),
      sortOrder: sortedInfo.columnKey === 'financialRef' ? sortedInfo.order : null,
      width: 100,
      dataIndex: 'financialRef'
    },
    {
      title: 'Reference No.',
      key: 'referenceNo',
      className: 'invoice',
      sorter: (a, b) => a.referenceNo.localeCompare(b.referenceNo),
      sortOrder: sortedInfo.columnKey === 'referenceNo' ? sortedInfo.order : null,
      dataIndex: 'referenceNo',
      width: 100
    },
    {
      title: 'Location',
      key: 'location_name',
      sorter: (a, b) => a.location_name.localeCompare(b.location_name),
      sortOrder: sortedInfo.columnKey === 'location_name' ? sortedInfo.order : null,
      dataIndex: 'location_name',
      width: 100
    },
    {
      title: 'Paid On',
      key: 'paidOn',
      sorter: (a, b) => moment(a.paidOn).unix() - moment(b.paidOn).unix(),
      sortOrder: sortedInfo.columnKey === 'paidOn' ? sortedInfo.order : null,
      render: (record) => {
        return <div>{moment(record.paidOn).local().format(DEFAULT_DATE_FORMAT)}</div>;
      },
      width: 100
    },
    {
      title: 'Sell Created At',
      key: 'sellCreatedAt',
      sorter: (a, b) => moment(a.sellCreatedAt).unix() - moment(b.sellCreatedAt).unix(),
      sortOrder: sortedInfo.columnKey === 'sellCreatedAt' ? sortedInfo.order : null,
      render: (record) => {
        return <div>{moment(record.sellCreatedAt).local().format(DEFAULT_DATE_FORMAT)}</div>;
      },
      width: 100
    },
    {
      title: 'Added By',
      key: 'addedBy',
      sorter: (a, b) => a.addedBy.localeCompare(b.addedBy),
      sortOrder: sortedInfo.columnKey === 'addedBy' ? sortedInfo.order : null,
      render: (record) => {
        return <div>{record.addedBy}</div>;
      },
      width: 100
    },
    {
      title: 'Amount',
      key: 'amount',
      className: 'highlight',
      dataIndex: 'amount',
      sorter: (a, b) => a.amount - b.amount,
      sortOrder: sortedInfo.columnKey === 'amount' ? sortedInfo.order : null,
      render: (a, record) => {
        return <div className="text-right mr-2">{nepaliNumberFormatter(record.amount)}</div>;
      },
      width: 50
    },
    {
      title: 'Customer',
      key: 'customer',
      sorter: (a, b) => a.customer.localeCompare(b.customer),
      sortOrder: sortedInfo.columnKey === 'customer' ? sortedInfo.order : null,
      render: (record) => {
        return <div>{record.customer}</div>;
      },
      width: 100
    },
    {
      title: 'Payment Method',
      dataIndex: 'paymentMethod',
      render: (text: string) => <div>{text}</div>,
      width: 50
    }
  ];

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    return current && current > moment().endOf('day');
  };

  const onChangeNepali = (val: string, isStart: boolean, type: 'date' | 'sell') => {
    const startFormName = type === 'date' ? 'startDate' : 'sellStartDate';
    const endFormName = type === 'date' ? 'endDate' : 'sellEndDate';

    if (isStart)
      form.setFieldValue(['constraints', startFormName], convert_nepali_to_english_date(val));
    else form.setFieldValue(['constraints', endFormName], convert_nepali_to_english_date(val));
  };

  return (
    <CustomReport
      toSort={handleChange}
      breadcrumbItems={breadcrumbItems}
      state={{ id: 13, name: 'Payment Sell Report' }}
      form={form}
      columnsData={columns}
      toDownload={true}>
      <Form.Item label={'page'} name={['constraints', 'page']} hidden={true}>
        <InputNumber controls={false} />
      </Form.Item>
      <Form.Item label={'size'} name={['constraints', 'size']} hidden={true}>
        <InputNumber controls={false} />
      </Form.Item>

      <UserSearchV2 showAll hasParentFormItem={false} name={['constraints', 'userId']} />
      <CustomerSearchV2 showAll hasParentFormItem={false} name={['constraints', 'customerId']} />
      <LocationSearchV2 showAll hasParentFormItem={false} name={['constraints', 'locationId']} />
      <RouteSearchV2 showAll hasParentFormItem={false} name={['constraints', 'routeId']} />
      <Form.Item name={['constraints', 'paymentMethod']} label="Payment Method">
        <Select placeholder="Select a method!">
          <Select.Option value="">All</Select.Option>
          <Select.Option value="fonepay">Fonepay</Select.Option>
          <Select.Option value="cash">Cash</Select.Option>
          <Select.Option value="card">Card</Select.Option>
          <Select.Option value="cheque">Cheque</Select.Option>
          <Select.Option value="bank">Bank Tranfer</Select.Option>
          <Select.Option value="qr_offline">Offline QR</Select.Option>
          <Select.Option value="other">Other</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        label={'Pick a date'}
        name={['constraints', 'dateCustom']}
        rules={[{ required: true, message: 'Please select date!' }]}
        hidden={nepaliType}>
        <RangePicker
          showTime
          disabledDate={disabledDate}
          ranges={CustomDatePresets}
          onChange={onChange}
          format={'YYYY-MM-DD HH:mm'}
          allowClear={false}
          className="w-full"
        />
      </Form.Item>
      <Form.Item
        label={'Sell Date Range'}
        name={['constraints', 'createdDateRange']}
        rules={[{ required: !nepaliType, message: 'Please select date!' }]}
        hidden={nepaliType}>
        <RangePicker
          showTime
          disabledDate={disabledDate}
          ranges={CustomDatePresets}
          onChange={handleCreatedDateChange}
          format={'YYYY-MM-DD HH:mm'}
          allowClear={false}
          className="w-full"
        />
      </Form.Item>
      <Form.Item label={'Start Date'} name={['constraints', 'startDate']} hidden>
        <Input />
      </Form.Item>
      <Form.Item label={'End Date'} name={['constraints', 'endDate']} hidden>
        <Input />
      </Form.Item>
      <Form.Item label={'Start Date'} name={['constraints', 'sellStartDate']} hidden>
        <Input />
      </Form.Item>
      <Form.Item label={'End Date'} name={['constraints', 'sellEndDate']} hidden>
        <Input />
      </Form.Item>
      <Form.Item label={'Select Start Date'} name={['startDateNepali']} hidden={!nepaliType}>
        <NepaliDatePicker
          onChange={(val) => onChangeNepali(val, true, 'date')}
          options={{ calenderLocale: 'ne', valueLocale: 'en' }}
        />
      </Form.Item>
      <Form.Item label={'Select End Date'} name={['endDateNepali']} hidden={!nepaliType}>
        <NepaliDatePicker
          onChange={(val) => onChangeNepali(val, false, 'date')}
          options={{ calenderLocale: 'ne', valueLocale: 'en' }}
        />
      </Form.Item>
      <Form.Item
        label={'Sell Start Date'}
        name={['sellStartDateNepali']}
        hidden={!nepaliType}
        rules={[{ required: nepaliType, message: 'Provide Sell Start Date' }]}>
        <NepaliDatePicker
          onChange={(val) => onChangeNepali(val, true, 'sell')}
          options={{ calenderLocale: 'ne', valueLocale: 'en' }}
        />
      </Form.Item>
      <Form.Item
        label={'Sell End Date'}
        name={['sellEndDateNepali']}
        hidden={!nepaliType}
        rules={[{ required: nepaliType, message: 'Provide Sell End Date' }]}>
        <NepaliDatePicker
          onChange={(val) => onChangeNepali(val, false, 'sell')}
          options={{ calenderLocale: 'ne', valueLocale: 'en' }}
        />
      </Form.Item>
    </CustomReport>
  );
};

export default PaymentSellReport;
