import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import LocalStore from './../store/localstorage';
import { logout } from '../utils/auth.utils';
import { checkAccess } from '@/routes/acl';
import { useFilterStore } from '@/store/zustand';
import { StoreNames } from '@/store/localstorage/storeList.enum';
import { useFinancialYearStore } from '@/store/zustand/financial-year';

interface Props {
  children: JSX.Element;
}

function BroadcastDeleteProvider({ children }: Props) {
  const navigateTo = useNavigate();
  const zustandFilter = useFilterStore();
  const financialYearStore = useFinancialYearStore();

  async function deleteFilterStore() {
    await zustandFilter.resetAll();
  }

  async function deleteLocalStore() {
    const dashboardPromise = LocalStore.clearStore(StoreNames.Dashboard);
    const lotsPromise = LocalStore.clearStore(StoreNames.TotalLotsAvailable);
    await Promise.allSettled([dashboardPromise, lotsPromise]);
  }

  useEffect(() => {
    const handleSignOut = async (event: MessageEvent) => {
      if (event.data.action === 'signout') {
        logout();
        financialYearStore.reset();
        await Promise.allSettled([deleteFilterStore(), deleteLocalStore()]).catch(console.log);
        navigateTo('/login');
      }

      if (event.data.action === 'signin') {
        if (checkAccess('CREATE_POS') && !checkAccess('B2B')) {
          navigateTo('/sell/pos-create-v2');
          return;
        }

        navigateTo('/sell');
      }
    };

    // Listen for deletion messages from other tabs
    const channel = new BroadcastChannel('auth-channel');
    channel.addEventListener('message', handleSignOut);

    return () => {
      // Remove the event listener when the component unmounts
      channel.removeEventListener('message', handleSignOut);
      channel.close();
    };
  }, []);
  return children;
}

export default BroadcastDeleteProvider;
