import moment from 'moment';
import { Descriptions, Form, message, PageHeader, Spin } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';

import AppContent from '@/components/Common/Content/Content';
import { DEFAULT_DATE_FORMAT } from '@/constants';
import { get_zero_lots_details } from '@/services/products/queries';
import { useQuery } from '@tanstack/react-query';

import Table from './Table';
import { useState } from 'react';
import { IClearLotDetails } from '@/services/products/types';
import getErrorMessage from '@/utils/getError';
import handlePagination from '@/utils/handlePagination';
import { fillHIstoryDetails, fillLotsDetails } from './services.lots';
import TableFilter from '@/components/FliterTable';
import ProductSearchV2 from '@/components/Common/CustomSearch/Products';

function ViewClearStockDetails() {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { id } = useParams() as { id: number | undefined };
  const [pagination, setPagination] = useState({ page: 1, size: 100 });

  const [selectedProduct, setSelectedProduct] = useState<number>();

  const [changedLots, setChangedLots] = useState({
    total: 0,
    results: [] as IClearLotDetails[]
  });

  const breadcrumbs = [
    { label: 'Products', link: '/products' },
    { label: 'Clear Stocks', link: '/products/clear-stocks' },
    { label: 'View', link: `/products/clear-stocks/view/${id}` }
  ];

  async function getInfo() {
    if (!id || Number.isNaN(Number(id))) {
      navigate('/products/clear-stocks');
      return;
    }

    const { history, lots } = await get_zero_lots_details(id);
    await Promise.all([fillHIstoryDetails(history), fillLotsDetails(lots)]);

    // Only get 100 lots
    const lotsToShow = lots.slice(0, 100);
    setChangedLots({ total: lots.length, results: lotsToShow });
    setPagination({ page: 1, size: 100 });

    return { history, lots };
  }

  const { data, isLoading } = useQuery(['get_clear_stock_details', id], getInfo, {
    enabled: !!id,

    onError: (error) => {
      message.error(getErrorMessage(error));
    }
  });

  const onPagination = async (page = 1, size = 100, isSize = false) => {
    if (!data) {
      return message.error('No data found');
    }

    const { values, ...pagination } = handlePagination({
      formValues: {},
      page,
      size,
      isSize
    });

    setPagination((prev) => {
      prev.page = pagination.page;
      if (isSize) prev.size = pagination.size;
      return prev;
    });

    if (!selectedProduct) {
      const newLotsToShow = data.lots.slice(values.skip, values.skip + values.count);
      setChangedLots({ total: data.lots.length, results: newLotsToShow });
    } else {
      const currentLots = data.lots.filter((lot) => lot.productId === selectedProduct);
      const newLotsToShow = currentLots.slice(values.skip, values.skip + values.count);
      setChangedLots({ total: currentLots.length, results: newLotsToShow });
    }
  };

  async function onSubmitFilter(filter: string) {
    if (!data) {
      return message.error('No data found');
    }

    const params = new URLSearchParams(filter);
    const productId = Number(params.get('productId'));

    if (!productId || Number.isNaN(productId)) {
      setSelectedProduct(undefined);
      const newLotsToShow = data.lots.slice(0, 100);
      setChangedLots({ total: data.lots.length, results: newLotsToShow });
    } else {
      setSelectedProduct(productId);
      const totalLots = data.lots.filter((lot) => lot.productId === productId);
      const newLotsToShow = totalLots.slice(0, 100);
      setChangedLots({ total: totalLots.length, results: newLotsToShow });
    }

    setPagination({ page: 1, size: 100 });
  }

  return (
    <Spin spinning={isLoading}>
      <AppContent
        breadcrumbItems={breadcrumbs}
        withfilter
        button={
          <TableFilter
            allowSaved={false}
            form={form}
            onSubmit={onSubmitFilter}
            initial={false}
            dateCustom={false}
            defaultValues={{
              productId: ''
            }}
            styleforbuttons={'flex justify-end items-center'}>
            <ProductSearchV2
              name="productId"
              hasParentFormItem={false}
              showAll
              locationId={data?.history?.locationId}
            />
          </TableFilter>
        }>
        <PageHeader title="Cleared Stock Detail" style={{ padding: '8px 0px' }} />
        <Descriptions
          bordered
          column={{
            xxl: 4,
            xl: 3,
            lg: 3,
            md: 2,
            sm: 1,
            xs: 1
          }}>
          <Descriptions.Item label="Location">{data?.history?.locationName}</Descriptions.Item>
          <Descriptions.Item label="Created At">
            {moment(data?.history?.createdAt).format(DEFAULT_DATE_FORMAT)}
          </Descriptions.Item>
          <Descriptions.Item label="Cleared By">{data?.history?.createdName}</Descriptions.Item>
        </Descriptions>

        <div>
          <PageHeader subTitle="Lots Details" style={{ padding: '8px 0px' }} />
          <Table data={changedLots} pagination={pagination} onPagination={onPagination} />
        </div>
      </AppContent>
    </Spin>
  );
}

export default ViewClearStockDetails;
