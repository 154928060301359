import AppContent from '@/components/Common/Content/Content';
import { ConvertObjectToURL } from '@/utils/converturl';
import getErrorMessage from '@/utils/getError';
import handlePagination from '@/utils/handlePagination';
import { Form, Input, Select } from 'antd';
import { useState } from 'react';
import Table from './Table';
import TableFilter from '@/components/FliterTable';
import moment from 'moment';
import UserSearchV2 from '@/components/Common/CustomSearch/Users';
import { ILogSupportResponse } from '@/services/logs/support/types';
import { get_logs_support_list } from '@/services/logs/support/queries';
import { getUser } from '@/services';
import UsersDB from '@/store/localstorage/UsersDB';

function LogSupportList() {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(true);
  const [pagination, setPagination] = useState({ page: 1, size: 100 });
  const [data, setData] = useState<ILogSupportResponse>({ total: 0, results: [] });

  async function onSubmitFilter(filter: string) {
    const data = await getInfo(filter);
    setPagination({ page: 1, size: 100 });
    return data;
  }

  async function getInfo(filter = '') {
    try {
      setIsLoading(true);
      const response = await get_logs_support_list(filter);
      const allUserIds = Array.from(new Set(response.results.map((l) => l.userId))).filter(
        Boolean
      ) as number[];

      await UsersDB.addUsersIfAbsent(allUserIds);
      for (const logSupport of response.results) {
        if (logSupport.userId) {
          const requestedBy = await getUser(logSupport.userId);
          logSupport.requestByName = requestedBy.name;
        }

        if (logSupport.solvedBy) {
          const solvedBy = await getUser(logSupport.solvedBy);
          logSupport.solvedByName = solvedBy.name;
        }
      }
      setData(response);
      return response;
    } catch (error) {
      getErrorMessage(error, true);
    } finally {
      setIsLoading(false);
    }
  }

  const onPagination = async (page = 1, size = 100, isSize = false) => {
    setIsLoading(true);
    const { values, ...pagination } = handlePagination({
      formValues: form.getFieldsValue(),
      page,
      size,
      isSize
    });

    setPagination((prev) => {
      prev.page = pagination.page;
      if (isSize) prev.size = pagination.size;
      return prev;
    });

    const url = ConvertObjectToURL(values);
    return await getInfo(url);
  };

  return (
    <div>
      <AppContent
        breadcrumbItems={[{ label: 'Logs', link: '/logs' }, { label: 'Support' }]}
        withfilter
        button={
          <TableFilter
            initial
            form={form}
            onInitialLoad={({ data, pagination }) => {
              setPagination(pagination);

              if (data) {
                setData(data);
                setIsLoading(false);
              }
            }}
            onSubmit={onSubmitFilter}
            defaultValues={{
              dateCustom: [moment(0, 'HH'), moment(0, 'HH').add(1, 'days')],
              value: '',
              skip: 0,
              count: 100,
              requestedBy: '',
              resolvedBy: '',
              isSolved: ''
            }}
            styleforbuttons={'flex justify-end items-center'}>
            <UserSearchV2
              hasParentFormItem={false}
              name="requestedBy"
              label="Requested By"
              showAll
            />

            <UserSearchV2 hasParentFormItem={false} name="resolvedBy" label="Resolved By" showAll />
            <Form.Item name="archiveStatus" label="Status">
              <Select placeholder="Select a status!" allowClear>
                <Select.Option value="">All</Select.Option>
                <Select.Option value={'INACTIVE'}>Solved</Select.Option>
                <Select.Option value={'ACTIVE'}>Unsolved</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item name="value" label="Name">
              <Input />
            </Form.Item>
          </TableFilter>
        }>
        <Table
          form={form}
          data={data}
          isLoading={isLoading}
          pagination={pagination}
          onPagination={onPagination}
        />
      </AppContent>
    </div>
  );
}

export default LogSupportList;
