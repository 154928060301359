import AppContent from '@/components/Common/Content/Content';
import CustomButton from '@/components/Common/CustomButton/CustomButton';
import CustomErrorModal from '@/components/Common/CustomErrorModal';
import CustomerSearchV2 from '@/components/Common/CustomSearch/Customer';
import { ListPage } from '@/constants/list.enum';
import { getCustomer } from '@/services';
import { update_offer_excluded_discount_mutation } from '@/services/offfers/mutations';
import { get_offer_excluded_customers } from '@/services/offfers/queries';
import { ICustomer } from '@/services/users/types';
import { useFilterStore } from '@/store/zustand';
import getErrorMessage from '@/utils/getError';
import isAxiosError from '@/utils/isAxiosError';
import { useQuery } from '@tanstack/react-query';
import {
  Button,
  Card,
  Empty,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  PageHeader,
  Spin
} from 'antd';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface FormValues {
  customer: number;
  customers: { id: number; name: string; phone: string }[];
}

function OfferExcludedCustomerUpdate() {
  const navigate = useNavigate();
  const [form] = Form.useForm<FormValues>();
  const customers = Form.useWatch('customers', form);

  const [isVisible, setIsVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const customerListRef = useRef<HTMLDivElement | null>(null);
  const zustandFilter = useFilterStore();

  useQuery(['offer-excluded-list'], async function () {
    try {
      setIsLoading(true);
      const excludedCustomers = await get_offer_excluded_customers();
      const updatedCustomer = await Promise.all(
        excludedCustomers.map(async (customer) => {
          const detail = await getCustomer(customer.customerId);
          return {
            id: customer.customerId,
            name: detail.user.name,
            phone: detail.user.phone
          };
        })
      );

      form.setFieldsValue({ customers: updatedCustomer });
    } catch (error) {
      message.error(getErrorMessage(error));
    } finally {
      setIsLoading(false);
    }
  });

  async function onFinish(values: FormValues) {
    try {
      setIsLoading(true);
      setIsVisible(false);
      const customerIds = values.customers.map((value) => value.id);
      await update_offer_excluded_discount_mutation(customerIds);
      zustandFilter.resetState(ListPage.OFFER_EXCLUDED_CUSTOMERS);
      message.success('Selected customers have been excluded from offer.');
      navigate('/offer/excluded-customers');
    } catch (error) {
      if (isAxiosError(error)) return;
    } finally {
      setIsLoading(false);
    }
  }

  function onCustomerChange(customer?: ICustomer) {
    if (!customer) return;
    // Check if user is duplicate
    const currentCustomers = (form.getFieldValue('customers') as FormValues['customers']) || [];
    const isDuplicate = currentCustomers.find((c) => c.id === customer.id);

    if (isDuplicate) {
      CustomErrorModal({ message: `Customer ${customer.user.name} is already added` });
      return;
    }

    const customers = [
      ...currentCustomers,
      { id: customer.id, name: customer.user.name, phone: customer.user.phone }
    ];

    form.setFieldsValue({ customers: customers });

    // Scroll to bottom
    if (customerListRef.current) {
      setTimeout(() => {
        customerListRef.current?.scroll({
          top: customerListRef.current.scrollHeight,
          behavior: 'smooth'
        });
      }, 0);
    }
  }

  return (
    <Spin spinning={isLoading}>
      <Modal
        visible={isVisible}
        onCancel={() => setIsVisible(false)}
        onOk={() => {
          form.submit();
        }}>
        <p>Are you sure you want to exclude selected customers from offer?</p>
      </Modal>
      <AppContent
        breadcrumbItems={[
          { label: 'Offer', link: '/offer/discount' },
          { label: 'Excluded Customers', link: '/offer/excluded-customers' },
          { label: 'Update' }
        ]}
        withfilter={false}>
        <Form
          form={form}
          onFinish={onFinish}
          disabled={isLoading}
          layout="vertical"
          validateTrigger={'onChange'}
          autoComplete="off">
          <PageHeader
            title="Offer Excluded Customers"
            style={{ padding: '8px 0px' }}
            extra={
              <Button onClick={() => form.setFieldsValue({ customers: [] })}>Remove All</Button>
            }
          />

          <CustomerSearchV2 hasParentFormItem={false} name="user" setSelected={onCustomerChange} />

          <Card
            style={{ maxHeight: '50vh', overflowY: 'scroll', borderRadius: '9px' }}
            ref={customerListRef}>
            {customers?.length === 0 && (
              <div className="flex flex-col items-center justify-center h-full">
                <Empty description="No customers added. Add customers to exclude them from offers." />
              </div>
            )}

            <Form.List name={['customers']}>
              {(fields, { remove }) =>
                fields.map((field, index) => (
                  <div key={field.key} className="flex gap-2 items-center">
                    <span className="font-bold text-sm mb-5">{index + 1}.</span>
                    <div
                      className="card  grid grid-cols-3 gap-3"
                      style={{ borderRadius: '9px', backgroundColor: 'white' }}>
                      <Form.Item name={[field.name, 'id']} hidden>
                        <InputNumber controls={false} disabled />
                      </Form.Item>

                      <Form.Item name={[field.name, 'name']} label={'User'}>
                        <Input style={{ color: 'black' }} disabled />
                      </Form.Item>

                      <Form.Item name={[field.name, 'phone']} label={'Phone No.'}>
                        <InputNumber controls={false} style={{ color: 'black' }} disabled />
                      </Form.Item>

                      <div className="flex items-center justify-start mt-5">
                        <CustomButton
                          backgroundColor="white"
                          text="Remove"
                          textColor="green"
                          onClick={async () => {
                            remove(field.name);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                ))
              }
            </Form.List>
          </Card>

          <div className="flex justify-end mt-5">
            <Button
              type="primary"
              loading={isLoading}
              htmlType="button"
              onClick={() => setIsVisible(true)}>
              Submit
            </Button>

            <Button
              type="default"
              className="ml-5"
              htmlType="button"
              onClick={() => navigate('/offer/excluded-customers')}>
              Cancel
            </Button>
          </div>
        </Form>
      </AppContent>
    </Spin>
  );
}

export default OfferExcludedCustomerUpdate;
