import { useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Form, Input, Button, PageHeader, message, Select } from 'antd';
import AppContent from '../../../components/Common/Content/Content';
import { create_price_group_mutation } from '../../../services/products/mutations';
import { get_location_list, get_location_list_for_ids } from '../../../services/locations/queries';
import CustomSubmitButton from '../../../components/Common/CustomButton/CustomSubmitButton';
import { checkAccess } from '../../../routes/acl';
import { LocationSearch } from '../../../components/Common/LocationSearch/LocationSearch';
import { get_price_groups_by_location } from '../../../services/products/queries';
import LocationsDB from '../../../store/localstorage/LocationsDB';
import { LocationType } from '../../../services/locations/enum';
import { useFilterStore } from '@/store/zustand';
import { ListPage } from '@/constants/list.enum';
const { Option } = Select;

const PriceGroupCreate = () => {
  const [form] = Form.useForm();
  const [isLoading, setIsloading] = useState<boolean>(false);
  const [isOutlet, setIsOutlet] = useState<boolean>(false);
  const { data: locationsList } = useQuery(['locations'], async () => get_location_list());

  const breadcrumbItems = [
    {
      label: 'Products',
      link: '/products'
    },
    {
      label: 'Price Group Create',
      link: '/products/price-groups-new'
    }
  ];

  const zustandFilter = useFilterStore();

  const createMutation = useMutation(create_price_group_mutation, {
    onSuccess: () => {
      message.success('Price Group added successfully');
      zustandFilter.resetState(ListPage.PRICE_GROUP);
      setIsloading(false);
    },
    onError: () => {
      setIsloading(false);
    }
  });

  const onLocationChange = async (locationId: number) => {
    if (locationId) {
      let locationDetails = await LocationsDB.getLocation(locationId);
      if (!locationDetails) {
        const locations = await get_location_list_for_ids([locationId]);
        await LocationsDB.addLocations(locations);
        locationDetails = await LocationsDB.getLocation(locationId);
      }
      if (locationDetails.type === LocationType.Outlet) {
        const response = await get_price_groups_by_location(locationId);
        if (response.data.length > 0) {
          setIsOutlet(true);
          message.error('Outlet cannot have more than one price group!');
        } else {
          setIsOutlet(false);
        }
      } else {
        setIsOutlet(false);
      }
    } else {
      message.error('Location not found!');
    }
  };

  const onFinish = (values: any) => {
    setIsloading(true);
    // console.log(values);
    createMutation.mutate(values);
  };

  return (
    <div>
      <AppContent breadcrumbItems={breadcrumbItems}>
        <Form
          form={form}
          onFinish={onFinish}
          disabled={isLoading}
          layout="vertical"
          validateTrigger={'onChange'}
          autoComplete="off">
          <PageHeader
            title="New Price Group"
            style={{
              padding: '8px 0px'
            }}
          />

          <div className={'grid grid-cols-3 gap-5 mb-5'}>
            <LocationSearch onSelect={onLocationChange} notAll={true} />
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: 'Please add name!' }]}>
              <Input />
            </Form.Item>
          </div>
          {checkAccess('CREATE_PRICE_GROUP') && (
            <div className="flex justify-end mt-5">
              <Form.Item>
                <CustomSubmitButton disabled={isOutlet} text="Submit" />
              </Form.Item>
            </div>
          )}
        </Form>
      </AppContent>
    </div>
  );
};

export default PriceGroupCreate;
