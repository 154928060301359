import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';

import { ICustomerReportType } from '@/services/report/types';
import TableCell from '@/components/Common/CustomizeTable/CustomCell';
import { getExportTableColumns } from './column.export';
import CustomizeTable from '@/components/Common/CustomizeTable/CustomizeTable';
import ExportCurrent from '@/components/Common/ExportCurrent';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';

interface Props {
  data: ICustomerReportType[];
}

function CustomerTable({ data }: Props) {
  // Extracting unique date keys and sorting them in ascending order
  const total = {} as { [key: string]: number };

  // Create a hash map of unique date keys and their total values
  const dateValues = data.reduce((acc, customer) => {
    for (const date in customer) {
      if (!['customerId', 'customerName', 'customerPhone'].includes(date)) {
        acc[date] = true;
        total[date] = (total[date] || 0) + (customer[date] as number);
      }
    }
    return acc;
  }, {} as { [key: string]: boolean });

  // Extracting unique date keys and sorting them in ascending order
  const dateKeys = Object.keys(dateValues).sort();

  // Defining columns for the table
  const columns: ColumnsType<ICustomerReportType> = [
    {
      title: 'SN',
      dataIndex: 'sn',
      key: 'sn',
      width: 50,
      render: (_, __, index) => <TableCell className="p-4">{index + 1}</TableCell>
    },
    {
      title: 'Name',
      width: 150,
      key: 'customerName',
      dataIndex: 'customerName',
      render: (value: string) => <TableCell className="px-2">{value}</TableCell>
    },
    {
      title: 'Phone',
      width: 120,
      key: 'customerPhone',
      dataIndex: 'customerPhone',
      render: (value: string) => <TableCell className="px-2">{value}</TableCell>
    },

    // Dynamically creating columns for each date
    ...dateKeys.map((dateKey) => ({
      title: dateKey,
      dataIndex: dateKey,
      key: dateKey,
      width: 80,
      className: 'text-center',
      render: (value: number | undefined) => (value ? nepaliNumberFormatter(value) : '-')
    }))
  ];

  const tableExportColumns = getExportTableColumns(data, 'customer');
  const excelExportColumns = tableExportColumns.map((column) => ({
    title: column.label,
    dataIndex: column.dataIndex,
    isNumber: column?.isNumber
  }));

  return (
    <CustomizeTable
      columns={columns}
      data={data}
      tableName="customer-suppliers-customer-table-report"
      customScroll={{ x: 'max-content', y: 450 }}
      notshowPagination
      tableSummary={
        <Table.Summary.Row>
          <Table.Summary.Cell index={0} colSpan={3}>
            Total
          </Table.Summary.Cell>
          {Object.keys(total)
            .sort()
            .map((dateKey, index) => (
              <Table.Summary.Cell
                key={`${dateKey}-footer`}
                index={index + 2}
                className="text-center">
                {total[dateKey]}
              </Table.Summary.Cell>
            ))}
        </Table.Summary.Row>
      }
      buttons={
        <ExportCurrent
          data={{ total: data.length, results: data }}
          columns={tableExportColumns}
          excelColumns={excelExportColumns}
          title="Customer Suppliers Report (Customer)"
        />
      }
    />
  );
}

export default CustomerTable;
