import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import dayjs from 'dayjs';

import { message } from 'antd';
import { FinancialYearDB } from './../../localstorage/FinancialYearDB';
import { FinancialYearState } from './types';
import { FINANCIAL_YEAR_PERSIST } from '@/constants/config';
import { FYAction } from './enum';
import { calculateDateRange, checkWithinRange } from './services';
import { useEffect, useState } from 'react';
import eventEmitter from '@/utils/events';

// Define Zustand store with persistence
export const useFinancialYearStore = create<FinancialYearState>()(
  devtools(
    persist(
      (set, get) => ({
        isLoading: false,
        financialYearList: [],
        currentFinancialYear: undefined,
        selectedFinancialYear: undefined,
        defaultRange: [
          dayjs().startOf('day').toISOString(),
          dayjs().endOf('day').toISOString()
        ] as [string, string],
        defaultSingleDate: dayjs().toISOString(),

        refreshFinancialYears(years) {
          set({ financialYearList: years }, undefined, {
            type: FYAction.REFRESH_FINANCIAL_YEARS,
            payload: years
          });
        },

        syncToday() {
          const currentState = get();
          const { range, singleDate } = calculateDateRange(currentState.selectedFinancialYear);
          set({ defaultRange: range, defaultSingleDate: singleDate }, undefined, {
            type: FYAction.SYNC_TODAY,
            payload: { defaultRange: range, defaultSingleDate: singleDate }
          });
        },

        // Fetch financial years from IndexedDB
        getFinancialYears: async () => {
          set({ isLoading: true }, undefined, {
            type: FYAction.IS_HYDRATING,
            payload: true
          });

          try {
            const years = await FinancialYearDB.getAll();
            const currentFinancialYear = await FinancialYearDB.getCurrent();

            if (!years || years.length === 0) {
              message.warning('No financial years found. Please sync your data.');
              set({ isLoading: false }, undefined, {
                type: FYAction.IS_HYDRATING,
                payload: false
              });
              return;
            }

            const formattedYears = years
              .map((year) => ({
                ...year,
                startDate: dayjs(year.startDate).format('YYYY-MM-DD'),
                endDate: dayjs(year.endDate).format('YYYY-MM-DD')
              }))
              .sort((a, b) => dayjs(b.startDate).diff(dayjs(a.startDate))); // Sort by start date

            const selectedFinancialYear = formattedYears.find((year) => year.isCurrent);
            const { range, singleDate } = calculateDateRange(selectedFinancialYear);

            const payload: Partial<FinancialYearState> = {
              financialYearList: formattedYears,
              currentFinancialYear,
              selectedFinancialYear,
              isLoading: false,
              defaultRange: range,
              defaultSingleDate: singleDate
            };

            set(payload, undefined, { type: FYAction.GET_FINANCIAL_YEARS, payload });
          } catch (error) {
            message.error('Failed to fetch financial years.');
            set({ isLoading: false }, undefined, { type: FYAction.IS_HYDRATING, payload: false });
          }
        },

        // Check if a given date range is within the selected financial year
        isWithinFinancialYear: (startDate, endDate) => {
          const { selectedFinancialYear } = get();
          return checkWithinRange({ startDate, endDate, range: selectedFinancialYear });
        },

        // Handle changing the selected financial year
        handleFinancialYearChange: (selectedYear) => {
          const { financialYearList, selectedFinancialYear } = get();
          const year = financialYearList.find((year) => year.name === selectedYear);

          if (!year) {
            message.error('We could not find the selected year. Please sync data.');
            return;
          }

          // Avoid unnecessary updates
          if (selectedFinancialYear?.name === year.name) return;
          const { range, singleDate } = calculateDateRange(year);

          const payload = {
            selectedFinancialYear: year,
            defaultRange: range,
            defaultSingleDate: singleDate
          };

          set(payload, undefined, { type: FYAction.HANDLE_FINANCIAL_YEAR_CHANGE, payload });
          eventEmitter.emit('FINANCIAL_YEAR_CHANGE', payload);
        },

        reset: () => {
          const payload: Partial<FinancialYearState> = {
            financialYearList: [],
            currentFinancialYear: undefined,
            selectedFinancialYear: undefined,
            defaultRange: ['', ''],
            defaultSingleDate: ''
          };

          set(payload, undefined, { type: FYAction.RESET_STORE, payload });
          useFinancialYearStore.persist.clearStorage();
        }
      }),
      {
        name: FINANCIAL_YEAR_PERSIST,
        partialize: (state) => ({
          financialYearList: state.financialYearList,
          currentFinancialYear: state.currentFinancialYear,
          selectedFinancialYear: state.selectedFinancialYear,
          defaultRange: state.defaultRange,
          defaultSingleDate: state.defaultSingleDate
        })
      }
    ),
    {
      name: FINANCIAL_YEAR_PERSIST,
      enabled: process.env.NODE_ENV === 'development'
    }
  )
);

export const useFinancialYearPersistHydration = () => {
  const [isHydrated, setIsHydrated] = useState(() => {
    return useFinancialYearStore.persist.hasHydrated();
  });

  useEffect(() => {
    // Subscribe to the completion of hydration
    const unsub = useFinancialYearStore.persist.onFinishHydration(() => {
      setIsHydrated(true);
    });

    return unsub;
  }, []);

  return isHydrated;
};
