import dayjs from 'dayjs';
import moment from 'moment';
import { IFinancialYear } from '@/services/financial-year/types';
import { message } from 'antd';
import { convert_string_to_nepali_date_string } from '@/utils/nepaliDateConverter';
import { find_date_preference } from '@/store/localstorage/preferences';
import { convertLocalToUTCString } from '@/utils/convertToUTC';

export function checkWithinRange(options: {
  startDate: string;
  endDate: string;
  range?: IFinancialYear;
}) {
  const { startDate, endDate, range } = options;
  if (!startDate || !endDate) {
    message.error('Invalid date range.');
    return false;
  }

  const selectedStartDate = dayjs(startDate);
  const selectedEndDate = dayjs(endDate);

  if (selectedStartDate.isAfter(selectedEndDate)) {
    message.error('Start date cannot be after end date.');
    return false;
  }

  if (!range) {
    message.error('Please select a financial year');
    return false;
  }

  const rangeStart = dayjs(range.startDate).startOf('day');
  const rangeEnd = dayjs(range.endDate).endOf('day');

  return selectedStartDate.isSameOrAfter(rangeStart) && selectedEndDate.isSameOrBefore(rangeEnd);
}

export const calculateDateRange = (financialYear?: IFinancialYear) => {
  const date = financialYear
    ? financialYear.isCurrent
      ? dayjs()
      : dayjs(financialYear.endDate)
    : dayjs();

  return {
    singleDate: date.toISOString(),
    range: [date.startOf('day').toISOString(), date.endOf('day').toISOString()] as [string, string]
  };
};

export function formatDateRange(startDate: string, endDate: string) {
  const momentRange = [moment(startDate), moment(endDate)] as [moment.Moment, moment.Moment];

  const nepaliDateStart = convert_string_to_nepali_date_string(momentRange[0].format('YYYY-MM-DD'));
  const nepaliDateEnd = convert_string_to_nepali_date_string(momentRange[1].format('YYYY-MM-DD'));
  return {
    dateCustom: momentRange,
    startDateNepali: nepaliDateStart,
    endDateNepali: nepaliDateEnd,
    startDate: convertLocalToUTCString(momentRange[0].clone()),
    endDate: convertLocalToUTCString(momentRange[1].clone())
  };
}

export function formatDateToForm(rangeDate: [string, string], singleDate: string) {
  const momentSingleDate = moment(singleDate);
  const momentRange = rangeDate.map((date) => moment(date)) as [moment.Moment, moment.Moment];

  const nepaliDate = convert_string_to_nepali_date_string(momentSingleDate.format());
  const nepaliDateStart = convert_string_to_nepali_date_string(momentRange[0].format('YYYY-MM-DD'));
  const nepaliDateEnd = convert_string_to_nepali_date_string(momentRange[1].format('YYYY-MM-DD'));

  const formDateRange = {
    dateCustom: momentRange,
    startDateNepali: nepaliDateStart,
    endDateNepali: nepaliDateEnd,
    startDate: convertLocalToUTCString(rangeDate[0]),
    endDate: convertLocalToUTCString(rangeDate[1])
  };

  const date = find_date_preference() ? nepaliDate : momentSingleDate;

  const formSingleDate = {
    dateSingle: date,
    date: convertLocalToUTCString(singleDate)
  };

  return { formDateRange, formSingleDate };
}
