import { Button, Form, Input, Menu, Spin, TableProps, Tag, Tooltip, message } from 'antd';
import { ColumnsType, SorterResult } from 'antd/lib/table/interface';
import { useRef, useState } from 'react';
import { getUserData } from '@/utils/auth.utils';
import { ConvertObjectToURL } from '@/utils/converturl';
import TableCell from '@/components/Common/CustomizeTable/CustomCell';
import TableFilter from '@/components/FliterTable';
import CustomizeTable from '@/components/Common/CustomizeTable/CustomizeTable';
import AppContent from '@/components/Common/Content/Content';
import moment from 'moment';

import { get_previous_lots_list_filter, get_product_list_ids } from '@/services/products/queries';
import { IProductPreviousLots, IProductPreviousLotsResponse } from '@/services/products/types';
import LocationsDB from '@/store/localstorage/LocationsDB';
import { get_location_list_for_ids } from '@/services/locations/queries';
import ProductsDB from '@/store/localstorage/ProductsDB';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';
import ActionDropdown from '@/components/Common/Dropdownactions';
import { ExportColumnType } from '@/utils/exportExcel';
import { Excel } from 'antd-table-saveas-excel';
import { CustomModal } from '@/components/Common/CustomModal';
import CustomTable from '@/components/Common/CustomResuableInvoice/CustomTable';
import { useReactToPrint } from 'react-to-print';
import ProductSearchV2 from '@/components/Common/CustomSearch/Products';
import LocationSearchV2 from '@/components/Common/CustomSearch/Location';
import { DEFAULT_DATE_FORMAT } from '@/constants';

const PreviousLotsList = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [form] = Form.useForm();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(100);
  const [previousLotsList, setPreviousLotsList] = useState<IProductPreviousLotsResponse>({
    count: 0,
    results: []
  });
  const printPDFRef = useRef<any>();
  const [productInfromation, setproductInformation] = useState<any>();
  const [openModalForPdfExport, setOpenModalForPdfExport] = useState<boolean>(false);
  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({});
  const handleChange: TableProps<any>['onChange'] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<any>);
  };

  const locationId = Form.useWatch(['locationId'], form);

  const breadcrumbItems = [
    {
      label: 'Previous Lots',
      link: '/products/previous-lots'
    }
  ];

  const { preferences } = getUserData();
  const preferenceLocationId = preferences?.preferences
    ? JSON.parse(preferences?.preferences)?.locationId
    : undefined;

  const onSubmitFilter = async () => {
    const values = form.getFieldsValue();
    delete values.ids;
    const val = ConvertObjectToURL(values);
    const data = await getInfo(val);
    setPage(1);
    setSize(100);
    return data;
  };

  const getInfo = async (filter = '') => {
    try {
      const ids = form.getFieldValue(['ids']);
      const productIds = [ids];
      if (!ids) {
        throw {
          name: 'ProductError',
          message: 'Please select atleast one product!'
        };
      }
      setIsLoading(true);
      const response = await get_previous_lots_list_filter(filter, productIds);
      for (let i = 0; i < response.results.length; i++) {
        if (response.results[i].locationId) {
          let locationDetails = await LocationsDB.getLocation(response.results[i].locationId);
          if (!locationDetails) {
            const allLocations = await get_location_list_for_ids([
              ...new Set(
                response.results
                  .filter((val) => val.locationId !== null)
                  .map((value) => {
                    return value.locationId;
                  })
              )
            ]);
            await LocationsDB.addLocations(allLocations);
            locationDetails = await LocationsDB.getLocation(response.results[i].locationId);
          }
          response.results[i].locationName = locationDetails.name;
        }

        if (response.results[i].productId) {
          let productDetails = await ProductsDB.getProduct(response.results[i].productId);
          if (!productDetails) {
            const allProducts = await get_product_list_ids([
              ...new Set(
                response.results
                  .filter((val) => val.productId !== null)
                  .map((value) => {
                    return value.productId;
                  })
              )
            ]);
            await ProductsDB.addProducts(allProducts.data.results);
            productDetails = await ProductsDB.getProduct(response.results[i].productId);
          }
          response.results[i].productName =
            typeof productDetails === 'object' ? productDetails.name : '';
        }
      }

      setPreviousLotsList(response);
      setIsLoading(false);
      return response;
    } catch (err: any) {
      setIsLoading(false);
      message.error(err.message);
    }
  };

  const exportData = previousLotsList.results.map((item, index) => {
    return {
      ...item,
      sn: index + 1,
      isTraceable: item.traceable ? 'Yes' : 'No'
    };
  });

  const onPagination = async (pageNo = 1, totalSize = 100, isSize = false) => {
    setIsLoading(true);
    const values = form.getFieldsValue();
    delete values.ids;
    if (isSize) {
      values.skip = 0;
      values.count = totalSize;
      setPage(1);
      setSize(totalSize);
    } else {
      values.skip = (pageNo - 1) * totalSize;
      values.count = totalSize;
      setPage(pageNo);
    }
    const url = ConvertObjectToURL(values);
    return await getInfo(url);
  };

  const columns: ColumnsType<IProductPreviousLots> = [
    {
      title: 'S.N',
      key: 'SN',
      width: 5,
      render: (txt, record, index) => {
        return <TableCell>{(page - 1) * size + (index + 1)}</TableCell>;
      }
    },
    {
      title: 'Product Name',
      key: 'productName',
      width: 15,
      sorter: (a, b) =>
        a.productName && b.productName ? a.productName.localeCompare(b.productName) : 0,
      sortOrder: sortedInfo.columnKey === 'productName' ? sortedInfo.order : null,
      render: (a, record) => {
        return (
          <>
            <TableCell>{record.productName}</TableCell>
          </>
        );
      }
    },
    {
      title: 'Lot Number',
      key: 'lotNumber',
      width: 20,
      sorter: (a, b) => a.lotNumber.localeCompare(b.lotNumber),
      sortOrder: sortedInfo.columnKey === 'lotNumber' ? sortedInfo.order : null,
      render: (a, record) => {
        return (
          <>
            <TableCell>{record.lotNumber}</TableCell>
          </>
        );
      }
    },
    {
      title: 'Grade',
      key: 'grade',
      width: 10,
      sorter: (a, b) => a.grade.localeCompare(b.grade),
      sortOrder: sortedInfo.columnKey === 'grade' ? sortedInfo.order : null,
      render: (a, record) => {
        return (
          <>
            <TableCell>{record.grade}</TableCell>
          </>
        );
      }
    },

    {
      title: 'Location',
      key: 'locationName',
      width: 15,
      sorter: (a, b) =>
        a.locationName && b.locationName ? a.locationName.localeCompare(b.locationName) : 0,
      sortOrder: sortedInfo.columnKey === 'locationName' ? sortedInfo.order : null,
      render: (a, record) => {
        return (
          <>
            <TableCell>{record.locationName}</TableCell>
          </>
        );
      }
    },
    {
      title: 'Price Avg',
      key: 'purchasePriceAvg',
      width: 10,
      sorter: (a, b) => a.purchasePriceAvg - b.purchasePriceAvg,
      sortOrder: sortedInfo.columnKey === 'purchasePriceAvg' ? sortedInfo.order : null,
      render: (a, record) => {
        return (
          <>
            <TableCell>{nepaliNumberFormatter(record.purchasePriceAvg)}</TableCell>
          </>
        );
      }
    },
    {
      title: 'Available (qty.)',
      key: 'qtyAvailable',
      width: 15,
      sorter: (a, b) => a.qtyAvailable - b.qtyAvailable,
      sortOrder: sortedInfo.columnKey === 'qtyAvailable' ? sortedInfo.order : null,
      render: (a, record) => {
        return (
          <>
            <TableCell>{nepaliNumberFormatter(record.qtyAvailable)}</TableCell>
          </>
        );
      }
    },
    {
      title: 'Sold (qty.)',
      key: 'qtySold',
      width: 15,
      sorter: (a, b) => a.qtySold - b.qtySold,
      sortOrder: sortedInfo.columnKey === 'qtySold' ? sortedInfo.order : null,
      render: (a, record) => {
        return (
          <>
            <TableCell>{nepaliNumberFormatter(record.qtySold)}</TableCell>
          </>
        );
      }
    },
    {
      title: 'Received (qty.)',
      key: 'qtyReceived',
      width: 15,
      sorter: (a, b) => a.qtyReceived - b.qtyReceived,
      sortOrder: sortedInfo.columnKey === 'qtyReceived' ? sortedInfo.order : null,
      render: (a, record) => {
        return (
          <>
            <TableCell>{nepaliNumberFormatter(record.qtyReceived)}</TableCell>
          </>
        );
      }
    },
    {
      title: 'Opening (qty.)',
      key: 'qtyOpening',
      width: 15,
      sorter: (a, b) => a.qtyOpening - b.qtyOpening,
      sortOrder: sortedInfo.columnKey === 'qtyOpening' ? sortedInfo.order : null,
      render: (a, record) => {
        return (
          <>
            <TableCell>{nepaliNumberFormatter(record.qtyOpening)}</TableCell>
          </>
        );
      }
    },
    {
      title: 'Closing (qty.)',
      key: 'qtyClosing',
      width: 15,
      sorter: (a, b) => a.qtyClosing - b.qtyClosing,
      sortOrder: sortedInfo.columnKey === 'qtyClosing' ? sortedInfo.order : null,
      render: (a, record) => {
        return (
          <>
            <TableCell>{nepaliNumberFormatter(record.qtyClosing)}</TableCell>
          </>
        );
      }
    },
    {
      title: 'Is Tracable',
      key: 'traceable',
      width: 10,
      render: (a, record) => {
        return (
          <TableCell data-boolean={record.traceable}>{record.traceable ? 'Yes' : 'No'}</TableCell>
        );
      }
    },
    {
      title: 'Lot Status',
      key: 'lotStatus',
      width: 15,
      sorter: (a, b) => a.lotStatus.localeCompare(b.lotStatus),
      sortOrder: sortedInfo.columnKey === 'lotStatus' ? sortedInfo.order : null,
      render: (a, record) => {
        return (
          <Tag color={record.lotStatus === 'CLOSED' ? 'red' : 'green'}>{record.lotStatus}</Tag>
        );
      }
    },
    {
      title: 'Created At',
      key: 'created_at',
      sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
      sortOrder: sortedInfo.columnKey === 'created_at' ? sortedInfo.order : null,
      dataIndex: 'createdAt',
      render: (createdAt) => {
        return (
          <div className="text-right mr-2">{moment(createdAt).format(DEFAULT_DATE_FORMAT)}</div>
        );
      },
      width: 15
    }
  ];

  const columsforPrint = [
    {
      label: 'Product Name',
      dataIndex: 'productName',
      width: 200,
      render: (text: string) => {
        return <TableCell>{text}</TableCell>;
      }
    },
    {
      label: 'Lot Number',
      dataIndex: 'lotNumber',
      width: 220,
      render: (text: string) => {
        return <TableCell>{text}</TableCell>;
      }
    },
    {
      label: 'Grade',
      dataIndex: 'grade',
      width: 100,
      render: (text: string) => {
        return <TableCell>{text}</TableCell>;
      }
    },
    {
      label: 'Lot Status',
      dataIndex: 'lotStatus',
      width: 150,
      render: (text: string) => {
        return <TableCell>{text}</TableCell>;
      }
    },
    {
      label: 'Location',
      dataIndex: 'locationName',
      width: 200,
      render: (text: string) => {
        return <TableCell>{text}</TableCell>;
      }
    },
    {
      label: 'Price Avg',
      dataIndex: 'purchasePriceAvg',
      width: 100,
      render: (text: number) => {
        return <TableCell>{text}</TableCell>;
      }
    },
    {
      label: 'Available (qty.)',
      dataIndex: 'qtyAvailable',
      width: 150,
      render: (text: number) => {
        return <TableCell>{text}</TableCell>;
      }
    },
    {
      label: 'Sold (qty.)',
      dataIndex: 'qtySold',
      width: 150,
      render: (text: number) => {
        return <TableCell>{text}</TableCell>;
      }
    },
    {
      label: 'Received (qty.)',
      dataIndex: 'qtyReceived',
      width: 150,
      render: (text: number) => {
        return <TableCell>{text}</TableCell>;
      }
    },
    {
      label: 'Opening (qty.)',
      dataIndex: 'qtyOpening',
      width: 150,
      render: (text: number) => {
        return <TableCell>{text}</TableCell>;
      }
    },
    {
      label: 'Closing (qty.)',
      dataIndex: 'qtyClosing',
      width: 150,
      render: (text: number) => {
        return <TableCell>{text}</TableCell>;
      }
    },
    {
      label: 'Is Tracable',
      dataIndex: 'isTraceable',
      width: 100,
      render: (text: string) => {
        return <TableCell>{text}</TableCell>;
      }
    }
  ];

  const handleExcelExport = () => {
    setIsLoading(true);
    try {
      const excelColumns: ExportColumnType[] = [
        {
          title: 'S.N',
          dataIndex: 'sn',
          width: 100
        },
        {
          title: 'Product Name',
          dataIndex: 'productName',
          width: 200
        },
        {
          title: 'Lot Number',
          dataIndex: 'lotNumber',
          width: 220
        },
        {
          title: 'Grade',
          dataIndex: 'grade',
          width: 100
        },
        {
          title: 'Lot Status',
          dataIndex: 'lotStatus',
          width: 150
        },
        {
          title: 'Location',
          dataIndex: 'locationName',
          width: 200
        },
        {
          title: 'Price Avg',
          dataIndex: 'purchasePriceAvg',
          width: 100
        },
        {
          title: 'Available (qty.)',
          dataIndex: 'qtyAvailable',
          width: 150
        },
        {
          title: 'Sold (qty.)',
          dataIndex: 'qtySold',
          width: 150
        },
        {
          title: 'Received (qty.)',
          dataIndex: 'qtyReceived',
          width: 150
        },
        {
          title: 'Opening (qty.)',
          dataIndex: 'qtyOpening',
          width: 150
        },
        {
          title: 'Closing (qty.)',
          dataIndex: 'qtyClosing',
          width: 150
        },
        {
          title: 'Is Tracable',
          dataIndex: 'isTraceable',
          width: 100
        }
      ];

      if (previousLotsList.results.length === 0) {
        message.error('No Data to Export');
        setIsLoading(false);
        return;
      }

      // Export Excel
      const excel = new Excel();
      excel.setTHeadStyle({
        background: 'ffffffff'
      });
      excel
        .setTHeadStyle({
          fontSize: 10
        })
        .setTBodyStyle({
          fontSize: 10
        })
        .addSheet('Previous Lots')
        .addColumns(excelColumns as any[])
        .addDataSource(exportData, {
          str2Percent: true
        })
        .saveAs('Previous Lots.xlsx');
      setIsLoading(false);
    } catch (err: any) {
      setIsLoading(false);
      console.log(err);
    }
  };

  const handlePDFExport = useReactToPrint({
    content: () => printPDFRef.current
  });

  return (
    <Spin spinning={isLoading}>
      <CustomModal
        footer={false}
        isModalOpen={openModalForPdfExport}
        setIsModalOpen={setOpenModalForPdfExport}
        title="PDF print">
        <div className="flex justify-end mb-3">
          <Button type="primary" onClick={handlePDFExport}>
            Print Pdf
          </Button>
        </div>
        <div style={{ maxHeight: '80vh', overflow: 'scroll' }}>
          <CustomTable
            columns={columsforPrint}
            datas={exportData}
            reff={printPDFRef}
            title={'Previous Lots'}
          />
        </div>
      </CustomModal>
      <AppContent
        breadcrumbItems={breadcrumbItems}
        withfilter={false}
        button={
          <>
            <div>
              <TableFilter
                onInitialLoad={({ data, pagination }) => {
                  setPage(pagination.page);
                  setSize(pagination.size);

                  if (data) {
                    setPreviousLotsList(data);
                    setIsLoading(false);
                  }
                }}
                defaultValues={{
                  dateCustom: [moment(0, 'HH'), moment(0, 'HH').add(1, 'days')],
                  value: '',
                  skip: 0,
                  count: 100,
                  productId: null,
                  locationId: preferenceLocationId ? preferenceLocationId : 1
                }}
                initial={true}
                onSubmit={onSubmitFilter}
                form={form}
                style={
                  'grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3  xl:grid-cols-4 gap-2 items-center justify-center'
                }
                styleforbuttons={'flex justify-end items-center'}>
                <LocationSearchV2 hasParentFormItem={false} name={'locationId'} showAll />
                <ProductSearchV2
                  hasParentFormItem={false}
                  name={'ids'}
                  locationId={locationId}
                  onSelect={(val) => form.setFieldValue(['ids'], val)}
                  setSelected={(value) => setproductInformation(value)}
                />
                <Form.Item name="value" label="Search">
                  <Input placeholder="Search" />
                </Form.Item>
              </TableFilter>
            </div>
            {/* </div> */}
          </>
        }>
        <div className="flex justify-end mb-2">
          <div>
            <ActionDropdown
              button={true}
              menu={
                <Menu
                  items={[
                    {
                      key: '1',
                      label: (
                        <Tooltip title="Export Excel" color="blue">
                          <div className="text-center">Excel</div>
                        </Tooltip>
                      ),
                      onClick: () => {
                        handleExcelExport();
                      }
                    },
                    {
                      key: '2',
                      label: (
                        <Tooltip title="Export PDF" color="blue">
                          <div className="text-center">PDF</div>
                        </Tooltip>
                      ),
                      onClick: () => {
                        try {
                          if (previousLotsList.results.length === 0) {
                            message.error('No Data to Export');
                            setIsLoading(false);
                            return;
                          }
                          setOpenModalForPdfExport(true);
                        } catch (err: any) {
                          console.log(err);
                        }
                      }
                    }
                  ]}
                />
              }
            />
          </div>
        </div>
        <CustomizeTable
          form={form}
          columns={columns}
          data={previousLotsList.results}
          notshowPagination={true}
          customScroll={{ x: 1400, y: '75vh' }}
          paginationDatas={{
            page: page,
            total: previousLotsList.count,
            size: size,
            onPagination
          }}
          tableName={'products-previous-lots-list'}
          toSort={handleChange}
        />
      </AppContent>
    </Spin>
  );
};

export default PreviousLotsList;
