import React, { useState } from 'react';
import AppContent from '@/components/Common/Content/Content';
import TableFilter from '@/components/FliterTable';
import moment from 'moment';
import { Form, Input, Menu } from 'antd';
import CustomizeTable from '@/components/Common/CustomizeTable/CustomizeTable';
import { ColumnsType, TableProps } from 'antd/lib/table';
import { SorterResult } from 'antd/lib/table/interface';
import TableCell from '@/components/Common/CustomizeTable/CustomCell';
import { convertUTCStringtoLocalString } from '@/utils/convertToUTC';
import { ILogEvents, ILogEventsResult } from '@/services/logs/events/types';
import { get_log_events_list } from '@/services/logs/events/queries';
import { checkAccess } from '@/routes/acl';
import DiscussionChatModal from '@/components/Common/DiscussionChatModal';
import ActionDropdown from '@/components/Common/Dropdownactions';
import { ConvertObjectToURL } from '@/utils/converturl';
import CustomViewIcon from '@/components/Common/CustomIcons/CustomViewIcon';
import { DEFAULT_DATE_FORMAT } from '@/constants';

const LogEventList: React.FC = () => {
  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({});
  const [form] = Form.useForm();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(100);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [logEventList, setLogEventsList] = useState<ILogEventsResult>({ total: 0, results: [] });

  const getInfo = async (filter = '') => {
    setIsLoading(true);
    const logEventList = await get_log_events_list(filter);
    setLogEventsList(logEventList);
    setIsLoading(false);
    return logEventList;
  };

  const onSubmitFilter = async (val: string) => {
    const data = await getInfo(val);
    setPage(1);
    setSize(100);
    return data;
  };
  const breadcrumbItems = [
    {
      label: 'Events',
      link: '/log-events'
    }
  ];
  const columns: ColumnsType<ILogEvents> = [
    {
      title: 'S.N',
      key: 'SN',
      width: 1,
      sorter: (a, b) => a.id - b.id,
      sortOrder: sortedInfo.columnKey === 'id' ? sortedInfo.order : null,
      render: (txt, record, index) => {
        return <TableCell>{(page - 1) * size + (index + 1)}</TableCell>;
      }
    },
    {
      title: 'Date',
      key: 'createdAt',
      width: 3,
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      sortOrder: sortedInfo.columnKey === 'date' ? sortedInfo.order : null,
      render: (record) => {
        return (
          <>
            <TableCell>
              {convertUTCStringtoLocalString(record.createdAt, DEFAULT_DATE_FORMAT)}
            </TableCell>
          </>
        );
      }
    },

    {
      title: 'URL',
      key: 'url',
      width: 10,
      sorter: (a, b) => {
        return a.url.localeCompare(b.url);
      },
      sortOrder: sortedInfo.columnKey === 'url' ? sortedInfo.order : null,

      render: (record: ILogEvents) => {
        return <TableCell>{record.url}</TableCell>;
      }
    },

    {
      title: 'Actions',
      key: 'actions',
      width: 1,
      fixed: 'right',
      render: (record, arr, index) => {
        const menuItems: (
          | {
              key: string;
              label: JSX.Element;
              onClick?: undefined;
            }
          | {
              key: string;
              label: JSX.Element;
              onClick: () => void;
            }
        )[] = [];

        menuItems.push({
          key: '1',
          label: <CustomViewIcon link={`/log-events/view/${record.id}`} />
        });

        if (checkAccess('READ_CHANNEL')) {
          menuItems.push({
            key: '2',
            label: <DiscussionChatModal slug="logEvents" id={record.id} />
          });
        }

        const menu = <Menu items={menuItems} />;
        return <ActionDropdown menu={menu} />;
      }
    }
  ];
  const handleChange: TableProps<any>['onChange'] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<any>);
  };
  const onPagination = async (pageNo = 1, totalSize = 100, isSize = false) => {
    setIsLoading(true);

    const values = form.getFieldsValue();
    if (isSize) {
      values.skip = 0;
      values.count = totalSize;
      setPage(1);
      setSize(totalSize);
    } else {
      values.skip = (pageNo - 1) * totalSize;
      values.count = totalSize;
      setPage(pageNo);
    }
    const url = ConvertObjectToURL(values);
    return await getInfo(url);
  };

  return (
    <div>
      <AppContent
        breadcrumbItems={breadcrumbItems}
        button={
          <TableFilter
            onInitialLoad={({ data, pagination }) => {
              setPage(pagination.page);
              setSize(pagination.size);

              if (data) {
                setLogEventsList(data);
                setIsLoading(false);
              }
            }}
            defaultValues={{
              value: '',
              skip: 0,
              count: 100
            }}
            dateCustom={false}
            singleDate={false}
            initial={true}
            onSubmit={onSubmitFilter}
            form={form}
            styleforbuttons={'flex justify-end items-center'}
            style={
              'grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3  xl:grid-cols-4 gap-2 items-center justify-center'
            }
            buttons={<></>}>
            <Form.Item name="value" label="Events" style={{ width: 300 }}>
              <Input placeholder="Search" />
            </Form.Item>
          </TableFilter>
        }>
        <CustomizeTable
          form={form}
          columns={columns}
          data={logEventList.results}
          usersLoading={isLoading}
          notshowPagination={true}
          tableName={'logs-events-list'}
          customScroll={{ x: 500, y: '75vh' }}
          paginationDatas={{
            page: page,
            total: logEventList.total,
            size: size,
            onPagination
          }}
          toSort={handleChange}
        />
      </AppContent>
    </div>
  );
};

export default LogEventList;
