import { useState } from 'react';
import { Button, Modal, message } from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';
import GenericTable from '@/components/Common/CustomizeTable';
import CategoryDB from '@/store/localstorage/CategoryDB';
import { get_category_list_only } from '@/services/category/queries';
import { getImageFromServer } from '@/services/upload/queries';
import getErrorMessage from '@/utils/getError';
import { ICategory } from '@/services/category/types';
import { TableProps } from 'antd/es/table';
import { ColumnsType } from 'antd/lib/table';
import { SorterResult } from 'antd/lib/table/interface';
import { sync_woo_category } from '@/services/woocommerce/mutation';
import useCache from '@/hooks/useCache';
import PreviewImageOnDemand from '@/components/Common/PreviewImageOnDemand';

interface Props {
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

function SyncCategory({ setIsLoading }: Props) {
  const { get: getCacheMedias } = useCache(getImageFromServer);
  const [sortedInfo, setSortedInfo] = useState<SorterResult<ICategory>>({});

  // Handle Table Sorting
  const handleChange: TableProps<ICategory>['onChange'] = (_, __, s) => {
    setSortedInfo(s as SorterResult<ICategory>);
  };

  // Fetch Categories from ERP
  const { data: categories } = useQuery(['category'], async () => {
    try {
      setIsLoading(true);
      const categories = await get_category_list_only(0, 1000);
      CategoryDB.addCategory(categories);

      // Map Media URLs to Categories
      return categories;
    } catch (error) {
      getErrorMessage(error, true);
      return [];
    } finally {
      setIsLoading(false);
    }
  });

  const handleSyncToWooCommerce = async () => {
    const instance = Modal.confirm({
      title: 'Confirm Sync',
      content: 'Are you sure you want to sync categories to WooCommerce?',
      okText: 'Yes, Sync',
      cancelText: 'Cancel',

      onOk: async () => {
        instance?.destroy();
        setIsLoading(true);
        try {
          await sync_woo_category();
          message.success('Synced Successfully');
        } catch (error) {
          getErrorMessage(error, true);
        } finally {
          setIsLoading(false);
        }
      }
    });
  };

  // Define Table Columns
  const columns: ColumnsType<ICategory> = [
    {
      title: 'S.N',
      key: 'SN',
      width: 2,
      sorter: (a, b) => a.id - b.id,
      sortOrder: sortedInfo.columnKey === 'id' ? sortedInfo.order : null,
      render: (_, __, index) => index + 1
    },
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
      width: 8,
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null
    },
    {
      title: 'Medias',
      children: [
        {
          title: 'Banner',
          key: 'bannerURL',
          dataIndex: 'bannerURL',
          width: 4,
          render: (url, row) => (
            <PreviewImageOnDemand
              setParentLoading={setIsLoading}
              mediaId={row.bannerId}
              fetcher={getCacheMedias}
            />
          )
        },
        {
          title: 'Image',
          key: 'imgURL',
          dataIndex: 'imgURL',
          width: 4,
          render: (url, row) => (
            <PreviewImageOnDemand
              setParentLoading={setIsLoading}
              mediaId={row.imgId}
              fetcher={getCacheMedias}
            />
          )
        }
      ]
    },
    {
      title: 'Description',
      key: 'description',
      dataIndex: 'description',
      width: 15,
      sorter: (a, b) => a.description.localeCompare(b.description),
      sortOrder: sortedInfo.columnKey === 'description' ? sortedInfo.order : null
    }
  ];

  return (
    <div>
      <p>
        These are the current categories present in the ERP system. You can sync them to WooCommerce
        by clicking the button below.
      </p>

      {/* Category Table */}
      <GenericTable
        columns={columns}
        data={categories || []}
        scroll={{ x: 600, y: '75vh' }}
        hideDefaultPagination
        toSort={handleChange}
      />

      <div className="flex justify-end mt-4">
        <Button type="primary" icon={<SyncOutlined />} onClick={handleSyncToWooCommerce}>
          Sync Categories
        </Button>
      </div>
    </div>
  );
}

export default SyncCategory;
