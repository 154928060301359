import { useQuery } from '@tanstack/react-query';
import { DatePicker, Form, Input, InputNumber, Select, TableProps } from 'antd';
import { RangePickerProps } from 'antd/lib/date-picker';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { useEffect, useState } from 'react';

import { CustomModal } from '@/components/Common/CustomModal';
import { get_reasons_list } from '@/services/adjustments/queries';
import AdjustmentResuableView from '../../../adjustments/view';
import { CustomDatePresets } from '../utils/datePresets';
import CustomReport from './report';
import { SorterResult } from 'antd/lib/table/interface';
import { nepaliNumberFormatter, numberDecimalFormatter } from '@/utils/numberFormatter';
import {
  convert_nepali_to_english_date,
  convert_string_to_nepali_date_string
} from '@/utils/nepaliDateConverter';
import { find_date_preference } from '@/store/localstorage/preferences';
import { NepaliDatePicker } from 'nepali-datepicker-reactjs';
import { getUserData } from '@/utils/auth.utils';
import LocationSearchV2 from '@/components/Common/CustomSearch/Location';

import { DEFAULT_DATE_FORMAT } from '@/constants';
import ProductCategorySearchV2 from '@/components/Common/CustomSearch/ProductCategory';
import ProductbyCategory from '@/components/Common/CustomSearch/Products/ProductsByCategory';
const { Option } = Select;
const { RangePicker } = DatePicker;

const AdjustmentReport = () => {
  const [form] = Form.useForm();
  // const { data: locationsList } = useQuery(['locations'], async () => get_location_list());
  const { data: reasonList } = useQuery(['reasons'], async () => get_reasons_list());
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [id, setId] = useState(0);
  const [categoryId, setCategoryId] = useState<number | null>(null);

  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({});
  const handleChange: TableProps<any>['onChange'] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<any>);
  };
  const [nepaliType] = useState(find_date_preference());
  const { preferences } = getUserData();
  const preferenceLocationId = preferences?.preferences
    ? JSON.parse(preferences?.preferences)?.locationId
    : undefined;
  useEffect(() => {
    const nepaliDateStart = convert_string_to_nepali_date_string(
      CustomDatePresets.Today[0].format('YYYY-MM-DD HH:mm')
    );
    const nepaliDateEnd = convert_string_to_nepali_date_string(
      CustomDatePresets.Today[0].format('YYYY-MM-DD HH:mm')
    );
    form.setFieldValue('constraints', {
      page: 1,
      size: 100,
      locationId: preferenceLocationId ? preferenceLocationId : '',
      reasonId: '',
      productId: '',
      categoryId: '',
      dateCustom: [...CustomDatePresets.Today],
      startDate: CustomDatePresets.Today[0].format('YYYY-MM-DD HH:mm'),
      endDate: CustomDatePresets.Today[1].format('YYYY-MM-DD HH:mm'),
      startDateNepali: nepaliDateStart,
      endDateNepali: nepaliDateEnd
    });
  }, []);

  const breadcrumbItems = [
    {
      label: 'Reports',
      link: '/reports'
    },
    {
      label: 'Product Adjustment',
      link: '/reports/adjustment'
    }
  ];

  const onChange: RangePickerProps['onChange'] = (dates, dateStrings) => {
    if (dateStrings.length == 2) {
      form.setFieldValue(['constraints', 'startDate'], dateStrings[0]);
      form.setFieldValue(['constraints', 'endDate'], dateStrings[1]);
    }
  };
  const columns: ColumnsType<any> = [
    {
      title: 'S.N',
      key: 'SN',
      width: 25,
      // sorter: (a, b) => a.id - b.id,
      // sortOrder: sortedInfo.columnKey === 'id' ? sortedInfo.order : null,
      render: (text, record, index) => {
        return <div color="black">{index + 1}</div>;
      }
    },
    {
      title: 'Product',
      key: 'product_name',
      // render: (text: string) => <div>{text}</div>,
      sorter: (a, b) => a.product_name.localeCompare(b.product_name),
      sortOrder: sortedInfo.columnKey === 'product_name' ? sortedInfo.order : null,
      render: (record) => {
        return (
          // <Link to={`/locations/${record.locationId}`}>
          <div color="black">{record.product_name}</div>
          // </Link>
        );
      },
      width: 100
    },
    {
      title: 'Location',

      sorter: (a, b) => a.location_name.localeCompare(b.location_name),
      sortOrder: sortedInfo.columnKey === 'location_name' ? sortedInfo.order : null,
      render: (record) => {
        return (
          // <Link to={`/locations/${record.locationId}`}>
          <div>{record.location_name}</div>
          // </Link>
        );
      },
      width: 60
    },
    {
      title: 'Category',
      key: 'category_name',
      sorter: (a, b) => a.category_name.localeCompare(b.category_name),
      sortOrder: sortedInfo.columnKey === 'category_name' ? sortedInfo.order : null,
      render: (record) => {
        return <div>{record.category_name}</div>;
      },
      width: 60
    },
    {
      title: 'Date',
      key: 'adjustment_date',
      sorter: (a, b) => moment(a.created_at).unix() - moment(b.created_at).unix(),
      sortOrder: sortedInfo.columnKey === 'adjustment_date' ? sortedInfo.order : null,
      render: (record) => {
        return <div>{moment(record.created_at).local().format(DEFAULT_DATE_FORMAT)}</div>;
      },
      width: 50
    },
    {
      title: 'Reason',
      key: 'reason',
      sorter: (a, b) => a.reason.localeCompare(b.reason),
      sortOrder: sortedInfo.columnKey === 'reason' ? sortedInfo.order : null,
      render: (record) => {
        return (
          // <Link to={`/locations/${record.locationId}`}>
          <div>{record.reason}</div>
          // </Link>
        );
      },

      width: 70
    },
    {
      title: 'Quantity',
      key: 'quantity',
      sorter: (a, b) => a.quantity.localeCompare(b.quantity),
      sortOrder: sortedInfo.columnKey === 'quantity' ? sortedInfo.order : null,
      render: (record) => {
        return (
          // <Link to={`/locations/${record.locationId}`}>
          <div>{`${numberDecimalFormatter(record.quantity)} ${record.shortName}`}</div>
          // </Link>
        );
      },
      width: 30
    },
    {
      title: 'Total Amount',
      className: 'highlight',
      // dataIndex: 'total_amount',
      // render: (text: string) => <div>{text}</div>,
      key: 'total_amount',
      sorter: (a, b) => a.total_amount - b.total_amount,
      sortOrder: sortedInfo.columnKey === 'total_amount' ? sortedInfo.order : null,
      render: (record) => {
        return (
          // <Link to={`/sell/${record.id}`} color="black">
          <div className="text-right mr-2">{nepaliNumberFormatter(record.total_amount)}</div>
          // </Link>
        );
      },
      width: 50
    }
  ];

  const onSelectt = (val: any) => {
    // console.log('this is from onSelect', val);
    form.setFieldValue(['constraints', 'locationId'], val);
  };
  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    // Can not select days before today and today
    return current && current > moment().endOf('day');
  };

  const onChangeNepali = (val: string, isStart: boolean) => {
    if (isStart)
      form.setFieldValue(['constraints', 'startDate'], convert_nepali_to_english_date(val));
    else form.setFieldValue(['constraints', 'endDate'], convert_nepali_to_english_date(val));
  };

  const locationId = Form.useWatch(['constraints', 'locationId'], form);

  return (
    <CustomReport
      toSort={handleChange}
      // locationList={locationsList?.data.results}
      breadcrumbItems={breadcrumbItems}
      state={{ id: 30, name: 'Adjustment Report' }}
      columnsData={columns}
      form={form}
      toDownload={true}>
      <CustomModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        title={'Adjustment View'}>
        <AdjustmentResuableView id={id} />
      </CustomModal>
      <Form.Item label={'page'} name={['constraints', 'page']} hidden={true}>
        <InputNumber controls={false} />
      </Form.Item>
      <Form.Item label={'size'} name={['constraints', 'size']} hidden={true}>
        <InputNumber controls={false} />
      </Form.Item>
      <ProductCategorySearchV2
        showAll
        hasParentFormItem={false}
        name={['constraints', 'categoryId']}
        onSelect={(val: any) => {
          val === '' ? setCategoryId(null) : setCategoryId(val as number);
          form.setFieldValue(['constraints', 'productId'], '');
        }}
      />
      <ProductbyCategory
        categoryId={categoryId}
        hasParentFormItem={false}
        showAll
        locationId={locationId}
        name={['constraints', 'productId']}
      />
      <LocationSearchV2 showAll hasParentFormItem={false} name={['constraints', 'locationId']} />

      <Form.Item name={['constraints', 'reasonId']} label="Reason">
        <Select placeholder="Select a reason!">
          <Option value="">All</Option>

          {reasonList?.data.map((value: any) => {
            return (
              <Option value={value.id} key={value.id}>
                {value.name}
              </Option>
            );
          })}
        </Select>
      </Form.Item>
      <Form.Item
        label={'Pick a date'}
        name={['constraints', 'dateCustom']}
        rules={[{ required: true, message: 'Please select date!' }]}
        hidden={nepaliType}>
        <RangePicker
          showTime
          ranges={CustomDatePresets}
          disabledDate={disabledDate}
          onChange={onChange}
          format={'YYYY-MM-DD HH:mm'}
          allowClear={false}
        />
      </Form.Item>
      <Form.Item label={'Start Date'} name={['constraints', 'startDate']} hidden>
        <Input />
      </Form.Item>
      <Form.Item label={'End Date'} name={['constraints', 'endDate']} hidden>
        <Input />
      </Form.Item>
      <Form.Item label={'Select Start Date'} name={['startDateNepali']} hidden={!nepaliType}>
        <NepaliDatePicker
          onChange={(val) => onChangeNepali(val, true)}
          options={{ calenderLocale: 'ne', valueLocale: 'en' }}
        />
      </Form.Item>
      <Form.Item label={'Select End Date'} name={['endDateNepali']} hidden={!nepaliType}>
        <NepaliDatePicker
          onChange={(val) => onChangeNepali(val, false)}
          options={{ calenderLocale: 'ne', valueLocale: 'en' }}
        />
      </Form.Item>
    </CustomReport>
  );
};

export default AdjustmentReport;
