import moment from 'moment';
import { CloseOutlined } from '@ant-design/icons';
import { Button, Drawer, Form, FormInstance, Input, message, Spin, Tooltip } from 'antd';
import { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { useFilterStore, FilterType, useHydration } from '@/store/zustand';
import FilterInfo, { filterColor } from '@/components/FliterTable/FilterInfo';
import FilterButton from '@/components/FliterTable/FilterButton';
import useToggle from '@/hooks/useToggle';
import AccountDateForm from './AccountDatePicker';
import { getDeepCopy } from '@/utils';
import { convertLocalToUTCString } from '@/utils/convertToUTC';
import { ConvertObjectToURL } from '@/utils/converturl';
import { useLocation } from 'react-router-dom';
import {
  useFinancialYearPersistHydration,
  useFinancialYearStore
} from '@/store/zustand/financial-year';

import {
  calculateDateRange,
  formatDateRange,
  formatDateToForm
} from '@/store/zustand/financial-year/services';
import {
  deleteUserFilter,
  get_current_page_filter,
  updateUserFilter
} from '@/services/users/services.users';
import { deepEqual } from '@/utils/isObjectEqual';
import { InitialLoad } from '@/components/FliterTable';
import { find_locationId_preference } from '@/store/localstorage/preferences';
import isAxiosError from '@/utils/isAxiosError';
import CustomErrorModal from '@/components/Common/CustomErrorModal';
import getErrorMessage from '@/utils/getError';
import eventEmitter from '@/utils/events';
import { excludedCacheData, persistAccountID } from '@/constants/account.menu';
import { isTodayIncluded } from '@/utils/isTodayIncluded';

interface FilterValues {
  skip?: number;
  count?: number;
  endDate?: string;
  startDate?: string;
  dateCustom?: [moment.Moment, moment.Moment];
  startDateNepali?: string;
  endDateNepali?: string;
  dateSingle?: boolean;
  date?: string;
  [key: string]: unknown;
}

interface Props {
  showFilterInitial?: boolean; // Control to show filter drawer initially
  initial?: boolean; // Control to fetch data initially
  form: FormInstance;
  defaultValues: Record<string, unknown>;
  hasHours?: boolean;
  dateType?: 'range' | 'single';
  buttons?: React.ReactNode;
  buttonParentStyle?: string;
  hideDate?: boolean;
  allowSaved?: boolean;
  onSearch: (values: string, isInitial?: boolean) => Promise<unknown>;
  onInitialLoad?: (props: InitialLoad) => void;
  onParentLoading?: (loading: boolean) => void;
}

function AccountFilters({
  showFilterInitial = false,
  form,
  initial,
  buttons,
  children,
  defaultValues,
  hasHours = true,
  buttonParentStyle = '',
  hideDate = false,
  allowSaved = true,
  dateType = 'range',
  ...handlers
}: PropsWithChildren<Props>) {
  const location = useLocation();
  const prevFinancialYear = useRef('');
  const savedFilter = get_current_page_filter(location.pathname);

  const [isLoading, setIsLoading] = useState(false);
  const [openDatePicker, setOpenDatePicker] = useState(false);

  const isHydrated = useHydration();
  const zustandFilter = useFilterStore();
  const zustandFinancialYear = useFinancialYearStore();
  const isFinancialYearHydrated = useFinancialYearPersistHydration();
  const currentFilterType = zustandFilter.getCurrentFilterType(location.pathname);
  const currentFilterColor = filterColor[currentFilterType];

  const { parentRef, triggerRef, isOpen, ...toggleHandlers } = useToggle({
    onClickOutside: () => setOpenDatePicker(false)
  });

  function getCurrentFormDate(
    rangeDate: [string, string],
    singleDate: string
  ): Record<string, unknown> {
    if (hideDate) return {};
    const { formDateRange, formSingleDate } = formatDateToForm(rangeDate, singleDate);
    if (dateType === 'range') return formDateRange;
    if (dateType === 'single') return formSingleDate;
    return {};
  }

  function getDefaultValues() {
    if (hideDate) return { ...defaultValues };

    const { range, singleDate } = calculateDateRange(zustandFinancialYear.selectedFinancialYear);
    const formDates = getCurrentFormDate(range, singleDate);
    return { ...defaultValues, ...formDates };
  }

  function getFilterType(currentFilter: Record<string, unknown>) {
    // Check if the local filter matches the saved filter
    if (savedFilter && deepEqual(currentFilter, savedFilter)) {
      return 'saved';
    }

    // Check if the local filter matches the default filter
    if (defaultValues) {
      const modifiedDefault = getDefaultValues();
      if (deepEqual(currentFilter, modifiedDefault)) {
        return 'default';
      }
    }

    return 'local';
  }

  async function handleSubmit(initial = false) {
    try {
      setIsLoading(true);
      const formValues = await form.validateFields();
      toggleHandlers.onOpen(false);
      const filterType = getFilterType(formValues);

      await onFinish(formValues, initial, filterType, true);
    } finally {
      setIsLoading(false);
    }
  }

  async function handleSync(apiCall = true) {
    const isPersist = persistAccountID.includes(location.pathname);
    const accountId = form.getFieldValue('accountId');
    form.resetFields();

    const defaultValues = getDefaultValues();
    if (isPersist) defaultValues.accountId = accountId;
    form.setFieldsValue(defaultValues);

    if (apiCall) await onFinish(defaultValues, false, 'default');
    return defaultValues;
  }

  function handleInitial() {
    if (!initial) {
      handlers.onParentLoading?.(false);
      toggleHandlers.onOpen(showFilterInitial);
    }
  }

  // Handle financial year change
  useEffect(() => {
    const unsubscribe = eventEmitter.on('FINANCIAL_YEAR_CHANGE', (payload) => {
      const { selectedFinancialYear, defaultRange, defaultSingleDate } = payload;
      if (prevFinancialYear.current !== selectedFinancialYear?.name) {
        const formDates = getCurrentFormDate(defaultRange, defaultSingleDate);
        form.setFieldsValue(formDates);

        // Open Drawers
        toggleHandlers.onOpen(true);
      }
    });

    return unsubscribe;
  }, []);

  useEffect(() => {
    const unsubscribe = eventEmitter.on('RESET_CURRENT_PAGE_SAVED_FILTERS', async (payload) => {
      if (payload.reset === 'all') {
        handleSync();
      }

      if (payload.reset === 'current') {
        await onResetFilter();
        handleSync();
      }
    });

    return unsubscribe;
  }, []);

  useEffect(() => {
    if (!isHydrated || zustandFinancialYear.isLoading || !isFinancialYearHydrated) return;

    const params = new URLSearchParams(location.search);
    const accountId = params.get('accountId');
    const startDate = params.get('startDate');
    const endDate = params.get('endDate');
    const defaultValues = getDefaultValues();

    const hasBothStartEnd = startDate && endDate;
    const validAccountID = accountId && Number(accountId) > 0;

    // If account ID is present in query, use default filters for that account
    if (accountId || hasBothStartEnd) {
      let initialValues = getDefaultValues();

      if (validAccountID) initialValues.accountId = Number(accountId);
      if (hasBothStartEnd) {
        // Check if startDate and endDate is valid or not
        const isStartDateValid = moment(startDate).isValid();
        const isEndDateValid = moment(endDate).isValid();

        if (isStartDateValid && isEndDateValid) {
          const fields = formatDateRange(startDate, endDate);
          initialValues = { ...defaultValues, ...fields };
        }
      }

      form.setFieldsValue({ ...initialValues });
      onFinish(initialValues, true, 'local');
      return;
    }

    // Check for filter persist
    const hasFilterPresist = checkFilterPersist();
    if (hasFilterPresist) return;

    // Default filters
    form.resetFields();
    form.setFieldsValue(defaultValues);
    if (!initial) return handleInitial();
    onFinish(defaultValues, true, 'default');
  }, [isHydrated, zustandFinancialYear.isLoading, isFinancialYearHydrated]);

  async function onFinish(
    values: FilterValues,
    isInitial = false,
    filterType?: FilterType,
    resetPage?: boolean
  ) {
    toggleHandlers.onOpen(false);
    const hasSkip = values.skip !== undefined && values.skip !== null;
    const hasCount = values.count !== undefined && values.count !== null;
    const hasSkipCount = hasSkip && hasCount;
    const resetPaginationCondition =
      filterType !== 'local' || (filterType === 'local' && resetPage);

    if (hasSkipCount && resetPaginationCondition) {
      values.skip = typeof defaultValues.skip === 'number' ? defaultValues.skip : 0;
      values.count = typeof defaultValues.count === 'number' ? defaultValues.count : 100;
    }

    const originalValues = getDeepCopy(values);
    if (!hideDate) {
      if (dateType === 'range') {
        const endDate = values.endDate as string | moment.Moment;
        const startDate = values.startDate as string | moment.Moment;
        values.endDate = convertLocalToUTCString(endDate);
        values.startDate = convertLocalToUTCString(startDate);
        delete values.dateCustom;
        delete values.startDateNepali;
        delete values.endDateNepali;
      } else {
        delete values.startDate;
        delete values.endDate;
      }

      if (dateType === 'single') {
        const date = values.date as string | moment.Moment;
        values.date = moment(convertLocalToUTCString(date)).format('YYYY-MM-DD');
        delete values.dateSingle;
      } else {
        delete values.date;
      }
    } else {
      delete values.startDate;
      delete values.endDate;
      delete values.date;
      delete values.dateSingle;
      delete values.dateCustom;
      delete values.startDateNepali;
      delete values.endDateNepali;
      delete values.dateSingleNepali;
    }

    const filter = ConvertObjectToURL(values);
    const data = await handlers.onSearch(filter, isInitial);

    if (allowSaved) {
      const isExcludedFromCache = excludedCacheData.includes(location.pathname);
      const isToday = isTodayIncluded(filter);

      const cacheDataCondition = isExcludedFromCache ? !isToday : true;

      zustandFilter.updateState(location.pathname, {
        data: cacheDataCondition ? data : undefined,
        resetScrollWhenData: filterType !== 'local',
        filter: { params: originalValues, type: filterType }
      });

      if (!cacheDataCondition) {
        zustandFilter.removeData(location.pathname);
      }
    }
  }

  function checkFilterPersist() {
    const preferenceLocationId = find_locationId_preference();
    const currentFilter = zustandFilter.getState(location.pathname);

    if (currentFilter && allowSaved) {
      const { filter, pagination, data } = currentFilter;
      const startDate = filter.startDate as string;
      const endDate = filter.endDate as string;

      const isWithin = zustandFinancialYear.isWithinFinancialYear(startDate, endDate);
      if (isWithin) {
        form.setFieldsValue(filter);
        const filterType = getFilterType(filter);
        if (data) {
          handlers?.onInitialLoad?.({ pagination, data });
          zustandFilter.updateFilterType(location.pathname, filterType);
        } else {
          onFinish(filter, true, filterType).then(() => handlers?.onInitialLoad?.({ pagination }));
        }
        return true;
      } else {
        message.error(
          'Previously selected date does not match selected financial year. Checking for saved filter instead.'
        );
      }
    }

    if (!savedFilter) return false;
    if (
      preferenceLocationId &&
      savedFilter?.locationId &&
      savedFilter?.locationId !== preferenceLocationId
    ) {
      message.error(
        'Saved filter does not match preference location. So, current applied filter will be reset.'
      );

      return false;
    }

    const isWithin = zustandFinancialYear.isWithinFinancialYear(
      savedFilter.startDate,
      savedFilter.endDate
    );

    if (!isWithin) {
      message.error(
        'Date of saved filter does not match selected financial year. Applying default filter instead.'
      );
      return false;
    }

    const filterType = getFilterType(savedFilter);
    form.setFieldsValue(savedFilter);
    zustandFilter.updateFilterType(location.pathname, filterType);
    initial ? onFinish(savedFilter, true, 'saved') : handleInitial();
    return true;
  }

  async function onSaveFilter() {
    try {
      setIsLoading(true);
      const formValues = form.getFieldsValue();
      if (formValues['dateCustom']) {
        const dates = formValues['dateCustom'] as [moment.Moment, moment.Moment];
        formValues['dateCustom'] = [dates[0].toISOString(), dates[1].toISOString()];
      }

      if (formValues['dateSingle']) {
        const date = formValues['dateSingle'] as moment.Moment;
        formValues['dateSingle'] = date.toISOString();
      }

      await updateUserFilter(location.pathname, formValues);
      message.success('Filter saved successfully for current page');

      const localFilter = zustandFilter.getState(location.pathname);
      const prevLocalFilter = localFilter?.filter;

      if ((prevLocalFilter && !deepEqual(formValues, prevLocalFilter)) || !prevLocalFilter) {
        zustandFilter.removeData(location.pathname);
      }

      zustandFilter.updateState(location.pathname, {
        filter: { params: formValues, type: 'saved' }
      });
    } catch (error: unknown) {
      if (isAxiosError(error)) return;
      CustomErrorModal({
        title: 'Error',
        message: getErrorMessage(error) || 'Error saving filter.'
      });
    } finally {
      setIsLoading(false);
    }
  }

  async function onResetFilter() {
    try {
      setIsLoading(true);
      const prevSavedFilter = get_current_page_filter(location.pathname);
      const defaultFormValue = await handleSync(false);
      await deleteUserFilter(location.pathname);
      message.success('Filter reset successfully for current page');

      if ((prevSavedFilter && !deepEqual(defaultFormValue, prevSavedFilter)) || !prevSavedFilter) {
        zustandFilter.removeData(location.pathname);
      }

      zustandFilter.updateState(location.pathname, {
        filter: { params: defaultFormValue, type: 'default' }
      });
    } catch (error: unknown) {
      if (isAxiosError(error)) return;
      CustomErrorModal({
        title: 'Error',
        message: getErrorMessage(error) || 'Error reseting filter'
      });
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div ref={parentRef} className="z-10">
      <div className="flex flex-row relative items-center justify-end mt-2 flex-wrap gap-2 sm:gap-0">
        <div className="flex items-center justify-center gap-2">
          {allowSaved && (
            <Tooltip title="Applied filter will reset and data will be reloaded">
              <Button
                className="flex items-center justify-end secondary-button"
                style={{ borderRadius: '5px' }}
                onClick={() => handleSync(true)}>
                Refresh
              </Button>
            </Tooltip>
          )}

          <Button
            className="flex items-center justify-end filter-button"
            style={{
              borderRadius: '5px',
              color: currentFilterColor,
              border: `1px solid ${currentFilterColor}`
            }}
            onClick={toggleHandlers.onToggle}>
            Filters
          </Button>
        </div>
        {buttons && <div className={buttonParentStyle}>{buttons}</div>}
      </div>

      <Drawer
        forceRender
        keyboard={false}
        visible={isOpen}
        placement="right"
        closable={false}
        style={{ position: 'fixed', zIndex: 1001 }}
        headerStyle={{ background: '#f3f4f6', paddingInline: 24 }}
        footerStyle={{ background: '#f3f4f6', paddingInline: 24 }}
        bodyStyle={{ paddingInline: 24, paddingBlock: 8, background: '#f3f4f6' }}
        onClose={() => toggleHandlers.onOpen(false)}
        title={
          <div className="flex items-center justify-between !text-sm">
            <FilterInfo />
            <CloseOutlined onClick={() => toggleHandlers.onOpen(false)} className="[&>svg]:m-0" />
          </div>
        }
        footer={
          <div className="flex gap-2 justify-end flex-wrap pb-2">
            <FilterButton
              form={form}
              isDisabled={isLoading}
              onFinish={(v, f) => onFinish(v, false, f)}
              onSaveFilter={onSaveFilter}
              onSaveReset={onResetFilter}
            />

            <Button
              ref={triggerRef}
              type="default"
              style={{
                borderRadius: '5px',
                color: currentFilterColor,
                border: `1px solid ${currentFilterColor}`
              }}
              disabled={isLoading}
              className="!rounded-md text-base"
              onClick={() => handleSubmit()}>
              Filter
            </Button>
          </div>
        }>
        <Spin spinning={isLoading} wrapperClassName="h-full filter-wrapper" className="h-full">
          <Form form={form} layout="vertical" autoComplete="off">
            <div className="flex h-full flex-col justify-between gap-2">
              <div className="grid grid-cols-1 gap-2">
                <Form.Item label={'Skip'} name={['skip']} hidden>
                  <Input />
                </Form.Item>
                <Form.Item label={'Count'} name={['count']} hidden>
                  <Input />
                </Form.Item>

                {!hideDate && (
                  <AccountDateForm
                    dateType={dateType}
                    hasHours={hasHours}
                    open={openDatePicker}
                    setOpen={setOpenDatePicker}
                  />
                )}
                {children}
              </div>
            </div>
          </Form>
        </Spin>
      </Drawer>
    </div>
  );
}

export default AccountFilters;
