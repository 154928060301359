import moment from 'moment';
import AppContent from '@/components/Common/Content/Content';
import ProductSearchV2 from '@/components/Common/CustomSearch/Products';
import VendorSearchV2 from '@/components/Common/CustomSearch/Vendors';
import { type PictureListRef } from '@/components/Common/PictureList';
import { getImagesFromServer, uploadImagesToServer } from '@/services/upload/queries';
import { IVendor } from '@/services/users/types';
import getErrorMessage from '@/utils/getError';
import { Alert, Button, Collapse, Form, message, Modal, PageHeader, Spin, UploadFile } from 'antd';
import { useRef, useState } from 'react';
import VendorImageCollapsible from './VendorImageCollapsible';
import DetailsCollapsible from './DetailsCollapsible';
import VisitCollapsible from './VisitCollapsible';
import { IVendorProductDetails, IVendorProductsPayload } from '@/services/vendor-products/types';
import { useMutation } from '@tanstack/react-query';
import { create_vendor_products } from '@/services/vendor-products/mutation';
import { useNavigate } from 'react-router-dom';
import { useFilterStore } from '@/store/zustand';
import { ListPage } from '@/constants/list.enum';

const breadcrumbItems = [
  { label: 'Vendor Products', link: '/vendor-products' },
  { label: 'Create', link: '/vendor-products/new' }
];

export interface IDetails {
  title: string;
  customDetails: { label: string; value: string }[];
  files?: UploadFile[];
}

export interface IVisits {
  date: moment.Moment | null;
  customDetails: { label: string; value: string; date: moment.Moment | null }[];
  files?: UploadFile[];
}

export interface FormValues {
  vendorId: number;
  isVendorDefault: boolean;
  productId: number;
  visits: IVisits[];
  details: IDetails[];
}

function CreateVendorProducts() {
  const [form] = Form.useForm<FormValues>();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const zustandFilter = useFilterStore();

  const [clearAll, setClearAll] = useState(false);
  const [vendorId, setVendorId] = useState<number>();

  const [vendorDefaultMedia, setVendorDefaultMedia] = useState<{
    avatarId?: number;
    vendorId?: number;
    file?: UploadFile;
  }>();

  const vendorMediaRef = useRef<PictureListRef>(null);
  const visitsMediaRef = useRef<PictureListRef>(null);

  const createVendorProductsMutation = useMutation(create_vendor_products, {
    onSuccess: () => {
      message.success('Product added successfully');
      zustandFilter.resetState(ListPage.VENDOR_PRODUCTS);
      navigate('/vendor-products');
    }
  });

  function handleReset(change: 'all' | 'vendor' | 'product') {
    if (change !== 'product') {
      vendorMediaRef?.current?.clearAll();
      form.resetFields(['isVendorDefault']);
    }

    visitsMediaRef?.current?.clearAll();

    if (change === 'all' || change === 'vendor') {
      form.resetFields(['productId', 'details', 'visits']);
      setClearAll(false);
      return;
    }

    form.resetFields(['details', 'visits']);
  }

  async function getMediaIds(files: UploadFile[]) {
    const mediaIdsWithURL = files.filter((file) => file.url).map((file) => parseInt(file.uid));
    const mediaWithoutURL = files.filter((file) => !file.url);

    const uploadData =
      mediaWithoutURL.length > 0 ? await uploadImagesToServer(mediaWithoutURL) : [];

    const mediaIdsWithoutURL = uploadData.map((media) => media?.id).filter(Boolean) as number[];

    return [...new Set([...mediaIdsWithoutURL, ...mediaIdsWithURL])];
  }

  async function onFinish(values: FormValues) {
    try {
      setIsLoading(true);
      const vendorMedias = vendorMediaRef.current?.getCurrentMedias() || [];
      const vendorMediaIds = await getMediaIds(vendorMedias);

      const createPayload: IVendorProductsPayload = {
        vendorId: values.vendorId,
        productId: values.productId,
        mediaIds: [],
        details: '{}',
        visits: []
      };

      const combinedMediaIds = [...vendorMediaIds];

      const detailsData = {
        vendorImage: vendorMediaIds,
        details: [] as IVendorProductDetails['details']
      };

      // Modify Details Payload
      if (values.details && values.details.length > 0) {
        const updatedDetails = await Promise.all(
          values.details.map(async (detail) => {
            const metadata = detail.customDetails;
            const mediaIds = await getMediaIds(detail.files || []);

            combinedMediaIds.push(...mediaIds);
            return {
              title: detail.title,
              metadata: { image: mediaIds, custom: metadata }
            };
          })
        );

        detailsData.details = updatedDetails;
      }

      createPayload.details = JSON.stringify(detailsData);

      // Modify Visits Payload
      if (values.visits && values.visits.length > 0) {
        const updatedVisits = await Promise.all(
          values.visits.map(async (visit) => {
            const dateString = moment(visit.date).toISOString();
            const metadata = visit.customDetails.map((m) => {
              const { date, ...rest } = m;
              if (!date) return rest;
              return { ...rest, date: moment(date).toISOString() };
            });

            const mediaIds = await getMediaIds(visit.files || []);
            combinedMediaIds.push(...mediaIds);

            return {
              date: dateString,
              metadata: JSON.stringify({ image: mediaIds, custom: metadata })
            };
          })
        );

        createPayload.visits = updatedVisits;
      }

      createPayload.mediaIds = Array.from(new Set(combinedMediaIds));
      await createVendorProductsMutation.mutateAsync(createPayload);
    } catch (error) {
      getErrorMessage(error, true);
    } finally {
      setIsLoading(false);
    }
  }

  async function onDefaultVendorImageChange(checked: boolean) {
    try {
      setIsLoading(true);
      // Show error if no vendor is selected
      if (!vendorId) {
        return message.error('Please select vendor first!');
      }

      if (!checked) {
        const avatarId = vendorDefaultMedia?.avatarId || '';
        vendorMediaRef.current?.removeMedia(String(avatarId));
        return;
      }

      // Show error if no default image is present
      if (!vendorDefaultMedia || !vendorDefaultMedia.avatarId) {
        return message.error('Selected vendor has no default image!');
      }

      // Check Cache Media and add default file
      if (vendorDefaultMedia.file && vendorDefaultMedia.vendorId === vendorId) {
        vendorMediaRef.current?.addMedia(vendorDefaultMedia.file);
        return;
      }

      // Fetch from server
      const vendorMedia = await getImagesFromServer([vendorDefaultMedia.avatarId]);
      const currentMedia = vendorMedia[0];
      const file: UploadFile = {
        url: currentMedia.url,
        name: currentMedia.name,
        uid: currentMedia.id.toString(),
        status: 'done'
      };

      vendorMediaRef.current?.addMedia(file);
      setVendorDefaultMedia((prev) => ({ ...prev, file }));
    } catch (error) {
      getErrorMessage(error, true);
    } finally {
      setIsLoading(false);
    }
  }

  function handleVendorChange(vendor?: IVendor) {
    if (!vendor || !vendor.user.avatarId) {
      setVendorDefaultMedia(undefined);
      return;
    }

    setVendorDefaultMedia({ avatarId: vendor.user.avatarId, vendorId: vendor.id });
  }

  function handleOnSelectVendor(id: number) {
    if (!id || id === vendorId) return;
    setVendorId(id);
    handleReset('vendor');
  }

  return (
    <Spin spinning={isLoading}>
      <Modal visible={clearAll} onOk={() => handleReset('all')} onCancel={() => setClearAll(false)}>
        <p>
          All data provided for the given supplier will be cleared. Are you sure you want to
          continue?
        </p>
      </Modal>
      <AppContent breadcrumbItems={breadcrumbItems}>
        <Alert
          message="Warning"
          description="Changing the product or supplier will clear all current data. Please be careful before proceeding."
          showIcon
        />
        <PageHeader title="Product Information" style={{ padding: '8px 0px' }} />
        <Form
          form={form}
          validateTrigger="onChange"
          layout="vertical"
          onFinish={onFinish}
          autoComplete="off"
          initialValues={{ visits: [], customDetails: [] }}>
          <div className={'grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5 mb-5'}>
            <VendorSearchV2
              hasParentFormItem={false}
              name="vendorId"
              label="Vendor"
              required
              allowClear={false}
              onSelect={handleOnSelectVendor}
              setSelected={(vendor) => handleVendorChange(vendor)}
            />
            <ProductSearchV2
              hasParentFormItem={false}
              name="productId"
              required
              allowClear={false}
              onSelect={() => handleReset('product')}
            />
          </div>

          <Collapse collapsible={vendorId ? undefined : 'disabled'}>
            <Collapse.Panel header="Vendor Image" key={'vendor-image'}>
              <VendorImageCollapsible
                vendorMediaRef={vendorMediaRef}
                avatarId={vendorDefaultMedia?.avatarId}
                onDefaultVendorImageChange={onDefaultVendorImageChange}
              />
            </Collapse.Panel>

            <Collapse.Panel header="Details" key={'details'}>
              <DetailsCollapsible />
            </Collapse.Panel>

            <Collapse.Panel header="Visits" key={'visits'}>
              <VisitCollapsible visitsMediaRef={visitsMediaRef} />
            </Collapse.Panel>
          </Collapse>

          <div className="flex justify-end mt-5 gap-2">
            <Button htmlType="button" onClick={() => setClearAll(true)}>
              Clear All
            </Button>

            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </div>
        </Form>
      </AppContent>
    </Spin>
  );
}

export default CreateVendorProducts;
