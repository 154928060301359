import { Popover } from 'antd';

interface ColorBoxProps {
  color: string;
  title: string;
  description: string;
}

function ColorBox(props: ColorBoxProps) {
  const { color, description, title } = props;

  return (
    <Popover title={title} content={description} trigger="hover" overlayStyle={{ maxWidth: 300 }}>
      <div
        className={'w-[9px] h-[9px] rounded-md cursor-pointer'}
        style={{ backgroundColor: color }}></div>
    </Popover>
  );
}

export default ColorBox;
