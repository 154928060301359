import { exportExcelBuffer } from '@/utils/exportNestedExcel';
import { ISumReportRow } from '.';
import ExcelJS from 'exceljs';
import getCharFromNumber from '@/utils/getCharFromNumber';
import { LineType } from '@/services/report/enums';

const borderStyle: Partial<ExcelJS.Borders> = {
  top: { style: 'medium', color: { argb: 'FF000000' } },
  left: { style: 'thin', color: { argb: 'FF000000' } },
  bottom: { style: 'medium', color: { argb: 'FF000000' } },
  right: { style: 'thin', color: { argb: 'FF000000' } }
};

const borderInlineStyle: Partial<ExcelJS.Borders> = {
  left: borderStyle.left,
  right: borderStyle.right
};

export async function excelExportSumReport(columns: string[], rows: ISumReportRow[], date: string) {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Sum Report');

  const lastColumnChar = getCharFromNumber(Math.max(columns.length - 1, 0));
  const parentStyle = [
    { value: 'FreshKtm Agro Pvt. Ltd.', size: 12, bold: true, align: 'center', font: 'Tahoma' },
    {
      value: 'SALES UPDATES',
      size: 11,
      bold: true,
      align: 'center',
      font: 'Tahoma',
      background: 'FFf1b187'
    },
    { value: date, size: 11, bold: false, align: 'center', font: 'Tahoma' }
  ];

  parentStyle.forEach((style, idx) => {
    const row = idx + 1;
    const cells = `A${row}:${lastColumnChar}${row}`;

    worksheet.mergeCells(cells);
    const cell = worksheet.getCell(`A${row}`);

    const currentRow = worksheet.getRow(row);
    currentRow.height = 30;

    cell.value = style.value;
    cell.alignment = { horizontal: 'center', vertical: 'middle' };
    cell.font = { size: style.size, bold: style.bold, name: style.font || 'Calibri' };
    if (style.background)
      cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: style.background } };
  });

  worksheet.addRow({});

  const excelColumns: Partial<ExcelJS.Column>[] = columns.map((column) => ({
    key: column,
    width: column.length
  }));

  worksheet.columns = excelColumns;

  const highLightColumns = [
    LineType.PURCHASE,
    LineType.TRANSFER_IN,
    LineType.SALE,
    LineType.SALE_RETURN,
    `${LineType.ADJUSTMENT} (%)`,
    `${LineType.ADJUSTMENT} (Rs)`,
    'No. of Transaction'
  ];

  const colRowCell = worksheet.addRow(columns.map(() => ''));
  colRowCell.height = 30;

  columns.forEach((col, colIndex) => {
    const cell = colRowCell.getCell(colIndex + 1);
    cell.value = col.toUpperCase();
    cell.font = { bold: true };
    cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF9fbfe6' } };
    cell.border = borderStyle;
    cell.alignment = { vertical: 'middle', horizontal: 'center' };
  });

  rows.forEach((row, rowIdx) => {
    const isHighLight = highLightColumns.includes(row.description);
    const isLastRow = rowIdx === rows.length - 1;

    const rowCell = worksheet.addRow(row);
    rowCell.height = 30;

    const skipDesc = [`${LineType.ADJUSTMENT} (%)`, 'Gross Profit/Loss'];
    const skipNumberFormat = skipDesc.includes(row.description);

    columns.forEach((column, colIndex) => {
      const value = row[column];
      const cell = rowCell.getCell(colIndex + 1);
      cell.value = value;

      if (isHighLight) {
        cell.font = { bold: true };
        cell.border = borderStyle;
        cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFd9d9d9' } };
      } else {
        cell.border = borderInlineStyle;
      }

      if (column !== 'description') {
        cell.alignment = { vertical: 'middle', horizontal: 'right' };
        if (!skipNumberFormat) cell.numFmt = '#,##0.00';
      }

      if (isLastRow) {
        cell.border = borderStyle;
      }

      // Calculate the max length for each column
      const columnLength = value ? value.toString().length : 10; // default length if no data
      const columnDef = worksheet.getColumn(column);
      columnDef.width = Math.max(columnDef?.width || 0, columnLength + 5);
    });
  });

  const buffer = await workbook.xlsx.writeBuffer();
  exportExcelBuffer(buffer, 'Sum Report');
}
