import getErrorMessage from '@/utils/getError';
import { Button, message, Modal } from 'antd';
import { CSSProperties, useEffect, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { CustomModal } from '../CustomModal';
import ExportDrawer from '../Drawer/ExportDrawer';
import { exportExcelWithHeader, IExportHeader } from '@/utils/exportExcelWithHeader';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';

const tdthStyle = {
  border: '1px solid black',
  padding: '2px 0px'
};

export interface ITableColumnExport {
  label: string;
  dataIndex: string;
  isNumber?: boolean;
  textAlign?: CSSProperties['textAlign'];
}

// Define Export Format Types
type ExportType = 'current' | 'all';
type ExportFormatType = 'pdf' | 'excel';

interface Props<T extends Record<string, unknown>> {
  formats: ExportFormatType | ExportFormatType[];
  columns: ITableColumnExport[];
  data: T[];
  exportType: ExportType[];
  header?: IExportHeader[];
  fileName?: string;
  sheetName?: string;
  highlightColumn?: boolean;
  modifyData?: (data: T[]) => T[];
  fetchData?: () => Promise<T[]>;
  setIsLoading?: (isLoading: boolean) => void;
}

function ReusableExport<T extends Record<string, any>>({
  formats,
  data,
  columns,
  exportType,
  header,
  highlightColumn,
  fileName = 'Report',
  sheetName = 'Sheet1',
  ...handlers
}: Props<T>) {
  const printPDFRef = useRef(null);
  const [exportData, setExportData] = useState<T[]>([]);
  const [openExportDrawer, setOpenExportDrawer] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState<string[]>([]);
  const [filteredColumns, setFilteredColumns] = useState<ITableColumnExport[]>([]);
  const [openModalForPdfExport, setOpenModalForPdfExport] = useState(false);

  const arrayFormats = Array.isArray(formats) ? formats : [formats];

  useEffect(() => {
    // Ensure only one type per export format
    if (Array.isArray(formats)) {
      const uniqueFormats = new Set(formats);
      if (uniqueFormats.size !== formats.length) {
        Modal.error({ content: 'Each export format should have a unique type.' });
      }
    }

    if (selectedColumns.length === 0) {
      const columnNames = columns.map((column) => column.label);
      setSelectedColumns(columnNames);
    }

    setFilteredColumns(columns);
  }, [columns]);

  async function handleExport(options: { type: string; file: string }) {
    try {
      const { type, file } = options;
      const optionType = type === 'all' ? 'all' : 'current';

      handlers.setIsLoading?.(true);
      if (data.length === 0) {
        message.error('No Data to Export');
        return;
      }

      let filteredData = data;
      setOpenExportDrawer(false);

      if (optionType === 'all') {
        if (!handlers.fetchData) {
          message.error('Please provide a fetchData function for "all" export type.');
          return;
        }

        filteredData = await handlers.fetchData();
      }

      const updatedData = handlers.modifyData ? handlers.modifyData(filteredData) : filteredData;

      if (file === 'excel') {
        exportExcelWithHeader({
          rows: updatedData,
          fileName,
          sheetName,
          header,
          columns: filteredColumns
        });
        return;
      }

      if (file === 'pdf') {
        setExportData(updatedData);
        setOpenModalForPdfExport(true);
      }
    } catch (error) {
      getErrorMessage(error, true);
    } finally {
      handlers.setIsLoading?.(false);
    }
  }

  const handlePDFExport = useReactToPrint({
    content: () => printPDFRef.current,
    documentTitle: fileName,
    onAfterPrint: () => setOpenModalForPdfExport(false)
  });

  function handleColumnChange(updatedColumns: string[]) {
    setSelectedColumns(updatedColumns);

    // Filter columns based on selected columns
    const filteredColumns = columns.filter((column) => updatedColumns.includes(column.label));
    setFilteredColumns(filteredColumns);
  }

  return (
    <div>
      <CustomModal
        footer={false}
        isModalOpen={openModalForPdfExport}
        setIsModalOpen={setOpenModalForPdfExport}
        title="PDF print">
        <div className="flex justify-end mb-3">
          <Button type="primary" onClick={handlePDFExport}>
            Print Pdf
          </Button>
        </div>

        <div style={{ maxHeight: '80vh', overflow: 'scroll' }}>
          <div className="flex flex-col justify-center font-sans" ref={printPDFRef}>
            <table width={'100%'}>
              <thead>
                {header?.map((h) => {
                  return (
                    <tr key={`header-${h.value}`}>
                      <th
                        colSpan={filteredColumns.length}
                        className="font-bold"
                        style={h.tableBorder ? tdthStyle : undefined}>
                        {h.value}
                      </th>
                    </tr>
                  );
                })}

                <tr>
                  {filteredColumns.map((column) => (
                    <th
                      key={column.label}
                      style={{
                        ...tdthStyle,
                        background: highlightColumn ? '#e7e6e6' : 'transparent'
                      }}>
                      <div className="text-center">{column.label}</div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {exportData.map((currData, indr: number) => {
                  return (
                    <tr key={`${currData}${indr}`}>
                      {filteredColumns.map((currCol, indc: number) => {
                        return (
                          <td
                            key={indr + indc}
                            style={{ ...tdthStyle, padding: '0 3px', textAlign: currCol.textAlign }}
                            className="text-center">
                            {currCol.isNumber
                              ? nepaliNumberFormatter(currData[currCol.dataIndex])
                              : currData[currCol.dataIndex]}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </CustomModal>

      <div className="flex">
        {selectedColumns.length > 0 && (
          <ExportDrawer
            hideFilter
            disabled={data.length === 0}
            selectedColumns={selectedColumns}
            allColumns={columns.map((c) => c.label)}
            open={openExportDrawer}
            fileOptions={arrayFormats.map((type) => ({
              key: `export-format-${type}`,
              label: type.charAt(0).toUpperCase() + type.slice(1),
              value: type
            }))}
            options={exportType.map((type) => ({
              key: `export-${type}`,
              label: `Export ${type.charAt(0).toUpperCase() + type.slice(1)}`,
              value: type
            }))}
            currentSelected={{
              type: exportType[0] || 'current',
              file: Array.isArray(formats) ? formats[0] : formats
            }}
            onOpen={setOpenExportDrawer}
            onExport={handleExport}
            setSelectedColumns={handleColumnChange}
          />
        )}
      </div>
    </div>
  );
}

export default ReusableExport;
