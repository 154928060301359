import moment from 'moment';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TextArea from 'antd/lib/input/TextArea';
import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  PageHeader,
  Spin
} from 'antd';

import JournalContent from './JournalContent';
import CategoryContent from './CategoryContent';
import AppContent from '@/components/Common/Content/Content';
import { ExpenseColumnDataType } from '@/services/expense/enum';
import { IExpenseCreateFormData } from '@/services/expense/types';
import ExpenseCategoryDB from '@/store/localstorage/ExpenseCategoryDB';
import { create_expense_mutation } from '@/services/expense/mutations';
import { ExpenseCategorySearch } from '@/components/Common/ExpenseCategorySearch';
import { ExpenseCategoryColumnItem } from '@/services/expense/expense-category/types';
import { get_expense_category_id } from '@/services/expense/expense-category/queries';
import { LocationSearch } from '@/components/Common/LocationSearch/LocationSearch';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { uploadImagesToServer } from '@/services/upload/queries';
import getErrorMessage from '@/utils/getError';
import { useFilterStore } from '@/store/zustand';
import { ListPage } from '@/constants/list.enum';

interface FormData extends IExpenseCreateFormData {
  billingDate: moment.Moment;
}

function ExpenseCreateRevamp() {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [locationId, setLocationId] = useState<number | undefined>(undefined);

  const [isCustom, setIsCustom] = useState(true);
  const [totalAmount, setTotalAmount] = useState(0);
  const [selectedAccount, setSelectedAccount] = useState(0);
  const [columns, setColumns] = useState<ExpenseCategoryColumnItem[]>([]);

  const zustandFilter = useFilterStore();

  function handleAmountChange(amount: any) {
    const amountNumber = Number(amount || 0);
    setTotalAmount(amountNumber || 0);
    const journalLines = form.getFieldValue('journalLines');
    if (!journalLines || journalLines.length === 0 || isCustom) return;

    form.setFieldValue(['journalLines', 0, 'debit'], amountNumber || 0);
    journalLines.length > 1 && form.setFieldValue(['journalLines', 1, 'credit'], amountNumber || 0);
  }

  function handleCustomCheck(event: CheckboxChangeEvent) {
    const isChecked = event.target.checked;
    const journalLines = form.getFieldValue('journalLines');
    if (journalLines?.length === 2) {
      setIsCustom(isChecked);
      if (!isChecked) {
        form.setFieldsValue({
          journalLines: [
            {
              description: '',
              accountId: selectedAccount || '',
              debit: totalAmount,
              credit: 0,
              locationId
            },
            { description: '', accountId: '', debit: 0, credit: totalAmount, locationId }
          ]
        });
      }

      return;
    }

    message.error('Journal Lines should be exactly 2 lines to use this feature');
    setIsCustom(true);
  }

  async function handleCategorySelect(categoryId: number) {
    let category = (await ExpenseCategoryDB.get(categoryId)) as any;
    if (!category) {
      category = await get_expense_category_id(categoryId);
      await ExpenseCategoryDB.add([category]);
    }

    const { debitAccountId } = category;
    setIsCustom(!debitAccountId);
    setSelectedAccount(debitAccountId);
    const totalAmount = form.getFieldValue('totalAmount') || 0;
    const amount = debitAccountId ? totalAmount : 0;

    form.setFieldsValue({
      journalLines: [
        { description: '', accountId: debitAccountId || '', debit: amount, credit: 0, locationId },
        { description: '', accountId: '', debit: 0, credit: amount, locationId }
      ]
    });

    const content = JSON.parse(category.content);
    const column = content.column as ExpenseCategoryColumnItem[];
    setColumns(column);

    // Initally set CategoryContent to have one object with all the columns with empty values
    const initialCategoryContent = column.reduce((acc, column) => {
      const isBoolean = column.dataType === ExpenseColumnDataType.BOOLEAN;
      const isExpenseId = column.name === 'expenseId';
      const isDate = column.dataType === ExpenseColumnDataType.DATE;

      acc[column.name] = isBoolean ? false : isExpenseId ? 0 : isDate ? moment() : undefined;
      return acc;
    }, {} as Record<string, any>);

    form.setFieldsValue({ categoryContent: [initialCategoryContent] });
  }

  useEffect(() => {
    // Add Empty Journal Line to the form
    form.setFieldsValue({
      journalLines: [
        { description: '', accountId: '', debit: 0, credit: 0, locationId: undefined },
        { description: '', accountId: '', debit: 0, credit: 0, locationId: undefined }
      ]
    });
  }, []);

  async function handleFormSubmit(values: FormData) {
    setIsLoading(true);
    try {
      await form.validateFields();

      const journalLines = values.journalLines;

      const accountIds = journalLines.map((line) => line.accountId);
      const isDuplicate = new Set(accountIds).size !== accountIds.length;

      // Check if the current line has duplicate account
      if (isDuplicate) {
        message.error({
          key: 'journalLines',
          content: 'You cannot add a line with the same account twice!'
        });
        return;
      }

      // Check if string field is exactly string or number
      const stringFields = columns
        .filter((column) => column.dataType === ExpenseColumnDataType.STRING)
        .map((column) => column.name);

      const categoryContent = values.categoryContent;

      for (const name of stringFields) {
        const isInvalid = categoryContent.some((content) => {
          return !Number.isNaN(Number(content[name]));
        });

        if (isInvalid) {
          message.error(`Field '${name}' should not contain any numeric values`);
          return;
        }
      }

      // check total credit is equal to total debit
      const totalDebit = journalLines.reduce((acc, line) => acc + line.debit, 0);
      const totalCredit = journalLines.reduce((acc, line) => acc + line.credit, 0);

      if (totalDebit !== totalCredit) {
        message.error('Total Debit and Credit should be equal');
        return;
      }

      if (totalDebit !== values.totalAmount) {
        message.error('Total Debit/Credit should be equal to Total Amount');
        return;
      }

      values.billDate = values.billingDate.utc().format();

      // @ts-ignore
      delete values.billingDate;

      // Convert all moment dates to UTC string and upload media
      values.categoryContent = await Promise.all(
        values.categoryContent.map(async (content) => {
          const dateFields = Object.keys(content).filter(
            (key: string) => content[key] instanceof moment
          );

          dateFields.forEach((field) => {
            content[field] = content[field].utc().format();
          });

          const mediaFiles = content?.mediaFiles;

          if (mediaFiles && mediaFiles.length > 0) {
            const uploadedImage = await uploadImagesToServer(mediaFiles);
            content.mediaIds = JSON.stringify(
              uploadedImage.filter((image) => image).map((image) => image?.id)
            );

            delete content.mediaFiles;
          }

          return content;
        })
      );

      values.journalLines = values.journalLines.map((lines) => ({
        ...lines,
        locationId: values.locationId
      }));

      // Validation Finish, now make an api call
      await create_expense_mutation(values);
      zustandFilter.resetState(ListPage.EXPENSE);
      message.success('Expense created successfully');
      navigate('/expense');
    } catch (error) {
      message.error(getErrorMessage(error));
    } finally {
      setIsLoading(false);
    }
  }

  function handleLocationChange(locationId: number) {
    setLocationId(locationId);
  }

  return (
    <Spin spinning={isLoading}>
      <AppContent
        breadcrumbItems={[
          { label: 'Expense', link: '/expense' },
          { label: 'Create', link: '/expense/new' }
        ]}>
        <Form
          form={form}
          onFinish={handleFormSubmit}
          layout="vertical"
          autoComplete="off"
          disabled={isLoading}
          validateTrigger={'onChange'}>
          <PageHeader title="Expense Information" style={{ padding: '8px 0px' }} />

          <div className="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4">
            <Form.Item
              label="Billing Date"
              name="billingDate"
              rules={[{ required: true, message: 'Select Billing Date' }]}>
              <DatePicker format={'YYYY-MM-DD'} allowClear={false} className="w-full" />
            </Form.Item>

            <Form.Item
              label="Bill Number"
              name="billNumber"
              rules={[{ required: true, message: 'Provide Billing Number' }]}>
              <Input />
            </Form.Item>

            <LocationSearch
              notAll
              required
              onSelect={handleLocationChange}
              formData={{ formLabel: 'Select Location', formName: 'locationId' }}
            />

            <Form.Item
              label="Total Amount"
              name="totalAmount"
              rules={[{ required: true, message: 'Provide Billing Number' }]}>
              <InputNumber controls={false} onChange={handleAmountChange} />
            </Form.Item>

            <ExpenseCategorySearch
              required
              isAll={false}
              allowClear={false}
              onSelect={handleCategorySelect}
              formData={{ label: 'Expense Category', formName: 'categoryId' }}
            />
          </div>

          <Form.Item
            label="Description"
            name="description"
            rules={[{ required: false, message: 'Provide Billing Number' }]}>
            <TextArea rows={4} placeholder="Description" />
          </Form.Item>

          {columns.length > 0 && (
            <div className="bg-gray-50 py-4 -mx-5 px-5">
              <PageHeader title="Category Content" style={{ padding: '8px 0px' }} />
              <CategoryContent columns={columns} form={form} />
            </div>
          )}

          <div className="flex gap-4 items-center flex-wrap justify-between">
            <PageHeader title="Journal Lines" style={{ padding: '8px 0px' }} />
            {selectedAccount && (
              <div className="flex items-center gap-4">
                <Checkbox checked={isCustom} onChange={handleCustomCheck}>
                  <span>Custom Journals</span>
                </Checkbox>
              </div>
            )}
          </div>

          <JournalContent
            form={form}
            isCustom={isCustom}
            locationId={locationId}
            totalAmount={totalAmount}
            selectedAccount={selectedAccount}
            setIsCustom={setIsCustom}
          />

          <div className="flex justify-end gap-4 mt-8">
            <Button type="primary" htmlType="submit">
              Create Expense
            </Button>
          </div>
        </Form>
      </AppContent>
    </Spin>
  );
}

export default ExpenseCreateRevamp;
