import { Modal } from 'antd';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useDestroyModalsOnRouteChange = () => {
  const location = useLocation();

  useEffect(() => {
    Modal.destroyAll();
  }, [location.pathname]);
};

export default useDestroyModalsOnRouteChange;
