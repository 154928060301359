import { Button, DatePicker, Form, Input, message, Modal, PageHeader, Select, Spin } from 'antd';
import { UploadFile } from 'antd/es/upload';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import AppContent from '@/components/Common/Content/Content';
import PictureListByForm from '@/components/Common/PictureList/PictureListByForm';
import CustomErrorModal from '@/components/Common/CustomErrorModal';
import getErrorMessage from '@/utils/getError';
import { IHRLeavesPayload } from '@/services/hr/types';
import { uploadImagesToServer } from '@/services/upload/queries';
import { create_hr_leaves_mutation } from '@/services/hr/mutations';
import { getUserData } from '@/utils/auth.utils';
import UserSearchV2 from '@/components/Common/CustomSearch/Users';
import isAxiosError from '@/utils/isAxiosError';
import { useFilterStore } from '@/store/zustand';
import { ListPage } from '@/constants/list.enum';

interface FormValues {
  userId: number;
  description: string;
  dates: [moment.Moment, moment.Moment];
  medias?: UploadFile[];
  leaveFor: 'self' | 'others';
}

function CreateLeaves() {
  const [form] = Form.useForm<FormValues>();
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  const currentUser = getUserData();
  const leaveFor = Form.useWatch('leaveFor', form);

  const zustandFilter = useFilterStore();

  async function onFinish(values: FormValues) {
    try {
      setIsLoading(true);

      const [start, end] = values.dates;
      const payload: IHRLeavesPayload = {
        description: values.description,
        isDisabled: false,
        startDate: moment(start).toISOString(),
        endDate: moment(end).toISOString(),
        mediaIds: [],
        userId: values.leaveFor === 'self' ? currentUser.id : values.userId
      };

      if (values.medias && values.medias.length > 0) {
        const medias = await uploadImagesToServer(values.medias);
        const mediaIds = medias.map((media) => media?.id).filter(Boolean) as number[];

        payload.mediaIds = mediaIds;
      }

      await create_hr_leaves_mutation(payload);
      zustandFilter.resetState(ListPage.HR_LEAVE);
      message.success('Leave created successfully');
      navigate('/hr/leaves');
    } catch (error) {
      if (isAxiosError(error)) return;
      CustomErrorModal({ message: getErrorMessage(error) });
    } finally {
      setIsLoading(false);
    }
  }

  function onConfirmation() {
    setIsModalOpen(false);
    form.submit();
  }

  async function onCreate() {
    await form.validateFields();
    setIsModalOpen(true);
  }

  return (
    <Spin spinning={isLoading}>
      <Modal visible={isModalOpen} onCancel={() => setIsModalOpen(false)} onOk={onConfirmation}>
        <p>Are you sure you want to create leave?</p>
      </Modal>

      <AppContent breadcrumbItems={[{ label: 'Leave', link: '/hr/leaves' }, { label: 'Create' }]}>
        <PageHeader title="Create Leave" style={{ padding: '8px 0' }} />
        <Form
          form={form}
          initialValues={{ leaveFor: 'self' }}
          layout="vertical"
          onFinish={onFinish}>
          <div className="grid gap-3 grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
            <Form.Item name="leaveFor" label="Leave For" rules={[{ required: true }]}>
              <Select>
                <Select.Option value="self">Self</Select.Option>
                <Select.Option value="others">Others</Select.Option>
              </Select>
            </Form.Item>

            <UserSearchV2
              hasParentFormItem={false}
              name="userId"
              label="Employee"
              hidden={leaveFor === 'self'}
              required={leaveFor === 'others'}
            />

            <Form.Item
              name="dates"
              label="Leave Date"
              rules={[{ required: true, message: 'Please select the date range' }]}>
              <DatePicker.RangePicker
                className="w-full max-w-md"
                showTime
                format={'YYYY-MM-DD HH:mm'}
              />
            </Form.Item>
          </div>

          <Form.Item
            name="description"
            label="Reason"
            rules={[{ required: true, message: 'Please provide your reason' }]}>
            <Input.TextArea placeholder="Provide your reason" rows={4} />
          </Form.Item>

          <div className="mt-4">
            <PictureListByForm name={'medias'} label="Attachments" showStyle />
          </div>

          <div className="flex justify-end mt-4">
            <Button type="primary" onClick={onCreate} disabled={isLoading}>
              Add Leave
            </Button>
          </div>
        </Form>
      </AppContent>
    </Spin>
  );
}

export default CreateLeaves;
