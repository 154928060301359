import { Form, message, Select, Spin, TableProps } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { useEffect, useState } from 'react';
import AppContent from '@/components/Common/Content/Content';
import { LocationSearch } from '@/components/Common/LocationSearch/LocationSearch';
import TableFilter from '@/components/FliterTable';
import useDebounce from '@/hooks/useDebounce';
import { get_location_list } from '@/services/locations/queries';
import { get_product_list_ids } from '@/services/products/queries';
import { get_purchase_order_form_filter_v2 } from '@/services/purchases/queries';
import { get_report_string } from '@/services/report/queries';
import ProductsDB from '@/store/localstorage/ProductsDB';
import { SorterResult } from 'antd/lib/table/interface';
import { getUserData } from '@/utils/auth.utils';
import { ExportColumnType, exportExcel } from '@/utils/exportExcel';
import CustomButton from '@/components/Common/CustomButton/CustomButton';
import FullScreenModal from '@/components/Common/FullScreenModal';
import { ConvertObjectToURL } from '@/utils/converturl';
import { IProductType } from '@/services/products/types';
import LocationSearchV2 from '@/components/Common/CustomSearch/Location';

const ProductPurchaseOrderView = () => {
  const [form] = Form.useForm();
  const [searchValue, setSearchValue] = useState<string>('');
  const [data, setData] = useState<any>();
  const [selectedLocation, setselectedLocation] = useState<number>();
  const debouncedSearchValue = useDebounce(searchValue, 500);
  useEffect(() => {
    searchLocation(debouncedSearchValue);
  }, [debouncedSearchValue]);
  const [locationSearch, setLocationSearch] = useState<any>([]);
  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({});
  const handleChange: TableProps<any>['onChange'] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<any>);
  };
  const [loading, setIsloading] = useState<boolean>(true);
  const { preferences } = getUserData();
  const preferenceLocationId = preferences?.preferences
    ? JSON.parse(preferences?.preferences)?.locationId
    : undefined;

  const searchLocation = async (value: any) => {
    let response;
    try {
      response = await get_location_list(0, 5, value);
    } catch (e) {
      console.log(e);
    }
    if (!response || response.data.results.length == 0) {
      message.error('Cannot find any location with that name!');
      setLocationSearch([]);
    } else {
      setLocationSearch(response.data.results);
    }
  };

  const options = locationSearch.map((value: any) => (
    <Select.Option key={value.id} value={value.id}>
      {value.name}
    </Select.Option>
  ));

  const onSearch = async (val: string) => {
    setIsloading(true);
    console.log(val, selectedLocation);
    let newfilter = ConvertObjectToURL({
      locationId: form.getFieldValue('locationId'),
      startDate: moment(form.getFieldValue(['date']))
        .utc()
        .format(),
      endDate: moment(form.getFieldValue(['date']))
        .utc()
        .add(1, 'days')
        .format()
    });
    // const response: any = (await get_purchase_order_form_filter(val)).data;
    const response: any = await get_purchase_order_form_filter_v2({
      locationId: form.getFieldValue('locationId'),
      startDate: moment(form.getFieldValue(['date']))
        .utc()
        .format(),
      endDate: moment(form.getFieldValue(['date']))
        .utc()
        .add(1, 'days')
        .format()
    });
    newfilter += '&page=1&size=1000';
    // console.log('Response-->', response);
    const responseSold: any = (await get_report_string(newfilter, 37)).data;
    // val +=
    //   '&page=1&size=1000&identifier=SALE&startDate=' +
    //   moment().subtract(7, 'days').utc().format() +
    //   '&endDate=' +
    //   moment().utc().format();
    // const responseSold: any = (await get_report_string(val, 11)).data;
    // console.log(responseSold);
    const searchProducts: any = {};
    for (let index = 0; index < response.data.data.length; index++) {
      const product: any = await ProductsDB.getProduct(response.data.data[index].productId);
      if (!product) {
        // product = await get_product_details(purchaseDetails.lines[index].productId);
        // await ProductsDB.addProducts([product]);
        if (response.data.data[index].productId in searchProducts) {
          searchProducts[response.data.data[index].productId] = [
            ...searchProducts[response.data.data[index].productId],
            index
          ];
        } else {
          searchProducts[response.data.data[index].productId] = [index];
        }

        // if(responseSold)
      } else {
        response.data.data[index].productName = product.name;
      }
      if (responseSold.data.length > 0) {
        const find = responseSold.data.find(
          (val: any) => val.product_id === response.data.data[index].productId
        );
        if (find) {
          response.data.data[index].soldAmount = find.quantity;
          responseSold.data = responseSold.data.filter(
            (val: any) => val.product_id != response.data.data[index].productId
          );
        } else {
          response.data.data[index].soldAmount = 0;
        }
      } else {
        response.data.data[index].soldAmount = 0;
      }
    }
    // for (let index = 0; index < responseSold.data.length; index++) {
    //   console.log(response.data.data);
    //   response.data.data.push({
    //     productId: responseSold.data[index].product_id,
    //     ordered: 0,
    //     soldAmount: responseSold.data[index].qty
    //   });
    //   const product: any = await ProductsDB.getProduct(responseSold.data[index].product_id);
    //   if (!product) {
    //     if (response.data.data[response.data.data.length - 1].productId in searchProducts) {
    //       searchProducts[response.data.data[response.data.data.length - 1].productId] = [
    //         ...searchProducts[response.data.data[response.data.data.length - 1].productId],
    //         response.data.data.length - 1
    //       ];
    //     } else {
    //       searchProducts[response.data.data[response.data.data.length - 1].productId] = [
    //         index
    //       ];
    //     }
    //   } else {
    //     response.data.data[response.data.data.length - 1].productName = product.name;
    //   }
    // }

    const searchProductslength = Object.entries(searchProducts).length;
    if (searchProductslength > 0) {
      const productsresponse = await get_product_list_ids([...Object.keys(searchProducts)]);
      for (const key in searchProducts) {
        const findproduct = productsresponse?.data?.results.find(
          (currProduct: IProductType) => currProduct.id == key
        );
        for (let i = 0; i < searchProducts[key].length; i++) {
          response.data.data[searchProducts[key][i]].productName = findproduct?.name;
        }
        await ProductsDB.addProducts([findproduct]);
      }
    }
    setIsloading(false);
    setData(response.data.data);
  };
  const breadcrumbItems = [
    {
      label: 'Purchase vs Purchase Demand'
    }
  ];

  const columns: ColumnsType<any> = [
    {
      title: 'ID',
      width: 50,
      key: 'id',
      sorter: (a, b) => a.id - b.id,
      sortOrder: sortedInfo.columnKey === 'id' ? sortedInfo.order : null,
      render: (record: any) => {
        return <div color="black">{record.id}</div>;
      }
    },
    {
      title: 'Product Name(SKU)',
      key: 'productName',
      sorter: (a, b) => a.productName.localeCompare(b.productName),
      sortOrder: sortedInfo.columnKey === 'productName' ? sortedInfo.order : null,
      width: 200,
      render: (record: any) => {
        return <div color="black">{record.productName}</div>;
      }
    },
    // {
    //   title: 'Location',
    //   key: 'locationId',
    //   render: (record: any) => {
    //     return <div color="black">{record.locationId}</div>;
    //   }
    // },
    // {
    //   title: 'Ordered',
    //   key: 'qty',
    //   sorter: (a, b) => a.qty - b.qty,
    //   sortOrder: sortedInfo.columnKey === 'qty' ? sortedInfo.order : null,
    //   width: 100,
    //   render: (record: any) => {
    //     return <div color="black">{record.qty}</div>;
    //   }
    // },
    // {
    //   title: 'Sold',
    //   width: 100,
    //   key: 'soldAmount',
    //   sorter: (a, b) => a.soldAmount - b.soldAmount,
    //   sortOrder: sortedInfo.columnKey === 'soldAmount' ? sortedInfo.order : null,
    //   render: (record: any) => {
    //     return <div color="black">{record.soldAmount}</div>;
    //   }
    // },
    {
      title: 'Demand Vs Purchase',
      width: 100,
      key: 'OrderedVsSold',
      render: (record: any) => {
        let percentSold = '';
        let percentOrdered = '';
        let overflow = false;
        if (record.qty >= record.soldAmount) {
          if (record.qty === 0) percentOrdered = '0%';
          else percentOrdered = '100%';
          percentSold = `${(record.soldAmount / record.qty) * 100}%`;
          if (isNaN(record.soldAmount / record.qty)) percentSold = '0%';
        } else {
          overflow = true;
          if (record.soldAmount === 0) percentSold = '0%';
          else percentSold = '100%';
          percentOrdered = `${(record.qty / record.soldAmount) * 100}%`;
          if (isNaN(record.soldAmount / record.qty)) percentOrdered = '0%';
        }
        // console.log('percentSOld', percentSold);
        // console.log('percentOrdered', percentOrdered);
        return (
          <div color="black" style={{ width: '100%' }}>
            <div>Demand({record.qty})</div>
            <div
              style={{
                height: '5px',
                backgroundColor: 'blue',
                width: percentOrdered,
                borderRadius: '4px'
              }}></div>
            <div>
              Purchased({record.soldAmount}){overflow ? ' overFlow' : ''}
            </div>
            <div
              style={{
                height: '5px',
                backgroundColor: 'red',
                width: percentSold,
                borderRadius: '4px'
              }}></div>
          </div>
        );
      }
    },
    {
      title: 'Total',
      width: 100,
      key: 'totalAmount',
      sorter: (a, b) => a.totalAmount - b.totalAmount,
      sortOrder: sortedInfo.columnKey === 'totalAmount' ? sortedInfo.order : null,
      render: (record: any) => {
        return <div color="black">{record.totalAmount}</div>;
      }
    }
  ];

  const handleExport = () => {
    setIsloading(true);
    try {
      const columns: ExportColumnType[] = [
        {
          title: 'ID',
          width: 50,
          dataIndex: 'id'
        },
        {
          title: 'Product Name(SKU)',
          dataIndex: 'productName',
          width: 200
        },
        {
          title: 'Demand Vs Purchase',
          width: 250,
          dataIndex: 'OrderedVsSold'
        },
        {
          title: 'Total',
          width: 100,
          dataIndex: 'totalAmount'
        }
      ];
      if (data?.todaysOrders.length === 0) {
        message.error('No Data to Export');
        return;
      }
      const dataUpdated = data?.todaysOrders.map((item: any, index: number) => {
        return {
          ...item,
          OrderedVsSold: `Demand: ${item.qty} | Purchase: ${item.soldAmount}`
        };
      });
      exportExcel(columns, dataUpdated, 'Demand Vs Purchase');
    } catch (err: any) {
      console.log(err);
    } finally {
      setIsloading(false);
    }
  };

  return (
    <Spin spinning={loading}>
      <AppContent
        breadcrumbItems={breadcrumbItems}
        withfilter={true}
        button={
          <>
            <div>
              {locationSearch.length > 0 && (
                <TableFilter
                  allowSaved={false}
                  defaultValues={{
                    // dateCustom: [moment(), moment().add(1, 'days')],
                    // locationId: locationSearch[0].id
                    locationId: preferenceLocationId ? preferenceLocationId : locationSearch[0].id,
                    date: moment().format('YYYY-MM-DD')
                  }}
                  dateCustom={false}
                  singleDate={true}
                  initial={true}
                  onSubmit={onSearch}
                  timeVal={true}
                  // outsidestyle={'flex flex-wrap items-end gap-2'}
                  style={
                    'grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3  xl:grid-cols-4 gap-2 items-center justify-center'
                  }
                  styleforbuttons="flex justify-end items-center"
                  form={form}>
                  {/* <Form.Item
                    name={['locationId']}
                    label="Location"
                    rules={[
                      {
                        required: true,
                        message: 'Please choose location!'
                      }
                    ]}>
                    <Select
                      showSearch
                      placeholder={'search location'}
                      defaultActiveFirstOption={false}
                      showArrow={false}
                      filterOption={false}
                      onSelect={(val: number) => setselectedLocation(val)}
                      dropdownMatchSelectWidth={false}
                      onSearch={(val) => {
                        setSearchValue(val);
                      }}
                      notFoundContent={null}>
                      {options}
                    </Select>
                  </Form.Item> */}
                  <LocationSearchV2 hasParentFormItem={false} name="locationId" />
                </TableFilter>
              )}
            </div>
            {/* </div> */}
          </>
        }>
        <div className="flex justify-end ">
          <CustomButton text="Excel Export" backgroundColor="#1890ff" onClick={handleExport} />
          <FullScreenModal>
            <div className="grid grid-cols-2  sm:grid-cols-4 md:grid-cols-6 xl:grid-cols-8 gap-2 mt-2">
              {data &&
                data.map((record: any, ind: number) => {
                  let percentSold = '';
                  let percentOrdered = '';
                  let overflow = false;
                  if (record.qty >= record.soldAmount) {
                    if (record.qty === 0) percentOrdered = '0%';
                    else percentOrdered = '100%';
                    percentSold = `${(record.soldAmount / record.qty) * 100}%`;
                    if (isNaN(record.soldAmount / record.qty)) percentSold = '0%';
                  } else {
                    overflow = true;
                    if (record.soldAmount === 0) percentSold = '0%';
                    else percentSold = '100%';
                    percentOrdered = `${(record.qty / record.soldAmount) * 100}%`;
                    if (isNaN(record.soldAmount / record.qty)) percentOrdered = '0%';
                  }
                  return (
                    <div className="cardforestimatestock" key={ind}>
                      {/* <div>{record.id}</div> */}
                      <div className="font-bold">{record.productName}</div>
                      <div color="black" style={{ width: '100%' }}>
                        <div>Demand ({record.qty})</div>
                        <div
                          style={{
                            height: '3px',
                            backgroundColor: 'blue',
                            width: percentOrdered,
                            borderRadius: '4px'
                          }}></div>
                        <div>
                          Purchased ({record.soldAmount}){overflow ? ' OverFlow' : ''}
                        </div>
                        <div
                          style={{
                            height: '3px',
                            backgroundColor: overflow ? 'red' : 'green',
                            width: percentSold,
                            borderRadius: '4px'
                          }}></div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </FullScreenModal>
        </div>
        <div className="grid grid-cols-2 sm:grid-cols-4 md:grid-cols-6 xl:grid-cols-8 gap-2 mt-2">
          {data &&
            data.map((record: any, ind: number) => {
              let percentSold = '';
              let percentOrdered = '';
              let overflow = false;
              if (record.qty >= record.soldAmount) {
                if (record.qty === 0) percentOrdered = '0%';
                else percentOrdered = '100%';
                percentSold = `${(record.soldAmount / record.qty) * 100}%`;
                if (isNaN(record.soldAmount / record.qty)) percentSold = '0%';
              } else {
                overflow = true;
                if (record.soldAmount === 0) percentSold = '0%';
                else percentSold = '100%';
                percentOrdered = `${(record.qty / record.soldAmount) * 100}%`;
                if (isNaN(record.soldAmount / record.qty)) percentOrdered = '0%';
              }
              return (
                <div className="cardforestimatestock" key={ind}>
                  {/* <div>{record.id}</div> */}
                  <div className="font-bold">{record.productName}</div>
                  <div color="black" style={{ width: '100%' }}>
                    <div>Demand ({record.qty})</div>
                    <div
                      style={{
                        height: '3px',
                        backgroundColor: 'blue',
                        width: percentOrdered,
                        borderRadius: '4px'
                      }}></div>
                    <div>
                      Purchased Order ({record.soldAmount}) {overflow ? ' Overflow' : ''}
                    </div>
                    <div
                      style={{
                        height: '3px',
                        backgroundColor: overflow ? 'red' : 'green',
                        width: percentSold,
                        borderRadius: '4px'
                      }}></div>
                  </div>
                </div>
              );
            })}
        </div>
      </AppContent>
    </Spin>
  );
};

export default ProductPurchaseOrderView;
