import { useMemo, useState } from 'react';

interface UsePaginationOptions {
  defaultSize: number;
}

interface Pagination {
  page: number;
  size: number;
}

interface UsePaginationResult<T> {
  data: T[];
  onReset: () => void;
  pagination: {
    total: number;
    page: number;
    size: number;
    onPagination: (page: number, size: number, isSizeChanged?: boolean) => void;
  };
}

function usePagination<T>(data: T[], options: UsePaginationOptions): UsePaginationResult<T> {
  const [pagination, setPagination] = useState<Pagination>({
    page: 1,
    size: options.defaultSize
  });

  const onPaginationChange = (page: number, size: number, isSizeChanged?: boolean) => {
    setPagination((prev) => ({
      page: isSizeChanged ? 1 : page,
      size: isSizeChanged ? size : prev.size
    }));
  };

  const resetPagination = () => setPagination({ page: 1, size: options.defaultSize });

  const paginatedData = useMemo(() => {
    const start = (pagination.page - 1) * pagination.size;
    return data.slice(start, start + pagination.size);
  }, [data, pagination]);

  return {
    data: paginatedData,
    onReset: resetPagination,
    pagination: {
      total: data.length,
      page: pagination.page,
      size: pagination.size,
      onPagination: onPaginationChange
    }
  };
}

export default usePagination;
