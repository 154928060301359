import { useState, useEffect } from 'react';
import { useMutation } from '@tanstack/react-query';
import { Form, Input, Button, PageHeader, message, Checkbox, Spin } from 'antd';
import { useNavigate } from 'react-router';
import AppContent from '../../../components/Common/Content/Content';
import { create_routes_mutation } from '../../../services/routes/mutations';
import { IRoutesCreateRequest } from '../../../services/routes/types';
import CustomSubmitButton from '../../../components/Common/CustomButton/CustomSubmitButton';
import CustomButton from '../../../components/Common/CustomButton/CustomButton';
import { useFilterStore } from '@/store/zustand';
import { ListPage } from '@/constants/list.enum';

const Create = () => {
  const navigate = useNavigate();
  const zustandFilter = useFilterStore();
  const [form] = Form.useForm();
  const [isLoading, setIsloading] = useState<boolean>(false);
  const breadcrumbItems = [
    {
      label: 'Routes',
      link: '/routes'
    },
    {
      label: 'Create',
      link: '/routes/new'
    }
  ];
  const createRoutesMutation = useMutation(create_routes_mutation, {
    onSuccess: (data: any) => {
      message.success('Routes added successfully');
      zustandFilter.resetState(ListPage.ROUTE);

      setIsloading(false);
      navigate('/routes');
    },
    onError: (e: any) => {
      setIsloading(false);
      message.error(`${e.response.data.message}`, 5);
    }
  });

  const onFinish = (values: IRoutesCreateRequest) => {
    setIsloading(true);
    if (!values.addressIds) values.addressIds = [];
    if (!values.path) values.path = JSON.stringify({});
    createRoutesMutation.mutate(values);
  };
  const [responsize, setResponsize] = useState(false);
  useEffect(() => {
    if (window.innerWidth <= 650) {
      // setCollapsed(true);
      setResponsize(true);
    } else {
      setResponsize(false);
    }
    function handleResize() {
      if (window.innerWidth <= 650) {
        // setCollapsed(true);
        setResponsize(true);
      } else {
        setResponsize(false);
      }
    }
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  return (
    <Spin spinning={isLoading}>
      <AppContent breadcrumbItems={breadcrumbItems}>
        <Form
          form={form}
          initialValues={{ isActive: false }}
          onFinish={onFinish}
          layout="vertical"
          validateTrigger={'onChange'}
          autoComplete="off">
          <PageHeader
            title="Routes Information"
            style={{
              padding: '8px 0px'
            }}
          />
          <div
            className={responsize ? 'grid grid-cols-1 gap-3 mb-3' : 'grid grid-cols-3 gap-5 mb-5'}>
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: 'Please add name!' }]}>
              <Input />
            </Form.Item>

            <div className="flex items-center">
              <Form.Item name="isActive" valuePropName="checked">
                <Checkbox className="w-100 text-white" defaultChecked={true}>
                  Is Active
                </Checkbox>
              </Form.Item>
            </div>
          </div>
          <div className="flex justify-end mt-5">
            <Form.Item>
              {/* <Button type="primary" htmlType="submit">
                Submit
              </Button> */}
              <CustomSubmitButton text="Submit" />
            </Form.Item>
            {/* <Button
              type="default"
              className="ml-5"
              htmlType="button"
              onClick={() => navigate('/routes')}>
              Cancel
            </Button> */}
            <CustomButton text="Cancel" textColor="black" onClick={() => navigate('/routes')} />
          </div>
        </Form>
      </AppContent>
    </Spin>
  );
};

export default Create;
