import { NepaliDatePicker } from 'nepali-datepicker-reactjs';
import { Button, DatePicker, Form, Input, InputNumber, Select } from 'antd';

import { ExtendedInitialValues } from '.';
import { CustomDatePresets } from '../utils/datePresets';
import { find_date_preference } from '@/store/localstorage/preferences';
import { WithReportPaginationProps } from '@/components/Common/Report/withReportPagination';
import { LineType } from '@/services/report/enums';
import ProductSearchV2 from '@/components/Common/CustomSearch/Products';
import LocationSearchV2 from '@/components/Common/CustomSearch/Location';
import UnitSearch, { IProductUnitId } from '@/components/Common/CustomSearch/Units';
import { useState } from 'react';
import { IProductDetails } from '@/services/products/types';

interface Props
  extends Omit<WithReportPaginationProps, 'onPagination' | 'getConstraint' | 'pagination'> {
  isLoading: boolean;
  initialValues: ExtendedInitialValues;
  onSearch: (values: ExtendedInitialValues) => void;
  onDownload: () => void;
}

function FilterForm({ isLoading, form, initialValues, ...handler }: Props) {
  const nepaliType = find_date_preference();
  const [productUnitIds, setProductUnitIds] = useState<IProductUnitId[]>([]);

  const locationId = Form.useWatch(['constraints', 'locationId'], form);

  function onProductChange(productDetails?: IProductDetails) {
    if (!productDetails) {
      form.setFieldValue(['constraints', 'unitId'], 'default');
      return setProductUnitIds([]);
    }

    const units = productDetails.productUnits.map((value) => ({
      id: value.unitId,
      isDefault: value.isDefault
    }));

    setProductUnitIds(units);
    form.setFieldValue(['constraints', 'unitId'], 'default');
  }

  return (
    <Form
      form={form}
      layout="vertical"
      autoComplete="off"
      disabled={isLoading}
      validateTrigger="onChange"
      initialValues={initialValues}
      onFinish={handler.onSearch}>
      <div className="grid grid-cols-4 gap-3 ">
        <Form.Item label={'page'} name={['constraints', 'page']} hidden>
          <InputNumber controls={false} />
        </Form.Item>

        <Form.Item label={'size'} name={['constraints', 'size']} hidden>
          <InputNumber controls={false} />
        </Form.Item>

        <Form.Item
          name={['constraints', 'identifier']}
          label="Transaction Type"
          rules={[{ required: true, message: 'Please select a transaction type' }]}>
          <Select placeholder="Select a transaction type">
            {Object.keys(LineType).map((key) => (
              <Select.Option key={key} value={LineType[key as keyof typeof LineType]}>
                {LineType[key as keyof typeof LineType]}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <ProductSearchV2
          hasParentFormItem={false}
          showAll
          name={['constraints', 'productId']}
          locationId={locationId}
          setSelected={(product) => {
            const singleProduct = Array.isArray(product) ? product[0] : product;
            onProductChange(singleProduct);
          }}
        />
        <UnitSearch
          hasParentFormItem={false}
          showAll
          name={['constraints', 'unitId']}
          showAllWithDefaultUnit
          selectedProductUnitIds={productUnitIds}
        />
        <LocationSearchV2 hasParentFormItem={false} showAll name={['constraints', 'locationId']} />

        <Form.Item
          label={'Pick a date'}
          name={['constraints', 'dateCustom']}
          rules={[{ required: true, message: 'Please select date!' }]}
          hidden={nepaliType}>
          <DatePicker.RangePicker
            showTime
            ranges={CustomDatePresets}
            disabledDate={handler.onDisabledDate}
            onChange={handler.onChange}
            format={'YYYY-MM-DD HH:mm'}
            allowClear={false}
          />
        </Form.Item>

        <Form.Item label={'Start Date'} name={['constraints', 'startDate']} hidden>
          <Input />
        </Form.Item>

        <Form.Item label={'End Date'} name={['constraints', 'endDate']} hidden>
          <Input />
        </Form.Item>

        <Form.Item label={'Select Start Date'} name={['startDateNepali']} hidden={!nepaliType}>
          <NepaliDatePicker
            onChange={(val) => handler.onChangeNepali(val, true)}
            options={{ calenderLocale: 'ne', valueLocale: 'en' }}
          />
        </Form.Item>

        <Form.Item label={'Select End Date'} name={['endDateNepali']} hidden={!nepaliType}>
          <NepaliDatePicker
            onChange={(val) => handler.onChangeNepali(val, false)}
            options={{ calenderLocale: 'ne', valueLocale: 'en' }}
          />
        </Form.Item>

        {/* // Buttons for submitting and downloading the form */}
        <div className="flex mt-7 gap-1">
          <Form.Item>
            <Button type="primary" htmlType="submit" style={{ borderRadius: '6px' }}>
              Search
            </Button>
          </Form.Item>

          <Form.Item>
            <Button
              className="secondary-button"
              style={{ borderRadius: '6px' }}
              onClick={handler.onDownload}>
              Download
            </Button>
          </Form.Item>
        </div>
      </div>
    </Form>
  );
}

export default FilterForm;
