import { useEffect } from 'react';
import { debounce } from 'lodash';
import { useFilterStore } from '@/store/zustand';
import { useLocation } from 'react-router-dom';

interface Props {
  totalLength: number;
  tableRef?: React.RefObject<HTMLDivElement | undefined>;
}

const useWindowScrollRestoration = ({ totalLength, tableRef }: Props) => {
  const zustandFilter = useFilterStore();
  const location = useLocation();

  const handleScroll = debounce(() => {
    const windowScroll = { x: window.scrollX, y: window.scrollY };

    const tableBody = tableRef?.current?.querySelector('.ant-table-body');
    const tableScroll = tableBody ? { x: tableBody.scrollLeft, y: tableBody.scrollTop } : undefined;

    // Get the current scroll state from Zustand
    const currentScrollState = zustandFilter.getState(location.pathname)?.scrollPosition;

    const isWindowChanged =
      currentScrollState?.window?.x !== windowScroll.x ||
      currentScrollState?.window?.y !== windowScroll.y;

    const isTableChanged =
      currentScrollState?.table?.x !== tableScroll?.x ||
      currentScrollState?.table?.y !== tableScroll?.y;

    if (isWindowChanged || isTableChanged) {
      zustandFilter.setScrollPosition(location.pathname, {
        window: windowScroll,
        table: tableScroll
      });
    }
  }, 1000);

  // Restore scroll position when data is available
  useEffect(() => {
    if (totalLength) {
      const scrollState = zustandFilter.getState(location.pathname)?.scrollPosition;

      // Restore the window scroll position
      if (scrollState?.window) {
        window.scrollTo(scrollState.window.x, scrollState.window.y);
      } else {
        window.scrollTo(0, 0);
      }

      // Restore table scroll position
      if (tableRef?.current) {
        const tableBody = tableRef.current.querySelector('.ant-table-body');
        if (tableBody) {
          tableBody.scrollLeft = scrollState?.table?.x || 0;
          tableBody.scrollTop = scrollState?.table?.y || 0;
        }
      }
    }

    // Cleanup debounce function on component unmount
    return () => handleScroll.cancel();
  }, [totalLength, location.pathname]);

  // Bind the scroll handler to the window scroll event
  useEffect(() => {
    const tableBody = tableRef?.current?.querySelector('.ant-table-body');
    window.addEventListener('scroll', handleScroll);
    tableBody?.addEventListener('scroll', handleScroll);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
      tableBody?.removeEventListener('scroll', handleScroll);
      handleScroll.cancel();
    };
  }, [location.pathname]);
};

export default useWindowScrollRestoration;
