import moment from 'moment';
import { Modal, List, Typography } from 'antd';
import { useState, useEffect } from 'react';

import { get_feature_request_list } from '@/services/supports/queries';
import { IFeatureRequest } from '@/services/supports/types';
import { EFeatureRequestProgress } from '@/services/supports/enum';
import { Link, useLocation } from 'react-router-dom';
import { getUserData } from '@/utils/auth.utils';
import { IUserPreferences } from '@/services/settings/types';
import { setLocalStorage } from '@/utils/storage.utils';
import { LAST_FEATURE_SHOWCASE, USER_INFO } from '@/constants/config';
import { update_preference_user_mutation } from '@/services/users/mutations';
import { find_feature_showcase_date } from '@/store/localstorage/preferences';

const LatestFeatureShowCase: React.FC = () => {
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);
  const [completedFeatures, setCompletedFeatures] = useState<IFeatureRequest[]>([]);

  async function save_last_visit() {
    const userData = getUserData();
    const hasUserPreferences = 'preferences' in userData && userData.preferences;

    const lastVisited = moment().utc().format();

    if (!hasUserPreferences) {
      localStorage.setItem(LAST_FEATURE_SHOWCASE, lastVisited);
      return;
    }

    const defaultLocalStoragePref = userData.preferences.preferences;
    let userPreferences: IUserPreferences | undefined = undefined;
    try {
      userPreferences = JSON.parse(defaultLocalStoragePref);
    } catch (error) {
      userPreferences = undefined;
    }

    if (!userPreferences) {
      localStorage.setItem(LAST_FEATURE_SHOWCASE, lastVisited);
      return;
    }

    try {
      userPreferences.lastFeatureModalShown = lastVisited;
      setLocalStorage(USER_INFO, {
        ...userData,
        preferences: {
          ...userData.preferences,
          preferences: JSON.stringify(userPreferences)
        }
      });

      await update_preference_user_mutation({
        id: userData.id,
        value: JSON.stringify(userPreferences)
      });
    } catch (error) {
      setLocalStorage(USER_INFO, {
        ...userData,
        preferences: {
          ...userData.preferences,
          preferences: defaultLocalStoragePref
        }
      });

      localStorage.setItem(LAST_FEATURE_SHOWCASE, lastVisited);
    }
  }

  useEffect(() => {
    let lastVisit = find_feature_showcase_date();
    if (!lastVisit) {
      lastVisit = localStorage.getItem(LAST_FEATURE_SHOWCASE) || '';
    }

    // Show Modal if last visit is more than 1 hour
    const lastVisitDate = lastVisit ? new Date(lastVisit) : null;
    const oneHourAgo = new Date(Date.now() - 60 * 60 * 1000);

    const shouldFetch = !lastVisitDate || lastVisitDate < oneHourAgo;
    if (shouldFetch) {
      const startDate = moment().subtract(7, 'days').startOf('day').utc().format();
      const endDate = moment().endOf('day').utc().format();

      const params = new URLSearchParams();
      params.append('startDate', startDate);
      params.append('endDate', endDate);
      params.append('status', EFeatureRequestProgress.COMPLETED);
      params.append('checkByCompletedAt', 'YES');

      get_feature_request_list(params.toString())
        .then(async (data) => {
          await save_last_visit();
          if (data?.results?.length > 0) {
            if (!lastVisitDate) {
              setCompletedFeatures(data.results);
              setShowModal(true);
              return;
            }

            const newFeatures = data.results.filter((feature) => {
              if (!feature.completedAt) return false;
              const completedAtDate = new Date(feature.completedAt);
              return completedAtDate > lastVisitDate;
            });

            if (newFeatures.length > 0) {
              setCompletedFeatures(data.results);
              setShowModal(true);
            }
          } else {
            setCompletedFeatures(data.results);
            setShowModal(false);
          }
        })
        .catch(console.error);
    }
  }, [location.pathname]);

  return (
    <Modal
      bodyStyle={{ paddingTop: 0 }}
      title="New Features"
      visible={showModal}
      onCancel={() => setShowModal(false)}
      footer={null}>
      <Typography.Text className="mb-3 inline-block">
        These are the new features that were recently added:
      </Typography.Text>

      <List
        size="small"
        dataSource={completedFeatures}
        renderItem={(feature, index) => (
          <List.Item
            key={feature.id}
            style={{ padding: 0 }}
            extra={
              <Link to={`/support/feature-request/${feature.id}`} target="_blank">
                View
              </Link>
            }>
            <List.Item.Meta
              title={
                <b>
                  {index + 1}. {feature.systemMessage}
                </b>
              }
            />
          </List.Item>
        )}
      />
    </Modal>
  );
};

export default LatestFeatureShowCase;
