import AppContent from '@/components/Common/Content/Content';
import { get_self_attendance_list } from '@/services/hr/queries';
import { IAttendanceList } from '@/services/hr/types';
import { ConvertObjectToURL } from '@/utils/converturl';
import getErrorMessage from '@/utils/getError';
import handlePagination from '@/utils/handlePagination';
import { Form, Spin } from 'antd';
import { useEffect, useState } from 'react';
import Table from './Table';
import TableFilter from '@/components/FliterTable';
import moment from 'moment';
import useBroadcast from '@/hooks/useBroadcast';
import { checkAccess } from '@/routes/acl';
import CustomButton from '@/components/Common/CustomButton/CustomButton';
import { useLocation } from 'react-router-dom';
import { useFilterStore } from '@/store/zustand';

function SelfAttendanceListPage() {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState({ page: 1, size: 100 });
  const [data, setData] = useState<IAttendanceList>({ total: 0, results: [] });

  const [initialFilter, setInitialFilter] = useState('');

  const { attendanceStatus } = useBroadcast();
  const location = useLocation();
  const zustandFilter = useFilterStore();

  useEffect(() => {
    if (initialFilter) {
      onSubmitFilter(initialFilter).then((data) => {
        zustandFilter.updateState(location.pathname, { data });
      });
    }
  }, [attendanceStatus]);

  async function onSubmitFilter(filter: string) {
    const data = await getInfo(filter);
    setPagination({ page: 1, size: 100 });
    setInitialFilter(filter);
    return data;
  }

  async function getInfo(filter = '') {
    try {
      setIsLoading(true);
      const response = await get_self_attendance_list(filter);
      setData(response);
      return response;
    } catch (error) {
      getErrorMessage(error, true);
    } finally {
      setIsLoading(false);
    }
  }

  const onPagination = async (page = 1, size = 100, isSize = false) => {
    setIsLoading(true);
    const { values, ...pagination } = handlePagination({
      formValues: form.getFieldsValue(),
      page,
      size,
      isSize
    });

    setPagination((prev) => {
      prev.page = pagination.page;
      if (isSize) prev.size = pagination.size;
      return prev;
    });

    const url = ConvertObjectToURL(values);
    return await getInfo(url);
  };

  return (
    <Spin spinning={isLoading}>
      <AppContent
        breadcrumbItems={[
          { label: 'HR', link: '/hr' },
          { label: 'Attendance', link: '/hr/attendance' },
          { label: 'Self' }
        ]}
        withfilter
        button={
          <TableFilter
            initial
            form={form}
            onInitialLoad={({ data, pagination }) => {
              setPagination(pagination);

              if (data) {
                setData(data);
                setIsLoading(false);
              }
            }}
            onSubmit={onSubmitFilter}
            defaultValues={{
              dateCustom: [moment(0, 'HH'), moment(0, 'HH').add(1, 'days')],
              skip: 0,
              count: 100
            }}
            buttons={
              <>
                {checkAccess('READ_ALL_USER_HR') && (
                  <CustomButton
                    text="Calendar"
                    backgroundColor="#1890ff"
                    Linkto="/hr/calendar/self"
                  />
                )}
              </>
            }
          />
        }>
        <Table form={form} data={data} pagination={pagination} onPagination={onPagination} />
      </AppContent>
    </Spin>
  );
}

export default SelfAttendanceListPage;
