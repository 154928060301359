import { message } from 'antd';
import { isValidUrl } from './isValidUrl';

export function downloadFile(url: string): void {
  if (!isValidUrl(url)) {
    throw new Error('Invalid URL');
  }

  const link = document.createElement('a');
  link.href = url;
  link.download = '';
  link.target = '_self';
  document.body.appendChild(link);
  link.click();
  message.success('File is being downloaded.');
  document.body.removeChild(link);
}
