import { ICustomerReportType, IVendorReportType } from '@/services/report/types';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';

type ReportTypeMap = {
  customer: ICustomerReportType;
  vendor: IVendorReportType;
};

export function getExportTableColumns<T extends keyof ReportTypeMap>(
  data: ReportTypeMap[T][],
  type: T
) {
  const dateValues = data.reduce((acc, vendor) => {
    for (const date in vendor) {
      if (
        ![
          'vendorId',
          'vendorName',
          'vendorPhone',
          'customerId',
          'customerName',
          'customerPhone'
        ].includes(date)
      ) {
        acc[date] = true;
      }
    }
    return acc;
  }, {} as { [key: string]: boolean });

  const dateKeys = Object.keys(dateValues).sort();

  const columns = [
    {
      label: 'Name',
      dataIndex: type === 'customer' ? 'customerName' : 'vendorName',
      render: (value: string) => <div className="text-center">{value}</div>,
      isNumber: false
    },
    {
      label: 'Phone',
      dataIndex: type === 'customer' ? 'customerPhone' : 'vendorPhone',
      render: (value: string) => <div className="text-center">{value}</div>,
      isNumber: false
    },

    // Dynamically creating columns for each date
    ...dateKeys.map((dateKey) => ({
      label: dateKey,
      dataIndex: dateKey,
      isNumber: true,
      render: (value: number | undefined) => (
        <div className="text-center">{value ? nepaliNumberFormatter(value) : ''}</div>
      )
    }))
  ];

  return columns;
}

export function getExportTableData(data: ICustomerReportType[], type: 'customer' | 'vendor') {
  const tableColumns = getExportTableColumns(data, type);
  return tableColumns.map((column) => ({
    title: column.label,
    dataIndex: column.dataIndex,
    isNumber: column.isNumber
  }));
}
