export function parseMultipleParams(filter: string, source: string, target: string) {
  const params = new URLSearchParams(filter);
  const values = params.get(source);
  params.delete(source);
  if (!values) return params.toString();

  const list = values.split(',');
  list.forEach((value) => params.append(target, value));
  return params.toString();
}
