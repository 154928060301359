import AppContent from '@/components/Common/Content/Content';
import { ConvertObjectToURL } from '@/utils/converturl';
import getErrorMessage from '@/utils/getError';
import handlePagination from '@/utils/handlePagination';
import { Form, Input } from 'antd';
import { useEffect, useState } from 'react';
import Table from './Table';
import TableFilter from '@/components/FliterTable';
import moment from 'moment';

import { get_opening_stock_history } from '@/services/stock/queries';
import { IOpeningStockHistoryList } from '@/services/stock/types';
import LocationSearchV2 from '@/components/Common/CustomSearch/Location';
import ProductSearchV2 from '@/components/Common/CustomSearch/Products';
import ProductsDB from '@/store/localstorage/ProductsDB';
import { getLocationByList, getProductById, getUnit } from '@/services';
import { useParams } from 'react-router-dom';
import { parseMultipleParams } from '@/utils/parseMultipleParams';

function OpeningStockHistoryList() {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(true);
  const [pagination, setPagination] = useState({ page: 1, size: 100 });
  const [data, setData] = useState<IOpeningStockHistoryList>({ total: 0, results: [] });

  const { id: openingStockId } = useParams<{ id: string }>();

  useEffect(() => {
    if (openingStockId) {
      form.setFieldValue('openingStockId', openingStockId);
      const parsedFormValue = handlePagination({
        formValues: form.getFieldsValue(),
        page: 1,
        size: 100,
        isSize: true
      });

      onSubmitFilter(ConvertObjectToURL(parsedFormValue.values));
    }
  }, [openingStockId]);

  async function onSubmitFilter(filter: string) {
    const data = await getInfo(filter);
    setPagination({ page: 1, size: 100 });
    return data;
  }

  async function getInfo(filter = '') {
    try {
      setIsLoading(true);
      const params = new URLSearchParams(filter);
      const filterStockId = params.get('openingStockId');

      if (!filterStockId && openingStockId) {
        params.set('openingStockId', openingStockId);
      }

      const updatedFilter = parseMultipleParams(
        params.toString(),
        'openingStockId',
        'openingStockIds[]'
      );

      const filterWithProducts = parseMultipleParams(updatedFilter, 'productId', 'productIds[]');
      const response = await get_opening_stock_history(filterWithProducts);

      const allUniqueProductIds = Array.from(new Set(response.results.map((u) => u.productId)));
      await ProductsDB.addProductsIfAbsent(allUniqueProductIds);

      for (const history of response.results) {
        const product = await getProductById(history.productId);
        const unit = await getUnit(history.unitId);
        const location = await getLocationByList(history.locationId);

        history.productName = product.name;
        history.unitName = unit.name;
        history.locationName = location.name;
      }

      setData(response);
      return response;
    } catch (error) {
      getErrorMessage(error, true);
    } finally {
      setIsLoading(false);
    }
  }

  const onPagination = async (page = 1, size = 100, isSize = false) => {
    setIsLoading(true);
    const { values, ...pagination } = handlePagination({
      formValues: form.getFieldsValue(),
      page,
      size,
      isSize
    });

    setPagination((prev) => {
      prev.page = pagination.page;
      if (isSize) prev.size = pagination.size;
      return prev;
    });

    const url = ConvertObjectToURL(values);
    return await getInfo(url);
  };

  return (
    <div>
      <AppContent
        breadcrumbItems={[{ label: 'Opening Stock', link: '/open-stock' }, { label: 'History' }]}
        withfilter
        button={
          <TableFilter
            initial={!openingStockId}
            dateCustom={!openingStockId}
            allowSaved={!openingStockId}
            form={form}
            onInitialLoad={({ data, pagination }) => {
              setPagination(pagination);

              if (data) {
                setData(data);
                setIsLoading(false);
              }
            }}
            onSubmit={onSubmitFilter}
            defaultValues={{
              dateCustom: [moment(0, 'HH'), moment(0, 'HH').add(1, 'days')],
              skip: 0,
              count: 100,
              locationId: ''
            }}
            styleforbuttons={'flex justify-end items-center'}>
            <LocationSearchV2
              hasParentFormItem={false}
              name="locationId"
              showAll
              hidden={!!openingStockId}
            />
            <ProductSearchV2 hasParentFormItem={false} name="productId" isMultiple />
            <Form.Item name="openingStockId" label="Opening Stock ID" hidden>
              <Input />
            </Form.Item>
          </TableFilter>
        }>
        <Table
          form={form}
          data={data}
          isLoading={isLoading}
          pagination={pagination}
          onPagination={onPagination}
        />
      </AppContent>
    </div>
  );
}

export default OpeningStockHistoryList;
