import { useQuery, useMutation } from '@tanstack/react-query';
import {
  Form,
  Input,
  Select,
  InputNumber,
  message,
  PageHeader,
  Divider,
  Checkbox,
  Card,
  Spin,
  UploadFile,
  UploadProps,
  Upload,
  Button
} from 'antd';
// import { useWatch } from 'antd/lib/form/Form';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import AppContent from '@/components/Common/Content/Content';
import CustomButton from '@/components/Common/CustomButton/CustomButton';
import CustomSubmitButton from '@/components/Common/CustomButton/CustomSubmitButton';
import { LocationSearch } from '@/components/Common/LocationSearch/LocationSearch';
import ProductSearchForLines from '@/components/Common/ProductSearch2';
import ReusableQuantity from '@/components/Common/ReusableQuantity';
import { VendorSearch } from '@/components/Common/VendorSearch/VendorSearch';
import useDebounce from '@/hooks/useDebounce';
import { create_tasks_mutation } from '@/services/procurement/mutations';

import {
  get_product_list,
  get_units_list,
  get_unexpired_lots_details_bylocationId_productId,
  prices_by_groupId,
  get_price_groups_by_location,
  get_unexpired_lots_details_bylocationId_productIds
} from '@/services/products/queries';
import { ILotDetails, IProductType, IUnits } from '@/services/products/types';
import { create_purchase_order_mutation } from '@/services/purchases/mutations';
import { ICreatePurchaseOrder, IPurchaseAgentBillRange, Line } from '@/services/purchases/types';
import { ILines, IProductUnits } from '@/services/sell/types';
import { get_vendor_list } from '@/services/users/queries';
import {
  find_default_product_category,
  find_financial_year,
  find_locationId_preference,
  find_purchase_confirmation
} from '@/store/localstorage/preferences';
import UnitsDB from '@/store/localstorage/UnitsDB';
import { getUserData } from '@/utils/auth.utils';
import { numberDecimalFormatter } from '@/utils/numberFormatter';
import { CustomModal } from '@/components/Common/CustomModal';
import ReusablePurchaseOrderConfirm from '@/components/Common/CustomModal/ReusablePurchaseOrderConfirm';
import ProductsDB from '@/store/localstorage/ProductsDB';
import { WebSocketContext } from '@/contexts/websocket.context';
import { SocketEvents, SystemNotificationType } from '@/constants/websocketConfig';
import { PurchaseBuyerType, PurchaseType } from '@/services/purchases/enums';
import ProductCategorySearch from '@/components/Common/ProductCategorySearch/ProductCategorySearch';
import useBeforeUnloadListener from '@/hooks/useBeforeUnloadListener';
import AgentsSearch from '@/components/Common/AgentsSearch';
import useDebounceFunction from '@/hooks/useDebounceFunction';
import { AgentsDB } from '@/store/localstorage/AgentsDB';
import {
  get_purchase_agent_bill_cancel_list,
  get_purchase_agent_bill_list
} from '@/services/purchases/queries';
import getError from '@/utils/getError';
import { onPreviewImage } from '@/services/upload/services';
import { UploadOutlined } from '@ant-design/icons';
import { uploadImagesToServer } from '@/services/upload/queries';
import VendorSearchV2 from '@/components/Common/CustomSearch/Vendors';
import { getVendors } from '@/pages/expense/view/services.expense';
import { get_account_details_by_userid_type } from '@/services/accounts/queries';
import { AccountType } from '@/services/accounts/enums';
import DebouncedInputNumber from '@/components/Common/InputNumber';
import { useFilterStore } from '@/store/zustand';
import { ListPage } from '@/constants/list.enum';

const { Option } = Select;

const CreatePurchaseOrder = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { socket } = useContext(WebSocketContext);
  const [isTask, setIsTask] = useState<boolean>(true);
  const [allLocalUnits, setAllLocalUnits] = useState<any>([]);
  const [isloading, setIsloading] = useState<boolean>(false);
  const [totalLots, settotalLots] = useState<any>([]);
  const loggedInUser = getUserData();
  //product search
  const [searchValue] = useState<string>('');
  const [productSearch, setProductSearch] = useState<any[]>([]);
  const [productList, setProductList] = useState<any[]>([]);
  const debouncedSearchValue = useDebounce(searchValue, 500);
  const [confirmModalValues, setConfirmModalValues] = useState<ICreatePurchaseOrder>();
  const [modalOpenForConfirmation, setModalOpenForConfirmation] = useState<boolean>(false);

  const [createValues, setCreateValues] = useState<ICreatePurchaseOrder>();
  useEffect(() => {
    searchProduct(debouncedSearchValue);
  }, [debouncedSearchValue]);

  // const { data: locationsList } = useQuery(['locations'], async () => get_location_list());
  const { data: usersList } = useQuery(['users'], async () => get_vendor_list());
  const [defaultPriceBylocationId, setDefaultPriceBylocationId] = useState<any>({});
  const preferenceLocationId = find_locationId_preference();
  const [productCategory, setProductCategory] = useState<number | string | null | undefined>(
    find_default_product_category()
  );
  const breadcrumbItems = [
    { label: 'Purchases Order', link: '/purchase/order' },
    { label: 'Create', link: '/purchase/order/new' }
  ];
  const autofocusRef: React.Ref<any> = useRef(null);
  const zustandFilter = useFilterStore();
  const createPurchaseOrderMutation = useMutation(create_purchase_order_mutation, {
    onSuccess: () => zustandFilter.resetState(ListPage.PURCHASE_ORDER)
  });

  const createTaskMutation = useMutation(create_tasks_mutation, {
    onSuccess: (data: any) => {
      message.success('Task added successfully');
      setIsloading(false);
      navigate('/purchase/order');
    }
  });

  const [baseInvoice, setBaseInvoice] = useState('');
  const [isSelfInvoice, setIsSelfInvoice] = useState(false);
  const [selectedAgentId, setSelectedAgentId] = useState<number>();

  useEffect(() => {
    socket?.on('connect', async () => {
      // console.log('Socket Reconnected');
      const locationId = form.getFieldValue(['locationId']);
      const lines = form.getFieldValue(['lines']);
      const productIds = new Set<number>(
        lines.map((value: ILines) => {
          return value.productId;
        })
      );
      await fetchLotsOnLocationandProductChange([...productIds], locationId, 'lotsupdate');
    });

    socket?.on(SocketEvents.SYSTEM_NOTIFICATION, async (data) => {
      if (data.type === SystemNotificationType.LOTS_ZERO) {
        const locationId = form.getFieldValue(['locationId']);
        const socketData = data.data as { locationId: number };
        if (socketData.locationId === locationId) {
          settotalLots((prev: any) => {
            return prev.map((a: any) => ({ ...a, qtyAvailable: 0 }));
          });
        }
      }

      if (data.type === SystemNotificationType.LOTS_UPDATE) {
        const lines = form.getFieldValue(['lines']);
        const productIds = new Set<number>(lines.map((value: ILines) => value.productId));

        const locationId = form.getFieldValue(['locationId']);
        // Get Products and filter by Location
        let updatedProducts = data.data as { productId: number; locationId: number }[];
        if (locationId) {
          updatedProducts = updatedProducts.filter(
            (value) =>
              value.locationId === locationId && Array.from(productIds).includes(value.productId)
          );
        }

        if (updatedProducts.length > 0) {
          const updatedProductIds = updatedProducts.map((value) => value.productId);
          await fetchLotsOnLocationandProductChange(updatedProductIds, locationId, 'lotsupdate');
        }
      }
    });

    return () => {
      socket?.off(SocketEvents.SYSTEM_NOTIFICATION);
    };
  }, [socket?.connected]);

  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const handleFileChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const onFinish = useDebounceFunction(async (values: any) => {
    setIsloading(true);
    try {
      //validation
      if (values.lines.length == 0)
        throw {
          name: 'LinesError',
          message: 'Please select atleast one Product.'
        };

      delete values.invoiceNumber;
      values.isSelfInvoice = isSelfInvoice;

      // if (productCategory !== null) {
      if (productCategory) {
        if (typeof productCategory === 'number') {
          values.categoryId = productCategory;
        } else {
          const defaultCategory = find_default_product_category();
          if (defaultCategory === null) {
            throw {
              name: 'CategoryError',
              message: 'Please select a default Product Category from preferences.'
            };
          } else {
            values.categoryId = defaultCategory;
          }
        }
      }

      const uploadImagesResponse = await uploadImagesToServer(values.media);
      if (uploadImagesResponse.length !== 0) {
        values.mediaId = uploadImagesResponse[0]?.id;
        delete values.media;
      }

      let totalWithoutDiscount = 0;

      for (let i = 0; i < values.lines.length; i++) {
        totalWithoutDiscount += values.lines[i].total + values.lines[i].vat;
      }

      if (totalWithoutDiscount > values.discount) {
        values.date = JSON.stringify(new Date()).slice(1, -1);
        values.createdBy = loggedInUser.id;
        setCreateValues(values);
        if (find_purchase_confirmation()) {
          setIsloading(false);
          const modalValues = values;
          confirmationPopup(modalValues);
        } else {
          createPurchaseOrderMutation.mutateAsync(values, {
            onSuccess: async ({ data }: { data: any }) => {
              if (data) {
                message.success('Purchase order added successfully');
                if (isTask) {
                  const found = usersList?.data.results.find(
                    (value: any) => value.id === data.vendorId
                  );
                  // console.log(found);
                  createTaskMutation.mutate({
                    name: ' ',
                    description: ' ',
                    assignee: found?.userId,
                    reference: 'purchase_order',
                    referenceId: data.id,
                    status: 'assigned',
                    dueDate: new Date(new Date().getTime() + 10 * 24 * 60 * 60 * 1000)
                  });
                } else {
                  setIsloading(false);
                  navigate('/purchase/order');
                }
              }
            }
          });
        }
      } else {
        setIsloading(false);
        form.setFields([
          { name: 'discount', errors: ['Discount cannot be greater than grand total'] }
        ]);
      }
    } catch (errors: any) {
      setIsloading(false);
      if ('name' in errors) message.error(errors.message);
      else message.error(getError(errors));
    }
  });

  const confirmationPopup = (values: ICreatePurchaseOrder) => {
    //console.log(values);
    setConfirmModalValues(values);
    setModalOpenForConfirmation(true);
  };

  const handleConfirmationModalSubmit = async () => {
    setIsloading(true);
    if (createValues) {
      await createPurchaseOrderMutation.mutateAsync(createValues, {
        onSuccess: async ({ data }: { data: any }) => {
          if (data) {
            message.success('Purchase order added successfully');
            if (isTask) {
              const found = usersList?.data.results.find(
                (value: any) => value.id === data.vendorId
              );
              createTaskMutation.mutate({
                name: ' ',
                description: ' ',
                assignee: found?.userId,
                reference: 'purchase_order',
                referenceId: data.id,
                status: 'assigned',
                dueDate: new Date(new Date().getTime() + 10 * 24 * 60 * 60 * 1000)
              });
              setIsloading(false);
            } else {
              setIsloading(false);
              navigate('/purchase/order');
            }
          }
        },
        onError: (e: any) => {
          message.error(getError(e), 5);
          setIsloading(false);
        }
      });
    }
  };

  const fetchLotsOnLocationandProductChange = async (
    productsIdArray: number[],
    locationId: number,
    from: string
  ) => {
    try {
      if (!locationId) {
        throw {
          name: 'Location Error',
          message: 'Please select Location!'
        };
      }

      if (!productsIdArray.length) {
        return;
      }

      const currenttotalLots = [];
      if (from === 'productchange') {
        const filterLots = totalLots.find((value: any) => value.productId == productsIdArray[0]);
        if (!filterLots) {
          const response = await get_unexpired_lots_details_bylocationId_productId(
            locationId,
            productsIdArray[0]
          );
          settotalLots([...totalLots, ...response]);
        }
      } else if (from === 'lotsupdate') {
        const result = await get_unexpired_lots_details_bylocationId_productIds(locationId, [
          ...new Set(productsIdArray)
        ]);

        settotalLots((prev: ILotDetails[]) => {
          const filterLots = prev.filter((value) => !productsIdArray.includes(value.productId));
          return [...filterLots, ...result];
        });
      } else {
        const result = await get_unexpired_lots_details_bylocationId_productIds(locationId, [
          ...new Set(productsIdArray)
        ]);
        currenttotalLots.push(...result);
        settotalLots([...currenttotalLots]);
      }
    } catch (err: any) {
      message.error(err.message);
    }
  };

  const onLocationChange = async (value: number) => {
    form.setFieldValue('location', value);
    const mySet = new Set<number>();
    const data = form.getFieldValue(['lines']);
    if (data) {
      data.map((curr: any, ind: number) => {
        mySet.add(curr.productId);
        form.setFieldValue(['lines', ind, 'lotId'], null);
      });
    }
    form.setFieldValue('location', value);
    fetchLotsOnLocationandProductChange(Array.from(mySet), value, 'location');

    let defaultPrice: any = [];
    if (defaultPriceBylocationId[value]) {
      defaultPrice = defaultPriceBylocationId[value];
    } else {
      const response = await get_price_groups_by_location(value);
      // console.log('resposne===>', response);
      if (response.data[0]?.id) {
        const response2 = await prices_by_groupId(response.data[0].id);
        // console.log('response 2-->', response2);
        defaultPrice = [...response2.data];
        setDefaultPriceBylocationId({ ...defaultPriceBylocationId, [value]: response2.data });
      } else {
        data.map((curr: any, ind: number) => {
          form.setFieldValue(['lines', ind, 'unitPrice'], undefined);
          form.setFieldValue(['lines', ind, 'sellingPrice'], undefined);
        });
      }
    }
    // console.log('defaultPrice', defaultPrice);
    if (defaultPrice.length > 0) {
      data.map((curr: any, ind: number) => {
        const findone = defaultPrice.find((res2elem: any) => res2elem.productId === curr.productId);
        // console.log('findOne', findone);
        if (findone) {
          const alreadySelectedUnitId = form.getFieldValue(['lines', ind, 'unitId']);
          // console.log('alreadySelectedUnitId', alreadySelectedUnitId);
          if (alreadySelectedUnitId !== undefined && alreadySelectedUnitId !== null) {
            const findUnit = allLocalUnits.find((curr: any) => curr.id === alreadySelectedUnitId);
            // console.log('findUnit', findUnit);
            form.setFieldValue(
              ['lines', ind, 'unitPrice'],
              findUnit.baseUnitMultiplier * findone.sellingPrice
            );
          } else {
            form.setFieldValue(['lines', ind, 'unitPrice'], findone.sellingPrice);
          }
          form.setFieldValue(['lines', ind, 'sellingPrice'], findone.sellingPrice);
        } else {
          form.setFieldValue(['lines', ind, 'unitPrice'], undefined);
          form.setFieldValue(['lines', ind, 'sellingPrice'], undefined);
        }
      });
    } else {
      data.map((curr: any, ind: number) => {
        form.setFieldValue(['lines', ind, 'unitPrice'], undefined);
        form.setFieldValue(['lines', ind, 'sellingPrice'], undefined);
      });
    }
  };
  const FilterUnits = (name: any) => {
    const checkCurrentProduct = form.getFieldValue(['lines', name, 'productId']);

    if (checkCurrentProduct && productList) {
      const selectedProduct = productList.find(
        (val: IProductType) => val.id == checkCurrentProduct
      );
      const filteredUnits = selectedProduct.productUnits.map((value: any) => {
        const data = allLocalUnits.find((val: any) => value.unitId == val.id);
        return data;
      });
      return (
        <>
          {filteredUnits.map((value: any) => {
            if (!value) return null;
            return (
              <Option value={value.id} key={value.id}>
                {`${value.name}`}
              </Option>
            );
          })}
        </>
      );
    }
  };

  function onLotChange(name: number, value: number) {
    const productId = form.getFieldValue(['lines', name, 'productId']);
    if (!productId) {
      return message.error('Failed to get product id');
    }

    let filteredLots: any = [];
    if (totalLots.length !== 0) {
      filteredLots = totalLots.filter((currLot: any) => currLot.productId === productId);
    }

    const currentSelectedLot = filteredLots.find((currLot: any) => currLot.id === value);
    if (!currentSelectedLot) {
      return message.error('Failed to assign hs code based on selected lot');
    }

    form.setFieldValue(['lines', name, 'hsCode'], currentSelectedLot?.hsCode);
  }

  const FilterLot = (name: number) => {
    const checkCurrentProduct = form.getFieldValue(['lines', name, 'productId']);

    const currentLocation = form.getFieldValue(['locationId']);

    if (checkCurrentProduct && currentLocation) {
      // const filteredLots = allLots.filter((value: Line) => value.productId == checkCurrentProduct);
      let filteredLots: any = [];
      // console.log('totalLots-->', totalLots);
      if (totalLots.length !== 0) {
        filteredLots = totalLots.filter(
          (currLot: any) => currLot.productId === checkCurrentProduct
        );
        // console.log('filtered Lots-->', filteredLots);
      }
      const unitId = form.getFieldValue(['lines', name, 'unitId']);
      const unitInfo: any = allLocalUnits.find((val: any) => unitId == val.id);
      return (
        <>
          {filteredLots?.map((value: any) => (
            <Option
              value={value.id}
              key={value.id}
              style={{ color: value.qtyAvailable > 0 ? 'green' : 'red' }}>
              {`(${numberDecimalFormatter(
                value.qtyAvailable / (unitInfo?.baseUnitMultiplier || 1)
              )} ${unitInfo?.shortName || ''}) ${value.lotNumber} Grade-${value.grade} Expiry-${
                value?.expirationDate ? new Date(value.expirationDate).toLocaleDateString() : 'N/A'
              }`}
            </Option>
          ))}
        </>
      );
    }
  };

  const onProductChange = async (value: number, name?: any) => {
    // form.setFieldValue([name, 'productId'], value);
    // await checkAndGetLot(value);
    const data = form.getFieldValue(['lines']);
    const currentLocation = form.getFieldValue(['locationId']);
    // await checkAndGetLot(value);
    await fetchLotsOnLocationandProductChange([value], currentLocation, 'productchange');
    if (productSearch) {
      const selectedProduct = productSearch.find((val: any) => val.id == value);
      const unitIdList: IProductUnits[] = selectedProduct.productUnits;
      const defaultUnit = unitIdList.find((currUnit: any) => currUnit.isDefault === true);
      form.setFieldValue(['lines', data.length - 1, 'unitId'], defaultUnit?.unitId);
      let fetch = false;
      for (const value of unitIdList) {
        let unitData;
        try {
          unitData = await UnitsDB.getUnit(value.unitId);
        } catch (e) {
          console.log(e);
        }
        if (!unitData) {
          const response = await get_units_list();
          await UnitsDB.addUnits(response);
          fetch = true;
        }
      }
      if (fetch) setAllLocalUnits(await UnitsDB.getAllUnits());
      if (selectedProduct.purchasePrice) {
        form.setFieldValue(['lines', data.length - 1, 'unitPrice'], selectedProduct.purchasePrice);
      } else {
        let defaultPriceArray: any = [];
        if (defaultPriceBylocationId[currentLocation]) {
          defaultPriceArray = defaultPriceBylocationId[currentLocation];
        } else {
          const response = await get_price_groups_by_location(currentLocation);
          // console.log('resposne===>', response);
          if (response.data[0]?.id) {
            const response2 = await prices_by_groupId(response.data[0].id);
            defaultPriceArray = [...response2.data];
            setDefaultPriceBylocationId({
              ...defaultPriceBylocationId,
              currentLocation: response2.data
            });
            // console.log('response 2-->', response2);
          }
        }
        if (defaultPriceArray.length > 0) {
          const findone = defaultPriceArray.find((res2elem: any) => res2elem.productId === value);
          if (findone) {
            form.setFieldValue(['lines', data.length - 1, 'unitPrice'], findone.sellingPrice);
            form.setFieldValue(['lines', data.length - 1, 'sellingPrice'], findone.sellingPrice);
          }
        } else {
          form.setFieldValue(['lines', data.length - 1, 'unitPrice'], undefined);
        }
      }
    }
  };
  const searchProduct = async (value: any) => {
    if (value != '') {
      let response;
      try {
        response = await get_product_list(0, 5, value);
      } catch (e) {
        console.log(e);
      }
      if (!response || response.data.results.length == 0) {
        message.error('Cannot find any product with that name!');
        setProductSearch([]);
      } else {
        setProductSearch(response.data.results);
        checkProductAndAdd(response.data.results);
      }
    }
    // setSearchLoading(false);
  };

  const checkProductAndAdd = (products: any[]) => {
    if (productList.length > 0) {
      products = products.filter((value) => {
        const searchProduct = productSearch.find((val) => val.id == value.id);
        if (searchProduct) return false;
        return true;
      });
      if (products.length > 0)
        setProductList((prevValue) => {
          return [...prevValue, ...products];
        });
    } else {
      setProductList(products);
    }
  };
  useEffect(() => {
    form.setFieldsValue({
      locationId: preferenceLocationId ? preferenceLocationId : null,
      discount: 0,
      vendorId: null,
      shippingAmount: 0,
      labourCost: 0,
      purchaseBuyerType: PurchaseBuyerType.OTHER,
      // addressId: null,
      // taxId: null,
      lines: []
    });
    initializeUnits();
  }, []);

  useBeforeUnloadListener();

  const initializeUnits = async () => {
    setAllLocalUnits((await UnitsDB.getAllUnits()) as IUnits[]);
  };

  const onUnitChange = (name: number) => {
    const data = form.getFieldValue(['lines']);
    const selectedUnitId = form.getFieldValue(['lines', name, 'unitId']);
    const findOne = allLocalUnits.find((curr: any) => curr.id === selectedUnitId);
    // const prevValue = form.getFieldValue(['lines', name, 'sellingPrice']);
    // form.setFieldValue(['lines', name, 'unitPrice'], prevValue * findOne.baseUnitMultiplier);
  };

  const onUnitandQuantityChange = async (name: number) => {
    // console.log('name', name);
    // console.log('number', value);
    const rate = form.getFieldValue(['lines', name, 'unitPrice']);
    const qty = form.getFieldValue(['lines', name, 'quantity']);
    const mis = form.getFieldValue(['lines', name, 'misc']);
    const dis = form.getFieldValue(['lines', name, 'discount']);

    const total = rate * qty - dis + mis;
    form.setFieldValue(['lines', name, 'total'], total);

    const productId = form.getFieldValue(['lines', name, 'productId']);
    let vat = 0;
    let productDetail = await ProductsDB.getProduct(productId);
    if (!productDetail) {
      const allProducts = await get_product_list();
      await ProductsDB.addProducts(allProducts.data.results);
      productDetail = await ProductsDB.getProduct(productId);
    }
    if (typeof productDetail === 'object' && productDetail.vat) {
      vat = (rate * qty - dis) * (productDetail.vat / 100);
    }
    form.setFieldValue(['lines', name, 'vat'], parseFloat(vat.toFixed(2)));

    const lines = form.getFieldValue(['lines']);
    // console.log('lines', lines);
    let grandTotal = 0;
    const discount = form.getFieldValue('discount');

    // console.log('lines', lines);
    for (let i = 0; i < lines.length; i++) {
      grandTotal += lines[i].total + lines[i].vat;
    }

    const totalWithDiscount = grandTotal - discount;

    form.setFieldValue('grandtotal', numberDecimalFormatter(totalWithDiscount));
    // const grandTotall = form.getFieldValue(['lines','grandtotal']);
    // console.log('grandTotall field value', grandTotall);
  };

  const isProductAlreadyAdded = (index: number, checkproductId: number) => {
    const lines = form.getFieldValue(['lines']);
    const findOne = lines?.find((curr: any) => curr.productId == checkproductId);
    if (findOne) {
      return false;
    }
    return true;
  };
  const onDiscountChange = () => {
    // console.log('lines', lines);
    let grandTotal = 0;
    const discount = form.getFieldValue('discount');

    const lines = form.getFieldValue(['lines']);
    // console.log('lines', lines);
    for (let i = 0; i < lines.length; i++) {
      grandTotal += lines[i].total + lines[i].vat;
    }

    const totalWithDiscount = grandTotal - discount;

    form.setFieldValue('grandtotal', totalWithDiscount);
  };

  const onVatChange = async () => {
    const lines = form.getFieldValue(['lines']);
    // console.log('lines', lines);
    let grandTotal = 0;
    const discount = form.getFieldValue('discount');

    // console.log('lines', lines);
    for (let i = 0; i < lines.length; i++) {
      grandTotal += lines[i].total + lines[i].vat;
    }

    const totalWithDiscount = grandTotal - discount;

    form.setFieldValue('grandtotal', numberDecimalFormatter(totalWithDiscount));
  };

  const [allPurchaseAgentBill, setAllPurchaseAgentBill] = useState<
    Record<number, IPurchaseAgentBillRange[]>
  >({});

  const [billDetails, setBillDetails] = useState<IPurchaseAgentBillRange>();
  const [disabledBillNumber, setDisabledBillNumber] = useState<number[]>([]);

  async function getAgentIdFromUserId(userId: number) {
    const agent = await AgentsDB.getAgentsByUserIds([userId]);
    return agent[0].id;
  }

  async function getAgentPurchaseBillRange(userId: number) {
    const agentId = await getAgentIdFromUserId(userId);

    if (allPurchaseAgentBill[userId]) {
      const current = allPurchaseAgentBill[userId];
      setAllPurchaseAgentBill((prev) => ({ ...prev, [userId]: current }));
      return current;
    }

    const params = new URLSearchParams();
    params.set('agentId', agentId.toString());
    params.set('archiveStatus', 'ACTIVE');
    params.set('page', '1');
    params.set('size', '1000');

    const filter = params.toString();

    const { results: agentBillList } = await get_purchase_agent_bill_list(filter);
    setAllPurchaseAgentBill((prev) => ({ ...prev, [userId]: agentBillList }));
    return agentBillList;
  }

  const onAgentInvoiceChange = async (billId: number) => {
    setIsloading(true);
    const agentBillInvoices = selectedAgentId ? allPurchaseAgentBill[selectedAgentId] : [];
    const value = agentBillInvoices.find((val) => val.id == billId);
    setBillDetails(value);

    if (!value) {
      setIsloading(false);
      console.error('Agent Invoice not found!');
      return;
    }

    const query = new URLSearchParams();
    query.set('agentId', value.agentId.toString());
    query.set('billRangeIds[]', billId.toString());
    query.set('skip', '0');
    query.set('count', '100');

    const { results } = await get_purchase_agent_bill_cancel_list(query.toString());
    const disabledBillNumber = results.map((val) => val.billNumber);
    form.setFieldValue(['agentPurchase', 'billNo'], undefined);
    setDisabledBillNumber(disabledBillNumber);
    setIsloading(false);
  };

  const preferenceFinancialYear = find_financial_year();

  const onVendorChange = async (vendorId: number) => {
    if (vendorId) {
      const vendorDetails = await getVendors(vendorId);
      if (typeof vendorDetails !== 'string') {
        const userId = vendorDetails.userId;
        const userAccount = await get_account_details_by_userid_type(userId, AccountType.USER);

        if (userAccount?.isArchived) {
          message.error({
            content: (
              <span>
                Unable to create purchase order though this vendor. <strong>Reason:</strong>{' '}
                <span className="text-red-500">Account Archived</span>
              </span>
            ),
            duration: 5
          });

          form.setFieldValue(['vendorId'], null);
          return;
        }
      }
    }

    const financialYear = preferenceFinancialYear;

    if (vendorId && financialYear) {
      const formattedInvoiceNumber = `${vendorId}-${financialYear}-`;
      setBaseInvoice(formattedInvoiceNumber);
    }
  };

  return (
    <Spin spinning={isloading}>
      <CustomModal
        isModalOpen={modalOpenForConfirmation}
        setIsModalOpen={setModalOpenForConfirmation}
        title={'Confirmation'}
        footer={false}>
        <ReusablePurchaseOrderConfirm
          disabled={isloading}
          data={confirmModalValues}
          handleSubmitClick={handleConfirmationModalSubmit}
        />
      </CustomModal>
      <AppContent breadcrumbItems={breadcrumbItems}>
        <PageHeader
          title="Order Information"
          style={{
            padding: '8px 0px'
          }}
        />
        <Form
          form={form}
          onFinish={onFinish}
          disabled={isloading}
          layout="vertical"
          validateTrigger={'onChange'}
          autoComplete="off">
          <div className={'grid cols-1 md:grid-cols-3 gap-5 mb-5'}>
            <LocationSearch notAll={true} onSelect={onLocationChange} />
            <VendorSearchV2
              name={['vendorId']}
              required
              hasParentFormItem={false}
              onSelect={(val) => {
                onVendorChange(val);
              }}
            />
            {/* <Form.Item
              name={['vendorId']}
              label="Vendor"
              rules={[
                {
                  required: true,
                  message: 'Please choose Vendor!'
                }
              ]}>
              <VendorSearch
                applyFilter={true}
                withoutForm={true}
                onSelect={(val) => {
                  form.setFieldValue(['vendorId'], val);
                  onVendorChange(val);
                }}
              />
            </Form.Item> */}
            <Form.Item
              label="Agents"
              name={['agentId']}
              rules={[{ required: isSelfInvoice, message: 'Please choose agent!' }]}>
              <AgentsSearch
                hasParentFormItem
                onSelect={(val) => {
                  form.setFieldValue(['agentId'], val);
                  if (isSelfInvoice) {
                    form.setFieldValue('agentPurchase', undefined);
                    form.setFieldValue('invoiceNumber', undefined);
                    getAgentPurchaseBillRange(Number(val));
                  }

                  setSelectedAgentId(Number(val));
                }}
              />
            </Form.Item>
            <Form.Item
              label="Discount"
              name={['discount']}
              rules={[{ required: true, message: 'Please add Discount!' }]}>
              <InputNumber
                controls={false}
                min={0}
                onChange={() => {
                  onDiscountChange();
                }}
              />
            </Form.Item>
            <Form.Item
              label="Shipping"
              name={['shippingAmount']}
              rules={[{ required: true, message: 'Please add shipping amount!' }]}>
              <InputNumber controls={false} min={0} />
            </Form.Item>
            <Form.Item
              label="Labour Cost"
              name={['labourCost']}
              rules={[{ required: true, message: 'Please add labour cost!' }]}>
              <InputNumber controls={false} min={0} />
            </Form.Item>
            <Form.Item
              label="Purchase Type"
              name={['purchaseType']}
              rules={[{ required: true, message: 'Please add purchase type!' }]}>
              <Select placeholder="Select purchase type">
                {(Object.keys(PurchaseType) as Array<keyof typeof PurchaseType>).map((key) => (
                  <Select.Option key={key} value={key}>
                    {PurchaseType[key]}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Buyer Type"
              initialValue={PurchaseBuyerType.OTHER}
              name={['purchaseBuyerType']}
              rules={[{ required: true, message: 'Please add purchase buyer type!' }]}>
              <Select placeholder="Select purchase buyer type">
                {(Object.keys(PurchaseBuyerType) as Array<keyof typeof PurchaseBuyerType>).map(
                  (key) => (
                    <Select.Option key={key} value={key}>
                      {PurchaseBuyerType[key]}
                    </Select.Option>
                  )
                )}
              </Select>
            </Form.Item>
            <LocationSearch
              formData={{ formLabel: 'LCC Location', formName: ['locationLCCId'] }}
              notAll={true}
              required={false}
            />

            <Form.Item name="isSelfInvoice" label="Invoice" valuePropName="checked">
              <Checkbox
                onChange={(value) => {
                  const isChecked = value.target.checked;
                  const selectedAgentId = form.getFieldValue('agentId');
                  if (selectedAgentId && isChecked) getAgentPurchaseBillRange(selectedAgentId);
                  form.setFieldsValue({
                    agentPurchase: undefined,
                    invoiceNumber: ''
                  });

                  setIsSelfInvoice(isChecked);
                }}>
                Self Invoice
              </Checkbox>
            </Form.Item>

            {isSelfInvoice && (
              <>
                <Form.Item
                  label="Agent Invoice"
                  name={['agentPurchase', 'billNoId']}
                  rules={[{ required: isSelfInvoice, message: 'Please add Agent Invoice!' }]}>
                  <Select
                    key={selectedAgentId}
                    placeholder="Select invoice"
                    onChange={(id) => onAgentInvoiceChange(id)}
                    dropdownMatchSelectWidth={false}>
                    {selectedAgentId &&
                      allPurchaseAgentBill[selectedAgentId]?.map((val) => (
                        <Select.Option key={val.id} value={val.id}>
                          <span>
                            Current: {val.current} ({val.min} - {val.max})
                          </span>
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  label="Agent Bill No."
                  rules={[{ required: isSelfInvoice, message: 'Please add bill no.!' }]}
                  name={['agentPurchase', 'billNo']}>
                  <DebouncedInputNumber
                    restrictedNumbers={disabledBillNumber}
                    errorMessage="This bill number is cancelled! Please select another one."
                    debouncedOnChange={(value) => {
                      form.setFieldValue(['agentPurchase', 'billNo'], value);
                      form.setFieldValue('invoiceNumber', `${value || ''}`);
                    }}
                    min={billDetails?.current || 0}
                    max={billDetails?.max}
                  />
                </Form.Item>

                <Form.Item
                  name="media"
                  label="Upload Media"
                  valuePropName="fileList"
                  getValueFromEvent={(e) => e.fileList}>
                  <Upload
                    accept="image/*"
                    multiple={false}
                    maxCount={1}
                    listType="text"
                    onPreview={onPreviewImage}
                    onChange={handleFileChange}
                    beforeUpload={() => false}>
                    <Button icon={<UploadOutlined />}>Click to Upload</Button>
                  </Upload>
                </Form.Item>

                <Form.Item label="Invoice Number" name={'invoiceNumber'}>
                  <Input disabled addonBefore={baseInvoice} />
                </Form.Item>
              </>
            )}
          </div>
          <div className="grid grid-cols-4 gap-5 mb-5"></div>
          <Divider />

          <Form.List name={['lines']}>
            {(fields2, { add: add2, remove: remove2 }) => (
              <>
                <div className={'grid mb-5'}>
                  <PageHeader
                    subTitle="Add Product"
                    style={{
                      padding: '8px 0px 8px 10px'
                    }}
                  />
                  <div className="grid grid-cols-2 gap-4">
                    <ProductCategorySearch
                      setValue={productCategory}
                      onProductCategoryChange={(val) => {
                        setProductCategory(val);
                        if (val !== 'default') {
                          form.setFieldValue(['lines'], []);
                        }
                      }}
                      isAll={false}
                      onClearProduct={() => {
                        setProductCategory(undefined);
                      }}
                      allowClear={true}
                    />
                    <ProductSearchForLines
                      add2={add2}
                      onProductChange={onProductChange}
                      productList={productList}
                      setProductList={setProductList}
                      productSearch={productSearch}
                      setProductSearch={setProductSearch}
                      autofocusRef={autofocusRef}
                      isProductAlreadyAdded={isProductAlreadyAdded}
                      index={0}
                      locationId={form.getFieldValue(['locationId'])}
                      validation={productCategory !== null ? true : false}
                      productCategory={productCategory}
                    />
                  </div>
                  {fields2.length > 0 && (
                    <PageHeader
                      title="All Products"
                      style={{
                        padding: '8px 0px 8px 10px'
                      }}
                    />
                  )}
                </div>
                <Card
                  style={{
                    maxHeight: '50vh',
                    overflowY: 'scroll',
                    // backgroundColor: 'gray',
                    borderRadius: '9px'
                  }}>
                  {fields2.map(({ key: key2, name: name2, ...restField2 }) => (
                    <div className="flex gap-1 items-center" key={key2}>
                      <span className="font-bold text-sm mb-5">{name2 + 1}.</span>
                      <div className="card">
                        <div
                          className={
                            'grid grid-cols-2 gap-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-9'
                          }
                          key={key2}>
                          <Form.Item {...restField2} name={[name2, 'productId']} hidden></Form.Item>
                          <Form.Item
                            {...restField2}
                            name={[name2, 'sellingPrice']}
                            hidden></Form.Item>
                          <Form.Item {...restField2} name={[name2, 'productName']} label="Name">
                            <Input
                              type={'text'}
                              disabled
                              style={{
                                backgroundColor: 'white',
                                color: 'black',
                                // border: '0px',
                                fontWeight: 'bold'
                              }}
                            />
                          </Form.Item>
                          <Form.Item
                            {...restField2}
                            name={[name2, 'unitId']}
                            label="Unit"
                            rules={[
                              {
                                required: true,
                                message: 'Please choose a Unit!'
                              }
                            ]}>
                            {
                              <Select
                                placeholder="Select a Unit!"
                                onChange={() => {
                                  onUnitChange(name2), onUnitandQuantityChange(name2);
                                }}
                                dropdownMatchSelectWidth={false}
                                allowClear>
                                {FilterUnits(name2)}
                              </Select>
                            }
                          </Form.Item>
                          <Form.Item
                            {...restField2}
                            name={[name2, 'lotId']}
                            label="Lot"
                            rules={[
                              {
                                required: true,
                                message: 'Please choose a Lot!'
                              }
                            ]}>
                            {
                              <Select
                                placeholder="Select a Lot!"
                                onChange={(value) => onLotChange(name2, value)}
                                dropdownMatchSelectWidth={false}
                                allowClear>
                                {FilterLot(name2)}
                              </Select>
                            }
                          </Form.Item>
                          {/* <Form.Item
                          {...restField2}
                          label="Quantity"
                          name={[name2, 'quantity']}
                          rules={[{ required: true, message: 'Please add Quantity!' }]}>
                          <InputNumber controls={false}
                            min={1}
                            onChange={() => onUnitandQuantityChange(name2)}
                            autoFocus
                          />
                        </Form.Item> */}
                          <ReusableQuantity
                            name={name2}
                            restField={restField2}
                            allUnits={allLocalUnits}
                            onChangeData={() => onUnitandQuantityChange(name2)}
                            onPressEnterData={(e) => {
                              if (autofocusRef.current) {
                                autofocusRef.current.focus();
                              }
                            }}
                            rules={[
                              { required: true, message: 'Please add Quantity!' },
                              () => ({
                                validator(_: any, value: any) {
                                  if (!value) {
                                    return Promise.reject(`Please input valid Quantity!`);
                                  }
                                  if (value == 0)
                                    return Promise.reject(`Please Input valid Quantity!`);
                                  return Promise.resolve();
                                }
                              })
                            ]}
                          />
                          <Form.Item
                            {...restField2}
                            label="Rate"
                            name={[name2, 'unitPrice']}
                            rules={[
                              { required: true, message: 'Please add Rate!' },
                              () => ({
                                validator(_: any, value: any) {
                                  if (
                                    value == 0 &&
                                    form.getFieldValue(['purchaseBuyerType']) ==
                                      PurchaseBuyerType.SELF
                                  )
                                    return Promise.resolve();

                                  if (!value) {
                                    return Promise.reject(`Please input valid Rate!`);
                                  }
                                  if (value == 0) return Promise.reject(`Please Input valid Rate!`);
                                  return Promise.resolve();
                                }
                              })
                            ]}>
                            <InputNumber
                              controls={false}
                              min={0}
                              onChange={() => onUnitandQuantityChange(name2)}
                            />
                          </Form.Item>
                          <Form.Item
                            {...restField2}
                            label="Discount"
                            name={[name2, 'discount']}
                            rules={[{ required: true, message: 'Please add Discount!' }]}>
                            <InputNumber
                              controls={false}
                              min={0}
                              onChange={() => onUnitandQuantityChange(name2)}
                            />
                          </Form.Item>
                          <Form.Item
                            {...restField2}
                            label="Misc"
                            name={[name2, 'misc']}
                            rules={[{ required: true, message: 'Please add Misc!' }]}>
                            <InputNumber
                              controls={false}
                              min={0}
                              onChange={() => onUnitandQuantityChange(name2)}
                            />
                          </Form.Item>
                          <Form.Item
                            {...restField2}
                            label="VAT"
                            name={[name2, 'vat']}
                            rules={[
                              () => ({
                                validator(_, value) {
                                  if (value !== null) {
                                    return Promise.resolve();
                                  } else {
                                    form.setFieldValue(['lines', name2, 'vat'], 0);
                                    return Promise.resolve();
                                  }
                                }
                              })
                            ]}>
                            <InputNumber
                              controls={false}
                              min={0}
                              style={{ color: 'black' }}
                              onChange={() => onVatChange()}
                            />
                          </Form.Item>
                          <Form.Item {...restField2} name={[name2, 'total']} label="Total">
                            <InputNumber
                              controls={false}
                              min={0}
                              disabled
                              style={{ color: 'black' }}
                            />
                          </Form.Item>
                          <Form.Item {...restField2} label="HS Code" name={[name2, 'hsCode']}>
                            <Input disabled />
                          </Form.Item>
                          {/* <div className="flex justify-center items-center"> */}
                          {fields2.length > 0 ? (
                            // <MinusCircleOutlined
                            //   className="dynamic-delete-button flex justify-center items-center"
                            //   onClick={() => remove2(name2)}
                            // />
                            <div className="flex items-center justify-start mt-5">
                              <CustomButton
                                backgroundColor="white"
                                text="Remove"
                                textColor="green"
                                onClick={() => remove2(name2)}
                              />
                            </div>
                          ) : null}
                          {/* </div> */}
                        </div>
                      </div>
                    </div>
                  ))}
                </Card>
              </>
            )}
          </Form.List>
          {form.getFieldValue(['lines'])?.length > 0 ? (
            <Card
              style={{ borderRadius: '10px', marginBottom: '10px' }}
              className="grid grid-cols-2">
              <Form.Item name={['grandtotal']} label="Grand Total">
                <InputNumber controls={false} min={0} disabled style={{ color: 'black' }} />
              </Form.Item>
            </Card>
          ) : null}
          <Divider />
          <PageHeader
            title="Task"
            style={{
              padding: '8px 0px 8px 10px'
            }}
          />
          <Checkbox
            // defaultChecked
            checked={isTask}
            onChange={(value) => {
              setIsTask(value.target.checked);
            }}>
            Create Task?
          </Checkbox>
          <div className="flex justify-end mt-5">
            <Form.Item>
              {/* <Button type="primary" htmlType="submit">
                Submit
              </Button> */}
              <CustomSubmitButton disabled={isloading} text="Submit" />
            </Form.Item>
          </div>
        </Form>
      </AppContent>
    </Spin>
  );
};

export default CreatePurchaseOrder;
