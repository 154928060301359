import {
  syncUnitsList,
  syncUserList,
  syncAgentsList,
  syncFinancialYrs,
  syncLocationsList,
  syncMenuItems,
  syncProductsList,
  syncRoutesList,
  syncVehicleList,
  syncGlobalSettings,
  syncReportList
} from '@/layouts/sync.services';
import { checkAccess } from '@/routes/acl';
import { SyncOperation } from '../SyncProgressUI';

export const syncOperations: SyncOperation[] = [
  { name: 'Users', func: syncUserList, hasPermission: checkAccess('READ_USER') },
  { name: 'Units', func: syncUnitsList, hasPermission: checkAccess('READ_PRODUCT') },
  { name: 'Products', func: syncProductsList, hasPermission: checkAccess('READ_PRODUCT') },
  { name: 'Locations', func: syncLocationsList, hasPermission: checkAccess('READ_LOCATION') },
  { name: 'Routes', func: syncRoutesList, hasPermission: checkAccess('READ_ROUTE') },
  { name: 'Financial Years', func: syncFinancialYrs, hasPermission: checkAccess('READ_ACCOUNT') },
  { name: 'Agents', func: syncAgentsList, hasPermission: checkAccess('READ_USER') },
  { name: 'Vehicle', func: syncVehicleList, hasPermission: checkAccess('READ_VEHICLE') },
  { name: 'Menu Items', func: syncMenuItems, hasPermission: true },
  { name: 'Global Settings', func: syncGlobalSettings, hasPermission: true },
  { name: 'Reports', func: syncReportList, hasPermission: true }
];
