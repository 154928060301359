import { useContext, useState } from 'react';
import { Avatar, Dropdown, Menu, message } from 'antd';
import { ClearOutlined, SyncOutlined, UserOutlined } from '@ant-design/icons';
import { AuthContext } from '@/contexts/auth.context';
import ResetFilterModal, { ResetFilterModalProps } from '../../FliterTable/ResetFilterModal';
import { deleteUserFilter } from '@/services/users/services.users';
import isAxiosError from '@/utils/isAxiosError';
import getErrorMessage from '@/utils/getError';
import CustomErrorModal from '../CustomErrorModal';
import { useNavigate } from 'react-router-dom';
import { useFilterStore, useHydration } from '@/store/zustand';
import eventEmitter from '@/utils/events';
import SyncProgressUI, { SyncResult } from '../SyncProgressUI';
import { syncOperations } from './sync.operation';
import { useFirstLoadEffect } from '@/hooks/useInitialSync';
import { HAS_INITIAL_SYNC } from '@/constants/config';

interface Props {
  isHamburgerShown?: boolean;
  onMenuRefetch?: () => void;
}

const UserProfile = ({ isHamburgerShown, onMenuRefetch }: Props) => {
  const navigate = useNavigate();
  const { profileData, signOut } = useContext(AuthContext);

  const [isResetting, setIsResetting] = useState(false);
  const [isClearModalOpen, setIsClearModalOpen] = useState(false);

  const zustandFilter = useFilterStore();

  const [isSyncingOpen, setIsSyncingOpen] = useState(false);
  const [syncResults, setSyncResults] = useState<SyncResult[]>([]);
  const [progress, setProgress] = useState(0);

  const isHydrated = useHydration();

  const onCloseSync = () => {
    setIsSyncingOpen(false);
    setSyncResults([]);
    setProgress(0);
  };

  useFirstLoadEffect({
    key: HAS_INITIAL_SYNC,
    enabled: isHydrated,
    callback: async () => {
      await handleSync();
    }
  });

  const onResetFilter: ResetFilterModalProps['onReset'] = async (type, scope) => {
    try {
      setIsClearModalOpen(false);
      setIsResetting(true);
      if (type === 'local') {
        setIsClearModalOpen(false);
        await zustandFilter.resetAll();
        navigate(0);
        return;
      }

      if (scope === 'all') {
        await deleteUserFilter();
        message.success('Filter reset successfully.');
        eventEmitter.emit('RESET_CURRENT_PAGE_SAVED_FILTERS', { reset: 'all' });
        return;
      }

      eventEmitter.emit('RESET_CURRENT_PAGE_SAVED_FILTERS', { reset: 'current' });
    } catch (error) {
      if (isAxiosError(error)) return;
      CustomErrorModal({
        title: 'Error',
        message: getErrorMessage(error) || 'Error reseting filter'
      });
    } finally {
      setIsResetting(false);
    }
  };

  async function handleSync() {
    setIsSyncingOpen(true);
    setProgress(0);
    setSyncResults([]);

    if (progress === 0) {
      const allowedOperations = syncOperations.filter((op) => op.hasPermission);
      const incrementPerOperation = 100 / allowedOperations.length;

      const updateOperationStatus = (name: string, status: SyncResult['status']) => {
        setSyncResults((prev) => {
          const existing = prev.findIndex((item) => item.name === name);
          if (existing >= 0) {
            const newResults = [...prev];
            newResults[existing] = { name, status };
            return newResults;
          }
          return [...prev, { name, status }];
        });
      };

      const promises = allowedOperations.map(async ({ name, func }) => {
        // Update status to syncing
        updateOperationStatus(name, 'syncing');

        return func()
          .then(() => {
            updateOperationStatus(name, 'fulfilled');
            return { name, status: 'fulfilled' };
          })
          .catch((error) => {
            updateOperationStatus(name, 'rejected');
            return { name, status: 'rejected', error };
          })
          .finally(() => {
            // Update with dynamically calculated increment
            setProgress((prev) => Math.min(prev + incrementPerOperation, 100));
          });
      });

      const results = await Promise.all(promises);
      const failedOperations = results
        .filter((result) => result.status === 'rejected')
        .map((result) => result.name);

      if (!failedOperations.length) {
        setProgress(100);
        message.success('Sync completed successfully!');
        onCloseSync();
      }

      onMenuRefetch?.();
    }
  }

  const retryFailedSync = async () => {
    const failedOps = syncResults.filter((r) => r.status === 'rejected');
    if (!failedOps.length) return;

    setProgress(0);
    const incrementPerOperation = 100 / failedOps.length;

    const updateOperationStatus = (name: string, status: SyncResult['status'], error?: unknown) => {
      setSyncResults((prev) => {
        const index = prev.findIndex((item) => item.name === name);
        const updated = { name, status, ...(error ? { error } : {}) };
        if (index >= 0) {
          const newResults = [...prev];
          newResults[index] = updated;
          return newResults;
        }
        return [...prev, updated];
      });
    };

    const retryPromises = failedOps.map(async ({ name }) => {
      const op = syncOperations.find((o) => o.name === name);
      if (!op || !op.hasPermission) return;

      updateOperationStatus(name, 'syncing');

      return op
        .func()
        .then(() => updateOperationStatus(name, 'fulfilled'))
        .catch((error) => updateOperationStatus(name, 'rejected', error))
        .finally(() => setProgress((prev) => Math.min(prev + incrementPerOperation, 100)));
    });

    await Promise.all(retryPromises);

    const remainingFailures = syncResults.filter((r) => r.status === 'rejected');
    if (remainingFailures.length === 0) {
      setProgress(100);
      message.success('Retry completed successfully!');
      onCloseSync();
    }

    onMenuRefetch?.();
  };

  return (
    <>
      <ResetFilterModal
        isLoading={isResetting}
        visible={isClearModalOpen}
        onReset={onResetFilter}
        onCancel={() => setIsClearModalOpen(false)}
      />

      <SyncProgressUI
        isOpen={isSyncingOpen}
        onClose={onCloseSync}
        percentComplete={progress}
        syncOperations={syncOperations}
        currentResults={syncResults}
        onRetryFailed={retryFailedSync}
      />

      <Dropdown
        overlay={
          <Menu
            items={[
              { key: 'sign-out', icon: <UserOutlined />, label: 'Sign Out', onClick: signOut },
              {
                key: 'sync',
                icon: <SyncOutlined />,
                label: 'Sync Data',
                onClick: handleSync,
                disabled: progress > 0 && progress < 100
              },
              {
                key: 'clear-local-data',
                icon: <ClearOutlined />,
                label: 'Clear Filter',
                onClick: () => setIsClearModalOpen(true)
              }
            ]}
          />
        }
        trigger={['click']}>
        <div className="cursor-pointer">
          {!isHamburgerShown && <span className="mr-5">{profileData?.name}</span>}
          <Avatar icon={<UserOutlined />} />
        </div>
      </Dropdown>
    </>
  );
};

export default UserProfile;
