import { IndexNames, StoreNames } from './storeList.enum';

export const storesToCreate = [
  {
    name: StoreNames.Locations,
    keyPath: 'id',
    index: IndexNames.LocationsSecondIndex,
    indexKey: 'lowercaseName'
  },
  {
    name: StoreNames.Products,
    keyPath: 'id',
    index: IndexNames.ProductsSecondIndex,
    indexKey: 'lowercaseName'
  },
  {
    name: StoreNames.Units,
    keyPath: 'id',
    index: IndexNames.UnitsSecondIndex,
    indexKey: 'lowercaseName'
  },
  {
    name: StoreNames.Category,
    keyPath: 'id',
    index: IndexNames.CategorySecondIndex,
    indexKey: 'lowercaseName'
  },
  {
    name: StoreNames.FinancialYears,
    keyPath: 'id',
    index: IndexNames.FinancialYearSecondIndex,
    indexKey: 'isCurrent'
  },
  {
    name: StoreNames.Vehicles,
    keyPath: 'id',
    index: IndexNames.VehiclesSecondIndex,
    indexKey: 'lowercaseName'
  },
  {
    name: StoreNames.Users,
    keyPath: 'id',
    index: IndexNames.UsersSecondIndex,
    indexKey: 'lowercaseName'
  },
  {
    name: StoreNames.Customers,
    keyPath: 'id',
    index: IndexNames.CustomersSecondIndex,
    indexKey: 'lowercaseName'
  },
  {
    name: StoreNames.Agents,
    keyPath: 'id',
    index: IndexNames.AgentsSecondIndex,
    indexKey: 'userId'
  },
  {
    name: StoreNames.Vendors,
    keyPath: 'id',
    index: IndexNames.VendorsSecondIndex,
    indexKey: 'lowercaseName'
  },
  {
    name: StoreNames.Routes,
    keyPath: 'id',
    index: IndexNames.RoutesSecondIndex,
    indexKey: 'lowercaseName'
  },
  { name: StoreNames.TotalLotsAvailable, keyPath: 'locationId' },
  {
    name: StoreNames.ExpenseCategory,
    keyPath: 'id',
    index: IndexNames.ExpenseCategorySecondIndex,
    indexKey: 'lowercaseName'
  },
  { name: StoreNames.Expenses, keyPath: 'id' },
  { name: StoreNames.AgentsBillRange, keyPath: 'id' },
  { name: StoreNames.Menu, keyPath: 'id' },
  { name: StoreNames.Reports, keyPath: 'id' },
  { name: StoreNames.Dashboard, keyPath: 'widgetId' },
  {
    name: StoreNames.Accounts,
    keyPath: 'id',
    index: IndexNames.AccountsSecondIndex,
    indexKey: 'lowercaseName'
  },
  {
    name: StoreNames.CustomerGroups,
    keyPath: 'id',
    index: IndexNames.CustomerGroupsSecondIndex,
    indexKey: 'lowercaseName'
  },
  {
    name: StoreNames.HRGroups,
    keyPath: 'id',
    index: IndexNames.HRGroupsSecondIndex,
    indexKey: 'lowercaseName'
  },
  {
    name: StoreNames.Shifts,
    keyPath: 'id',
    index: IndexNames.ShiftsSecondIndex,
    indexKey: 'lowercaseName'
  },
  {
    name: StoreNames.WooCommerceProducts,
    keyPath: 'wooProductId',
    index: IndexNames.WooCommerceProductsSecondIndex,
    indexKey: 'lowercaseName'
  },
  {
    name: StoreNames.WooCommerceCustomers,
    keyPath: 'id',
    index: IndexNames.WooCommerceCustomersSecondIndex,
    indexKey: 'lowercaseName'
  }
];
