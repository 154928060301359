import { useMutation, useQuery } from '@tanstack/react-query';
import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  PageHeader,
  Select
} from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AppContent from '../../../components/Common/Content/Content';
import { UsersSearch } from '../../../components/Common/UsersSearch';
import {
  get_purchase_list,
  get_purchase_order_list,
  get_purchase_return_list
} from '../../../services/purchases/queries';
import { get_sell_list, get_sell_order_list } from '../../../services/sell/queries';

import { create_tasks_mutation } from '../../../services/tasks/mutations';
import { ITasksCreateRequest } from '../../../services/tasks/types';
import { get_user_list } from '../../../services/users/queries';
import { User } from '../../../services/users/types';
import { get_vehicles_list } from '../../../services/vehicle/queries';
import RichTextEditor from '../../../components/RichTextEditor';
import TextArea from 'antd/lib/input/TextArea';
import { useFilterStore } from '@/store/zustand';
import { ListPage } from '@/constants/list.enum';

interface ITaskCreateModal {
  isModalOpen: boolean;
  handleOk: () => void;
  handleCancel: () => void;
  taskStatus: string;
  handleResetTaskModal: () => void;
  refetch: () => void;
}

const CreateModal = () => {
  const [form] = Form.useForm();
  const referenceType = Form.useWatch('reference', form);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [activeReferenceOptions, setActiveReferenceOptions] = useState<any[]>([]);
  const [vehicleList, setVehicleList] = useState<any>([]);
  const [userList, setUserList] = useState<User[]>([]);

  const handleRichTextEditorChange = (field: string, value: string) => {
    form.setFieldValue(field, value);
  };

  const zustandFilter = useFilterStore();

  useQuery(['vehicleList'], async () => {
    // get_vehicles_list;
    const response = await get_vehicles_list();
    // console.log('Response-->', response.data);
    setVehicleList(response.data.vehicles);
    return response;
  });

  const createCategoryMutation = useMutation(create_tasks_mutation, {
    onSuccess: (data: any) => {
      zustandFilter.resetState(ListPage.TASK);
      message.success('Task added successfully');
      navigate('/tasks');
    },
    onError: (data: any) => {
      message.error(data.message);
    }
  });
  const onFinish = (values: ITasksCreateRequest) => {
    if (values.reference === 'other' || !values.reference) {
      (values.reference = 'other'), (values.referenceId = 0);
    }
    values.isArchived = false;
    // values.status = 'PACKED';
    // console.log('Values', values);
    createCategoryMutation.mutate(values);
  };

  // useEffect(() => {
  //   if (taskStatus) {
  //     form.setFieldsValue({
  //       status: taskStatus.toLowerCase()
  //     });
  //   }
  // }, [taskStatus]);

  // useEffect(() => {
  //   return () => {
  //     handleResetTaskModal();
  //     form.resetFields();
  //   };
  // }, []);

  const getUserFromId = (id: number) => {
    //@ts-ignore
    return userList.find((user) => user?.id == id)?.name;
  };

  const breadcrumbItems = [
    {
      label: 'Tasks',
      link: '/tasks'
    },
    {
      label: 'Create',
      link: '/tasks/new'
    }
  ];

  return (
    <div>
      {/* //   <Modal/
    //     title={'Create a new task'}
    //     visible={isModalOpen}
    //     onOk={handleOk}
    //     onCancel={handleCancel}
    //     destroyOnClose
    //     footer={null}>
    //     <Form
    //       form={form}
    //       initialValues={{
    //         title: undefined,
    //         description: undefined,
    //         status: taskStatus,
    //         dueDate: '',
    //         assignee: undefined,
    //         reference: undefined,
    //         referenceId: undefined
    //       }}
    //       onFinish={onFinish}
    //       layout="vertical"
    //       validateTrigger={'onChange'}
    //       autoComplete="off">
    //       <div className="grid grid-cols-2 gap-5 mb-5">
    //         <Form.Item
    //           label="Name"
    //           name="name"
    //           rules={[{ required: true, message: 'Please add name!' }]}>
    //           <Input />
    //         </Form.Item>
    //       </div>

    //       <Form.Item label="ID" name="id" hidden>
    //         <InputNumber controls={false} />
    //       </Form.Item>

    //       <Form.Item
    //         label="Description"
    //         name="description"
    //         rules={[{ required: true, message: 'Please add description!' }]}>
    //         <Input.TextArea />
    //       </Form.Item>

    //       <div className="grid grid-cols-3 gap-5 mb-5">
    //         <Form.Item
    //           label="Status"
    //           name="status"
    //           rules={[{ required: true, message: 'Please add status!' }]}>
    //           <Select allowClear>
    //             <Select.Option value={'pending'}>Pending</Select.Option>
    //             <Select.Option value={'inProgress'}>In Progress</Select.Option>
    //             <Select.Option value={'rejected'}>Rejected</Select.Option>
    //             <Select.Option value={'done'}>Done</Select.Option>
    //           </Select>
    //         </Form.Item>
    //         <Form.Item
    //           label="Assignee"
    //           name="assignee"
    //           rules={[{ required: true, message: 'Please add assignee!' }]}>
    //           <Select allowClear>
    //             {userList.map((user: any, index: any) => (
    //               <Select.Option key={index} value={user.id}>
    //                 {user.name}
    //               </Select.Option>
    //             ))}
    //           </Select>
    //         </Form.Item>

    //         <Form.Item
    //           label="Due date"
    //           name="dueDate"
    //           rules={[{ required: true, message: 'Please add due date!' }]}>
    //           <DatePicker className="w-full" format={'DD-MM-YYYY'} />
    //         </Form.Item>
    //       </div>

    //       <PageHeader
    //         title="References"
    //         style={{
    //           padding: '8px 0px'
    //         }}
    //       />

    //       <div className="grid grid-cols-2 gap-5 mb-5">
    //         <Form.Item
    //           label="Reference"
    //           name="reference"
    //           rules={[{ required: false, message: 'Please add reference!' }]}>
    //           <Select allowClear>
    //             <Select.Option value={'purchase'}>Purchase</Select.Option>
    //             <Select.Option value={'purchase_order'}>Purchase Order</Select.Option>
    //             <Select.Option value={'purchase_return'}>Purchase return</Select.Option>
    //             <Select.Option value={'transfer'}>Transfer</Select.Option>
    //             <Select.Option value={'adjustment'}>Adjustment</Select.Option>
    //             <Select.Option value={'sell'}>Sell</Select.Option>
    //             <Select.Option value={'sell_order'}>Sell order</Select.Option>
    //             <Select.Option value={'sell_return'}>Sell return</Select.Option>
    //             <Select.Option value={'other'}>other</Select.Option>
    //           </Select>
    //         </Form.Item>
    //         <Form.Item
    //           label="Reference ID"
    //           name="referenceId"
    //           rules={[{ required: false, message: 'Please add reference!' }]}>
    //           <Select allowClear loading={loading}>
    //             {activeReferenceOptions?.map((purchase: any, index: any) => (
    //               <Select.Option key={index} value={purchase.id}>
    //                 {purchase.id}
    //               </Select.Option>
    //             ))}
    //           </Select>
    //         </Form.Item>
    //       </div>

    //       <PageHeader
    //         title="Tasks Log"
    //         style={{
    //           padding: '8px 0px'
    //         }}
    //       />

    //       <div className="flex justify-end mt-5">
    //         <Form.Item>
    //           <Button type="primary" htmlType="submit">
    //             Submit
    //           </Button>
    //         </Form.Item>
    //         <Button
    //           type="default"
    //           className="ml-5"
    //           htmlType="button"
    //           onClick={() => handleCancel()}>
    //           Cancel
    //         </Button>
    //       </div>
    //     </Form>
    //   </Modal> */}
      <AppContent breadcrumbItems={breadcrumbItems}>
        <PageHeader
          title="Task Information"
          style={{
            padding: '8px 0px'
          }}
        />
        <Form
          form={form}
          initialValues={{
            title: undefined,
            description: undefined,
            status: undefined,
            dueDate: '',
            assignee: undefined,
            reference: undefined,
            referenceId: undefined
          }}
          onFinish={onFinish}
          layout="vertical"
          validateTrigger={'onChange'}
          autoComplete="off">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-5 mb-5">
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: 'Please add name!' }]}>
              <Input />
            </Form.Item>
          </div>

          <Form.Item label="ID" name="id" hidden>
            <InputNumber controls={false} />
          </Form.Item>

          <Form.Item
            label="Description"
            name="description"
            rules={[{ required: true, message: 'Please add description!' }]}>
            {/* <RichTextEditor
              value={form.getFieldValue('description')}
              fieldName="description"
              fn={handleRichTextEditorChange}
            /> */}
            <TextArea
              value={form.getFieldValue('description')}
              rows={5}
              placeholder="Description..."
            />
          </Form.Item>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5 mb-5">
            <Form.Item
              label="Status"
              name="status"
              rules={[{ required: true, message: 'Please add status!' }]}>
              <Select allowClear>
                <Select.Option value={'packed'}>Packed</Select.Option>
                <Select.Option value={'dispatched'}>Dispatched</Select.Option>
                <Select.Option value={'delivered'}>Delivered</Select.Option>
                <Select.Option value={'assigned'}>Assigned</Select.Option>
              </Select>
            </Form.Item>
            {/* <Form.Item
              label="Assignee"
              name="assignee"
              rules={[{ required: true, message: 'Please add assignee!' }]}>
              <Select allowClear>
                {userList.map((user: any, index: any) => (
                  <Select.Option key={index} value={user.id}>
                    {user.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item> */}

            <UsersSearch formData={{ formName: 'assignee' }} isAll={false} required={true} />

            <Form.Item
              label="Due date"
              name="dueDate"
              rules={[{ required: true, message: 'Please add due date!' }]}>
              <DatePicker className="w-full" format={'DD-MM-YYYY'} />
            </Form.Item>
            <Form.Item
              label="Vehicle"
              name="vehicleId"
              rules={[{ required: false, message: 'Please select vehicle' }]}>
              <Select allowClear>
                {vehicleList &&
                  vehicleList?.map((currvehicle: any, index: any) => (
                    <Select.Option key={index} value={currvehicle.id}>
                      {currvehicle.name}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </div>

          <PageHeader
            title="References"
            style={{
              padding: '8px 0px'
            }}
          />

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-5 mb-5">
            <Form.Item
              label="Reference"
              name="reference"
              rules={[{ required: false, message: 'Please add reference!' }]}>
              <Select allowClear>
                {/* <Select.Option value={'purchase'}>Purchase</Select.Option>
                <Select.Option value={'purchase_order'}>Purchase Order</Select.Option>
                <Select.Option value={'purchase_return'}>Purchase return</Select.Option>
                <Select.Option value={'transfer'}>Transfer</Select.Option>
                <Select.Option value={'adjustment'}>Adjustment</Select.Option>
                <Select.Option value={'sell'}>Sell</Select.Option>
                <Select.Option value={'sell_order'}>Sell order</Select.Option>
                <Select.Option value={'sell_return'}>Sell return</Select.Option> */}
                <Select.Option value={'other'}>other</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="Reference ID"
              name="referenceId"
              rules={[{ required: false, message: 'Please add reference!' }]}>
              <Select allowClear loading={loading} disabled>
                {activeReferenceOptions?.map((purchase: any, index: any) => (
                  <Select.Option key={index} value={purchase.id}>
                    {purchase.id}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>

          <PageHeader
            title="Tasks Log"
            style={{
              padding: '8px 0px'
            }}
          />

          <div className="flex justify-end mt-5">
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
            <Button
              type="default"
              className="ml-5"
              htmlType="button"
              onClick={() => navigate('/tasks')}>
              Cancel
            </Button>
          </div>
        </Form>
      </AppContent>
    </div>
  );
};

export default CreateModal;
