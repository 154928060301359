import CustomUpdateIcon from '@/components/Common/CustomIcons/CustomUpdateIcon';
import GenericTable from '@/components/Common/CustomizeTable';
import ActionDropdown from '@/components/Common/Dropdownactions';
import { DEFAULT_DATE_FORMAT } from '@/constants';
import { checkAccess } from '@/routes/acl';
import { IAttendance, IAttendanceList } from '@/services/hr/types';
import { convertUTCStringtoLocalString } from '@/utils/convertToUTC';
import { FormInstance, Menu, TableProps } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { SorterResult } from 'antd/lib/table/interface';
import { useState } from 'react';
import { Link } from 'react-router-dom';

interface Props {
  isLoading?: boolean;
  form: FormInstance;
  data: IAttendanceList;
  pagination: { page: number; size: number };
  onPagination: (page?: number, size?: number, isSize?: boolean) => Promise<unknown>;
}

function Table({ isLoading, data, pagination, form, onPagination }: Props) {
  const [sortedInfo, setSortedInfo] = useState<SorterResult<IAttendance>>({});

  const handleChange: TableProps<IAttendance>['onChange'] = (_, __, s) => {
    setSortedInfo(s as SorterResult<IAttendance>);
  };

  const columns: ColumnsType<IAttendance> = [
    {
      title: 'S.N.',
      key: 'id',
      width: 5,
      render: (_, __, index) => (pagination.page - 1) * pagination.size + index + 1
    },
    {
      title: 'Shift',
      key: 'shiftID',
      width: 5,
      render: (_, record) =>
        record.shiftId ? <Link to={`/hr/shift/${record.shiftId}`}>View</Link> : '-'
    },
    {
      title: 'Check-in',
      key: 'startDate',
      width: 15,
      dataIndex: 'startDate',
      sorter: (a, b) => a.startDate.localeCompare(b.startDate),
      sortOrder: sortedInfo.columnKey === 'startDate' ? sortedInfo.order : null,
      render: (startDate: string) => {
        return convertUTCStringtoLocalString(startDate, DEFAULT_DATE_FORMAT);
      }
    },
    {
      title: 'Check-out',
      key: 'endDate',
      width: 15,
      dataIndex: 'endDate',
      sorter: (a, b) => (a.endDate || '').localeCompare(b.endDate || ''),
      sortOrder: sortedInfo.columnKey === 'endDate' ? sortedInfo.order : null,
      render: (endDate) => {
        return endDate ? convertUTCStringtoLocalString(endDate, DEFAULT_DATE_FORMAT) : '-';
      }
    }
  ];

  if (checkAccess('UPDATE_HR_ATTENDANCE')) {
    columns.push({
      title: 'Actions',
      key: 'actions',
      width: 4,
      fixed: 'right',
      render: (record: IAttendance) => {
        const menuItems: (
          | { key: string; label: JSX.Element; onClick?: undefined }
          | { key: string; label: JSX.Element; onClick: () => void }
        )[] = [
          {
            key: 'update',
            label: <CustomUpdateIcon link={`/hr/attendance/details/${record.id}`} />
          }
        ];

        const menu = <Menu items={menuItems} />;
        return <ActionDropdown menu={menu} />;
      }
    });
  }

  return (
    <div>
      <GenericTable
        form={form}
        columns={columns}
        data={data.results}
        isLoading={isLoading}
        scroll={{ x: 800 }}
        hideDefaultPagination
        tableName={'hr-attendance-list'}
        pagination={{ ...pagination, total: data.total, onPagination, scrollToTop: true }}
        toSort={handleChange}
      />
    </div>
  );
}

export default Table;
