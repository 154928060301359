import AppContent from '@/components/Common/Content/Content';
import GenericTable from '@/components/Common/CustomizeTable';
import { DEFAULT_DATE_FORMAT } from '@/constants';
import { OrderStatusColor } from '@/services/woocommerce/enum';
import { get_woo_commerce_order_details } from '@/services/woocommerce/queries';
import { IOrderLineItem, IWooCommerceOrder } from '@/services/woocommerce/types';
import { convertUTCStringtoLocalString } from '@/utils/convertToUTC';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';
import { useQuery } from '@tanstack/react-query';
import { Card, Descriptions, PageHeader, Spin, Tag } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { Navigate, useParams } from 'react-router-dom';

function ViewWooOrderDetails() {
  const params = useParams();
  const wooOrderId = Number(params.id);

  if (isNaN(wooOrderId) || wooOrderId <= 0) return <Navigate to={'/woo-commerce/orders'} />;

  const { isLoading, isFetching, data } = useQuery(
    ['get_woo_order_details', wooOrderId],
    async () => {
      return await get_woo_commerce_order_details(wooOrderId);
    }
  );

  const order = data || ({} as IWooCommerceOrder);
  const customer = order.billing || {};
  const shipping = order.shipping || {};
  const products = order.line_items || [];
  const coupons = order.coupon_lines || [];
  const shippingMethods = order.shipping_lines || [];

  const productColumns: ColumnsType<IOrderLineItem> = [
    { title: 'Product', dataIndex: 'name', key: 'name', width: 150 },
    { title: 'SKU', dataIndex: 'sku', key: 'sku', width: 50 },
    { title: 'Quantity', dataIndex: 'quantity', width: 50, key: 'quantity' },
    {
      title: 'Price',
      dataIndex: 'price',
      width: 75,
      key: 'price',
      render: (price: number) => <span>{nepaliNumberFormatter(price)}</span>
    },
    { title: 'Subtotal', dataIndex: 'subtotal', width: 75, key: 'subtotal' },
    { title: 'Tax', width: 75, dataIndex: 'total_tax', key: 'total_tax' },
    { title: 'Total', dataIndex: 'total', width: 100, key: 'total' }
  ];

  return (
    <Spin spinning={isLoading || isFetching}>
      <AppContent
        breadcrumbItems={[
          { label: 'WooCommerce', link: '/woo-commerce/products' },
          { label: 'Orders', link: '/woo-commerce/orders' },
          { label: `Order #${wooOrderId}` }
        ]}>
        <PageHeader
          title={'Order Information'}
          style={{ padding: '8px 0px' }}
          extra={
            <Tag color={order.status ? OrderStatusColor[order.status] || 'default' : 'default'}>
              {order.status}
            </Tag>
          }
        />

        <div className="grid grid-cols-1 md:grid-cols-2 gap-5 mb-5">
          <Card className="shadow-sm border">
            <Descriptions title="Order Information" column={1} size="small">
              <Descriptions.Item label="Discount">{order.discount_total}</Descriptions.Item>
              <Descriptions.Item label="Tax">{order.total_tax}</Descriptions.Item>
              <Descriptions.Item label="Shipping Cost">{order.shipping_total}</Descriptions.Item>
              <Descriptions.Item label="Total Amount">Rs {order.total}</Descriptions.Item>

              <Descriptions.Item label="Payment Method">
                {order.payment_method_title}
              </Descriptions.Item>
              <Descriptions.Item label="Order Date">
                {order.date_created
                  ? convertUTCStringtoLocalString(order.date_created, DEFAULT_DATE_FORMAT)
                  : '-'}
              </Descriptions.Item>
            </Descriptions>
          </Card>

          {/* Customer Details */}
          <Card className="shadow-sm border">
            <Descriptions title="Customer Details" column={1} size="small">
              <Descriptions.Item label="Name">
                {customer.first_name} {customer.last_name}
              </Descriptions.Item>
              <Descriptions.Item label="Email">{customer.email}</Descriptions.Item>
              <Descriptions.Item label="Phone">{customer.phone || 'N/A'}</Descriptions.Item>
              <Descriptions.Item label="Address">
                {customer.address_1}, {customer.city}, {customer.state}
              </Descriptions.Item>
            </Descriptions>
          </Card>
        </div>

        {/* Product Details */}
        <Card className="shadow-sm border mb-5">
          <Descriptions title="Order Items" />
          <GenericTable
            data={products}
            columns={productColumns}
            rowKey="id"
            generateSummary
            excludeSummaryByKeys={['sku']}
            excludeNepaliNumberFormatter={['quantity']}
            summaryClassName="text-left"
            scroll={{ x: 1000, y: '75vh' }}
            hideDefaultPagination
          />
        </Card>

        {coupons.length > 0 && (
          <Card className="shadow-sm border mb-5">
            <Descriptions title="Coupons Used" column={1} size="small">
              {coupons.map((coupon) => (
                <Descriptions.Item key={coupon.id} label="Coupon Code">
                  {coupon.code} (Discount: {coupon.discount}, Tax: {coupon.discount_tax})
                </Descriptions.Item>
              ))}
            </Descriptions>
          </Card>
        )}

        {shippingMethods.length > 0 && (
          <Card className="shadow-sm border">
            <Descriptions title="Shipping Details" column={1} size="small">
              <Descriptions.Item label="Recipient">
                {shipping.first_name} {shipping.last_name}
              </Descriptions.Item>
              <Descriptions.Item label="Shipping Address">
                {shipping.address_1}, {shipping.address_2 ? shipping.address_2 + ',' : ''}{' '}
                {shipping.city}, {shipping.state}
              </Descriptions.Item>
              <Descriptions.Item label="Shipping Method">
                {shippingMethods[0]?.method_title}
              </Descriptions.Item>
              <Descriptions.Item label="Shipping Cost">{order.shipping_total}</Descriptions.Item>
              <Descriptions.Item label="Customer Note">{order.customer_note}</Descriptions.Item>
            </Descriptions>
          </Card>
        )}
      </AppContent>
    </Spin>
  );
}

export default ViewWooOrderDetails;
