import ColorBox from '../Common/ColorBox';
import { FilterType } from '@/store/zustand';

export const filterColor = {
  default: '#d22324',
  local: '#309bd9',
  saved: '#0ba345'
} as Record<FilterType, string>;

function FilterInfo() {
  return (
    <div className="flex gap-2 items-center">
      <ColorBox
        color={filterColor.default}
        title="Default Filter"
        description="The default filter is applied. No customizations have been made yet."
      />
      <ColorBox
        color={filterColor.saved}
        title="Saved Filter"
        description="A saved filter has been applied, based on your prior selections."
      />
      <ColorBox
        color={filterColor.local}
        title="Local Filter"
        description="Your previous filter preferences have been restored."
      />
    </div>
  );
}

export default FilterInfo;
