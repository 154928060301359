import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { FormInstance } from 'antd/es/form/Form';
import React, { useEffect, useRef, useState } from 'react';
import { NepaliDatePicker } from 'nepali-datepicker-reactjs';
import { CloseOutlined } from '@ant-design/icons';
import { Button, DatePicker, Form, Input, message, Spin, Tooltip } from 'antd';
import { DatePickerProps, RangePickerProps } from 'antd/lib/date-picker';

import { cn, getDeepCopy } from '@/utils';
const { RangePicker } = DatePicker;
import { ConvertObjectToURL } from '../../utils/converturl';
import { convertLocalToUTCString } from '../../utils/convertToUTC';
import {
  CustomDatePresets,
  DashboardDatePresets
} from '../../pages/sqlsource/report/utils/datePresets';
import {
  find_date_preference,
  find_locationId_preference,
  find_time_limit
} from '../../store/localstorage/preferences';
import {
  convert_nepali_to_english_date,
  convert_string_to_nepali_date_string
} from '../../utils/nepaliDateConverter';
import {
  deleteUserFilter,
  get_current_page_filter,
  updateUserFilter
} from '@/services/users/services.users';
import CustomErrorModal from '../Common/CustomErrorModal';
import isAxiosError from '@/utils/isAxiosError';
import { useFilterStore, FilterType, useHydration } from '@/store/zustand';
import getErrorMessage from '@/utils/getError';
import FilterInfo, { filterColor } from './FilterInfo';
import { deepEqual } from '@/utils/isObjectEqual';
import FilterButton from './FilterButton';
import { FILTER_SYNC_EVENT } from '@/constants/event.menu';
import eventEmitter from '@/utils/events';

export interface InitialLoad {
  pagination: { page: number; size: number };
  data?: any;
}

interface FilterValues {
  skip?: number;
  count?: number;
  endDate?: string;
  startDate?: string;
  dateCustom?: boolean;
  startDateNepali?: string;
  endDateNepali?: string;
  dateSingle?: boolean;
  date?: string;
  [key: string]: unknown;
}

type FilterStringify =
  | { isObject?: false; onSubmit: (value: string) => Promise<unknown> }
  | { isObject: true; onSubmit: (value: Record<string, unknown>) => Promise<unknown> };

type IFilterDrawers = {
  defaultValues: Record<string, unknown>;
  dateCustom?: boolean;
  singleDate?: boolean;
  useFilterBy?: boolean;
  initial?: boolean;
  form?: FormInstance;
  style?: string;
  buttons?: React.ReactNode;
  styleforbuttons?: string;
  outsidestyle?: string;
  timeVal?: boolean;
  hasHours?: boolean;
  datePresets?: Record<string, [moment.Moment, moment.Moment]>;
  buttonWrapperClassName?: string;
  allowSaved?: boolean;
  allowFull?: boolean;
  onPagination?: (page: number, size: number) => void;
  onInitialLoad?: (props: InitialLoad) => void;
  disabledDate?: boolean;
} & FilterStringify;

let timer: NodeJS.Timeout | null = null;

const TableFilter: React.FC<IFilterDrawers> = ({
  initial = false,
  defaultValues,
  dateCustom = true,
  singleDate = false,
  children,
  form = Form.useForm()[0],
  buttons,
  styleforbuttons = '',
  outsidestyle = '',
  timeVal = false,
  hasHours = true,
  buttonWrapperClassName = '',
  allowFull = false,
  datePresets,
  onInitialLoad,
  allowSaved = true,
  disabledDate,
  ...props
}) => {
  const location = useLocation();
  const zustandFilter = useFilterStore();
  const nepaliType = find_date_preference();
  const savedFilter = get_current_page_filter(location.pathname);
  const preferenceLocationId = find_locationId_preference();

  const [showFilters, setShowFilters] = useState(false);
  const formParentRef = useRef<HTMLDivElement | null>(null);
  const filterRef = useRef<HTMLElement | null>(null);
  const [openDatePicker, setOpenDatePicker] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const currentDatePresets = datePresets || (allowFull ? DashboardDatePresets : CustomDatePresets);

  const isHydrated = useHydration();
  const currentFilterType = zustandFilter.getCurrentFilterType(location.pathname);
  const currentFilterColor = filterColor[currentFilterType];

  const toggleDrawer = () => {
    setShowFilters((prev) => !prev);
  };

  const closeModal = () => {
    setShowFilters(false);
  };

  function handleKeyboardClick(key: string) {
    if (showFilters && key === 'Enter') {
      filterRef.current?.click();
    }

    if (key === 'Escape') {
      toggleDrawer();
    }
  }

  useEffect(() => {
    const parentElement = formParentRef.current;
    if (!parentElement) return;

    function handleClickOutside(event: KeyboardEvent) {
      event.stopPropagation();
      const target = event.target as HTMLElement;
      const isSelectOpen = target.closest('.ant-select-open');
      setOpenDatePicker(false);

      if (isSelectOpen) return;
      handleKeyboardClick(event.key);
    }

    parentElement.addEventListener('keydown', handleClickOutside, true);
    return () => parentElement.removeEventListener('keydown', handleClickOutside, true);
  }, [showFilters]);

  useEffect(() => {
    function handleKeyboard(event: KeyboardEvent) {
      handleKeyboardClick(event.key);
    }

    document.addEventListener('keydown', handleKeyboard);
    return () => document.removeEventListener('keydown', handleKeyboard);
  }, [showFilters]);

  useEffect(() => {
    document.body.style.overflow = showFilters ? 'hidden' : 'auto';
  }, [showFilters]);

  const defaultTime = find_time_limit();

  const handleFilterClick = async () => {
    try {
      setIsLoading(true);
      setShowFilters(false);
      if (timer) clearInterval(timer);

      const formValues = form.getFieldsValue();
      const filterType = getFilterType(formValues);

      if (timeVal) {
        timer = setInterval(() => {
          onFinish(form.getFieldsValue(), filterType);
        }, defaultTime * 1000);
      }
      await onFinish(form.getFieldsValue(), filterType);
    } finally {
      setIsLoading(false);
    }
  };

  const onFinish = async (values: FilterValues, filterType?: FilterType) => {
    setShowFilters(false);
    // Pagination state is only saved for zustand filter
    if (filterType !== 'local') {
      values.skip = typeof defaultValues.skip === 'number' ? defaultValues.skip : 0;
      values.count = typeof defaultValues.count === 'number' ? defaultValues.count : 100;
    }

    const originalValues = getDeepCopy(values);

    const rangeCheck = props.useFilterBy ? values.filterBy === 'range' : dateCustom;
    const singleCheck = props.useFilterBy ? values.filterBy === 'exact' : singleDate;

    if (rangeCheck) {
      const endDate = values.endDate as string | moment.Moment;
      const startDate = values.startDate as string | moment.Moment;
      values.endDate = convertLocalToUTCString(endDate);
      values.startDate = convertLocalToUTCString(startDate);
      delete values.dateCustom;
      delete values.startDateNepali;
      delete values.endDateNepali;
    } else {
      delete values.startDate;
      delete values.endDate;
    }

    if (singleCheck) {
      const date = values.date as string | moment.Moment;
      values.date = moment(convertLocalToUTCString(date)).format('YYYY-MM-DD');
      delete values.dateSingle;
    } else {
      delete values.date;
    }

    const filter = ConvertObjectToURL(values);
    const data = props.isObject ? await props.onSubmit(values) : await props.onSubmit(filter);
    if (allowSaved) {
      zustandFilter.updateState(location.pathname, {
        data,
        resetScrollWhenData: filterType !== 'local',
        filter: { params: originalValues, type: filterType }
      });
    }
  };

  const onChange: RangePickerProps['onChange'] = (dates) => {
    if (dates && dates?.length == 2) {
      const dateStrings = dates.map((date) =>
        convertLocalToUTCString(date?.clone() as moment.Moment)
      );
      form.setFieldValue(['startDate'], dateStrings[0]);
      form.setFieldValue(['endDate'], dateStrings[1]);
    }
  };

  const onChangeNepali = (val: string, isStart: boolean) => {
    if (isStart) form.setFieldValue(['startDate'], convert_nepali_to_english_date(val));
    else form.setFieldValue(['endDate'], convert_nepali_to_english_date(val));
  };
  const onChangeSingle: DatePickerProps['onChange'] = (dates, dateStrings) => {
    // console.log('date', dateStrings);
    form.setFieldValue(['date'], dateStrings);
  };
  const onChangeSingleNepali = (val: string) => {
    form.setFieldValue(['date'], convert_nepali_to_english_date(val));
  };

  function modifyDefaultValues() {
    const customDateValues = defaultValues.dateCustom as [moment.Moment, moment.Moment];

    const currentFilterBy = defaultValues.filterBy || 'range';
    const rangeCheck = props.useFilterBy ? currentFilterBy === 'range' : dateCustom;
    const singleCheck = props.useFilterBy ? currentFilterBy === 'exact' : singleDate;

    if (rangeCheck) {
      const nepaliDateStart = convert_string_to_nepali_date_string(
        customDateValues[0].format('YYYY-MM-DD')
      );

      const nepaliDateEnd = convert_string_to_nepali_date_string(
        customDateValues[1].format('YYYY-MM-DD')
      );

      return {
        ...defaultValues,
        dateCustom: customDateValues,
        startDateNepali: nepaliDateStart,
        endDateNepali: nepaliDateEnd,
        startDate: customDateValues[0].format('YYYY-MM-DD'),
        endDate: customDateValues[1].format('YYYY-MM-DD')
      };
    } else if (singleCheck) {
      const nepaliDate = convert_string_to_nepali_date_string(moment().format());
      const dateFormat = nepaliType ? nepaliDate : moment();
      return {
        ...defaultValues,
        dateSingle: dateFormat,
        date: moment().format('YYYY-MM-DD')
      };
    } else {
      return defaultValues;
    }
  }

  useEffect(() => {
    const unsubscribe = eventEmitter.on('RESET_CURRENT_PAGE_SAVED_FILTERS', async (payload) => {
      if (payload.reset === 'all') {
        handleSync();
      }

      if (payload.reset === 'current') {
        await onResetFilter();
        handleSync();
      }
    });

    return unsubscribe;
  }, []);

  function handleFormChange() {
    form.resetFields();
    const updatedFilter = modifyDefaultValues();
    form.setFieldsValue({ ...updatedFilter });
  }

  useEffect(() => {
    if (!isHydrated) return;
    const hasFilterPresist = checkFilterPersist();
    if (hasFilterPresist) return;

    handleFormChange();
    if (initial) {
      if (timeVal) {
        timer = setTimeout(() => {
          onFinish(form.getFieldsValue(), 'default');
        }, defaultTime * 1000);
      }

      onFinish(form.getFieldsValue(), 'default');
    }

    return () => {
      if (timer) clearInterval(timer);
    };
  }, [isHydrated]);

  async function onSaveFilter() {
    try {
      setIsLoading(true);
      const formValues = form.getFieldsValue();
      if (formValues['dateCustom']) {
        const dates = formValues['dateCustom'] as [moment.Moment, moment.Moment];
        formValues['dateCustom'] = [dates[0].toISOString(), dates[1].toISOString()];
      }

      if (formValues['dateSingle']) {
        const date = formValues['dateSingle'] as moment.Moment;
        formValues['dateSingle'] = date.toISOString();
      }

      await updateUserFilter(location.pathname, formValues);
      message.success('Filter saved successfully for current page');
      zustandFilter.updateState(location.pathname, {
        filter: { params: formValues, type: 'saved' }
      });
      zustandFilter.removeData(location.pathname);
    } catch (error: unknown) {
      if (isAxiosError(error)) return;
      CustomErrorModal({
        title: 'Error',
        message: getErrorMessage(error) || 'Error saving filter'
      });
    } finally {
      setIsLoading(false);
    }
  }

  async function onResetFilter() {
    try {
      setIsLoading(true);
      handleFormChange();

      await deleteUserFilter(location.pathname);
      message.success('Filter reset successfully for current page');
      zustandFilter.updateState(location.pathname, {
        filter: { params: form.getFieldsValue(), type: 'default' }
      });
      zustandFilter.removeData(location.pathname);
    } catch (error: unknown) {
      if (isAxiosError(error)) return;
      CustomErrorModal({
        title: 'Error',
        message: getErrorMessage(error) || 'Error reseting filter'
      });
    } finally {
      setIsLoading(false);
    }
  }

  function getFilterType(currentFilter: Record<string, unknown>) {
    // Check if the local filter matches the saved filter
    if (savedFilter && deepEqual(currentFilter, savedFilter)) {
      return 'saved';
    }

    // Check if the local filter matches the default filter
    if (defaultValues) {
      const modifiedDefault = modifyDefaultValues();
      if (deepEqual(currentFilter, modifiedDefault)) {
        return 'default';
      }
    }

    return 'local';
  }

  const checkFilterPersist = (): boolean => {
    const currentFilter = zustandFilter.getState(location.pathname);
    if (currentFilter && allowSaved) {
      const { filter, pagination, data } = currentFilter;
      form.setFieldsValue(filter);
      const filterType = getFilterType(filter);

      if (data) {
        onInitialLoad?.({ pagination, data });
        zustandFilter.updateFilterType(location.pathname, filterType);
      } else {
        onFinish(filter, filterType).then(() => onInitialLoad?.({ pagination }));
      }

      return true;
    }

    if (!savedFilter) return false;
    if (
      preferenceLocationId &&
      savedFilter?.locationId &&
      savedFilter?.locationId !== preferenceLocationId
    ) {
      message.error(
        'Saved filter does not match preference location. So, current applied filter will be reset.'
      );

      return false;
    }

    form.setFieldsValue(savedFilter);
    onFinish(savedFilter, 'saved');
    return true;
  };

  async function handleSync() {
    handleFormChange();
    onFinish(form.getFieldsValue(), 'default');

    window.dispatchEvent(
      new CustomEvent(FILTER_SYNC_EVENT, {
        detail: { path: location.pathname }
      })
    );
  }

  return (
    <div ref={formParentRef}>
      <Form
        form={form}
        layout={'vertical'}
        onFinish={onFinish}
        className={outsidestyle ? outsidestyle : ''}
        autoComplete="off">
        <div
          className={cn(
            'flex flex-row relative items-center justify-end mt-2 flex-wrap gap-2 sm:gap-0',
            buttonWrapperClassName
          )}>
          <div className="flex items-center justify-center gap-2">
            {allowSaved && (
              <Tooltip title="Applied filter will reset and data will be reloaded">
                <Button
                  className="flex items-center justify-end secondary-button"
                  style={{ borderRadius: '5px' }}
                  onClick={handleSync}>
                  Refresh
                </Button>
              </Tooltip>
            )}

            <Button
              className="flex items-center justify-end filter-button"
              style={{
                borderRadius: '5px',
                color: currentFilterColor,
                border: `1px solid ${currentFilterColor}`
              }}
              onClick={toggleDrawer}>
              Filters
            </Button>
          </div>

          {buttons && <div className={styleforbuttons}>{buttons}</div>}
        </div>
        <div
          className={`fixed inset-0 bg-gray-800 bg-opacity-25 z-[1001] transition-opacity duration-300 ${
            showFilters ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'
          }`}>
          <div className="fixed inset-0 z-0" onClick={closeModal}></div>
          <div
            style={{
              borderInline: `2px solid ${currentFilterColor}`
            }}
            className="fixed top-0 right-0 bottom-0 max-h-[100vh] w-full md:w-1/3 lg:w-1/4 transition-transform duration-300 ">
            <div className="h-full p-6 bg-gray-100 overflow-y-auto">
              <div className="absolute top-3 right-4 cursor-pointer" onClick={closeModal}>
                <CloseOutlined />
              </div>

              <Spin
                spinning={isLoading}
                wrapperClassName="h-full filter-wrapper"
                className="h-full">
                <div className="pb-3">
                  <FilterInfo />
                </div>

                <div className="flex h-full flex-col justify-between gap-2 space-y-2">
                  <div className="grid grid-cols-1 gap-2">
                    <Form.Item label={'Start Date'} name={['skip']} hidden>
                      <Input />
                    </Form.Item>
                    <Form.Item label={'End Date'} name={['count']} hidden>
                      <Input />
                    </Form.Item>
                    <Form.Item label={'Select Date'} name={['dateSingle']} hidden={!singleDate}>
                      {nepaliType ? (
                        <NepaliDatePicker
                          onChange={onChangeSingleNepali}
                          value={'2079-09-29'}
                          options={{ calenderLocale: 'ne', valueLocale: 'en' }}
                        />
                      ) : (
                        <DatePicker
                          disabled={disabledDate}
                          onChange={onChangeSingle}
                          format={'YYYY-MM-DD'}
                          allowClear={false}
                          className="w-full"
                        />
                      )}
                    </Form.Item>
                    <Form.Item
                      label={'Select Start Date'}
                      name={['startDateNepali']}
                      hidden={!dateCustom || !nepaliType}>
                      <NepaliDatePicker
                        onChange={(val) => onChangeNepali(val, true)}
                        options={{ calenderLocale: 'ne', valueLocale: 'en' }}
                      />
                    </Form.Item>
                    <Form.Item
                      label={'Select End Date'}
                      name={['endDateNepali']}
                      hidden={!dateCustom || !nepaliType}>
                      <NepaliDatePicker
                        onChange={(val) => onChangeNepali(val, false)}
                        options={{ calenderLocale: 'ne', valueLocale: 'en' }}
                      />
                    </Form.Item>
                    <Form.Item
                      label={'Select Date'}
                      name={['dateCustom']}
                      hidden={!dateCustom || nepaliType}>
                      <RangePicker
                        disabled={disabledDate}
                        open={openDatePicker}
                        onOpenChange={(status) => setOpenDatePicker(status)}
                        showTime={hasHours}
                        ranges={currentDatePresets}
                        onChange={onChange}
                        className="w-full"
                        format={hasHours ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD'}
                        allowClear={false}
                      />
                    </Form.Item>
                    <Form.Item label={'Date'} name={['date']} hidden>
                      <Input />
                    </Form.Item>
                    <Form.Item label={'Start Date'} name={['startDate']} hidden>
                      <Input />
                    </Form.Item>
                    <Form.Item label={'End Date'} name={['endDate']} hidden>
                      <Input />
                    </Form.Item>
                    {/* <div className={`grid grid-cols-${React.Children.count(children)} gap-2`}> */}
                    {children}
                    {/* </div> */}
                  </div>

                  <div className="flex gap-2 justify-end flex-wrap pb-2">
                    <FilterButton
                      form={form}
                      onFinish={onFinish}
                      onSaveFilter={onSaveFilter}
                      onSaveReset={onResetFilter}
                    />

                    <Button
                      ref={filterRef}
                      type="default"
                      style={{
                        borderRadius: '5px',
                        color: currentFilterColor,
                        border: `1px solid ${currentFilterColor}`
                      }}
                      disabled={isLoading}
                      className="!rounded-md text-base"
                      onClick={handleFilterClick}>
                      Filter
                    </Button>
                  </div>
                </div>
              </Spin>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default TableFilter;
