import { Button, Divider, message, Select } from 'antd';
import React, { useState, useEffect } from 'react';
import useDebounce from '@/hooks/useDebounce';
import {
  get_product_active_list_only,
  get_product_list_only,
  get_units_list
} from '@/services/products/queries';
import { get_lot_by_location_product } from '@/services/purchases/queries';
import ProductsDB from '@/store/localstorage/ProductsDB';
import UnitsDB from '@/store/localstorage/UnitsDB';
import { getUserData } from '@/utils/auth.utils';
import CustomErrorModal from '../CustomErrorModal';
import { IProductDetails } from '@/services/products/types';
import getErrorMessage from '@/utils/getError';

interface Iprops {
  data: any[];
  setData: React.Dispatch<any>;
  selectedLocation?: number;
  type: string;
  disabled?: boolean;
  locationId?: number;
  showHidden?: boolean;
}

const ProductSearchForEstimateStock = ({
  data,
  setData,
  selectedLocation,
  type,
  disabled = false,
  locationId,
  showHidden
}: Iprops) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const debouncedSearchValue = useDebounce(searchValue, 500);
  const [isCacheResponse, setIsCacheResponse] = useState<boolean>(false);
  const [productList, setProductList] = useState<IProductDetails[]>([]);
  const [productSearch, setProductSearch] = useState<IProductDetails[]>([]);
  const { Option } = Select;
  const [skip, setSkip] = useState<number>(0);
  const [isMore, setIsMore] = useState<boolean>(true);

  async function fetchProducts(skip = 0, count = 1000, value = '', filter = '') {
    const params = new URLSearchParams(filter);
    if (locationId) params.set('locationId', locationId.toString());

    const updatedFilter = params.toString();
    return showHidden
      ? get_product_list_only(skip, count, value, filter)
      : get_product_active_list_only(skip, count, value, updatedFilter);
  }

  async function fetchProductsViaIndexDB(name = '', limit = 10) {
    return showHidden
      ? ProductsDB.searchProduct(name, limit)
      : ProductsDB.searchActiveProduct(name, limit, locationId);
  }

  if (!locationId) {
    const { preferences } = getUserData();
    locationId = preferences?.preferences
      ? JSON.parse(preferences?.preferences)?.locationId
      : message.error('Please choose a location from preferences!');
  }

  const searchProductIndexDB = async (value: string) => {
    try {
      let response = await fetchProductsViaIndexDB(value);
      response = response.filter(
        (val) =>
          !val.hidden && val.status.some((el: any) => el.locationId === locationId && !el.hidden)
      );
      if (!response || response.length == 0) {
        message.info('Cannot find any product with that name in cache, searching in server...');
        searchProduct(value);
      } else {
        setProductSearch(response);
        checkProductAndAdd(response);
        setIsCacheResponse(true);
      }
    } catch (error) {
      message.error(error as string);
    }
  };
  const searchProduct = async (value: string) => {
    let response;
    try {
      response = await fetchProducts(0, 10, value);
    } catch (e) {
      getErrorMessage(e);
    }

    if (!response || response.length == 0) {
      message.error('Cannot find any product with that name!');
      setProductSearch([]);
      setIsMore(false);
    } else {
      setProductSearch(response);
      checkProductAndAdd(response);
      ProductsDB.addProducts(response);
      if (response.length < 10) {
        setIsMore(false);
      } else {
        setIsMore(true);
      }
    }
    setIsCacheResponse(false);
    setSkip(10);

    // setSearchLoading(false);
  };

  const searchMoreProduct = async (value: string) => {
    let response;
    try {
      response = await fetchProducts(skip, 10, value);
    } catch (e) {
      getErrorMessage(e);
    }
    if (!response || response.length == 0) {
      message.info('Cannot find more products with that value in server!');
      setIsMore(false);
    } else {
      setSkip(skip + 10);
      setProductSearch([...productSearch, ...response]);
      checkProductAndAdd(response);
      ProductsDB.addProducts(response);

      if (response.length < 10) {
        setIsMore(false);
      }
    }
  };
  useEffect(() => {
    searchProductIndexDB(debouncedSearchValue);
  }, [debouncedSearchValue]);
  const checkProductAndAdd = (products: IProductDetails[]) => {
    if (productList.length > 0) {
      products = products.filter((value) => {
        const searchProduct = productSearch.find((val) => val.id == value.id);
        if (searchProduct) return false;
        return true;
      });

      if (products.length > 0)
        setProductList((prevValue) => {
          return [...prevValue, ...products];
        });
    } else {
      setProductList(products);
    }
  };
  const options = productSearch.map((d) => (
    <Option key={d.id} value={d.id}>
      {`${d.name} (${d.sku})`}
    </Option>
  ));

  return (
    <>
      <Select
        id="productSearch-custom"
        disabled={disabled}
        showSearch
        placeholder={'Search product'}
        defaultActiveFirstOption={false}
        style={{ borderRadius: '10px' }}
        autoClearSearchValue={true}
        showArrow={false}
        filterOption={false}
        onSearch={(val) => {
          setSearchValue(val);
        }}
        onSelect={async (val: number) => {
          const selectedProduct = productSearch.find((value) => value.id == val);
          const hasDefaultUnit = selectedProduct?.productUnits?.find((unit) => unit.isDefault);
          if (!hasDefaultUnit && selectedProduct) {
            message.warning('This product does not have a default unit', 5);
          }
          const find = data.find((value) => value.id == val);
          const findUnit = selectedProduct?.productUnits.find((curr) => curr.isDefault == true);

          if (!findUnit) {
            CustomErrorModal({
              title: 'Error',
              message: `Default unit not selected for ${selectedProduct?.name}`
            });
            return;
          }
          let unitInfo: any = await UnitsDB.getUnit(findUnit.unitId);
          if (!unitInfo) {
            const allUnits = await get_units_list();
            await UnitsDB.addUnits(allUnits);
            unitInfo = await UnitsDB.getUnit(findUnit.unitId);
          }
          if (find) {
            message.error('Product already selected!');
            return;
          }
          const lotInfo: any = (
            await get_lot_by_location_product(`locationId=${selectedLocation}`, val)
          ).data;
          let totalLots = 0;
          for (let ind = 0; ind < lotInfo.length; ind++) {
            totalLots += lotInfo[ind].qtyAvailable;
            lotInfo[ind].qtyAvailable = `${
              lotInfo[ind].qtyAvailable / unitInfo.baseUnitMultiplier
            } ${unitInfo.shortName}`;
          }
          setData([
            ...data,
            {
              id: val,
              productId: val,
              unitName: unitInfo.shortName,
              allowQtyDecimal: unitInfo.allowDecimal,
              name: selectedProduct?.name,
              type,
              locationId: selectedLocation,
              lotInfo,
              qty: 0,
              sold: 0,
              totalLots: `${totalLots / unitInfo.baseUnitMultiplier}`
            }
          ]);
        }}
        notFoundContent={null}
        dropdownRender={(menu) => (
          <>
            {menu}
            <Divider style={{ margin: '8px 0' }} />
            {isCacheResponse ? (
              <div className="flex flex-col" style={{ padding: '0 8px 4px' }}>
                <Button
                  type="text"
                  style={{
                    color: 'blue',
                    width: '100%'
                  }}
                  onClick={() => {
                    searchProduct(searchValue);
                  }}>
                  {'Pull More & Sync'}
                </Button>
              </div>
            ) : (
              <div className="flex flex-col" style={{ padding: '0 8px 4px' }}>
                {isMore ? (
                  <Button
                    type="text"
                    style={{
                      color: 'blue',
                      width: '100%'
                    }}
                    onClick={() => {
                      searchMoreProduct(searchValue);
                    }}>
                    {'Get More...'}
                  </Button>
                ) : (
                  <div
                    style={{
                      width: '100%',
                      textAlign: 'center'
                    }}>
                    No more data...
                  </div>
                )}
              </div>
            )}
          </>
        )}>
        {options}
      </Select>
    </>
  );
};

export default ProductSearchForEstimateStock;
