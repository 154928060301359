import { Button, Form, Input, InputNumber, PageHeader, Select, Spin, message } from 'antd';
import AppContent from '../../../../components/Common/Content/Content';
import { DiscountStatus, OfferType } from '../../../../services/offfers/enums';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ICreateDiscountCategory } from '../../../../services/offfers/types';
import ProductCategorySearch from '../../../../components/Common/ProductCategorySearch/ProductCategorySearch';
import { useMutation, useQuery } from '@tanstack/react-query';
import { create_discount_category_mutation } from '../../../../services/offfers/mutations';
import { LocationSearch } from '../../../../components/Common/LocationSearch/LocationSearch';
import { CustomDatePresets } from '../../../sqlsource/report/utils/datePresets';
import DatePicker, { RangePickerProps } from 'antd/lib/date-picker';
import { convertLocalToUTCString } from '../../../../utils/convertToUTC';
import moment from 'moment';
import { get_category_list } from '../../../../services/category/queries';
import { useFilterStore } from '@/store/zustand';
import { ListPage } from '@/constants/list.enum';
const { RangePicker } = DatePicker;

const CreateDiscountCategory = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [type, setType] = useState<OfferType>(OfferType.RECURRING);
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const zustandFilter = useFilterStore();

  const breadcrumbItems = [
    {
      label: 'Product CategoryDiscount',
      link: '/offer/discount-category'
    },
    {
      label: 'New',
      link: '/offer/discount-category/new'
    }
  ];

  useEffect(() => {
    initializeValues();
  }, []);

  const initializeValues = () => {
    form.setFieldsValue({
      timer: {
        dateCustom: [moment(0, 'HH'), moment(0, 'HH').add(1, 'days')],
        startDate: convertLocalToUTCString(moment(0, 'HH').format('YYYY-MM-DD HH:mm')),
        endDate: convertLocalToUTCString(moment(0, 'HH').add(1, 'days').format('YYYY-MM-DD HH:mm'))
      }
    });
  };

  const onFinish = async (values: ICreateDiscountCategory) => {
    setIsLoading(true);
    // console.log('Values', values);
    await createCustomerGroupMutation.mutateAsync(values);
  };

  useQuery(['categoryList'], async () => await fetchCategory());

  const fetchCategory = async () => {
    setIsLoading(true);
    const response = (await get_category_list(0, 1000)).data;
    // console.log('response', response);
    if (response) {
      const data = response.results.map((values) => {
        return {
          categoryId: values.id,
          categoryName: values.name,
          percentage: 0
        };
      });

      form.setFieldValue(['lines'], data);
    }
    setIsLoading(false);
  };

  const createCustomerGroupMutation = useMutation(create_discount_category_mutation, {
    onSuccess: () => {
      setIsLoading(false);
      message.success('Discount added successfully');
      zustandFilter.resetState(ListPage.PRODUCT_CATEGORY_DISCOUNT);
      navigate('/offer/discount-category');
    },
    onError: (e: any) => {
      setIsLoading(false);
      message.error(`${e.response.data.message}`);
    }
  });

  const onChange: RangePickerProps['onChange'] = (dates, dateStrings) => {
    if (dateStrings.length == 2) {
      form.setFieldValue(['timer', 'startDate'], convertLocalToUTCString(dateStrings[0]));
      form.setFieldValue(['timer', 'endDate'], convertLocalToUTCString(dateStrings[1]));
    }
  };

  return (
    <Spin spinning={isLoading}>
      <AppContent breadcrumbItems={breadcrumbItems} withfilter={false}>
        <Form
          form={form}
          initialValues={{
            type: type
          }}
          onFinish={onFinish}
          disabled={isLoading}
          layout="vertical"
          validateTrigger={'onChange'}
          autoComplete="off">
          <PageHeader
            title="Discount Category Information"
            style={{
              padding: '8px 0px'
            }}
          />
          <div className={'grid grid-cols-1 sm:grid-cols-4 gap-5 mb-5'}>
            <Form.Item
              label="Discount Name"
              name="name"
              rules={[{ required: true, message: 'Please add Discount Name!' }]}>
              <Input />
            </Form.Item>
            <LocationSearch
              formData={{ formLabel: 'Location', formName: 'locationId' }}
              allowClear={true}
              required={true}
              notAll={true}
            />
            <Form.Item
              label={'Type'}
              name={['type']}
              rules={[{ required: true, message: 'Please add Offer Type!' }]}>
              <Select placeholder="Select type" onChange={(val: OfferType) => setType(val)}>
                {(Object.keys(OfferType) as Array<keyof typeof OfferType>).map((key) => (
                  <Select.Option key={key} value={key}>
                    {OfferType[key]}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            {type === OfferType.RECURRING ? (
              <>
                <Form.Item
                  label={'Day'}
                  name={['recurring']}
                  rules={[{ required: true, message: 'Please add a recurring offer day!' }]}>
                  <Select placeholder="Select offer day">
                    <Select.Option value={7}>{'SUNDAY'}</Select.Option>
                    <Select.Option value={1}>{'MONDAY'}</Select.Option>
                    <Select.Option value={2}>{'TUESDAY'}</Select.Option>
                    <Select.Option value={3}>{'WEDNESDAY'}</Select.Option>
                    <Select.Option value={4}>{'THURSDAY'}</Select.Option>
                    <Select.Option value={5}>{'FRIDAY'}</Select.Option>
                    <Select.Option value={6}>{'SATURDAY'}</Select.Option>
                  </Select>
                </Form.Item>
              </>
            ) : (
              <>
                <Form.List name={['timer']}>
                  {() => (
                    <>
                      <Form.Item
                        label={'Select Date'}
                        name={['dateCustom']}
                        hidden={false}
                        rules={[{ required: true, message: 'Please select Date Range!' }]}>
                        <RangePicker
                          showTime
                          ranges={CustomDatePresets}
                          onChange={onChange}
                          format={'YYYY-MM-DD HH:mm'}
                          allowClear={false}
                        />
                      </Form.Item>
                      <Form.Item label={'Start Date'} name={['startDate']} hidden>
                        <Input />
                      </Form.Item>
                      <Form.Item label={'End Date'} name={['endDate']} hidden>
                        <Input />
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </>
            )}
          </div>
          <div>
            <Form.List name="lines">
              {(fields) => (
                <div>
                  {fields.map((field, index) => (
                    <div className="flex gap-2 items-center" key={field.key}>
                      <span className="font-bold text-sm mb-5">{index + 1}.</span>
                      <div
                        className="card"
                        style={{ borderRadius: '9px', backgroundColor: 'white' }}>
                        <div
                          className={
                            'grid grid-cols-2 gap-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-7 xl:grid-cols-7'
                          }>
                          <Form.Item name={[field.name, 'categoryId']} hidden>
                            <Input />
                          </Form.Item>
                          <Form.Item name={[field.name, 'categoryName']} label={'Category'}>
                            <Input disabled style={{ color: 'black' }} />
                          </Form.Item>
                          <Form.Item
                            label="Percentage"
                            name={[field.name, 'percentage']}
                            rules={[
                              {
                                required: true,
                                pattern: new RegExp('^[0-9][0-9]?$|^100$'),
                                message: 'Can contain only number from 0-100'
                              }
                            ]}>
                            <InputNumber controls={false} min={0} />
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </Form.List>
          </div>
          <div className="flex justify-end mt-5">
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={isLoading}>
                Submit
              </Button>
            </Form.Item>
            <Button
              type="default"
              className="ml-5"
              htmlType="button"
              onClick={() => navigate('/offer/discount-category')}>
              Cancel
            </Button>
          </div>
        </Form>
      </AppContent>
    </Spin>
  );
};

export default CreateDiscountCategory;
