import ExcelJS from 'exceljs';
import { IRow } from '../grid.types';
import {
  addNestedRows,
  exportExcelBuffer,
  flattenHeaders,
  IExportNestedColumn
} from '@/utils/exportNestedExcel';
import getCharFromNumber, { getExcelColumnName } from '@/utils/getCharFromNumber';
import getGroupedData from '@/utils/getGroupedData';

interface Props<T> {
  date?: string;
  data: T[];
  columns: IExportNestedColumn<T>[];
  total: string;
}

const borderStyle: Partial<ExcelJS.Borders> = {
  top: { style: 'thin', color: { argb: 'FF000000' } },
  left: { style: 'thin', color: { argb: 'FF000000' } },
  bottom: { style: 'thin', color: { argb: 'FF000000' } },
  right: { style: 'thin', color: { argb: 'FF000000' } }
};

export async function exportPriceAnalysisAsExcel({ data, columns, date, total }: Props<IRow>) {
  const workbook = new ExcelJS.Workbook();
  const workSheet = workbook.addWorksheet('Price Analysis');

  const flattenColumns = flattenHeaders(columns);
  const lastColumnChar = getExcelColumnName(Math.max(flattenColumns.length - 1, 0));

  const parentStyle = [
    { value: 'Price Analysis', size: 12, bold: true, align: 'center', font: 'Tahoma' },
    { value: date, size: 10, bold: false, align: 'center', font: 'Tahoma' },
    { value: total, size: 10, bold: false, align: 'center', font: 'Tahoma' }
  ];

  parentStyle.forEach((style, idx) => {
    const row = idx + 1;
    const cells = `A${row}:${lastColumnChar}${row}`;

    workSheet.mergeCells(cells);
    const cell = workSheet.getCell(`A${row}`);
    cell.value = style.value;
    cell.alignment = { horizontal: 'center' };
    cell.font = { size: style.size, bold: style.bold, name: style.font || 'Calibri' };
  });

  addNestedRows({
    data,
    columns,
    workSheet,
    startRow: parentStyle.length + 2,
    header: { borderStyle }
  });

  // Save the workbook to file
  const buffer = await workbook.xlsx.writeBuffer();
  await exportExcelBuffer(buffer, 'Price Analysis');
}

export async function exportOutletToExcel(rows: IRow[], date?: string) {
  const groupedData = getGroupedData(rows, 'categoryName');

  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Price Analysis - Outlets');

  const cols = [
    { name: 'Category', key: 'categoryName', width: 15 },
    { name: 'Particular', key: 'productName', width: 50 },
    { name: "Today's Price", key: 'sellTodayPrice', width: 15 },
    { name: '', key: 'isSellRateChanged', width: 20 }
  ];
  const lastColumnChar = getCharFromNumber(cols.length - 1);

  function generateCell(currentRow: number) {
    return `A${currentRow}:${lastColumnChar}${currentRow}`;
  }

  const parentStyle = [
    { value: 'Price Analysis', size: 12, bold: true, align: 'center', font: 'Tahoma' },
    { value: date, size: 10, bold: false, align: 'center', font: 'Tahoma' }
  ];

  // Add Title
  parentStyle.forEach((style, idx) => {
    const row = idx + 1;
    const cells = generateCell(row);

    worksheet.mergeCells(cells);
    const cell = worksheet.getCell(`A${row}`);
    cell.value = style.value;
    cell.alignment = { horizontal: 'center', vertical: 'middle', wrapText: true };
    cell.font = { size: style.size, bold: style.bold, name: style.font || 'Calibri' };
  });

  const MAIN_TITLE_ROW_START = parentStyle.length + 2;

  // Set Table Title
  worksheet.mergeCells(generateCell(MAIN_TITLE_ROW_START));
  const titleCell = worksheet.getCell(`A${MAIN_TITLE_ROW_START}`);
  titleCell.value = 'FRESHKTM OUTLET';

  titleCell.font = { bold: true, size: 14, color: { argb: 'FFFFFFFF' } };
  titleCell.alignment = { horizontal: 'center' };
  titleCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFef4444' } };
  worksheet.getRow(MAIN_TITLE_ROW_START).height = 45;

  const OUTLET_ROW_START = MAIN_TITLE_ROW_START + 1;
  cols.forEach((column, index) => {
    const currentCol = index + 1;
    const cell = worksheet.getCell(OUTLET_ROW_START, currentCol);
    cell.value = column.name;
    cell.border = borderStyle;
    cell.font = { bold: true, size: 11 };
    cell.alignment = { horizontal: 'center' };
    cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFd9d9d9' } };
  });

  worksheet.columns = cols.map((header) => {
    return { key: header.key, width: header.width || 15 };
  });

  // Fill Data
  Object.keys(groupedData).forEach((category) => {
    const products = groupedData[category];

    products.forEach((product, index) => {
      const isChanged = product.isSellRateChanged ? 'Changed' : 'Same';
      const row = worksheet.addRow([
        index === 0 ? product.categoryName : '',
        product.productName,
        product.sellTodayPrice,
        isChanged
      ]);

      row.eachCell((cell) => {
        cell.border = borderStyle;
      });

      const numberCol = 3;
      const changedCol = 4;

      const numberCell = row.getCell(numberCol);
      const changedCell = row.getCell(changedCol);

      numberCell.numFmt = '#,##,##0.00';
      numberCell.alignment = { horizontal: 'right' };

      changedCell.alignment = { horizontal: 'center' };
      changedCell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: product.isSellRateChanged ? 'FFFF00' : 'FFf3f3f3' }
      };
    });
  });

  // Save the workbook to file
  const buffer = await workbook.xlsx.writeBuffer();
  await exportExcelBuffer(buffer, 'Price Analysis (Outlet Only)');
}
