import CustomSearch, { SelectProps } from '..';
import { get_woo_commerce_customers_only } from '@/services/woocommerce/queries';
import { IWooCommerceCustomer } from '@/services/woocommerce/types';
import { WooCommerceCustomersDB } from '@/store/localstorage/WooCommerceCustomersDB';

type MultipleProps =
  | {
      defaultValue?: number;
      isMultiple?: false;
      setSelected?: (selected?: IWooCommerceCustomer) => void;
      onSelect?: (selected: number) => void;
    }
  | {
      defaultValue?: number[];
      isMultiple: true;
      setSelected?: (selected?: IWooCommerceCustomer[]) => void;
      onSelect?: (selected: number[]) => void;
    };

type Props = SelectProps & MultipleProps;

function CustomerByWooCommerce(props: Props) {
  async function getCustomer(skip = 0, count = 10, value = '', filter = '') {
    const page = count === 0 ? 1 : Math.floor(skip / count) + 1;
    return get_woo_commerce_customers_only(page, count, value, filter);
  }

  return (
    <CustomSearch
      formFor="woo-customer"
      addCallback={WooCommerceCustomersDB.add}
      serverCallback={getCustomer}
      dbSearchById={(id) => {
        const intId = Number(id);
        return WooCommerceCustomersDB.getByID(intId);
      }}
      dbSearchCallback={WooCommerceCustomersDB.search}
      {...props}
    />
  );
}

export default CustomerByWooCommerce;
