import { ICreateOpeningStock, IOpeningStockCheckResponse } from './types';
import http from '../../utils/http.utils';
import { AxiosResponse } from 'axios';

export const create_opening_stock_mutation = async (values: ICreateOpeningStock) => {
  const response: AxiosResponse<any, any> = await http.store('products/opening-stock', values);
  return response;
};

export const upload_opening_stock_mutation = async (locationId: number, file: FormData) => {
  const response = await http.store('ports/import-opening-stock/' + locationId, file, {
    showErrorModal: false
  });
  return response;
};

export const upload_opening_stock_error_check_mutation = async (
  locationId: number,
  file: FormData
) => {
  const response: AxiosResponse<IOpeningStockCheckResponse> = await http.store(
    'ports/import-opening-stock-error-check/' + locationId,
    file,
    { showErrorModal: false }
  );
  return response.data;
};

export const download_opening_stock_mutation = async (locationId: number) => {
  const response = await http.get(`ports/sample/opening-stock/${locationId}`);
  return response;
};
