import { IExportNestedColumn } from '@/utils/exportNestedExcel';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';
import { forwardRef, useEffect } from 'react';

export interface Props<T> {
  columns: IExportNestedColumn<T>[];
  data: T[];
  onAuto?: () => void;
  highlightHeader?: boolean;
  showEmptyRow?: boolean;
  title?: string;
  subTitle?: string;
}

// Define the generic component separately
function NestedTableExportComponent<T>(
  { columns, data, highlightHeader, title, subTitle, onAuto }: Props<T>,
  ref: React.Ref<HTMLDivElement>
) {
  const firstRow = columns.map((value) => ({
    title: value.title,
    width: value.width,
    colspan: value.children ? value.children.length : 1,
    rowspan: value.children && value.children.length > 0 ? 1 : 2
  }));

  const secondRow: Array<{
    isNumber?: boolean;
    title: string;
    width?: number;
    dataIndex: keyof T;
    colspan: number;
    rowspan: number;
    showDashIfAbsent?: boolean;
  }> = [];

  columns.forEach((value) => {
    if (value.children) {
      value.children.forEach((child) => {
        secondRow.push({
          isNumber: child.formatByComma,
          title: child.title,
          width: child.width,
          dataIndex: child.dataIndex as keyof T,
          colspan: 1,
          rowspan: 1,
          showDashIfAbsent: child.showDashIfAbsent
        });
      });
    } else {
      secondRow.push({
        isNumber: value.formatByComma,
        title: value.title,
        width: value.width,
        dataIndex: value.dataIndex as keyof T,
        colspan: 0,
        rowspan: 0,
        showDashIfAbsent: value.showDashIfAbsent
      });
    }
  });

  useEffect(() => {
    onAuto?.();
  }, [data]);

  return (
    <div className="flex flex-col justify-center items-center w-full" ref={ref}>
      <table width={'100%'}>
        <thead>
          <tr>
            <th colSpan={secondRow.length} className="font-extrabold text-lg">
              {title}
            </th>
          </tr>
          <tr>
            <th colSpan={secondRow.length} className="font-bold">
              {subTitle}
            </th>
          </tr>
          <tr>
            <th colSpan={secondRow.length} style={{ height: 15 }}></th>
          </tr>
          <tr>
            {firstRow.map((currCol) => (
              <th
                key={currCol.title}
                rowSpan={currCol.rowspan}
                colSpan={currCol.colspan}
                style={{
                  ...tdthStyle,
                  background: highlightHeader ? '#e7e6e6' : 'transparent'
                }}>
                <div className="text-center">{currCol.title}</div>
              </th>
            ))}
          </tr>
          <tr>
            {secondRow.map((currCol) =>
              currCol.colspan !== 0 && currCol.rowspan !== 0 ? (
                <th
                  key={String(currCol.dataIndex)}
                  style={{
                    ...tdthStyle,
                    background: highlightHeader ? '#e7e6e6' : 'transparent'
                  }}>
                  <div className="text-center">{currCol.title}</div>
                </th>
              ) : null
            )}
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {secondRow.map((col, colIndex) => (
                <td
                  key={colIndex}
                  style={{ ...tdthStyle, padding: '0 3px' }}
                  className="text-center">
                  {col.dataIndex
                    ? col.isNumber
                      ? nepaliNumberFormatter(row[col.dataIndex] as unknown as number)
                      : row[col.dataIndex] ?? (col.showDashIfAbsent ? '—' : '')
                    : ''}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

// Use `forwardRef` with generics properly
const NestedTableExport = forwardRef(NestedTableExportComponent) as <T>(
  props: Props<T> & { ref?: React.Ref<HTMLDivElement> }
) => React.ReactElement;

const tdthStyle = {
  border: '1px solid black',
  padding: '2px 0px'
};

export default NestedTableExport;
