import GenericTable from '@/components/Common/CustomizeTable';

import { FormInstance, TableProps } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { SorterResult } from 'antd/lib/table/interface';
import { useState } from 'react';

import { IWooCommerceCustomer, IWooCommerceCustomerResponse } from '@/services/woocommerce/types';
import PreviewMultipleImage from '@/components/Common/PreviewMultipleImage';

interface Props {
  isLoading?: boolean;
  data: IWooCommerceCustomerResponse;
  form: FormInstance;
  pagination: { page: number; size: number };
  onPagination: (
    page?: number,
    size?: number,
    isSize?: boolean
  ) => Promise<{ data: IWooCommerceCustomerResponse | undefined }>;
}

function Table({ isLoading, data, pagination, form, onPagination }: Props) {
  const [sortedInfo, setSortedInfo] = useState<SorterResult<IWooCommerceCustomer>>({});
  const fallbackTotal = getTotalCount(data, pagination);

  function getTotalCount(
    data: IWooCommerceCustomerResponse,
    pagination: { page: number; size: number }
  ): number {
    const { page, size } = pagination;
    const itemsCount = data.results?.length ?? 0;

    if (page === 1 && itemsCount === 0) return 0;
    if (page > 1 && itemsCount === 0) return (page - 1) * size;

    return (page - 1) * size + itemsCount * 2;
  }

  const handleChange: TableProps<IWooCommerceCustomer>['onChange'] = (_, __, s) => {
    setSortedInfo(s as SorterResult<IWooCommerceCustomer>);
  };

  const columns: ColumnsType<IWooCommerceCustomer> = [
    {
      title: 'S.N.',
      key: 'id',
      width: 10,
      render: (_, __, index) => (pagination.page - 1) * pagination.size + index + 1
    },
    {
      title: 'Personal Details',
      children: [
        {
          title: 'Name',
          dataIndex: 'first_name',
          key: 'name',
          width: 25,
          render: (_, record) => `${record.first_name} ${record.last_name}`,
          sorter: (a, b) => a.first_name.localeCompare(b.first_name),
          sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null
        },
        {
          title: 'Email',
          dataIndex: 'email',
          width: 50,
          key: 'email',
          sorter: (a, b) => a.email.localeCompare(b.email),
          sortOrder: sortedInfo.columnKey === 'email' ? sortedInfo.order : null
        },
        {
          title: 'Avatar',
          dataIndex: 'avatar_url',
          width: 10,
          key: 'avatar_url',
          render: (url) => (url ? <PreviewMultipleImage medias={[url]} /> : '')
        },
        {
          title: 'Username',
          dataIndex: 'username',
          width: 25,
          key: 'username',
          sorter: (a, b) => a.username.localeCompare(b.username),
          sortOrder: sortedInfo.columnKey === 'username' ? sortedInfo.order : null
        }
      ]
    },
    {
      title: 'Billing Details',
      children: [
        {
          width: 50,
          title: 'Address',
          dataIndex: ['billing', 'address_1'],
          key: 'billing_address',
          render: (_, record) => `${record.billing.address_1}, ${record.billing.address_2 || ''}`
        },
        {
          title: 'City',
          width: 25,
          dataIndex: ['billing', 'city'],
          key: 'billing_city',
          sorter: (a, b) => a.billing.city.localeCompare(b.billing.city),
          sortOrder: sortedInfo.columnKey === 'billing_city' ? sortedInfo.order : null
        },
        {
          title: 'State',
          width: 25,
          dataIndex: ['billing', 'state'],
          key: 'billing_state',
          sorter: (a, b) => a.billing.state.localeCompare(b.billing.state),
          sortOrder: sortedInfo.columnKey === 'billing_state' ? sortedInfo.order : null
        },
        {
          title: 'Phone',
          width: 25,
          dataIndex: ['billing', 'phone'],
          key: 'billing_phone'
        }
      ]
    },
    {
      title: 'Shipping Details',
      children: [
        {
          title: 'Address',
          width: 50,
          dataIndex: ['shipping', 'address_1'],
          key: 'shipping_address',
          render: (_, record) => `${record.shipping.address_1}, ${record.shipping.address_2 || ''}`
        },
        {
          title: 'City',
          dataIndex: ['shipping', 'city'],
          key: 'shipping_city',
          width: 25,
          sorter: (a, b) => a.shipping.city.localeCompare(b.shipping.city),
          sortOrder: sortedInfo.columnKey === 'shipping_city' ? sortedInfo.order : null
        },
        {
          title: 'State',
          dataIndex: ['shipping', 'state'],
          key: 'shipping_state',
          width: 25,
          sorter: (a, b) => a.shipping.state.localeCompare(b.shipping.state),
          sortOrder: sortedInfo.columnKey === 'shipping_state' ? sortedInfo.order : null
        },
        {
          title: 'Phone',
          dataIndex: ['shipping', 'phone'],
          key: 'shipping_phone',
          width: 25
        }
      ]
    }
  ];

  return (
    <div>
      <GenericTable
        form={form}
        columns={columns}
        data={data.results}
        isLoading={isLoading}
        scroll={{ x: 2200, y: '75vh' }}
        hideDefaultPagination
        showPager={false}
        tableName={'woo-customers-list'}
        pagination={{
          ...pagination,
          total: data?.totalCount || fallbackTotal,
          onPagination,
          scrollToTop: true,
          showSizeChanger: false
        }}
        toSort={handleChange}
      />
    </div>
  );
}

export default Table;
