import { Form, Input, Menu, Spin, TableProps, Tooltip } from 'antd';
import AppContent from '../../../../components/Common/Content/Content';
import TableFilter from '../../../../components/FliterTable';
import { checkAccess } from '../../../../routes/acl';
import CustomButton from '../../../../components/Common/CustomButton/CustomButton';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ColumnsType, SorterResult } from 'antd/lib/table/interface';
import CustomizeTable from '../../../../components/Common/CustomizeTable/CustomizeTable';
import { convertUTCStringtoLocalString } from '../../../../utils/convertToUTC';
import TableCell from '../../../../components/Common/CustomizeTable/CustomCell';
import { get_fonepay_credentials_list_filter } from '../../../../services/epay/queries';
import { IFonepayCredentialsResponseTableData } from '../../../../services/epay/types';
import moment from 'moment';
import ActionDropdown from '../../../../components/Common/Dropdownactions';
import { EditOutlined } from '@ant-design/icons';
import LocationsDB from '../../../../store/localstorage/LocationsDB';
import { get_location_list_for_ids } from '../../../../services/locations/queries';
import { DEFAULT_DATE_FORMAT } from '@/constants';
import { ConvertObjectToURL } from '@/utils/converturl';

const FonepayList = () => {
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(100);
  const [fonepayList, setFonepayList] = useState<IFonepayCredentialsResponseTableData[]>([]);
  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({});
  const handleChange: TableProps<any>['onChange'] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<any>);
  };

  const breadcrumbItems = [
    {
      label: 'Fonepay',
      link: '/fonepay'
    }
  ];

  const onSubmitFilter = async (val: string) => {
    const data = await getInfo(val);
    setPage(1);
    setSize(100);
    return data;
  };

  const getInfo = async (filter = '') => {
    setIsLoading(true);
    const response = await get_fonepay_credentials_list_filter(filter);

    const tableData: IFonepayCredentialsResponseTableData[] = await Promise.all(
      response.map(async (value) => {
        const locationDetails = await LocationsDB.getLocation(value.locationId);
        if (!locationDetails) {
          const allLocations = await get_location_list_for_ids([
            ...new Set(
              response.map((value) => {
                return value.locationId;
              })
            )
          ]);
          await LocationsDB.addLocations(allLocations);
        }
        return { ...value, locationName: locationDetails.name };
      })
    );
    setFonepayList(tableData);
    setIsLoading(false);
    return tableData;
  };

  const onPagination = async (pageNo = 1, totalSize = 100, isSize = false) => {
    const values = form.getFieldsValue();
    delete values.dateCustom;
    delete values.startDateNepali;
    delete values.endDateNepali;
    if (isSize) {
      values.skip = 0;
      values.count = totalSize;
      setPage(1);
      setSize(totalSize);
    } else {
      values.skip = (pageNo - 1) * totalSize;
      values.count = totalSize;
      setPage(pageNo);
    }

    const url = ConvertObjectToURL(values);
    return await getInfo(url);
  };

  const columns: ColumnsType<IFonepayCredentialsResponseTableData> = [
    {
      title: 'S.N',
      key: 'SN',
      width: 5,
      render: (txt, record, index) => {
        return <TableCell>{(page - 1) * size + (index + 1)}</TableCell>;
      }
    },
    {
      title: 'Username',
      key: 'username',
      width: 20,
      sorter: (a, b) => a.username.localeCompare(b.username),
      sortOrder: sortedInfo.columnKey === 'username' ? sortedInfo.order : null,
      render: (a, record) => {
        return (
          <>
            <TableCell>{record.username}</TableCell>
          </>
        );
      }
    },
    {
      title: 'Location',
      key: 'location',
      width: 20,
      sorter: (a, b) => a.locationName.localeCompare(b.locationName),
      sortOrder: sortedInfo.columnKey === 'username' ? sortedInfo.order : null,
      render: (a, record) => {
        return (
          <>
            <TableCell>{record.locationName}</TableCell>
          </>
        );
      }
    },
    {
      title: 'Merchant Code',
      key: 'merchantCode',
      width: 15,
      sorter: (a, b) => a.merchantCode - b.merchantCode,
      sortOrder: sortedInfo.columnKey === 'merchantCode' ? sortedInfo.order : null,
      render: (a, record) => {
        return (
          <>
            <TableCell>{record.merchantCode}</TableCell>
          </>
        );
      }
    },
    {
      title: 'Secret Key',
      key: 'secretKey',
      width: 15,
      sorter: (a, b) => a.secretKey.localeCompare(b.secretKey),
      sortOrder: sortedInfo.columnKey === 'secretKey' ? sortedInfo.order : null,
      render: (a, record) => {
        return (
          <>
            <TableCell>{record.secretKey}</TableCell>
          </>
        );
      }
    },
    {
      title: 'Created Date',
      key: 'createdAt',
      width: 15,
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      sortOrder: sortedInfo.columnKey === 'createdAt' ? sortedInfo.order : null,
      render: (a, record) => {
        return (
          <>
            <TableCell>
              {convertUTCStringtoLocalString(record.createdAt, DEFAULT_DATE_FORMAT)}
            </TableCell>
          </>
        );
      }
    },
    {
      title: 'Action',
      key: 'actions',
      width: 5,
      fixed: 'right',
      render: (record) => {
        const menuItems: {
          key: string;
          label: JSX.Element;
          onClick?: undefined;
        }[] = [];

        if (checkAccess('ADMIN')) {
          menuItems.push({
            key: '1',
            label: (
              <Link to={`/fonepay/${record.id}`}>
                <Tooltip title="Update" color="blue">
                  <EditOutlined style={{ transform: 'scale(1.4)', width: '100%' }} />
                </Tooltip>
              </Link>
            )
          });
        }

        const menu = <Menu items={menuItems} />;
        return <ActionDropdown menu={menu} />;
      }
    }
  ];

  return (
    <Spin spinning={isLoading}>
      <AppContent
        breadcrumbItems={breadcrumbItems}
        withfilter={false}
        button={
          <>
            <div>
              <TableFilter
                dateCustom={false}
                defaultValues={{
                  value: '',
                  skip: 0,
                  count: 100
                }}
                onInitialLoad={({ data, pagination }) => {
                  setPage(pagination.page);
                  setSize(pagination.size);

                  if (data) {
                    setFonepayList(data);
                    setIsLoading(false);
                  }
                }}
                initial={true}
                onSubmit={onSubmitFilter}
                form={form}
                style={
                  'grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3  xl:grid-cols-4 gap-2 items-center justify-center'
                }
                styleforbuttons={'flex justify-end items-center'}
                buttons={
                  <>
                    {checkAccess('ADMIN') && (
                      <div>
                        <CustomButton
                          onClick={() => navigate('/fonepay/new')}
                          text="Add"
                          backgroundColor="#1890ff"
                          Linkto="/fonepay/new"
                        />
                      </div>
                    )}
                  </>
                }>
                <Form.Item name="value" label="Search">
                  <Input placeholder="Search" />
                </Form.Item>
              </TableFilter>
            </div>
            {/* </div> */}
          </>
        }>
        <CustomizeTable
          form={form}
          columns={columns}
          data={fonepayList}
          notshowPagination={true}
          customScroll={{ x: 600, y: '75vh' }}
          paginationDatas={{
            page: page,
            total: fonepayList.length,
            size: size,
            onPagination
          }}
          tableName={'epay-fonepay-list'}
          toSort={handleChange}
        />
      </AppContent>
    </Spin>
  );
};

export default FonepayList;
