import { useQuery, useMutation } from '@tanstack/react-query';
import {
  Form,
  Input,
  Button,
  Select,
  InputNumber,
  message,
  PageHeader,
  Divider,
  Card,
  Spin,
  Checkbox
} from 'antd';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import AppContent from '@/components/Common/Content/Content';
import CustomButton from '@/components/Common/CustomButton/CustomButton';
import CustomErrorModal from '@/components/Common/CustomErrorModal';
import GoBackButton from '@/components/Common/GoBackButton/GoBackButton';
import { LocationSearch } from '@/components/Common/LocationSearch/LocationSearch';
import ProductSearchForLines from '@/components/Common/ProductSearch2';
import ReusableQuantity from '@/components/Common/ReusableQuantity';
import { RouteSearch } from '@/components/Common/RouteSearch/RouteSearch';
import useDebounce from '@/hooks/useDebounce';
import { checkAccess } from '@/routes/acl';
import { get_location_details, get_location_list } from '@/services/locations/queries';
import {
  get_product_list,
  get_units_list,
  get_unexpired_lots_details_bylocationId_productId,
  get_price_groups_by_location,
  prices_by_groupId,
  get_product_list_ids,
  get_unexpired_lots_details_bylocationId_productIds
} from '@/services/products/queries';
import { IProductType, IUnits } from '@/services/products/types';
import { get_routes_list } from '@/services/routes/queries';
import { create_sell_mutation, edit_sell_order_mutation } from '@/services/sell/mutations';
import {
  get_sell_details,
  get_sell_discount,
  get_sell_lines_details,
  get_sell_order_details,
  get_sell_order_line_details
} from '@/services/sell/queries';
import {
  IEditSellOrder,
  ILines,
  IProductUnits,
  ISellConfirmationData,
  Line
} from '@/services/sell/types';
import { get_invoices_list } from '@/services/settings/queries';
import {
  get_customer_details,
  get_customer_list,
  get_customer_list_ids,
  get_customer_list_route,
  get_user_details
} from '@/services/users/queries';
import CustomersDB from '@/store/localstorage/CustomerDB';
import LocationsDB from '@/store/localstorage/LocationsDB';
import {
  find_sell_default_grade,
  find_invoice_due_limit,
  find_zero_total_shipping,
  find_shipping_cost_from_location,
  find_sell_confirmation,
  find_default_product_category
} from '@/store/localstorage/preferences';
import ProductsDB from '@/store/localstorage/ProductsDB';
import RoutesDB from '@/store/localstorage/RoutesDB';
import UnitsDB from '@/store/localstorage/UnitsDB';
import UsersDB from '@/store/localstorage/UsersDB';
import { numberDecimalFormatter } from '@/utils/numberFormatter';
import ReuseChannel from '../../../channel/Reuse';
import ScoreBoard from '../../../score-board';
import { CustomModal } from '@/components/Common/CustomModal';
import ReusableSellConfirm from '@/components/Common/CustomModal/ReusableSellConfirm';
import { WebSocketContext } from '@/contexts/websocket.context';
import { SocketEvents, SystemNotificationType } from '@/constants/websocketConfig';
import ProductCategorySearch from '@/components/Common/ProductCategorySearch/ProductCategorySearch';
import SellInvoice from '@/components/Common/InvoicePrint/SellInvoice/SellInvoice';
import { ISellInvoice, ISellRecordData } from '@/services/invoice/types';
import { getSellPrintData } from '@/components/Common/InvoicePrint/SellInvoice/services';
import { ICreateInvoiceResponse } from '@/services/settings/types';
import useBeforeUnloadListener from '@/hooks/useBeforeUnloadListener';
import fetchProductData, { fetchProductsDataFromResponse } from '@/utils/fetchProductData';
import { notifyMessage } from '@/utils/notifyPurchaseMessage';
import NotifySellPriceModal from '@/components/Common/NotifySellPriceModal';
import { checkHasAccountRule } from '@/services/accounts/services';
import { AccountRulesEvent, AccountType } from '@/services/accounts/enums';
import CustomInfoModal from '@/components/Common/CustomInfoModal';
import useDebounceFunction from '@/hooks/useDebounceFunction';
import { get_latest_purchase_line_by_location_productIds } from '@/services/transfer/queries';
import { get_account_details_by_userid_type } from '@/services/accounts/queries';
import getErrorMessage from '@/utils/getError';
import { ICustomerPriceGroups } from '@/services/customer-prices/types';
import {
  get_current_price_By_priceGroupId,
  get_customer_price_groups_by_customerId
} from '@/services/customer-prices/queries';
import isAxiosError from '@/utils/isAxiosError';
import ErrorModalWithLog from '@/components/Common/ErrorModal/ErrorModalWithLog';
import { useFilterStore } from '@/store/zustand';
import { ListPage } from '@/constants/list.enum';

const { Option } = Select;

const SellOrderDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [form] = Form.useForm();
  const { socket } = useContext(WebSocketContext);
  const [tax, setTax] = useState<any[]>([]);
  const [address, setAddress] = useState<any[]>([]);
  const [allLocalUnits, setAllLocalUnits] = useState<any>([]);
  const [isloading, setIsloading] = useState<boolean>(true);
  const [totalLots, settotalLots] = useState<any>([]);
  // const [responsize, setResponsize] = useState(false);
  const [scoreBoardData, setScoreBoardData] = useState<any>();
  const [userIdforchannel, setUserIdforchannel] = useState<any>(0);
  // const [initalLocation, setinitialLocation] = useState<number>();
  const [pricegroupDropdown, setpricegroupDropdown] = useState<any>([]);
  const [customerPriceGroupsGroupId, setCustomerPriceGroupsGroupId] = useState<any>({});
  const [priceGroupCustomerId, setPriceGroupCustomerId] = useState<any>({});

  //product search
  const [searchValue, setSearchValue] = useState<string>('');
  const [productSearch, setProductSearch] = useState<any[]>([]);
  const [productList, setProductList] = useState<any[]>([]);
  const [createValues, setCreateValues] = useState<IEditSellOrder>();
  const debouncedSearchValue = useDebounce(searchValue, 500);
  useEffect(() => {
    searchProduct(debouncedSearchValue);
  }, [debouncedSearchValue]);
  const [modalOpenForInvoiceDueLimit, setModalOpenForInvoiceDueLimit] = useState<boolean>(false);
  const [errormessage, setErrorMessage] = useState<string>('');
  const [searchValueUser, setSearchValueUser] = useState<string>('');
  const debouncedSearchValueUser = useDebounce(searchValueUser, 500);
  const [selectValue, setselectValue] = useState<any>();
  const [isRouteSelected, setIsRouteSelected] = useState<boolean>(false);
  const [manualShipping, setManualShipping] = useState<boolean>(false);
  const [productCategory, setProductCategory] = useState<number | string | null>(null);
  const [openModalForInvoicePrint, setOpenModalForInvoicePrint] = useState<boolean>(false);
  const [invoiceData, setInvoiceData] = useState<ISellInvoice>(Object);
  const [confirm, setConfirm] = useState<boolean>(false);
  const [finalizeOnNotify, setFinalizeOnNotify] = useState<boolean>(false);
  const isCustomerPriceGroup = Form.useWatch('isCustomerPriceGroup', form);

  useEffect(() => {
    searchUser(debouncedSearchValueUser);
    // searchUser(debouncedSearchValue);
  }, [debouncedSearchValueUser]);
  const [skip, setSkip] = useState<number>(0);
  const [isMore, setIsMore] = useState<boolean>(true);
  const [userSearch, setUserSearch] = useState<any>([]);
  const searchUser = async (value: any, initial?: any) => {
    let response;
    try {
      const routeId = form.getFieldValue('routeId');
      if (initial) {
        response = await get_customer_list_ids([initial]);
        if (response.data) {
          setUserSearch(response.data.results);

          CustomersDB.addCustomers(response.data.results);
        }
        return;
      }

      if (routeId != '' && routeId != undefined) {
        setIsRouteSelected(true);
        response = await get_customer_list_route(0, 10, value, routeId);
      } else {
        setIsRouteSelected(false);
        form.setFieldValue('userId', null);
      }
    } catch (e) {
      console.log(e);
    }
    if (!response || response.data.results.length == 0) {
      message.error('Cannot find any customer with that value in server!');
      setUserSearch([]);
      setIsMore(false);
      setSkip(0);
    } else {
      setUserSearch(response.data.results);
      CustomersDB.addCustomers(response.data.results);
      if (response.data.results.length < 10) setIsMore(false);
      else setIsMore(true);
      setSkip(10);
    }
  };

  // searchValue for locationSearch starts
  const [searchLocationValue, setSearchLocationValue] = useState<string>('');
  const debouncedSearchLocationValue = useDebounce(searchLocationValue, 500);
  const [isCacheResponse, setIsCacheResponse] = useState<boolean>(false);
  const [notifyModalData, setNotifyModalData] = useState<any>([]);

  useEffect(() => {
    // searchLocation(debouncedSearchValue);
    searchLocationIndexDB(debouncedSearchLocationValue);
  }, [debouncedSearchLocationValue]);
  const [locationSearch, setLocationSearch] = useState<any>([]);
  //for locationSearch Ends

  useBeforeUnloadListener();

  useEffect(() => {
    socket?.on('connect', async () => {
      // console.log('Socket Reconnected');
      const locationId = form.getFieldValue(['locationId']);
      const lines = form.getFieldValue(['lines']);
      const productIds = new Set<number>(
        lines.map((value: ILines) => {
          return value.productId;
        })
      );
      await fetchLotsOnLocationandProductChange([...productIds], locationId, 'lotsupdate');
    });

    socket?.on(SocketEvents.SYSTEM_NOTIFICATION, async (data) => {
      if (data.type === SystemNotificationType.LOTS_ZERO) {
        const locationId = form.getFieldValue(['locationId']);
        const socketData = data.data as { locationId: number };
        if (socketData.locationId === locationId) {
          settotalLots((prev: any) => {
            return prev.map((a: any) => ({ ...a, qtyAvailable: 0 }));
          });
        }
      }

      if (data.type === SystemNotificationType.LOTS_UPDATE) {
        const lines = form.getFieldValue(['lines']);
        const productIds = new Set<number>(lines.map((value: ILines) => value.productId));

        const locationId = form.getFieldValue(['locationId']);
        // Get Products and filter by Location
        let updatedProducts = data.data as { productId: number; locationId: number }[];
        if (locationId) {
          updatedProducts = updatedProducts.filter(
            (value) =>
              value.locationId === locationId && Array.from(productIds).includes(value.productId)
          );
        }

        if (updatedProducts.length > 0) {
          const updatedProductIds = updatedProducts.map((value) => value.productId);
          await fetchLotsOnLocationandProductChange(updatedProductIds, locationId, 'lotsupdate');
        }
      }
    });

    return () => {
      socket?.off(SocketEvents.SYSTEM_NOTIFICATION);
    };
  }, [socket?.connected]);

  const searchMoreUser = async (value: any) => {
    let response;
    try {
      const routeId = form.getFieldValue('routeId');
      if (routeId != '' && routeId != undefined) {
        response = await get_customer_list_route(skip, 10, value, routeId);
      } else {
        response = await get_customer_list(skip, 10, value);
      }
    } catch (e) {
      console.log(e);
    }
    if (!response || response.data.results.length == 0) {
      message.info('Cannot find more customer with that value in server!');
      setIsMore(false);
    } else {
      setSkip(skip + 10);
      setUserSearch([...userSearch, ...response.data.results]);
      CustomersDB.addCustomers(response.data.results);
      if (response.data.results.length < 10) {
        setIsMore(false);
      }
    }
  };
  const optionsUser = userSearch.map((value: any) => (
    <Option key={value.id} value={value.id}>
      <div className="flex justify-between">
        <div>
          {' '}
          {value.name ? value.name : value?.user?.name},{' '}
          {value.phone ? value.phone : value?.user?.phone}{' '}
        </div>
        <div style={{ marginRight: '15%', color: 'green' }}>
          {selectValue && selectValue == value.id ? (
            <>
              <span className="text-xl font-bold">{value.grade}</span>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </Option>
  ));

  const zustandFilter = useFilterStore();

  const createSellMutation = useMutation(create_sell_mutation, {
    onSuccess: () => {
      zustandFilter.resetState(ListPage.SELL);
    }
  });
  // const [defaultPriceBylocationId, setDefaultPriceBylocationId] = useState<any>({});
  const [pricegroupsgroupId, setpricegroupsId] = useState<any>({});
  const [pricegrouplocationId, setpricegrouplocationId] = useState<any>({});
  const [offerList, setOfferList] = useState<any>([]);
  const autofocusRef: React.Ref<any> = useRef(null);
  const [defaultPriceGroup, setdefaultPriceGroup] = useState<any>([]);
  const [initalCustomer, setInitialCustomer] = useState<number>();
  const [firstCreatedByUser, setsellordercreatedBy] = useState<number>();
  const [confirmModalValues, setConfirmModalValues] = useState<ISellConfirmationData>();
  const [modalOpenForConfirmation, setModalOpenForConfirmation] = useState<boolean>(false);

  const [isAccountDisabled, setIsAccountDisabled] = useState(false);
  const [isPrevCustomerPriceGroup, setIsPrevCustomerPriceGroup] = useState(false);

  async function fetchLotsOnLocationandProductChange(
    productIds: number[],
    locationId: number,
    from: string
  ) {
    if (!locationId) {
      throw { name: 'Location Error', message: 'Please select Location!' };
    }

    if (from === 'productchange') {
      const filterLots = totalLots.find((value: Line) => value.productId == productIds[0]);
      if (!filterLots) {
        const response = await get_unexpired_lots_details_bylocationId_productId(
          locationId,
          productIds[0]
        );
        settotalLots([...totalLots, ...response]);
      }
    } else if (from === 'lotsupdate') {
      const results = await get_unexpired_lots_details_bylocationId_productIds(
        locationId,
        productIds
      );

      for (let i = 0; i < productIds.length; i++) {
        settotalLots((prev: any) => {
          const filterLots = prev.filter((value: Line) => value.productId !== productIds[i]);
          const currentResult = results.find((value) => value.productId == productIds[i]);

          const result = currentResult ? [currentResult] : [];
          return [...filterLots, ...result];
        });
      }
    } else {
      const results = await get_unexpired_lots_details_bylocationId_productIds(
        locationId,
        productIds
      );

      settotalLots([...results]);
      return results;
    }
  }

  async function getOptimizedDetails() {
    const sellOrderDetailsPromise = get_sell_order_details(parseInt(id as string));
    const defaultPriceGroupPromise = get_price_groups_by_location('');

    // Run Order Details and Default Price Group at the same time
    const [response, defaultpricegroup] = await Promise.all([
      sellOrderDetailsPromise,
      defaultPriceGroupPromise
    ]);

    // Get Data from Response
    const locationId = response.data.locationId;

    const priceGroupByLocationPromise = get_price_groups_by_location(locationId);
    const linesPromise = get_sell_order_line_details(response.data.id);

    const [pricegroup, linesResponse] = await Promise.all([
      priceGroupByLocationPromise,
      linesPromise
    ]);

    let grandTotal = 0;
    setInitialCustomer(response?.data?.customerId);
    setselectValue(response?.data?.customerId);
    setProductCategory(response.data.categoryId);
    if (response?.data?.createdBy) setsellordercreatedBy(response.data.createdBy);

    setpricegroupDropdown([...pricegroup.data, ...defaultpricegroup.data]);
    setpricegrouplocationId((prev: any) => ({
      ...prev,
      [locationId]: [...pricegroup.data, ...defaultpricegroup.data]
    }));

    if (linesResponse.data.length > 0) {
      const allProducts: any = [...productList];
      let addProducts: any = [];
      const mySet = new Set<number>();
      const searchProducts: any = {};

      grandTotal = 0;

      // Add Products Names and grandTotal
      for (let i = 0; i < linesResponse.data.length; i++) {
        mySet.add(linesResponse.data[i].productId);
        // console.log('productList', productList);
        // console.log('allProducts', allProducts);
        let product: any = allProducts.find(
          (value: any) => value.id == linesResponse.data[i].productId
        );
        if (!product) {
          product = await ProductsDB.getProduct(linesResponse.data[i].productId);
          if (!product) {
            if (linesResponse.data[i].productId in searchProducts) {
              searchProducts[linesResponse.data[i].productId] = [
                ...searchProducts[linesResponse.data[i].productId],
                i
              ];
            } else {
              searchProducts[linesResponse.data[i].productId] = [i];
            }
          } else {
            allProducts.push(product);
            addProducts.push(product);
            linesResponse.data[i].productName = product.name;
          }
        } else {
          linesResponse.data[i].productName = product.name;
        }

        const total =
          linesResponse.data[i].unitPrice * linesResponse.data[i].quantity +
          linesResponse.data[i].misc -
          linesResponse.data[i].discount;

        linesResponse.data[i].total = total;
        grandTotal += total + linesResponse.data[i].vat;
      }

      // Add Products Names in Search
      const searchProductslength = Object.entries(searchProducts).length;
      let done = false;
      if (searchProductslength > 0) {
        const productsresponse = await get_product_list_ids([...Object.keys(searchProducts)]);
        for (const key in searchProducts) {
          const findproduct = productsresponse?.data?.results.find(
            (currProduct: IProductType) => currProduct.id == key
          );
          for (let i = 0; i < searchProducts[key].length; i++) {
            linesResponse.data[searchProducts[key][i]].productName = findproduct?.name;
          }
          await ProductsDB.addProducts([findproduct]);
        }
        if (productsresponse?.data?.results) {
          setProductList((prev: any) => {
            addProducts = addProducts.filter((value: any) => {
              const searchProduct = prev.find((val: any) => val.id == value.id);
              if (searchProduct) return false;
              return true;
            });

            const responsefilter = productsresponse.data.results.filter((value: any) => {
              const searchProduct = prev.find((val: any) => val.id == value.id);
              if (searchProduct) return false;
              return true;
            });
            return [...prev, ...addProducts, ...responsefilter];
          });
          done = true;
        }
      }
      if (!done) {
        setProductList((prev: any) => {
          addProducts = addProducts.filter((value: any) => {
            const searchProduct = prev.find((val: any) => val.id == value.id);
            if (searchProduct) return false;
            return true;
          });
          return [...prev, ...addProducts];
        });
      }

      form.setFieldValue(['grandtotal'], numberDecimalFormatter(grandTotal));

      // Fetch all the lots
      const lotsTotal = await fetchLotsOnLocationandProductChange(
        Array.from(mySet),
        locationId,
        'location'
      );

      for (let i = 0; i < linesResponse.data.length; i++) {
        const findLot = lotsTotal?.filter(
          (value) => value.productId == linesResponse.data[i].productId
        );
        if (findLot && findLot?.length > 0) {
          let maxLot = findLot[0];
          for (let j = 1; j < findLot.length; j++) {
            if (findLot[j].qtyAvailable > maxLot.qtyAvailable) {
              maxLot = findLot[j];
            }
          }
          linesResponse.data[i].lotId = maxLot.id;
          linesResponse.data[i].hsCode = maxLot.hsCode;
        }
      }
    }

    if (typeof response.data.misc == 'string') {
      response.data.misc = parseInt(response.data.misc);
    }

    form.setFieldsValue({
      lines: linesResponse.data,
      ...response.data,
      userId: response.data.customerId,
      routeId: response.data.address.routeId
    });

    setManualShipping(response.data.isCustomShipping);
    if (!response.data.isCustomShipping) {
      await calculateShipping(grandTotal);
    } else {
      form.setFieldValue(['shipping'], response.data.shipping - response.data.shippingTax);
      calculateShippingWithTax(response.data.shipping - response.data.shippingTax);
    }

    setIsloading(false);
    onUserChange(response.data.customerId, true);
    setUserIdforchannel(response.data.address.userId);
    return response;
  }

  useQuery(['order-details', id], getOptimizedDetails, { enabled: true });
  // This is needed to get the product details update as its written
  const [productDetails, setProductDetails] = useState<any>();

  const breadcrumbItems = [{ label: 'Sell Order', link: '/sell/order' }, { label: 'Edit' }];

  const editSellOrderMutation = useMutation(edit_sell_order_mutation, {
    onSuccess: () => {
      zustandFilter.removeData(ListPage.SELL_ORDER);
    }
  });

  const onUserChange = async (id: number, initial = false) => {
    try {
      setIsloading(true);
      let selectedUser: any = await CustomersDB.getCustomer(id);
      let userDetails: any; //for address
      if (!selectedUser) {
        const response = await get_customer_details(id);
        if (response?.user && response?.customer) {
          selectedUser = response.customer;
          CustomersDB.addCustomers([{ ...response.customer, user: response?.user?.user }]);
          userDetails = response.user;
        }
      }

      const { isCustomerPriceGroup } = await fetchCustomerPriceGroup(id, initial);

      if (selectedUser) {
        const userAccount = await get_account_details_by_userid_type(
          selectedUser.userId,
          AccountType.USER
        );

        setIsAccountDisabled(userAccount?.isArchived);
        if (userAccount?.isArchived) {
          message.error({
            content: (
              <span>
                Unable to update a sale order of this customer. <strong>Reason:</strong>{' '}
                <span className="text-red-500">Account Archived</span>
              </span>
            ),
            duration: 5
          });
        }
      }

      if (!isCustomerPriceGroup) {
        if (!initial && isPrevCustomerPriceGroup) {
          const locationId = form.getFieldValue('locationId');
          if (locationId) {
            const pricegroups = await fetchPriceGroupViaLocation(locationId);
            await syncPriceFromNormalPriceGroup(pricegroups);
          }
        }

        await getAllDiscounts();
      }

      const scoreDetails = JSON.parse(selectedUser.scoreCard);
      if (scoreDetails) {
        const diff = scoreDetails?.numberOfSales - scoreDetails?.numberOfPayments;
        const limitdiff = find_invoice_due_limit();
        if (diff > limitdiff) {
          setErrorMessage(
            `This customer has pending payments for ${diff} sales greater than limit ${limitdiff}.`
          );
          setModalOpenForInvoiceDueLimit(true);
        }
      }
      if (!userDetails) {
        userDetails = await get_user_details(selectedUser.userId);
      }

      if (initial) searchUser('', id);
      setIsRouteSelected(true);

      setAddress(userDetails.addresses);
      form.setFieldValue('addressId', userDetails.addresses[0].id);
      setTax(userDetails.tax_informations);
      form.setFieldValue('taxId', userDetails.tax_informations[0].id);
      setScoreBoardData({ userId: id, allData: JSON.parse(selectedUser.scoreCard) });
      setIsPrevCustomerPriceGroup(isCustomerPriceGroup);
    } catch (error) {
      getErrorMessage(error, true);
    } finally {
      setIsloading(false);
    }
  };

  async function fetchCustomerPriceGroup(customerId: number, isInitial = false) {
    let currentPriceGroups: ICustomerPriceGroups[] = [];
    if (!customerId) {
      form.setFieldValue('isCustomerPriceGroup', false);
      form.setFieldValue('globalPriceGroupId', undefined);
      return { isCustomerPriceGroup: false, currentPriceGroups };
    }

    // Check Cache for customer price groups
    const cacheCustomerPriceGroups = priceGroupCustomerId[customerId];
    if (cacheCustomerPriceGroups) {
      currentPriceGroups = [...cacheCustomerPriceGroups];
    } else {
      const customerPriceGroups = await get_customer_price_groups_by_customerId(customerId);
      setPriceGroupCustomerId((prev: any) => ({ ...prev, [customerId]: customerPriceGroups }));
      currentPriceGroups = [...customerPriceGroups];
    }

    form.setFieldValue('isCustomerPriceGroup', currentPriceGroups.length !== 0);
    if (currentPriceGroups.length === 0) {
      form.setFieldValue('globalPriceGroupId', undefined);
      return { isCustomerPriceGroup: false, currentPriceGroups };
    }

    setpricegroupDropdown(currentPriceGroups);
    form.setFieldValue('isCustomerPriceGroup', true);
    if (!isInitial && !isPrevCustomerPriceGroup) {
      form.setFieldValue('globalPriceGroupId', currentPriceGroups[0].id);
      onGlobalPriceGroupChange(currentPriceGroups[0].id);
    }

    return { isCustomerPriceGroup: true, currentPriceGroups };
  }

  const getAllDiscounts = async () => {
    const lines = form.getFieldValue(['lines']);

    const customerId = form.getFieldValue(['userId']);
    const currentLocationId = form.getFieldValue(['locationId']);
    if (!currentLocationId) {
      message.error('Please select locationId.');
      return;
    }
    if (currentLocationId) {
      const linesWithQuantity = lines
        .filter((val: any) => val?.productId)
        .map((line: any) => {
          return { ...line, quantity: line.quantity || 0 };
        });

      if (linesWithQuantity.length > 0) {
        const topOffer = await get_sell_discount(currentLocationId, customerId, linesWithQuantity);

        if (topOffer) {
          for (let i = 0; i < lines.length; i++) {
            if (lines[i].productId) {
              const discPer = topOffer.find(
                (val) => val.productId === lines[i].productId
              )?.discountPercent;

              form.setFieldValue(['lines', i, 'discountPer'], discPer);
              onUnitandQuantityChange(i);
            }
          }
        } else {
          message.error('Offer not found.');
        }
      }
    } else {
      message.error('Location not found.');
    }
  };

  const onFinishInitial = async (values: IEditSellOrder, finalize = false) => {
    setIsloading(true);
    const userId = values.userId;
    const routeId = values.routeId;
    const grandTotal = values.grandtotal;
    delete values.userId;
    delete values.routeId;
    delete values.grandtotal;
    values.cratesSent = 0;
    values.date = JSON.stringify(new Date()).slice(1, -1);
    // values.createdBy = 0;
    values.id = parseInt(id as string);
    try {
      // validation
      await form.validateFields();
      if (values.lines.length == 0)
        throw {
          name: 'LinesError',
          message: 'Please select atleast one Product.'
        };

      if (productCategory !== null) {
        if (typeof productCategory === 'number') {
          values.categoryId = productCategory;
        } else {
          const defaultCategory = find_default_product_category();
          if (defaultCategory === null) {
            throw {
              name: 'CategoryError',
              message: 'Please select a default Product Category from preferences.'
            };
          } else {
            values.categoryId = defaultCategory;
          }
        }
      } else {
        throw {
          name: 'CategoryError',
          message: 'Please select a Product Category.'
        };
      }

      for (let ind = 0; ind < values.lines.length; ind++) {
        if (finalize) {
          const filteredLot = totalLots.find((value: any) => value.id == values.lines[ind].lotId);
          const unitId = values.lines[ind].unitId;
          const findOne: any = allLocalUnits.find((curr: any) => curr.id === unitId);
          if (filteredLot) {
            if (
              filteredLot.qtyAvailable <
              values.lines[ind].quantity * findOne.baseUnitMultiplier
            ) {
              form.setFields([
                {
                  name: ['lines', ind, 'quantity'],
                  errors: [
                    `available quantity: ${filteredLot.qtyAvailable / findOne.baseUnitMultiplier} ${
                      findOne.shortName
                    }`
                  ]
                }
              ]);
              throw {
                name: 'QtyError',
                message: `The selected product number ${ind + 1}. ${
                  values.lines[ind].productName
                }'s lot has quantity: ${
                  filteredLot.qtyAvailable / findOne.baseUnitMultiplier
                }. Cannot send ${values.lines[ind].quantity}.`
              };
            }
          } else {
            throw {
              name: 'QtyError',
              message: 'Lot is not selected!.'
            };
          }
        }
        delete values.lines[ind].priceGroupId;
        delete values.lines[ind].sellingPrice;
        delete values.lines[ind].total;
        delete values.lines[ind].productName;
        if (values.lines[ind].lotId == null) values.lines[ind].lotId = 0;
      }

      setCreateValues(values);
      if (await check_discount()) {
        if (find_sell_confirmation()) {
          setIsloading(false);
          const modalValues = {
            ...values,
            userId: userId,
            routeId: routeId,
            grandTotal: grandTotal,
            finalize: finalize
          };
          // console.log('modalValues', modalValues);
          confirmationPopup(modalValues);
        } else {
          await editSellOrderMutation.mutateAsync(values, {
            onSuccess: async ({ data }: { data: any }) => {
              if (data) {
                message.success('Sell order edited successfully from sell order.');
                if (finalize) {
                  await finalizeSell();
                } else {
                  navigate('/sell/order');
                }
              }
            },
            onError: (e: any) => {
              setIsloading(false);
            }
          });
        }
      } else {
        setIsloading(false);
        await getAllDiscounts();
        CustomErrorModal({ message: 'Discount was updated. Please click submit again!' });
      }
    } catch (errors: any) {
      setIsloading(false);
      if (isAxiosError(errors)) {
        ErrorModalWithLog({ message: getErrorMessage(errors), axiosError: errors });
        return;
      }
      if ('name' in errors) message.error(errors.message);
      else message.error('Empty fields found!');
    }
  };

  const onFinish = useDebounceFunction(onFinishInitial);

  const check_discount = async () => {
    const lines = form.getFieldValue(['lines']);

    const customerId = form.getFieldValue(['userId']);
    const currentLocationId = form.getFieldValue(['locationId']);
    if (!currentLocationId) {
      message.error('Please select locationId.');
      return;
    }

    if (!customerId) {
      message.error('Please select customer.');
      return;
    }

    // Bypass Discount Check
    const isCustomerPriceGroup = form.getFieldsValue(['isCustomerPriceGroup']);
    if (isCustomerPriceGroup) return true;

    if (currentLocationId) {
      const linesWithQuantity = lines
        .filter((val: any) => val?.productId)
        .map((line: any) => {
          return { ...line, quantity: line.quantity || 0 };
        });
      if (linesWithQuantity.length > 0) {
        const topOffer = await get_sell_discount(currentLocationId, customerId, linesWithQuantity);

        if (topOffer) {
          for (let i = 0; i < lines.length; i++) {
            if (lines[i].productId) {
              const discPer = topOffer.find(
                (val) => val.productId === lines[i].productId
              )?.discountPercent;
              const currDiscPer = form.getFieldValue(['lines', i, 'discountPer']);
              if (discPer !== currDiscPer) {
                return false;
              }
            }
          }
        }
      }
    }
    return true;
  };

  const confirmationPopup = (values: ISellConfirmationData) => {
    //console.log(values);
    setConfirmModalValues(values);
    setModalOpenForConfirmation(true);
  };

  const handleConfirmationModalSubmit = async (finalize = false) => {
    setIsloading(true);
    if (createValues) {
      await editSellOrderMutation.mutateAsync(createValues, {
        onSuccess: async ({ data }: { data: any }) => {
          if (data) {
            message.success('Sell order edited successfully from sell order.');
            if (finalize) {
              await finalizeSell();
            } else {
              navigate('/sell/order');
            }
          }
        },
        onError: (e: any) => {
          setIsloading(false);
          ErrorModalWithLog({ message: getErrorMessage(e), axiosError: e });
        }
      });
    }
  };

  const onLocationChange = async (value: number) => {
    try {
      setIsloading(true);
      form.setFieldValue('globalPriceGroupId', null);
      form.setFieldValue('locationId', value);

      const customerId = form.getFieldValue(['userId']);
      const { isCustomerPriceGroup } = await fetchCustomerPriceGroup(customerId);

      const mySet = new Set<number>();
      const data = form.getFieldValue(['lines']);
      if (data) {
        data.map((curr: any, ind: number) => {
          mySet.add(curr.productId);
          form.setFieldValue(['lines', ind, 'lotId'], undefined);
        });
      }
      if (value) {
        // Find Discount
        if (!isCustomerPriceGroup) await getAllDiscounts();
        let grandTotal = 0;
        for (let i = 0; i < data.length; i++) {
          grandTotal += data[i].total + data[i].vat;
        }
        if (!manualShipping) {
          await calculateShipping(grandTotal);
        }
      }
      fetchLotsOnLocationandProductChange(Array.from(mySet), value, 'location');
      // if it is customer price group
      if (isCustomerPriceGroup) return;
      const currPriceGroups: any = await fetchPriceGroupViaLocation(value);
      await syncPriceFromNormalPriceGroup(currPriceGroups);
    } catch (error) {
      console.log(error);
    } finally {
      setIsloading(false);
    }
  };

  async function syncPriceFromNormalPriceGroup(currPriceGroups: any) {
    const data = form.getFieldValue(['lines']);
    if (data.length == 0) {
      return;
    }
    const foundArray = new Array(data.length).fill(false);
    // console.log('current price groups', currPriceGroups);
    if (currPriceGroups.length == 0) {
      for (let lineindex = 0; lineindex < data.length; lineindex++) {
        form.setFieldValue(['lines', lineindex, 'unitPrice'], 0);
        form.setFieldValue(['lines', lineindex, 'priceGroupId'], null);
      }
    }

    for (let ind = 0; ind < currPriceGroups.length; ind++) {
      let currDefaultPrice: any = [];
      if (pricegroupsgroupId[currPriceGroups[ind].id]) {
        currDefaultPrice = pricegroupsgroupId[currPriceGroups[ind].id];
      } else {
        const response2 = await prices_by_groupId(currPriceGroups[ind].id);
        setpricegroupsId((prev: any) => ({
          ...prev,
          [currPriceGroups[ind].id]: response2.data
        }));
        currDefaultPrice = response2.data;
      }

      for (let lineindex = 0; lineindex < data.length; lineindex++) {
        if (!foundArray[lineindex]) {
          const selectedProductId = data[lineindex].productId;
          const selectedUnitId = data[lineindex].unitId;
          const findOne = currDefaultPrice.find(
            (curr: any) => curr.productId == selectedProductId && curr.unitId == selectedUnitId
          );
          if (findOne) {
            form.setFieldValue(['lines', lineindex, 'unitPrice'], findOne.sellingPrice);
            form.setFieldValue(['lines', lineindex, 'priceGroupId'], findOne.priceGroupId);
            foundArray[lineindex] = true;
          } else {
            form.setFieldValue(['lines', lineindex, 'unitPrice'], 0);
            form.setFieldValue(['lines', lineindex, 'priceGroupId'], null);
          }
        }
      }

      const checkallisfound = foundArray.find((curr: any) => !curr);
      if (checkallisfound == undefined) {
        break;
      }
    }
  }

  async function fetchPriceGroupViaLocation(locationId: number) {
    const cachePriceGroup = pricegrouplocationId[locationId];
    if (cachePriceGroup) {
      setpricegroupDropdown(cachePriceGroup);
      return cachePriceGroup;
    }

    const response = await get_price_groups_by_location(locationId);
    let defaultpricegroup = [...defaultPriceGroup];
    if (defaultpricegroup.length == 0) {
      const response2 = await get_price_groups_by_location('');
      setdefaultPriceGroup(response2.data);
      defaultpricegroup = [...response2.data];
    }

    const serverPriceGroup = [...response.data, ...defaultpricegroup];
    setpricegroupDropdown(serverPriceGroup);
    setpricegrouplocationId((prev: any) => ({ ...prev, [locationId]: serverPriceGroup }));
    return serverPriceGroup;
  }

  const FilterUnits = (name: any) => {
    const checkCurrentProduct = form.getFieldValue(['lines', name, 'productId']);
    if (checkCurrentProduct && productList.length > 0) {
      // console.log('Product List-> ', productList);
      const selectedProduct = productList.find((val: any) => val.id == checkCurrentProduct);
      // console.log('selectedProduct-> ', selectedProduct);
      if (selectedProduct && 'productUnits' in selectedProduct) {
        const filteredUnits = selectedProduct.productUnits.map((value: any) => {
          const data = allLocalUnits.find((val: any) => value.unitId == val.id);
          return data;
        });
        return (
          <>
            {filteredUnits.map((value: any) => {
              if (!value) return null;
              return (
                <Option value={value.id} key={value.id}>
                  {`${value.name}`}
                </Option>
              );
            })}
          </>
        );
      }
      return (
        <>
          {selectedProduct?.units?.map((value: any) => (
            <Option value={value.id} key={value.id}>
              {`${value.name}`}
            </Option>
          ))}
        </>
      );
    }
  };

  function onLotChange(name: number, value: number) {
    const productId = form.getFieldValue(['lines', name, 'productId']);
    if (!productId) {
      return message.error('Failed to get product id');
    }

    let filteredLots: any = [];
    if (totalLots.length !== 0) {
      filteredLots = totalLots.filter((currLot: any) => currLot.productId === productId);
    }

    const currentSelectedLot = filteredLots.find((currLot: any) => currLot.id === value);
    if (!currentSelectedLot) {
      return message.error('Failed to assign hs code based on selected lot');
    }

    form.setFieldValue(['lines', name, 'hsCode'], currentSelectedLot?.hsCode);
  }

  const FilterLot = (name: number) => {
    // console.log('This is called..');
    const checkCurrentProduct = form.getFieldValue(['lines', name, 'productId']);
    const currentLocation = form.getFieldValue(['locationId']);
    if (checkCurrentProduct && currentLocation) {
      let filteredLots: any = [];
      if (totalLots.length !== 0) {
        filteredLots = totalLots.filter(
          (currLot: any) => currLot.productId === checkCurrentProduct
        );
      }
      const unitId = form.getFieldValue(['lines', name, 'unitId']);
      const unitInfo: any = allLocalUnits.find((val: any) => unitId == val.id);
      return (
        <>
          {filteredLots?.map((value: any) => (
            <Option
              value={value.id}
              key={value.id}
              style={{ color: value.qtyAvailable > 0 ? 'green' : 'red' }}>
              {`(${numberDecimalFormatter(
                value.qtyAvailable / (unitInfo?.baseUnitMultiplier || 1)
              )} ${unitInfo?.shortName}) ${value.lotNumber} Grade-${value.grade} Expiry-${
                value?.expirationDate ? new Date(value.expirationDate).toLocaleDateString() : 'N/A'
              }`}
            </Option>
          ))}
        </>
      );
    }
  };
  const onProductChange = async (value: number) => {
    const data = form.getFieldValue(['lines']);
    const currentLocation = form.getFieldValue(['locationId']);
    await fetchLotsOnLocationandProductChange([value], currentLocation, 'productchange');
    if (productSearch) {
      const selectedProduct = productSearch.find((val) => val.id == value);
      // const selectedProduct = productList.data.results.find((val: IProductType) => val.id == value);
      const unitIdList: IProductUnits[] = selectedProduct.productUnits;
      const existingProduct = data.find((valueUnitTest: any) => valueUnitTest.productId == value);
      // console.log(existingProduct, data, selectedProduct);
      if (existingProduct && existingProduct.unitId) {
        form.setFieldValue(['lines', data.length - 1, 'unitId'], existingProduct.unitId);
      } else {
        const defaultUnit = unitIdList.find((currUnit: any) => currUnit.isDefault === true);
        form.setFieldValue(['lines', data.length - 1, 'unitId'], defaultUnit?.unitId);
      }
      let fetch = false;
      for (const value of unitIdList) {
        let unitData;
        try {
          unitData = await UnitsDB.getUnit(value.unitId);
        } catch (e) {
          console.log(e);
        }
        if (!unitData) {
          const response = await get_units_list();
          await UnitsDB.addUnits(response);
          fetch = true;
        }
      }
      if (fetch) setAllLocalUnits((await UnitsDB.getAllUnits()) as IUnits[]);
      if (!form.getFieldValue('offerId')) {
        const wholeDiscountOffer = offerList.find((curr: any) => curr.wholeDiscount != null);
        if (wholeDiscountOffer) {
          form.setFieldValue('offerId', wholeDiscountOffer.id);
        } else {
          for (let ind = 0; ind < offerList.length; ind++) {
            if (offerList[ind].productDiscount) {
              const productDiscountArray = JSON.parse(offerList[ind].productDiscount);
              const findProduct = productDiscountArray.find(
                (currPro: any) => currPro.productId == selectedProduct.id
              );
              if (findProduct) {
                form.setFieldValue('offerId', offerList[ind].id);
                break;
              } else {
                continue;
              }
            }
          }
        }
      }
    }
    const selectedUnitId = form.getFieldValue(['lines', data.length - 1, 'unitId']);
    const selectedProductId = form.getFieldValue(['lines', data.length - 1, 'productId']);
    const currentLocationId = form.getFieldValue(['locationId']);
    const isCustomerPriceGroup = form.getFieldValue(['isCustomerPriceGroup']);

    if (!currentLocationId) {
      message.error('Please select locationId.');
      return;
    }

    if (!isCustomerPriceGroup) {
      // Find Discount
      const customerId = form.getFieldValue(['userId']);
      if (currentLocationId && selectedProductId && customerId) {
        const lines = form.getFieldValue(['lines']);
        const linesWithQuantity = lines.map((line: any) => {
          return { ...line, quantity: line.quantity || 0 };
        });

        const topOffer = await get_sell_discount(currentLocationId, customerId, linesWithQuantity);

        if (topOffer) {
          const discount = topOffer[0].discountPercent;
          form.setFieldValue(['lines', data.length - 1, 'discountPer'], discount);
        } else {
          message.error('Offer not found.');
        }
      } else {
        message.error('Location not found.');
      }
    }

    const globalPriceGroupId = form.getFieldValue(['globalPriceGroupId']);
    if (globalPriceGroupId) {
      onGlobalPriceGroupChange(globalPriceGroupId);
      return;
    }

    for (let ind = 0; ind < pricegroupDropdown.length; ind++) {
      let currDefaultPrice: any = [];
      if (pricegroupsgroupId[pricegroupDropdown[ind].id]) {
        currDefaultPrice = pricegroupsgroupId[pricegroupDropdown[ind].id];
      } else {
        const response2 = await prices_by_groupId(pricegroupDropdown[ind].id);
        setpricegroupsId((prev: any) => ({
          ...prev,
          [pricegroupDropdown[ind].id]: response2.data
        }));
        currDefaultPrice = response2.data;
      }
      const findOne = currDefaultPrice.find(
        (curr: any) => curr.productId == selectedProductId && curr.unitId == selectedUnitId
      );
      if (findOne) {
        form.setFieldValue(['lines', data.length - 1, 'unitPrice'], findOne.sellingPrice);
        form.setFieldValue(['lines', data.length - 1, 'priceGroupId'], findOne.priceGroupId);
        break;
      } else {
        form.setFieldValue(['lines', data.length - 1, 'unitPrice'], 0);
        form.setFieldValue(['lines', data.legth - 1, 'priceGroupId'], null);
      }
    }
  };

  async function pricesByPriceGroupId(priceGroupId: number) {
    const isCustomerPriceGroup = form.getFieldValue(['isCustomerPriceGroup']);
    if (isCustomerPriceGroup) {
      if (customerPriceGroupsGroupId[priceGroupId]) {
        return customerPriceGroupsGroupId[priceGroupId];
      }

      const data = await get_current_price_By_priceGroupId(priceGroupId);
      setCustomerPriceGroupsGroupId((prev: any) => ({ ...prev, [priceGroupId]: data }));
      return data;
    }

    if (pricegroupsgroupId[priceGroupId]) {
      return pricegroupsgroupId[priceGroupId];
    }

    const response2 = await prices_by_groupId(priceGroupId);
    setpricegroupsId((prev: any) => ({ ...prev, [priceGroupId]: response2.data }));
    return response2.data;
  }

  async function onGlobalPriceGroupChange(globalPriceGroupId: number) {
    if (!globalPriceGroupId) return;
    const currDefaultPrice = await pricesByPriceGroupId(globalPriceGroupId);
    const lines = form.getFieldValue(['lines']) || [];
    const isCustomerPriceGroup = form.getFieldValue(['isCustomerPriceGroup']);

    for (let i = 0; i < lines.length; i++) {
      const line = lines[i];
      const findOne = currDefaultPrice.find(
        (curr: any) => curr.productId == line.productId && curr.unitId == line.unitId
      );

      form.setFieldValue(['lines', i, 'priceGroupId'], globalPriceGroupId);
      form.setFieldValue(['lines', i, 'unitPrice'], findOne?.sellingPrice || 0);

      // Reset Discount
      if (isCustomerPriceGroup) {
        form.setFieldValue(['lines', i, 'discountPer'], 0);
      }

      onUnitandQuantityChange(i);
    }
  }

  const searchProduct = async (value: any) => {
    if (value != '') {
      let response;
      try {
        response = await get_product_list(0, 5, value);
      } catch (e) {
        console.log(e);
      }
      if (!response || response.data.results.length == 0) {
        message.error('Cannot find any product with that name!');
        setProductSearch([]);
      } else {
        setProductSearch(response.data.results);
        checkProductAndAdd(response.data.results);
      }
    }
    // setSearchLoading(false);
  };
  const options = productSearch.map((d) => (
    <Option key={d.id} value={d.id}>
      {d.name}
    </Option>
  ));
  const checkProductAndAdd = (products: any[]) => {
    if (productList.length > 0) {
      products = products.filter((value) => {
        const searchProduct = productSearch.find((val) => val.id == value.id);
        if (searchProduct) return false;
        return true;
      });
      if (products.length > 0)
        setProductList((prevValue) => {
          return [...prevValue, ...products];
        });
    } else {
      setProductList(products);
    }
  };
  useEffect(() => {
    initializeUnits();
  }, []);

  const printInvoice = async (data: any) => {
    const response: any = await get_sell_details(parseInt(data.id as string));
    // console.log('printInvoice', response);
    const invoiceresponse = await get_invoices_list();
    // setReturnList(response.returns);
    // setPayList(response.payments);
    let firstCreatedByUserDetails: any;
    if (firstCreatedByUser) {
      firstCreatedByUserDetails = await UsersDB.getUser(firstCreatedByUser);
      if (!firstCreatedByUserDetails) {
        firstCreatedByUserDetails = (await get_user_details(firstCreatedByUser)).user;
        if (firstCreatedByUserDetails) UsersDB.addUsers(firstCreatedByUserDetails);
      }
    }
    let createdByDetails: any;
    if (response.createdBy) {
      createdByDetails = await UsersDB.getUser(response.createdBy);
      if (!createdByDetails) {
        createdByDetails = (await get_user_details(response.createdBy)).user;
      }
    }
    // setcreatedUser(createdByDetails);
    const linesResponse = await get_sell_lines_details(parseInt(data.id as string));
    const userData: any = await get_customer_details(response.customerId);
    // console.log('UUU', response.address.userId);

    // console.log('userData', userData);
    // setVendorDetails(userData.user);
    // if (!userData.user) setVendorDetails(userData);
    let locationDetails: any = await LocationsDB.getLocation(response.locationId);
    if (!locationDetails) {
      locationDetails = await get_location_details(response.locationId);
      await LocationsDB.addLocations([locationDetails]);
    }
    // setLocationDetails(locationDetailss);
    let totalQuantity: any = 0;
    let shortName: any = '';
    if (linesResponse.data.length > 0) {
      const searchProducts: any = {};
      for (let index = 0; index < linesResponse.data.length; index++) {
        const product: any = await ProductsDB.getProduct(linesResponse.data[index].productId);
        if (!product) {
          // product = await get_product_details(linesResponse.lines[index].productId);
          // await ProductsDB.addProducts([product]);
          if (linesResponse.data[index].productId in searchProducts) {
            searchProducts[linesResponse.data[index].productId] = [
              ...searchProducts[linesResponse.data[index].productId],
              index
            ];
          } else {
            searchProducts[linesResponse.data[index].productId] = [index];
          }
        } else {
          linesResponse.data[index].productName = product.name;
        }

        let findUnit: any = await UnitsDB.getUnit(linesResponse.data[index].unitId);
        if (!findUnit) {
          const allUnits = await get_units_list();
          await UnitsDB.addUnits(allUnits);
          findUnit = await UnitsDB.getUnit(linesResponse.data[index].unitId);
        }
        // linesResponse.lines[index].unitName = findUnit.name;
        // linesResponse.data[index].quantity = `${linesResponse.data[index].quantity}`;
        linesResponse.data[index].shortName = findUnit.shortName;
        shortName = findUnit.shortName;
        totalQuantity += linesResponse.data[index].quantity;
      }
      const searchProductslength = Object.entries(searchProducts).length;
      if (searchProductslength > 0) {
        const productsresponse = await get_product_list_ids([...Object.keys(searchProducts)]);
        for (const key in searchProducts) {
          const findproduct = productsresponse?.data?.results.find(
            (currProduct: any) => currProduct.id == key
          );
          for (let i = 0; i < searchProducts[key].length; i++) {
            linesResponse.data[searchProducts[key][i]].productName = findproduct?.name;
          }
          await ProductsDB.addProducts([findproduct]);
        }
      }
      // setLines(linesResponse.data);
      // console.log(linesResponse.data);
      // setIsloading(false);
      // console.log(allProducts);
      // setProductList(allProducts);
    }
    // setpurchaseDetails({ ...response, totalQuantity: `${totalQuantity} ${shortName}` });
    // console.log(userData, response);

    const routeId: number = response?.address?.routeId as number;
    let routeDetails: any;
    if (routeId) {
      routeDetails = await RoutesDB.getRoute(routeId);
      if (!routeDetails) {
        const response = await get_routes_list();
        await RoutesDB.addRoutes(response?.data?.results);
        routeDetails = await RoutesDB.getRoute(routeId);
      }
    } else {
      routeDetails = {
        name: ''
      };
    }

    const sendUserData = {
      ...userData.user.user,
      routeName: routeDetails.name
    };
    localStorage.setItem(
      'referrer',
      JSON.stringify({
        purchaseDetails: { ...response, totalQuantity: `${totalQuantity} ${shortName}` },
        locationDetails,
        customerDetails: sendUserData,
        lines: linesResponse.data ? linesResponse.data : [],
        invoiceLayouts: invoiceresponse?.data[0] ? invoiceresponse?.data[0] : undefined,
        createdByDetails,
        firstCreatedByUserDetails
      })
    );
    window.open('/ERP-UI#/invoice-sell', '_blank', 'noopener,noreferrer');

    // console.log('linesResponse-->', linesResponse.data);s
    // console.log('Response--->', response.data);
    setIsloading(false);
    //navigate('/sell');
  };

  const checkAccountRule = async (locationId: number) => {
    if (
      (await checkHasAccountRule(locationId, AccountRulesEvent.SELL_CREATE)) &&
      (await checkHasAccountRule(locationId, AccountRulesEvent.VAT_CREATE))
    ) {
      return true;
    } else {
      CustomInfoModal({
        title: 'Info',
        message: `"${AccountRulesEvent.SELL_CREATE}" or "${AccountRulesEvent.VAT_CREATE}" rule has not been created!`
      });
      return false;
    }
  };

  const finalizeSell = async () => {
    setIsloading(true);
    try {
      //validation
      const values = await form.validateFields();

      if (values.lines.length == 0)
        throw {
          name: 'LinesError',
          message: 'Please select atleast one Product.'
        };

      for (let i = 0; i < values.lines.length; i++) {
        const unitId = values.lines[i].unitId;
        const findOne: any = allLocalUnits.find((curr: any) => curr.id === unitId);
        if (!values.lines[i].lotId) {
          // console.log('this is called.');
          const defaultGrade = find_sell_default_grade();
          // console.log('default grade', defaultGrade);
          const lotsWithDefaultGrade = totalLots.filter(
            (curr: any) => curr.grade == defaultGrade && values.lines[i].productId == curr.productId
          );
          // console.log('lotswithDefaultgrade', lotsWithDefaultGrade);
          lotsWithDefaultGrade.sort((a: any, b: any) => b.qtyAvailable - a.qtyAvailable);
          if (lotsWithDefaultGrade.length == 0) {
            throw {
              name: 'Lines Error',
              message: `Location you selected have not any lots with your grade for product ${values.lines[i].productName}.`
            };
          }
          if (
            lotsWithDefaultGrade[0].qtyAvailable / findOne.baseUnitMultiplier <
            values.lines[i].quantity
          ) {
            throw {
              name: 'Lines Error',
              message: `Location you selected have not any lots with your quantity for product ${
                values.lines[i].productName
              }.Highest quantity is ${
                lotsWithDefaultGrade[0].qtyAvailable / findOne.baseUnitMultiplier
              } ${findOne.shortName}`
            };
          }
          values.lines[i].lotId = lotsWithDefaultGrade[0].id;
        } else {
          const filteredLot = totalLots.find((value: any) => value.id == values.lines[i].lotId);

          if (filteredLot.qtyAvailable / findOne.baseUnitMultiplier < values.lines[i].quantity) {
            form.setFields([
              {
                name: ['lines', i, 'quantity'],
                errors: [
                  `available quantity: ${filteredLot.qtyAvailable / findOne.baseUnitMultiplier}`
                ]
              }
            ]);
            throw {
              name: 'QtyError',
              message: `The selected product number ${i + 1}. ${
                values.lines[i].productName
              }'s lot has quantity: ${
                filteredLot.qtyAvailable / findOne.baseUnitMultiplier
              }. Cannot send ${values.lines[i].quantity}.`
            };
          }
        }
      }

      if (productCategory !== null) {
        if (typeof productCategory === 'number') {
          values.categoryId = productCategory;
        } else {
          const defaultCategory = find_default_product_category();
          if (defaultCategory === null) {
            throw {
              name: 'CategoryError',
              message: 'Please select a default Product Category from preferences.'
            };
          } else {
            values.categoryId = defaultCategory;
          }
        }
      } else {
        throw {
          name: 'CategoryError',
          message: 'Please select a Product Category.'
        };
      }

      //send sell
      if (id) values.sellOrderId = parseInt(id);
      values.date = JSON.stringify(new Date()).slice(1, -1);
      let checkErr = true;

      const { lines, locationId, isCustomerPriceGroup } = values;
      if (!isCustomerPriceGroup) {
        const response = await get_sell_discount(locationId, Number(selectValue), lines);

        const changedLines = [];

        for (const data of response) {
          const lineIndex = lines.findIndex((line: any) => line.productId === data.productId);
          const requestedDiscount = data.discount === 0 ? 0 : parseFloat(data.discount.toFixed(2));

          if (lineIndex !== -1) {
            const line = lines[lineIndex];
            const isDiscountSame = line.discount === requestedDiscount;
            if (!isDiscountSame) {
              // Replace the discount with the new one in the form values
              form.setFieldValue(['lines', lineIndex, 'discountPer'], data.discountPercent);
              onUnitandQuantityChange(lineIndex);
              changedLines.push(line.productName);
            }
          }
        }

        if (changedLines.length > 0) {
          CustomErrorModal({
            message: `Discount for ${changedLines.join(', ')} was updated. Please submit again!`
          });

          setIsloading(false);
          setModalOpenForConfirmation(false);
          return;
        }
      }

      values.discount = 0;
      await createSellMutation.mutateAsync(values, {
        onSuccess: async ({ data }: { data: any }) => {
          message.success('Sell finalized successfully');
          checkErr = false;
          navigate(`/sell/${data.id}`);
        },
        onError: async (e: any) => {
          setModalOpenForConfirmation(false);
          setIsloading(false);
        }
      });

      return checkErr;
    } catch (errors: any) {
      if (isAxiosError(errors)) {
        ErrorModalWithLog({ message: getErrorMessage(errors), axiosError: errors });
      } else {
        CustomErrorModal({
          title: 'Error! Cannot finalize Sell',
          message: errors.message
        });
      }
      setIsloading(false);
      return true;
    }
  };
  const initializeUnits = async () => {
    // console.log('allUnits', await UnitsDB.getAllUnits());
    setAllLocalUnits((await UnitsDB.getAllUnits()) as IUnits[]);
  };

  const calculateShipping = async (grandTotal: number) => {
    if (grandTotal > find_zero_total_shipping()) {
      form.setFieldValue(['shipping'], 0);
      calculateShippingWithTax(0);
    } else {
      const locationId = form.getFieldValue(['locationId']);
      if (!locationId) {
        message.error('Please select location!');
      } else {
        const shippingCost = await find_shipping_cost_from_location(locationId);
        form.setFieldValue(['shipping'], shippingCost);
        calculateShippingWithTax(shippingCost);
      }
    }
  };

  const calculateShippingWithTax = async (val: number | null) => {
    if (val !== null) {
      const shippingTax = val * (13 / 100);
      const shippingWithTax = (val + shippingTax).toFixed(2);
      form.setFieldValue(['shippingWithTax'], shippingWithTax);
    }
  };

  const onUnitandQuantityChange = async (name: number) => {
    // console.log('name', name);
    // console.log('number', value);
    const rate = form.getFieldValue(['lines', name, 'unitPrice']);
    const qty = form.getFieldValue(['lines', name, 'quantity']);
    const mis = form.getFieldValue(['lines', name, 'misc']);

    let total = rate * qty + mis;
    const productId = form.getFieldValue(['lines', name, 'productId']);

    // For Discount
    const discountPer = form.getFieldValue(['lines', name, 'discountPer']);
    const discount = parseFloat(((total * discountPer) / 100).toFixed(2));
    total = parseFloat((total - discount).toFixed(2));

    form.setFieldValue(['lines', name, 'discount'], discount);

    let vat = 0;
    let productDetail = await ProductsDB.getProduct(productId);
    if (!productDetail) {
      const allProducts = await get_product_list();
      await ProductsDB.addProducts(allProducts.data.results);
      productDetail = await ProductsDB.getProduct(productId);
    }
    if (typeof productDetail === 'object' && productDetail.vat) {
      vat = total * (productDetail.vat / 100);
    }
    form.setFieldValue(['lines', name, 'vat'], vat);
    form.setFieldValue(['lines', name, 'total'], total);

    const lines = form.getFieldValue(['lines']);
    // console.log('lines', lines);
    let grandTotal = 0;
    let discountTotal = 0;
    for (let i = 0; i < lines.length; i++) {
      grandTotal += lines[i].total + lines[i].vat;
      lines[i].discount = parseFloat(lines[i].discount);
      discountTotal += lines[i].discount;
    }
    // console.log('grandtotal', grandTotal);

    form.setFieldValue(['grandtotal'], numberDecimalFormatter(grandTotal));
    form.setFieldValue(['discount'], numberDecimalFormatter(discountTotal));
    if (!manualShipping) {
      await calculateShipping(grandTotal);
    }
  };

  // console.log('Scroe board data', scoreBoardData);

  const onRouteChange = (val: number) => {
    // console.log('value', val);
    searchUser('');

    const addresswithoRouteID = address?.find((curr: any) => curr.routeId == val);
    // console.log('Route and addresswithrouteid', addresswithoRouteID);
    if (addresswithoRouteID) {
      form.setFieldValue('addressId', addresswithoRouteID.id);
    }
  };

  const onPriceGroupChange = async (val: number) => {
    const productInfo = form.getFieldValue(['lines', val]);
    // console.log('product Info', productInfo);
    let defaultPrice: any = [];
    const locationId = form.getFieldValue(['locationId']);
    // console.log('pricegroupsgroupId', pricegroupsgroupId);
    if (locationId && productInfo.priceGroupId) {
      if (!pricegroupsgroupId[productInfo.priceGroupId]) {
        const response2 = await prices_by_groupId(productInfo.priceGroupId);
        defaultPrice = [...response2.data];
        // pricegroupsgroupId[productInfo.priceGroupId] = [...response2.data];
        setpricegroupsId({
          ...pricegroupsgroupId,
          [productInfo.priceGroupId]: response2.data
        });
      } else {
        defaultPrice = pricegroupsgroupId[productInfo.priceGroupId];
      }
    }
    const alreadySelectedUnitId = form.getFieldValue(['lines', val, 'unitId']);
    const findone = defaultPrice.find(
      (res2elem: any) =>
        res2elem.productId == productInfo.productId && res2elem.unitId == alreadySelectedUnitId
    );
    if (findone) {
      form.setFieldValue(['lines', val, 'unitPrice'], findone.sellingPrice);
    } else {
      form.setFieldValue(['lines', val, 'unitPrice'], 0);
    }
  };

  const onUnitChange = async (name: number, unitId?: any) => {
    const selectedUnitId = form.getFieldValue(['lines', name, 'unitId']);
    const selectedProductId = form.getFieldValue(['lines', name, 'productId']);

    if (unitId) {
      const allLines = form.getFieldValue(['lines']);
      // console.log(allLines, unitId);
      allLines.map((val: any, index: number) => {
        // console.log(selectedProductId, val.productId);
        if (val.productId == selectedProductId) {
          form.setFieldValue(['lines', index, 'unitId'], unitId);
        }
      });
    }
    const currentLocationId = form.getFieldValue(['locationId']);
    if (!currentLocationId) {
      message.error('Please select locationId.');
      return;
    }

    for (let ind = 0; ind < pricegroupDropdown.length; ind++) {
      let currDefaultPrice: any = [];
      if (pricegroupsgroupId[pricegroupDropdown[ind].id]) {
        currDefaultPrice = pricegroupsgroupId[pricegroupDropdown[ind].id];
      } else {
        const response2 = await prices_by_groupId(pricegroupDropdown[ind].id);
        setpricegroupsId((prev: any) => ({
          ...prev,
          [pricegroupDropdown[ind].id]: response2.data
        }));
        currDefaultPrice = response2.data;
      }
      const findOne = currDefaultPrice.find(
        (curr: any) => curr.productId == selectedProductId && curr.unitId == selectedUnitId
      );
      if (findOne) {
        form.setFieldValue(['lines', name, 'unitPrice'], findOne.sellingPrice);
        form.setFieldValue(['lines', name, 'priceGroupId'], findOne.priceGroupId);
        break;
      } else {
        form.setFieldValue(['lines', name, 'unitPrice'], 0);
        form.setFieldValue(['lines', name, 'priceGroupId'], null);
      }
    }
    onUnitandQuantityChange(name);
  };

  const searchLocationIndexDB = async (value: string) => {
    try {
      const response = await LocationsDB.searchLocationByName(value, 100);
      if (!response || response.length == 0) {
        message.info('Cannot find any location with that name in cache, searching in server...');
        searchLocation(value);
      } else {
        setLocationSearch(response);
        setIsCacheResponse(true);
      }
    } catch (error) {
      message.error(error as string);
    }
  };

  const searchLocation = async (value: any) => {
    let response;
    try {
      response = await get_location_list(0, 100, value);
    } catch (e) {
      console.log(e);
    }
    if (!response || response.data.results.length == 0) {
      message.error('Cannot find any location with that name in server!');
      setLocationSearch([]);
    } else {
      setLocationSearch(response.data.results);
      LocationsDB.addLocations(response.data.results);
    }
    setIsCacheResponse(false);
  };

  const calculateTotal = async () => {
    const lines = form.getFieldValue(['lines']);
    let grandTotal = 0;
    let discountTotal = 0;
    if (lines !== undefined) {
      for (let i = 0; i < lines.length; i++) {
        grandTotal += lines[i].total + lines[i].vat;
        lines[i].discount = parseFloat(lines[i].discount);
        discountTotal += lines[i].discount;
      }
    }
    form.setFieldValue(['grandtotal'], numberDecimalFormatter(grandTotal));
    form.setFieldValue(['discount'], numberDecimalFormatter(discountTotal));

    if (!manualShipping) {
      await calculateShipping(grandTotal);
    }
  };

  const generateInvoiceForPrint = async (record: ISellRecordData, curr: ICreateInvoiceResponse) => {
    setIsloading(true);
    const invoiceModalData = await getSellPrintData(
      record.id,
      record.locationId,
      record.sellOrderId
    );
    setIsloading(false);

    setInvoiceData({ ...invoiceModalData, invoiceLayouts: curr });

    setOpenModalForInvoicePrint(true);
  };

  const handleInvoicePrintModalClose = () => {
    setOpenModalForInvoicePrint(false);
  };

  const isProductAlreadyAdded = (index: number, checkproductId: number) => {
    const lines = form.getFieldValue(['lines']);
    const findOne = lines?.find((curr: any) => curr.productId == checkproductId);
    if (findOne) {
      return false;
    }
    return true;
  };

  return (
    <Spin spinning={isloading}>
      <CustomModal
        footer={false}
        isModalOpen={openModalForInvoicePrint}
        setIsModalOpen={setOpenModalForInvoicePrint}
        title="Sell Invoice Print">
        <SellInvoice
          sellDetails={invoiceData.sellDetails}
          customerDetails={invoiceData.customerDetails}
          lines={invoiceData.lines}
          invoiceLayouts={invoiceData.invoiceLayouts}
          handleModalClose={handleInvoicePrintModalClose}
        />
      </CustomModal>
      <CustomModal
        isModalOpen={modalOpenForInvoiceDueLimit}
        setIsModalOpen={setModalOpenForInvoiceDueLimit}
        title="Error"
        footer={false}
        width="50%"
        handleCancel={() => setModalOpenForInvoiceDueLimit(true)}>
        <div>{errormessage}</div>
        <div className="flex justify-end gap-2 mt-3">
          <Button
            // type="primary"
            style={{
              backgroundColor: '#0AA245',
              color: 'white'
            }}
            onClick={() => {
              setModalOpenForInvoiceDueLimit(false);
            }}>
            Approve
          </Button>
          <Button
            style={{ backgroundColor: 'red', color: 'white' }}
            onClick={() => {
              // navigate('/sell');
              form.setFieldValue('userId', null);
              setModalOpenForInvoiceDueLimit(false);
            }}>
            Deny
          </Button>
        </div>
      </CustomModal>
      <NotifySellPriceModal
        title={'Confirmation'}
        visible={confirm}
        onOk={async () => {
          setConfirm(false);
          if (finalizeOnNotify) {
            await onFinish(form.getFieldsValue(), true);
          } else {
            await form.submit();
          }
        }}
        onCancel={() => {
          setConfirm(false);
        }}
        data={notifyModalData}
      />
      <CustomModal
        isModalOpen={modalOpenForConfirmation}
        setIsModalOpen={setModalOpenForConfirmation}
        title={'Confirmation'}
        footer={false}>
        <ReusableSellConfirm
          data={confirmModalValues}
          isFinishLoading={isloading}
          handleSubmitClick={handleConfirmationModalSubmit}
        />
      </CustomModal>
      <AppContent
        breadcrumbItems={breadcrumbItems}
        backgroundWhite={true}
        button={<GoBackButton onClick={() => navigate('/sell/order')} />}>
        <Form
          form={form}
          onFinish={onFinish}
          disabled={isloading}
          layout="vertical"
          validateTrigger={'onChange'}
          onValuesChange={(_, allFields) => {
            setProductDetails(allFields);
          }}
          autoComplete="off">
          <Card style={{ borderRadius: '10px' }}>
            <PageHeader
              title="Sell Order Information"
              style={{
                padding: '8px 0px'
              }}
            />
            <div className={'grid cols-1 md:grid-cols-3 lg:grid-cols-4 gap-5 mb-5'}>
              {<LocationSearch onSelect={onLocationChange} notAll={true} required={true} />}
              <RouteSearch form={form} onSelect={onRouteChange} isClear={true} />

              {/* <CustomerSearch onSelect={onUserChange} /> */}
              {initalCustomer && (
                <Form.Item
                  name={['userId']}
                  label="Customer"
                  rules={[
                    {
                      required: true,
                      message: 'Please choose Customer!'
                    }
                  ]}>
                  <Select
                    showSearch
                    placeholder={'search user'}
                    defaultActiveFirstOption={false}
                    showArrow={false}
                    filterOption={false}
                    onChange={(value) => {
                      setselectValue(value);
                      onUserChange(value);
                    }}
                    dropdownMatchSelectWidth={false}
                    onSearch={(val) => {
                      setSearchValueUser(val);
                    }}
                    notFoundContent={null}
                    dropdownRender={(menu) => (
                      <>
                        {menu}
                        <Divider style={{ margin: '8px 0' }} />

                        <div className="flex flex-col" style={{ padding: '0 8px 4px' }}>
                          {isMore ? (
                            <Button
                              type="text"
                              style={{
                                color: 'blue',
                                width: '100%'
                              }}
                              onClick={() => {
                                searchMoreUser(searchValueUser);
                              }}>
                              {/* {'Sync & Search on Server'} */}
                              {'Get More...'}
                            </Button>
                          ) : (
                            <div
                              style={{
                                width: '100%',
                                textAlign: 'center'
                              }}>
                              No more data...
                            </div>
                          )}
                        </div>
                      </>
                    )}>
                    {optionsUser}
                  </Select>
                </Form.Item>
              )}

              <Form.Item name={['isCustomerPriceGroup']} valuePropName="checked" hidden>
                <Checkbox />
              </Form.Item>

              <Form.Item
                name={['globalPriceGroupId']}
                label="Price Group"
                rules={[{ required: false, message: 'Select Price Group' }]}>
                {
                  <Select
                    placeholder="Select price group"
                    dropdownMatchSelectWidth={false}
                    disabled={!form.getFieldValue(['locationId'])}
                    onChange={(value) => onGlobalPriceGroupChange(value)}
                    allowClear={!form.getFieldValue(['isCustomerPriceGroup'])}>
                    {pricegroupDropdown?.map((value: any) => (
                      <Option value={value.id} key={value.id}>
                        {value.name}
                      </Option>
                    ))}
                  </Select>
                }
              </Form.Item>
            </div>
          </Card>
          {scoreBoardData && (
            <div className="card grid mb-5 mt-5 bg-white" style={{ borderRadius: '10px' }}>
              <ScoreBoard data={scoreBoardData} />
            </div>
          )}
          <Form.List name={['lines']}>
            {(fields2, { add: add2, remove: remove2 }, { errors: errors2 }) => (
              <>
                <div>
                  <div className={'card grid mb-5 mt-5 bg-white'} style={{ borderRadius: '10px' }}>
                    <PageHeader
                      subTitle="Add Product"
                      style={{
                        padding: '8px 0px 8px 10px'
                      }}
                    />
                    <div className="grid grid-cols-2 gap-4">
                      <ProductCategorySearch
                        setValue={productCategory}
                        onProductCategoryChange={(val) => {
                          setProductCategory(val);
                          if (val !== 'default') {
                            form.setFieldValue(['lines'], []);
                          }
                        }}
                        isAll={false}
                      />
                      <ProductSearchForLines
                        add2={add2}
                        onProductChange={onProductChange}
                        productList={productList}
                        setProductList={setProductList}
                        productSearch={productSearch}
                        setProductSearch={setProductSearch}
                        autofocusRef={autofocusRef}
                        isProductAlreadyAdded={isProductAlreadyAdded}
                        index={0}
                        locationId={form.getFieldValue(['locationId'])}
                        validation={productCategory !== null ? true : false}
                        productCategory={productCategory}
                      />
                    </div>
                  </div>
                  {fields2.length > 0 && (
                    <PageHeader
                      title="All Products"
                      style={{
                        padding: '8px 0px 8px 10px'
                      }}
                    />
                  )}
                </div>
                <Card
                  style={{
                    maxHeight: '50vh',
                    overflowY: 'scroll',
                    // backgroundColor: 'gray',
                    borderRadius: '9px',
                    padding: '0px'
                  }}>
                  {fields2.map(({ key: key2, name: name2, ...restField2 }) => (
                    <div className="flex gap-1 items-center" key={name2}>
                      <span className="font-bold text-sm mb-5">{name2 + 1}.</span>
                      <div
                        key={key2}
                        className="card"
                        style={{ borderRadius: '9px', backgroundColor: 'white' }}>
                        <div
                          className={
                            'grid grid-cols-2 gap-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-5 xl:grid-cols-10'
                          }
                          key={key2}>
                          <Form.Item {...restField2} name={[name2, 'productId']} hidden></Form.Item>
                          <Form.Item {...restField2} name={[name2, 'productName']} label="Name">
                            <Input
                              type={'text'}
                              disabled
                              style={{
                                backgroundColor: 'white',
                                color: 'black',
                                // border: '0px',
                                fontWeight: 'bold'
                              }}
                            />
                          </Form.Item>
                          <Form.Item
                            {...restField2}
                            name={[name2, 'unitId']}
                            label="Unit"
                            rules={[
                              {
                                required: true,
                                message: 'Please choose a Unit!'
                              }
                            ]}>
                            {
                              <Select
                                placeholder="Select a Unit!"
                                dropdownMatchSelectWidth={false}
                                onChange={(value: number) => {
                                  onUnitChange(name2, value), onUnitandQuantityChange(name2);
                                }}
                                allowClear>
                                {FilterUnits(name2)}
                              </Select>
                            }
                          </Form.Item>
                          <Form.Item
                            {...restField2}
                            name={[name2, 'lotId']}
                            label="Lot"
                            rules={[
                              {
                                required: false,
                                message: 'Please choose a Lot!'
                              }
                            ]}>
                            {
                              <Select
                                placeholder="Select a Lot!"
                                dropdownMatchSelectWidth={false}
                                onChange={(value) => onLotChange(name2, value)}
                                allowClear>
                                {FilterLot(name2)}
                              </Select>
                            }
                          </Form.Item>
                          <ReusableQuantity
                            name={name2}
                            restField={restField2}
                            allUnits={allLocalUnits}
                            onChangeData={() => onUnitandQuantityChange(name2)}
                            onPressEnterData={(e) => {
                              if (autofocusRef.current) {
                                autofocusRef.current.focus();
                              }
                            }}
                          />
                          <Form.Item
                            {...restField2}
                            name={[name2, 'priceGroupId']}
                            label="Price Group"
                            rules={[
                              {
                                required: false,
                                message: 'Select Price Group'
                              }
                            ]}>
                            {
                              <Select
                                placeholder="Select price group"
                                dropdownMatchSelectWidth={false}
                                disabled={
                                  form.getFieldValue('globalPriceGroupId') || isCustomerPriceGroup
                                }
                                onChange={() => onPriceGroupChange(name2)}
                                allowClear>
                                {pricegroupDropdown?.map((value: any) => (
                                  <Option value={value.id} key={value.id}>
                                    {value.name}
                                  </Option>
                                ))}
                              </Select>
                            }
                          </Form.Item>
                          <Form.Item
                            {...restField2}
                            label="Rate"
                            name={[name2, 'unitPrice']}
                            rules={[
                              { required: true, message: 'Please add Rate!' },
                              () => ({
                                validator(_: any, value: any) {
                                  if (!value) {
                                    return Promise.reject(`Please input valid Rate!`);
                                  }
                                  if (value == 0) return Promise.reject(`Please Input valid Rate!`);

                                  return Promise.resolve();
                                }
                              })
                            ]}>
                            <InputNumber
                              controls={false}
                              min={0}
                              disabled={isCustomerPriceGroup}
                              onChange={() => onUnitandQuantityChange(name2)}
                            />
                          </Form.Item>
                          <Form.Item
                            {...restField2}
                            name={[name2, 'discountPer']}
                            initialValue="0"
                            hidden>
                            <InputNumber controls={false} disabled={true} />
                          </Form.Item>
                          <Form.Item
                            {...restField2}
                            label="Discount"
                            name={[name2, 'discount']}
                            rules={[{ required: true, message: 'Please add Discount!' }]}>
                            <InputNumber
                              controls={false}
                              disabled={true}
                              style={{ color: 'black' }}
                              min={0}
                              onChange={() => onUnitandQuantityChange(name2)}
                            />
                          </Form.Item>
                          <Form.Item
                            {...restField2}
                            label="Misc"
                            name={[name2, 'misc']}
                            rules={[{ required: true, message: 'Please add Misc!' }]}>
                            <InputNumber
                              controls={false}
                              min={0}
                              onChange={() => onUnitandQuantityChange(name2)}
                            />
                          </Form.Item>
                          <Form.Item {...restField2} label="VAT" name={[name2, 'vat']}>
                            <InputNumber
                              controls={false}
                              precision={2}
                              min={0}
                              disabled
                              style={{ color: 'black' }}
                            />
                          </Form.Item>
                          <Form.Item {...restField2} name={[name2, 'total']} label="Total">
                            <InputNumber
                              controls={false}
                              min={0}
                              disabled
                              style={{ color: 'black' }}
                            />
                          </Form.Item>
                          <Form.Item {...restField2} label="HS Code" name={[name2, 'hsCode']}>
                            <Input disabled />
                          </Form.Item>
                          {fields2.length > 0 ? (
                            <div className="flex items-center justify-start mt-5">
                              <CustomButton
                                backgroundColor="white"
                                text="Remove"
                                textColor="green"
                                onClick={async () => {
                                  message.info(
                                    `${form.getFieldValue([
                                      'lines',
                                      name2,
                                      'productName'
                                    ])} was removed!`
                                  );
                                  remove2(name2);
                                  await calculateTotal();
                                }}
                              />
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  ))}
                </Card>
                {/* {fields2.length > 0 ? (
                  <Card
                    style={{ borderRadius: '10px', marginBottom: '10px' }}
                    className="grid grid-cols-2">
                    <Form.Item name={['grandtotal']} label="Grand Total">
                      <InputNumber controls={false} min={0} disabled style={{ color: 'black' }} />
                    </Form.Item>
                  </Card>
                ) : null} */}
              </>
            )}
          </Form.List>
          {form.getFieldValue(['lines'])?.length > 0 ? (
            <Card
              style={{ borderRadius: '10px', marginBottom: '10px', marginTop: '10px' }}
              className="grid grid-cols-2">
              <Form.Item name={['grandtotal']} label="Grand Total (Without Shipping)">
                <InputNumber controls={false} min={0} disabled style={{ color: 'black' }} />
              </Form.Item>
            </Card>
          ) : null}
          <div
            className={
              'card grid grid-cols-2 gap-3 mb-3 md:grid-cols-3 lg:grid-cols-4 gap-2 mb-5 bg-white'
            }
            style={{ borderRadius: '10px' }}>
            <Form.Item
              name={['addressId']}
              label="Address"
              rules={[
                {
                  required: true,
                  message: 'Please choose address!'
                }
              ]}>
              <Select placeholder="Select a address!" allowClear dropdownMatchSelectWidth={false}>
                {address?.map((value: any) => {
                  return (
                    <Option value={value.id} key={value.id}>
                      {`${value.addressLine1}`}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              name={['taxId']}
              label="Tax"
              rules={[
                {
                  required: false,
                  message: 'Please choose tax number!'
                }
              ]}>
              <Select
                placeholder="Select a tax number!"
                allowClear
                dropdownMatchSelectWidth={false}>
                {tax?.map((value: any) => {
                  return (
                    <Option value={value.id} key={value.id}>
                      {`${value.taxNumber}`}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            {/* <Form.Item
              name={['offerId']}
              label="Offer"
              rules={[
                {
                  required: false,
                  message: 'Please choose offer!'
                }
              ]}>
              <Select placeholder="Select a offer!" allowClear dropdownMatchSelectWidth={false}>
                {offerList?.map((value: any) => {
                  return (
                    <Option value={value.id} key={value.id}>
                      {`${value.name}(${value.code})`}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item> */}
            <Form.Item
              label="Discount"
              name={['discount']}
              rules={[{ required: true, message: 'Please add Discount!' }]}>
              <InputNumber controls={false} disabled={true} style={{ color: 'black' }} min={0} />
            </Form.Item>
            <Form.Item
              label="Misc"
              name={['misc']}
              rules={[{ required: true, message: 'Please add misc!' }]}>
              <InputNumber controls={false} min={0} />
            </Form.Item>
            <Form.Item
              label="Shipping"
              name={['shipping']}
              rules={[{ required: true, message: 'Please add shipping!' }]}>
              <InputNumber
                controls={false}
                min={0}
                disabled={!manualShipping}
                onChange={(val) => calculateShippingWithTax(val)}
                addonAfter={
                  <Form.Item name={['isCustomShipping']} noStyle initialValue={false}>
                    <Select
                      onChange={async (val) => {
                        setManualShipping(val);
                        const grandtotal = form.getFieldValue(['grandtotal']);
                        if (grandtotal !== undefined || grandtotal !== null) {
                          await calculateShipping(grandtotal);
                        }
                      }}
                      dropdownMatchSelectWidth={false}>
                      <Option value={true}>Enabled</Option>
                      <Option value={false}>Disabled</Option>
                    </Select>
                  </Form.Item>
                }
              />
            </Form.Item>
            <Form.Item label="Shipping (With Tax)" name={['shippingWithTax']}>
              <InputNumber controls={false} min={0} disabled={true} />
            </Form.Item>
            <Form.Item
              label="Adjustment"
              name={['adjustment']}
              rules={[{ required: true, message: 'Please add adjustment!' }]}>
              <InputNumber controls={false} min={0} />
            </Form.Item>
          </div>
          <Form.Item
            label="Note"
            name={['note']}
            rules={[{ required: false, message: 'Please add adjustment!' }]}>
            <Input />
          </Form.Item>

          <div className="flex justify-end mt-5 gap-5">
            <Button
              type="primary"
              disabled={isAccountDisabled}
              loading={isloading}
              style={{
                borderRadius: '5px',
                color: 'white',
                backgroundColor: '#1890ff',
                marginTop: '30px'
              }}
              onClick={useDebounceFunction(async () => {
                if (await checkAccountRule(form.getFieldValue(['locationId']))) {
                  await onFinish(form.getFieldsValue(), true);
                }
              })}>
              Finalize
            </Button>
            <Form.Item>
              <Button
                type="primary"
                disabled={isAccountDisabled}
                onClick={form.submit}
                loading={isloading}
                style={{
                  borderRadius: '5px',
                  color: 'white',
                  backgroundColor: '#1890ff',
                  marginTop: '30px'
                }}>
                Update
              </Button>
            </Form.Item>
          </div>
        </Form>
        {checkAccess('READ_CHANNEL') && (
          <ReuseChannel
            slug={`sell_order_${id}`}
            fromSellorPurchaseId={String(userIdforchannel)}
            withReferenceandId={{ reference: 'sell_order', referenceId: id || 0 }}
          />
        )}
      </AppContent>
    </Spin>
  );
};

export default SellOrderDetails;
