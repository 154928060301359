import moment from 'moment';

import { useQuery, useMutation } from '@tanstack/react-query';
import {
  Form,
  message,
  PageHeader,
  Input,
  Upload,
  DatePicker,
  InputNumber,
  Checkbox,
  Tooltip
} from 'antd';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import AppContent from '@/components/Common/Content/Content';
import CustomSubmitButton from '@/components/Common/CustomButton/CustomSubmitButton';
import { update_vehicles_mutation } from '@/services/vehicle/mutations';
import { get_vehicle_details } from '@/services/vehicle/queries';
import { IEditVehicle, IVehicleData } from '@/services/vehicle/types';
import { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import { API_URL, JWT_TOKEN } from '@/constants/config';
import { getLocalStorage } from '@/utils/storage.utils';
import { InfoCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { get_image } from '@/components/Common/FetchImage/FetchImage';
import getErrorMessage from '@/utils/getError';
import { useFilterStore } from '@/store/zustand';
import { ListPage } from '@/constants/list.enum';

const EditVehicle = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { id } = useParams();

  const [vehicleDetails, setVehicleDetails] = useState<IVehicleData>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [bluebookIds, setBluebookId] = useState<UploadFile[]>([]);
  const [insuranceIds, setInsuranceId] = useState<UploadFile[]>([]);
  const [pollutionIds, setPollutionId] = useState<UploadFile[]>([]);

  const isReminderByDistance = Form.useWatch('isReminderByDistance', form);

  useQuery(['vehicle'], async () => (await get_vehicle_details(id as string)).data, {
    retry: false,
    onSuccess: async (data: IVehicleData) => {
      const vehicle = {
        id: data.id,
        name: data.name,
        imei: data.imei,
        number: data.number,
        bluebookId: data.bluebookId,
        insuranceId: data.insuranceId,
        pollutionId: data.pollutionId,
        isReminderByDistance: data.servicingReminderDistance ? true : false,
        servicingReminderDistance: data?.servicingReminderDistance,
        servicingReminderDate: data.servicingReminderDate
          ? moment(data.servicingReminderDate)
          : undefined,
        blueBookReminderDate: data.blueBookReminderDate
          ? moment(data.blueBookReminderDate)
          : undefined,
        insuranceReminderDate: data.insuranceReminderDate
          ? moment(data.insuranceReminderDate)
          : undefined,
        pollutionReminderDate: data.pollutionReminderDate
          ? moment(data.pollutionReminderDate)
          : undefined
      };

      if (data.bluebookId) {
        const bluebookData = await get_image(`${API_URL}storage/media/${data.bluebookId}`);
        setBluebookId([{ uid: '1', name: 'test.png', status: 'done', url: bluebookData }]);
      }

      if (data.insuranceId) {
        const insuranceData = await get_image(`${API_URL}storage/media/${data.insuranceId}`);
        setInsuranceId([{ uid: '1', name: 'test.png', status: 'done', url: insuranceData }]);
      }

      if (data.pollutionId) {
        const pollutionData = await get_image(`${API_URL}storage/media/${data.pollutionId}`);
        setPollutionId([{ uid: '1', name: 'test.png', status: 'done', url: pollutionData }]);
      }
      form.setFieldsValue(vehicle);
      setIsLoading(false);
    }
  });
  const breadcrumbItems = [
    { label: 'Vehicles', link: '/vehicle' },
    { label: 'Edit', link: '/vehicle/new' }
  ];

  const zustandFilter = useFilterStore();
  const updateVehicleMutation = useMutation(update_vehicles_mutation, {
    onSuccess: () => {
      message.success('Vehicle updated successfully');
      zustandFilter.removeData(ListPage.VEHICLE);
      navigate('/vehicle');
    },
    onError: (error) => {
      message.error(getErrorMessage(error));
    }
  });
  const onFinish = async (values: IEditVehicle) => {
    setIsLoading(true);
    await updateVehicleMutation.mutateAsync(values);
    setIsLoading(false);
  };
  const onPreview = async (file: UploadFile) => {
    let src = file.url as string;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj as RcFile);
        reader.onload = () => resolve(reader.result as string);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const onBluebookIdChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    const updatedFileId: number = newFileList[0]?.response?.id;
    if (updatedFileId) {
      form.setFieldValue('bluebookId', updatedFileId);
      if (vehicleDetails) {
        setVehicleDetails({ ...vehicleDetails, bluebookId: updatedFileId });
      }
    }
    setBluebookId(newFileList);
  };

  const uploadBluebookProps: UploadProps = {
    name: 'media',
    multiple: false,
    action: `${API_URL}storage/upload`,
    headers: {
      Authorization: `Bearer ${getLocalStorage(JWT_TOKEN)}`
    },
    maxCount: 1,
    listType: 'picture-card',
    fileList: bluebookIds,
    accept:
      '.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf,image/*',
    onPreview: onPreview,
    onChange: onBluebookIdChange
  };
  const onInsuranceIdChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    const updatedFileId = newFileList[0]?.response?.id;
    if (updatedFileId) {
      form.setFieldValue('insuranceId', updatedFileId);
      if (vehicleDetails) {
        setVehicleDetails({ ...vehicleDetails, insuranceId: updatedFileId });
      }
    }
    setInsuranceId(newFileList);
  };

  const uploadInsuranceIdProps: UploadProps = {
    name: 'media',
    multiple: false,
    action: `${API_URL}storage/upload`,
    headers: {
      Authorization: `Bearer ${getLocalStorage(JWT_TOKEN)}`
    },
    maxCount: 1,
    listType: 'picture-card',
    fileList: insuranceIds,
    accept:
      '.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf,image/*',
    onPreview: onPreview,
    onChange: onInsuranceIdChange
  };
  const onPollutionIdChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    const updatedFileId = newFileList[0]?.response?.id;
    if (updatedFileId) {
      form.setFieldValue('pollutionId', updatedFileId);
      if (vehicleDetails) {
        setVehicleDetails({ ...vehicleDetails, pollutionId: updatedFileId });
      }
    }
    setPollutionId(newFileList);
  };

  const uploadPollutionIdProps: UploadProps = {
    name: 'media',
    multiple: false,
    action: `${API_URL}storage/upload`,
    headers: {
      Authorization: `Bearer ${getLocalStorage(JWT_TOKEN)}`
    },
    maxCount: 1,
    listType: 'picture-card',
    fileList: pollutionIds,
    accept:
      '.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf,image/*',
    onPreview: onPreview,
    onChange: onPollutionIdChange
  };
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div>Upload</div>
    </div>
  );

  return (
    <AppContent breadcrumbItems={breadcrumbItems}>
      <PageHeader
        title="Vehicle Information"
        style={{
          padding: '8px 0px'
        }}
      />
      <Form
        form={form}
        onFinish={onFinish}
        disabled={isLoading}
        layout="vertical"
        validateTrigger={'onChange'}
        autoComplete="off">
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-2">
          <Form.Item label="ID" name={['id']} hidden>
            <Input />
          </Form.Item>
          <Form.Item
            label="Name"
            name={['name']}
            rules={[{ required: true, message: 'Please add Name!' }]}>
            <Input />
          </Form.Item>
          <Form.Item
            label="IMEI"
            name={['imei']}
            rules={[{ required: true, message: 'Please add IMEI!' }]}>
            <Input />
          </Form.Item>
          <Form.Item
            label="Vehicle Number"
            name={['number']}
            rules={[{ required: true, message: 'Please add vehicle number!' }]}>
            <Input />
          </Form.Item>
        </div>

        <div className="flex flex-wrap sm:grid grid-cols-3 gap-2 mt-2">
          <Form.Item
            label="Bluebook"
            name={['bluebookId']}
            rules={[{ required: true, message: 'Please add Bluebook Id!' }]}>
            <Upload {...uploadBluebookProps}>
              {bluebookIds.length >= 1 ? null : uploadButton}
            </Upload>
          </Form.Item>

          <Form.Item
            label="Insurance "
            name={['insuranceId']}
            rules={[{ required: true, message: 'Please add Insurance Id!' }]}>
            <Upload {...uploadInsuranceIdProps}>
              {insuranceIds.length >= 1 ? null : uploadButton}
            </Upload>
          </Form.Item>

          <Form.Item
            label="Pollution "
            name={['pollutionId']}
            rules={[{ required: true, message: 'Please add Pollution Id!' }]}>
            <Upload {...uploadPollutionIdProps}>
              {pollutionIds.length >= 1 ? null : uploadButton}
            </Upload>
          </Form.Item>
        </div>

        <div className="flex sm:gap-4 flex-wrap justify-between">
          <PageHeader title="Servicing Reminder" style={{ padding: 0 }} className="small-title" />
          <div className="flex items-center">
            <Form.Item
              className="flex items-center space-x-2"
              name="isReminderByDistance"
              valuePropName="checked">
              <Checkbox onChange={() => form.setFieldValue('servicingReminderDistance', undefined)}>
                Set Reminder by Distance
              </Checkbox>
            </Form.Item>

            <Tooltip
              overlayStyle={{ maxWidth: 500 }}
              title="If you choose this option, you must specify the distance in km. The system will send a reminder when the distance is reached from the specified start date. If unchecked, the reminder will be based on the selected date.">
              <InfoCircleOutlined className="cursor-pointer -mt-2" />
            </Tooltip>
          </div>
        </div>

        <div className="mt-2">
          {isReminderByDistance ? (
            <div className="flex flex-col sm:flex-row gap-4 max-w-3xl">
              <div className="flex-1">
                <Form.Item
                  label="Start Date"
                  name="servicingReminderDate"
                  rules={[
                    { required: isReminderByDistance, message: 'Please select a start date' }
                  ]}>
                  <DatePicker
                    className="w-full"
                    showTime
                    showNow={false}
                    format="YYYY-MM-DD hh:mm a"
                  />
                </Form.Item>
              </div>

              <div className="flex-1">
                <Form.Item
                  label="Distance (km)"
                  name="servicingReminderDistance"
                  rules={[
                    { required: isReminderByDistance, message: 'Please enter distance in km' }
                  ]}>
                  <InputNumber
                    controls={false}
                    placeholder="Enter distance in km"
                    addonAfter="km"
                    min={0}
                    className="w-full"
                  />
                </Form.Item>
              </div>
            </div>
          ) : (
            <Form.Item label="Reminder Date" name="servicingReminderDate">
              <DatePicker
                className="w-[300px]"
                showTime
                showNow={false}
                format="YYYY-MM-DD hh:mm a"
              />
            </Form.Item>
          )}
        </div>

        <PageHeader
          title="Other Reminders"
          style={{ padding: '8px 0px' }}
          className="small-title"
        />
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
          <Form.Item label="Bluebook Reminder" name="blueBookReminderDate">
            <DatePicker
              className="w-full"
              placeholder="Select bluebook reminder date"
              showTime
              showNow={false}
              format="YYYY-MM-DD hh:mm a"
            />
          </Form.Item>

          <Form.Item label="Pollution Reminder" name="pollutionReminderDate">
            <DatePicker
              className="w-full"
              placeholder="Select pollution reminder date"
              showTime
              showNow={false}
              format="YYYY-MM-DD hh:mm a"
            />
          </Form.Item>

          <Form.Item label="Insurance Reminder" name="insuranceReminderDate">
            <DatePicker
              className="w-full"
              placeholder="Select insurance reminder date"
              showTime
              showNow={false}
              format="YYYY-MM-DD hh:mm a"
            />
          </Form.Item>
        </div>

        <div className="flex justify-end mt-5">
          <Form.Item>
            <CustomSubmitButton text="Submit" />
          </Form.Item>
        </div>
      </Form>
    </AppContent>
  );
};

export default EditVehicle;
