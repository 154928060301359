import {
  Input,
  Button,
  InputNumber,
  PageHeader,
  Divider,
  Form,
  Upload,
  Card,
  Collapse,
  Select,
  message,
  Tooltip,
  Table
} from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import AppContent from '@/components/Common/Content/Content';
import { IUserUpdateNameAndPhoneRequest, IUserUpdateRequest } from '@/services/users/types';
import { getLocalStorage, setLocalStorage } from '@/utils/storage.utils';
import { API_URL, JWT_TOKEN, USER_INFO } from '@/constants/config';
import { LeftOutlined, PlusOutlined, RetweetOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@tanstack/react-query';
import { get_user_avatar } from '@/services/users/queries';
import DeleteConfirmModal from '@/components/Common/Deleteconfirm/DeleteConfirmModal';
import CustomButton from '@/components/Common/CustomButton/CustomButton';
import GoBackButton from '@/components/Common/GoBackButton/GoBackButton';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import { checkAccess } from '@/routes/acl';
import {
  update_credit_limit_for_customer,
  update_disabled_mutation,
  update_grade_for_vendor_or_customer,
  update_preference_user_mutation,
  update_vendor_type_for_vendor
} from '@/services/users/mutations';
import { LocationSearch } from '@/components/Common/LocationSearch/LocationSearch';
import { getUserData } from '@/utils/auth.utils';
import { generatePassword } from '@/utils/password.utils';
import TableFilter from '@/components/FliterTable';
import moment from 'moment';
import { get_report_filter } from '@/services/report/queries';
import { report } from 'process';
import { data } from 'autoprefixer';
import UnitsDB from '@/store/localstorage/UnitsDB';
import ProductsDB from '@/store/localstorage/ProductsDB';
import { get_product_list_ids, get_units_list } from '@/services/products/queries';
import { numberDecimalFormatter } from '@/utils/numberFormatter';
import { type } from 'os';
import { VendorTypeSearch } from '@/components/Common/VendorTypeSearch';
import { UserTypeSearch } from '@/components/Common/UsersTypeSearch';
import UserLedgerSecondVersion from '../../Customers/Ledger/index2';
import ReuseChannel from '../../../channel/Reuse';
import VendorLedger from '../../Vendors/Ledger';
import { breadcrumbItem } from '../types';
import './index.css';

interface DetailsProps {
  breadcrumbItems: breadcrumbItem[];
  title: string;
  allInfo: any;
  onFinish: (values: any) => void;
  form: FormInstance<any>;
  userDetails: IUserUpdateRequest;
  onNameAndPhoneUpdate: (props: IUserUpdateNameAndPhoneRequest) => void;
  setUserDetails: (value: IUserUpdateRequest) => void;
  isLoading: boolean;
  setIsloading: React.Dispatch<React.SetStateAction<boolean>>;
  buttonloading: boolean;
  setButtonloading: React.Dispatch<React.SetStateAction<boolean>>;
  deleteUserMutation?: any;
  refetch: any;
  customerDetails?: any;
  vendorDetails?: any;
}

const DetailsUI: React.FC<DetailsProps> = ({
  breadcrumbItems,
  title,
  onFinish,
  form,
  allInfo,
  userDetails,
  setUserDetails,
  isLoading,
  setIsloading,
  buttonloading,
  setButtonloading,
  onNameAndPhoneUpdate,
  deleteUserMutation,
  refetch,
  customerDetails,
  vendorDetails
}) => {
  // console.log('Userdetails-->', userDetails);
  // console.log('customerDetails-->', customerDetails);
  // console.log('vendorDetails', vendorDetails);
  const [form2] = Form.useForm();
  const navigate = useNavigate();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [locationId, setLocationId] = useState();
  const loggedInData = getUserData();
  const [topProductsData, setTopProductsData] = useState<any>([]);
  const [tableLoading, setTableLoading] = useState<boolean>(true);
  const [changedFieldsList, setChangedFieldsList] = useState<string[]>([]);

  const submit = async () => {
    try {
      if (
        changedFieldsList.length > 0 &&
        changedFieldsList.filter(
          (val) => val !== 'name' && val !== 'phone' && val !== 'secondaryPhone'
        ).length === 0
      ) {
        await form.validateFields(['phone', 'name']);
        const updatedValues: { [key: string]: string | undefined | null | number } = {};
        changedFieldsList.map((name) => {
          updatedValues[name] = userDetails[name as keyof IUserUpdateRequest];
        });
        updatedValues.id = userDetails.id;
        onNameAndPhoneUpdate(updatedValues as unknown as IUserUpdateNameAndPhoneRequest);
      } else {
        form.submit();
      }
    } catch (e) {
      if (e instanceof Error) {
        console.log(e.message);
      }
    }
  };
  useQuery(
    ['storage/media', userDetails.avatarId],
    async () => get_user_avatar(userDetails.avatarId),
    {
      retry: false,
      onSuccess: (data: any) => {
        if (data && fileList.length === 0) {
          setFileList([
            {
              uid: '-1',
              name: 'test.png',
              status: 'done',
              url: data
            }
          ]);
        }

        // form.setFieldsValue(data.user);
        // setUserDetails(data.user);
      }
    }
  );
  const onPreview = async (file: UploadFile) => {
    let src = file.url as string;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj as RcFile);
        reader.onload = () => resolve(reader.result as string);
      });
    }

    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const onChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    const updatedFileId = newFileList[0]?.response?.id;
    if (updatedFileId) {
      form.setFieldValue('avatarId', updatedFileId);
      setUserDetails({ ...userDetails, avatarId: updatedFileId });
    }
    setFileList(newFileList);
  };

  const uploadProps: UploadProps = {
    name: 'media',
    multiple: false,
    action: `${API_URL}storage/upload`,
    headers: {
      Authorization: `Bearer ${getLocalStorage(JWT_TOKEN)}`
    },
    maxCount: 1,
    listType: 'picture-card',
    fileList: fileList,
    accept: 'image/*',
    onPreview: onPreview,
    onChange: onChange
  };

  const uploadButton = (
    <div className="rounded-full">
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const confirmFunction = () => {
    deleteUserMutation.mutate(userDetails.id);
  };

  const [responsize, setResponsize] = useState(false);
  useEffect(() => {
    if (window.innerWidth <= 650) {
      // setCollapsed(true);
      setResponsize(true);
    } else {
      setResponsize(false);
    }
    function handleResize() {
      if (window.innerWidth <= 650) {
        // setCollapsed(true);
        setResponsize(true);
      } else {
        setResponsize(false);
      }
    }
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  useEffect(() => {
    if (breadcrumbItems[0].label == 'Customer' && customerDetails) {
      if (allInfo?.user?.preferences) {
        const preferences = JSON.parse(allInfo.user.preferences.preferences);
        if (!preferences) return;

        if ('locationId' in preferences) {
          form.setFieldValue('locationId', preferences.locationId);
        }

        if ('nepaliDate' in preferences) form.setFieldValue('nepaliDate', preferences.nepaliDate);
      }
      form.setFieldValue('grade', customerDetails.grade);
      form.setFieldValue('creditLimit', customerDetails.creditLimit);
      form.setFieldValue('balance', customerDetails.balance);
    } else if (breadcrumbItems[0].label == 'Vendors' && vendorDetails) {
      if (allInfo?.user?.preferences) {
        const preferences = JSON.parse(allInfo.user.preferences.preferences);
        if (!preferences) return;

        if ('locationId' in preferences) {
          form.setFieldValue('locationId', preferences.locationId);
        }
        if ('nepaliDate' in preferences) form.setFieldValue('nepaliDate', preferences.nepaliDate);
      }
      form.setFieldValue('grade', vendorDetails.grade);
      form.setFieldValue('vendorTypeId', vendorDetails.vendorTypeId);
    } else {
      // console.log('pref->', allInfo?.preferences);
      if (allInfo?.preferences) {
        const preferences = JSON.parse(allInfo.preferences.preferences);
        if (!preferences) return;

        if ('locationId' in preferences) {
          form.setFieldValue('locationId', preferences.locationId);
        }
        if ('nepaliDate' in preferences) form.setFieldValue('nepaliDate', preferences.nepaliDate);
        // form.setFieldValue('locationId', );
      }
    }
  }, [customerDetails, vendorDetails, allInfo]);

  const TopChildern = () => {
    return (
      <>
        <div className={responsize ? 'flex flex-wrap gap-1' : 'flex justify-between'}>
          <GoBackButton onClick={() => navigate(breadcrumbItems[0].link)} />
          <div className={responsize ? 'flex flex-wrap gap-1' : 'flex gap-3'}>
            <CustomButton
              text="View Address"
              // onClick={() => navigate(`${breadcrumbItems[0].link}/${userDetails.id}/address`)}
              Linkto={`/users/${userDetails.id}/address`}
              textColor="green"
            />
            <CustomButton
              text="View Tax"
              // onClick={() => navigate(`${breadcrumbItems[0].link}/${userDetails.id}/tax`)}
              Linkto={`/users/${userDetails.id}/tax`}
              textColor="green"
            />
            <CustomButton
              text="ACL"
              // onClick={() => navigate(`${breadcrumbItems[0].link}`)}
              Linkto={`/acl`}
              textColor="green"
            />
            <DeleteConfirmModal FunctionAfterOk={confirmFunction} fromUserdetails={true} />
          </div>
        </div>
      </>
    );
  };

  const updateDisabledMutation = useMutation(update_disabled_mutation, {
    onSuccess: (data: any) => {
      message.success('User updated successfully');
    }
  });

  const updatePreferneceMutation = useMutation(update_preference_user_mutation, {
    onSuccess: (data: any) => {
      message.success('User updated successfully');
    }
  });
  const updateGradeMutation = useMutation(update_grade_for_vendor_or_customer, {
    onSuccess: (data: any) => {
      message.success('Grade Updated SucessFully');
    },
    onError: (err: any) => {
      message.error(err.message);
    }
  });

  const updateVendorTypeMutation = useMutation(update_vendor_type_for_vendor, {
    onSuccess: () => {
      message.success('Vendor Type Updated SucessFully');
    }
  });

  const updateCreditLimitMutation = useMutation(update_credit_limit_for_customer, {
    onSuccess: (data: any) => {
      message.success('Credit Limit Updated SucessFully');
    },
    onError: (err: any) => {
      message.error(err.message);
    }
  });

  const changeStatus = async () => {
    const updated_grade = form.getFieldsValue().grade;
    const updated_vendorTypeId = form.getFieldsValue().vendorTypeId;
    // console.log('updated_vendorTypeId', updated_vendorTypeId);
    const locationIdPref = form.getFieldsValue().locationId;
    const nepaliDatePref = form.getFieldsValue().nepaliDate;
    const updated_creditLimit = form.getFieldsValue().creditLimit;
    const values = {
      id: userDetails.id,
      flag: form.getFieldsValue().disabled
    };

    let prefUpdate: any;

    if (breadcrumbItems[0].label == 'Customer') {
      if (locationIdPref != locationId) {
        let value;
        if (allInfo?.user?.preferences) {
          const preferences = JSON.parse(allInfo.user.preferences.preferences) || {};
          value = { ...preferences };
        }
        if (value) {
          value = { ...value, locationId: locationIdPref, nepaliDate: nepaliDatePref };
        } else {
          value = { locationId: locationIdPref, nepaliDate: nepaliDatePref };
        }
        await updatePreferneceMutation.mutateAsync({
          id: allInfo.customer.userId,
          value: JSON.stringify(value)
        });
        prefUpdate = value;
      }
      if (customerDetails.grade != updated_grade) {
        const values = {
          type: 'customer',
          id: customerDetails.id,
          grade: updated_grade
        };
        await updateGradeMutation.mutateAsync(values);
      }
      if (customerDetails.creditLimit != updated_creditLimit) {
        const values = {
          id: customerDetails.id,
          creditLimit: updated_creditLimit
        };
        await updateCreditLimitMutation.mutateAsync(values);
      }
    } else if (breadcrumbItems[0].label == 'Vendors') {
      if (locationIdPref != locationId) {
        let value;
        if (allInfo?.user?.preferences) {
          const preferences = JSON.parse(allInfo.user.preferences.preferences) || {};
          value = { ...preferences };
        }
        if (value) {
          value = { ...value, locationId: locationIdPref, nepaliDate: nepaliDatePref };
        } else {
          value = { locationId: locationIdPref, nepaliDate: nepaliDatePref };
        }
        await updatePreferneceMutation.mutateAsync({
          id: allInfo.vendor.userId,
          value: JSON.stringify(value)
        });
        prefUpdate = value;
      }
      if (vendorDetails.grade != updated_grade) {
        const values = {
          type: 'vendor',
          id: vendorDetails.id,
          grade: updated_grade
        };
        await updateGradeMutation.mutateAsync(values);
      }
      if (vendorDetails.vendorTypeId != updated_vendorTypeId) {
        const values = {
          id: vendorDetails.id,
          vendorTypeId: updated_vendorTypeId
        };
        // console.log('values', values);
        await updateVendorTypeMutation.mutateAsync(values);
      }
    } else {
      if (locationIdPref != locationId) {
        let value;
        if (allInfo?.preferences) {
          const preferences = JSON.parse(allInfo.preferences.preferences) || {};
          value = { ...preferences };
        }
        if (value) {
          value = { ...value, locationId: locationIdPref, nepaliDate: nepaliDatePref };
        } else {
          value = { locationId: locationIdPref, nepaliDate: nepaliDatePref };
        }
        await updatePreferneceMutation.mutateAsync({
          id: allInfo.user.id,
          value: JSON.stringify(value)
        });
        prefUpdate = value;

        // await updatePreferneceMutation.mutateAsync({id:})
      }
    }
    if (userDetails.id == loggedInData.id) {
      setLocalStorage(USER_INFO, {
        ...loggedInData,
        preferences: {
          ...loggedInData.preferences,
          preferences: JSON.stringify(prefUpdate)
        }
      });
    }

    // console.log('values', values);
    // console.log('--->', form.getFieldsValue().disabled);
    await updateDisabledMutation.mutateAsync(values);
    refetch();
  };

  const columns = [
    {
      title: 'S.N.',
      render: (a: any, b: any, c: any) => <div className="text-center">{c + 1}</div>,
      width: 1
    },
    {
      title: 'Product',
      dataIndex: 'productName',
      width: 10
    },
    {
      title: 'Quantity',
      dataIndex: 'totalQuantityWithUnit',
      width: 10
    },
    {
      title: 'Unit',
      dataIndex: 'shortName',
      width: 3
    }
  ];

  const handleSubmit = async (filter = '') => {
    // console.log('Filter ', filter);
    setTableLoading(true);
    // filter += `&page=1&size=100&customerId=${customerDetails.id}`;
    const response = await get_report_filter(filter, 16);
    // console.log('Report', response);
    const productsMap: any = {};
    if (response?.data?.data) {
      for (let ind = 0; ind < response.data.data.length; ind++) {
        const { product_id, quantity, unit_id, name } = response.data.data[ind];
        let unitInfo: any = await UnitsDB.getUnit(unit_id);
        if (!unitInfo) {
          const allUnits = await get_units_list();
          await UnitsDB.addUnits(allUnits);
          unitInfo = await UnitsDB.getUnit(unit_id);
        }

        const tquantity = parseFloat(quantity) * unitInfo.baseUnitMultiplier;
        if (!productsMap[product_id]) {
          productsMap[product_id] = {
            productName: name,
            totalQuantity: tquantity
          };
        } else {
          productsMap[product_id] = {
            ...productsMap[product_id],
            totalQuantity: productsMap[product_id].totalQuantity + tquantity
          };
        }
      }
    }
    const finalArray = [];
    const fetchProduct = [];
    for (const key in productsMap) {
      const product: any = await ProductsDB.getProduct(parseInt(key as string));
      if (!product) {
        fetchProduct.push(key);
      } else {
        // console.log('Product', product);
        let defaultUnit = product.productUnits.find((curr: any) => curr.isDefault === true);
        if (!defaultUnit) {
          defaultUnit = product.productUnits[0];
        }
        // console.log('Default Unit-->', defaultUnit);
        let findUnit: any = await UnitsDB.getUnit(defaultUnit.unitId);
        if (!findUnit) {
          const allUnits = await get_units_list();
          await UnitsDB.addUnits(allUnits);
          findUnit = await UnitsDB.getUnit(defaultUnit.unitId);
        }
        const totalQuantityWithUnit = numberDecimalFormatter(
          productsMap[key].totalQuantity / findUnit.baseUnitMultiplier
        );
        finalArray.push({
          ...productsMap[key],
          totalQuantityWithUnit,
          shortName: findUnit.shortName
        });
      }
    }
    if (fetchProduct.length > 0) {
      const response: any = await get_product_list_ids(fetchProduct);
      for (let ind = 0; ind < response?.data?.results.length; ind++) {
        const { id, productUnits } = response.data.results[ind];
        let defaultUnit = productUnits.find((curr: any) => curr.isDefault === true);
        if (!defaultUnit) {
          defaultUnit = productUnits[0];
        }
        // console.log('Default Unit-->', defaultUnit);
        let findUnit: any = await UnitsDB.getUnit(defaultUnit.unitId);
        if (!findUnit) {
          const allUnits = await get_units_list();
          await UnitsDB.addUnits(allUnits);
          findUnit = await UnitsDB.getUnit(defaultUnit.unitId);
        }
        const totalQuantityWithUnit = numberDecimalFormatter(
          productsMap[id].totalQuantity / findUnit.baseUnitMultiplier
        );
        finalArray.push({
          ...productsMap[id],
          totalQuantityWithUnit,
          shortName: findUnit.shortName
        });
      }
      // console.log('findproduct-->', findproduct);
    }
    // console.log('Final Array', finalArray);
    setTopProductsData(finalArray);
    setTableLoading(false);
    // console.log('Products Map', productsMap);
  };
  // console.log('Customer details', customerDetails);
  return (
    <div>
      <AppContent breadcrumbItems={breadcrumbItems} backgroundWhite={true} button={<TopChildern />}>
        <Card style={{ margin: '15px 0px', borderRadius: '9px' }}>
          <PageHeader
            title={title}
            style={{
              padding: '8px 0px'
            }}
          />
          <Form
            form={form}
            onFinish={onFinish}
            disabled={isLoading}
            layout="vertical"
            onFieldsChange={(changedFields) => {
              // console.log(changedFields);
            }}
            className="main-div"
            validateTrigger={'onChange'}
            onValuesChange={(_, allFields) => {
              if (allFields.phone) {
                allFields.phone = allFields.phone.toString();
                setUserDetails({ ...userDetails, ...allFields, phone: allFields.phone.toString() });
              }
              if (allFields.secondaryPhone) {
                allFields.secondaryPhone = allFields.secondaryPhone.toString();
                setUserDetails({
                  ...userDetails,
                  ...allFields,
                  secondaryPhone: allFields.secondaryPhone.toString()
                });
              }
              if (!allFields.phone && allFields.secondaryPhone) {
                setUserDetails({ ...userDetails, ...allFields });
              }
              const fieldName = Object.keys(_)[0];
              const fieldValue = Object.values(_)[0];

              if (changedFieldsList.includes(fieldName)) {
                if (fieldValue == allInfo.user[fieldName]) {
                  const index = changedFieldsList.indexOf(fieldName);
                  if (index > -1) {
                    setChangedFieldsList((prev) => {
                      return prev.filter((val) => val !== fieldName);
                    });
                  }
                }
              } else {
                setChangedFieldsList((val) => [...val, fieldName]);
                // changedFieldsList.push(fieldName);
              }
            }}
            autoComplete="off">
            <div style={{ display: 'flex', gap: '10px' }}>
              <div
                className="flex-col mb-5 justify-center align-center left-div"
                style={{ flex: '1' }}>
                <Form.Item
                  label="Name"
                  name="name"
                  rules={[{ required: true, message: 'Please input name!' }]}>
                  <Input style={{ padding: '10px', borderRadius: '9px' }} />
                </Form.Item>
                <Form.Item
                  label="Phone"
                  name="phone"
                  rules={[{ required: true, message: 'Please add phone!' }]}>
                  <InputNumber controls={false} style={{ padding: '6px', borderRadius: '9px' }} />
                </Form.Item>
                <Form.Item label="Phone (Secondary)" name="secondaryPhone">
                  <InputNumber controls={false} style={{ padding: '6px', borderRadius: '9px' }} />
                </Form.Item>
                {breadcrumbItems[0].label == 'Users' && (
                  <UserTypeSearch
                    formData={{ formName: 'typeId', label: 'User Type' }}
                    required={true}
                    isAll={false}
                    initialValue={{
                      typeId: allInfo?.user?.typeId
                    }}
                  />
                )}
                <div className="flex flex-row justify-between items-center gap-1">
                  <Form.Item
                    label="Password"
                    name="password"
                    rules={[{ required: true, message: 'Please add password!' }]}>
                    {/* <Input.Password
                    className="mb-5"
                    style={{ padding: '10px', borderRadius: '9px' }}
                  /> */}
                    <Input.Password
                      className="mb-5"
                      autoComplete="new-password"
                      style={{ padding: '10px', borderRadius: '9px' }}
                    />
                  </Form.Item>
                  <Tooltip title="generate password">
                    <Button
                      style={{ borderRadius: '9px' }}
                      icon={<RetweetOutlined />}
                      onClick={() => {
                        const password: any = generatePassword(10);
                        form.setFieldValue('password', password);
                      }}
                    />
                  </Tooltip>
                </div>
                {/* <Collapse>
                <CollapsePanel header="Status" key="1">
                  <Form.Item label="Status" name="disabled">
                    <Select
                      placeholder="select a status"
                      style={{ borderRadius: '10px' }}
                      allowClear={false}
                      dropdownMatchSelectWidth={false}>
                      <Select.Option value={false} key={'enabled'}>
                        Enabled
                      </Select.Option>
                      <Select.Option value={true} key={'disabled'}>
                        Disabled
                      </Select.Option>
                    </Select>
                  </Form.Item>
                  <div className="flex justify-end mt-5 mr-5">
                    <Button
                      loading={buttonloading}
                      onClick={changeStatus}
                      style={{ borderRadius: '5px', color: 'white', backgroundColor: '#1890ff' }}>
                      Update Status
                    </Button>
                  </div>
                </CollapsePanel>
              </Collapse> */}
              </div>
              <div className="flex flex-col" style={{ flex: 1 }}>
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[{ required: true, message: 'Please add email!' }]}>
                  <Input style={{ padding: '10px', borderRadius: '9px' }} />
                </Form.Item>
                <div className="flex flex-row-reverse align-center">
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}>
                    <Upload className="rounded-full" {...uploadProps}>
                      {fileList.length >= 1 ? null : uploadButton}
                    </Upload>
                  </div>
                </div>
                <Form.Item
                  label="Image"
                  name="avatarId"
                  hidden
                  rules={[{ required: false, message: 'Please upload image!' }]}>
                  <Input />
                </Form.Item>
                <div className="flex flex-1 justify-end items-end">
                  <Form.Item>
                    <Button
                      onClick={() => {
                        submit();
                      }}
                      loading={buttonloading}
                      style={{ borderRadius: '5px', color: 'white', backgroundColor: '#1890ff' }}>
                      Update
                    </Button>
                  </Form.Item>
                </div>
              </div>
            </div>

            <Collapse style={{ marginBottom: '10px' }}>
              <CollapsePanel header="Other Info" key="1">
                <div className="grid grid-cols-3 gap-3">
                  <Form.Item label="Status" name="disabled">
                    <Select
                      placeholder="select a status"
                      style={{ borderRadius: '10px' }}
                      allowClear={false}
                      dropdownMatchSelectWidth={false}>
                      <Select.Option value={false} key={'enabled'}>
                        Enabled
                      </Select.Option>
                      <Select.Option value={true} key={'disabled'}>
                        Disabled
                      </Select.Option>
                    </Select>
                  </Form.Item>
                  {breadcrumbItems[0].label == 'Customer' && (
                    <>
                      <Form.Item label="Credit Limit" name="creditLimit">
                        <Input />
                      </Form.Item>

                      <Form.Item name="balance" label="Wallet Balance">
                        <Input
                          disabled
                          style={{
                            backgroundColor: 'white',
                            color: 'black',
                            fontWeight: 'bold'
                          }}
                        />
                      </Form.Item>
                    </>
                  )}
                  {breadcrumbItems[0].label != 'Users' && (
                    <Form.Item label="Grade" name="grade">
                      <Input maxLength={1} />
                    </Form.Item>
                  )}
                  <Form.Item
                    name={['locationId']}
                    label="Default Location"
                    rules={[
                      {
                        required: true,
                        message: 'Please choose location!'
                      }
                    ]}>
                    <LocationSearch
                      onSelect={(val) => form.setFieldValue('locationId', val)}
                      notAll={true}
                      withoutForm={true}
                    />
                  </Form.Item>
                  <Form.Item name="nepaliDate" label="Date Type:">
                    <Select
                      placeholder="Select a Type!"
                      style={{ borderRadius: '10px' }}
                      allowClear
                      dropdownMatchSelectWidth={false}>
                      <Select.Option value={false} key={'1'}>
                        English
                      </Select.Option>
                      <Select.Option value={true} key={'2'}>
                        Nepali
                      </Select.Option>
                    </Select>
                  </Form.Item>
                  {breadcrumbItems[0].label == 'Vendors' && (
                    <VendorTypeSearch
                      formData={{ formName: 'vendorTypeId', label: 'Supplier Category' }}
                      onSelect={(val) => form.setFieldValue('vendorTypeId', val)}
                      isAll={false}
                    />
                  )}
                </div>
                <div className="flex justify-end mt-5 mr-5">
                  <Button
                    loading={buttonloading}
                    onClick={changeStatus}
                    style={{ borderRadius: '5px', color: 'white', backgroundColor: '#1890ff' }}>
                    Update Status
                  </Button>
                </div>
              </CollapsePanel>
            </Collapse>
          </Form>
        </Card>

        {breadcrumbItems[0].label == 'Customer' && (
          <>
            <Collapse>
              <CollapsePanel header="User Ledger" key="1">
                {userDetails?.id && <UserLedgerSecondVersion />}
              </CollapsePanel>
            </Collapse>

            <Collapse>
              {customerDetails && (
                <CollapsePanel header="Customer Top Products" key="3">
                  <TableFilter
                    allowSaved={false}
                    form={form2}
                    defaultValues={{
                      dateCustom: [
                        moment(0, 'HH').subtract(6, 'days'),
                        moment(0, 'HH').add(1, 'days')
                      ],
                      customerId: customerDetails.id,
                      page: 1,
                      size: 100
                    }}
                    onSubmit={handleSubmit}
                    initial={true}
                    // style="flex gap-2"
                    outsidestyle="flex gap-2 items-end">
                    <Form.Item name="page" hidden></Form.Item>
                    <Form.Item name="size" hidden></Form.Item>
                    <Form.Item name="customerId" hidden></Form.Item>
                  </TableFilter>
                  <Table
                    size="small"
                    columns={columns}
                    dataSource={topProductsData}
                    loading={tableLoading}></Table>
                </CollapsePanel>
              )}
            </Collapse>
          </>
        )}
        {breadcrumbItems[0].label == 'Vendors' && (
          <Collapse>
            <CollapsePanel header="Vendors Ledger" key="1">
              {userDetails?.id && <VendorLedger />}
            </CollapsePanel>
          </Collapse>
        )}
        {checkAccess('READ_CHANNEL') && <ReuseChannel slug={`user_${parseInt(userDetails.id)}`} />}
      </AppContent>
    </div>
  );
};

export default DetailsUI;
