import { useState } from 'react';
import { get_closed_channel_list_filter } from '../../../services/channels/queries';
import AppContent from '../../../components/Common/Content/Content';
import TableFilter from '../../../components/FliterTable';
import { Form, Input, Menu, Modal } from 'antd';
import moment from 'moment';
import { getUserData } from '../../../utils/auth.utils';

import { ConvertObjectToURL } from '../../../utils/converturl';
import type { ColumnsType } from 'antd/es/table';
import CustomizeTable from '../../../components/Common/CustomizeTable/CustomizeTable';
import ReusableRoom from '../ReusableRoom';
import UsersDB from '../../../store/localstorage/UsersDB';
import { get_user_list_ids } from '../../../services/users/queries';
import ActionDropdown from '../../../components/Common/Dropdownactions';
import TableCell from '../../../components/Common/CustomizeTable/CustomCell';
import CustomViewIcon from '../../../components/Common/CustomIcons/CustomViewIcon';
import LocationSearchV2 from '@/components/Common/CustomSearch/Location';
import RouteSearchV2 from '@/components/Common/CustomSearch/Routes';

const ClosedChannelList = () => {
  const [form] = Form.useForm();
  const [allClosedChannelList, setallClosedChannelList] = useState<any>({ total: 0, results: [] });
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(100);
  const { preferences } = getUserData();
  const [channelDetails, setChannelDetails] = useState<any>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const preferenceLocationId = preferences?.preferences
    ? JSON.parse(preferences?.preferences)?.locationId
    : undefined;

  const breadcrumbItems = [
    {
      label: 'CLosed channels',
      link: '/channel/closed'
    }
  ];

  const onSubmitFilter = async (val: string) => {
    const data = await getInfo(val);
    setPage(1);
    setSize(100);
    return data;
  };

  const getInfo = async (filter = '') => {
    // console.log('filter', filter);
    const response = await get_closed_channel_list_filter(filter);
    const searchUsers: any = {};
    for (let index = 0; index < response.data.results.length; index++) {
      const user: any = await UsersDB.getUser(response.data.results[index].userId);
      if (!user) {
        if (response.data.results[index].userId in searchUsers) {
          searchUsers[response.data.results[index].userId] = [
            ...searchUsers[response.data.results[index].userId],
            index
          ];
        } else {
          searchUsers[response.data.results[index].userId] = [index];
        }
      } else {
        response.data.results[index].addedByName = user.name;
      }
    }
    const searchUserslength = Object.entries(searchUsers).length;
    if (searchUserslength > 0) {
      const customerresponse = await get_user_list_ids([...Object.keys(searchUsers)]);

      for (const key in searchUsers) {
        const newUser = customerresponse?.data?.results.find(
          (currCustomer: any) => currCustomer.id == key
        );
        for (let i = 0; i < searchUsers[key].length; i++) {
          response.data.results[searchUsers[key][i]].addedByName = newUser?.name;
        }
        await UsersDB.addUsers([newUser]);
      }
    }
    setallClosedChannelList(response.data);
    return response.data;
  };

  const onPagination = async (pageNo = 1, totalSize = 100, isSize = false) => {
    // setIsloading(true);
    const values = form.getFieldsValue();
    delete values.dateCustom;
    if (isSize) {
      values.skip = 0;
      values.count = totalSize;
      setPage(1);
      setSize(totalSize);
    } else {
      values.skip = (pageNo - 1) * totalSize;
      values.count = totalSize;
      setPage(pageNo);
    }
    const url = ConvertObjectToURL(values);
    // const response = await get_sell_lists_v2(url);
    // setallSellList(response.data);
    return await getInfo(url);
    // setIsloading(false);
    // ProductsDB.addProducts(productResponse.data.results);
  };

  const columns: ColumnsType<any> = [
    {
      title: 'ID',
      key: 'id',
      //   sorter: (a, b) => a.id - b.id,
      //   sortOrder: sortedInfo.columnKey === 'id' ? sortedInfo.order : null,
      width: 100,
      render: (record) => {
        return <TableCell>{record.id}</TableCell>;
      }
    },
    {
      title: 'Channel Id',
      width: 100,
      key: 'channelId',
      //   sorter: (a, b) => a.referenceNumber.localeCompare(b.referenceNumber),
      //   sortOrder: sortedInfo.columnKey === 'referenceNumber' ? sortedInfo.order : null,
      render: (record) => {
        return <TableCell>{record.channelId}</TableCell>;
      }
    },
    {
      title: 'Created By',
      width: 100,
      key: 'userId',
      //   sorter: (a, b) => a.userName.localeCompare(b.userName),
      //   sortOrder: sortedInfo.columnKey === 'userName' ? sortedInfo.order : null,
      render: (record) => {
        return <TableCell>{record.addedByName}</TableCell>;
      }
    },
    {
      title: 'Channel Name',
      width: 100,
      //   key: 'locationName',
      //   sorter: (a, b) => a.locationName.localeCompare(b.locationName),
      //   sortOrder: sortedInfo.columnKey === 'locationName' ? sortedInfo.order : null,
      render: (record) => {
        return <TableCell>{record.channel.name}</TableCell>;
      }
    },
    {
      title: 'Actions',
      key: 'actions',
      width: 50,
      fixed: 'right',
      render: (record) => {
        const menuItems: (
          | {
              key: string;
              label: JSX.Element;
              onClick?: undefined;
            }
          | {
              key: string;
              label: JSX.Element;
              onClick: () => void;
            }
        )[] = [];

        menuItems.push({
          key: '2',
          label: <CustomViewIcon />,
          onClick: () => {
            setChannelDetails(record.channel);
            setIsModalOpen(true);
          }
        });

        const menu = <Menu items={menuItems} />;
        return <ActionDropdown menu={menu} />;
      }
    }
  ];
  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <Modal
        title={'Channel Details'}
        visible={isModalOpen}
        footer={false}
        onOk={handleOk}
        onCancel={handleCancel}>
        {channelDetails?.id && (
          <ReusableRoom
            id={channelDetails.id}
            channelName={channelDetails.name}
            fromChatList={false}
          />
        )}
      </Modal>

      <AppContent
        breadcrumbItems={breadcrumbItems}
        withfilter={true}
        button={
          <>
            {' '}
            <div>
              {
                <TableFilter
                  form={form}
                  onInitialLoad={({ data, pagination }) => {
                    setPage(pagination.page);
                    setSize(pagination.size);

                    if (data) {
                      setChannelDetails(data);
                    }
                  }}
                  defaultValues={{
                    dateCustom: [moment(0, 'HH'), moment(0, 'HH').add(1, 'days')],
                    locationId: preferenceLocationId ? preferenceLocationId : '',
                    customerGroup: '',
                    user: '',
                    paymentStatus: '',
                    skip: 0,
                    count: 100
                  }}
                  initial={true}
                  onSubmit={onSubmitFilter}
                  styleforbuttons={'flex justify-end'}
                  style={
                    'grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3  xl:grid-cols-4 gap-2 items-center justify-center'
                  }>
                  <LocationSearchV2
                    hasParentFormItem={false}
                    name={'locationId'}
                    label="Location"
                  />
                  <RouteSearchV2 hasParentFormItem={false} name={'routeId'} />
                  <Form.Item name="value" label="Search">
                    <Input />
                  </Form.Item>
                </TableFilter>
              }
            </div>
            {/* </div> */}
          </>
        }>
        <CustomizeTable
          form={form}
          columns={columns}
          data={allClosedChannelList.results}
          notshowPagination={true}
          paginationDatas={{
            page: page,
            total: allClosedChannelList.total,
            size: size,
            onPagination
          }}
          tableName={'channel-closed-list'}
          //   toSort={handleChange}
        />
      </AppContent>
    </div>
  );
};

export default ClosedChannelList;
