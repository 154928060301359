import moment from 'moment';
import AppContent from '@/components/Common/Content/Content';
import { get_price_analysis_id, get_price_groups_list } from '@/services/products/queries';
import getErrorMessage from '@/utils/getError';
import { useQuery } from '@tanstack/react-query';
import { Alert, Button, Form, message, Select, Spin } from 'antd';
import { useRef, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import ProductsDB from '@/store/localstorage/ProductsDB';
import { getCategoryById, getLocationByList, getProductById, getUnit } from '@/services';
import PriceList from './PriceListSideBySide';
import { toPng } from 'html-to-image';
import { convert_string_to_nepali_date_string } from '@/utils/nepaliDateConverter';
import { uploadImagesFormData } from '@/services/upload/queries';
import { update_price_group_price_by_price_analysis } from '@/services/products/mutations';
import { LocationType } from '@/services/locations/enum';

interface FormValues {
  priceGroupIds: number[];
  type: 'B2B' | 'OUTLET';
}

function UpdatePriceGroupByAnalysis() {
  const params = useParams();
  const analysisId = Number(params.id);
  const navigate = useNavigate();

  const [form] = Form.useForm<FormValues>();
  const [isLoading, setIsLoading] = useState(false);
  const [priceGroups, setPriceGroups] = useState<{ label: string; value: number; id: number }[]>(
    []
  );

  if (!params.id) {
    return <Navigate to="/price-analysis" replace />;
  }

  const breadcrumbs = [
    { label: 'Price Analysis', link: '/price-analysis' },
    { label: 'Update Price Groups' }
  ];

  const { data } = useQuery(['priceGroups-update', analysisId], getData);
  const b2bListRef = useRef<HTMLDivElement>(null);
  const outletListRef = useRef<HTMLDivElement>(null);

  async function generateImageFormData(element: HTMLElement) {
    const dataUrl = await toPng(element, { width: 1400, backgroundColor: 'white', pixelRatio: 2 });
    const blob = await (await fetch(dataUrl)).blob();
    const formData = new FormData();
    formData.append('media', blob, 'media.png');
    return formData;
  }

  async function getData() {
    try {
      setIsLoading(true);
      const analysisData = await get_price_analysis_id(analysisId);
      const analysisDate = moment(analysisData.analysis.date).format('YYYY-MM-DD');
      const todayDate = moment().format('YYYY-MM-DD');

      if (analysisDate !== todayDate) {
        message.error(`Price Analysis of ${analysisDate} cannot be set for today (${todayDate})`);
        navigate('/price-analysis');
        return;
      }

      const purchaseProductIds = analysisData.purchase.map((item) => item.productId);
      const saleProductIds = analysisData.sale.map((item) => item.productId);

      const uniqueProductIds = [...new Set([...purchaseProductIds, ...saleProductIds])];
      await ProductsDB.addProductsIfAbsent(uniqueProductIds);

      const purchaseDataPromise = analysisData.purchase.map(async (item) => {
        const product = await getProductById(item.productId);
        const unitName = await getUnit(item.unitId);
        const category = await getCategoryById(product.categoryId);

        return {
          productId: item.productId,
          productName: product.name,
          price: item.todayPrice,
          unit: unitName.shortName,
          category: category.name
        };
      });

      const saleDataPromise = analysisData.sale.map(async (item) => {
        const product = await getProductById(item.productId);
        const unitName = await getUnit(item.unitId);
        const category = await getCategoryById(product.categoryId);

        return {
          productId: item.productId,
          productName: product.name,
          price: item.todayPrice,
          unit: unitName.shortName,
          category: category.name
        };
      });

      const purchaseData = await Promise.all(purchaseDataPromise);
      const saleData = await Promise.all(saleDataPromise);

      const dateString = moment(analysisData.analysis.date).format('YYYY-MM-DD');
      const nepaliDate = convert_string_to_nepali_date_string(dateString);
      return { b2bExportData: purchaseData, outletExportData: saleData, date: nepaliDate };
    } catch (error) {
      getErrorMessage(error, true);
    } finally {
      setIsLoading(false);
    }
  }
  async function handleUpdatePrice() {
    const values = await form.validateFields();

    try {
      setIsLoading(true);
      message.info('Generating Price List Image...');
      if (!b2bListRef.current || !outletListRef.current) {
        throw new Error('Failed to generate price list image');
      }

      const b2bFormData = await generateImageFormData(b2bListRef.current);
      const outletFormData = await generateImageFormData(outletListRef.current);

      const [b2bMedia, outletMedia] = await Promise.all([
        uploadImagesFormData(b2bFormData),
        uploadImagesFormData(outletFormData)
      ]);

      message.destroy();
      message.info('Images has been generated successfully. Updating prices...');

      if (values.priceGroupIds.includes(-1)) {
        values.priceGroupIds = priceGroups
          .filter((item) => item.value !== -1)
          .map((item) => item.id);
      }

      await update_price_group_price_by_price_analysis({
        ...values,
        analysisId,
        dailyRateB2bMediaId: b2bMedia.id,
        dailyRateOutletMediaId: outletMedia.id
      });

      message.destroy();
      message.info('Price for the selected price groups has been updated successfully');
      navigate('/price-analysis');
    } catch (error) {
      getErrorMessage(error, true);
    } finally {
      setIsLoading(false);
    }
  }

  async function handleTypeChange(type: 'B2B' | 'OUTLET') {
    try {
      setIsLoading(true);
      form.setFieldsValue({ priceGroupIds: [] });
      const priceGroups =
        type === 'B2B'
          ? await get_price_groups_list()
          : await get_price_groups_list({ locationType: LocationType.Outlet });

      // Add Location to Price Groups
      const priceGroupsWithLocation = await Promise.all(
        priceGroups.results.map(async (item) => {
          let location = 'Other';
          if (item.locationId) {
            const locationDetails = await getLocationByList(item.locationId);
            location = locationDetails.name;
          }

          return { ...item, name: `${item.name} (${location})` };
        })
      );

      const updatedPriceGroups = [
        { label: 'All', value: -1, id: -1 },
        ...priceGroupsWithLocation.map((item) => ({
          label: item.name,
          value: item.id,
          id: item.id
        }))
      ];

      setPriceGroups(updatedPriceGroups);
      setIsLoading(false);
    } catch (error) {
      getErrorMessage(error, true);
    } finally {
      setIsLoading(false);
    }
  }

  function handlePriceGroupChange(selectedValues: number[]) {
    const lastValue = selectedValues[selectedValues.length - 1];
    if (lastValue === -1) {
      form.setFieldValue('priceGroupIds', [-1]);
    } else {
      form.setFieldValue(
        'priceGroupIds',
        selectedValues.filter((value) => value !== -1)
      );
    }
  }

  return (
    <Spin spinning={isLoading}>
      <AppContent breadcrumbItems={breadcrumbs}>
        <Alert
          closable
          showIcon
          type="info"
          message="Information"
          description={
            <ul className="pl-0">
              <li>The prices to update come from the selected price analysis.</li>
              <li>
                For valid products, old prices in the price group will be removed and replaced with
                new prices (other prices stay the same).
              </li>
              <li>If today's price is 0, it will not be updated.</li>
              <li>The new price is based on what user has selected. Either B2B or OUTLET.</li>
              <li>
                The image will be generated for both B2B and Outlet from selected price analysis.
              </li>
            </ul>
          }
        />

        <div className="mt-2">
          <Form form={form} layout="vertical">
            <div className="space-y-4">
              <div className="max-w-sm">
                <Form.Item
                  label="Type"
                  name={['type']}
                  rules={[{ required: true, message: 'Please select type!' }]}>
                  <Select placeholder="Select a type!" onChange={handleTypeChange}>
                    <Select.Option value="B2B">B2B</Select.Option>
                    <Select.Option value="OUTLET">OUTLET</Select.Option>
                  </Select>
                </Form.Item>
              </div>

              <Form.Item
                label="Price Group"
                name="priceGroupIds"
                rules={[{ required: true, message: 'Please select price group!' }]}>
                <Select
                  placeholder="Select a price group!"
                  mode="multiple"
                  showSearch
                  optionFilterProp="label"
                  dropdownMatchSelectWidth={false}
                  onChange={handlePriceGroupChange}
                  options={priceGroups.map((priceGroup) => ({
                    label: priceGroup.label,
                    value: priceGroup.id
                  }))}
                />
              </Form.Item>
            </div>

            <div className="mt-4">
              <Button type="primary" onClick={handleUpdatePrice}>
                Update
              </Button>
            </div>
          </Form>
        </div>

        <div>
          <div style={{ position: 'absolute', top: '-9999px', left: '-9999px' }}>
            <PriceList data={data?.b2bExportData || []} date={data?.date || ''} ref={b2bListRef} />
          </div>

          <div style={{ position: 'absolute', top: '-9999px', left: '-9999px' }}>
            <PriceList
              data={data?.outletExportData || []}
              date={data?.date || ''}
              ref={outletListRef}
              isOutlet
            />
          </div>
        </div>
      </AppContent>
    </Spin>
  );
}

export default UpdatePriceGroupByAnalysis;
