import { MinusCircleOutlined } from '@ant-design/icons';
import { useMutation } from '@tanstack/react-query';
import {
  Form,
  Input,
  Button,
  PageHeader,
  message,
  Checkbox,
  Select,
  InputNumber,
  DatePicker
} from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';

import { useNavigate } from 'react-router';
import AppContent from '@/components/Common/Content/Content';

import { IproductlisttypeforOffer } from '@/services/offfers/types';
import { create_offer_mutation } from '@/services/offfers/mutations';

import { get_product_list } from '@/services/products/queries';
import ProductsDB from '@/store/localstorage/ProductsDB';
import useDebounce from '@/hooks/useDebounce';
import ProductSearchForOffer from '@/components/Common/ProductSearch2/ProductSearchForOffer';
import { IProductType } from '@/services/products/types';

const { Option } = Select;
const { RangePicker } = DatePicker;

const CreateOffer = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [productList, setProductList] = useState<IProductType[]>([]);
  const [isloading, setIsloading] = useState<boolean>(false);
  const [discounttype, setDiscounttype] = useState<string>('');
  const [searchValue] = useState<string>('');
  const [productSearch, setProductSearch] = useState<any[]>([]);

  const debouncedSearchValue = useDebounce(searchValue, 500);
  useEffect(() => {
    searchProduct(debouncedSearchValue);
  }, [debouncedSearchValue]);

  const getDataFromLC = async () => {
    const data: IproductlisttypeforOffer[] =
      (await ProductsDB.getAllProducts()) as IproductlisttypeforOffer[];
    if (data.length > 0) {
      setProductList(data as IProductType[]);
    } else {
      const response = await get_product_list();
      setProductList(response.data.results);
      ProductsDB.addProducts(response.data.results);
    }
  };
  const breadcrumbItems = [
    { label: 'Offer', link: '/offer' },
    { label: 'Create', link: '/offer/new' }
  ];

  const createOfferMutation = useMutation(create_offer_mutation, {
    onSuccess: () => {
      message.success('Offer added successfully');
      setIsloading(false);
      navigate('/offer');
    },
    onError: (e: any) => {
      setIsloading(false);
      message.error(`${e.response.data.message}`, 5);
    }
  });
  const onFinish = (values: any) => {
    setIsloading(true);
    values = {
      ...values,
      endDate: values.startDate[1].utc().format(),
      startDate: values.startDate[0].utc().format()
    };
    // console.log('Values--->', values);
    createOfferMutation.mutate(values);
  };
  useEffect(() => {
    getDataFromLC();
    // form.setFieldValue('isActive', false);
  }, []);

  // console.log('Productlist--->', productList);

  const onDiscounttypechange = () => {
    const selected = form.getFieldValue(['Discount', 'type']);
    setDiscounttype(selected);
    console.log('selected', selected);
  };

  const searchProduct = async (value: any) => {
    if (value != '') {
      let response;
      try {
        response = await get_product_list(0, 5, value);
      } catch (e) {
        console.log(e);
      }
      if (!response || response.data.results.length == 0) {
        message.error('Cannot find any product with that name!');
        setProductSearch([]);
      } else {
        setProductSearch(response.data.results);
        checkProductAndAdd(response.data.results);
      }
    }
    // setSearchLoading(false);
  };
  const options = productSearch.map((d) => (
    <Option key={d.id} value={d.id}>
      {d.name}
    </Option>
  ));

  const checkProductAndAdd = (products: any[]) => {
    if (productList.length > 0) {
      products = products.filter((value) => {
        const searchProduct = productSearch.find((val) => val.id == value.id);
        if (searchProduct) return false;
        return true;
      });
      if (products.length > 0)
        setProductList((prevValue) => {
          return [...prevValue, ...products];
        });
    } else {
      setProductList(products);
    }
  };

  return (
    <div>
      <AppContent breadcrumbItems={breadcrumbItems}>
        <Form
          form={form}
          initialValues={{
            wholeDiscount: {
              ceilAmount: 0,
              flat: 0,
              minimumAmount: 0,
              percentage: 0,
              type: 'percentage'
            },
            // productDiscount: [
            //   {
            //     productId: undefined,
            //     minimumQuantity: 0,
            //     minimumAmount: 0,
            //     percentage: 0,
            //     flat: 0,
            //     type: 'percentage'
            //   }
            // ],
            isActive: true
          }}
          onFinish={onFinish}
          disabled={isloading}
          layout="vertical"
          validateTrigger={'onChange'}
          autoComplete="off">
          <PageHeader
            title="Offer Information"
            style={{
              padding: '8px 0px'
            }}
          />
          <div className="grid grid-cols-2 gap-5 mb-5">
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: 'Please add name!' }]}>
              <Input />
            </Form.Item>
            <Form.Item
              label="Code"
              name="code"
              rules={[{ required: true, message: 'Please add name!' }]}>
              <Input />
            </Form.Item>
          </div>

          <div className="grid grid-cols-2 gap-5 mb-5">
            <Form.Item label="Select Discount Type" name={['Discount', 'type']}>
              <Select
                onChange={onDiscounttypechange}
                placeholder={'Select Whole Discount/Product Discount'}>
                <Option key={'wholediscount'} value={'wholediscount'}>
                  Whole Discount
                </Option>
                <Option key={'productdiscount'} value={'productdiscount'}>
                  productdiscount
                </Option>
              </Select>
            </Form.Item>
          </div>

          {discounttype === '' ? (
            <></>
          ) : discounttype === 'wholediscount' ? (
            <>
              <PageHeader
                subTitle="Whole Discount"
                style={{
                  padding: '8px 0px'
                }}
              />
              <div className="grid grid-cols-2 gap-5 mb-5">
                <Form.Item label="Type" name={['wholeDiscount', 'type']}>
                  <Select defaultValue={'percentage'}>
                    <Option key={'percentage'} value={'percentage'}>
                      Percentage
                    </Option>
                    <Option key={'flat'} value={'flat'}>
                      Flat
                    </Option>
                  </Select>
                </Form.Item>
              </div>
              <div className={'grid grid-cols-2 md:grid-cols-4 gap-5 mb-3'}>
                <Form.Item name={['wholeDiscount', 'minimumAmount']} label="Min. Amount">
                  <InputNumber controls={false} min={0} defaultValue={0} />
                </Form.Item>
                <Form.Item name={['wholeDiscount', 'ceilAmount']} label="Ceil Amount">
                  <InputNumber controls={false} min={0} defaultValue={0} />
                </Form.Item>
                <Form.Item name={['wholeDiscount', 'percentage']} label="Percentage">
                  <InputNumber controls={false} min={0} defaultValue={0} />
                </Form.Item>
                <Form.Item name={['wholeDiscount', 'flat']} label="Flat">
                  <InputNumber controls={false} min={0} defaultValue={0} />
                </Form.Item>
              </div>
            </>
          ) : (
            <>
              <PageHeader
                subTitle="Product Discount"
                style={{
                  padding: '8px 0px'
                }}
              />
              <Form.List name="productDiscount">
                {(fields, { add, remove }, { errors }) => (
                  <>
                    <div className={'grid mb-5'}>
                      <PageHeader
                        subTitle="Add Product"
                        style={{
                          padding: '8px 0px 8px 10px'
                        }}
                      />{' '}
                      <ProductSearchForOffer add2={add} />
                      {fields.length > 0 && (
                        <PageHeader
                          title="All Products"
                          style={{
                            padding: '8px 0px 8px 10px'
                          }}
                        />
                      )}
                    </div>
                    {fields.map(({ key, name, ...restField }) => (
                      <div key={key} className="card">
                        <div className={'grid grid-cols-2 gap-5 mb-5'}>
                          {/* <Form.Item {...restField} label="Product" name={[name, 'productId']}>
                            <Select defaultValue={'none'}>
                              <Option key={'none'} value={undefined}>
                                None
                              </Option>
                              {productList?.map((product: Product) => (
                                <Option key={product.id} value={product.id}>
                                  {product.name}
                                </Option>
                              ))}
                            </Select> */}
                          <Form.Item {...restField} name={[name, 'productName']} label="Name">
                            <Input type={'text'} disabled />
                          </Form.Item>

                          <Form.Item {...restField} label="Type" name={[name, 'type']}>
                            <Select defaultValue={'percentage'}>
                              <Option key={'percentage'} value={'percentage'}>
                                Percentage
                              </Option>
                              <Option key={'flat'} value={'flat'}>
                                Flat
                              </Option>
                            </Select>
                          </Form.Item>
                        </div>
                        <div className={'grid grid-cols-2 md:grid-cols-4 gap-3 mb-5'}>
                          <Form.Item
                            {...restField}
                            name={[name, 'minimumQuantity']}
                            label="Min. Quantity">
                            <InputNumber controls={false} min={0} defaultValue={0} />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, 'minimumAmount']}
                            label="Min. Amount">
                            <InputNumber controls={false} min={0} defaultValue={0} />
                          </Form.Item>

                          <Form.Item {...restField} name={[name, 'percentage']} label="Percentage">
                            <InputNumber controls={false} min={0} defaultValue={0} />
                          </Form.Item>
                          <Form.Item {...restField} name={[name, 'flat']} label="Flat">
                            <InputNumber controls={false} min={0} defaultValue={0} />
                          </Form.Item>
                        </div>

                        <div className="flex justify-center items-center">
                          {fields.length > 0 ? (
                            <MinusCircleOutlined
                              className="dynamic-delete-button flex justify-center items-center"
                              onClick={() => remove(name)}
                            />
                          ) : null}
                        </div>
                      </div>
                    ))}
                    {/* <Form.Item>
                      <Button
                        type="primary"
                        onClick={() =>
                          add({
                            productId: undefined,
                            type: 'percentage',
                            minimumQuantity: 0,
                            minimumAmount: 0,
                            percentage: 0,
                            flat: 0
                          })
                        }
                        icon={<PlusOutlined />}>
                        Add Product
                      </Button>

                      <Form.ErrorList errors={errors} />
                    </Form.Item> */}
                  </>
                )}
              </Form.List>
            </>
          )}
          {/* <PageHeader
            subTitle="Whole Discount"
            style={{
              padding: '8px 0px'
            }}
          /> */}
          {/* <div className="grid grid-cols-2 gap-5 mb-5"> */}
          {/* <Form.Item label="Category" name={'offerCategoryId'}>
              <Select defaultValue={'none'}>
                <Option key={'none'} value={undefined}>
                  None
                </Option>
                {categoryList?.data.results.map((category: ICategory) => (
                  <Option key={category.id} value={category.id}>
                    {category.name}
                  </Option>
                ))}
              </Select>
            </Form.Item> */}
          {/* <Form.Item label="Type" name={['wholeDiscount', 'type']}>
              <Select defaultValue={'percentage'}>
                <Option key={'percentage'} value={'percentage'}>
                  Percentage
                </Option>
                <Option key={'flat'} value={'flat'}>
                  Flat
                </Option>
              </Select>
            </Form.Item>
          </div> */}

          {/* <div className={responsize ? 'grid grid-cols-2 gap-4' : 'grid grid-cols-4 gap-5 mb-5'}>
            <Form.Item name={['wholeDiscount', 'minimumAmount']} label="Min. Amount">
              <InputNumber controls={false} min={0} defaultValue={0} />
            </Form.Item>
            <Form.Item name={['wholeDiscount', 'ceilAmount']} label="Ceil Amount">
              <InputNumber controls={false} min={0} defaultValue={0} />
            </Form.Item>
            <Form.Item name={['wholeDiscount', 'percentage']} label="Percentage">
              <InputNumber controls={false} min={0} defaultValue={0} />
            </Form.Item>
            <Form.Item name={['wholeDiscount', 'flat']} label="Flat">
              <InputNumber controls={false} min={0} defaultValue={0} />
            </Form.Item>
          </div> */}
          {/* <PageHeader
            subTitle="Product Discount"
            style={{
              padding: '8px 0px'
            }}
          />
          <Form.List name="productDiscount">
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <div key={key} className="card">
                    <div className={'grid grid-cols-2 gap-5 mb-5'}>
                      <Form.Item {...restField} label="Product" name={[name, 'productId']}>
                        <Select defaultValue={'none'}>
                          <Option key={'none'} value={undefined}>
                            None
                          </Option>
                          {productList?.map((product: Product) => (
                            <Option key={product.id} value={product.id}>
                              {product.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>

                      <Form.Item {...restField} label="Type" name={[name, 'type']}>
                        <Select defaultValue={'percentage'}>
                          <Option key={'percentage'} value={'percentage'}>
                            Percentage
                          </Option>
                          <Option key={'flat'} value={'flat'}>
                            Flat
                          </Option>
                        </Select>
                      </Form.Item>
                    </div>
                    <div
                      className={
                        responsize ? 'grid grid-cols-2 gap-5 mb-5' : 'grid grid-cols-4 gap-3 mb-5'
                      }>
                      <Form.Item
                        {...restField}
                        name={[name, 'minimumQuantity']}
                        label="Min. Quantity">
                        <InputNumber controls={false} min={0} defaultValue={0} />
                      </Form.Item>
                      <Form.Item {...restField} name={[name, 'minimumAmount']} label="Min. Amount">
                        <InputNumber controls={false} min={0} defaultValue={0} />
                      </Form.Item>

                      <Form.Item {...restField} name={[name, 'percentage']} label="Percentage">
                        <InputNumber controls={false} min={0} defaultValue={0} />
                      </Form.Item>
                      <Form.Item {...restField} name={[name, 'flat']} label="Flat">
                        <InputNumber controls={false} min={0} defaultValue={0} />
                      </Form.Item>
                    </div>

                    <div className="flex justify-center items-center">
                      {fields.length > 0 ? (
                        <MinusCircleOutlined
                          className="dynamic-delete-button flex justify-center items-center"
                          onClick={() => remove(name)}
                        />
                      ) : null}
                    </div>
                  </div>
                ))}
                <Form.Item>
                  <Button
                    type="primary"
                    onClick={() =>
                      add({
                        productId: undefined,
                        type: 'percentage',
                        minimumQuantity: 0,
                        minimumAmount: 0,
                        percentage: 0,
                        flat: 0
                      })
                    }
                    icon={<PlusOutlined />}>
                    Add Product
                  </Button>

                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </>
            )}
          </Form.List> */}

          <div className="grid grid-cols-2 gap-5 mb-5">
            <Form.Item
              name={'startDate'}
              label="Duration"
              rules={[{ required: true, message: 'Please add duration!' }]}>
              <RangePicker
                showTime={{
                  hideDisabledOptions: true,
                  defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('11:59:59', 'HH:mm:ss')]
                }}
                format="YYYY-MM-DD HH:mm:ss"
              />
            </Form.Item>
            <Form.Item name="isActive" valuePropName="checked">
              <Checkbox>Active?</Checkbox>
            </Form.Item>
          </div>

          <div className="flex justify-end mt-5">
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={isloading}>
                Submit
              </Button>
            </Form.Item>
            <Button
              type="default"
              className="ml-5"
              htmlType="button"
              onClick={() => navigate('/products/category')}>
              Cancel
            </Button>
          </div>
        </Form>
      </AppContent>
    </div>
  );
};

export default CreateOffer;
