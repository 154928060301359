import AppContent from '@/components/Common/Content/Content';
import { ConvertObjectToURL } from '@/utils/converturl';
import getErrorMessage from '@/utils/getError';
import handlePagination from '@/utils/handlePagination';
import { Form, Input, Popover, Select } from 'antd';
import { useState } from 'react';
import Table from './Table';
import TableFilter from '@/components/FliterTable';
import moment from 'moment';
import CustomButton from '@/components/Common/CustomButton/CustomButton';
import UserSearchV2 from '@/components/Common/CustomSearch/Users';
import { get_feature_request_list } from '@/services/supports/queries';
import { IFeatureRequestListTable } from '@/services/supports/types';
import UsersDB from '@/store/localstorage/UsersDB';
import { getUser } from '@/services';
import { EFeatureRequestProgress } from '@/services/supports/enum';
import { InfoCircleOutlined } from '@ant-design/icons';

function FeatureRequestList() {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(true);
  const [pagination, setPagination] = useState({ page: 1, size: 100 });
  const [data, setData] = useState<IFeatureRequestListTable>({ total: 0, results: [] });

  const checkByCompletedAt = Form.useWatch('checkByCompletedAt', form);
  const isCheckByCompletedAt = checkByCompletedAt === 'YES';

  async function onSubmitFilter(filter: string) {
    const data = await getInfo(filter);
    setPagination({ page: 1, size: 100 });
    return data;
  }

  async function getInfo(filter = '') {
    try {
      setIsLoading(true);
      const query = new URLSearchParams(filter);
      const checkByCompletedAt = query.get('checkByCompletedAt');
      if (checkByCompletedAt !== 'YES') {
        query.delete('startDateNepali');
        query.delete('endDateNepali');
        query.delete('dateCustom');
        query.delete('startDate');
        query.delete('endDate');
      }

      const response = await get_feature_request_list(query.toString());
      const allRequestById = response.results.map((a) => a.requestedBy);
      await UsersDB.addUsersIfAbsent(allRequestById);

      const updatedResponsePromise = response.results.map(async (data) => {
        const requestedBy = await getUser(data.requestedBy);
        return {
          ...data,
          requestedByName: requestedBy.name,
          medias: []
        };
      });

      const updatedResponse = { ...response, results: await Promise.all(updatedResponsePromise) };
      setData(updatedResponse);
      return updatedResponse;
    } catch (error) {
      getErrorMessage(error, true);
    } finally {
      setIsLoading(false);
    }
  }

  const onPagination = async (page = 1, size = 100, isSize = false) => {
    setIsLoading(true);
    const { values, ...pagination } = handlePagination({
      formValues: form.getFieldsValue(),
      page,
      size,
      isSize
    });

    setPagination((prev) => {
      prev.page = pagination.page;
      if (isSize) prev.size = pagination.size;
      return prev;
    });

    const url = ConvertObjectToURL(values);
    return await getInfo(url);
  };

  return (
    <div>
      <AppContent
        breadcrumbItems={[{ label: 'Support' }, { label: 'Feature Request' }]}
        withfilter
        button={
          <TableFilter
            disabledDate={!isCheckByCompletedAt}
            initial
            form={form}
            onInitialLoad={({ data, pagination }) => {
              setPagination(pagination);

              if (data) {
                setData(data);
                setIsLoading(false);
              }
            }}
            buttons={
              <>
                <CustomButton
                  text="Request New Feature"
                  backgroundColor="#1890ff"
                  Linkto="/support/feature-request/new"
                />
              </>
            }
            onSubmit={onSubmitFilter}
            defaultValues={{
              dateCustom: [moment(0, 'HH'), moment(0, 'HH').add(1, 'days')],
              value: '',
              skip: 0,
              count: 100,
              status: EFeatureRequestProgress.PENDING,
              requestedBy: '',
              checkByCompletedAt: 'NO'
            }}
            styleforbuttons={'flex justify-end items-center'}>
            <Form.Item
              className="label-w-full"
              name="checkByCompletedAt"
              label={
                <div className="flex justify-between items-center w-full">
                  <div>Filter by Completion Date?</div>
                  <div className="block">
                    <Popover
                      placement="left"
                      overlayClassName="w-[400px]"
                      title="Completion Date"
                      content={
                        <span>
                          Select <b>'Yes'</b> to filter using the completion date. Select{' '}
                          <b>'No'</b> to filter using the creation date.{' '}
                          <div>Above date filter will only be applied if you select Yes.</div>
                        </span>
                      }>
                      <InfoCircleOutlined style={{ color: '#1890ff', cursor: 'pointer' }} />
                    </Popover>
                  </div>
                </div>
              }>
              <Select
                placeholder="Select"
                allowClear
                dropdownMatchSelectWidth={false}
                onChange={(value) => {
                  if (value === 'YES') {
                    form.setFieldsValue({
                      status: EFeatureRequestProgress.COMPLETED
                    });
                  }
                }}>
                <Select.Option value={'YES'}>Yes</Select.Option>
                <Select.Option value={'NO'}>No</Select.Option>
              </Select>
            </Form.Item>

            <UserSearchV2
              hasParentFormItem={false}
              name="requestedBy"
              label="Requested By"
              showAll
            />
            <Form.Item name="status" label="Status">
              <Select placeholder="Select Status" allowClear dropdownMatchSelectWidth={false}>
                <Select.Option value={''} key={'none'}>
                  All
                </Select.Option>
                {Object.keys(EFeatureRequestProgress).map((key) => {
                  const value =
                    EFeatureRequestProgress[key as keyof typeof EFeatureRequestProgress];

                  return (
                    <Select.Option value={value} key={key}>
                      {value}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>

            <Form.Item name="value" label="Description">
              <Input />
            </Form.Item>
          </TableFilter>
        }>
        <Table
          form={form}
          data={data}
          isLoading={isLoading}
          pagination={pagination}
          getInfo={getInfo}
          onPagination={onPagination}
        />
      </AppContent>
    </div>
  );
}

export default FeatureRequestList;
