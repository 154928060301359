import { Button, Divider, Form, message, Select } from 'antd';
import React, { useState, useEffect } from 'react';
import useDebounce from '@/hooks/useDebounce';
import { get_product_active_list_only, get_product_list_only } from '@/services/products/queries';
import ProductsDB from '@/store/localstorage/ProductsDB';
import { IProductDetails } from '@/services/products/types';
import { getUserData } from '@/utils/auth.utils';
import getErrorMessage from '@/utils/getError';
import { NamePath } from 'antd/lib/form/interface';

interface Iprops {
  formData?: {
    formName: string | (string | number)[];
    label?: string;
  };
  locationId?: number;
  onProductChange?: (val: number, name?: NamePath) => any;
  productSearch: IProductDetails[];
  setProductSearch: React.Dispatch<React.SetStateAction<IProductDetails[]>>;
  disabled?: boolean;
  required?: boolean;
  noall?: boolean;
  productCategory?: number | string | null;
  showHidden?: boolean;
}

const ProductSearch2 = ({
  formData,
  onProductChange,
  productSearch,
  locationId,
  setProductSearch,
  disabled = false,
  required = false,
  noall = false,
  productCategory,
  showHidden
}: Iprops) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const debouncedSearchValue = useDebounce(searchValue, 500);
  const [isCacheResponse, setIsCacheResponse] = useState<boolean>(false);
  const { Option } = Select;
  const [skip, setSkip] = useState<number>(0);
  const [isMore, setIsMore] = useState<boolean>(true);

  async function fetchProducts(skip = 0, count = 1000, value = '', filter = '') {
    const params = new URLSearchParams(filter);
    if (locationId) params.set('locationId', locationId.toString());
    if (productCategory && typeof productCategory === 'number')
      params.set('categoryId', productCategory.toString());

    const updatedFilter = params.toString();
    return showHidden
      ? get_product_list_only(skip, count, value, updatedFilter)
      : get_product_active_list_only(skip, count, value, updatedFilter);
  }

  async function fetchProductsViaIndexDB(name = '', limit = 10) {
    return showHidden
      ? ProductsDB.searchProduct(name, limit)
      : ProductsDB.searchActiveProduct(name, limit, locationId);
  }

  if (!locationId) {
    const { preferences } = getUserData();
    locationId = preferences?.preferences
      ? JSON.parse(preferences?.preferences)?.locationId
      : message.error('Please choose a location from preferences!');
  }

  useEffect(() => {
    searchProductIndexDB(debouncedSearchValue);
  }, [debouncedSearchValue]);

  useEffect(() => {
    searchProductIndexDB(debouncedSearchValue);
  }, [productCategory]);

  const searchProductIndexDB = async (value: string) => {
    try {
      const response = await fetchProductsViaIndexDB(value);
      if (!response || response.length == 0) {
        message.info('Cannot find any product with that name in cache, searching in server...');
        searchProduct(value);
      } else {
        setProductSearch(response);
        setIsCacheResponse(true);
      }
    } catch (error) {
      message.error(error as string);
    }
  };
  const searchProduct = async (value: string) => {
    let response;
    try {
      response = await fetchProducts(0, 10, value);
    } catch (e) {
      getErrorMessage(e);
    }

    if (!response || response.length == 0) {
      message.error('Cannot find any product with that name!');
      setProductSearch([]);
      setIsMore(false);
    } else {
      setProductSearch(response);
      // checkProductAndAdd(response);
      ProductsDB.addProducts(response);
      if (response.length < 10) {
        setIsMore(false);
      } else {
        setIsMore(true);
      }
    }
    setIsCacheResponse(false);
    setSkip(10);

    // setSearchLoading(false);
  };

  const searchMoreProduct = async (value: string) => {
    let response;
    try {
      response = await fetchProducts(skip, 10, value);
    } catch (e) {
      getErrorMessage(e);
    }
    if (!response || response.length == 0) {
      message.info('Cannot find more products with that value in server!');
      setIsMore(false);
    } else {
      setSkip(skip + 10);
      setProductSearch([...productSearch, ...response]);
      ProductsDB.addProducts(response);

      if (response.length < 10) {
        setIsMore(false);
      }
    }
  };

  const options = productSearch.map((d) => (
    <Option key={d.id} value={d.id}>
      <div
        onClick={() => {
          message.destroy('product_missing_default_unit');
          const hasDefaultUnit = d.productUnits?.find((unit) => unit.isDefault);
          if (!hasDefaultUnit) {
            message.warning({
              key: 'product_missing_default_unit',
              content: 'This product does not have a default unit.',
              duration: 5
            });
          }
        }}>{`${d.name} (${d.sku})`}</div>
    </Option>
  ));

  return (
    <>
      {formData ? (
        <Form.Item
          name={formData.formName}
          label={formData.label ? formData.label : 'Product'}
          rules={[
            {
              required: required,
              message: 'Please choose a product!'
            }
          ]}
          initialValue={null}>
          <Select
            dropdownMatchSelectWidth={false}
            showSearch
            placeholder={'Search product'}
            defaultActiveFirstOption={false}
            style={{ width: '100%' }}
            autoClearSearchValue={true}
            showArrow={false}
            filterOption={false}
            disabled={disabled}
            onSearch={(val) => {
              setSearchValue(val);
            }}
            onSelect={async (val: number) => {
              await onProductChange?.(val, formData?.formName);
            }}
            notFoundContent={null}
            dropdownRender={(menu) => (
              <>
                {menu}
                <Divider style={{ margin: '8px 0' }} />
                {isCacheResponse ? (
                  <div className="flex flex-col" style={{ padding: '0 8px 4px' }}>
                    <Button
                      type="text"
                      style={{ color: 'blue', width: '100%' }}
                      onClick={() => {
                        searchProduct(searchValue);
                      }}>
                      {'Pull More & Sync'}
                    </Button>
                  </div>
                ) : (
                  <div className="flex flex-col" style={{ padding: '0 8px 4px' }}>
                    {isMore ? (
                      <Button
                        type="text"
                        style={{ color: 'blue', width: '100%' }}
                        onClick={() => {
                          searchMoreProduct(searchValue);
                        }}>
                        {'Get More...'}
                      </Button>
                    ) : (
                      <div style={{ width: '100%', textAlign: 'center' }}>No more data...</div>
                    )}
                  </div>
                )}
              </>
            )}>
            {noall ? <></> : <Option value="">All</Option>}
            {options}
          </Select>
        </Form.Item>
      ) : (
        <Select
          dropdownMatchSelectWidth={false}
          showSearch
          placeholder={'Search product'}
          defaultActiveFirstOption={false}
          style={{ width: '100%' }}
          autoClearSearchValue={true}
          // value={''}
          showArrow={false}
          filterOption={false}
          disabled={disabled}
          // onClick={(val) => {
          //   console.log(val);
          //   console.log('clicked');
          // }}
          onSearch={(val) => {
            setSearchValue(val);
          }}
          onSelect={async (val: number) => {
            await onProductChange?.(val);
          }}
          notFoundContent={null}
          dropdownRender={(menu) => (
            <>
              {menu}
              <Divider style={{ margin: '8px 0' }} />
              {isCacheResponse ? (
                <div className="flex flex-col" style={{ padding: '0 8px 4px' }}>
                  <Button
                    type="text"
                    style={{ color: 'blue', width: '100%' }}
                    onClick={() => {
                      searchProduct(searchValue);
                    }}>
                    {'Pull More & Sync'}
                  </Button>
                </div>
              ) : (
                <div className="flex flex-col" style={{ padding: '0 8px 4px' }}>
                  {isMore ? (
                    <Button
                      type="text"
                      style={{ color: 'blue', width: '100%' }}
                      onClick={() => {
                        searchMoreProduct(searchValue);
                      }}>
                      {'Get More...'}
                    </Button>
                  ) : (
                    <div style={{ width: '100%', textAlign: 'center' }}>No more data...</div>
                  )}
                </div>
              )}
            </>
          )}>
          {noall ? <></> : <Option value="">All</Option>}
          {options}
        </Select>
      )}
    </>
  );
};

export default ProductSearch2;
