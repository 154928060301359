import {
  IOutletPricingByDate,
  IPriceAnalysisDetails,
  IProductsLatestLinePriceQty,
  IProductsLinesAvgQty
} from '@/services/products/types';
import { IRow } from './grid.types';
import { getCategoryById, getProductById, getUnit } from '@/services';
import roundNumber from '@/utils/round.utils';
import { getAllFilterPayload, getBaseUnitMultiplier } from './create/services';
import {
  get_products_lots_sum,
  get_products_lines_latest_price_qty_by_productIds,
  get_products_lines_sum_qty_By_ids
} from '@/services/products/queries';
import { LineType } from '@/services/report/enums';
import ProductsDB from '@/store/localstorage/ProductsDB';
import getGroupedData from '@/utils/getGroupedData';
import { LocationType } from '@/services/locations/enum';

interface IFormatOptions {
  latestStock?: boolean;
}

export async function formatPriceAnalysistoGridRow(
  data: IPriceAnalysisDetails,
  options?: IFormatOptions
) {
  const { purchase: purchaseList, sale: saleList, analysis } = data;
  const analysisDate = analysis.date;

  const purchaseData = purchaseList.map((p) => ({ id: p.productId, unitId: p.unitId }));
  const outletData = saleList.map((p) => ({ id: p.productId, unitId: p.unitId }));

  const combinedPriceAnalysis = [...purchaseData, ...outletData];
  const uniqueProductIds = [...new Set(combinedPriceAnalysis.map((p) => p.id))];

  const productWithMultiplier = await getBaseUnitMultiplier(combinedPriceAnalysis); // Return unique productIds with multiplier
  const filter = getAllFilterPayload(analysisDate, uniqueProductIds);

  const stockQtyPromise = options?.latestStock
    ? get_products_lots_sum(uniqueProductIds, filter.todayDate)
    : Promise.resolve([]);

  const latestStock = await stockQtyPromise;

  const rows: IRow[] = [];
  await ProductsDB.addProductsIfAbsent(uniqueProductIds);
  const cacheCategory: Record<number, string> = {};

  let isStockChanged = false;

  for (const product of productWithMultiplier) {
    const { id: productId, baseUnitMultiplier: multiplier } = product;
    const purchase = purchaseList.find((item) => item.productId === productId);
    const sell = saleList.find((item) => item.productId === productId);

    const unitId = (purchase?.unitId || sell?.unitId) as number;

    const productDetails = await getProductById(productId);
    const unitDetails = await getUnit(unitId);
    const categoryId = productDetails.categoryId;

    let categoryName = '';
    if (!cacheCategory[categoryId]) {
      const category = await getCategoryById(categoryId);
      cacheCategory[categoryId] = category.name;
      categoryName = category.name;
    } else {
      categoryName = cacheCategory[categoryId];
    }

    const purchaseAmount = {
      margin: 0,
      marginPercentage: 0,
      isPriceChanged: false,
      b2bForecast: 0
    };
    const sellAmount = {
      margin: 0,
      marginPercentage: 0,
      isPriceChanged: false,
      isSellRateChanged: false,
      outletForecast: 0
    };

    if (purchase) {
      purchaseAmount.margin = roundNumber(purchase.todayPrice - purchase.purchasePrice);
      purchaseAmount.marginPercentage = purchase?.purchasePrice
        ? roundNumber((purchaseAmount.margin / purchase.purchasePrice) * 100)
        : 0;
      purchaseAmount.isPriceChanged = purchase.yesterdaySellingRate !== purchase.todayPrice;
      purchaseAmount.b2bForecast = roundNumber(purchase.yesterdaySoldQty * purchaseAmount.margin);
    }

    if (sell) {
      sellAmount.margin = roundNumber(sell.todayPrice - sell.purchasePrice);
      sellAmount.marginPercentage = sell?.purchasePrice
        ? roundNumber((sellAmount.margin / sell.purchasePrice) * 100)
        : 0;
      sellAmount.isPriceChanged = sell.purchasePrice !== purchase?.todayPrice;
      sellAmount.isSellRateChanged = sell.yesterdayPrice !== sell.todayPrice;
      sellAmount.outletForecast = roundNumber(sell.yesterdaySoldQty * sellAmount.margin);
    }

    const allUnits = productDetails.productUnits.map((p) => p.unitId);

    const rowData: IRow = {
      // Product Units
      analysisId: analysis.id,
      productId,
      productName: productDetails?.name,
      categoryId: productDetails?.categoryId,
      categoryName,
      productUnits: allUnits || [],
      unitName: unitDetails?.shortName,
      unitId: unitId,

      // Purchase Data
      purchaseId: purchase?.id,
      purchasePrice: purchase?.purchasePrice || 0,
      kalimatiRate: purchase?.kalimatiRate || 0,
      morningPurchase: purchase?.morningPurchase || 0,
      kalimatiSurveyRate: purchase?.kalimatiSurveyRate || '',
      yesterdaySellingRate: purchase?.yesterdaySellingRate || 0,
      yesterdaySoldQty: purchase?.yesterdaySoldQty || 0,
      totalStock: purchase?.totalStock || 0,
      todayPrice: purchase?.todayPrice || 0,
      openingStock: purchase?.openingStock || 0,
      totalOrder: purchase?.totalOrder || 0,
      estimatedData: purchase?.estimatedData || 0,
      b2bForecast: purchaseAmount.b2bForecast,

      // Outlet Data
      sellId: sell?.id,
      sellPurchasePrice: sell?.purchasePrice || 0,
      sellYesterdayPrice: sell?.yesterdayPrice || 0,
      sellTodayPrice: sell?.todayPrice || 0,
      outletForecast: sellAmount.outletForecast,
      sellYesterdaySoldQty: sell?.yesterdaySoldQty || 0,

      // Calculated Purchase Data
      isPriceChanged: purchaseAmount.isPriceChanged,
      marginAmount: purchaseAmount.margin,
      marginPercentage: purchaseAmount.marginPercentage,

      // Calculated Outlet Data
      isPurchasePriceChanged: sellAmount.isPriceChanged,
      isSellRateChanged: sellAmount.isSellRateChanged,
      marginSellAmount: sellAmount.margin,
      marginSellPercentage: sellAmount.marginPercentage,
      totalYesterdaySoldQty: roundNumber(
        (purchase?.yesterdaySoldQty || 0) + (sell?.yesterdaySoldQty || 0)
      )
    };

    const stock = latestStock.find((item) => item.productId === productId);
    if (stock) {
      const prevOpeningStock = rowData.openingStock || 0;
      const currentOpeningStock = parseInt(stock.sumQtyAvailable, 10) / multiplier;

      if (prevOpeningStock !== currentOpeningStock) {
        rowData.openingStock = currentOpeningStock;
        isStockChanged = true;
      }
    }

    rows.push(rowData);
  }

  return { rows, isStockChanged };
}

export const getLatestForEachIdentifier = async (
  productIds: number[],
  dates: { startDate: string; endDate: string },
  identifiers?: LineType[]
) => {
  const data = await get_products_lines_latest_price_qty_by_productIds(
    productIds,
    dates,
    identifiers
  );

  // Group by identifier (LineType)
  const groupedByLineType = getGroupedData(data, 'identifier');
  const latestEntries: IProductsLatestLinePriceQty[] = [];

  for (const key in groupedByLineType) {
    const identifier = key as LineType;
    const identifierData = groupedByLineType[identifier];

    // Group by productId within each identifier
    const dataByProducts = getGroupedData(identifierData, 'productId');
    const latestForIdentifier = Object.values(dataByProducts).map((p) =>
      p.reduce((latest, current) => {
        return new Date(latest.latestCreatedAt) > new Date(current.latestCreatedAt)
          ? latest
          : current;
      })
    );

    latestEntries.push(...latestForIdentifier);
  }

  return latestEntries;
};

export function getIdentifierData(
  data: IProductsLinesAvgQty[],
  productId: number,
  identifier: LineType,
  baseUnitMultiplier = 1
) {
  const product = data.find((p) => p.productId === productId && p.identifier === identifier);
  const price = (product?.avgBaseUnitPrice || 0) * baseUnitMultiplier;
  const quantity = (product?.avgBaseUnitQuantity || 0) / baseUnitMultiplier;
  return { price: roundNumber(price), quantity: roundNumber(quantity) };
}

export function getIdentifierLatestData(
  data: IProductsLatestLinePriceQty[],
  productId: number,
  identifier: LineType,
  baseUnitMultiplier = 1
) {
  const product = data.find((p) => p.productId === productId && p.identifier === identifier);
  const price = (product?.baseUnitPrice || 0) * baseUnitMultiplier;
  const quantity = (product?.baseUnitQuantity || 0) / baseUnitMultiplier;
  return { price: roundNumber(price), quantity: roundNumber(quantity) };
}

export async function fetchProductAllPriceDetails(
  products: { id: number; baseUnitMultiplier: number }[],
  date: string,
  priceAnalysis?: IOutletPricingByDate
) {
  const productIds = products.map((p) => p.id);
  const filter = getAllFilterPayload(date, productIds);

  const [today, morning, stockQty, yesterdayB2BStockQty, yesterdayOutletStockQty] =
    await Promise.all([
      getLatestForEachIdentifier(productIds, filter.todayDate, [LineType.PURCHASE]),
      getLatestForEachIdentifier(productIds, filter.morningDate, [LineType.PURCHASE]),
      get_products_lots_sum(productIds, filter.todayDate),
      get_products_lines_sum_qty_By_ids(productIds, {
        ...filter.yesterdayDate,
        identifiers: [LineType.SALE],
        locationType: LocationType.DC
      }),
      get_products_lines_sum_qty_By_ids(productIds, {
        ...filter.yesterdayDate,
        identifiers: [LineType.SALE],
        locationType: LocationType.Outlet
      })
    ]);

  const absentPurchasePriceProductIds: number[] = [];
  const absentSellPriceProductIds: number[] = [];
  const productDetails: Record<number, Partial<IRow>> = {};

  for (const product of products) {
    const { id: productId, baseUnitMultiplier: multiplier } = product;
    productDetails[productId] = productDetails[productId] || {};

    const purchasePriceAnalysis = priceAnalysis?.purchase || [];
    const sellPriceAnalysis = priceAnalysis?.outlet || [];

    // Today Purchase
    const todayPurchase = getIdentifierLatestData(today, productId, LineType.PURCHASE, multiplier);
    productDetails[productId].purchasePrice = todayPurchase.price;

    //Morning Purchase
    const morningPurchase = getIdentifierLatestData(
      morning,
      productId,
      LineType.PURCHASE,
      multiplier
    );
    productDetails[productId].morningPurchase = morningPurchase.price;

    // Stock Quantity
    const stock = stockQty.find((item) => item.productId === productId);
    if (stock) {
      productDetails[productId].openingStock = parseInt(stock.sumQtyAvailable, 10) / multiplier;
    }

    // Yesterday B2B Stock Quantity
    const yesterdayB2BStock = yesterdayB2BStockQty.find((item) => item.productId === productId);
    if (yesterdayB2BStock) {
      productDetails[productId].yesterdaySoldQty =
        parseInt(yesterdayB2BStock.sumBaseUnitQuantity, 10) / multiplier;
    }

    // Yesterday Outlet Stock Quantity
    const yesterdayOutletStock = yesterdayOutletStockQty.find((i) => i.productId === productId);
    if (yesterdayOutletStock) {
      productDetails[productId].sellYesterdaySoldQty =
        parseInt(yesterdayOutletStock.sumBaseUnitQuantity, 10) / multiplier;
    }

    // Assign YesterdaySellingRate and SellYesterdayPrice
    const productOutletDetails = sellPriceAnalysis.find((p) => p.productId === productId);
    const productPurchaseDetails = purchasePriceAnalysis.find((p) => p.productId === productId);

    if (productPurchaseDetails) {
      productDetails[productId].yesterdaySellingRate = productPurchaseDetails.todayPrice || 0;
    } else {
      absentPurchasePriceProductIds.push(productId);
    }

    if (productOutletDetails) {
      productDetails[productId].sellYesterdayPrice = productOutletDetails.todayPrice || 0;
      productDetails[productId].sellTodayPrice = productOutletDetails.todayPrice || 0;
    } else {
      absentSellPriceProductIds.push(productId);
    }
  }

  const allAbsentProductIds = new Set([
    ...absentPurchasePriceProductIds,
    ...absentSellPriceProductIds
  ]);

  if (allAbsentProductIds.size === 0) {
    return productDetails;
  }

  const absentDetails = await getLatestForEachIdentifier(
    Array.from(allAbsentProductIds),
    filter.yesterdayDate,
    [LineType.SALE, LineType.PURCHASE]
  );

  for (const productId of allAbsentProductIds) {
    const multiplier = products.find((p) => p.id === productId)?.baseUnitMultiplier || 1;
    const isPresentInAbsentPurchasePrice = absentPurchasePriceProductIds.includes(productId);
    const isPresentInAbsentSellPrice = absentSellPriceProductIds.includes(productId);

    if (isPresentInAbsentPurchasePrice) {
      const data = getIdentifierLatestData(absentDetails, productId, LineType.PURCHASE, multiplier);
      productDetails[productId].yesterdaySellingRate = data.price;
    }

    if (isPresentInAbsentSellPrice) {
      const data = getIdentifierLatestData(absentDetails, productId, LineType.SALE, multiplier);
      productDetails[productId].sellYesterdayPrice = data.price;
      productDetails[productId].sellTodayPrice = data.price;
    }
  }

  return productDetails;
}
