import { IRow } from '../grid.types';

export const GRID_MIN_HEIGHT = 280;
export const GRID_ROW_HEIGHT = 35;

export const emptyRow: IRow = {
  productName: '',
  productUnits: [],
  totalYesterdaySoldQty: 0,

  // Outlet Data
  sellPurchasePrice: 0,
  sellYesterdayPrice: 0,
  sellTodayPrice: 0,
  sellYesterdaySoldQty: 0,
  outletForecast: 0,

  // Purchase Data
  purchasePrice: 0,
  kalimatiRate: 0,
  morningPurchase: 0,
  kalimatiSurveyRate: '',
  yesterdaySellingRate: 0,
  yesterdaySoldQty: 0,
  b2bForecast: 0,
  totalStock: 0,
  todayPrice: 0,
  openingStock: 0,
  totalOrder: 0,
  estimatedData: 0,

  // Calculated Purchase Data
  isPriceChanged: false,
  marginAmount: 0,
  marginPercentage: 0,

  // Calculated Outlet Data
  isPurchasePriceChanged: false,
  isSellRateChanged: false,
  marginSellAmount: 0,
  marginSellPercentage: 0
};
