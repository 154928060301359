import { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { Link, useNavigate } from 'react-router-dom';
import { Alert, Button, Form, message, Modal, Spin } from 'antd';

import getErrorMessage from '@/utils/getError';
import AppContent from '@/components/Common/Content/Content';
import LocationSearchV2 from '@/components/Common/CustomSearch/Location';
import { create_lots_clear_mutation } from '@/services/products/mutations';
import { useFilterStore } from '@/store/zustand';
import { ListPage } from '@/constants/list.enum';

function ClearAllStock() {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const zustandFilter = useFilterStore();

  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const clearLotsOfLocation = useMutation(create_lots_clear_mutation, {
    onSuccess: () => {
      message.success('Lots cleared successfully');
      zustandFilter.resetState(ListPage.CLEAR_STOCKS);
      navigate('/products/clear-stocks');
    }
  });

  async function onConfirmation() {
    try {
      setIsConfirmationOpen(false);
      setIsLoading(true);
      const values = (await form.validateFields()) as { locationId: number };
      await clearLotsOfLocation.mutateAsync(values.locationId);
    } catch (error) {
      message.error(getErrorMessage(error));
    } finally {
      setIsLoading(false);
    }
  }

  function onClickClear() {
    setIsConfirmationOpen(true);
  }

  const alertMessage = (
    <span>
      Ensure you have downloaded all the stock data before proceeding with any clearing actions. If
      not, please <Link to="/open-stock-download-upload">download it from here</Link>.
    </span>
  );

  return (
    <Spin spinning={isLoading}>
      <AppContent
        breadcrumbItems={[
          { label: 'Products', link: '/products' },
          { label: 'Clear Stocks', link: '/products/clear-stocks' },
          { label: 'Clear' }
        ]}>
        <Alert
          message="Clear All Stock"
          description={
            <>
              {alertMessage}{' '}
              <span>
                Please note that this process can take between 3-5 minutes. It is highly{' '}
                <span className="text-red-500">not recommended</span> to run it when lots are being
                modified.
              </span>
            </>
          }
        />
        <Modal
          title="Clear All Stock"
          visible={isConfirmationOpen}
          onOk={onConfirmation}
          onCancel={() => setIsConfirmationOpen(false)}>
          {alertMessage}
        </Modal>

        <div className="mt-2 space-y-4">
          <Form form={form} disabled={isLoading} layout="vertical">
            <div className="max-w-sm">
              <LocationSearchV2 hasParentFormItem={false} name={'locationId'} required />
            </div>

            <div className="flex justify-end gap-2">
              <Link to="/products/clear-stocks">
                <Button type="default" className="secondary-button">
                  Go Back
                </Button>
              </Link>

              <Button type="primary" danger onClick={onClickClear}>
                Clear All Stock
              </Button>
            </div>
          </Form>
        </div>
      </AppContent>
    </Spin>
  );
}

export default ClearAllStock;
