import { useEffect, useMemo, useState } from 'react';
import { Modal, Progress, Alert, Tabs, List, Button } from 'antd';
import type { IWooCommerceSocketData } from '@/services/web-sockets/types';
import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  ExclamationCircleTwoTone,
  LoadingOutlined
} from '@ant-design/icons';
import { IProductDetails } from '@/services/products/types';
import ProductsDB from '@/store/localstorage/ProductsDB';

const { TabPane } = Tabs;

type Props = {
  socketData: IWooCommerceSocketData | null;
  visible: boolean;
  onClose: () => void;
  getTotalItems: () => number;
  setLoading: (loading: boolean) => void;
  showTotalItems?: boolean;
  onRetry?: (failedProducts: IProductDetails[]) => Promise<void>;
};

export default function ProductSyncModal({
  socketData,
  visible,
  showTotalItems,
  onClose,
  getTotalItems,
  setLoading,
  onRetry
}: Props) {
  const [error, setError] = useState<string | null>(null);
  const [productMap, setProductMap] = useState<Record<string, IProductDetails>>({});
  const [absentSkus, setAbsentSkus] = useState<Set<string>>(new Set());

  useEffect(() => {
    if (!socketData) return;

    const allSkus = new Set<string>();
    socketData.data.forEach((entry) => {
      entry.productsSku?.forEach((sku) => allSkus.add(sku));
    });

    const alreadyFetched = new Set([...Object.keys(productMap), ...Array.from(absentSkus)]);
    const skusToFetch = Array.from(allSkus).filter((sku) => !alreadyFetched.has(sku));

    if (skusToFetch.length > 0) {
      ProductsDB.getProductBySKU(skusToFetch).then(({ present, absentSkus }) => {
        // Update productMap
        const newMap = { ...productMap };
        present.forEach((p) => {
          newMap[p.sku] = p;
        });
        setProductMap(newMap);
        setAbsentSkus((prev) => new Set([...prev, ...absentSkus]));
      });
    }
  }, [socketData]);

  const successMessages = useMemo(
    () => [...(socketData?.messages.success ?? [])].reverse(),
    [socketData]
  );

  const errorMessages = useMemo(
    () => [...(socketData?.messages.error ?? [])].reverse(),
    [socketData]
  );

  const likelyFailedProducts = useMemo(() => {
    return Object.values(productMap).filter(
      (product) =>
        !successMessages.some((successName) =>
          successName.toLowerCase().includes(product.name.toLowerCase())
        )
    );
  }, [productMap, successMessages]);

  const totalProcessed = successMessages.length + errorMessages.length;
  const totalItems = getTotalItems?.() ?? 0;

  const hasFinished = useMemo(() => {
    return socketData?.data?.some((d) => d.message === 'Finished processing.');
  }, [socketData]);

  const progress = useMemo(() => {
    if (hasFinished) return 100;
    if (!totalItems) return 0;
    return Math.min(Math.round((totalProcessed / totalItems) * 100), 100);
  }, [hasFinished, totalProcessed, totalItems]);

  useEffect(() => {
    const batchWithError = socketData?.data.find(
      (d) => d.wooError && Object.keys(d.wooError).length > 0
    );

    if (batchWithError?.wooError?.message) {
      setError(batchWithError.wooError.message);
      return;
    }

    if (errorMessages.length) {
      const last = errorMessages.at(-1);
      if (typeof last === 'object' && last?.error?.message) {
        setError(last.error.message);
      } else if (typeof last === 'string') {
        setError(last);
      } else {
        setError(JSON.stringify(last));
      }
      return;
    }

    setError(null);
  }, [socketData, errorMessages]);

  useEffect(() => {
    if (!visible || !socketData) return;
    setLoading(!hasFinished);
  }, [socketData, visible, setLoading, hasFinished]);

  return (
    <Modal
      title="Stock Update Progress"
      visible={visible}
      onCancel={onClose}
      footer={null}
      maskClosable={false}
      closable={hasFinished || progress === 100}
      width={700}>
      <div className="space-y-4">
        <div>
          {!hasFinished && <LoadingOutlined style={{ fontSize: 18 }} />}
          <Progress
            percent={progress}
            status={error ? 'exception' : progress === 100 ? 'success' : 'active'}
          />
        </div>

        {error && (
          <Alert
            message="An error occurred during stock update"
            description={
              <div>
                <div>{error}</div>

                {likelyFailedProducts.length > 0 && (
                  <div className="text-[13px] text-gray-500 mt-2">
                    <strong>Possible Failed Products:</strong>
                    <ul className="list-disc pl-5">
                      {likelyFailedProducts.map((p) => (
                        <li key={p.sku}>
                          {p.name} ({p.sku})
                        </li>
                      ))}
                    </ul>
                  </div>
                )}

                {absentSkus.size > 0 && (
                  <div className="text-xs text-yellow-600 mt-2">
                    <strong>Missing SKUs:</strong> {Array.from(absentSkus).join(', ')}
                  </div>
                )}
              </div>
            }
            type="error"
            showIcon={false}
            icon={<ExclamationCircleTwoTone twoToneColor="#ff4d4f" />}
          />
        )}

        {showTotalItems && (
          <div className="flex justify-between items-center text-sm text-gray-600 mt-2">
            <span>Items Processed:</span>
            <span>
              {totalProcessed} of {totalItems}
            </span>
          </div>
        )}

        <Tabs defaultActiveKey="success">
          <TabPane tab={`Success (${successMessages.length})`} key="success">
            <List
              size="small"
              bordered
              dataSource={successMessages}
              className="max-h-64 overflow-auto"
              renderItem={(item) => (
                <List.Item>
                  <CheckCircleTwoTone twoToneColor="#52c41a" className="mr-2" />
                  {item}
                </List.Item>
              )}
            />
          </TabPane>
          <TabPane tab={`Failed (${errorMessages.length})`} key="failed">
            <List
              size="small"
              bordered
              dataSource={errorMessages}
              className="max-h-64 overflow-auto"
              renderItem={(item) => {
                const msg =
                  typeof item === 'string' ? item : item?.error?.message ?? JSON.stringify(item);
                return (
                  <List.Item>
                    <CloseCircleTwoTone twoToneColor="#ff4d4f" className="mr-2" />
                    {msg}
                  </List.Item>
                );
              }}
            />
          </TabPane>
        </Tabs>

        {errorMessages.length > 0 && onRetry && hasFinished && (
          <div className="flex justify-end pt-2">
            <Button
              type="primary"
              onClick={() => {
                setLoading(true);
                onClose();
                onRetry(likelyFailedProducts);
              }}>
              Retry
            </Button>
          </div>
        )}
      </div>
    </Modal>
  );
}
