import GenericTable from '@/components/Common/CustomizeTable';
import { DEFAULT_DATE_FORMAT } from '@/constants';
import { ESchedularSuccessStatus } from '@/services/schedule/enum';
import { ISchedulerResponse, ISchedulerStatus } from '@/services/schedule/types';
import { convertUTCStringtoLocalString } from '@/utils/convertToUTC';
import { Tag } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import { TableProps } from 'antd/es/table';
import { ColumnsType } from 'antd/lib/table';
import { SorterResult } from 'antd/lib/table/interface';
import { useState } from 'react';

interface Props {
  isLoading?: boolean;
  form: FormInstance;
  data: ISchedulerResponse;
  pagination: { page: number; size: number };
  onPagination: (
    page?: number,
    size?: number,
    isSize?: boolean
  ) => Promise<ISchedulerResponse | undefined>;
}

const color = {
  [ESchedularSuccessStatus.SUCCESS]: 'green',
  [ESchedularSuccessStatus.FAIL]: 'red',
  [ESchedularSuccessStatus.RUNNING]: 'blue'
};

function Table({ isLoading, data, pagination, form, onPagination }: Props) {
  const [sortedInfo, setSortedInfo] = useState<SorterResult<ISchedulerStatus>>({});

  const handleChange: TableProps<ISchedulerStatus>['onChange'] = (_, __, s) => {
    setSortedInfo(s as SorterResult<ISchedulerStatus>);
  };

  const columns: ColumnsType<ISchedulerStatus> = [
    {
      title: 'S.N.',
      key: 'id',
      width: 5,
      sorter: (a, b) => a.id - b.id,
      sortOrder: sortedInfo.columnKey === 'id' ? sortedInfo.order : null,
      render: (_, __, index) => (pagination.page - 1) * pagination.size + index + 1
    },
    {
      title: 'Runned At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 400,
      sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
      sortOrder: sortedInfo.columnKey === 'createdAt' ? sortedInfo.order : null,
      render: (text: string) => convertUTCStringtoLocalString(text, DEFAULT_DATE_FORMAT)
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 5,
      sorter: (a, b) => a.status.localeCompare(b.status),
      sortOrder: sortedInfo.columnKey === 'status' ? sortedInfo.order : null,
      render: (text: ESchedularSuccessStatus) => <Tag color={color[text]}>{text}</Tag>
    }
  ];

  return (
    <GenericTable
      columns={columns}
      data={data.results}
      form={form}
      isLoading={isLoading}
      hideDefaultPagination
      pagination={{ ...pagination, total: data.total, onPagination, scrollToTop: true }}
      toSort={handleChange}
      scroll={{ x: 400 }}
    />
  );
}

export default Table;
