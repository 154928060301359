import moment from 'moment';
import { ILeafyItemList } from '@/services/leafy-items/types';

import { tableExportColumns, tableCurrentExportColumns } from './column.export';
import { useEffect, useState } from 'react';
import ReusableExport from '@/components/Common/ReusableExport';

interface Props {
  data: ILeafyItemList;
  filterBy: 'exact' | 'range';
  getInfo: (filter: string, isExport?: boolean) => Promise<ILeafyItemList | undefined>;
  fullExportURL: string;
}

function ExportLeafy({ data, filterBy, getInfo, fullExportURL }: Props) {
  const [selectedDate, setSelectedDate] = useState('');
  const columns = filterBy === 'exact' ? tableCurrentExportColumns : tableExportColumns;

  useEffect(() => {
    const params = new URLSearchParams(fullExportURL);
    const date = params.get('exactDate');
    const startDate = params.get('startDate');
    const endDate = params.get('endDate');

    if (date) {
      setSelectedDate(date);
      return;
    }

    if (startDate && endDate) {
      const start = moment(startDate).format('YYYY-MM-DD');
      const end = moment(endDate).format('YYYY-MM-DD');
      setSelectedDate(`${start} to ${end}`);
    }
  }, [fullExportURL]);

  return (
    <ReusableExport
      header={[
        {
          value: 'Freshktm Leafy Data Details',
          size: 11,
          bold: true,
          font: 'Tahoma',
          tableBorder: false
        },
        { value: selectedDate, size: 10, bold: false, font: 'Tahoma', tableBorder: true }
      ]}
      fileName="Freshktm Leafy Data Details"
      data={data.result}
      columns={columns}
      formats={['excel', 'pdf']}
      exportType={['current', 'all']}
      fetchData={async () => {
        const response = await getInfo(fullExportURL, true);
        return response?.result || [];
      }}
    />
  );

  // return (
  //   <div className="flex justify-end gap-2">
  //     <ActionDropdown
  //       marginLeft="0px"
  //       button={true}
  //       buttonJSX={
  //         <Button type="primary" className="!rounded-md">
  //           Export All
  //         </Button>
  //       }
  //       menu={
  //         <Menu
  //           items={[
  //             {
  //               key: '1',
  //               onClick: () => handleExportAll('excel'),
  //               label: <div className="text-center">Excel</div>
  //             }
  //           ]}
  //         />
  //       }
  //     />

  //     <ActionDropdown
  //       marginLeft="0px"
  //       button={true}
  //       buttonJSX={
  //         <Button type="primary" className="!rounded-md">
  //           Export
  //         </Button>
  //       }
  //       menu={
  //         <Menu
  //           items={[
  //             {
  //               key: '1',
  //               onClick: () => handleExport('excel'),
  //               label: <div className="text-center">Excel</div>
  //             }
  //           ]}
  //         />
  //       }
  //     />
  //   </div>
  // );
}

export default ExportLeafy;
