import { Button, Form, Input, InputNumber, PageHeader, Select, Spin, message } from 'antd';
import AppContent from '../../../../components/Common/Content/Content';
import { DiscountStatus } from '../../../../services/offfers/enums';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ICreateDiscount, ICustomerGroupListData } from '../../../../services/offfers/types';
import ProductCategorySearch from '../../../../components/Common/ProductCategorySearch/ProductCategorySearch';
import { CustomerGroupSearch } from '../../../../components/Common/CustomerGroupSearch';
import { useMutation, useQuery } from '@tanstack/react-query';
import { create_discount_mutation } from '../../../../services/offfers/mutations';
import CustomErrorModal from '../../../../components/Common/CustomErrorModal';
import { get_category_details, get_category_list } from '../../../../services/category/queries';
import { get_customer_group_details } from '../../../../services/offfers/queries';
import CustomersDB from '../../../../store/localstorage/CustomerDB';
import getErrorMessage from '@/utils/getError';
import { useFilterStore } from '@/store/zustand';
import { ListPage } from '@/constants/list.enum';

const CreateDiscount = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [customerGroupList, setCustomerGroupList] = useState<ICustomerGroupListData[]>([]);

  const zustandFilter = useFilterStore();

  const breadcrumbItems = [
    {
      label: 'Discount',
      link: '/offer/discount'
    },
    {
      label: 'New',
      link: '/offer/discount/new'
    }
  ];

  useQuery(['categoryList'], async () => await fetchCategory());

  const fetchCategory = async () => {
    setIsLoading(true);
    const response = (await get_category_list(0, 1000)).data;
    // console.log('response', response);
    if (response) {
      const data = response.results.map((values) => {
        return {
          categoryId: values.id,
          categoryName: values.name,
          percentage: 0
        };
      });

      form.setFieldValue(['lines'], data);
    }
    setIsLoading(false);
  };

  const onFinish = async (values: ICreateDiscount) => {
    setIsLoading(true);
    // console.log('Values', values);
    try {
      await createCustomerGroupMutation.mutateAsync(values);
    } catch (err: any) {
      setIsLoading(false);
      console.log(err.message);
    }
  };

  const createCustomerGroupMutation = useMutation(create_discount_mutation, {
    onSuccess: () => {
      setIsLoading(false);
      message.success('Discount added successfully');
      zustandFilter.resetState(ListPage.CUSTOMER_GROUP_DISCOUNT);
      navigate('/offer/discount');
    },
    onError: async () => {
      setIsLoading(false);
    }
  });

  return (
    <Spin spinning={isLoading}>
      <AppContent breadcrumbItems={breadcrumbItems} withfilter={false}>
        <Form
          form={form}
          initialValues={{ isActive: DiscountStatus.ACTIVE, percentage: 1 }}
          onFinish={onFinish}
          disabled={isLoading}
          layout="vertical"
          validateTrigger={'onChange'}
          autoComplete="off">
          <PageHeader
            title="Discount Information"
            style={{
              padding: '8px 0px'
            }}
          />
          <div className={'grid grid-cols-1 sm:grid-cols-4 gap-5 mb-5'}>
            <Form.Item
              label="Discount Name"
              name="name"
              rules={[{ required: true, message: 'Please add Discount Name!' }]}>
              <Input />
            </Form.Item>
            <CustomerGroupSearch
              formData={{ formName: 'customerGroupId', label: 'Customer Group' }}
              customerGroupList={customerGroupList}
              setCustomerGroupList={setCustomerGroupList}
              required={true}
              isAll={false}
            />
          </div>
          <div>
            <Form.List name="lines">
              {(fields) => (
                <div>
                  {fields.map((field, index) => (
                    <div className="flex gap-2 items-center" key={field.key}>
                      <span className="font-bold text-sm mb-5">{index + 1}.</span>
                      <div
                        className="card"
                        style={{ borderRadius: '9px', backgroundColor: 'white' }}>
                        <div
                          className={
                            'grid grid-cols-2 gap-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-7 xl:grid-cols-7'
                          }>
                          <Form.Item name={[field.name, 'categoryId']} hidden>
                            <Input />
                          </Form.Item>
                          <Form.Item name={[field.name, 'categoryName']} label={'Category'}>
                            <Input disabled style={{ color: 'black' }} />
                          </Form.Item>
                          <Form.Item
                            label="Percentage"
                            name={[field.name, 'percentage']}
                            rules={[
                              {
                                required: true,
                                pattern: new RegExp('^[0-9][0-9]?$|^100$'),
                                message: 'Can contain only number from 0-100'
                              }
                            ]}>
                            <InputNumber controls={false} min={0} />
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </Form.List>
          </div>
          <div className="flex justify-end mt-5">
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={isLoading}>
                Submit
              </Button>
            </Form.Item>
            <Button
              type="default"
              className="ml-5"
              htmlType="button"
              onClick={() => navigate('/offer/discount')}>
              Cancel
            </Button>
          </div>
        </Form>
      </AppContent>
    </Spin>
  );
};

export default CreateDiscount;
