import moment from 'moment';
import AppContent from '@/components/Common/Content/Content';
import CustomButton from '@/components/Common/CustomButton/CustomButton';
import TableFilter from '@/components/FliterTable';
import { checkAccess } from '@/routes/acl';
import { get_leafy_purchase_wastage_list } from '@/services/leafy-items/queries';
import { ConvertObjectToURL } from '@/utils/converturl';
import getErrorMessage from '@/utils/getError';
import handlePagination from '@/utils/handlePagination';
import { Form, Select } from 'antd';
import { useState } from 'react';
import Table from './Table';
import { ILeafyItemList } from '@/services/leafy-items/types';
import { getLocationByList, getUser } from '@/services';
import UsersDB from '@/store/localstorage/UsersDB';
import LocationSearchV2 from '@/components/Common/CustomSearch/Location';
import UserSearchV2 from '@/components/Common/CustomSearch/Users';
import { convert_string_to_nepali_date_string } from '@/utils/nepaliDateConverter';
import { convertLocalToUTCString } from '@/utils/convertToUTC';
import { find_date_preference } from '@/store/localstorage/preferences';

interface ISavedData {
  fullExportURL?: string;
  data?: ILeafyItemList;
}

function LeaftItemsList() {
  const [form] = Form.useForm();
  const nepaliType = find_date_preference();

  const [isLoading, setIsLoading] = useState(true);
  const [pagination, setPagination] = useState({ page: 1, size: 100 });
  const [data, setData] = useState<ILeafyItemList>({ total: 0, result: [] });

  const [fullExportURL, setFullExportURL] = useState('');

  const filterBy = Form.useWatch('filterBy', form) || 'range';

  async function onSubmitFilter(filter: string) {
    const params = new URLSearchParams(filter);
    const date = params.get('date');
    if (date) {
      params.delete('date');
      params.set('exactDate', date);
    }

    const data = await getInfo(params.toString());
    setPagination({ page: 1, size: 100 });
    params.set('count', String(data?.total));

    const exportURL = params.toString();
    setFullExportURL(data ? exportURL : '');

    return { data, fullExportURL: exportURL };
  }

  async function getInfo(filter = '', isExport = false) {
    try {
      setIsLoading(true);
      const response = await get_leafy_purchase_wastage_list(filter);
      const uniqueUserIds = Array.from(
        new Set(response.result.map((u) => u.createdBy).filter(Boolean))
      );
      await UsersDB.addUsersIfAbsent(uniqueUserIds);

      for (const item of response.result) {
        if (item.locationId) {
          const location = await getLocationByList(item.locationId);
          item.location = location.name || '';
        }

        if (item.createdBy) {
          const user = await getUser(item.createdBy);
          item.createdByName = user.name || '';
        }
      }

      if (!isExport) {
        setData(response);
      }
      return response;
    } catch (error) {
      getErrorMessage(error, true);
    } finally {
      setIsLoading(false);
    }
  }

  const onPagination = async (page = 1, size = 100, isSize = false) => {
    setIsLoading(true);
    const { values, ...pagination } = handlePagination({
      formValues: form.getFieldsValue(),
      page,
      size,
      isSize
    });

    setPagination((prev) => {
      prev.page = pagination.page;
      if (isSize) prev.size = pagination.size;
      return prev;
    });

    const url = ConvertObjectToURL(values);
    const data = await getInfo(url);
    return { data, fullExportURL };
  };

  function handleFilterByChange(value: 'exact' | 'range') {
    const customDateValues = [moment(0, 'HH'), moment(0, 'HH').add(1, 'days')];
    const nepaliDateStart = convert_string_to_nepali_date_string(
      customDateValues[0].format('YYYY-MM-DD')
    );

    const nepaliDateEnd = convert_string_to_nepali_date_string(
      customDateValues[1].format('YYYY-MM-DD')
    );

    const nepaliDate = convert_string_to_nepali_date_string(moment().format());
    const dateFormat = nepaliType ? nepaliDate : moment();

    form.setFieldsValue({
      dateCustom: value === 'range' ? customDateValues : undefined,
      startDateNepali: value === 'range' ? nepaliDateStart : undefined,
      endDateNepali: value === 'range' ? nepaliDateEnd : undefined,
      startDate:
        value === 'range' ? convertLocalToUTCString(customDateValues[0].clone()) : undefined,
      endDate: value === 'range' ? convertLocalToUTCString(customDateValues[0].clone()) : undefined,
      dateSingle: value === 'range' ? undefined : dateFormat,
      date: value === 'range' ? undefined : moment().format('YYYY-MM-DD')
    });
  }

  return (
    <div>
      <AppContent
        breadcrumbItems={[{ label: 'Leafy Items', link: '/leafy-items' }]}
        withfilter
        button={
          <TableFilter
            initial
            form={form}
            useFilterBy
            dateCustom={filterBy === 'range'}
            singleDate={filterBy === 'exact'}
            onInitialLoad={({ data, pagination }) => {
              setPagination(pagination);
              if (data) {
                const typedData = data as ISavedData;
                if (typedData.data) setData(typedData.data);
                if (typedData.fullExportURL) setFullExportURL(typedData.fullExportURL);
                setIsLoading(false);
              }
            }}
            buttons={
              <>
                {checkAccess('CREATE_LEAFY_ITEM') && (
                  <CustomButton text="Add" backgroundColor="#1890ff" Linkto="/leafy-items/new" />
                )}
              </>
            }
            onSubmit={onSubmitFilter}
            defaultValues={{
              dateCustom: [moment(0, 'HH'), moment(0, 'HH').add(1, 'days')],
              locationId: '',
              skip: 0,
              count: 100,
              createdBy: '',
              filterBy: 'range'
            }}
            styleforbuttons={'flex justify-end items-center'}>
            <Form.Item className="label-w-full" name="filterBy" label="Filter By">
              <Select
                placeholder="Select"
                allowClear
                dropdownMatchSelectWidth={false}
                onSelect={handleFilterByChange}>
                <Select.Option value={'range'}>Range</Select.Option>
                <Select.Option value={'exact'}>Exact Date</Select.Option>
              </Select>
            </Form.Item>

            <LocationSearchV2 name="locationId" hasParentFormItem={false} showAll />
            <UserSearchV2 name="createdBy" hasParentFormItem={false} showAll label="Created By" />
          </TableFilter>
        }>
        <Table
          form={form}
          data={data}
          isLoading={isLoading}
          fullExportURL={fullExportURL}
          filterBy={filterBy}
          getInfo={getInfo}
          pagination={pagination}
          onPagination={onPagination}
        />
      </AppContent>
    </div>
  );
}

export default LeaftItemsList;
