import { IProductRow } from '@/services/woocommerce/types';
import { Button, FormInstance, message, Modal } from 'antd';
import { useContext, useEffect, useRef, useState } from 'react';
import { Column, DataGridHandle, RenderCellProps } from 'react-data-grid';
import { emptyRow } from '../constant';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import ReactDataGrid, { Input, InputNumber, ReadOnlyInput } from '@/components/Common/DataGrid';
import ProductSearchForNonForm from '@/components/Common/CustomSearch/Products/ProductSearchFoNonForm';
import { IPriceTypeById, IProductDetails } from '@/services/products/types';
import getErrorMessage from '@/utils/getError';
import { formatToGrid } from '@/services/woocommerce/services';
import { useMutation } from '@tanstack/react-query';
import { create_woo_products } from '@/services/woocommerce/mutation';

import useCache from '@/hooks/useCache';
import { getImageFromServer } from '@/services/upload/queries';
import PreviewImageOnDemand from '@/components/Common/PreviewImageOnDemand';
import MultiProductSyncModal from '../MultiProductSyncModal';
import { SocketEvents, SystemNotificationType } from '@/constants/websocketConfig';
import { IWooCommerceSocketData, IWooCommerceMap } from '@/services/web-sockets/types';
import { WebSocketContext } from '@/contexts/websocket.context';
import { useNavigate } from 'react-router-dom';
import { ListPage } from '@/constants/list.enum';

interface Props {
  form: FormInstance;
  isLoading: boolean;
  rows: IProductRow[];
  addEmptyRow: () => void;
  getPriceGroupDetails: (key: number) => Promise<IPriceTypeById[]>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setRows: React.Dispatch<React.SetStateAction<IProductRow[]>>;
}

function Grid({
  isLoading,
  rows,
  form,
  setIsLoading,
  setRows,
  addEmptyRow,
  getPriceGroupDetails
}: Props) {
  const navigate = useNavigate();
  const [openSubmitModal, setOpenSubmitModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<{ id?: number; name: string }>();

  const gridRef = useRef<DataGridHandle>(null);
  const { get: getCacheMedias } = useCache(getImageFromServer);
  const { socket } = useContext(WebSocketContext);

  const [wooData, setWooData] = useState<IWooCommerceSocketData | null>(null);
  const [wooDataMap, setWooDataMap] = useState({} as IWooCommerceMap);

  const [modalVisible, setModalVisible] = useState(false);
  const createWooProductHistory = useMutation(create_woo_products);

  useEffect(() => {
    const handler = (data: { event: string; data: unknown }) => {
      if (data.event !== SystemNotificationType.WOO_PRODUCTS) return;

      setIsLoading(true);
      const response = data.data as IWooCommerceSocketData;

      const mainType =
        response.data.find(
          (d) => d.message === 'Started processing.' || d.message === 'Finished processing.'
        )?.type ?? response.data.find((d) => d.type)?.type;

      if (!mainType) return;

      setWooData(response);
      setWooDataMap((prev) => ({
        ...prev,
        [mainType]: response
      }));
      setModalVisible(true);
    };

    socket?.on(SocketEvents.USER_NOTIFICATION, handler);
    return () => {
      socket?.off(SocketEvents.USER_NOTIFICATION, handler);
    };
  }, [socket?.connected]);

  function onDeleteProduct() {
    if (!selectedProduct) {
      return message.error('Please select a product first.');
    }

    deleteRow(selectedProduct.id);
    setOpenDeleteModal(false);
  }

  const deleteRow = (productId?: number) => {
    setRows((prev) => {
      const filteredRows = prev.filter((row) => row.id !== productId);
      return filteredRows;
    });
  };

  async function onProductChange(
    productId: number,
    product: IProductDetails,
    props: RenderCellProps<IProductRow>
  ) {
    try {
      setIsLoading(true);
      if (!productId) {
        return message.error('Please select a product first.');
      }

      const defaultUnit = product.productUnits.find((u) => u.isDefault);
      const currentUnitId = defaultUnit?.unitId;

      if (!currentUnitId) {
        message.error('Selected product has no default unit.');
        props.onRowChange({ ...props.row, name: '', id: undefined });
        return;
      }

      const { rows } = await formatToGrid([product]);
      const productRow = rows[0];
      productRow.priority = props.rowIdx + 1;

      // Add Selling Price to Product
      const priceGroupId = form.getFieldValue('priceGroupId');
      if (priceGroupId) {
        const prices = await getPriceGroupDetails(priceGroupId);
        const productDetail = prices.find(
          (p) => p.productId === productId && p.unitId === currentUnitId
        );

        productRow.sell_price = productDetail?.sellingPrice || 0;
      }

      props.onRowChange(productRow);

      const lastRow = rows[rows.length - 1];
      const isLastRowFull = Boolean(lastRow.id && lastRow.name);
      if (isLastRowFull || props.rowIdx === rows.length - 1) {
        addEmptyRow();
      }
    } catch (error) {
      getErrorMessage(error, true);
    } finally {
      setIsLoading(false);
    }
  }

  const columnsWithActions: Column<IProductRow>[] = [
    {
      key: 'sn',
      name: '',
      frozen: true,
      width: 45,
      minWidth: 45,
      renderCell(props) {
        return <strong>{props.rowIdx + 1}</strong>;
      }
    },
    {
      key: 'delete',
      name: '',
      frozen: true,
      width: 45,
      minWidth: 45,
      cellClass: 'p-0',
      renderCell(props) {
        return (
          <div
            onClick={(event) => {
              event.stopPropagation();
              if (props.row.id === undefined) {
                return deleteRow();
              }
              setSelectedProduct({ id: props.row.id, name: props.row.name });
              setOpenDeleteModal(true);
            }}
            className="flex justify-center items-center cursor-pointer h-full">
            <DeleteOutlined style={{ fontSize: 16 }} className="!text-red-500 [&>svg]:!mt-0" />
          </div>
        );
      }
    },
    {
      key: 'name',
      name: 'Product',
      minWidth: 200,
      cellClass: 'p-0',
      headerCellClass: 'text-wrap-header',
      renderCell(props) {
        return (
          <div onClick={(e) => e.stopPropagation()} className="w-full">
            <ProductSearchForNonForm
              className="w-full"
              allowClear={false}
              defaultValue={props.row.id}
              onSelect={async (productId, product) => {
                const doesExist = rows.some((row) => row.id === productId);
                if (doesExist) {
                  message.destroy();
                  message.error('Product already added');
                  props.onRowChange(emptyRow);
                  throw new Error('Product already added');
                }

                await onProductChange(productId, product, props);
              }}
            />
          </div>
        );
      }
    },
    {
      key: 'unitName',
      name: 'Unit',
      cellClass: 'p-0',
      minWidth: 100,
      headerCellClass: 'text-wrap-header',
      renderCell: (props) => renderInputs(props, 'readonly')
    },
    {
      key: 'categoryName',
      name: 'Category',
      minWidth: 100,
      headerCellClass: 'text-wrap-header',
      cellClass: 'p-0',
      renderCell: (props) => renderInputs(props, 'readonly')
    },
    {
      key: 'sku',
      name: 'SKU',
      minWidth: 75,
      headerCellClass: 'text-wrap-header',
      cellClass: 'p-0',
      renderCell: (props) => renderInputs(props, 'readonly')
    },
    {
      key: 'imgId',
      name: 'Media',
      width: 45,
      headerCellClass: 'text-wrap-header',
      cellClass: 'p-0',
      renderCell: ({ row }) => {
        return (
          <PreviewImageOnDemand
            setParentLoading={setIsLoading}
            mediaId={row.imgId}
            fetcher={getCacheMedias}
          />
        );
      }
    },
    {
      key: 'limit_qty',
      name: 'Limit Qty',
      minWidth: 75,
      cellClass: 'p-0',
      headerCellClass: 'text-wrap-header',
      editable: true,
      renderCell: (props) => renderInputs(props, 'number')
    },
    {
      key: 'sell_price',
      name: 'Today Selling Price',
      minWidth: 100,
      cellClass: 'p-0',
      headerCellClass: 'text-wrap-header',
      editable: true,
      renderCell: (props) => renderInputs(props, 'number')
    }
  ];

  function renderInputs(
    props: RenderCellProps<IProductRow>,
    type: 'number' | 'string' | 'readonly'
  ) {
    const columnKey = props.column.key as keyof IProductRow;
    const value = props.row[columnKey];

    switch (type) {
      case 'number':
        return (
          <InputNumber
            props={props}
            gridRef={gridRef}
            keyboard
            columns={columnsWithActions}
            onChange={(value) => props.onRowChange({ ...props.row, [columnKey]: value || 0 })}
          />
        );
      case 'string':
        return (
          <Input
            props={props}
            gridRef={gridRef}
            keyboard
            columns={columnsWithActions}
            onChange={(value) => props.onRowChange({ ...props.row, [columnKey]: value || '' })}
          />
        );
      case 'readonly':
        return <ReadOnlyInput value={value} />;
    }
  }

  function handleNewRow() {
    const lastIndex = rows.length - 1;
    const lastRow = lastIndex >= 0 ? rows[lastIndex] : ({} as IProductRow);
    const isRowFilled = Boolean(lastRow.id && lastRow.name);

    if (!isRowFilled && rows.length > 0) {
      message.destroy();
      message.error('Please fill current row before adding new one');
      return;
    }

    addEmptyRow();

    requestAnimationFrame(() => {
      if (gridRef.current?.element) {
        gridRef.current.element.scrollTo({
          top: gridRef.current.element.scrollHeight,
          behavior: 'smooth'
        });
      }
    });
  }

  function handleSubmitClick(type: 'check' | 'save') {
    const lastIndex = rows.length - 1;
    const lastRow = lastIndex >= 0 ? rows[lastIndex] : ({} as IProductRow);
    const isRowFilled = Boolean(lastRow.id && lastRow.name);

    // If last row is not filled, exclude it
    const updatedRows = isRowFilled ? rows : rows.slice(0, -1);
    if (updatedRows.length === 0) {
      message.destroy();
      message.error('Please fill atleast one row');
      return;
    }

    const someRowEmpty = updatedRows.some((row) => !row.id || !row.name);
    if (someRowEmpty) {
      message.destroy();
      message.error('Please fill all rows or remove empty rows');
      return;
    }

    setOpenSubmitModal(type === 'check');
    return updatedRows;
  }

  function getTotalItems() {
    const lastIndex = rows.length - 1;
    const lastRow = lastIndex >= 0 ? rows[lastIndex] : ({} as IProductRow);
    const isRowFilled = Boolean(lastRow.id && lastRow.name);

    // If last row is not filled, exclude it
    const updatedRows = isRowFilled ? rows : rows.slice(0, -1);
    return updatedRows.length;
  }

  const handleModalClose = () => {
    setModalVisible(false);
    setIsLoading(false);

    if (!wooData) return;

    const totalItems = getTotalItems();
    const allProcessed =
      wooData?.messages.success.length + wooData?.messages.error.length === totalItems;

    const noFailures = wooData?.messages.error.length === 0;

    if (allProcessed && noFailures) {
      navigate(ListPage.WOO_PRODUCT_HISTORY);
    }
  };

  async function onFinish() {
    try {
      setIsLoading(true);
      const updatedRows = handleSubmitClick('save');
      if (!updatedRows) {
        setIsLoading(false);
        return;
      }

      const payload = updatedRows.map((row) => ({
        productId: row.id as number,
        unitId: row.unitId,
        orderQtyLimit: row.limit_qty,
        sellPrice: row.sell_price
      }));

      await createWooProductHistory.mutateAsync({ history: payload });
      message.success(`Processing started for ${updatedRows.length} product(s)`);
    } catch (error) {
      setIsLoading(false);
      getErrorMessage(error, true);
    }
  }

  async function onRetry(failedProducts: IProductDetails[]) {
    try {
      setIsLoading(true);
      if (!failedProducts.length) {
        message.error('No failed products found.');
        return;
      }

      // Only get rows of failed products
      const failedProductIds = failedProducts.map((a) => Number(a.id)).filter(Boolean);
      const failedRows = rows.filter((row) => failedProductIds.includes(row.id as number));
      const payload = failedRows.map((row) => ({
        productId: row.id as number,
        unitId: row.unitId,
        orderQtyLimit: row.limit_qty,
        sellPrice: row.sell_price
      }));

      setWooData(null);
      setWooDataMap({} as IWooCommerceMap);
      await createWooProductHistory.mutateAsync({ history: payload });
      message.success(`Processing started for ${payload.length} product(s)`);
    } catch (error) {
      getErrorMessage(error, true);
    } finally {
      setOpenSubmitModal(false);
    }
  }

  return (
    <div>
      <MultiProductSyncModal
        wooDataMap={wooDataMap}
        visible={modalVisible}
        getTotalItems={getTotalItems}
        onClose={handleModalClose}
        setLoading={setIsLoading}
        onRetry={onRetry}
      />

      <Modal
        title="Delete Product?"
        visible={openDeleteModal}
        onCancel={() => setOpenDeleteModal(false)}
        onOk={onDeleteProduct}>
        Are you sure you want to remove{' '}
        <span className="text-red-500">{selectedProduct?.name}</span> product from this list?
      </Modal>

      <Modal visible={openSubmitModal} onCancel={() => setOpenSubmitModal(false)} onOk={onFinish}>
        Do you want to create this?
      </Modal>

      <div className="mt-2 space-y-2">
        <ReactDataGrid
          ref={gridRef}
          rows={rows}
          columns={columnsWithActions}
          onRowsChange={setRows}
          emptyRowFallback="No products have been added yet."
        />

        <Button
          disabled={isLoading}
          type="dashed"
          onClick={handleNewRow}
          block
          style={{ width: '100%' }}>
          <PlusOutlined /> Add field
        </Button>

        <div className="mt-2.5 flex justify-end gap-2.5">
          <Button
            type="primary"
            onClick={() => handleSubmitClick('check')}
            disabled={isLoading || !rows.length}>
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Grid;
