import { UploadOutlined } from '@ant-design/icons';
import { Button, Form, message, Modal, PageHeader, Spin, Table } from 'antd';
import Upload from 'antd/lib/upload/Upload';
import { useState } from 'react';
import { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import { useMutation } from '@tanstack/react-query';
import {
  download_opening_stock_mutation,
  upload_opening_stock_error_check_mutation,
  upload_opening_stock_mutation
} from '@/services/stock/mutations';
import AppContent from '@/components/Common/Content/Content';
import ReuseChannel from '../../channel/Reuse';
import { getUserData } from '@/utils/auth.utils';

import { AxiosError } from 'axios';
import ErrorModalWithLog from '@/components/Common/ErrorModal/ErrorModalWithLog';
import LocationSearchV2 from '@/components/Common/CustomSearch/Location';
import { downloadFile } from '@/utils/downloadFile';
import isAxiosError from '@/utils/isAxiosError';
import CustomErrorModal from '@/components/Common/CustomErrorModal';
import getErrorMessage from '@/utils/getError';
import { convertResponseToRows } from './extractFile';
import { OpeningStockHistoryLine } from '@/services/stock/types';
import { transformedColumns } from './column.export';
import { Link } from 'react-router-dom';

interface IUpload {
  file: FormData;
  locationId: number;
}

const OpenStockDownloadAndUpload = () => {
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [isloading, setIsloading] = useState<boolean>(false);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [rows, setRows] = useState<OpeningStockHistoryLine[]>([]);
  const uploadMutation = useMutation((values: IUpload) =>
    upload_opening_stock_mutation(values.locationId, values.file)
  );

  const selectedLocationId = Form.useWatch('locationId', form) as number | undefined;
  const onChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const { preferences } = getUserData();
  const preferenceLocationId = preferences?.preferences
    ? JSON.parse(preferences?.preferences)?.locationId
    : undefined;

  const uploadProps: UploadProps = {
    name: 'media',
    multiple: false,
    maxCount: 1,
    fileList: fileList,
    accept: '.xlsx',
    onChange: onChange,
    beforeUpload: () => false,
    showUploadList: {
      showPreviewIcon: false,
      showRemoveIcon: true
    }
  };

  const displayError = (axiosError: AxiosError) => {
    const errorData = axiosError.response?.data as { message: string; errors: string[] };

    const content = (
      <div className="grid grid-cols-1 gap-2 ">
        {errorData.errors
          ? errorData.errors.map((error, index) => {
              return (
                <div key={index}>
                  <div className="text-red-600">{`${index + 1}. ${error}`}</div>
                </div>
              );
            })
          : errorData.message}
      </div>
    );

    ErrorModalWithLog({
      message: content,
      axiosError,
      title: errorData?.errors ? errorData?.message : 'Error'
    });
  };

  const handlePreviewModal = async () => {
    try {
      setIsloading(true);
      if (!selectedLocationId) {
        message.error('Please select a location!');
        setIsloading(false);
        return;
      }

      if (fileList.length === 0) {
        message.error('Please upload a file!');
        return;
      }

      const file = fileList[0].originFileObj as RcFile;

      const formData = new FormData();
      formData.append('file', file);

      // Check for any errors before making the request
      const data = await upload_opening_stock_error_check_mutation(selectedLocationId, formData);

      // Extract data safely
      const rows = await convertResponseToRows(data.data);
      if (rows.length === 0) {
        throw new Error('No data found in the file.');
      }

      setShowPreviewModal(true);
      setRows(rows);
    } catch (error) {
      setShowPreviewModal(false);
      if (isAxiosError(error)) {
        displayError(error as AxiosError);
      } else {
        CustomErrorModal({ message: getErrorMessage(error) });
      }
    } finally {
      setIsloading(false);
    }
  };

  const handleUpload = async () => {
    setIsloading(true);
    setShowPreviewModal(false);
    if (!selectedLocationId) {
      message.error('Please select a location!');
      setIsloading(false);
      return;
    }

    const formData = new FormData();
    formData.append('file', fileList[0].originFileObj as RcFile);
    const values = { file: formData, locationId: selectedLocationId };
    await uploadMutation.mutateAsync(values, {
      onSuccess: async () => {
        message.success('Uploaded successfully');
        setIsloading(false);
      },
      onError: (error) => {
        setIsloading(false);
        if (isAxiosError(error)) {
          displayError(error as AxiosError);
        } else {
          CustomErrorModal({ message: getErrorMessage(error) });
        }
      }
    });
  };

  const breadcrumbItems = [
    {
      label: 'Upload or Download Opening Stock',
      link: '/products/price-group'
    }
  ];

  const handleDownload = async () => {
    setIsloading(true);
    try {
      if (selectedLocationId) {
        const response = await download_opening_stock_mutation(selectedLocationId);
        downloadFile(response.data);
        // window.open(response.data, '_blank');
      } else {
        message.error('Please select a location!');
      }
    } catch (error) {
      message.error('unable to fetch');
    } finally {
      setIsloading(false);
    }
  };

  return (
    <div>
      <Spin spinning={isloading}>
        <Modal
          width={950}
          visible={showPreviewModal}
          onCancel={() => setShowPreviewModal(false)}
          onOk={handleUpload}
          okText="Upload"
          title="Stock Summary">
          <p className="text-gray-700 mb-4">
            Adjustments will be created for the <b>Accounted</b> and
            <b> Unaccounted Wastage</b> based on the reason specified in{' '}
            <Link to={'/settings/global'} target="_blank">
              Global Settings
            </Link>
            .
          </p>
          <Table
            pagination={false}
            scroll={{ x: 800, y: '75vh' }}
            columns={transformedColumns}
            dataSource={rows}
          />
        </Modal>
        <AppContent breadcrumbItems={breadcrumbItems}>
          <PageHeader
            title={'Price Information by Location'}
            style={{ padding: '8px 0px 0px 0px' }}
          />

          <Form
            form={form}
            layout={'vertical'}
            initialValues={{ locationId: preferenceLocationId }}>
            <LocationSearchV2
              hasParentFormItem={false}
              name={['locationId']}
              className="max-w-md"
            />
          </Form>

          <div className="flex gap-5 mt-10 mb-5 flex-wrap">
            <Upload {...uploadProps}>
              <Button
                style={{ width: 150 }}
                icon={<UploadOutlined />}
                disabled={fileList.length > 0}>
                Upload File
              </Button>
            </Upload>
            <Button
              type="primary"
              onClick={handlePreviewModal}
              disabled={fileList.length === 0}
              loading={isloading}
              style={{ width: 150 }}>
              {isloading ? 'loading' : 'Preview and Upload'}
            </Button>
            <Button
              onClick={handleDownload}
              disabled={isloading}
              type={'primary'}
              style={{ borderRadius: '9px' }}>
              Download
            </Button>
          </div>
          <ReuseChannel slug={`opening_stock_upload_${null}`} />
        </AppContent>
      </Spin>
    </div>
  );
};

export default OpenStockDownloadAndUpload;
