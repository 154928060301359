import { useQuery } from '@tanstack/react-query';
import { Button, Descriptions, Image, PageHeader, Spin, Tag } from 'antd';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { getImagesFromServer } from '@/services/upload/queries';
import { getUser } from '@/services';
import AppContent from '@/components/Common/Content/Content';
import { ControlOutlined, FileTextOutlined, UserOutlined } from '@ant-design/icons';
import { get_feature_request_detail } from '@/services/supports/queries';
import { EFeatureRequestColor } from '@/services/supports/constant';
import { EFeatureRequestProgress } from '@/services/supports/enum';
import { getUserData } from '@/utils/auth.utils';
import { ListPage } from '@/constants/list.enum';
import { isAdmin } from '@/routes/acl';

function ViewFeatureRequest() {
  const params = useParams();
  const requestFeatureID = Number(params.id);
  const navigate = useNavigate();

  const currentUser = getUserData();
  const isCurrentAdmin = isAdmin();

  if (isNaN(requestFeatureID) || requestFeatureID <= 0)
    return <Navigate to={ListPage.FEATURE_REQUEST} />;

  const { isLoading, isFetching, data } = useQuery(
    ['get_feature_request_detail', requestFeatureID],
    async () => {
      const details = await get_feature_request_detail(requestFeatureID);

      const [medias, user] = await Promise.all([
        getImagesFromServer(details.imgIds),
        getUser(details.requestedBy)
      ]);

      return {
        ...details,
        medias: medias.map((m) => m.url),
        userName: user.name,
        original: details
      };
    }
  );

  return (
    <Spin spinning={isLoading || isFetching}>
      <AppContent
        breadcrumbItems={[
          { label: 'Feature Request', link: '/support/feature-request' },
          { label: 'View' }
        ]}>
        <div className="card" style={{ borderRadius: '15px', padding: '0px 2px 0px 0px' }}>
          <PageHeader
            className="site-page-header"
            title="Feature Details"
            extra={
              <Tag color={EFeatureRequestColor[data?.status || EFeatureRequestProgress.PENDING]}>
                {data?.status}
              </Tag>
            }
          />
          <Descriptions bordered column={2} size="small" labelStyle={{ width: 200 }}>
            <Descriptions.Item
              span={2}
              label={
                <span>
                  <UserOutlined className="mr-2" />
                  Requested By
                </span>
              }>
              {data?.userName}
            </Descriptions.Item>

            <Descriptions.Item
              span={2}
              label={
                <span>
                  <ControlOutlined className="mr-2" />
                  System Message
                </span>
              }>
              {data?.systemMessage || 'N/A'}
            </Descriptions.Item>

            <Descriptions.Item
              span={2}
              label={
                <span>
                  <FileTextOutlined className="mr-2" />
                  Description
                </span>
              }>
              {data?.description}
            </Descriptions.Item>

            {data?.medias && data.medias.length > 0 && (
              <Descriptions.Item
                label={
                  <span>
                    <FileTextOutlined className="mr-2" />
                    Attachments
                  </span>
                }>
                <Image.PreviewGroup>
                  <div className="flex gap-2">
                    {data?.medias.map((media, index) => (
                      <div
                        key={`media-${index}`}
                        className="w-[104px] h-[104px] p-2 rounded-sm flex items-center justify-center"
                        style={{ border: '1px solid #d9d9d9' }}>
                        <Image src={media} className="object-contain" />
                      </div>
                    ))}
                  </div>
                </Image.PreviewGroup>
              </Descriptions.Item>
            )}
          </Descriptions>
        </div>

        <div className="flex justify-end mt-5 gap-1.5">
          {(data?.requestedBy === currentUser.id || isCurrentAdmin) && (
            <Button
              type="primary"
              htmlType="button"
              onClick={() => navigate(`/support/feature-request/details/${requestFeatureID}`)}>
              Edit Feature
            </Button>
          )}
        </div>
      </AppContent>
    </Spin>
  );
}

export default ViewFeatureRequest;
