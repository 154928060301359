import { Button, Form, Input, InputNumber, PageHeader, Spin, message } from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AppContent from '../../../../components/Common/Content/Content';
import { LocationSearch } from '../../../../components/Common/LocationSearch/LocationSearch';
import { IFonepayCredentialsCreate } from '../../../../services/epay/types';
import { useMutation } from '@tanstack/react-query';
import { create_fonepay_credentials_mutation } from '../../../../services/epay/mutations';
import Password from 'antd/lib/input/Password';
import { useFilterStore } from '@/store/zustand';
import { ListPage } from '@/constants/list.enum';

const CreateFonepay = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const zustandFilter = useFilterStore();

  const breadcrumbItems = [
    {
      label: 'Fonepay',
      link: '/fonepay'
    },
    {
      label: 'New',
      link: '/fonepay/new'
    }
  ];

  const onFinish = (values: IFonepayCredentialsCreate) => {
    setIsLoading(true);
    // console.log('Values', values);
    createFonepayCredentialsMutation.mutate(values);
  };

  const createFonepayCredentialsMutation = useMutation(create_fonepay_credentials_mutation, {
    onSuccess: () => {
      setIsLoading(false);
      zustandFilter.resetState(ListPage.FONEPAY);
      message.success('Fonepay Credentials added successfully');
      navigate('/fonepay');
    },
    onError: (e: any) => {
      setIsLoading(false);
      message.error(`${e.response.data.message}`);
    }
  });

  return (
    <Spin spinning={isLoading}>
      <AppContent breadcrumbItems={breadcrumbItems} withfilter={false}>
        <Form
          form={form}
          //initialValues={}
          onFinish={onFinish}
          disabled={isLoading}
          layout="vertical"
          validateTrigger={'onChange'}
          autoComplete="off"
          initialValues={{ username: '', password: '' }}>
          <PageHeader
            title="Fonepay Credential Information"
            style={{
              padding: '8px 0px'
            }}
          />
          <div className={'grid grid-cols-1 sm:grid-cols-3 gap-5 mb-5'}>
            <Form.Item
              label="Username"
              name="username"
              rules={[{ required: true, message: 'Please add Username!' }]}>
              <Input />
            </Form.Item>
            <Form.Item
              label="Password"
              name="password"
              rules={[{ required: true, message: 'Please add Username!' }]}>
              <Password />
            </Form.Item>
            <LocationSearch
              formData={{ formName: 'locationId', formLabel: 'Location' }}
              notAll={true}
              required={true}
            />
            <Form.Item
              label="Merchant Code"
              name="merchantCode"
              rules={[{ required: true, message: 'Please add Merchant Code!' }]}>
              <InputNumber controls={false} />
            </Form.Item>
            <Form.Item
              label="Secret Key"
              name="secretKey"
              rules={[{ required: true, message: 'Please add Secret Key!' }]}>
              <Input />
            </Form.Item>
          </div>
          <div className="flex justify-end mt-5">
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={isLoading}>
                Submit
              </Button>
            </Form.Item>
            <Button
              type="default"
              className="ml-5"
              htmlType="button"
              onClick={() => navigate('/fonepay')}>
              Cancel
            </Button>
          </div>
        </Form>
      </AppContent>
    </Spin>
  );
};

export default CreateFonepay;
