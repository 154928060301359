import { AxiosResponse } from 'axios';
import http from '@/utils/http.utils';
import { IWooHistoryUpdatePayload, IWooProductsHistoryPayload } from './types';

export const create_woo_products = async (payload: IWooProductsHistoryPayload) => {
  const response: AxiosResponse<{ messages: string[] }> = await http.store(
    'woo-commerce-products/order-sync',
    payload
  );
  return response.data;
};

export const sync_woo_category = async () => {
  const response: AxiosResponse<{ messages: string[] }> = await http.store(
    'woo-commerce-category/sync'
  );
  return response.data;
};

// Use for syncing products name, images, description, category, etc
export const sync_woo_products_metadata = async (payload: IWooHistoryUpdatePayload) => {
  const response: AxiosResponse<{ messages: string[] }> = await http.store(
    '/woo-commerce-products/update-product-from-ids',
    payload
  );
  return response.data;
};

export const delete_woo_categories = async () => {
  const response: AxiosResponse<{ messages: string[] }> = await http.update(
    'woo-commerce-category/delete-all-categories'
  );
  return response.data;
};

export const delete_woo_products = async () => {
  const response: AxiosResponse<{ messages: string[] }> = await http.update(
    'woo-commerce-products/delete-all-products'
  );
  return response.data;
};
