import moment from 'moment';
import { LocationType } from '@/services/locations/enum';
import { find_date_preference } from '@/store/localstorage/preferences';
import { convert_nepali_to_english_date } from '@/utils/nepaliDateConverter';
import { Form, FormInstance, Select, DatePicker, Input, PageHeader, Button } from 'antd';
import { NepaliDatePicker } from 'nepali-datepicker-reactjs';
import { ISumReportFormValue } from '.';

interface Props {
  isLoading: boolean;
  form: FormInstance;
  initialValues?: Partial<ISumReportFormValue>;
  onFinish: (values: ISumReportFormValue) => Promise<void>;
}

function FilterForm({ isLoading, form, initialValues, onFinish }: Props) {
  const nepaliType = find_date_preference();

  function onChangeSingle(value: string, type: 'english' | 'nepali') {
    const formValue = type === 'english' ? value : convert_nepali_to_english_date(value);
    form.setFieldValue(['date'], formValue);
  }

  return (
    <Form
      form={form}
      layout="vertical"
      autoComplete="off"
      disabled={isLoading}
      validateTrigger="onChange"
      initialValues={initialValues}
      onFinish={onFinish}>
      <PageHeader subTitle="Constraints" style={{ padding: '0px 0px' }} />
      <div className="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 ">
        <Form.Item name={['locationType']} label="Location Type">
          <Select placeholder="Select location type">
            <Select.Option value={''}>All</Select.Option>
            {Object.keys(LocationType).map((key) => (
              <Select.Option value={LocationType[key as keyof typeof LocationType]} key={key}>
                {key}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label={'Select Date'} name={['dateSingleNepali']} hidden={!nepaliType}>
          <NepaliDatePicker
            className="w-full"
            onChange={(val) => onChangeSingle(val, 'nepali')}
            options={{ calenderLocale: 'ne', valueLocale: 'en' }}
          />
        </Form.Item>

        <Form.Item label={'Select Date'} name={['dateSingle']} hidden={nepaliType}>
          <DatePicker
            onChange={(_, date) => onChangeSingle(date, 'english')}
            format={'YYYY-MM-DD'}
            className="w-full"
            disabledDate={(date) => date && date.isAfter(moment().endOf('day'))}
            allowClear={false}
          />
        </Form.Item>

        <Form.Item label={'Date'} name={['date']} hidden>
          <Input />
        </Form.Item>

        <div className="flex justify-start mt-7 gap-1">
          <Form.Item>
            <Button type="primary" htmlType="submit" style={{ borderRadius: '6px' }}>
              Search
            </Button>
          </Form.Item>
        </div>
      </div>
    </Form>
  );
}

export default FilterForm;
