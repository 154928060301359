import { Button, Form, Input, PageHeader, Spin, message } from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ICreateBank } from '../../../services/bank/types';
import { create_bank_mutation } from '../../../services/bank/mutations';
import { useMutation } from '@tanstack/react-query';
import AppContent from '../../../components/Common/Content/Content';
import { useFilterStore } from '@/store/zustand';
import { ListPage } from '@/constants/list.enum';

const CreateBank = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const zustandFilter = useFilterStore();

  const breadcrumbItems = [
    {
      label: 'Bank',
      link: '/bank'
    },
    {
      label: 'New',
      link: '/bank/new'
    }
  ];

  const onFinish = (values: ICreateBank) => {
    setIsLoading(true);
    //console.log('Values', values);
    createBankMutation.mutate(values);
  };

  const createBankMutation = useMutation(create_bank_mutation, {
    onSuccess: () => {
      setIsLoading(false);
      zustandFilter.resetState(ListPage.BANK);
      message.success('Bank added successfully');
      navigate('/bank');
    },
    onError: (e: any) => {
      setIsLoading(false);
      message.error(`${e.response.data.message}`);
    }
  });

  return (
    <Spin spinning={isLoading}>
      <AppContent breadcrumbItems={breadcrumbItems} withfilter={false}>
        <Form
          form={form}
          onFinish={onFinish}
          disabled={isLoading}
          layout="vertical"
          validateTrigger={'onChange'}
          autoComplete="off">
          <PageHeader
            title="Bank Information"
            style={{
              padding: '8px 0px'
            }}
          />
          <div className={'grid grid-cols-1 sm:grid-cols-4 gap-5 mb-5'}>
            <Form.Item
              label="Bank Name"
              name="name"
              rules={[{ required: true, message: 'Please add Bank Name!' }]}>
              <Input />
            </Form.Item>
            <Form.Item
              label="Account Number"
              name="accountNumber"
              rules={[{ required: true, message: 'Please add Account Number!' }]}>
              <Input />
            </Form.Item>
            <Form.Item
              label="Account Name"
              name="accountName"
              rules={[{ required: true, message: 'Please add Account Name!' }]}>
              <Input />
            </Form.Item>
            <Form.Item
              label="Address"
              name="address"
              rules={[{ required: true, message: 'Please add Address!' }]}>
              <Input />
            </Form.Item>
          </div>
          <div className="flex justify-end mt-5">
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={isLoading}>
                Submit
              </Button>
            </Form.Item>
            <Button
              type="default"
              className="ml-5"
              htmlType="button"
              onClick={() => navigate('/bank')}>
              Cancel
            </Button>
          </div>
        </Form>
      </AppContent>
    </Spin>
  );
};

export default CreateBank;
