import getGroupedData from '@/utils/getGroupedData';
import { forwardRef } from 'react';
import RenderTableV2 from './TableList';
import { PageHeader } from 'antd';

interface IExportData {
  productId: number;
  productName: string;
  price: number;
  unit: string;
  category: string;
}

interface Props {
  data: IExportData[];
  date: string;
  isOutlet?: boolean;
}

interface TableProps {
  isEven?: boolean;
  data: { [key: string]: IExportData[] };
}

const CHUNK_SIZE = 10;

function chunkArray<T>(arr: T[], chunkSize: number): T[][] {
  const chunks: T[][] = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    chunks.push(arr.slice(i, i + chunkSize));
  }
  return chunks;
}

function balancedDivideCategory(data: IExportData[]) {
  const productsByCategory = getGroupedData(data, 'category');
  const batchedCategories: Record<string, IExportData[]> = {};

  Object.entries(productsByCategory).forEach(([category, items]) => {
    const chunks = chunkArray(items, CHUNK_SIZE);
    chunks.forEach((chunk, index) => {
      const key = chunks.length > 1 ? `${category} (${index + 1})` : category;
      batchedCategories[key] = chunk;
    });
  });

  const categoryKeys = Object.keys(batchedCategories);
  if (categoryKeys.length === 1) {
    const key = categoryKeys[0];
    const items = batchedCategories[key];
    const mid = Math.ceil(items.length / 2);

    return {
      evenProductsByCategory: { [`${key} (1)`]: items.slice(0, mid) },
      oddProductsByCategory: { [`${key} (2)`]: items.slice(mid) }
    };
  }

  const sortedBatchedCategories = Object.entries(batchedCategories).sort(
    ([, itemsA], [, itemsB]) => itemsB.length - itemsA.length
  );

  const evenProductsByCategory: TableProps['data'] = {};
  const oddProductsByCategory: TableProps['data'] = {};
  let evenTotal = 0;
  let oddTotal = 0;

  sortedBatchedCategories.forEach(([key, items]) => {
    if (evenTotal <= oddTotal) {
      evenProductsByCategory[key] = items;
      evenTotal += items.length;
    } else {
      oddProductsByCategory[key] = items;
      oddTotal += items.length;
    }
  });

  return { evenProductsByCategory, oddProductsByCategory };
}

const PriceList = forwardRef<HTMLDivElement, Props>(({ data, date, isOutlet }, ref) => {
  const { evenProductsByCategory, oddProductsByCategory } = balancedDivideCategory(data);

  const evenProductsLength = Object.values(evenProductsByCategory).flat().length;
  const oddProductsLength = Object.values(oddProductsByCategory).flat().length;
  const maxPossibleRows = Math.max(evenProductsLength, oddProductsLength);

  return (
    <div>
      <PageHeader title={'Generated Price List'} style={{ padding: '8px 0' }} />
      <div ref={ref} style={{ width: '1400px', padding: '20px' }}>
        <div className="flex-center">
          <img
            src="/images/flogo_long_colorful.png"
            alt="freshktm-logo"
            width={220}
            className="object-contain"
          />
        </div>

        <div
          className="font-bold text-base flex-center h-10 mt-4"
          style={{ border: '1px solid black', background: '#e7e6e6', borderBottom: 'none' }}>
          {isOutlet ? 'Outlet' : 'B2B'} Price List for {date}
        </div>

        <div className="grid grid-cols-2">
          <div>
            <RenderTableV2
              isEven
              maxPossibleRows={maxPossibleRows}
              groupByCategories={evenProductsByCategory}
            />
          </div>
          <div>
            <RenderTableV2
              maxPossibleRows={maxPossibleRows}
              groupByCategories={oddProductsByCategory}
            />
          </div>
        </div>

        <div
          className="font-bold text-base flex-center h-10 italic"
          style={{ border: '1px solid black', background: '#e7e6e6', borderTop: 'none' }}>
          The prices mentioned above are per kilogram excluding VAT. All items have been graded.
        </div>
      </div>
    </div>
  );
});

PriceList.displayName = 'PriceList';
export default PriceList;
