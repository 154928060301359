import moment from 'moment';
import AppContent from '@/components/Common/Content/Content';
import { type PictureListRef } from '@/components/Common/PictureList';
import { getImagesFromServer, uploadImagesToServer } from '@/services/upload/queries';
import getErrorMessage from '@/utils/getError';
import {
  Alert,
  Button,
  Collapse,
  Form,
  InputNumber,
  message,
  Modal,
  PageHeader,
  Spin,
  UploadFile
} from 'antd';
import { useRef, useState } from 'react';
import VendorImageCollapsible from './VendorImageCollapsible';
import DetailsCollapsible from './DetailsCollapsible';
import VisitCollapsible from './VisitCollapsible';
import {
  IVendorProductData,
  IVendorProductDetails,
  IVendorProductsPayload,
  IVendorProductVisits
} from '@/services/vendor-products/types';
import { useMutation, useQuery } from '@tanstack/react-query';
import { update_vendor_products } from '@/services/vendor-products/mutation';
import { useNavigate, useParams } from 'react-router-dom';
import { get_vendor_products_by_id } from '@/services/vendor-products/queries';
import { getProductById, getVendors } from '@/services';
import { getFileListFromMediaIds } from '@/services/upload/services';
import VerticalForm from '@/components/FakeForm/VerticalForm';
import { FormValues } from '../create';
import { useFilterStore } from '@/store/zustand';
import { ListPage } from '@/constants/list.enum';

const breadcrumbItems = [{ label: 'Vendor Products', link: '/vendor-products' }, { label: 'Edit' }];

function EditVendorProducts() {
  const [form] = Form.useForm<FormValues>();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const params = useParams();
  const vendorProductId = parseInt(params.id || '0');

  const [clearAll, setClearAll] = useState(false);

  const vendorId = Form.useWatch('vendorId', form);
  const [productDetails, setProductDetails] = useState({ product: '', vendor: '' });
  const [vendorDefaultMedia, setVendorDefaultMedia] = useState<{
    avatarId?: number;
    vendorId?: number;
    file?: UploadFile;
  }>();

  const vendorMediaRef = useRef<PictureListRef>(null);
  const zustandFilter = useFilterStore();

  const updateVendorProductMutation = useMutation(update_vendor_products, {
    onSuccess: () => {
      message.success('Vendor Product updated successfully');
      zustandFilter.removeData(ListPage.VENDOR_PRODUCTS);
      navigate('/vendor-products');
    }
  });

  async function onSetVendorImageData(vendorProducts: IVendorProductData) {
    const { vendorId, productId, details: detailsStr } = vendorProducts;
    const details = JSON.parse(detailsStr) as IVendorProductDetails;

    const vendor = await getVendors(vendorId);

    const vendorAvatarId = vendor.user.avatarId;
    if (Object.keys(details).length) {
      const isVendorDefault = vendorAvatarId
        ? details?.vendorImage.includes(vendorAvatarId)
        : false;

      const addedMedia = await vendorMediaRef.current?.addByMediaIds(
        details.vendorImage,
        'replace'
      );

      const file = addedMedia?.find((media) => parseInt(media.uid) === vendorAvatarId);
      setVendorDefaultMedia({ avatarId: vendorAvatarId, vendorId: vendorId, file: file });

      form.setFieldValue('isVendorDefault', isVendorDefault);
    } else {
      setVendorDefaultMedia({ avatarId: vendorAvatarId, vendorId: vendorId });
    }

    form.setFieldValue('vendorId', vendorId);
    form.setFieldValue('productId', productId);
  }

  async function onSetDetailsData(details: IVendorProductDetails) {
    const currentDetails = details?.details || [];
    const detailsFormData = await Promise.all(
      currentDetails.map(async ({ title, metadata }) => {
        const files = await getFileListFromMediaIds(metadata.image);
        return { title, files, customDetails: metadata.custom };
      })
    );

    form.setFieldValue('details', detailsFormData);
  }

  async function onSetVisitsData(visits: IVendorProductVisits[]) {
    const formattedVisits = await Promise.all(
      visits.map(async ({ date, metadata }) => {
        const parsedMetadata =
          typeof metadata === 'string'
            ? (JSON.parse(metadata) as IVendorProductVisits['metadata'])
            : metadata;

        const momentDate = date ? moment(date) : null;
        const files = await getFileListFromMediaIds(parsedMetadata.image);

        const customDetails = parsedMetadata.custom.map((custom) => {
          return { ...custom, date: custom.date ? moment(custom.date) : null };
        });

        return { date: momentDate, files, customDetails };
      })
    );

    form.setFieldValue('visits', formattedVisits);
  }

  useQuery(['vendorProducts', vendorProductId], async () => {
    try {
      setIsLoading(true);
      const { vendorProducts } = await get_vendor_products_by_id(vendorProductId);
      const { details: detailsStr, visits: visitsStr } = vendorProducts;

      const vendor = await getVendors(vendorProducts.vendorId);
      const product = await getProductById(vendorProducts.productId);

      const details = JSON.parse(detailsStr) as IVendorProductDetails;
      const visits = JSON.parse(visitsStr) as IVendorProductVisits[];

      await Promise.all([
        onSetVendorImageData(vendorProducts),
        onSetDetailsData(details),
        onSetVisitsData(visits)
      ]);

      setProductDetails({ product: product.name, vendor: vendor.user.name });
    } catch (error) {
      console.log(error);
      getErrorMessage(error);
    } finally {
      setIsLoading(false);
    }
  });

  function handleReset(change: 'all' | 'vendor' | 'product') {
    if (change !== 'product') {
      vendorMediaRef?.current?.clearAll();
      form.resetFields(['isVendorDefault']);
    }

    if (change === 'all' || change === 'vendor') {
      form.resetFields(['productId', 'details', 'visits']);
      setClearAll(false);
      return;
    }

    form.resetFields(['details', 'visits']);
  }

  async function getMediaIds(files: UploadFile[]) {
    const mediaIdsWithURL = files.filter((file) => file.url).map((file) => parseInt(file.uid));
    const mediaWithoutURL = files.filter((file) => !file.url);

    const uploadData =
      mediaWithoutURL.length > 0 ? await uploadImagesToServer(mediaWithoutURL) : [];

    const mediaIdsWithoutURL = uploadData.map((media) => media?.id).filter(Boolean) as number[];

    return [...new Set([...mediaIdsWithoutURL, ...mediaIdsWithURL])];
  }

  async function onFinish(values: FormValues) {
    try {
      setIsLoading(true);
      const vendorMedias = vendorMediaRef.current?.getCurrentMedias() || [];
      const vendorMediaIds = await getMediaIds(vendorMedias);

      const updatePayload: IVendorProductsPayload = {
        vendorId: values.vendorId,
        productId: values.productId,
        mediaIds: [],
        details: '{}',
        visits: []
      };

      const combinedMediaIds = [...vendorMediaIds];

      const detailsData = {
        vendorImage: vendorMediaIds,
        details: [] as IVendorProductDetails['details']
      };

      // Modify Details Payload
      if (values.details && values.details.length > 0) {
        const updatedDetails = await Promise.all(
          values.details.map(async (detail) => {
            const metadata = detail.customDetails;
            const mediaIds = await getMediaIds(detail.files || []);

            combinedMediaIds.push(...mediaIds);
            return {
              title: detail.title,
              metadata: { image: mediaIds, custom: metadata }
            };
          })
        );

        detailsData.details = updatedDetails;
      }

      updatePayload.details = JSON.stringify(detailsData);

      // Modify Visits Payload
      if (values.visits && values.visits.length > 0) {
        const updatedVisits = await Promise.all(
          values.visits.map(async (visit) => {
            const dateString = moment(visit.date).toISOString();
            const metadata = visit.customDetails.map((m) => {
              const { date, ...rest } = m;
              if (!date) return rest;
              return { ...rest, date: moment(date).toISOString() };
            });

            const mediaIds = await getMediaIds(visit.files || []);
            combinedMediaIds.push(...mediaIds);

            return {
              date: dateString,
              metadata: JSON.stringify({ image: mediaIds, custom: metadata })
            };
          })
        );

        updatePayload.visits = updatedVisits;
      }

      updatePayload.mediaIds = Array.from(new Set(combinedMediaIds));
      await updateVendorProductMutation.mutateAsync({ id: vendorProductId, values: updatePayload });
    } catch (error) {
      getErrorMessage(error, true);
    } finally {
      setIsLoading(false);
    }
  }

  async function onDefaultVendorImageChange(checked: boolean) {
    try {
      setIsLoading(true);
      // Show error if no vendor is selected
      if (!vendorId) {
        return message.error('Please select vendor first!');
      }

      if (!checked) {
        const avatarId = vendorDefaultMedia?.avatarId || '';
        vendorMediaRef.current?.removeMedia(String(avatarId));
        return;
      }

      // Show error if no default image is present
      if (!vendorDefaultMedia || !vendorDefaultMedia.avatarId) {
        return message.error('Selected vendor has no default image!');
      }

      // Check Cache Media and add default file
      if (vendorDefaultMedia.file && vendorDefaultMedia.vendorId === vendorId) {
        vendorMediaRef.current?.addMedia(vendorDefaultMedia.file, 'replace');
        return;
      }

      // Fetch from server
      const vendorMedia = await getImagesFromServer([vendorDefaultMedia.avatarId]);
      const currentMedia = vendorMedia[0];
      const file: UploadFile = {
        url: currentMedia.url,
        name: currentMedia.name,
        uid: currentMedia.id.toString(),
        status: 'done'
      };

      vendorMediaRef.current?.addMedia(file, 'replace');
      setVendorDefaultMedia((prev) => ({ ...prev, file }));
    } catch (error) {
      getErrorMessage(error, true);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Spin spinning={isLoading}>
      <Modal visible={clearAll} onOk={() => handleReset('all')} onCancel={() => setClearAll(false)}>
        <p>
          All data provided{' '}
          <span className="font-bold text-blue-500">(except supplier and product)</span> will be
          cleared. Are you sure you want to continue?
        </p>
      </Modal>
      <AppContent breadcrumbItems={breadcrumbItems}>
        <Alert
          message="Warning"
          description="Changing the product or supplier will clear all current data. Please be careful before proceeding."
          showIcon
        />
        <PageHeader title="Product Information" style={{ padding: '8px 0px' }} />
        <Form
          form={form}
          validateTrigger="onChange"
          layout="vertical"
          onFinish={onFinish}
          autoComplete="off"
          initialValues={{ visits: [], customDetails: [] }}>
          <div className={'grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5 mb-5'}>
            <VerticalForm label="Vendor" value={productDetails.vendor} />
            <VerticalForm label="Product" value={productDetails.product} />
            <Form.Item label="Product ID" name="productId" hidden>
              <InputNumber />
            </Form.Item>
            <Form.Item label="Vendor ID" name="vendorId" hidden>
              <InputNumber />
            </Form.Item>
          </div>

          <Collapse collapsible={vendorId ? undefined : 'disabled'}>
            <Collapse.Panel header="Vendor Image" key={'vendor-image'} forceRender>
              <VendorImageCollapsible
                vendorMediaRef={vendorMediaRef}
                avatarId={vendorDefaultMedia?.avatarId}
                onDefaultVendorImageChange={onDefaultVendorImageChange}
              />
            </Collapse.Panel>

            <Collapse.Panel header="Details" key={'details'} forceRender>
              <DetailsCollapsible />
            </Collapse.Panel>

            <Collapse.Panel header="Visits" key={'visits'} forceRender>
              <VisitCollapsible />
            </Collapse.Panel>
          </Collapse>

          <div className="flex justify-end mt-5 gap-2">
            <Button htmlType="button" onClick={() => setClearAll(true)}>
              Clear All
            </Button>

            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </div>
        </Form>
      </AppContent>
    </Spin>
  );
}

export default EditVendorProducts;
