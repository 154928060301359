import moment from 'moment';

export function getDateRangeFromSingle(date: string | moment.Moment) {
  const startDate = moment(date).startOf('day').millisecond(0).toISOString();
  const endDate = moment(date).endOf('day').millisecond(0).toISOString();

  return { startDate, endDate };
}

export function getRangeMomentFromSingle(date: string | moment.Moment) {
  const dateFormatted = moment(date).format('YYYY-MM-DD');
  const startDate = moment(dateFormatted).startOf('day').millisecond(0);
  const endDate = moment(dateFormatted).endOf('day').millisecond(0);

  return [startDate.clone(), endDate.clone()] as [moment.Moment, moment.Moment];
}
