import { Form, Input, Menu, Spin, TableProps, Tooltip } from 'antd';
import { ColumnsType, SorterResult } from 'antd/lib/table/interface';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import AppContent from '@/components/Common/Content/Content';
import AccountFilterTable from '@/components/AccountFilterTable/v2';

import moment from 'moment';
import { checkAccess } from '@/routes/acl';
import CustomButton from '@/components/Common/CustomButton/CustomButton';
import CustomizeTable from '@/components/Common/CustomizeTable/CustomizeTable';
import { convertLocalToUTCString, convertUTCStringtoLocalString } from '@/utils/convertToUTC';
import TableCell from '@/components/Common/CustomizeTable/CustomCell';
import {
  get_account_detail_by_ids,
  get_account_journal_lines_credit_debit_sum,
  get_account_latest_history,
  get_journal_list
} from '@/services/accounts/queries';
import {
  IJournalListResponse,
  IJournalListResponseData,
  IJournalViewResponseTableData
} from '@/services/accounts/types';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';
import { ConvertObjectToURL } from '@/utils/converturl';
import { getUserData } from '@/utils/auth.utils';
import LocationsDB from '@/store/localstorage/LocationsDB';
import { get_location_list_for_ids } from '@/services/locations/queries';
import UsersDB from '@/store/localstorage/UsersDB';
import { get_user_list_ids } from '@/services/users/queries';
import { PrinterOutlined } from '@ant-design/icons';
import DiscussionChatModal from '@/components/Common/DiscussionChatModal';
import ActionDropdown from '@/components/Common/Dropdownactions';
import JournalInvoice from '@/components/Common/CustomResuableInvoice/JournalInvoice';
import CustomViewIcon from '@/components/Common/CustomIcons/CustomViewIcon';
import AccountsDB from '@/store/localstorage/AccountsDB';
import { getDateFromDateTime } from '@/utils/getDateFromDateTime';
import { DEFAULT_DATE_FORMAT } from '@/constants';
import LocationSearchV2 from '@/components/Common/CustomSearch/Location';
import AccountSearchV2 from '@/components/Common/CustomSearch/Accounts/AccountSearchV2';

interface ISavedData {
  tableData: IJournalListResponse;
  totalCredit: number;
  totalDebit: number;
}

const JournalList = () => {
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(100);

  const [journalList, setJournalList] = useState<IJournalListResponse>(Object);
  const [journalDetails, setJournalDetails] = useState<IJournalListResponseData>();
  const [modalData, setModalData] = useState<IJournalViewResponseTableData[]>();
  const [openModalForPrint, setOpenModalForPrint] = useState<boolean>(false);
  const [total, setTotal] = useState<{ totalCredit: number; totalDebit: number }>({
    totalCredit: 0,
    totalDebit: 0
  });
  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({});
  const handleChange: TableProps<any>['onChange'] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<any>);
  };

  const breadcrumbItems = [
    {
      label: 'Journal',
      link: '/accounts/journal'
    }
  ];

  const { preferences } = getUserData();
  const preferenceLocationId = preferences?.preferences
    ? JSON.parse(preferences?.preferences)?.locationId
    : undefined;

  const onSubmitFilter = async (val: string) => {
    const data = await getInfo(val);
    setPage(1);
    setSize(100);
    return data;
  };

  const getInfo = async (filter = ''): Promise<ISavedData> => {
    setIsLoading(true);
    const response = await get_journal_list(filter);

    const createdByUsers: any = {};
    let totalCredit = 0;
    let totalDebit = 0;
    const tableData: IJournalListResponse = response;
    for (let ind = 0; ind < tableData.results.length; ind++) {
      if (tableData.results[ind].locationId !== null) {
        let locationDetails = await LocationsDB.getLocation(tableData.results[ind].locationId);
        if (!locationDetails) {
          const allLocations = await get_location_list_for_ids([
            ...new Set(
              tableData.results
                .filter((value) => value.locationId !== null)
                .map((value) => {
                  return value.locationId;
                })
            )
          ]);
          await LocationsDB.addLocations(allLocations);
          locationDetails = await LocationsDB.getLocation(tableData.results[ind].locationId);
        }
        if (typeof locationDetails === 'object')
          tableData.results[ind].locationName = locationDetails.name;
      }
      if (tableData.results[ind].createdBy !== 'SYSTEM') {
        const createdByuser = await UsersDB.getUser(parseInt(tableData.results[ind].createdBy));
        if (!createdByuser) {
          if (tableData.results[ind].createdBy in createdByUsers) {
            createdByUsers[tableData.results[ind].createdBy] = [
              ...createdByUsers[tableData.results[ind].createdBy],
              ind
            ];
          } else {
            createdByUsers[tableData.results[ind].createdBy] = [ind];
          }
        } else {
          tableData.results[ind].createdBy = createdByuser.name;
        }
      } else {
        tableData.results[ind].createdBy = 'SYSTEM';
      }

      // Calc Total
      totalCredit += tableData.results[ind].credit;
      totalDebit += tableData.results[ind].debit;
    }
    const createdByUserslength = Object.keys(createdByUsers).length;
    if (createdByUserslength > 0) {
      const createdByUsersList = await get_user_list_ids([...Object.keys(createdByUsers)]);
      for (const key in createdByUsers) {
        const createdByUser = createdByUsersList.data.results.find((value) => value.id == key);
        if (!createdByUser) continue;
        for (let i = 0; i < createdByUsers[key].length; i++) {
          tableData.results[createdByUsers[key][i]].createdBy = createdByUser.name;
        }
        await UsersDB.addUsers([createdByUser]);
      }
    }
    setJournalList(tableData);
    setTotal({ totalCredit: totalCredit, totalDebit: totalDebit });
    setIsLoading(false);
    return { tableData, totalCredit, totalDebit };
  };

  const openModal = async (row?: IJournalListResponseData) => {
    const tableData: IJournalViewResponseTableData[] = [];

    if (row) {
      const journalDate = row.lines[0].journalDate;
      const accountsId = row.lines.map((data) => data.accountId);

      const startDate = getDateFromDateTime(journalDate);
      const endDate = new Date(journalDate);

      const journalDebitCredit = await get_account_journal_lines_credit_debit_sum({
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        ids: accountsId
      });

      const newRunningBalance = await get_account_latest_history(
        startDate.toISOString(),
        accountsId
      );
      for (let ind = 0; ind < row.lines.length; ind++) {
        let filteredAcc = await AccountsDB.getAccount(row.lines[ind].accountId);
        if (!filteredAcc) {
          const allAccounts = await get_account_detail_by_ids([
            ...new Set(
              row.lines.map((value) => {
                return value.accountId;
              })
            )
          ]);
          await AccountsDB.addAccounts(allAccounts.results);
          filteredAcc = await AccountsDB.getAccount(row.lines[ind].accountId);
        }
        const accName = typeof filteredAcc === 'object' ? filteredAcc?.name : '';

        // if (typeof filteredAcc === 'object' && filteredAcc?.type !== 'USER') {
        //   row.lines[ind].runningBalance = null;
        // }

        let currentBalance = newRunningBalance.results.find(
          (val) => val.accountId === row.lines[ind].accountId
        )?.balance;

        const currentJournalDebit = journalDebitCredit.results.find(
          (val) => val.accountId === row.lines[ind].accountId
        );

        if (currentJournalDebit) {
          if (!currentBalance) {
            currentBalance =
              Number(currentJournalDebit.totalDebit) - Number(currentJournalDebit.totalCredit);
          } else {
            currentBalance =
              currentBalance +
              Number(currentJournalDebit.totalDebit) -
              Number(currentJournalDebit.totalCredit);
          }
        }

        tableData.push({
          ...row.lines[ind],
          journalName: row.lines[ind].description,
          accountName: accName,
          runningBalance: currentBalance || 0
        });
      }

      setModalData(tableData);
      setJournalDetails(row);
      setOpenModalForPrint(true);
    }
  };

  const columns: ColumnsType<IJournalListResponseData> = [
    {
      title: 'S.N',
      key: 'SN',
      width: 5,
      render: (txt, record, index) => {
        return <TableCell>{(page - 1) * size + (index + 1)}</TableCell>;
      }
    },
    {
      title: 'Description',
      key: 'description',
      width: 20,
      sorter: (a, b) => a.description.localeCompare(b.description),
      sortOrder: sortedInfo.columnKey === 'description' ? sortedInfo.order : null,
      render: (a, record) => {
        return (
          <TableCell>
            <Link to={'/accounts/journal/view/' + record.id}>{record.description}</Link>
          </TableCell>
        );
      }
    },
    {
      title: 'Serial Number',
      key: 'referenceNumber',
      width: 20,
      sorter: (a, b) => a.referenceNumber - b.referenceNumber,
      sortOrder: sortedInfo.columnKey === 'referenceNumber' ? sortedInfo.order : null,
      render: (a, record) => {
        return (
          <TableCell className="invoice">
            <Link to={'/accounts/journal/view/' + record.id}>{record.referenceNumber}</Link>
          </TableCell>
        );
      }
    },
    {
      title: 'Debit',
      key: 'debit',
      width: 10,
      sorter: (a, b) => a.debit - b.debit,
      sortOrder: sortedInfo.columnKey === 'debit' ? sortedInfo.order : null,
      render: (a, record) => {
        return (
          <>
            <TableCell className="text-right mr-2 text-xs">
              {nepaliNumberFormatter(record.debit)}
            </TableCell>
          </>
        );
      }
    },
    {
      title: 'Credit',
      key: 'credit',
      width: 10,
      sorter: (a, b) => a.credit - b.credit,
      sortOrder: sortedInfo.columnKey === 'credit' ? sortedInfo.order : null,
      render: (a, record) => {
        return (
          <>
            <TableCell className="text-right mr-2 text-xs">
              {nepaliNumberFormatter(record.credit)}
            </TableCell>
          </>
        );
      }
    },
    {
      title: 'Location',
      key: 'locationName',
      width: 15,
      sorter: (a, b) =>
        a.locationName && b.locationName ? a.locationName.localeCompare(b.locationName) : 0,
      sortOrder: sortedInfo.columnKey === 'locationName' ? sortedInfo.order : null,
      render: (a, record) => {
        return (
          <>
            <TableCell>{record.locationName}</TableCell>
          </>
        );
      }
    },
    {
      title: 'Date',
      key: 'date',
      width: 15,
      sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
      sortOrder: sortedInfo.columnKey === 'date' ? sortedInfo.order : null,
      render: (a, record) => {
        return (
          <>
            <TableCell>{convertUTCStringtoLocalString(record.date, DEFAULT_DATE_FORMAT)}</TableCell>
          </>
        );
      }
    },
    {
      title: 'Created By',
      key: 'createdBy',
      width: 15,
      sorter: (a, b) => (a.createdBy && b.createdBy ? a.createdBy.localeCompare(b.createdBy) : 0),
      sortOrder: sortedInfo.columnKey === 'createdBy' ? sortedInfo.order : null,
      render: (a, record) => {
        return (
          <>
            <TableCell>{record.createdBy}</TableCell>
          </>
        );
      }
    },
    {
      title: 'Created Date',
      key: 'createdAt',
      width: 15,
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      sortOrder: sortedInfo.columnKey === 'createdAt' ? sortedInfo.order : null,
      render: (a, record) => {
        return (
          <>
            <TableCell>
              {convertUTCStringtoLocalString(record.createdAt, DEFAULT_DATE_FORMAT)}
            </TableCell>
          </>
        );
      }
    },
    {
      title: 'Actions',
      key: 'actions',
      width: 10,
      fixed: 'right',
      render: (record) => {
        const menuItems: (
          | {
              key: string;
              label: JSX.Element;
              onClick?: undefined;
            }
          | {
              key: string;
              label: JSX.Element;
              onClick: () => void;
            }
        )[] = [];
        if (checkAccess('READ_ACCOUNT')) {
          menuItems.push(
            {
              key: '1',
              label: <CustomViewIcon link={'/accounts/journal/view/' + record.id} />
            },
            {
              key: '2',
              label: (
                <div style={{ height: '1.5rem' }} className="flex justify-center items-center">
                  <Tooltip title="Print" color="blue">
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        openModal(record);
                      }}>
                      <PrinterOutlined style={{ transform: 'scale(1.4)', width: '100%' }} />
                    </div>
                  </Tooltip>
                </div>
              )
            }
          );
        }

        if (checkAccess('READ_CHANNEL')) {
          menuItems.push({
            key: '4',
            label: (
              <>
                {checkAccess('READ_CHANNEL') && (
                  <DiscussionChatModal slug="journal_lines" id={record.id} />
                )}
              </>
            )
          });
        }
        const menu = <Menu items={menuItems} />;

        return <ActionDropdown menu={menu} />;
      }
    }
  ];

  const onPagination = async (pageNo = 1, totalSize = 100, isSize = false) => {
    setIsLoading(true);
    const values = form.getFieldsValue();
    values.endDate = convertLocalToUTCString(values.endDate);
    values.startDate = convertLocalToUTCString(values.startDate);
    delete values.dateCustom;
    delete values.startDateNepali;
    delete values.endDateNepali;
    if (isSize) {
      values.skip = 0;
      values.count = totalSize;
      setPage(1);
      setSize(totalSize);
    } else {
      values.skip = (pageNo - 1) * totalSize;
      values.count = totalSize;
      setPage(pageNo);
    }
    const url = ConvertObjectToURL(values);
    return await getInfo(url);
  };

  return (
    <Spin spinning={isLoading}>
      {modalData && (
        <JournalInvoice
          openModal={openModalForPrint}
          setOpenModal={setOpenModalForPrint}
          modalData={modalData}
          journalData={journalDetails}
        />
      )}
      <AppContent
        breadcrumbItems={breadcrumbItems}
        withfilter={false}
        button={
          <>
            <div>
              <AccountFilterTable
                initial
                onParentLoading={(loading) => setIsLoading(loading)}
                onInitialLoad={({ pagination, data }) => {
                  setPage(pagination.page);
                  setSize(pagination.size);

                  if (data) {
                    const { tableData, ...total } = data as ISavedData;
                    setJournalList(tableData);
                    setTotal(total);
                    setIsLoading(false);
                  }
                }}
                defaultValues={{
                  value: '',
                  skip: 0,
                  count: 100,
                  locationId: preferenceLocationId ? preferenceLocationId : 1
                }}
                onSearch={onSubmitFilter}
                form={form}
                buttonParentStyle={'flex justify-end items-center'}
                buttons={
                  <>
                    {checkAccess('CREATE_ACCOUNT') && (
                      <div>
                        <CustomButton
                          onClick={() => navigate('/accounts/journal/new')}
                          text="Add"
                          backgroundColor="#1890ff"
                          Linkto="/accounts/journal/new"
                        />
                      </div>
                    )}
                  </>
                }>
                <LocationSearchV2 hasParentFormItem={false} name={'locationId'} showAll />
                <AccountSearchV2
                  name={'accountId'}
                  hasParentFormItem={false}
                  locationId={form.getFieldValue(['locationId']) || undefined}
                />

                <Form.Item name="value" label="Search">
                  <Input placeholder="Search" />
                </Form.Item>
              </AccountFilterTable>
            </div>
            {/* </div> */}
          </>
        }>
        <CustomizeTable
          form={form}
          columns={columns}
          data={journalList.results}
          notshowPagination={true}
          customScroll={{ x: 1400, y: '75vh' }}
          paginationDatas={{
            page: page,
            total: journalList.total,
            size: size,
            onPagination
          }}
          toSort={handleChange}
          tableName={'journal-list'}
          tableSummaryForFilter={[
            { index: 2, render: <span className="flex">Total</span> },
            { index: 3, render: nepaliNumberFormatter(total.totalDebit) },
            { index: 4, render: nepaliNumberFormatter(total.totalCredit) }
          ]}
          // tableSummary={
          //   <Table.Summary fixed={true}>
          //     <Table.Summary.Row>
          //       <Table.Summary.Cell index={0}></Table.Summary.Cell>
          //       <Table.Summary.Cell index={1}></Table.Summary.Cell>
          //       <Table.Summary.Cell index={2}>Total</Table.Summary.Cell>
          //       <Table.Summary.Cell index={3} className="text-right mr-2 text-xs">
          //         {nepaliNumberFormatter(total.totalDebit)}
          //       </Table.Summary.Cell>
          //       <Table.Summary.Cell index={4} className="text-right mr-2 text-xs">
          //         {nepaliNumberFormatter(total.totalDebit)}
          //       </Table.Summary.Cell>
          //       <Table.Summary.Cell index={5}></Table.Summary.Cell>
          //       <Table.Summary.Cell index={6}></Table.Summary.Cell>
          //       <Table.Summary.Cell index={7}></Table.Summary.Cell>
          //       <Table.Summary.Cell index={8}></Table.Summary.Cell>
          //       <Table.Summary.Cell index={9}></Table.Summary.Cell>
          //     </Table.Summary.Row>
          //   </Table.Summary>
          // }
        />
      </AppContent>
    </Spin>
  );
};

export default JournalList;
