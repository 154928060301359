import CustomCalendarIcon from '@/components/Common/CustomIcons/CustomCalendarIcon';
import CustomDisableIcon from '@/components/Common/CustomIcons/CustomDisableIcon';
import CustomUpdateIcon from '@/components/Common/CustomIcons/CustomUpdateIcon';
import CustomViewIcon from '@/components/Common/CustomIcons/CustomViewIcon';
import GenericTable from '@/components/Common/CustomizeTable';
import ActionDropdown from '@/components/Common/Dropdownactions';
import { DEFAULT_DATE_FORMAT } from '@/constants';
import { checkAccess } from '@/routes/acl';
import { IHRLeaves, IHRLeavesList } from '@/services/hr/types';
import { convertUTCStringtoLocalString } from '@/utils/convertToUTC';
import { FormInstance, Menu, message, Modal, TableProps, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { SorterResult } from 'antd/lib/table/interface';
import { useState } from 'react';

interface Props {
  form: FormInstance;
  isLoading?: boolean;
  data: IHRLeavesList;
  pagination: { page: number; size: number };
  onPagination: (page?: number, size?: number, isSize?: boolean) => Promise<unknown>;
  handleDisabled: (leave: IHRLeaves) => Promise<void>;
}

function Table({ isLoading, data, pagination, form, onPagination, handleDisabled }: Props) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [sortedInfo, setSortedInfo] = useState<SorterResult<IHRLeaves>>({});
  const [selectedLeave, setSelectedLeave] = useState<IHRLeaves>();

  function handleArchiveClick(leave: IHRLeaves) {
    setIsModalVisible(true);
    setSelectedLeave(leave);
  }

  async function handleOk() {
    if (!selectedLeave) {
      return message.error('Please select the leave');
    }

    setIsModalVisible(false);
    await handleDisabled(selectedLeave);
    setSelectedLeave(undefined);
  }

  const handleChange: TableProps<IHRLeaves>['onChange'] = (_, __, s) => {
    setSortedInfo(s as SorterResult<IHRLeaves>);
  };

  const columns: ColumnsType<IHRLeaves> = [
    {
      title: 'S.N.',
      key: 'id',
      width: 5,
      render: (_, __, index) => (pagination.page - 1) * pagination.size + index + 1
    },
    {
      title: 'User',
      key: 'user',
      width: 20,
      dataIndex: 'userName',
      sorter: (a, b) => a.userName.localeCompare(b.userName),
      sortOrder: sortedInfo.columnKey === 'user' ? sortedInfo.order : null
    },
    {
      title: 'Start',
      key: 'startDate',
      width: 15,
      dataIndex: 'startDate',
      sorter: (a, b) => a.startDate.localeCompare(b.startDate),
      sortOrder: sortedInfo.columnKey === 'date' ? sortedInfo.order : null,
      render: (startDate: string) => {
        return convertUTCStringtoLocalString(startDate, DEFAULT_DATE_FORMAT);
      }
    },
    {
      title: 'End',
      key: 'endDate',
      width: 15,
      dataIndex: 'endDate',
      sorter: (a, b) => a.endDate.localeCompare(b.endDate),
      sortOrder: sortedInfo.columnKey === 'date' ? sortedInfo.order : null,
      render: (endDate: string) => {
        return convertUTCStringtoLocalString(endDate, DEFAULT_DATE_FORMAT);
      }
    },
    {
      title: 'Description',
      key: 'description',
      width: 35,
      dataIndex: 'description'
    },
    {
      title: 'Last Updated',
      key: 'lastUpdated',
      width: 15,
      dataIndex: 'updatedAt',
      sorter: (a, b) => a.updatedAt.localeCompare(b.updatedAt),
      sortOrder: sortedInfo.columnKey === 'lastUpdated' ? sortedInfo.order : null,
      render: (_, record) => convertUTCStringtoLocalString(record.updatedAt, DEFAULT_DATE_FORMAT)
    },
    {
      title: 'Status',
      key: 'isDisabled',
      width: 10,
      dataIndex: 'isDisabled',
      sorter: (a, b) => Number(a.isDisabled) - Number(b.isDisabled),
      sortOrder: sortedInfo.columnKey === 'isDisabled' ? sortedInfo.order : null,
      render: (isDisabled) => (
        <Tag color={isDisabled ? 'red' : 'green'}>{isDisabled ? 'Rejected' : 'Approved'}</Tag>
      )
    },
    {
      title: 'Actions',
      key: 'actions',
      width: 6,
      fixed: 'right',
      render: (record: IHRLeaves) => {
        const menuItems: (
          | { key: string; label: JSX.Element; onClick?: undefined }
          | { key: string; label: JSX.Element; onClick: () => void }
        )[] = [
          {
            key: 'view',
            label: <CustomViewIcon link={`/hr/leaves/${record.id}`} />
          }
        ];

        if (checkAccess('READ_ALL_USER_HR')) {
          menuItems.push({
            key: 'calendar',
            label: <CustomCalendarIcon link={`/hr/calendar/${record.userId}`} />
          });
        }

        if (checkAccess('UPDATE_LEAVES')) {
          menuItems.push(
            ...[
              {
                key: 'update',
                label: <CustomUpdateIcon link={`/hr/leaves/details/${record.id}`} />
              },
              {
                key: 'disabled',
                label: (
                  <CustomDisableIcon
                    currentState={record.isDisabled}
                    onClick={() => handleArchiveClick(record)}
                  />
                )
              }
            ]
          );
        }

        const menu = <Menu items={menuItems} />;
        return <ActionDropdown menu={menu} />;
      }
    }
  ];

  return (
    <div>
      <Modal visible={isModalVisible} onOk={handleOk} onCancel={() => setIsModalVisible(false)}>
        <p>
          Are you sure you want to {selectedLeave?.isDisabled ? 'enable' : 'disable'} this leave?
        </p>
      </Modal>
      <GenericTable
        form={form}
        columns={columns}
        data={data.results}
        isLoading={isLoading}
        scroll={{ x: 1300 }}
        hideDefaultPagination
        tableName={'hr-leaves-list'}
        pagination={{ ...pagination, total: data.total, onPagination, scrollToTop: true }}
        toSort={handleChange}
      />
    </div>
  );
}

export default Table;
