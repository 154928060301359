import { IOpeningStockData, IOpeningStockHistoryList } from './types';
import { getDataForIds } from '@/utils/queries.utils';
import http from '@/utils/http.utils';
import { AxiosResponse } from 'axios';

export const get_created_by_opening_stock_by_ids = async (ids: number[]) => {
  return await getDataForIds<IOpeningStockData>(ids, 'products/opening-stock/?');
};

export const get_opening_stock_history = async (filter: string) => {
  const response: AxiosResponse<IOpeningStockHistoryList> = await http.get(
    'products/opening-stock-history/?' + filter
  );
  return response.data;
};
