import moment from 'moment';
import React, { useState, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { useMutation } from '@tanstack/react-query';
import { Link, useLocation, useNavigate } from 'react-router-dom';

// ANT DESIGN IMPORTS
import type { ColumnsType } from 'antd/es/table';
import { SorterResult } from 'antd/lib/table/interface';

import {
  EditFilled,
  EyeFilled,
  PlusCircleFilled,
  LockOutlined,
  RetweetOutlined,
  IssuesCloseOutlined,
  SyncOutlined
} from '@ant-design/icons';

import {
  Input,
  Table,
  Tooltip,
  Menu,
  Form,
  TableProps,
  message,
  Spin,
  Select,
  Button,
  Modal,
  Divider
} from 'antd';

// CUSTOM COMPONENTS
import { breadcrumbItem } from '../types';
import { checkAccess } from '@/routes/acl';
import TableFilter from '@/components/FliterTable';
import AppContent from '@/components/Common/Content';
import { ConvertObjectToURL } from '@/utils/converturl';
import { get_all_acl_groups } from '@/services/acl/queries';
import { CustomModal } from '@/components/Common/CustomModal';
import ActionDropdown from '@/components/Common/Dropdownactions';
import { ExportColumnType, exportExcel } from '@/utils/exportExcel';
import TableCell from '@/components/Common/CustomizeTable/CustomCell';
import { VendorTypeSearch } from '@/components/Common/VendorTypeSearch';
import DiscussionChatModal from '@/components/Common/DiscussionChatModal';
import CustomizeTable from '@/components/Common/CustomizeTable/CustomizeTable';
import { CustomDatePresets } from '@/pages/sqlsource/report/utils/datePresets';
import CustomTable from '@/components/Common/CustomResuableInvoice/CustomTable';
import CustomGreenButton from '@/components/Common/CustomButton/CustomGreenButton';
import DeleteConfirmModal from '@/components/Common/Deleteconfirm/DeleteConfirmModal';
import {
  ICustomerDataMgmt,
  ICustomerWithDiscount,
  IUserData,
  IVendorDataMgmt,
  User
} from '@/services/users/types';
import { convertLocalToUTCString, convertUTCStringtoLocalString } from '@/utils/convertToUTC';

import {
  get_customers_with_discount_list,
  get_users_list_v2,
  get_users_list_with_acl,
  get_vendor_list_v2,
  toggle_user_status
} from '@/services/users/queries';

import {
  convert_user_to_customer_mutation,
  convert_user_to_vendor_mutation,
  update_customer_type_mutation
} from '@/services/users/mutations';
import { getUserData } from '@/utils/auth.utils';
import { DEFAULT_DATE_FORMAT } from '@/constants';
import RouteSearchV2 from '@/components/Common/CustomSearch/Routes';
import { DiscountStatus } from '@/services/offfers/enums';
import { getCategoryById } from '@/services';
import { useFilterStore } from '@/store/zustand';

// import AddressCreate from '../Address/Create';
// import AddressDetails from '../Address/Details';

interface ListUIProps {
  deleteMutation: any;
  breadcrumbItems: breadcrumbItem[];
  type?: 'customer' | 'user' | 'vendor';
}

const ListUI: React.FC<ListUIProps> = ({ deleteMutation, breadcrumbItems, type = 'user' }) => {
  const printPDFRef = useRef<any>();
  const navigate = useNavigate();
  const [allList, setAlllist] = useState<IUserData | ICustomerDataMgmt | IVendorDataMgmt>({
    total: 0,
    results: []
  });
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(100);
  const [isloading, setIsloading] = useState(true);
  const [form] = Form.useForm();
  const [formForVendorConvert] = Form.useForm();
  const [customerPopOpen, setCustomerPopOpen] = useState(false);
  const [vendorPopOpen, setVendorPopOpen] = useState(false);
  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({});
  const handleChange: TableProps<any>['onChange'] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<any>);
  };
  const [openmodalforExport, setOpenModalforexport] = useState(false);
  const [openUserDisabledModal, setOpenUserDisabledModal] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState<number>();

  const [selectedUserStatus, setSelectedUserStatus] = useState<'enable' | 'disable'>('disable');
  const currentUser = getUserData();

  const [isConvertCredit, setIsConvertCredit] = useState({
    open: false,
    id: -1,
    isCredit: false
  });

  const location = useLocation();
  const zustandFilter = useFilterStore();

  async function getCustomerList(filter = '') {
    const response = await get_customers_with_discount_list(filter);
    const customerList = response.data.results;

    for (const customer of customerList) {
      const groups = customer.groups || [];
      if (groups.length === 0) continue;

      if (!customer.discount) {
        customer.discount = { name: '', group: '', categories: [] };
      }

      customer.discount.group = groups[0].name;

      const discountGroup = groups[0].offers || [];
      if (discountGroup.length === 0) continue;

      const currentOffer = discountGroup[0];
      if (currentOffer.isActive !== DiscountStatus.ACTIVE) continue;

      customer.discount.name = currentOffer.name;

      const offerLines = currentOffer.offerLines || [];
      if (offerLines.length === 0) continue;

      const offerWithCategory = await Promise.all(
        offerLines.map(async (offerLine) => {
          const category = await getCategoryById(offerLine.categoryId);
          return { name: category.name, perc: offerLine.percentage };
        })
      );

      customer.discount.categories = offerWithCategory;
    }

    return response;
  }

  async function toggleUserStatus() {
    setIsloading(true);
    const userList = [...allList.results] as User[];

    if (selectedUserId === undefined) {
      setIsloading(false);
      setOpenUserDisabledModal(false);
      return message.error(`No user selected. Please specify which user to ${selectedUserStatus}d`);
    }

    const filteredUserIndex = userList.findIndex((user: any) => {
      const userId = type === 'user' ? user.id : user.userId;
      return userId === selectedUserId;
    });

    if (filteredUserIndex < 0) return;
    const filteredUser = userList[filteredUserIndex];

    try {
      // do optimistic update
      userList.splice(filteredUserIndex, 1);

      // Make an API Call and display success message
      await toggle_user_status(selectedUserId, selectedUserStatus);
      message.success(`User ${selectedUserStatus}d successfully`);
    } catch (error) {
      // show error message and revert the optimistic update
      message.error(`Failed to ${selectedUserStatus} user`);
      userList.splice(filteredUserIndex, 0, filteredUser);
    } finally {
      // update the state
      const updatedData = { ...allList, results: userList };
      setAlllist(updatedData);
      zustandFilter.updateState(location.pathname, { data: updatedData });
      setIsloading(false);
      setOpenUserDisabledModal(false);
    }
  }

  const getId = (record: any) => {
    return record.id;
  };
  const getUserId = (record: any) => {
    if (breadcrumbItems[0].label == 'Customers') {
      return record.userId;
    } else if (breadcrumbItems[0].label == 'Vendors') {
      return record.userId;
    }
    return record.id;
  };
  const columns: ColumnsType<any> = [
    Table.SELECTION_COLUMN,
    // Table.EXPAND_COLUMN,
    {
      title: 'S.N',
      key: 'SN',
      width: 2,
      sorter: (a, b) => a.id - b.id,
      sortOrder: sortedInfo.columnKey === 'id' ? sortedInfo.order : null,
      render: (text, record, index) => {
        return <TableCell>{(page - 1) * size + (index + 1)}</TableCell>;
      }
    },
    {
      title: 'Name',
      width: 10,
      key: 'name',
      sorter: (a, b) => {
        if (type === 'user') {
          return a.name.localeCompare(b.name);
        } else {
          return a.user.name.localeCompare(b.user.name);
        }
      },
      sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null,
      render: (record) => {
        return (
          <>
            {type === 'user' ? (
              // <Link to={`${breadcrumbItems[0].link}/${getId(record)}`} color="black">
              <TableCell>{record.name}</TableCell>
            ) : (
              // </Link>
              // <Link to={`${breadcrumbItems[0].link}/${getId(record)}`} color="black">
              <TableCell>{record.user.name}</TableCell>
              // </Link>
            )}
          </>
        );
      }
    },
    {
      title: 'Email',
      key: 'email',
      width: 10,
      sorter: (a, b) => {
        if (type === 'user') {
          return a.email.localeCompare(b.email);
        } else {
          return a.user.email.localeCompare(b.user.email);
        }
      },
      sortOrder: sortedInfo.columnKey === 'email' ? sortedInfo.order : null,
      render: (record) => {
        return (
          <>
            {type === 'user' ? (
              // <Link to={`${breadcrumbItems[0].link}/${getId(record)}`} color="black">
              <TableCell>{record.email}</TableCell>
            ) : (
              // </Link>
              // <Link to={`${breadcrumbItems[0].link}/${getId(record)}`} color="black">
              <TableCell> {record.user.email}</TableCell>
              // </Link>
            )}
          </>
        );
      }
    },
    {
      title: 'Phone',
      key: 'phone',
      width: type === 'customer' ? 4 : 7,
      sorter: (a, b) => {
        if (type === 'user') {
          return a.phone.localeCompare(b.phone);
        } else {
          return a.user.phone.localeCompare(b.user.phone);
        }
      },
      sortOrder: sortedInfo.columnKey === 'phone' ? sortedInfo.order : null,
      render: (record) => {
        return (
          <>
            {type === 'user' ? (
              // <Link to={`${breadcrumbItems[0].link}/${getId(record)}`} color="black">
              <TableCell>{record.phone}</TableCell>
            ) : (
              // </Link>
              // <Link to={`${breadcrumbItems[0].link}/${getId(record)}`} color="black">
              <TableCell>{record.user.phone}</TableCell>

              // </Link>
            )}
          </>
        );
      }
    },
    {
      title: 'Phone (Secondary)',
      key: 'secondaryPhone',
      width: type === 'customer' ? 4 : 7,
      sorter: (a, b) => {
        if (type === 'user') {
          return a.secondaryPhone.localeCompare(b.secondaryPhone);
        } else {
          return a.user.secondaryPhone.localeCompare(b.user.secondaryPhone);
        }
      },
      sortOrder: sortedInfo.columnKey === 'secondaryPhone' ? sortedInfo.order : null,
      render: (record) => {
        return (
          <>
            {type === 'user' ? (
              <TableCell>{record.secondaryPhone}</TableCell>
            ) : (
              <TableCell>{record.user.secondaryPhone}</TableCell>
            )}
          </>
        );
      }
    }
  ];

  if (type == 'user') {
    columns.push({
      title: 'ACL',
      width: 10,
      key: 'acl',
      sorter: (a, b) => {
        if (type === 'user') {
          return a.name.localeCompare(b.name);
        } else {
          return a.user.name.localeCompare(b.user.name);
        }
      },
      sortOrder: sortedInfo.columnKey === 'acl' ? sortedInfo.order : null,
      render: (record) => {
        return <TableCell>{record.aclname}</TableCell>;
      }
    });
  }

  if (type === 'customer') {
    columns.push(
      ...[
        {
          title: 'Group',
          width: 7,
          key: 'type',
          render: (record: ICustomerWithDiscount) => {
            const currentDiscount = record.discount;
            if (!currentDiscount) return;

            return currentDiscount.group;
          }
        },
        {
          title: 'Discount',
          width: 12,
          key: 'type',
          render: (record: ICustomerWithDiscount) => {
            const currentDiscount = record.discount;
            if (!currentDiscount) return;

            const formattedCategory = currentDiscount.categories
              .map((category) => `${category.name} (${category.perc}%)`)
              .join(', ');

            return formattedCategory;
          }
        }
      ]
    );
  }

  if (type == 'customer' && checkAccess('CUSTOMER_ENABLE_CREDIT')) {
    columns.push({
      title: 'Credit',
      width: 3,
      key: 'type',
      sorter: (a, b) => a.isCredit.localeCompare(b.isCredit),
      render: (record) => {
        return (
          <div data-boolean={record.isCredit} className="capitalize">
            {record.isCredit ? 'Yes' : 'No'}
          </div>
        );
      }
    });
  }

  columns.push({
    title: 'Created At',
    key: 'createdAt',
    width: 7,
    sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
    sortOrder: sortedInfo.columnKey === 'createdAt' ? sortedInfo.order : null,
    render: (record) => {
      return moment(record.createdAt).format(DEFAULT_DATE_FORMAT);
    }
  });

  columns.push({
    title: 'Actions',
    key: 'actions',
    width: 3,
    fixed: 'right',
    render: (record) => {
      const userId = getUserId(record);
      const isDisabled = type === 'user' ? record.disabled : record.user.disabled;
      const confirmFunction = () => {
        deleteMutation.mutate(record.id);
      };
      const menuItems: {
        key: string;
        label: JSX.Element;
        onClick?: () => void;
      }[] = [
        {
          key: '1',
          label: (
            <Tooltip title="Update User" color="blue">
              <Link to={`${breadcrumbItems[0].link}/${getId(record)}`}>
                <div className="flex justify-between gap-3">
                  <EditFilled
                    style={{
                      transform: 'scale(1.1)',
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  />
                  <div>User</div>
                </div>
              </Link>
            </Tooltip>
          )
        },
        {
          key: '2',
          label: (
            <Tooltip title="Add Address" color="blue">
              <Link to={`/users/${userId}/address/new`}>
                <div className="flex justify-between gap-3">
                  <PlusCircleFilled
                    style={{ transform: 'scale(1.1)', display: 'flex', alignItems: 'center' }}
                  />
                  <div>Address</div>
                </div>
              </Link>
            </Tooltip>
          )
        },
        {
          key: '3',
          label: (
            <Tooltip title="View Address" color="blue">
              <Link to={`/users/${userId}/address`}>
                <div className="flex justify-between gap-3">
                  <EyeFilled
                    style={{ transform: 'scale(1.3)', display: 'flex', alignItems: 'center' }}
                  />
                  <div>Address</div>
                </div>
              </Link>
            </Tooltip>
          )
        },
        {
          key: '4',
          label: (
            <Tooltip title="Add Tax" color="blue">
              <Link to={`/users/${userId}/tax/new`}>
                <div className="flex justify-between gap-3">
                  <PlusCircleFilled
                    style={{ transform: 'scale(1.1)', display: 'flex', alignItems: 'center' }}
                  />
                  <div>Tax</div>
                </div>
              </Link>
            </Tooltip>
          )
        },
        {
          key: '5',
          label: (
            <Tooltip title="View Tax">
              <Link to={`/users/${userId}/tax`}>
                <div className="flex justify-between gap-3">
                  <EyeFilled
                    style={{ transform: 'scale(1.3)', display: 'flex', alignItems: 'center' }}
                  />
                  <div>Tax</div>
                </div>
              </Link>
            </Tooltip>
          )
        },
        {
          key: '6',
          label: <DeleteConfirmModal FunctionAfterOk={confirmFunction} fromUser={true} />
        },
        {
          key: '7',
          label: (
            <DiscussionChatModal slug="user" rreference="other" id={record.id} fromUser={true} />
          )
        },
        {
          key: '8',
          label: (
            <Link to={`/acl/user/${record.id}`}>
              <Tooltip title="ACL Groups" color="blue">
                <div className="flex justify-between gap-3">
                  <LockOutlined
                    style={{ transform: 'scale(1.1)', display: 'flex', alignItems: 'center' }}
                  />
                  <div>ACL</div>
                </div>
              </Tooltip>
            </Link>
          )
        }
      ];

      if (type != 'user') {
        menuItems.push({
          key: '9',
          label: (
            <Link to={type == 'customer' ? `/ledger/${record.id}` : `/ledger/vendor/${record.id}`}>
              <Tooltip title="ACL Groups" color="blue">
                <div className="flex justify-between gap-3">
                  <div>Ledger</div>
                </div>
              </Tooltip>
            </Link>
          )
        });

        if (type === 'customer') {
          menuItems.push({
            key: '12',
            label: (
              <Tooltip title="Convert to Vendor" color="blue">
                <div className="flex justify-between gap-3">
                  <RetweetOutlined
                    style={{
                      transform: 'scale(1.1)',
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  />
                  <div>Vendor</div>
                </div>
              </Tooltip>
            ),
            onClick: () => {
              formForVendorConvert.resetFields();
              showVendorPopconfirm(record.userId);
            }
          });

          // If has permissions, then show convert credit debit
          checkAccess('CUSTOMER_ENABLE_CREDIT') &&
            menuItems.push({
              key: 'convert_credit_debit',
              label: (
                <div className="flex justify-between items-center gap-3">
                  <SyncOutlined className="[&>svg]:m-0" />
                  {record.isCredit ? 'Revoke' : 'Allow'} Credit
                </div>
              ),
              onClick: () =>
                setIsConvertCredit({ open: true, id: record.id, isCredit: record.isCredit })
            });
        } else if (type === 'vendor') {
          menuItems.push({
            key: '11',
            label: (
              <Tooltip title="Convert to Customer" color="blue">
                <div className="flex justify-between gap-3">
                  <RetweetOutlined
                    style={{
                      transform: 'scale(1.1)',
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  />
                  <div>Customer</div>
                </div>
              </Tooltip>
            ),
            onClick: () => {
              showCustomerPopconfirm(record.userId);
            }
          });
        }
      }
      if (type === 'user') {
        menuItems.push(
          {
            key: '10',
            label: (
              <Tooltip title="Edit HR" color="blue">
                <Link to={`/hr/${record.id}`}>
                  <div className="flex justify-between gap-3">
                    <EditFilled
                      style={{
                        transform: 'scale(1.1)',
                        display: 'flex',
                        alignItems: 'center'
                      }}
                    />
                    <div>HR</div>
                  </div>
                </Link>
              </Tooltip>
            )
          },
          {
            key: '11',
            label: (
              <Tooltip title="Convert to Customer" color="blue">
                <div className="flex justify-between gap-3">
                  <RetweetOutlined
                    style={{
                      transform: 'scale(1.1)',
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  />
                  <div>Customer</div>
                </div>
              </Tooltip>
            ),
            onClick: () => {
              showCustomerPopconfirm(record.id);
            }
          },
          {
            key: '12',
            label: (
              <Tooltip title="Convert to Vendor" color="blue">
                <div className="flex justify-between gap-3">
                  <RetweetOutlined
                    style={{
                      transform: 'scale(1.1)',
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  />
                  <div>Vendor</div>
                </div>
              </Tooltip>
            ),
            onClick: () => {
              formForVendorConvert.resetFields();
              showVendorPopconfirm(record.id);
            }
          }
        );
      }

      menuItems.push({
        key: 'user_toggle_service_status',
        label: (
          <Tooltip title={`${isDisabled ? 'Enable' : 'Disable'} User`} color="blue">
            <div
              className={`flex justify-between gap-3 ${
                currentUser.id === userId ? 'cursor-not-allowed text-gray-500' : ''
              }`}>
              <IssuesCloseOutlined
                style={{
                  transform: 'scale(1.1)',
                  display: 'flex',
                  alignItems: 'center'
                }}
              />
              <div>{`${isDisabled ? 'Enable' : 'Disable'} User`}</div>
            </div>
          </Tooltip>
        ),
        onClick: () => onClickDisableUser(record)
      });
      const menu = <Menu items={menuItems} />;
      return <ActionDropdown menu={menu} />;
    }
  });

  async function handleConvertCredit() {
    setIsloading(true);
    const details = { ...isConvertCredit };
    setIsConvertCredit({ ...details, open: false });

    if (details.id === -1) {
      setIsloading(false);
      setIsConvertCredit({ open: false, id: -1, isCredit: false });
      return message.error('No customer selected. Please specify which customer to convert');
    }

    try {
      const response = await update_customer_type_mutation(details.id, !details.isCredit);
      message.success(
        `Customer is successfully ${details.isCredit ? 'removed from credit' : 'added to credit'}`
      );

      const updatedList = allList.results.map((user) => {
        if (user.id === details.id) {
          return { ...user, isCredit: !details.isCredit };
        }
        return user;
      }) as ICustomerDataMgmt['results'];

      setAlllist({ ...allList, results: updatedList });
      zustandFilter.updateState(location.pathname, { data: updatedList });
    } catch (error) {
      message.error('Failed to change type of customer');
    } finally {
      setIsloading(false);
      setIsConvertCredit({ open: false, id: -1, isCredit: false });
    }
  }

  function onClickDisableUser(record: any) {
    const selectedUser = type === 'user' ? record.id : record.userId;
    if (currentUser.id === selectedUser) {
      message.error('You cannot disable yourself');
      return;
    }

    const isDisabled = type === 'user' ? record.disabled : record.user.disabled;

    setSelectedUserStatus(isDisabled ? 'enable' : 'disable');
    setSelectedUserId(selectedUser);
    setOpenUserDisabledModal(true);
  }

  const showCustomerPopconfirm = (userId: number) => {
    setSelectedUserId(userId);
    setCustomerPopOpen(true);
  };

  const showVendorPopconfirm = (userId: number) => {
    setSelectedUserId(userId);
    setVendorPopOpen(true);
  };

  const handleConvertToCustomer = async () => {
    try {
      setCustomerPopOpen(false);
      if (selectedUserId) {
        setIsloading(true);
        await convertUserToCustomerMutation.mutateAsync(selectedUserId);
      } else {
        message.error('User not found please try again!');
      }
    } catch (e: any) {
      setIsloading(false);
      message.error(`${e.message}`);
    }
  };

  const handleConvertToVendor = async (values = formForVendorConvert.getFieldsValue()) => {
    try {
      setVendorPopOpen(false);
      if (selectedUserId) {
        setIsloading(true);
        values.userId = selectedUserId;
        await convertUserToVendorMutation.mutateAsync(values);
      } else {
        message.error('User not found please try again!');
      }
    } catch (e: any) {
      setIsloading(false);
      message.error(`${e.message}`);
    }
  };

  const convertUserToCustomerMutation = useMutation(convert_user_to_customer_mutation, {
    onSuccess: async () => {
      message.success(`${type} converted to customer successfully`);
      const values = form.getFieldsValue();
      values.endDate = convertLocalToUTCString(values.endDate);
      values.startDate = convertLocalToUTCString(values.startDate);
      delete values.dateCustom;
      delete values.startDateNepali;
      delete values.endDateNepali;
      const url = ConvertObjectToURL(values);
      if (type === 'user') {
        const response = await get_users_list_v2(url);
        setAlllist(response?.data);
        zustandFilter.updateState(location.pathname, { data: response?.data });
      }
      setIsloading(false);
    },
    onError: (e: any) => {
      setIsloading(false);
      message.error(`${e.message}`);
    }
  });

  const convertUserToVendorMutation = useMutation(convert_user_to_vendor_mutation, {
    onSuccess: async () => {
      message.success(`${type} converted to vendor successfully`);
      const values = form.getFieldsValue();
      values.endDate = convertLocalToUTCString(values.endDate);
      values.startDate = convertLocalToUTCString(values.startDate);
      delete values.dateCustom;
      delete values.startDateNepali;
      delete values.endDateNepali;
      const url = ConvertObjectToURL(values);
      let response;
      if (type === 'user') {
        response = await get_users_list_v2(url);
      } else if (type === 'customer') {
        response = await getCustomerList(url);
      } else {
        response = await get_vendor_list_v2(url);
      }

      setAlllist(response?.data);
      zustandFilter.updateState(location.pathname, { data: response?.data });
      setIsloading(false);
    },
    onError: (e: any) => {
      setIsloading(false);
      message.error(`${e.message}`);
    }
  });

  const onSubmitFilterr = async (val: string) => {
    setIsloading(true);
    let response;
    let updatedTableData;
    if (type === 'user') {
      // response = await get_users_list_v2(val);
      response = await get_users_list_with_acl(val);
      const aclresponse = await get_all_acl_groups();
      const mappedaclwithID: any = {};
      for (let ind = 0; ind < aclresponse?.data?.length; ind++) {
        mappedaclwithID[aclresponse.data[ind].id] = aclresponse.data[ind].name;
      }
      for (let ind = 0; ind < response.data.results.length; ind++) {
        let acl = '';
        const size: number = response.data.results[ind].acl.length;
        // console.log('size', size);
        for (let i = 0; i < response.data.results[ind].acl.length; i++) {
          acl += `${mappedaclwithID[response.data.results[ind].acl[i].groupId]}${
            i == size - 1 ? '' : ', '
          }`;
        }
        response.data.results[ind].aclname = acl;
      }

      setAlllist(response?.data);
      updatedTableData = response.data;
    } else if (type === 'customer') {
      response = await getCustomerList(val);
      const updatedReponse: ICustomerDataMgmt = response.data;
      updatedReponse.results = updatedReponse.results.map((data: any) => {
        return { ...data, secondaryPhone: data.user.secondaryPhone };
      });
      setAlllist(updatedReponse);
      updatedTableData = updatedReponse;
    } else {
      response = await get_vendor_list_v2(val);
      const updatedReponse: IVendorDataMgmt = response.data;
      updatedReponse.results = updatedReponse.results.map((data: any) => {
        return { ...data, secondaryPhone: data.user.secondaryPhone };
      });
      setAlllist(updatedReponse);
      updatedTableData = updatedReponse;
    }
    setIsloading(false);
    setPage(1);
    setSize(100);
    return updatedTableData;
  };

  const onPagination = async (pageNo = 1, totalSize = 100, isSize = false) => {
    setIsloading(true);
    const values = form.getFieldsValue();
    values.endDate = convertLocalToUTCString(values.endDate);
    values.startDate = convertLocalToUTCString(values.startDate);
    delete values.dateCustom;
    delete values.startDateNepali;
    delete values.endDateNepali;
    if (isSize) {
      values.skip = 0;
      values.count = totalSize;
      setPage(1);
      setSize(totalSize);
    } else {
      values.skip = (pageNo - 1) * totalSize;
      values.count = totalSize;
      setPage(pageNo);
    }
    const url = ConvertObjectToURL(values);
    // console.log('Url', url);
    let response;
    if (type === 'user') {
      response = await get_users_list_with_acl(url);
      const aclresponse = await get_all_acl_groups();
      const mappedaclwithID: any = {};
      for (let ind = 0; ind < aclresponse?.data?.length; ind++) {
        mappedaclwithID[aclresponse.data[ind].id] = aclresponse.data[ind].name;
      }
      for (let ind = 0; ind < response.data.results.length; ind++) {
        let acl = '';
        const size: number = response.data.results[ind].acl.length;
        // console.log('size', size);
        for (let i = 0; i < response.data.results[ind].acl.length; i++) {
          acl += `${mappedaclwithID[response.data.results[ind].acl[i].groupId]}${
            i == size - 1 ? '' : ', '
          }`;
        }
        response.data.results[ind].aclname = acl;
      }
    } else if (type === 'customer') {
      response = await getCustomerList(url);
    } else {
      response = await get_vendor_list_v2(url);
    }

    setAlllist(response?.data);
    setIsloading(false);
    return response?.data;
  };

  const handleExport = () => {
    setIsloading(true);
    try {
      const columns: ExportColumnType[] = [
        {
          title: 'S.N',
          dataIndex: 'SN',
          width: 100
        },
        {
          title: 'Name',
          width: 250,
          dataIndex: 'name'
        },
        {
          title: 'Email',
          width: 250,
          dataIndex: 'email'
        },
        {
          title: 'Phone',
          width: 200,
          dataIndex: 'phone'
        },
        {
          title: 'Secondary Phone',
          width: 200,
          dataIndex: 'secondaryPhone'
        },
        {
          title: 'Created At',
          width: 200,
          dataIndex: 'date'
        }
      ];
      if (allList.results.length === 0) {
        message.error('No Data to Export');
        return;
      }
      const dataUpdated = allList.results.map((item: any, index: number) => {
        return {
          ...item,
          SN: index + 1,
          date: convertUTCStringtoLocalString(item.createdAt, DEFAULT_DATE_FORMAT),
          email: type === 'user' ? item.email : item.user.email,
          phone: type === 'user' ? item.phone : item.user.phone,
          name: type === 'user' ? item.name : item.user.name
        };
      });
      exportExcel(
        type === 'user'
          ? [
              ...columns,
              {
                title: 'acl',
                width: 200,
                dataIndex: 'aclname'
              }
            ]
          : columns,
        dataUpdated,
        `${type} List`
      );
    } catch (err: any) {
      console.log(err);
    } finally {
      setIsloading(false);
    }
  };

  const columsforPrint: any = [
    {
      label: 'S.N',
      dataIndex: 'SN',
      render: (text: string) => {
        return <div className="text-center">{text}</div>;
      }
    },
    {
      label: 'Name',
      dataIndex: 'name',
      render: (text: string) => {
        return <div className="text-center">{text}</div>;
      }
    },
    {
      label: 'Email',
      dataIndex: 'email',
      render: (text: string) => {
        return <div className="text-center">{text}</div>;
      }
    },
    {
      label: 'Phone',
      dataIndex: 'phone',
      render: (text: string) => {
        return <div className="text-center">{text}</div>;
      }
    },
    {
      label: 'Secondary Phone',
      dataIndex: 'secondaryPhone',
      render: (text: string) => {
        return <div className="text-center">{text}</div>;
      }
    },
    {
      label: 'Created At',
      dataIndex: 'date',
      render: (text: string) => {
        return <div className="text-center">{text}</div>;
      }
    }
  ];

  const handlePDFExport = useReactToPrint({
    content: () => printPDFRef.current
  });

  return (
    <Spin spinning={isloading}>
      <Modal
        title="Confirmation"
        visible={customerPopOpen}
        onOk={async () => await handleConvertToCustomer()}
        onCancel={() => {
          setSelectedUserId(undefined);
          setCustomerPopOpen(false);
        }}>
        <div>Are you sure you want to extend this user into customer?</div>
      </Modal>
      <Modal
        title="Confirmation"
        visible={isConvertCredit.open}
        onOk={handleConvertCredit}
        onCancel={() => setIsConvertCredit({ open: false, id: -1, isCredit: false })}>
        <div>
          {`Customer will be ${
            isConvertCredit.isCredit ? 'removed from' : 'added to'
          } credit. Are you sure?`}
        </div>
      </Modal>
      <Modal
        title="Confirmation"
        visible={openUserDisabledModal}
        onOk={toggleUserStatus}
        onCancel={() => {
          setSelectedUserId(undefined);
          setOpenUserDisabledModal(false);
        }}>
        <div>Are you sure you want to {selectedUserStatus} this user?</div>
      </Modal>
      <Modal
        title="Confirmation"
        visible={vendorPopOpen}
        onOk={async () => formForVendorConvert.submit()}
        onCancel={() => {
          setSelectedUserId(undefined);
          setVendorPopOpen(false);
        }}>
        <div>Are you sure you want to extend this user into vendor?</div>
        <Divider style={{ marginTop: '5px', marginBottom: '5px' }} />
        <div>
          <Form
            form={formForVendorConvert}
            disabled={isloading}
            onFinish={handleConvertToVendor}
            initialValues={{ vendorTypeId: null }}
            layout="vertical"
            validateTrigger={'onChange'}
            autoComplete="off">
            <VendorTypeSearch
              isAll={false}
              formData={{ formName: 'vendorTypeId', label: 'Vendor Type' }}
              required={true}
            />
          </Form>
        </div>
      </Modal>
      <CustomModal
        footer={false}
        isModalOpen={openmodalforExport}
        setIsModalOpen={setOpenModalforexport}
        title={`${type} View For PDF print`}>
        <div className="flex justify-end mb-3">
          <Button type="primary" onClick={handlePDFExport}>
            Print Pdf
          </Button>
        </div>
        <div style={{ maxHeight: '80vh', overflow: 'scroll' }}>
          <CustomTable
            columns={
              type == 'user'
                ? [
                    ...columsforPrint,
                    {
                      label: 'ACL',
                      dataIndex: 'aclname',
                      render: (text: string) => {
                        return <div className="text-center">{text}</div>;
                      }
                    }
                  ]
                : columsforPrint
            }
            datas={allList.results.map((item: any, index: number) => {
              return {
                ...item,
                SN: index + 1,
                date: convertUTCStringtoLocalString(item.createdAt, DEFAULT_DATE_FORMAT),
                email: type === 'user' ? item.email : item.user.email,
                phone: type === 'user' ? item.phone : item.user.phone,
                name: type === 'user' ? item.name : item.user.name
              };
            })}
            reff={printPDFRef}
            title={`${type} List`}
            onAuto={handlePDFExport}
          />
        </div>
      </CustomModal>
      <AppContent
        breadcrumbItems={breadcrumbItems}
        backgroundWhite={false}
        withfilter={true}
        button={
          <>
            {/* <div className="flex flex-wrap items-center justify-end"> */}
            <div>
              <TableFilter
                onInitialLoad={({ data, pagination }) => {
                  setPage(pagination.page);
                  setSize(pagination.size);

                  if (data) {
                    setAlllist(data as any);
                    setIsloading(false);
                  }
                }}
                defaultValues={{
                  dateCustom: [...CustomDatePresets.Today],
                  skip: 0,
                  count: 100,
                  value: '',
                  disabled: false
                }}
                form={form}
                initial={true}
                onSubmit={onSubmitFilterr}
                // outsidestyle={type == 'customer' ? 'grid' : 'flex flex-wrap items-end gap-2'}
                styleforbuttons={'flex items-center justify-end'}
                style={`grid sm:grid-cols-1 md:grid-cols-${type == 'customer' ? 4 : 3} gap-2`}
                buttons={
                  <>
                    {type === 'user' ? (
                      <>
                        {checkAccess('CREATE_USER') && (
                          <div>
                            {/* <CustomButton
                                
                                
                                backgroundColor="#1890ff"
                                // marginTop="1.7rem"
                                 
                              /> */}
                            <CustomGreenButton
                              text="Add User"
                              Linkto={`${breadcrumbItems[0].link}/new`}
                              onClick={() => navigate(`${breadcrumbItems[0].link}/new`)}
                            />
                          </div>
                        )}
                      </>
                    ) : (
                      <>
                        {type === 'customer' ? (
                          <>
                            {checkAccess('CREATE_CUSTOMER') && (
                              <div>
                                {/* <CustomButton
                                    onClick={() => navigate(`${breadcrumbItems[0].link}/new`)}
                                    text="Add User"
                                    backgroundColor="#1890ff"
                                    // marginTop="1.7rem"
                                    Linkto={`${breadcrumbItems[0].link}/new`}
                                  /> */}
                                <CustomGreenButton
                                  text="Add User"
                                  Linkto={`${breadcrumbItems[0].link}/new`}
                                  onClick={() => navigate(`${breadcrumbItems[0].link}/new`)}
                                />
                              </div>
                            )}
                          </>
                        ) : (
                          <>
                            {checkAccess('CREATE_VENDOR') && (
                              <div>
                                {/* <CustomButton
                                    onClick={() => navigate(`${breadcrumbItems[0].link}/new`)}
                                    text="Add User"
                                    backgroundColor="#1890ff"
                                    // marginTop="1.7rem"
                                    Linkto={`${breadcrumbItems[0].link}/new`}
                                  /> */}
                                <CustomGreenButton
                                  text="Add User"
                                  Linkto={`${breadcrumbItems[0].link}/new`}
                                  onClick={() => navigate(`${breadcrumbItems[0].link}/new`)}
                                />
                              </div>
                            )}
                          </>
                        )}
                      </>
                    )}
                    {/* <CustomButton
                        text="Export"
                        backgroundColor="#1890ff"
                        onClick={handleExport}
                      /> */}
                    <div>
                      <ActionDropdown
                        button={true}
                        buttonJSX={
                          <Button type="primary" className="ml-2">
                            Export
                          </Button>
                        }
                        menu={
                          <Menu
                            items={[
                              {
                                key: '1',
                                label: (
                                  <Tooltip title="Export Excel" color="blue">
                                    <div className="text-center">Excel</div>
                                  </Tooltip>
                                ),
                                onClick: () => {
                                  handleExport();
                                }
                              },
                              {
                                key: '2',
                                label: (
                                  <Tooltip title="Export PDF" color="blue">
                                    <div className="text-center">PDF</div>
                                  </Tooltip>
                                ),
                                onClick: () => {
                                  setOpenModalforexport(true);
                                }
                              }
                            ]}
                          />
                        }
                      />
                    </div>
                  </>
                }>
                <Form.Item label="Search" name="value">
                  <Input placeholder="Search" />
                </Form.Item>
                <Form.Item label="Status" name="disabled">
                  <Select allowClear>
                    <Select.Option value={true}>Disabled</Select.Option>
                    <Select.Option value={false}>Enabled</Select.Option>
                  </Select>
                </Form.Item>
                {type == 'customer' && (
                  <RouteSearchV2 hasParentFormItem={false} name={'routeId'} label="Route" />
                )}
              </TableFilter>
            </div>
            {/* </div> */}
          </>
        }>
        <CustomizeTable
          form={form}
          data={allList.results}
          columns={columns}
          notshowPagination={true}
          customScroll={{ x: type === 'customer' ? 1800 : 1400, y: '75vh' }}
          tableName={'users-list'}
          paginationDatas={{
            page: page,
            total: allList.total,
            size: size,
            onPagination
          }}
          toSort={handleChange}
        />
      </AppContent>
    </Spin>
  );
};

export default ListUI;
