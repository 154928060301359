import axios from 'axios';

import { API_URL, FRONT_VERSION } from '../constants/config';
import { globalSignOut, isTokenExpired } from './auth.utils';
import { getTokenFromStorage } from './storage.utils';
import { getRefreshedToken } from './refreshTokenManger';
import { AuthErrorEnum } from '@/services/auth/types';

export const httpBase = (isFile = false) => {
  const axiosInstance = axios.create({
    baseURL: `${API_URL}/`,
    responseType: 'json',
    headers: {
      Accept: 'application/json',
      'front-version': FRONT_VERSION,
      'Content-Type': isFile ? 'multipart/form-data' : 'application/json'
    }
  });

  axiosInstance.interceptors.request.use(
    async (config) => {
      let accessToken = getTokenFromStorage();
      if (accessToken && isTokenExpired(accessToken)) {
        try {
          accessToken = await getRefreshedToken();
        } catch {
          globalSignOut();
          return Promise.reject(new Error('Session expired'));
        }
      }

      // Set Authorization header
      if (accessToken) {
        if (!config.headers) config.headers = {};
        config.headers['Authorization'] = `Bearer ${accessToken}`;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );

  // If request interceptors failed to refresh token, log out
  axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (error.response?.status === 401) {
        const errorMesage = error.response?.data?.message;

        // If already in login page, no need to log out
        if (errorMesage !== AuthErrorEnum.CREDENTIALS) {
          globalSignOut();
        }
        return Promise.reject(new Error('Unauthorized: Logging out'));
      }
      return Promise.reject(error);
    }
  );

  return axiosInstance;
};
