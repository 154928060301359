import { Button, Form, Input, Menu, Spin, TableProps, Tooltip, message } from 'antd';
import { useRef, useState } from 'react';
import AppContent from '@/components/Common/Content/Content';
import CustomizeTable from '@/components/Common/CustomizeTable/CustomizeTable';
import { ColumnsType, SorterResult } from 'antd/lib/table/interface';
import TableCell from '@/components/Common/CustomizeTable/CustomCell';
import { convertLocalToUTCString, convertUTCStringtoLocalString } from '@/utils/convertToUTC';
import AccountFilterTable from '@/components/AccountFilterTable/v2';
import moment from 'moment';
import {
  get_account_history_ids,
  get_account_list_custom,
  get_journal_details
} from '@/services/accounts/queries';
import {
  ICustomViewAccount,
  ICustomViewAccountResponse,
  ICustomViewAccountTableData
} from '@/services/accounts/types';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';
import { ConvertObjectToURL } from '@/utils/converturl';
import { getUserData } from '@/utils/auth.utils';

import ActionDropdown from '@/components/Common/Dropdownactions';
import { ExportColumnType, exportExcel } from '@/utils/exportExcel';
import { checkAccess } from '@/routes/acl';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import CustomViewIcon from '@/components/Common/CustomIcons/CustomViewIcon';
import { CustomModal } from '@/components/Common/CustomModal';
import { useReactToPrint } from 'react-to-print';
import { AccountRulesEvent, redirectData } from '@/services/accounts/enums';
import { get_payment_details } from '@/services/payments/queries';
import { PaymentAgainst } from '@/services/payments/enums';
import { get_sell_return_details_by_id } from '@/services/sell/queries';
import { get_purchase_reutrn_details_by_id } from '@/services/purchases/queries';
import CustomErrorModal from '@/components/Common/CustomErrorModal';
import CustomTableRowSpan from '@/components/Common/CustomResuableInvoice/CustomTableRowSpan';

import { optionalDateSorter } from '@/utils/sorter.utils';
import getAccountInitialDate from '@/utils/getAccountInitialDate';
import { DEFAULT_DATE_FORMAT } from '@/constants';
import { getAccountDetails, getSingleHistory } from '@/services/accounts/services';
import LocationSearchV2 from '@/components/Common/CustomSearch/Location';
import AccountSearchV2 from '@/components/Common/CustomSearch/Accounts/AccountSearchV2';
import { createEmptyPromise } from '@/utils/createEmptyPromise';
import { addLocationToJournal } from '../../detailedLedger-2/services/location.services';
import getGroupedData from '@/utils/getGroupedData';
import { useFinancialYearStore } from '@/store/zustand/financial-year';

interface ISavedData {
  journalLines: ICustomViewAccountResponse;
  selectedAccount: string;
  isRunningBalance: boolean;
  pageRunningBalance: {
    [key: number]: { [key: number]: number };
  };
  openingBalance: string;
  closingBalance: { [key: number]: number };
  tableData: ICustomViewAccountTableData[];
  exportData: ICustomViewAccountTableData[];
}

const JournalLinesList = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isRunningBalance, setIsRunningBalance] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [form] = Form.useForm();
  const [searchParams] = useSearchParams();
  const accountId = searchParams.get('accountId');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(100);
  const [pageRunningBalance, setPageRunningBalance] = useState<{
    [key: number]: { [key: number]: number };
  }>(
    {} as {
      [key: number]: { [key: number]: number };
    }
  );
  const printPDFRef = useRef<any>();
  const [journalLinesList, setJournalLinesList] = useState<ICustomViewAccountResponse>(Object);
  const [lines, setLines] = useState<ICustomViewAccountTableData[]>([]);
  const [exportData, setExportData] = useState<ICustomViewAccountTableData[]>([]);

  const { preferences } = getUserData();
  const preferenceLocationId = preferences?.preferences
    ? JSON.parse(preferences?.preferences)?.locationId
    : undefined;

  const zustandFinancialYear = useFinancialYearStore();

  const [locationId, setLocationId] = useState<number>(preferenceLocationId);
  const [openingBalance, setOpeningBalance] = useState<string>('--');
  const [closingBalance, setClosingBalance] = useState<{ [key: number]: number }>(
    {} as { [key: number]: number }
  );
  const [selectedAccount, setSelectedAccount] = useState<string>('--');
  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({});
  const [openModalForPdfExport, setOpenModalForPdfExport] = useState<boolean>(false);
  const handleChange: TableProps<any>['onChange'] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<any>);
  };

  const breadcrumbItems = [
    {
      label: 'General Ledger',
      link: '/accounts/journal-lines'
    }
  ];

  const onSubmitFilter = async (val: string) => {
    //console.log('filterVal', val);
    setPageRunningBalance(() => {
      return {};
    });
    const data = await getInfo(val);
    setPage(1);
    setSize(100);
    return data;
  };

  const getTransactionIdForEndDay = (
    tableData: ICustomViewAccountTableData[],
    transDate: string,
    accountId: number
  ) => {
    const targetDate = new Date(transDate);

    const validData = tableData.filter(
      (val) =>
        moment(val.journalDate).format('YYYY-MM-DD') === transDate && val.accountId === accountId
    );

    const maxDateTrans = validData.reduce((max, item) => {
      if (item.journalDate && max.journalDate) {
        const currentDate = new Date(item.journalDate);
        const maxDate = new Date(max.journalDate);

        if (currentDate >= targetDate && currentDate > maxDate) {
          return item;
        } else if (currentDate.getTime() === maxDate.getTime()) {
          const currentCreatedDate = new Date(item.createdAt);
          const maxCreatedDate = new Date(max.createdAt);
          return currentCreatedDate > maxCreatedDate ? item : max;
        } else {
          return max;
        }
      } else {
        return max;
      }
    }, validData[0]);

    return maxDateTrans.journalLineId;
  };

  const getInfo = async (filter = '', pageNo = 1, isResetPageRunningBalance = false) => {
    setIsLoading(true);
    setIsRunningBalance(false);
    setSelectedAccount('--');

    const urlParams = new URLSearchParams(filter);
    const accountId = urlParams.get('accountId') as string;
    const startDate = urlParams.get('startDate');
    const endDate = urlParams.get('endDate');
    urlParams.append('isAsc', 'true');

    const accountIds = accountId ? accountId.split(',').map(Number) : [];
    const accountIdFilter = accountIds.map((id) => `accountIds[]=${id}`).join('&');
    urlParams.delete('accountId');

    // Add accountIds to filter if exists
    filter = `${urlParams.toString()}${accountIds.length ? `&${accountIdFilter}` : ''}`;

    const mergeValue = new Set();
    const response = await get_account_list_custom(filter);
    setJournalLinesList(response);

    // For Filter to return all current calculations
    const savedData = { journalLines: response } as ISavedData;

    // Combine results and otherJournals
    const groupedJournalLists = getGroupedData(
      [...response.results, ...response.otherJournals],
      'journalId'
    );

    const journalLists = Object.keys(groupedJournalLists).flatMap((key) => {
      const journalGroups = groupedJournalLists[key];
      journalGroups.sort((a, b) => a.journalLineId - b.journalLineId);
      return journalGroups;
    });

    let opBalance = 0;
    const allJournals = { results: journalLists, total: response.total };

    let tableData: ICustomViewAccountTableData[] = [];
    const runningBalanceObj: { [key: number]: number } = {} as {
      [key: number]: number;
    };

    const allAccountIds = [...new Set(allJournals.results.map((value) => value.accountId))];

    const historyPromise =
      accountId && accountIds.length > 0
        ? get_account_history_ids(startDate, endDate, allAccountIds)
        : createEmptyPromise([]);

    const selectedAccountPromise = accountId
      ? getAccountDetails(accountIds[0])
      : createEmptyPromise(undefined);

    const [allAccountsHistory, selectedAccount] = await Promise.all([
      historyPromise,
      selectedAccountPromise
    ]);

    setSelectedAccount(selectedAccount?.name || '--');
    savedData.selectedAccount = selectedAccount?.name || '--';

    tableData = await addLocationToJournal(journalLists);

    if (accountId && tableData.length > 0) {
      setIsRunningBalance(true);
      savedData.isRunningBalance = true;
      const currentFinancialYear = zustandFinancialYear.currentFinancialYear;
      const currentFinancialStart = currentFinancialYear?.startDate as string;

      // Get 1 day before current financial start
      const currentFinancialHistoryDate = moment(currentFinancialStart)
        .subtract(1, 'days')
        .format('YYYY-MM-DD');

      // For Next
      const responseNext: ICustomViewAccount[] = [];
      tableData.sort((a, b) => optionalDateSorter(a.journalDate, b.journalDate));

      // Op Bal
      const opBalStart = tableData.find(
        (val) => val.accountId === parseInt(accountId)
      )?.journalDate;

      let opBalDate = moment(opBalStart ? opBalStart : tableData[0].journalDate)
        .subtract(1, 'days')
        .format('YYYY-MM-DD');

      // use currentfinancial if opBalDate less than currentfinancial
      if (moment(opBalDate).isBefore(currentFinancialStart)) {
        opBalDate = currentFinancialHistoryDate;
      }

      if (pageNo === 1) {
        if (
          allAccountsHistory.find(
            (val) => val.date === opBalDate && val.accountId === parseInt(accountId)
          )
        ) {
          const allHistory = allAccountsHistory.filter(
            (val) => val.date === opBalDate && val.accountId === parseInt(accountId)
          );

          const selectedOpBal = getSingleHistory(allHistory, opBalDate);
          if (selectedOpBal) opBalance = selectedOpBal.balance;
        } else {
          const selectedOpBal = allAccountsHistory
            .filter((val) => val.accountId === parseInt(accountId))
            .find((val) => val.date < opBalDate && val.date >= currentFinancialStart);
          if (selectedOpBal) opBalance = selectedOpBal.balance;
        }

        if (
          !allAccountsHistory.find(
            (val) => val.date < opBalDate && val.accountId === parseInt(accountId)
          )
        ) {
          opBalance = 0;
        }
      } else {
        opBalance = closingBalance[pageNo - 1];
      }

      // Group and sort
      const groupedData = tableData.reduce<Record<number, ICustomViewAccountTableData[]>>(
        (acc, item) => {
          const key = item.journalId;

          if (!acc[key]) {
            acc[key] = [];
          }

          acc[key].push(item);
          return acc;
        },
        {}
      );

      const sortedKeys = Object.keys(groupedData).sort((a, b) => {
        const journalDateA = groupedData[parseInt(a)][0].journalDate;
        const journalDateB = groupedData[parseInt(b)][0].journalDate;
        return optionalDateSorter(journalDateA, journalDateB);
      });

      const sortedGroupedData: [number, ICustomViewAccountTableData[]][] = sortedKeys.map((key) => [
        parseInt(key),
        groupedData[parseInt(key)]
      ]);

      const pageRunBal = isResetPageRunningBalance ? {} : pageRunningBalance;

      tableData = sortedGroupedData.flatMap((sGroup, index) => {
        let group = sGroup[1];

        if (pageNo === 1 && index === 0) {
          group = group.map((g) => {
            let startTrans = 0;
            const startTransDate = moment(g.journalDate).subtract(1, 'days').format('YYYY-MM-DD');
            if (
              allAccountsHistory.find(
                (val) => val.date === startTransDate && val.accountId === g.accountId
              )
            ) {
              const selectedTransBal = allAccountsHistory.find(
                (val) => val.date === startTransDate && val.accountId === g.accountId
              );
              if (selectedTransBal) startTrans = selectedTransBal.balance;
            } else {
              const selectedTransBal = allAccountsHistory
                .filter((val) => val.accountId === g.accountId)
                .find((val) => val.date < opBalDate);
              if (selectedTransBal) startTrans = selectedTransBal.balance;
            }

            runningBalanceObj[g.accountId] = startTrans + g.debit - g.credit;
            return { ...g, runningBalance: runningBalanceObj[g.accountId] };
          });

          group = group.map((grp) => {
            const transDate = moment(grp.journalDate).format('YYYY-MM-DD');
            const selectedAccountsHistory = allAccountsHistory.find(
              (val) => val.accountId === grp.accountId && val.date === transDate
            );

            let hasNextSameDate = false;
            if (responseNext.length > 0) {
              if (
                responseNext.find(
                  (val) =>
                    moment(val.journalDate).format('YYYY-MM-DD') === transDate &&
                    val.accountId === grp.accountId
                )
              ) {
                hasNextSameDate = true;
              }
            }

            if (selectedAccountsHistory && !hasNextSameDate) {
              const transactionId = getTransactionIdForEndDay(tableData, transDate, grp.accountId);
              if (transactionId === grp.journalLineId) {
                runningBalanceObj[grp.accountId] = selectedAccountsHistory.balance;
              }
            }
            return { ...grp, runningBalance: runningBalanceObj[grp.accountId] };
          });

          pageRunBal[pageNo] = runningBalanceObj;
        } else {
          if (pageRunBal[pageNo] !== undefined && pageRunBal[pageNo] !== null) {
            group = group.map((g) => {
              if (
                pageRunBal[pageNo][g.accountId] !== undefined &&
                pageRunBal[pageNo][g.accountId] !== null
              ) {
                runningBalanceObj[g.accountId] =
                  pageRunBal[pageNo][g.accountId] + g.debit - g.credit;
              } else {
                let found = false;
                for (let ind = pageNo - 1; ind > 0; ind--) {
                  if (pageRunBal[ind][g.accountId]) {
                    runningBalanceObj[g.accountId] =
                      pageRunBal[ind][g.accountId] + g.debit - g.credit;
                    found = true;
                    break;
                  }
                }
                if (!found) {
                  const accBalance = allAccountsHistory.find(
                    (val) => val.accountId === g.accountId
                  )?.balance;
                  runningBalanceObj[g.accountId] = accBalance ? accBalance + g.debit - g.credit : 0;
                }
              }
              return { ...g, runningBalance: runningBalanceObj[g.accountId] };
            });
          } else {
            group = group.map((g) => {
              let found = false;
              for (let ind = pageNo - 1; ind > 0; ind--) {
                if (
                  pageRunBal[ind][g.accountId] !== undefined &&
                  pageRunBal[ind][g.accountId] !== null
                ) {
                  runningBalanceObj[g.accountId] =
                    pageRunBal[ind][g.accountId] + g.debit - g.credit;
                  found = true;
                  break;
                }
              }
              if (!found) {
                const accBalance = allAccountsHistory.find(
                  (val) => val.accountId === g.accountId
                )?.balance;
                runningBalanceObj[g.accountId] = accBalance ? accBalance + g.debit - g.credit : 0;
              }
              pageRunBal[pageNo] = runningBalanceObj;
              return { ...g, runningBalance: runningBalanceObj[g.accountId] };
            });
          }

          if (index !== sortedGroupedData.length - 1) {
            group = group.map((grp) => {
              const transDate = moment(grp.journalDate).format('YYYY-MM-DD');
              const selectedAccountsHistory = allAccountsHistory.find(
                (val) => val.accountId === grp.accountId && val.date === transDate
              );

              let hasNextSameDate = false;
              if (responseNext.length > 0) {
                if (
                  responseNext.find(
                    (val) =>
                      moment(val.journalDate).format('YYYY-MM-DD') === transDate &&
                      val.accountId === grp.accountId
                  )
                ) {
                  hasNextSameDate = true;
                }
              }

              if (selectedAccountsHistory && !hasNextSameDate) {
                const transactionId = getTransactionIdForEndDay(
                  tableData,
                  transDate,
                  grp.accountId
                );
                if (transactionId === grp.journalLineId) {
                  runningBalanceObj[grp.accountId] = selectedAccountsHistory.balance;
                }
              }
              return { ...grp, runningBalance: runningBalanceObj[grp.accountId] };
            });
          }
          pageRunBal[pageNo] = runningBalanceObj;
        }

        return group;
      });
      setPageRunningBalance((prev) => {
        prev[pageNo] = runningBalanceObj;
        savedData.pageRunningBalance = prev;
        return { ...prev };
      });
    } else {
      // Group and sort
      const groupedData = tableData.reduce<Record<number, ICustomViewAccountTableData[]>>(
        (acc, item) => {
          const key = item.journalId;

          if (!acc[key]) {
            acc[key] = [];
          }

          acc[key].push(item);

          return acc;
        },
        {}
      );

      const sortedKeys = Object.keys(groupedData).sort((a, b) => {
        const journalDateA = groupedData[parseInt(a)][0].journalDate;
        const journalDateB = groupedData[parseInt(b)][0].journalDate;
        return optionalDateSorter(journalDateA, journalDateB);
      });

      const sortedGroupedData: [number, ICustomViewAccountTableData[]][] = sortedKeys.map((key) => [
        parseInt(key),
        groupedData[parseInt(key)]
      ]);

      tableData = sortedGroupedData.flatMap((group) => {
        return group[1];
      });
    }

    let sn = 0;
    tableData = tableData.map((value) => {
      if (mergeValue.has(`${value.journalId}`)) {
        return { ...value, rows: 0, sn: 0 };
      } else {
        const rowCount = tableData.filter((data) => data.journalId === value.journalId).length;
        mergeValue.add(`${value.journalId}`);
        sn += 1;
        return { ...value, rows: rowCount, sn: sn };
      }
    });

    setOpeningBalance(nepaliNumberFormatter(opBalance));
    savedData.openingBalance = nepaliNumberFormatter(opBalance);

    const journalWithTransfer = tableData.map((value) => {
      if (value.locationName?.includes('>>>'))
        return { journalId: value.journalId, locationName: value.locationName };
    });

    tableData = tableData.map((value) => {
      const hasJournalWithTransfer = journalWithTransfer.find(
        (val) => val?.journalId === value.journalId
      );

      if (hasJournalWithTransfer)
        return { ...value, locationName: hasJournalWithTransfer.locationName };
      else return { ...value };
    });

    if (accountId && tableData.length > 0) {
      const filteredByAcc = tableData.filter((val) => val.accountId === parseInt(accountId));
      const clBalance = filteredByAcc[filteredByAcc.length - 1].runningBalance;
      const openingBalance = nepaliNumberFormatter(opBalance ? opBalance : 0);
      const closingBalance = nepaliNumberFormatter(clBalance ? clBalance : 0);
      setClosingBalance((prev) => {
        prev[pageNo] = clBalance ? clBalance : 0;
        savedData.closingBalance = prev;
        return prev;
      });

      const openingBalanceRowData = {
        accountName: 'Opening Balance',
        runningBalance: parseFloat(openingBalance.replace(/,/g, '')) || '0.0',
        rows: 1,
        createdAt: '',
        isBold: true
      };

      const closingBalanceRowData = {
        accountName: 'Closing Balance',
        runningBalance: parseFloat(closingBalance.replace(/,/g, '')) || '0.0',
        rows: 1,
        createdAt: '',
        isBold: true
      };

      tableData = [
        openingBalanceRowData,
        ...tableData,
        closingBalanceRowData
      ] as ICustomViewAccountTableData[];
    }

    setLines(tableData);
    savedData.tableData = tableData;
    const dataUpdated = tableData.map((item) => {
      return {
        ...item,
        createdAt: convertUTCStringtoLocalString(item.createdAt, DEFAULT_DATE_FORMAT),
        transactionDate: item.journalDate
          ? convertUTCStringtoLocalString(item.journalDate, DEFAULT_DATE_FORMAT)
          : ''
      };
    });
    setExportData(dataUpdated);
    savedData.exportData = dataUpdated;
    setIsLoading(false);
    return savedData;
  };

  const onPagination = async (pageNo = 1, totalSize = 100, isSize = false) => {
    setIsLoading(true);
    let resetPageRunBal = false;
    const values = form.getFieldsValue();
    values.endDate = convertLocalToUTCString(values.endDate);
    values.startDate = convertLocalToUTCString(values.startDate);
    delete values.dateCustom;
    delete values.startDateNepali;
    delete values.endDateNepali;
    if (isSize) {
      values.skip = 0;
      values.count = totalSize;
      setPage(1);
      setSize(totalSize);
      setPageRunningBalance(() => {
        return {};
      });
      resetPageRunBal = true;
      pageNo = 1;
    } else {
      values.skip = (pageNo - 1) * totalSize;
      values.count = totalSize;
      setPage(pageNo);
    }
    const url = ConvertObjectToURL(values);
    return await getInfo(url, pageNo, resetPageRunBal);
  };

  const fetchReferenceAndRedirect = async (journalId: number) => {
    setIsLoading(true);
    //redirect
    const response = await get_journal_details(journalId);
    const redirectObj = redirectData.find((val) => val.key === response.type);
    if (redirectObj && response) {
      // set referenceId
      switch (response.type) {
        case AccountRulesEvent.SELL_PAYMENT_CASH:
        case AccountRulesEvent.SELL_PAYMENT_BANK:
        case AccountRulesEvent.SELL_PAYMENT_OTHER: {
          const paymentDetails = await get_payment_details(response.referenceId);
          if (paymentDetails.against === PaymentAgainst.Sell)
            response.redirectId = paymentDetails.againstId;
          break;
        }
        case AccountRulesEvent.SELL_RETURN: {
          const returnDetails = await get_sell_return_details_by_id(response.referenceId);
          response.redirectId = returnDetails.sellId;
          break;
        }
        case AccountRulesEvent.PURCHASE_PAYMENT_CASH:
        case AccountRulesEvent.PURCHASE_PAYMENT_USER:
        case AccountRulesEvent.PURCHASE_PAYMENT_OTHER: {
          const paymentDetails = await get_payment_details(response.referenceId);
          if (paymentDetails.against === PaymentAgainst.Purchase)
            response.redirectId = paymentDetails.againstId;
          break;
        }
        case AccountRulesEvent.PURCHASE_RETURN: {
          const returnDetails = await get_purchase_reutrn_details_by_id(response.referenceId);
          response.redirectId = returnDetails.purchaseId;
          break;
        }
        default: {
          response.redirectId = response.referenceId;
        }
      }
      navigate(`${redirectObj.link}${response.redirectId}`);
    } else {
      setIsLoading(false);
      CustomErrorModal({ message: 'Cannot redirect! Page not found.' });
    }
  };

  const columns: ColumnsType<ICustomViewAccountTableData> = [
    {
      title: 'S.N',
      key: 'SN',
      width: 5,
      onCell: (record) => {
        return { rowSpan: record.rows };
      },
      render: (txt, record) => {
        return <TableCell>{record.sn ? (page - 1) * size + record.sn : ''}</TableCell>;
      }
    },
    {
      title: 'Journal',
      key: 'journalName',
      width: 15,
      rowSpan: 1,
      onCell: (record) => {
        return { rowSpan: record.rows };
      },
      render: (a, record) => (
        <TableCell>
          <Link to={'/accounts/journal/view/' + record.journalId}>{record.journalDescription}</Link>
        </TableCell>
      )
    },
    {
      title: 'Account',
      key: 'accountName',
      width: 15,
      render: (a, record) => (
        <TableCell className={record.isBold ? 'font-bold' : ''}>{record.accountName}</TableCell>
      )
    },
    {
      title: 'Debit',
      key: 'debit',
      width: 15,
      render: (a, record) => (
        <TableCell>
          {record.debit !== undefined ? nepaliNumberFormatter(record.debit) : ''}
        </TableCell>
      )
    },
    {
      title: 'Credit',
      key: 'credit',
      width: 15,
      render: (a, record) => (
        <TableCell>
          {record.credit !== undefined ? nepaliNumberFormatter(record.credit) : ''}
        </TableCell>
      )
    },
    {
      title: 'Reference',
      key: 'ledgerType',
      width: 20,
      onCell: (record) => {
        return { rowSpan: record.rows };
      },
      render: (a, record) => (
        <TableCell>
          <Button
            type="link"
            block
            style={{
              padding: '0px',
              textAlign: 'left',
              wordBreak: 'break-all',
              whiteSpace: 'normal'
            }}
            onClick={async () => await fetchReferenceAndRedirect(record.journalId)}>
            {record.journalType}
          </Button>
        </TableCell>
      )
    },
    {
      title: 'Reference Number',
      key: 'refNumber',
      width: 15,
      onCell: (record) => {
        return { rowSpan: record.rows };
      },
      render: (a, record) => <TableCell>{record.financialReference}</TableCell>
    },
    {
      title: 'Running Balance',
      key: 'runningBalance',
      width: 15,
      render: (a, record) => (
        <TableCell className={record.isBold ? 'font-bold' : ''}>
          {record.runningBalance
            ? nepaliNumberFormatter(record.runningBalance)
            : nepaliNumberFormatter(0)}
        </TableCell>
      )
    },
    {
      title: 'Location',
      key: 'locationName',
      width: 15,
      onCell: (record) => {
        return { rowSpan: record.rows };
      },
      render: (a, record) => {
        return (
          <>
            <TableCell>{record.locationName}</TableCell>
          </>
        );
      }
    },
    {
      title: 'Transaction Date',
      key: 'transactionDate',
      width: 15,
      onCell: (record) => {
        return { rowSpan: record.rows };
      },
      render: (a, record) => {
        return (
          <>
            <TableCell>
              {record.journalDate
                ? convertUTCStringtoLocalString(record.journalDate, DEFAULT_DATE_FORMAT)
                : null}
            </TableCell>
          </>
        );
      }
    },
    {
      title: 'Created Date',
      key: 'createdAt',
      width: 15,
      onCell: (record) => {
        return { rowSpan: record.rows };
      },
      render: (a, record) => {
        return (
          <>
            <TableCell>
              {record.createdAt
                ? convertUTCStringtoLocalString(record.createdAt, DEFAULT_DATE_FORMAT)
                : ''}
            </TableCell>
          </>
        );
      }
    },
    {
      title: 'Actions',
      key: 'actions',
      width: 10,
      fixed: 'right',
      onCell: (record) => {
        return { rowSpan: record.rows };
      },
      render: (record) => {
        const menuItems: (
          | {
              key: string;
              label: JSX.Element;
              onClick?: undefined;
            }
          | {
              key: string;
              label: JSX.Element;
              onClick: () => void;
            }
        )[] = [];
        if (checkAccess('READ_ACCOUNT')) {
          menuItems.push({
            key: '1',
            label: <CustomViewIcon link={'/accounts/journal/view/' + record.journalId} />
          });
        }

        const menu = <Menu items={menuItems} />;

        return <ActionDropdown menu={menu} />;
      }
    }
  ];

  const columsforPrint = [
    {
      label: 'Journal',
      dataIndex: 'journalName',
      width: 100,
      hasRowSpan: true,
      render: (text: string) => {
        return <TableCell>{text}</TableCell>;
      }
    },
    {
      label: 'Account',
      dataIndex: 'accountName',
      width: 100,
      hasRowSpan: false,
      render: (text: string) => {
        return <TableCell>{text}</TableCell>;
      }
    },
    {
      label: 'Debit',
      dataIndex: 'debit',
      width: 100,
      hasRowSpan: false,
      render: (text: number) => {
        return <TableCell>{text}</TableCell>;
      }
    },
    {
      label: 'Credit',
      dataIndex: 'credit',
      width: 100,
      hasRowSpan: false,
      render: (text: number) => {
        return <TableCell>{text}</TableCell>;
      }
    },
    {
      label: 'Reference',
      dataIndex: 'journalType',
      width: 100,
      hasRowSpan: false,
      render: (text: string) => {
        return <TableCell>{text}</TableCell>;
      }
    },
    {
      label: 'Reference Number',
      dataIndex: 'financialReference',
      width: 100,
      hasRowSpan: false,
      render: (text: string) => {
        return <TableCell>{text}</TableCell>;
      }
    },
    {
      label: 'Running Balance',
      dataIndex: 'runningBalance',
      width: 100,
      hasRowSpan: false,
      render: (text: number) => {
        return <TableCell>{text}</TableCell>;
      }
    },
    {
      label: 'Location',
      dataIndex: 'locationName',
      width: 150,
      hasRowSpan: true,
      render: (text: string) => {
        return <TableCell>{text}</TableCell>;
      }
    },
    {
      label: 'Transaction Date',
      dataIndex: 'transactionDate',
      width: 130,
      hasRowSpan: true,
      render: (text: string) => {
        return <TableCell>{convertUTCStringtoLocalString(text, DEFAULT_DATE_FORMAT)}</TableCell>;
      }
    },
    {
      label: 'Created Date',
      dataIndex: 'createdAt',
      width: 130,
      hasRowSpan: true,
      render: (text: string) => {
        return (
          <TableCell>
            {text ? convertUTCStringtoLocalString(text, DEFAULT_DATE_FORMAT) : ''}
          </TableCell>
        );
      }
    }
  ];

  const handleLocationChange = () => {
    // console.log('locationId', form.getFieldValue(['locationId']));
    form.resetFields(['accountId']);
    setLocationId(form.getFieldValue(['locationId']));
  };

  const handleExport = async () => {
    setIsLoading(true);
    try {
      const columns: ExportColumnType[] = [
        {
          title: `Opening Balance: ${openingBalance}`,
          width: 1500,
          children: [
            {
              title: 'S.N',
              dataIndex: 'sn',
              width: 50
            },
            {
              title: 'Journal',
              dataIndex: 'journalName',
              width: 100
            },
            {
              title: 'Account',
              dataIndex: 'accountName',
              width: 100
            },
            {
              title: 'Running Balance',
              dataIndex: 'runningBalance',
              width: 120
            },
            {
              title: 'Debit',
              dataIndex: 'debit',
              width: 100
            },
            {
              title: 'Credit',
              dataIndex: 'credit',
              width: 100
            },
            {
              title: 'Reference',
              dataIndex: 'ledgerType',
              width: 100
            },
            {
              title: 'Reference Number',
              dataIndex: 'refNumber',
              width: 100
            },
            {
              title: 'Location',
              dataIndex: 'locationName',
              width: 150
            },
            {
              title: 'Transaction Date',
              dataIndex: 'transactionDate',
              width: 100
            },
            {
              title: 'Created Date',
              dataIndex: 'createdAt',
              width: 100
            }
          ]
        }
      ];
      if (lines.length === 0) {
        message.error('No Data to Export');
        return;
      }

      const dataUpdated = lines.map((item) => {
        return {
          ...item,
          sn: item.rows && item.rows > 0 ? item.sn : '',
          journalName: item.rows && item.rows > 0 ? item.journalDescription : '',
          locationName: item.rows && item.rows > 0 ? item.locationName : '',
          ledgerType: item.rows && item.rows > 0 ? item.journalType : '',
          refNumber: item.rows && item.rows > 0 ? item.financialReference : '',
          createdAt:
            item.rows && item.rows > 0
              ? convertUTCStringtoLocalString(item.createdAt, DEFAULT_DATE_FORMAT)
              : '',
          transactionDate:
            item.journalDate && item.rows && item.rows > 0
              ? convertUTCStringtoLocalString(item.journalDate, DEFAULT_DATE_FORMAT)
              : ''
        };
      });
      exportExcel(columns, dataUpdated, 'Journal Lines');
    } catch (err: any) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePDFExport = useReactToPrint({
    content: () => printPDFRef.current
  });

  return (
    <Spin spinning={isLoading}>
      <CustomModal
        footer={false}
        isModalOpen={openModalForPdfExport}
        setIsModalOpen={setOpenModalForPdfExport}
        title="PDF print">
        <div className="flex justify-end mb-3">
          <Button type="primary" onClick={handlePDFExport}>
            Print Pdf
          </Button>
        </div>
        <div style={{ maxHeight: '80vh', overflow: 'scroll' }}>
          <CustomTableRowSpan
            columns={columsforPrint}
            datas={exportData}
            reff={printPDFRef}
            title={'Accounts Journal'}
          />
        </div>
      </CustomModal>
      <AppContent
        breadcrumbItems={breadcrumbItems}
        withfilter={true}
        button={
          <>
            <div>
              <AccountFilterTable
                showFilterInitial
                onParentLoading={(loading) => setIsLoading(loading)}
                onInitialLoad={({ pagination, data }) => {
                  setPage(pagination.page);
                  setSize(pagination.size);

                  if (data) {
                    const typedData = data as ISavedData;
                    if (typedData.journalLines) setJournalLinesList(typedData.journalLines);
                    if (typedData.selectedAccount) setSelectedAccount(typedData.selectedAccount);
                    if (typedData.isRunningBalance) setIsRunningBalance(typedData.isRunningBalance);
                    if (typedData.pageRunningBalance)
                      setPageRunningBalance(typedData.pageRunningBalance);
                    if (typedData.openingBalance) setOpeningBalance(typedData.openingBalance);
                    if (typedData.closingBalance) setClosingBalance(typedData.closingBalance);
                    if (typedData.tableData) setLines(typedData.tableData);
                    if (typedData.exportData) setExportData(typedData.exportData);
                    setIsLoading(false);
                  }
                }}
                defaultValues={{
                  value: '',
                  skip: 0,
                  count: 100,
                  locationId: preferenceLocationId ? preferenceLocationId : 1,
                  accountId: accountId ? parseInt(accountId) : undefined
                }}
                initial={false}
                onSearch={onSubmitFilter}
                form={form}
                buttonParentStyle={'flex justify-end items-center'}>
                <LocationSearchV2
                  hasParentFormItem={false}
                  name={'locationId'}
                  showAll
                  onOptionClick={handleLocationChange}
                />
                <AccountSearchV2
                  name={'accountId'}
                  locationId={locationId}
                  hasParentFormItem={false}
                />
                <Form.Item name="value" label="Search">
                  <Input placeholder="Search" />
                </Form.Item>
              </AccountFilterTable>
            </div>
          </>
        }>
        <div className="flex justify-between items-center">
          <div>
            <div className="font-bold text-lg">
              {selectedAccount} : <span>{openingBalance}</span>
            </div>
          </div>
          <div>
            <ActionDropdown
              button={true}
              menu={
                <Menu
                  items={[
                    {
                      key: '1',
                      label: (
                        <Tooltip title="Export Excel" color="blue">
                          <div className="text-center">Excel</div>
                        </Tooltip>
                      ),
                      onClick: () => {
                        handleExport();
                      }
                    },
                    {
                      key: '2',
                      label: (
                        <Tooltip title="Export PDF" color="blue">
                          <div className="text-center">PDF</div>
                        </Tooltip>
                      ),
                      onClick: () => {
                        try {
                          if (lines.length === 0) {
                            message.error('No Data to Export');
                            setIsLoading(false);
                            return;
                          }
                          setOpenModalForPdfExport(true);
                        } catch (err: any) {
                          console.log(err);
                        }
                      }
                    }
                  ]}
                />
              }
            />
          </div>
        </div>
        <CustomizeTable
          form={form}
          columns={
            isRunningBalance ? columns : columns.filter((val) => val.key !== 'runningBalance')
          }
          data={lines}
          notshowPagination={true}
          customScroll={{ x: 1750, y: '75vh' }}
          showPager={false}
          paginationDatas={{
            page: page,
            total: journalLinesList.total,
            size: size,
            onPagination,
            scrollToTop: true
          }}
          toSort={handleChange}
          tableName={'journal-lines-list'}
        />
      </AppContent>
    </Spin>
  );
};

export default JournalLinesList;
