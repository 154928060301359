import { useState, useRef, useContext, useEffect } from 'react';
import AppContent from '@/components/Common/Content/Content';
import TableFilter from '@/components/FliterTable';
import moment from 'moment';
import { Button, Form, Menu, message, Spin, TableProps, Tag, Tooltip } from 'antd';
import { get_total_lots_with_location } from '@/services/products/queries';
import ProductsDB from '@/store/localstorage/ProductsDB';
import TableCell from '@/components/Common/CustomizeTable/CustomCell';
import type { ColumnsType } from 'antd/es/table';
import { SorterResult } from 'antd/lib/table/interface';
import CustomizeTable from '@/components/Common/CustomizeTable/CustomizeTable';
import TotalLotsAvailableDB from '@/store/localstorage/TotalAvailableLotsDB';
import { CustomModal } from '@/components/Common/CustomModal';
import Lotsinfo from './Lotsinfo';
import ActionDropdown from '@/components/Common/Dropdownactions';
import { ExportColumnType, exportExcel } from '@/utils/exportExcel';
import CustomTable from '@/components/Common/CustomResuableInvoice/CustomTable';
import { useReactToPrint } from 'react-to-print';
import { ILotsTotal } from '@/services/products/types';
import CustomViewIcon from '@/components/Common/CustomIcons/CustomViewIcon';
import { WebSocketContext } from '@/contexts/websocket.context';
import { SocketEvents, SystemNotificationType } from '@/constants/websocketConfig';
import LocationSearchV2 from '@/components/Common/CustomSearch/Location';
import { find_location_id_preference } from '@/store/localstorage/preferences';
import { getProductById, getUnit } from '@/services';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';
import getErrorMessage from '@/utils/getError';
import usePagination from '@/hooks/usePagination';

const TotalLotsAndProducts = () => {
  const printPDFRef = useRef<HTMLDivElement>(null);
  const [form] = Form.useForm();
  const { socket } = useContext(WebSocketContext);

  const [allproducts, setAllproducts] = useState<ILotsTotal[]>([]);
  const [isLoading, setIsloading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [productInfoforModal, setProductInfoforModal] = useState<ILotsTotal>();
  const [openmodalforExport, setOpenModalforexport] = useState(false);
  const preferenceLocationId = find_location_id_preference();

  const {
    data: paginatedData,
    onReset,
    pagination
  } = usePagination(allproducts, { defaultSize: 20 });

  const breadcrumbItems = [{ label: 'Products and Lots Total', link: '/products/totalLots' }];
  const [sortedInfo, setSortedInfo] = useState<SorterResult<ILotsTotal>>({});
  const handleChange: TableProps<ILotsTotal>['onChange'] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<ILotsTotal>);
  };

  useEffect(() => {
    socket?.on('connect', async () => {
      await onSubmitFilter();
    });

    socket?.on(SocketEvents.SYSTEM_NOTIFICATION, async (data) => {
      if (data.type === SystemNotificationType.LOTS_ZERO) {
        const locationId = form.getFieldValue(['locationId']);
        const socketData = data.data as { locationId: number };
        if (socketData.locationId === locationId) {
          await onSubmitFilter();
        }
      }

      if (data.type === SystemNotificationType.LOTS_UPDATE) {
        const productIds = new Set<number>(allproducts.map((value) => value.productId));
        let updatedProducts = data.data as { productId: number; locationId: number }[];
        const locationId = form.getFieldValue(['locationId']);

        if (locationId) {
          updatedProducts = updatedProducts.filter(
            (value) =>
              value.locationId === locationId && Array.from(productIds).includes(value.productId)
          );
        }

        if (updatedProducts.length > 0) {
          await onSubmitFilter();
        }
      }
    });

    return () => {
      socket?.off(SocketEvents.SYSTEM_NOTIFICATION);
    };
  }, [socket?.connected, allproducts]);

  const onSubmitFilter = async () => {
    const data = await getInfo();
    onReset();
    return data;
  };

  const getInfo = async () => {
    setIsloading(true);
    const locationId = form.getFieldValue(['locationId']);
    await TotalLotsAvailableDB.getData(locationId);

    const { results, latestLines } = await get_total_lots_with_location(locationId);

    const uniqueProductIds = Array.from(new Set(results.map((value) => value.productId)));
    await ProductsDB.addProductsIfAbsent(uniqueProductIds);

    for (const lot of results) {
      const product = await getProductById(lot.productId);
      lot.productName = product.name;

      const defaultUnit = product.productUnits.find((curr) => curr.isDefault);
      const unit =
        defaultUnit ?? product.productUnits.length > 0 ? product.productUnits[0] : undefined;

      if (!unit) continue;

      const unitInfo = await getUnit(unit.unitId);
      lot.unitName = unitInfo.shortName;
      lot.baseUnitMultiplier = unitInfo.baseUnitMultiplier || 1;
      lot.qty = parseFloat(lot.quantity) / lot.baseUnitMultiplier;
      lot.purchasePriceAvg = `${parseFloat(lot.purchasePriceAvg) * lot.baseUnitMultiplier}`;

      // Find Latest Lines
      const latestLine = latestLines.find((curr) => curr.productId === lot.productId);
      if (latestLine) {
        lot.latestPurchasePrice = latestLine.unitPrice;
      }
    }

    TotalLotsAvailableDB.addDatas([{ locationId, date: moment().utc().format(), results }]);

    setAllproducts(results);
    setIsloading(false);
    return results;
  };

  const columns: ColumnsType<ILotsTotal> = [
    {
      title: 'S.N',
      key: 'SN',
      width: 5,
      sorter: (a, b) => a.productId - b.productId,
      sortOrder: sortedInfo.columnKey === 'id' ? sortedInfo.order : null,
      render: (text, record, index) => {
        return <TableCell>{(pagination.page - 1) * pagination.size + (index + 1)}</TableCell>;
      }
    },
    {
      title: 'Name',
      key: 'name',
      width: 50,
      sorter: (a, b) => a.productName.localeCompare(b.productName),
      sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null,
      render: (_, record) => {
        return <TableCell>{record.productName}</TableCell>;
      }
    },

    {
      title: 'Quantity',
      key: 'quantity',
      width: 20,
      sorter: (a, b) => parseFloat(a.quantity) - parseFloat(b.quantity),
      sortOrder: sortedInfo.columnKey === 'quantity' ? sortedInfo.order : null,
      render: (_, record) => {
        return <TableCell>{record.qty}</TableCell>;
      }
    },
    {
      title: 'Latest Purchase Price',
      key: 'latestPurchasePrice',
      className: 'highlight',
      width: 20,
      sorter: (a, b) => a.latestPurchasePrice - b.latestPurchasePrice,
      sortOrder: sortedInfo.columnKey === 'latestPurchasePrice' ? sortedInfo.order : null,
      render: (_, record) => {
        if (!record.latestPurchasePrice) return <TableCell>-</TableCell>;
        return <TableCell>{nepaliNumberFormatter(record.latestPurchasePrice)}</TableCell>;
      }
    },
    {
      title: 'Price Avg.',
      key: 'purchasePriceAvg',
      width: 20,
      sorter: (a, b) => parseFloat(a.purchasePriceAvg) - parseFloat(b.purchasePriceAvg),
      sortOrder: sortedInfo.columnKey === 'purchasePriceAvg' ? sortedInfo.order : null,
      render: (_, record) => {
        return <TableCell>{nepaliNumberFormatter(parseFloat(record.purchasePriceAvg))}</TableCell>;
      }
    },
    {
      title: 'Unit',
      key: 'unit',
      width: 20,
      sorter: (a, b) => a.unitName.localeCompare(b.unitName),
      sortOrder: sortedInfo.columnKey === 'unit' ? sortedInfo.order : null,
      render: (_, record) => {
        return <TableCell>{record.unitName}</TableCell>;
      }
    },
    {
      title: 'Active',
      key: 'hidden',
      width: 10,
      sorter: (a, b) => a.hidden - b.hidden,
      sortOrder: sortedInfo.columnKey === 'hidden' ? sortedInfo.order : null,
      render: (_, record) => {
        return (
          <Tag color={record.hidden ? 'red' : 'green'}>{record.hidden ? 'Inactive' : 'Active'}</Tag>
        );
      }
    },
    {
      title: 'Actions',
      key: 'actions',
      width: 13,
      fixed: 'right',
      render: (_, record) => {
        const menu = (
          <Menu
            items={[
              {
                key: '1',
                label: <CustomViewIcon title="View All Lots" />,
                onClick: () => {
                  setProductInfoforModal(record);
                  setIsModalOpen(true);
                }
              }
            ]}
          />
        );
        return <ActionDropdown menu={menu} />;
      }
    }
  ];

  const handleExport = () => {
    setIsloading(true);
    try {
      const columns: ExportColumnType[] = [
        { title: 'S.N', dataIndex: 'SN', width: 50 },
        { title: 'Product', dataIndex: 'productName', width: 350 },
        { title: 'Active', dataIndex: 'hiddenStatus', width: 80 },
        { title: 'Price Avg', width: 100, dataIndex: 'purchasePriceAvg' },
        { title: 'Unit', width: 100, dataIndex: 'unitName' },
        { title: 'Quantity', width: 100, dataIndex: 'qty' }
      ];

      if (allproducts?.length === 0) {
        message.error('No Data to Export');
        return;
      }

      const dataUpdated = allproducts?.map((item, index: number) => {
        return { ...item, SN: index + 1 };
      });

      exportExcel(columns, dataUpdated, 'Total Lots and Products');
    } catch (err) {
      getErrorMessage(err, true);
    } finally {
      setIsloading(false);
    }
  };

  const columsforPrint = [
    {
      label: 'Name',
      dataIndex: 'productName',
      render: (text: string) => {
        return <div className="ml-2">{text}</div>;
      }
    },
    {
      label: 'Active',
      dataIndex: 'hidden',
      render: (record: ILotsTotal) => {
        return <div className="text-center">{record.hidden ? 'Inactive' : 'Active'}</div>;
      }
    },
    {
      label: 'Quantity',
      dataIndex: 'qty',
      render: (text: string) => {
        return <div className="text-center">{text}</div>;
      }
    },
    {
      label: 'Unit',
      dataIndex: 'unitName',
      render: (text: string) => {
        return <div className="text-center">{text}</div>;
      }
    },
    {
      label: 'Price Avg',
      dataIndex: 'purchasePriceAvg',
      render: (text: string) => {
        return <div className="text-center">{text}</div>;
      }
    }
  ];

  const handlePDFExport = useReactToPrint({
    content: () => printPDFRef.current
  });

  return (
    <Spin spinning={isLoading}>
      <CustomModal
        footer={false}
        isModalOpen={openmodalforExport}
        setIsModalOpen={setOpenModalforexport}
        title="Product List View For PDF print">
        <div className="flex justify-end mt-3">
          <Button type="primary" onClick={handlePDFExport}>
            Print Pdf
          </Button>
        </div>
        <div style={{ maxHeight: '80vh', overflow: 'scroll' }}>
          <CustomTable
            columns={columsforPrint}
            datas={allproducts}
            reff={printPDFRef}
            title={'Product List'}
          />
        </div>
      </CustomModal>
      <CustomModal
        footer={false}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        title={`${productInfoforModal?.productName} Lots Information`}>
        {productInfoforModal && (
          <Lotsinfo
            locationId={form.getFieldValue(['locationId'])}
            productinfo={productInfoforModal}
          />
        )}
      </CustomModal>
      <AppContent
        breadcrumbItems={breadcrumbItems}
        withfilter={true}
        button={
          <>
            <div>
              <TableFilter
                defaultValues={{
                  dateCustom: [moment(0, 'HH'), moment(0, 'HH').add(1, 'days')],
                  skip: 0,
                  count: 100,
                  locationId: preferenceLocationId ? preferenceLocationId : ''
                }}
                onInitialLoad={({ data }) => {
                  if (data) {
                    setAllproducts(data);
                    setIsloading(false);
                  }
                }}
                initial={true}
                onSubmit={onSubmitFilter}
                form={form}
                // outsidestyle={'flex flex-wrap items-end gap-2'}
                styleforbuttons={'flex justify-end items-center'}
                style={
                  'grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3  xl:grid-cols-4 gap-2 items-center justify-center'
                }
                buttons={
                  <>
                    <div>
                      <ActionDropdown
                        button={true}
                        menu={
                          <Menu
                            items={[
                              {
                                key: '1',
                                label: (
                                  <Tooltip title="Export Excel" color="blue">
                                    <div className="text-center">Excel</div>
                                  </Tooltip>
                                ),
                                onClick: () => {
                                  handleExport();
                                }
                              },
                              {
                                key: '2',
                                label: (
                                  <Tooltip title="Export PDF" color="blue">
                                    <div className="text-center">PDF</div>
                                  </Tooltip>
                                ),
                                onClick: () => {
                                  setOpenModalforexport(true);
                                }
                              }
                            ]}
                          />
                        }
                      />
                    </div>
                  </>
                }>
                <LocationSearchV2 hasParentFormItem={false} name={'locationId'} showAll />
              </TableFilter>
            </div>
          </>
        }>
        <CustomizeTable
          form={form}
          columns={columns}
          data={paginatedData}
          toSort={handleChange}
          customScroll={{ y: '75vh', x: 1000 }}
          notshowPagination={true}
          paginationDatas={pagination}
        />
      </AppContent>
    </Spin>
  );
};

export default TotalLotsAndProducts;
