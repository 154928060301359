import moment from 'moment';
import CustomUpdateIcon from '@/components/Common/CustomIcons/CustomUpdateIcon';
import CustomViewIcon from '@/components/Common/CustomIcons/CustomViewIcon';
import GenericTable from '@/components/Common/CustomizeTable';
import ActionDropdown from '@/components/Common/Dropdownactions';
import { DEFAULT_DATE_FORMAT } from '@/constants';
import { isAdmin } from '@/routes/acl';
import { EFeatureRequestColor } from '@/services/supports/constant';
import { EFeatureRequestProgress } from '@/services/supports/enum';
import {
  IFeatureReqDevMultiPayload,
  IFeatureRequestListTable,
  IFeatureRequestTable
} from '@/services/supports/types';
import { getUserData } from '@/utils/auth.utils';
import { convertUTCStringtoLocalString } from '@/utils/convertToUTC';
import {
  Button,
  Card,
  DatePicker,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Menu,
  message,
  Modal,
  Select,
  Spin,
  TableProps,
  Tag
} from 'antd';
import { ColumnsType } from 'antd/es/table';
import { SorterResult, TableRowSelection } from 'antd/lib/table/interface';
import { useEffect, useState } from 'react';
import { update_feature_request_dev_multi_mutation } from '@/services/supports/mutation';
import { useFilterStore } from '@/store/zustand';
import isAxiosError from '@/utils/isAxiosError';
import CustomErrorModal from '@/components/Common/CustomErrorModal';
import getErrorMessage from '@/utils/getError';
import { useLocation } from 'react-router-dom';
import { FILTER_SYNC_EVENT } from '@/constants/event.menu';

interface Props {
  isLoading?: boolean;
  data: IFeatureRequestListTable;
  form: FormInstance;
  pagination: { page: number; size: number };
  getInfo: (filter?: string) => Promise<IFeatureRequestListTable | undefined>;
  onPagination: (
    page?: number,
    size?: number,
    isSize?: boolean
  ) => Promise<IFeatureRequestListTable | undefined>;
}

interface IFeatureForm {
  id: number;
  status: EFeatureRequestProgress;
  systemMessage: string;
  requirement: string;
  requestedBy: string;
  completedAt?: string;
  completedAtMoment?: moment.Moment;
}

interface FormValues {
  features: IFeatureForm[];
}
function Table({ isLoading, data, pagination, form, onPagination, getInfo }: Props) {
  const [sortedInfo, setSortedInfo] = useState<SorterResult<IFeatureRequestTable>>({});
  const [selectedFeatures, setSelectedFeatures] = useState<IFeatureRequestTable[]>([]);
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    const handleCustomEvent = (event: Event) => {
      const customEvent = event as CustomEvent<{ path: string }>;
      const isSame = customEvent.detail.path === location.pathname;
      if (isSame) setSelectedFeatures([]);
    };

    // Add event listener
    window.addEventListener(FILTER_SYNC_EVENT, handleCustomEvent);

    // Cleanup event listener
    return () => {
      window.removeEventListener(FILTER_SYNC_EVENT, handleCustomEvent);
    };
  }, []);

  const [multiUpdateForm] = Form.useForm<FormValues>();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const zustandFilter = useFilterStore();
  const location = useLocation();

  const handleChange: TableProps<IFeatureRequestTable>['onChange'] = (_, __, s) => {
    setSortedInfo(s as SorterResult<IFeatureRequestTable>);
  };

  const currentUser = getUserData();
  const isCurrentAdmin = isAdmin();

  const rowSelection: TableRowSelection<IFeatureRequestTable> = {
    selectedRowKeys: selectedFeatures.map((f) => f.id),
    onChange: (key, selectedRows) => {
      setSelectedFeatures(selectedRows);
    },
    getCheckboxProps: () => ({
      disabled: !isCurrentAdmin
    })
  };

  const columns: ColumnsType<IFeatureRequestTable> = [
    {
      title: 'S.N.',
      key: 'id',
      width: 5,
      render: (_, __, index) => (pagination.page - 1) * pagination.size + index + 1
    },
    {
      title: 'Requested By',
      key: 'requestedByName',
      dataIndex: 'requestedByName',
      width: 10,
      sorter: (a, b) => a.requestedByName.localeCompare(b.requestedByName),
      sortOrder: sortedInfo.columnKey === 'requestedByName' ? sortedInfo.order : null
    },
    {
      title: 'Status',
      key: 'status',
      width: 8,
      sorter: (a, b) => a.status.localeCompare(b.status),
      sortOrder: sortedInfo.columnKey === 'status' ? sortedInfo.order : null,
      render: (a, record) => <Tag color={EFeatureRequestColor[record.status]}>{record.status}</Tag>
    },
    {
      title: 'System Message',
      key: 'systemMessage',
      width: 20,
      dataIndex: 'systemMessage',
      sorter: (a, b) => a.systemMessage.localeCompare(b.systemMessage),
      sortOrder: sortedInfo.columnKey === 'date' ? sortedInfo.order : null
    },
    {
      title: 'Description',
      key: 'description',
      width: 30,
      dataIndex: 'description',
      sorter: (a, b) => a.description.localeCompare(b.description),
      sortOrder: sortedInfo.columnKey === 'date' ? sortedInfo.order : null
    },
    {
      title: 'Completed At',
      key: 'completedAt',
      width: 10,
      dataIndex: 'completedAt',
      sorter: (a, b) => (a.completedAt || '').localeCompare(b.updatedAt || ''),
      sortOrder: sortedInfo.columnKey === 'completedAt' ? sortedInfo.order : null,
      render: (completedAt) =>
        completedAt ? convertUTCStringtoLocalString(completedAt, DEFAULT_DATE_FORMAT) : ''
    },
    {
      title: 'Actions',
      key: 'actions',
      width: 6,
      fixed: 'right',
      render: (record: IFeatureRequestTable) => {
        const menuItems: (
          | { key: string; label: JSX.Element; onClick?: undefined }
          | { key: string; label: JSX.Element; onClick: () => void }
        )[] = [
          {
            key: 'view',
            label: <CustomViewIcon link={`/support/feature-request/${record.id}`} />
          }
        ];

        if (currentUser.id === record.requestedBy || isCurrentAdmin) {
          menuItems.push({
            key: 'update',
            label: <CustomUpdateIcon link={`/support/feature-request/details/${record.id}`} />
          });
        }

        const menu = <Menu items={menuItems} />;
        return <ActionDropdown menu={menu} />;
      }
    }
  ];

  function handleMultiUpdateClick() {
    if (selectedFeatures.length === 0) {
      return message.error('Please select atleast one feature request to update');
    }

    const features = selectedFeatures.map((feature) => ({
      id: feature.id,
      status: feature.status,
      systemMessage: feature.systemMessage,
      requirement: feature.description,
      requestedBy: feature.requestedByName,
      completedAt: feature.completedAt || undefined,
      completedAtMoment: feature.completedAt ? moment(feature.completedAt) : undefined
    }));

    multiUpdateForm.setFieldsValue({ features });
    setIsModalOpen(true);
  }

  async function onUpdate() {
    try {
      setIsUpdating(true);

      const values = await multiUpdateForm.validateFields();
      const payload: IFeatureReqDevMultiPayload[] = values.features.map((feature) => {
        const selected = selectedFeatures.find((f) => f.id === feature.id) as IFeatureRequestTable;

        return {
          id: feature.id,
          status: feature.status,
          description: feature.requirement,
          systemMessage: feature.systemMessage,
          imgIds: selected.imgIds,
          completedAt: feature.completedAt
        };
      });

      await update_feature_request_dev_multi_mutation(payload);
      setIsModalOpen(false);
      zustandFilter.refetchData(location.pathname, getInfo);
      message.success('Feature request updated.');
    } catch (error) {
      if (isAxiosError(error)) return;
      CustomErrorModal({ message: getErrorMessage(error) });
    } finally {
      setSelectedFeatures([]);
      setIsUpdating(false);
    }
  }

  return (
    <div>
      <Modal
        width={'80%'}
        title="Update Feature Request"
        footer={null}
        visible={isModalOpen}
        onCancel={() => {
          multiUpdateForm.resetFields();
          setIsModalOpen(false);
        }}>
        <Spin spinning={isUpdating}>
          <Form
            form={multiUpdateForm}
            disabled={isLoading || isUpdating}
            initialValues={{ features: [] }}
            layout="vertical"
            validateTrigger={'onChange'}
            autoComplete="off">
            <Card style={{ maxHeight: '80vh', overflowY: 'scroll', borderRadius: '9px' }}>
              <Form.List name={['features']}>
                {(fields) =>
                  fields.map((field, index) => {
                    const currentValue = multiUpdateForm.getFieldValue(['features', field.name]);

                    return (
                      <div key={field.key} className="flex gap-2 items-center w-full">
                        <span className="font-bold text-sm mb-5">{index + 1}.</span>
                        <div className="card w-full space-y-4">
                          <div className="flex flex-col">
                            <span>
                              <strong>Requirement:</strong>
                              {currentValue.requirement}
                            </span>

                            <span>
                              <strong>Requested By:</strong>
                              {currentValue.requestedBy}
                            </span>
                          </div>
                          <div className="grid xl:grid-cols-3 gap-3 bg-white rounded-lg">
                            <div className="grid md:grid-cols-2 gap-3 w-full">
                              <Form.Item name={[field.name, 'id']} hidden>
                                <InputNumber controls={false} disabled />
                              </Form.Item>

                              <Form.Item name={[field.name, 'status']} label="Status">
                                <Select
                                  placeholder="Select Status"
                                  allowClear
                                  dropdownMatchSelectWidth={false}
                                  disabled={!isCurrentAdmin}>
                                  {Object.keys(EFeatureRequestProgress).map((key) => {
                                    const value =
                                      EFeatureRequestProgress[
                                        key as keyof typeof EFeatureRequestProgress
                                      ];

                                    return (
                                      <Select.Option value={value} key={key}>
                                        {value}
                                      </Select.Option>
                                    );
                                  })}
                                </Select>
                              </Form.Item>

                              <Form.Item
                                name={[field.name, 'completedAt']}
                                label="Completed At"
                                hidden>
                                <Input />
                              </Form.Item>

                              <Form.Item
                                name={[field.name, 'completedAtMoment']}
                                label="Completed At">
                                <DatePicker
                                  showTime
                                  format={'YYYY-MM-DD HH:mm'}
                                  disabled={!isCurrentAdmin}
                                  className="w-full"
                                  onChange={(date) =>
                                    form.setFieldValue('completedAt', date?.clone().utc().format())
                                  }
                                />
                              </Form.Item>
                            </div>

                            <Form.Item
                              className="xl:col-span-2"
                              name={[field.name, 'systemMessage']}
                              label="System Message"
                              rules={[
                                {
                                  required: isCurrentAdmin,
                                  message: 'Please provide system message'
                                }
                              ]}>
                              <Input
                                placeholder="Provide system message"
                                disabled={!isCurrentAdmin}
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                    );
                  })
                }
              </Form.List>
            </Card>

            <div className="flex justify-end mt-4">
              <Button
                type="primary"
                loading={isUpdating}
                onClick={onUpdate}
                disabled={isLoading || isUpdating}>
                Update
              </Button>
            </div>
          </Form>
        </Spin>
      </Modal>
      <GenericTable
        form={form}
        {...(isCurrentAdmin && { rowSelection })}
        rowSelectionWidthInPx={3}
        buttons={
          <div>
            <Button
              ghost
              type="primary"
              loading={isUpdating}
              onClick={handleMultiUpdateClick}
              disabled={selectedFeatures.length === 0}>
              Update Selected
            </Button>
          </div>
        }
        columns={columns}
        data={data.results}
        isLoading={isLoading || isUpdating}
        scroll={{ x: 1500 }}
        hideDefaultPagination
        tableName={'feature-request-list'}
        pagination={{ ...pagination, total: data.total, onPagination, scrollToTop: true }}
        toSort={handleChange}
      />
    </div>
  );
}

export default Table;
