import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  Form,
  Input,
  Button,
  PageHeader,
  message,
  Checkbox,
  Select,
  InputNumber,
  DatePicker,
  Divider
} from 'antd';
// import { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useNavigate, useParams } from 'react-router';
import AppContent from '../../../components/Common/Content/Content';
import ProductSearchForOffer from '../../../components/Common/ProductSearch2/ProductSearchForOffer';
import useDebounce from '../../../hooks/useDebounce';
import { checkAccess } from '../../../routes/acl';
// import { API_URL,  } from '../../../constants/config';
// import { get_category_details, get_category_list } from '../../../services/category/queries';
// import { ICategory } from '../../../services/category/types';
import { update_offer_mutation } from '../../../services/offfers/mutations';
import { get_offer_details, get_offer_list } from '../../../services/offfers/queries';
import { IOffer, IproductlisttypeforOffer } from '../../../services/offfers/types';
import { get_product_list, get_product_list_ids } from '../../../services/products/queries';
import ProductsDB from '../../../store/localstorage/ProductsDB';

import { AppDispatch, RootState } from '../../../store/store';
import ReuseChannel from '../../channel/Reuse';
import { IProductType } from '../../../services/products/types';
// import { getLocalStorage } from '../../../utils/storage.utils';
const { Option } = Select;
const { RangePicker } = DatePicker;

const OfferDetails = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  // const dispatch: AppDispatch = useDispatch();
  useQuery(['offer'], async () => get_offer_list(0, 200));
  const { id } = useParams();
  const [productList, setProductList] = useState<IProductType[]>([]);
  const [offerDetails, setOfferDetails] = useState<IOffer>();
  const [isloading, setIsloading] = useState<boolean>(true);
  const [submitisloading, setSubmitisloading] = useState<boolean>(false);
  const [discounttype, setDiscounttype] = useState<string>('');
  const [searchValue, setSearchValue] = useState<string>('');
  const [productSearch, setProductSearch] = useState<any[]>([]);

  const debouncedSearchValue = useDebounce(searchValue, 500);
  useEffect(() => {
    searchProduct(debouncedSearchValue);
  }, [debouncedSearchValue]);

  const getDataFromLC = async () => {
    const data: IproductlisttypeforOffer[] =
      (await ProductsDB.getAllProducts()) as IproductlisttypeforOffer[];
    if (data.length > 0) {
      setProductList(data as IProductType[]);
    } else {
      const response = await get_product_list();
      setProductList(response.data.results);
      ProductsDB.addProducts(response.data.results);
    }
  };
  const breadcrumbItems = [
    {
      label: 'Offer',
      link: '/offer'
    },
    {
      label: 'Details',
      link: `/offer/${id}`
    }
  ];

  const updateOfferMutation = useMutation(update_offer_mutation, {
    onSuccess: (data: any) => {
      message.success('Offer updated successfully');
      navigate('/offer');
      setIsloading(false);
      setSubmitisloading(false);
    },
    onError: (e: any) => {
      setIsloading(false);
      setSubmitisloading(false);
      message.error(`${e.response.data.message}`, 5);
    }
  });
  const onFinish = (values: any) => {
    setIsloading(true);
    setSubmitisloading(true);
    values = {
      ...values,
      id: parseInt(id as string),
      endDate: values.startDate[1].utc().format(),
      startDate: values.startDate[0].utc().format()
    };
    // console.log(values);
    updateOfferMutation.mutate(values);
  };
  const getData = async () => {
    if (id) {
      const data: IOffer = await get_offer_details(parseInt(id));

      if (data.wholeDiscount === null) {
        setDiscounttype('productdiscount');
        form.setFieldValue(['Discount', 'type'], 'productdiscount');
        // form.setFieldValue(['productDiscount'], data.productDiscount);
      } else {
        setDiscounttype('wholediscount');
        form.setFieldValue(['Discount', 'type'], 'wholediscount');
      }
      if (typeof data.productDiscount == 'string') {
        data.productDiscount = JSON.parse(data.productDiscount);
        // console.log('data', data.productDiscount);
        const searchProducts: any = {};
        for (let ind = 0; ind < data.productDiscount.length; ind++) {
          if (!data.productDiscount[ind].productName) {
            // console.log('this runs');
            const product: any = await ProductsDB.getProduct(data.productDiscount[ind].productId);
            if (!product) {
              if (data.productDiscount[ind].productId in searchProducts) {
                searchProducts[data.productDiscount[ind].productId] = [
                  ...searchProducts[data.productDiscount[ind].productId],
                  ind
                ];
              } else {
                searchProducts[data.productDiscount[ind].productId] = [ind];
              }
            } else {
              data.productDiscount[ind].productName = product.name;
            }
          }
        }

        const searchProductslength = Object.entries(searchProducts).length;
        if (searchProductslength > 0) {
          const productsresponse = await get_product_list_ids([...Object.keys(searchProducts)]);
          for (const key in searchProducts) {
            const findproduct = productsresponse?.data?.results.find(
              (currProduct: any) => currProduct.id == key
            );
            for (let i = 0; i < searchProducts[key].length; i++) {
              data.productDiscount[searchProducts[key][i]].productName = findproduct?.name;
            }
            await ProductsDB.addProducts([findproduct]);
          }
        }
      }
      if (typeof data.wholeDiscount == 'string') {
        data.wholeDiscount = JSON.parse(data.wholeDiscount);
      }
      await getDataFromLC();
      form.setFieldsValue({
        ...data,
        // productDiscount: [...data.productDiscount],
        startDate: [moment(data.startDate), moment(data.endDate)]
      });
      // console.log('data', data);
      setOfferDetails(data);
      setIsloading(false);
    }
  };
  useEffect(() => {
    getData();
  }, []);

  const [responsize, setResponsize] = useState(false);
  useEffect(() => {
    if (window.innerWidth <= 650) {
      // setCollapsed(true);
      setResponsize(true);
    } else {
      setResponsize(false);
    }
    function handleResize() {
      if (window.innerWidth <= 650) {
        // setCollapsed(true);
        setResponsize(true);
      } else {
        setResponsize(false);
      }
    }
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  const searchProduct = async (value: any) => {
    if (value != '') {
      let response;
      try {
        response = await get_product_list(0, 5, value);
      } catch (e) {
        console.log(e);
      }
      if (!response || response.data.results.length == 0) {
        message.error('Cannot find any product with that name!');
        setProductSearch([]);
      } else {
        setProductSearch(response.data.results);
        checkProductAndAdd(response.data.results);
      }
    }
    // setSearchLoading(false);
  };
  const options = productSearch.map((d) => (
    <Option key={d.id} value={d.id}>
      {d.name}
    </Option>
  ));

  const checkProductAndAdd = (products: any[]) => {
    if (productList.length > 0) {
      products = products.filter((value) => {
        const searchProduct = productSearch.find((val) => val.id == value.id);
        if (searchProduct) return false;
        return true;
      });
      if (products.length > 0)
        setProductList((prevValue) => {
          return [...prevValue, ...products];
        });
    } else {
      setProductList(products);
    }
  };
  // console.log('Product list', productList);

  const onDiscounttypechange = () => {
    const selected = form.getFieldValue(['Discount', 'type']);
    setDiscounttype(selected);
    console.log('selected', selected);
  };

  return (
    <div>
      <AppContent breadcrumbItems={breadcrumbItems}>
        <Form
          form={form}
          onFinish={onFinish}
          layout="vertical"
          disabled={isloading}
          validateTrigger={'onChange'}
          autoComplete="off">
          <PageHeader
            title="Offer Information"
            style={{
              padding: '8px 0px'
            }}
          />
          <div className="grid grid-cols-2 gap-5 mb-5">
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: 'Please add name!' }]}>
              <Input />
            </Form.Item>
            <Form.Item
              label="Code"
              name="code"
              rules={[{ required: true, message: 'Please add name!' }]}>
              <Input />
            </Form.Item>
          </div>
          <div className="grid grid-cols-2 gap-5 mb-5">
            <Form.Item label="Select Discount Type" name={['Discount', 'type']}>
              <Select
                onChange={onDiscounttypechange}
                placeholder={'Select Whole Discount/Product Discount'}>
                <Option key={'wholediscount'} value={'wholediscount'}>
                  Whole Discount
                </Option>
                <Option key={'productdiscount'} value={'productdiscount'}>
                  productdiscount
                </Option>
              </Select>
            </Form.Item>
          </div>
          {discounttype === '' ? (
            <></>
          ) : discounttype === 'wholediscount' ? (
            <>
              <PageHeader
                subTitle="Whole Discount"
                style={{
                  padding: '8px 0px'
                }}
              />
              <div className="grid grid-cols-2 gap-5 mb-5">
                <Form.Item label="Type" name={['wholeDiscount', 'type']}>
                  <Select defaultValue={'percentage'}>
                    <Option key={'percentage'} value={'percentage'}>
                      Percentage
                    </Option>
                    <Option key={'flat'} value={'flat'}>
                      Flat
                    </Option>
                  </Select>
                </Form.Item>
              </div>
              <div
                className={responsize ? 'grid grid-cols-2 gap-4' : 'grid grid-cols-4 gap-5 mb-5'}>
                <Form.Item name={['wholeDiscount', 'minimumAmount']} label="Min. Amount">
                  <InputNumber controls={false} min={0} defaultValue={0} />
                </Form.Item>
                <Form.Item name={['wholeDiscount', 'ceilAmount']} label="Ceil Amount">
                  <InputNumber controls={false} min={0} defaultValue={0} />
                </Form.Item>
                <Form.Item name={['wholeDiscount', 'percentage']} label="Percentage">
                  <InputNumber controls={false} min={0} defaultValue={0} />
                </Form.Item>
                <Form.Item name={['wholeDiscount', 'flat']} label="Flat">
                  <InputNumber controls={false} min={0} defaultValue={0} />
                </Form.Item>
              </div>
            </>
          ) : (
            <>
              <PageHeader
                subTitle="Product Discount"
                style={{
                  padding: '8px 0px'
                }}
              />
              <Form.List name={['productDiscount']}>
                {(fields, { add, remove }, { errors }) => (
                  <>
                    <div className={'grid mb-5'}>
                      <PageHeader
                        subTitle="Add Product"
                        style={{
                          padding: '8px 0px 8px 10px'
                        }}
                      />
                      <ProductSearchForOffer add2={add} />
                      {/* <Select
                        showSearch
                        placeholder={'search product'}
                        defaultActiveFirstOption={false}
                        showArrow={false}
                        filterOption={false}
                        onSearch={(val) => {
                          setSearchValue(val);
                        }}
                        onSelect={(val: number) => {
                          const selectedProduct = productSearch.find((value) => value.id == val);
                          add({
                            productId: val,
                            productName: selectedProduct.name,
                            type: 'percentage',
                            minimumQuantity: 0,
                            minimumAmount: 0,
                            percentage: 0,
                            flat: 0
                          });
                        }}
                        notFoundContent={null}>
                        {options}
                      </Select> */}
                      {fields.length > 0 && (
                        <PageHeader
                          title="All Products"
                          style={{
                            padding: '8px 0px 8px 10px'
                          }}
                        />
                      )}
                    </div>
                    {fields.map(({ key, name, ...restField }) => (
                      <div key={key} className="card">
                        <div className={'grid grid-cols-2 gap-5 mb-5'}>
                          {/* <Form.Item {...restField} label="Product" name={[name, 'productId']}>
                            <Select defaultValue={'none'}>
                              <Option key={'none'} value={undefined}>
                                None
                              </Option>
                              {productList?.map((product: Product) => (
                                <Option key={product.id} value={product.id}>
                                  {product.name}
                                </Option>
                              ))}
                            </Select> */}
                          <Form.Item {...restField} name={[name, 'productName']} label="Name">
                            <Input type={'text'} disabled />
                          </Form.Item>

                          <Form.Item {...restField} label="Type" name={[name, 'type']}>
                            <Select defaultValue={'percentage'}>
                              <Option key={'percentage'} value={'percentage'}>
                                Percentage
                              </Option>
                              <Option key={'flat'} value={'flat'}>
                                Flat
                              </Option>
                            </Select>
                          </Form.Item>
                        </div>
                        <div
                          className={
                            responsize
                              ? 'grid grid-cols-2 gap-5 mb-5'
                              : 'grid grid-cols-4 gap-3 mb-5'
                          }>
                          <Form.Item
                            {...restField}
                            name={[name, 'minimumQuantity']}
                            label="Min. Quantity">
                            <InputNumber controls={false} min={0} defaultValue={0} />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, 'minimumAmount']}
                            label="Min. Amount">
                            <InputNumber controls={false} min={0} defaultValue={0} />
                          </Form.Item>

                          <Form.Item {...restField} name={[name, 'percentage']} label="Percentage">
                            <InputNumber controls={false} min={0} defaultValue={0} />
                          </Form.Item>
                          <Form.Item {...restField} name={[name, 'flat']} label="Flat">
                            <InputNumber controls={false} min={0} defaultValue={0} />
                          </Form.Item>
                        </div>

                        <div className="flex justify-center items-center">
                          {fields.length > 0 ? (
                            <MinusCircleOutlined
                              className="dynamic-delete-button flex justify-center items-center"
                              onClick={() => remove(name)}
                            />
                          ) : null}
                        </div>
                      </div>
                    ))}
                    {/* <Form.Item>
                      <Button
                        type="primary"
                        onClick={() =>
                          add({
                            productId: undefined,
                            type: 'percentage',
                            minimumQuantity: 0,
                            minimumAmount: 0,
                            percentage: 0,
                            flat: 0
                          })
                        }
                        icon={<PlusOutlined />}>
                        Add Product
                      </Button>

                      <Form.ErrorList errors={errors} />
                    </Form.Item> */}
                  </>
                )}
              </Form.List>
            </>
          )}
          {/* <PageHeader
            subTitle="Whole Discount"
            style={{
              padding: '8px 0px'
            }}
          />
          <div className="grid grid-cols-2 gap-5 mb-5"> */}
          {/* <Form.Item label="Category" name={'offerCategoryId'}>
              <Select defaultValue={'none'}>
                <Option key={'none'} value={undefined}>
                  None
                </Option>
                {categoryList?.data.results.map((category: ICategory) => (
                  <Option key={category.id} value={category.id}>
                    {category.name}
                  </Option>
                ))}
              </Select>
            </Form.Item> */}
          {/* <Form.Item label="Type" name={['wholeDiscount', 'type']}>
              <Select defaultValue={offerDetails?.wholeDiscount.type}>
                <Option key={'percentage'} value={'percentage'}>
                  percentage
                </Option>
                <Option key={'flat'} value={'flat'}>
                  flat
                </Option>
              </Select>
            </Form.Item>
          </div> */}

          {/* <div
            className={responsize ? 'grid grid-cols-2 gap-3 mb-5' : 'grid grid-cols-4 gap-5 mb-5'}>
            <Form.Item name={['wholeDiscount', 'minimumAmount']} label="Min. Amount">
              <InputNumber controls={false} min={0} defaultValue={0} />
            </Form.Item>
            <Form.Item name={['wholeDiscount', 'ceilAmount']} label="Ceil Amount">
              <InputNumber controls={false} min={0} defaultValue={0} />
            </Form.Item>
            <Form.Item name={['wholeDiscount', 'percentage']} label="Percentage">
              <InputNumber controls={false} min={0} defaultValue={0} />
            </Form.Item>
            <Form.Item name={['wholeDiscount', 'flat']} label="Flat">
              <InputNumber controls={false} min={0} defaultValue={0} />
            </Form.Item>
          </div>
          <PageHeader
            subTitle="Product Discount"
            style={{
              padding: '8px 0px'
            }}
          />
          <Form.List name="productDiscount">
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map(({ key, name, ...restField }) => {
                  const productValue = form.getFieldValue([name, 'productId']);
                  return (
                    <div key={key} className="card">
                      <div className="grid grid-cols-2 gap-5 mb-5">
                        <Form.Item {...restField} label="Product" name={[name, 'productId']}>
                          <Select defaultValue={productValue ? productValue : 'none'}>
                            <Option key={'none'} value={undefined}>
                              None
                            </Option>
                            {productList?.map((product: Product) => (
                              <Option key={product.id} value={product.id}>
                                {product.name}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Form.Item {...restField} label="Type" name={[name, 'type']}>
                          <Select defaultValue={offerDetails?.productDiscount.type}>
                            <Option key={'percentage'} value={'percentage'}>
                              Percentage
                            </Option>
                            <Option key={'flat'} value={'flat'}>
                              Flat
                            </Option>
                          </Select>
                        </Form.Item>
                      </div>
                      <div
                        className={
                          responsize ? 'grid grid-cols-2 gap-4 mb-5' : 'grid grid-cols-4 gap-5 mb-5'
                        }>
                        <Form.Item
                          {...restField}
                          name={[name, 'minimumQuantity']}
                          label="Min. Quantity">
                          <InputNumber controls={false} min={0} defaultValue={0} />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, 'minimumAmount']}
                          label="Min. Amount">
                          <InputNumber controls={false} min={0} defaultValue={0} />
                        </Form.Item>

                        <Form.Item {...restField} name={[name, 'percentage']} label="Percentage">
                          <InputNumber controls={false} min={0} defaultValue={0} />
                        </Form.Item>
                        <Form.Item {...restField} name={[name, 'flat']} label="Flat">
                          <InputNumber controls={false} min={0} defaultValue={0} />
                        </Form.Item>
                      </div>

                      <div className="flex justify-center items-center">
                        {fields.length > 0 ? (
                          <MinusCircleOutlined
                            className="dynamic-delete-button flex justify-center items-center"
                            onClick={() => remove(name)}
                          />
                        ) : null}
                      </div>
                    </div>
                  );
                })}
                <Form.Item>
                  <Button
                    type="primary"
                    onClick={() =>
                      add({
                        productId: undefined,
                        type: 'percentage',
                        minimumQuantity: 0,
                        minimumAmount: 0,
                        percentage: 0,
                        flat: 0
                      })
                    }
                    icon={<PlusOutlined />}>
                    Add Product
                  </Button>

                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </>
            )}
          </Form.List> */}
          {/* <div className="grid grid-cols-2 gap-5 mb-5">
            <Form.Item label="Product" name={['productDiscount', 'productId']}>
              <Select defaultValue={}>
                <Option key={'none'} value={undefined}>
                  None
                </Option>
                {productList?.map((product: Product) => (
                  <Option key={product.id} value={product.id}>
                    {product.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Type" name={['productDiscount', 'type']}>
              <Select defaultValue={}>
                <Option key={'percentage'} value={'percentage'}>
                  percentage
                </Option>
                <Option key={'flat'} value={'flat'}>
                  flat
                </Option>
              </Select>
            </Form.Item>
          </div>
          <div className="grid grid-cols-4 gap-5 mb-5">
            <Form.Item name={['productDiscount', 'minimumQuantity']} label="Min. Quantity">
              <InputNumber controls={false} defaultValue={0} />
            </Form.Item>
            <Form.Item name={['productDiscount', 'minimumAmount']} label="Min. Amount">
              <InputNumber controls={false} defaultValue={0} />
            </Form.Item>

            <Form.Item name={['productDiscount', 'percentage']} label="Percentage">
              <InputNumber controls={false} defaultValue={0} />
            </Form.Item>
            <Form.Item name={['productDiscount', 'flat']} label="Flat">
              <InputNumber controls={false} defaultValue={0} />
            </Form.Item>
          </div> */}
          <div className="grid grid-cols-2 gap-5 mb-5">
            <Form.Item
              name={'startDate'}
              label="Duration"
              rules={[{ required: true, message: 'Please add duration!' }]}>
              <RangePicker
                showTime={{
                  hideDisabledOptions: true,
                  defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('11:59:59', 'HH:mm:ss')]
                }}
                format="YYYY-MM-DD HH:mm:ss"
              />
            </Form.Item>
            <Form.Item name="isActive" valuePropName="checked">
              <Checkbox>Active?</Checkbox>
            </Form.Item>
          </div>

          <div className="flex justify-end mt-5">
            <div className="flex justify-end">
              <Form.Item>
                <Button type="primary" htmlType="submit" loading={submitisloading}>
                  Submit
                </Button>
              </Form.Item>
              <Button
                type="default"
                className="ml-5"
                htmlType="button"
                onClick={() => navigate('/offer')}>
                Cancel
              </Button>
            </div>
          </div>
        </Form>
        <Divider />
        {checkAccess('READ_CHANNEL') && <ReuseChannel slug={`offer_${id}`} />}
      </AppContent>
    </div>
  );
};

export default OfferDetails;
