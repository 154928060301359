import GenericTable from '@/components/Common/CustomizeTable';
import { DEFAULT_DATE_FORMAT } from '@/constants';
import { IOpeningStockHistory, IOpeningStockHistoryList } from '@/services/stock/types';
import { convertUTCStringtoLocalString } from '@/utils/convertToUTC';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';
import { FormInstance, TableProps } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { SorterResult } from 'antd/lib/table/interface';
import { useState } from 'react';

interface Props {
  isLoading?: boolean;
  data: IOpeningStockHistoryList;
  form: FormInstance;
  pagination: { page: number; size: number };
  onPagination: (
    page?: number,
    size?: number,
    isSize?: boolean
  ) => Promise<IOpeningStockHistoryList | undefined>;
}

function Table({ isLoading, data, pagination, form, onPagination }: Props) {
  const [sortedInfo, setSortedInfo] = useState<SorterResult<IOpeningStockHistory>>({});

  const handleChange: TableProps<IOpeningStockHistory>['onChange'] = (_, __, s) => {
    setSortedInfo(s as SorterResult<IOpeningStockHistory>);
  };

  const columns: ColumnsType<IOpeningStockHistory> = [
    {
      title: 'S.N.',
      key: 'id',
      width: 5,
      render: (_, __, index) => (pagination.page - 1) * pagination.size + index + 1
    },
    { title: 'Opening Stock ID', key: 'openingStockId', width: 5, dataIndex: 'openingStockId' },
    {
      title: 'Location',
      key: 'locationName',
      dataIndex: 'locationName',
      width: 20,
      sorter: (a, b) => a.locationName.localeCompare(b.locationName),
      sortOrder: sortedInfo.columnKey === 'locationName' ? sortedInfo.order : null
    },
    {
      title: 'Product',
      key: 'productName',
      dataIndex: 'productName',
      width: 20,
      sorter: (a, b) => a.productName.localeCompare(b.productName),
      sortOrder: sortedInfo.columnKey === 'productName' ? sortedInfo.order : null
    },
    {
      title: 'Unit',
      key: 'unitName',
      dataIndex: 'unitName',
      width: 10,
      sorter: (a, b) => a.unitName.localeCompare(b.unitName),
      sortOrder: sortedInfo.columnKey === 'unitName' ? sortedInfo.order : null
    },
    {
      title: 'Unit Price',
      key: 'unitPrice',
      dataIndex: 'unitPrice',
      width: 5,
      sorter: (a, b) => (Number(a.unitPrice) || 0) - (Number(b.unitPrice) || 0),
      sortOrder: sortedInfo.columnKey === 'unitPrice' ? sortedInfo.order : null,
      render: (_, record) => nepaliNumberFormatter(record.unitPrice)
    },
    {
      title: 'Quantity',
      key: 'quantity',
      dataIndex: 'quantity',
      width: 5,
      sorter: (a, b) => (Number(a.quantity) || 0) - (Number(b.quantity) || 0),
      sortOrder: sortedInfo.columnKey === 'quantity' ? sortedInfo.order : null
    },
    {
      title: 'Qty Available',
      key: 'qtyAvailable',
      dataIndex: 'qtyAvailable',
      width: 5,
      sorter: (a, b) => (Number(a.qtyAvailable) || 0) - (Number(b.qtyAvailable) || 0),
      sortOrder: sortedInfo.columnKey === 'qtyAvailable' ? sortedInfo.order : null
    },
    {
      title: 'Opening Quantity',
      key: 'openingQuantity',
      dataIndex: 'openingQuantity',
      width: 5,
      sorter: (a, b) => (Number(a.openingQuantity) || 0) - (Number(b.openingQuantity) || 0),
      sortOrder: sortedInfo.columnKey === 'openingQuantity' ? sortedInfo.order : null
    },
    {
      title: 'Accounted Quantity',
      key: 'accountedQuantity',
      dataIndex: 'accountedQuantity',
      width: 5,
      sorter: (a, b) => (Number(a.accountedQuantity) || 0) - (Number(b.accountedQuantity) || 0),
      sortOrder: sortedInfo.columnKey === 'accountedQuantity' ? sortedInfo.order : null
    },
    {
      title: 'Miscellaneous Quantity',
      key: 'unAccountedQuantity',
      dataIndex: 'unAccountedQuantity',
      width: 5,
      sorter: (a, b) => (Number(a.unAccountedQuantity) || 0) - (Number(b.unAccountedQuantity) || 0),
      sortOrder: sortedInfo.columnKey === 'unAccountedQuantity' ? sortedInfo.order : null
    },
    {
      title: 'Wastage',
      key: 'wastage',
      dataIndex: 'wastage',
      width: 5,
      sorter: (a, b) => (Number(a.wastage) || 0) - (Number(b.wastage) || 0),
      sortOrder: sortedInfo.columnKey === 'wastage' ? sortedInfo.order : null
    },
    {
      title: 'Last Updated',
      key: 'lastUpdated',
      width: 15,
      dataIndex: 'updatedAt',
      sorter: (a, b) => a.updatedAt.localeCompare(b.updatedAt),
      sortOrder: sortedInfo.columnKey === 'lastUpdated' ? sortedInfo.order : null,
      render: (_, record) => convertUTCStringtoLocalString(record.updatedAt, DEFAULT_DATE_FORMAT)
    }
  ];

  return (
    <div>
      <GenericTable
        form={form}
        columns={columns}
        data={data.results}
        isLoading={isLoading}
        scroll={{ x: 1600, y: '75vh' }}
        hideDefaultPagination
        tableName={'opening-stock-history-list'}
        pagination={{ ...pagination, total: data.total, onPagination, scrollToTop: true }}
        toSort={handleChange}
      />
    </div>
  );
}

export default Table;
