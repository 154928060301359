export enum StoreNames {
  Locations = 'Locations',
  Products = 'Products',
  Units = 'Units',
  Category = 'Category',
  FinancialYears = 'FinancialYears',
  Vehicles = 'Vehicles',
  Users = 'Users',
  Customers = 'Customers',
  Agents = 'Agents',
  Vendors = 'Vendors',
  Routes = 'Routes',
  TotalLotsAvailable = 'TotalLotsAvailable',
  ExpenseCategory = 'ExpenseCategory',
  Expenses = 'Expenses',
  AgentsBillRange = 'AgentsBillRange',
  Menu = 'Menu',
  Reports = 'Reports',
  Dashboard = 'Dashboard',
  Accounts = 'Accounts',
  CustomerGroups = 'CustomerGroups',
  HRGroups = 'HRGroups',
  Shifts = 'Shifts',
  WooCommerceProducts = 'WooCommerceProducts',
  WooCommerceCustomers = 'WooCommerceCustomers'
}

export enum IndexNames {
  LocationsSecondIndex = 'LocationsSecondIndex',
  ProductsSecondIndex = 'ProductsSecondIndex',
  UnitsSecondIndex = 'UnitsSecondIndex',
  CategorySecondIndex = 'CategorySecondIndex',
  FinancialYearSecondIndex = 'FinancialYearSecondIndex',
  VehiclesSecondIndex = 'VehiclesSecondIndex',
  UsersSecondIndex = 'UsersSecondIndex',
  CustomersSecondIndex = 'CustomersSecondIndex',
  AgentsSecondIndex = 'AgentsSecondIndex',
  VendorsSecondIndex = 'VendorsSecondIndex',
  RoutesSecondIndex = 'RoutesSecondIndex',
  ExpenseCategorySecondIndex = 'ExpenseCategorySecondIndex',
  AccountsSecondIndex = 'AccountsSecondIndex',
  CustomerGroupsSecondIndex = 'CustomerGroupsSecondIndex',
  HRGroupsSecondIndex = 'HRGroupsSecondIndex',
  ShiftsSecondIndex = 'ShiftsSecondIndex',
  WooCommerceProductsSecondIndex = 'WooCommerceProductsSecondIndex',
  WooCommerceCustomersSecondIndex = 'WooCommerceCustomersSecondIndex'
}
