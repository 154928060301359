import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  InputNumber,
  PageHeader,
  Spin,
  message
} from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AppContent from '@/components/Common/Content/Content';
import GoBackButton from '@/components/Common/GoBackButton/GoBackButton';
import moment from 'moment';
import { PlusOutlined } from '@ant-design/icons';
import CustomButton from '@/components/Common/CustomButton/CustomButton';
import { AccountsTypeSearch } from '@/components/Common/AccountsTypeSearch';
import {
  IAccountTypeResponseData,
  ICreateJournal,
  LinesForJournal
} from '@/services/accounts/types';
import CustomErrorModal from '@/components/Common/CustomErrorModal';
import { getUserData } from '@/utils/auth.utils';
import { LocationSearch } from '@/components/Common/LocationSearch/LocationSearch';
import { CustomModal } from '@/components/Common/CustomModal';
import ReusableAccountCreate from '@/components/Common/CustomModal/ReusableAccountCreate';
import { create_journal_mutation } from '@/services/accounts/mutations';
import { useMutation } from '@tanstack/react-query';
import { ReusableJournalPreview } from '@/components/Common/CustomModal/ReusableJournalPreview';
import isAxiosError from '@/utils/isAxiosError';
import { useFilterStore } from '@/store/zustand';
import { ListPage } from '@/constants/list.enum';

const CreateJournal = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [journalDetails, setJournalDetails] = useState<ICreateJournal>();
  const [accountIdList, setAccountIdList] = useState<IAccountTypeResponseData[]>([]);
  const [previewData, setPreviewData] = useState<ICreateJournal>({} as ICreateJournal);
  const [isJournalPreviewModalOpen, setIsJournalPreviewModalOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [locationId, setLocationId] = useState<number>();
  const [isAddAccountModalOpen, setIsAddAccountModalOpen] = useState<boolean>(false);
  const scrollElement = useRef<HTMLDivElement>(null);
  const breadcrumbItems = [
    {
      label: 'Journal',
      link: '/accounts/journal'
    },
    {
      label: 'New',
      link: '/accounts/journal/new'
    }
  ];

  useEffect(() => {
    setInitialValues();
  }, []);

  const { preferences } = getUserData();
  const preferenceLocationId = preferences?.preferences
    ? JSON.parse(preferences?.preferences)?.locationId
    : undefined;

  const setInitialValues = async () => {
    form.setFieldValue(['date'], moment());
    handleLocationChange();
  };

  const zustandFilter = useFilterStore();
  const createJournalMutation = useMutation(create_journal_mutation, {
    onSuccess: () => {
      zustandFilter.resetState(ListPage.JOURNAL);
    }
  });

  const onFinish = async (values: ICreateJournal = form.getFieldsValue()) => {
    // console.log('Form Values', values);
    setIsLoading(true);
    try {
      if (values.lines == undefined) {
        throw {
          name: 'LinesError',
          message: 'Must have atleast one journal entry.'
        };
      }
      if (values.lines.length == 0) {
        throw {
          name: 'LinesError',
          message: 'Must have atleast one journal entry.'
        };
      }
      let drTotal = 0;
      let crTotal = 0;
      for (let ind = 0; ind < values.lines.length; ind++) {
        drTotal = parseFloat((drTotal + values.lines[ind].debit).toFixed(2));
        crTotal = parseFloat((crTotal + values.lines[ind].credit).toFixed(2));
      }
      if (drTotal !== crTotal) {
        throw {
          name: 'TallyError',
          message: 'Total Credit is not equals to Total Debit.'
        };
      }

      values.lines = values.lines.map((value) => {
        return { ...value, locationId: locationId };
      });

      await handleJournalPreview(values);

      // await createJournalMutation.mutateAsync(values, {
      //   onSuccess: async (data) => {
      //     setIsLoading(false);
      //     if (data) {
      //       // console.log('data-->', data);
      //       message.success('Journal added successfully');
      //       navigate('/accounts/journal');
      //     }
      //   },
      //   onError: (data: any) => {
      //     setIsLoading(false);
      //     message.error(data.message);
      //   }
      // });
    } catch (errors: any) {
      setIsLoading(false);
      if (isAxiosError(errors)) return;
      if ('name' in errors) {
        CustomErrorModal({
          title: 'Error! Cannot create journal',
          message: errors.message
        });
      } else {
        CustomErrorModal({
          title: 'Error! Cannot create journal',
          message: errors.message
        });
      }
    }
  };

  const handleJournalPreview = (values: ICreateJournal) => {
    setPreviewData(values);
    setIsJournalPreviewModalOpen(true);
    setIsLoading(false);
  };

  const handleJournalPreviewModalClose = async (values: ICreateJournal) => {
    setIsJournalPreviewModalOpen(false);
    setIsLoading(true);
    await createJournalMutation.mutateAsync(values, {
      onSuccess: async (data) => {
        setIsLoading(false);
        if (data) {
          // console.log('data-->', data);
          message.success('Journal added successfully');
          navigate(`/accounts/journal/view/${data.data.journal.id}?print=1`);
        }
      },
      onError: (data: any) => {
        setIsLoading(false);
        message.error(data.message);
      }
    });
    setIsLoading(false);
  };

  const onDebitCreditChange = () => {
    const lines: LinesForJournal[] = form.getFieldValue(['lines']);
    if (lines !== undefined) {
      if (lines.length > 0) {
        let drTotal = 0;
        let crTotal = 0;
        for (let ind = 0; ind < lines.length; ind++) {
          drTotal = parseFloat((drTotal + lines[ind].debit).toFixed(2));
          crTotal = parseFloat((crTotal + lines[ind].credit).toFixed(2));
        }

        form.setFieldValue(['totalDebit'], drTotal);
        form.setFieldValue(['totalCredit'], crTotal);
      }
    }
  };

  const handleAddAccount = () => {
    setIsAddAccountModalOpen(true);
  };

  const handleAddAccountModalClose = () => {
    setIsAddAccountModalOpen(false);
  };

  const handleLocationChange = () => {
    // console.log('locationId', form.getFieldValue(['locationId']));
    form.resetFields(['lines', 'accountId']);
    setLocationId(form.getFieldValue(['locationId']));
  };

  return (
    <Spin spinning={isLoading}>
      <CustomModal
        isModalOpen={isAddAccountModalOpen}
        setIsModalOpen={setIsAddAccountModalOpen}
        title={'Add Customer'}
        footer={false}>
        <ReusableAccountCreate handleModalClose={handleAddAccountModalClose} />
      </CustomModal>
      <CustomModal
        isModalOpen={isJournalPreviewModalOpen}
        setIsModalOpen={setIsJournalPreviewModalOpen}
        title={'Preview'}
        footer={false}>
        <ReusableJournalPreview
          data={previewData}
          handleModalClose={handleJournalPreviewModalClose}
        />
      </CustomModal>
      <AppContent
        breadcrumbItems={breadcrumbItems}
        withfilter={false}
        backgroundWhite={true}
        button={<GoBackButton onClick={() => navigate('/accounts/journal')} />}>
        <Form
          form={form}
          initialValues={{
            locationId: preferenceLocationId ? preferenceLocationId : 1,
            totalDebit: 0,
            totalCredit: 0
          }}
          onFinish={onFinish}
          layout="vertical"
          validateTrigger={'onChange'}
          disabled={isLoading}
          onValuesChange={(_, allFields) => {
            setJournalDetails(allFields);
          }}
          autoComplete="off">
          <Card style={{ borderRadius: '10px' }}>
            <div className="flex justify-end">
              <Button type="primary" onClick={handleAddAccount}>
                Add Account
              </Button>
            </div>
            <PageHeader
              title="Journal Information"
              style={{
                padding: '8px 0px'
              }}
            />
            <div className={'grid cols-1 md:grid-cols-4 gap-5 mb-5'}>
              <LocationSearch
                formData={{ formLabel: 'Location', formName: 'locationId' }}
                required={true}
                notAll={true}
                onSelect={() => handleLocationChange()}
              />
              <Form.Item
                name={['date']}
                label="Date"
                rules={[
                  {
                    required: true,
                    message: 'Please choose date!'
                  }
                ]}>
                <DatePicker style={{ width: '100%' }} defaultValue={moment()} allowClear={false} />
              </Form.Item>
              <Form.Item
                name={['description']}
                label="Description"
                rules={[
                  {
                    required: true,
                    message: 'Please add a description!'
                  }
                ]}>
                <Input />
              </Form.Item>
              <Form.Item
                name={['chequeRef']}
                label="Cheque Reference"
                rules={[
                  {
                    required: true,
                    pattern: new RegExp('^(?:[0-9]*[a-zA-Z]?[0-9]*)$'),
                    message: 'Can contain only one alphabet'
                  }
                ]}>
                <Input />
              </Form.Item>
            </div>
          </Card>
          <Card
            ref={scrollElement}
            style={{
              maxHeight: '50vh',
              overflowY: 'scroll',
              borderRadius: '9px'
            }}>
            <Form.List
              name={['lines']}
              initialValue={[
                { accountId: null, description: '', debit: 0, credit: 0 },
                { accountId: null, description: '', debit: 0, credit: 0 }
              ]}>
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field, index) => (
                    <div className="flex gap-2 items-center" key={field.key}>
                      <span className="font-bold text-sm mb-5">{index + 1}.</span>
                      <div
                        className="card"
                        style={{ borderRadius: '9px', backgroundColor: 'white' }}>
                        <div
                          className={
                            'grid grid-cols-2 gap-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-7 xl:grid-cols-7'
                          }>
                          <AccountsTypeSearch
                            formData={{ formName: [field.name, 'accountId'], label: 'Account' }}
                            isAll={false}
                            required={true}
                            accountList={accountIdList}
                            setAccountList={setAccountIdList}
                            locationId={locationId}
                          />
                          <Form.Item name={[field.name, 'description']} label={'Description'}>
                            <Input value={''} />
                          </Form.Item>
                          <Form.Item
                            name={[field.name, 'debit']}
                            label={'DR.'}
                            rules={[
                              {
                                required: true,
                                message: 'Please add a debit or credit!'
                              }
                            ]}>
                            <InputNumber
                              controls={false}
                              onChange={() => {
                                form.setFieldValue(['lines', field.name, 'credit'], 0);
                                onDebitCreditChange();
                              }}
                            />
                          </Form.Item>
                          <Form.Item
                            name={[field.name, 'credit']}
                            label={'CR.'}
                            rules={[
                              {
                                required: true,
                                message: 'Please add a debit or credit!'
                              }
                            ]}>
                            <InputNumber
                              controls={false}
                              onChange={() => {
                                form.setFieldValue(['lines', field.name, 'debit'], 0);
                                onDebitCreditChange();
                              }}
                            />
                          </Form.Item>
                          <div className="flex items-center justify-start mt-5">
                            <CustomButton
                              backgroundColor="white"
                              text="Remove"
                              textColor="green"
                              onClick={() => {
                                remove(field.name);
                                const y = scrollElement.current?.scrollTop;
                                if (scrollElement.current && typeof y == 'number') {
                                  scrollElement.current.scrollTop = y;
                                }
                                onDebitCreditChange();
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => {
                        add({ description: '' });
                      }}
                      block
                      style={{ width: '100%' }}>
                      <PlusOutlined /> Add field
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4 mt-5">
              <Form.Item name={'totalDebit'} label="Total Debit">
                <InputNumber controls={false} readOnly={true} />
              </Form.Item>
              <Form.Item name={'totalCredit'} label="Total Credit">
                <InputNumber controls={false} readOnly={true} />
              </Form.Item>
            </div>
          </Card>
          <div className="flex justify-end mt-5">
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={isLoading}>
                Submit
              </Button>
            </Form.Item>
          </div>
        </Form>
      </AppContent>
    </Spin>
  );
};

export default CreateJournal;
