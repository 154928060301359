import moment from 'moment';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Form, Input, DatePicker, PageHeader, Spin, Checkbox, message, Modal } from 'antd';

import AppContent from '@/components/Common/Content/Content';
import { FinancialYearDB } from '@/store/localstorage/FinancialYearDB';
import { useQuery } from '@tanstack/react-query';
import { get_financial_year_by_id, update_financial_year } from '@/services/financial-year/queries';
import { RangePickerProps } from 'antd/lib/date-picker';
import { DEFAULT_DATE_FORMAT } from '@/constants';
import { useFilterStore } from '@/store/zustand';
import { ListPage } from '@/constants/list.enum';

interface FormValues {
  name: string;
  dateRange: [moment.Moment, moment.Moment];
  isClosed: boolean;
}

function FinancialYearUpdate() {
  const { id } = useParams();

  const navigate = useNavigate();
  const [form] = Form.useForm<FormValues>();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedData, setSelectedData] = useState(['', '']);
  const [openConfirmation, setOpenConfirmation] = useState(false);

  const zustandFilter = useFilterStore();

  if (!id) {
    return <h1>Financial Year not found</h1>;
  }

  const { data } = useQuery(
    ['financial-year', id],
    async () => {
      setIsLoading(true);
      const response = await get_financial_year_by_id(parseInt(id));
      return response;
    },

    {
      enabled: !!id,
      onError: () => {
        message.error('Failed to get financial year details');
      },
      onSuccess: (data) => {
        setIsLoading(false);
        const date = [
          moment(data.startDate).startOf('day'),
          moment(data.endDate).endOf('day').set({ millisecond: 0 })
        ];

        setSelectedData([date[0].toISOString(), date[1].toISOString()]);
        form.setFieldsValue({
          name: data.name,
          dateRange: date,
          isClosed: data.isClosed
        });
      }
    }
  );

  const onChange: RangePickerProps['onChange'] = (_, dateStrings) => {
    if (dateStrings.length == 2) {
      const [startDate, endDate] = dateStrings;
      const newStartDate = moment(startDate).startOf('day');
      const newEndDate = moment(endDate).endOf('day').set({ millisecond: 0 });

      setSelectedData([newStartDate.toISOString(), newEndDate.toISOString()]);
    }
  };

  async function handleFormEvaluate() {
    const values = await form.validateFields();
    if (values.dateRange.length !== 2) {
      message.error('Please select valid date range');
      return;
    }

    setOpenConfirmation(true);
  }

  async function handleFormSubmit() {
    try {
      if (!id) return;

      setIsLoading(true);
      setOpenConfirmation(false);
      const values = await form.validateFields();

      if (values.dateRange.length !== 2) {
        message.error('Please select valid date range');
        return;
      }

      const data = {
        name: values.name,
        startDate: selectedData[0],
        endDate: selectedData[1],
        isClosed: values.isClosed
      };

      const response = await update_financial_year(parseInt(id), data);
      zustandFilter.removeData(ListPage.FINANCIAL_YEAR);
      if (response) {
        await FinancialYearDB.update(response);
      }

      message.success('Financial Year updated successfully');
      navigate('/accounts/financial-year');
    } catch (error: any) {
      const hasError = error?.response?.data?.message || 'Failed to update financial year';
      message.error(hasError);
    } finally {
      setIsLoading(false);
      setOpenConfirmation(false);
    }
  }

  return (
    <Spin spinning={isLoading}>
      <AppContent
        breadcrumbItems={[
          { label: 'Accounts', link: '/accounts' },
          { label: 'FinancialYear', link: '/accounts/financial-year' },
          { label: 'Details', link: `/accounts/financial-year/${id}` }
        ]}>
        <Modal
          title="Confirmation"
          visible={openConfirmation}
          onOk={handleFormSubmit}
          onCancel={() => {
            setOpenConfirmation(false);
          }}>
          <div>
            <span className="block">Do you want to update this financial year?</span>
            {`${moment(selectedData[0]).format(DEFAULT_DATE_FORMAT)} - ${moment(
              selectedData[1]
            ).format(DEFAULT_DATE_FORMAT)}`}
          </div>
        </Modal>
        <Form
          form={form}
          initialValues={{ isClosed: false }}
          onFinish={handleFormEvaluate}
          layout="vertical"
          autoComplete="off"
          disabled={isLoading}
          validateTrigger={'onChange'}>
          <PageHeader title="Financial Year Information" style={{ padding: '8px 0px' }} />
          <div className="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: 'Name is required' }]}>
              <Input />
            </Form.Item>

            <Form.Item
              label={'Select Year Range'}
              name={'dateRange'}
              rules={[{ required: true, message: 'Year Range is required' }]}>
              <DatePicker.RangePicker
                format={'YYYY-MM-DD'}
                allowClear
                onChange={onChange}
                className="w-full"
              />
            </Form.Item>

            <Form.Item label={'Status'} name={'isClosed'} valuePropName="checked">
              <Checkbox disabled={data?.isClosed}>Close Financial Year</Checkbox>
            </Form.Item>
          </div>

          <div className="flex sm:justify-end gap-4 mt-8 flex-wrap">
            <Button type="primary" htmlType="submit">
              Update
            </Button>

            <Button onClick={() => navigate(-1)}>Back</Button>
          </div>
        </Form>
      </AppContent>
    </Spin>
  );
}

export default FinancialYearUpdate;
