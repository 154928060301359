import GenericTable from '@/components/Common/CustomizeTable';
import TableCell from '@/components/Common/CustomizeTable/CustomCell';
import ExportCurrent from '@/components/Common/ExportCurrent';
import { IAverageSellReport } from '@/services/report/types';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';
import { TableProps } from 'antd';
import { SorterResult } from 'antd/lib/table/interface';
import { useEffect, useState } from 'react';
import { getUpdatedData, tableExportColumns, excelExportColumns } from './column.export';
import { convertUTCStringtoLocalString } from '@/utils/convertToUTC';

type PaginatedData = { data: IAverageSellReport[]; total: number };
const DEFAULT_SIZE = 100;

function TableData({ data }: { data: IAverageSellReport[] }) {
  const [sortedInfo, setSortedInfo] = useState<SorterResult<IAverageSellReport>>({});
  const [pagination, setPagination] = useState({ page: 1, size: DEFAULT_SIZE });
  const [paginatedData, setPaginatedData] = useState<PaginatedData>({ data: [], total: 0 });

  useEffect(() => {
    const paginatedData = { data: data.slice(0, DEFAULT_SIZE), total: data.length };
    setPaginatedData(paginatedData);
    setPagination({ page: 1, size: DEFAULT_SIZE });
  }, [data]);

  const handleChange: TableProps<IAverageSellReport>['onChange'] = (_, __, sorter) => {
    setSortedInfo(sorter as SorterResult<IAverageSellReport>);
  };

  const columns: TableProps<IAverageSellReport>['columns'] = [
    {
      title: 'S.N',
      key: 'SN',
      width: 30,
      fixed: 'left',
      render: (_, __, index) => {
        return <TableCell>{index + 1}</TableCell>;
      }
    },
    {
      title: 'Location',
      dataIndex: 'location_name',
      width: 75,
      key: 'location_name',
      sorter: (a, b) => a.location_name.localeCompare(b.location_name),
      sortOrder: sortedInfo.columnKey === 'location_name' ? sortedInfo.order : null
    },
    {
      title: 'Date',
      dataIndex: 'date',
      width: 50,
      key: 'date',
      sorter: (a, b) => a.date.localeCompare(b.date),
      sortOrder: sortedInfo.columnKey === 'date' ? sortedInfo.order : null,
      render: (text: string) => convertUTCStringtoLocalString(text, 'YYYY-MM-DD')
    },
    {
      title: 'No of Sells',
      dataIndex: 'no_of_sells',
      width: 30,
      key: 'no_of_sells',
      align: 'right',
      sorter: (a, b) => parseFloat(a.no_of_sells) - parseFloat(b.no_of_sells),
      sortOrder: sortedInfo.columnKey === 'no_of_sells' ? sortedInfo.order : null,
      render: (text: number) => <TableCell>{nepaliNumberFormatter(text)}</TableCell>
    },
    {
      title: 'Total Amount',
      dataIndex: 'total_amount',
      width: 50,
      key: 'total_amount',
      align: 'right',
      sorter: (a, b) => parseFloat(a.total_amount) - parseFloat(b.total_amount),
      sortOrder: sortedInfo.columnKey === 'total_amount' ? sortedInfo.order : null,
      render: (text: number) => <TableCell>{nepaliNumberFormatter(text)}</TableCell>
    },
    {
      title: 'Total Return Amount',
      dataIndex: 'total_return_amount',
      width: 50,
      key: 'total_return_amount',
      align: 'right',
      sorter: (a, b) => parseFloat(a.total_return_amount) - parseFloat(b.total_return_amount),
      sortOrder: sortedInfo.columnKey === 'total_return_amount' ? sortedInfo.order : null,
      render: (text: number) => <TableCell>{nepaliNumberFormatter(text)}</TableCell>
    },
    {
      title: 'Net Amount',
      dataIndex: 'net_amount',
      width: 50,
      key: 'net_amount',
      align: 'right',
      sorter: (a, b) => parseFloat(a.net_amount) - parseFloat(b.net_amount),
      sortOrder: sortedInfo.columnKey === 'net_amount' ? sortedInfo.order : null,
      render: (text: number) => <TableCell>{nepaliNumberFormatter(text)}</TableCell>
    },
    {
      title: 'Average Transaction',
      dataIndex: 'avg_transaction_amount',
      width: 50,
      key: 'avg_transaction_amount',
      align: 'right',
      sorter: (a, b) => parseFloat(a.avg_transaction_amount) - parseFloat(b.avg_transaction_amount),
      sortOrder: sortedInfo.columnKey === 'avg_transaction_amount' ? sortedInfo.order : null,
      render: (text: number) => <TableCell>{nepaliNumberFormatter(text)}</TableCell>
    }
  ];

  const updatedData = getUpdatedData(data);

  function onPagination(page: number, size?: number) {
    const pages = size ? 1 : page;
    const sizes = size || pagination.size;

    setPagination((prev) => {
      prev.page = pages;
      if (size) prev.size = size;
      return prev;
    });

    setPaginatedData((prev) => {
      const start = (pages - 1) * sizes;
      const end = pages * sizes;
      return { ...prev, data: data.slice(start, end) };
    });
  }

  return (
    <div>
      <GenericTable
        columns={columns}
        data={paginatedData.data}
        toSort={handleChange}
        scroll={{ x: 1000 }}
        hideDefaultPagination
        generateSummary
        makeSummaryAverage={['avg_transaction_amount']}
        buttons={
          <ExportCurrent
            data={{ total: updatedData.length, results: updatedData }}
            columns={tableExportColumns}
            excelColumns={excelExportColumns}
            title="Average Sells Report"
          />
        }
        pagination={{
          page: pagination.page,
          total: paginatedData.total,
          size: pagination.size,
          onPagination: (pageNo, currentSize) => {
            currentSize == pagination.size
              ? onPagination(pageNo)
              : onPagination(pageNo, currentSize);
          }
        }}
      />
    </div>
  );
}

export default TableData;
