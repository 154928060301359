import AppContent from '@/components/Common/Content/Content';
import CustomErrorModal from '@/components/Common/CustomErrorModal';
import CustomerGroupsSearchV2 from '@/components/Common/CustomSearch/Customer/CustomerGroups';
import { ListPage } from '@/constants/list.enum';
import { create_customers_price_group_mutation } from '@/services/customer-prices/mutation';
import { useFilterStore } from '@/store/zustand';
import getErrorMessage from '@/utils/getError';
import isAxiosError from '@/utils/isAxiosError';
import { useMutation } from '@tanstack/react-query';
import { Button, Form, Input, message, Modal, PageHeader, Spin } from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface FormData {
  customerGroupId: number;
  name: string;
}

function CustomerPriceGroupCreate() {
  const navigate = useNavigate();
  const [form] = Form.useForm<FormData>();
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const zustandFilter = useFilterStore();

  const create_customer_price_groups_mutation = useMutation(create_customers_price_group_mutation, {
    onSuccess: () => zustandFilter.resetState(ListPage.CUSTOMER_PRICE_GROUP)
  });

  async function onFinish() {
    try {
      setIsLoading(true);
      const values = await form.validateFields();
      await create_customer_price_groups_mutation.mutateAsync(values);

      message.success('Customer Price Group added successfully');
      navigate('/price-groups/customers');
    } catch (error) {
      if (isAxiosError(error)) return;
      CustomErrorModal({ message: getErrorMessage(error) });
    } finally {
      setIsLoading(false);
    }
  }

  async function onConfirmation() {
    await form.validateFields();
    setShowModal(true);
  }

  return (
    <Spin spinning={isLoading}>
      <Modal
        visible={showModal}
        onCancel={() => setShowModal(false)}
        onOk={() => {
          setShowModal(false);
          onFinish();
        }}>
        <p>Are you sure you want to create this?</p>
      </Modal>

      <AppContent
        breadcrumbItems={[
          { label: 'Customer Price Groups', link: '/price-groups/customers' },
          { label: 'New' }
        ]}>
        <div className="font-bold text-lg">New Price Group</div>
        <PageHeader
          subTitle="Create a new price group for customers"
          style={{ padding: '0 0 8px' }}
        />

        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          validateTrigger={'onChange'}
          autoComplete="off">
          <div className={'grid grid-cols-3 gap-5 mb-5'}>
            <CustomerGroupsSearchV2 hasParentFormItem={false} name="customerGroupId" required />

            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: 'Please add name!' }]}>
              <Input />
            </Form.Item>
          </div>

          <div className="flex mt-4 justify-end gap-2">
            <Button type="primary" ghost onClick={() => navigate(-1)}>
              Go Back
            </Button>

            <Button type="primary" onClick={onConfirmation}>
              Create
            </Button>
          </div>
        </Form>
      </AppContent>
    </Spin>
  );
}

export default CustomerPriceGroupCreate;
