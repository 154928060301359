import { Link, useLocation } from 'react-router-dom';
import { Dispatch, SetStateAction, useRef } from 'react';

// ANTD Components
import { Button, Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { EllipsisOutlined, EnterOutlined } from '@ant-design/icons';

import { nepaliNumberFormatter } from '@/utils/numberFormatter';
import TableCell from '@/components/Common/CustomizeTable/CustomCell';
import { addTransactionToBalanceChildren } from '../balance-sheet.services';
import { addLocationIfAbsent, convertIdToIndex } from '@/services/accounts/services';

import {
  IBalanceSheetTableDataForIndexing,
  IBalanceSheetWithTransaction,
  ITotalBalanceTypes
} from '@/services/accounts/types';
import { useFilterStore } from '@/store/zustand';
import { IBalanceSheetSaveData } from '.';
import useWindowScrollRestoration from '@/hooks/useWindowScrollRestoration';

interface Props {
  date: { startDate: string; endDate: string };
  tableData: IBalanceSheetWithTransaction[];
  shownColumns: CheckboxValueType[];
  locationId?: number | string;
  expandedRows: number[];
  total: ITotalBalanceTypes;

  setIsLoading: Dispatch<SetStateAction<boolean>>;
  setExpandedRows: Dispatch<SetStateAction<number[]>>;
  setTotal: Dispatch<SetStateAction<ITotalBalanceTypes>>;
  setTableData: Dispatch<SetStateAction<IBalanceSheetWithTransaction[]>>;
}

function TableColumn({
  date,
  tableData,
  expandedRows,
  shownColumns,
  locationId,
  total,
  setExpandedRows,
  setTableData,
  setTotal
}: Props) {
  const location = useLocation();
  const zustandFilter = useFilterStore();

  const tableRef = useRef<HTMLDivElement>(null);

  useWindowScrollRestoration({
    totalLength: tableData.length,
    tableRef
  });

  // Columns for the table
  const columns: ColumnsType<IBalanceSheetWithTransaction> = [
    {
      title: 'Name',
      key: 'name',
      width: 40,
      render: (a, record) => {
        return (
          <>
            <TableCell>
              {record.hasMore ? (
                <Tooltip title="Load More . . .">
                  <Button
                    type="primary"
                    style={{ borderRadius: '9999px', padding: '0px 8px', height: '20px' }}
                    onClick={async (event) => {
                      event.stopPropagation();
                      await searchMore(record);
                    }}>
                    <EllipsisOutlined style={{ fontSize: '20px' }} />
                  </Button>
                </Tooltip>
              ) : record.parentId ? (
                <div>
                  <EnterOutlined
                    className="origin-center -scale-x-100 text-lg"
                    style={{ color: record.iconColor }}
                  />{' '}
                  <Link
                    to={`/accounts/detailed-ledger?accountId=${record.id}&startDate=${date.startDate}&endDate=${date.endDate}`}
                    onClick={(event) => event.stopPropagation()}
                    target="_blank">
                    {record.locationName
                      ? `${record.name} ${record.locationName}`
                      : `${record.name}`}
                  </Link>
                </div>
              ) : (
                <Link
                  to={`/accounts/detailed-ledger?accountId=${record.id}&&startDate=${date.startDate}&endDate=${date.endDate}`}
                  onClick={(event) => event.stopPropagation()}
                  target="_blank">
                  {record.locationName ? `${record.name} ${record.locationName}` : `${record.name}`}
                </Link>
              )}
            </TableCell>
          </>
        );
      }
    },
    {
      title: 'Type',
      key: 'type',
      width: 15,
      render: (_, record) => <TableCell>{record.type}</TableCell>
    },
    {
      title: 'Debit (Start)',
      key: 'debitStart',
      width: 15,
      render: (a, record) => {
        return (
          <>
            <TableCell>
              {record.hasMore
                ? ''
                : record.startBalance !== null
                ? nepaliNumberFormatter(record.startBalance >= 0 ? record.startBalance : 0)
                : 'N/A'}
            </TableCell>
          </>
        );
      }
    },
    {
      title: 'Credit (Start)',
      key: 'creditStart',
      width: 15,
      render: (a, record) => {
        return (
          <>
            <TableCell>
              {record.hasMore
                ? ''
                : record.startBalance !== null
                ? nepaliNumberFormatter(
                    record.startBalance <= 0 ? Math.abs(record.startBalance) : 0
                  )
                : 'N/A'}
            </TableCell>
          </>
        );
      }
    },
    {
      title: 'Debit (Transaction)',
      key: 'transactionDebitStart',
      width: 20,
      render: (a, record) => {
        return (
          <>
            <TableCell>
              {record.hasMore
                ? ''
                : record.transaction
                ? nepaliNumberFormatter(record.transaction.totalDebit)
                : 'N/A'}
            </TableCell>
          </>
        );
      }
    },
    {
      title: 'Credit (Transaction)',
      key: 'transactionCreditStart',
      width: 20,
      render: (a, record) => {
        return (
          <TableCell>
            {record.hasMore
              ? ''
              : record.transaction
              ? nepaliNumberFormatter(record.transaction.totalCredit)
              : 'N/A'}
          </TableCell>
        );
      }
    },
    {
      title: 'Debit (End)',
      key: 'debitEnd',
      width: 15,
      render: (a, record) => {
        return (
          <TableCell>
            {record.hasMore
              ? ''
              : record.endBalance !== null
              ? nepaliNumberFormatter(record.endBalance >= 0 ? record.endBalance : 0)
              : 'N/A'}
          </TableCell>
        );
      }
    },
    {
      title: 'Credit (End)',
      key: 'creditEnd',
      width: 15,
      render: (a, record) => {
        return (
          <TableCell>
            {record.hasMore
              ? ''
              : record.endBalance !== null
              ? nepaliNumberFormatter(record.endBalance <= 0 ? Math.abs(record.endBalance) : 0)
              : 'N/A'}
          </TableCell>
        );
      }
    }
  ];

  // Function to get sub balance list for the parent
  async function getSubBalanceList(
    parentData: IBalanceSheetWithTransaction,
    locationId: number | string
  ) {
    let hasMoreData = false;
    if (!parentData) return;

    const parentMapIds: Map<number, number> = new Map();
    parentMapIds.set(parentData.id, parentData.id);

    const parentIds = [parentData.id];

    // Get children data and append transaction to it
    const response = await addTransactionToBalanceChildren({
      locationId,
      date,
      currentBalanceSortBy: 'DESC',
      parentIds,
      skip: 0,
      count: 100
    });

    if (response.results.length === 0) return;
    if (response.results.length >= 100) hasMoreData = true;

    // Check if location is absent
    if (locationId === '') {
      response.results = await addLocationIfAbsent({
        data: response.results
      });
    }

    const cloneData = [...tableData];
    const childData: IBalanceSheetWithTransaction[] = [...response.results];

    if (hasMoreData) {
      const obj = {} as IBalanceSheetWithTransaction;
      childData.push({
        ...obj,
        parentId: parentData.id,
        parentIds,
        skip: response.results.length,
        hasMore: true
      });
    }

    if (parentIds) {
      const newTableData = convertIdToIndex(
        parentMapIds,
        cloneData as IBalanceSheetTableDataForIndexing[],
        childData as IBalanceSheetTableDataForIndexing[]
      );
      setTableData(newTableData);
      return { tableData: newTableData };
    }
  }

  async function searchMore(record: IBalanceSheetWithTransaction) {
    if (locationId === undefined || record.parentIds === undefined) return;
    const savedData = {} as IBalanceSheetSaveData;

    const response = await addTransactionToBalanceChildren({
      locationId,
      date,
      currentBalanceSortBy: 'DESC',
      parentIds: record.parentIds,
      skip: record.skip,
      count: 100
    });

    if (locationId === '') {
      response.results = await addLocationIfAbsent({
        data: response.results
      });
    }

    const totalBalance = {
      debitStart: 0,
      creditStart: 0,
      debitEnd: 0,
      creditEnd: 0,
      creditTransaction: 0,
      debitTransaction: 0
    };

    setTableData((prev) => {
      if (!record.parentIds) {
        savedData.tableData = prev;
        return prev;
      }

      if (record.parentIds.length === 1) {
        const prevItem = [...prev];

        // Insert new data to the correct position
        response.results.forEach((data) => {
          prevItem.splice(prev.length - 1, 0, { ...data, children: [] });
        });

        if (response.results.length < 100) {
          prevItem.pop();
        } else {
          prevItem[prevItem.length - 1].skip = prevItem.filter((val) => !val.hasMore).length;
        }

        // Calculate total balance
        response.results.forEach((data) => {
          if (data.transaction) {
            totalBalance.debitTransaction += data.transaction.totalDebit || 0;
            totalBalance.creditTransaction += data.transaction.totalCredit || 0;
          }

          if (data.startBalance) {
            totalBalance.debitStart += data.startBalance > 0 ? data.startBalance : 0;
            totalBalance.creditStart += data.startBalance < 0 ? Math.abs(data.startBalance) : 0;
          }

          if (data.endBalance) {
            totalBalance.debitEnd += data.endBalance > 0 ? data.endBalance : 0;
            totalBalance.creditEnd += data.endBalance < 0 ? Math.abs(data.endBalance) : 0;
          }
        });

        savedData.tableData = prevItem;
        return prevItem;
      } else {
        const newTableData = searchTableAndAdd(record.parentIds[0], response.results, prev);
        // Calculate total balance
        newTableData.forEach((data) => {
          if (data.transaction) {
            totalBalance.debitTransaction += data.transaction.totalDebit || 0;
            totalBalance.creditTransaction += data.transaction.totalCredit || 0;
          }

          if (data.startBalance) {
            totalBalance.debitStart += data.startBalance > 0 ? data.startBalance : 0;
            totalBalance.creditStart += data.startBalance < 0 ? Math.abs(data.startBalance) : 0;
          }

          if (data.endBalance) {
            totalBalance.debitEnd += data.endBalance > 0 ? data.endBalance : 0;
            totalBalance.creditEnd += data.endBalance < 0 ? Math.abs(data.endBalance) : 0;
          }
        });

        savedData.tableData = newTableData;
        return newTableData;
      }
    });

    setTotal(totalBalance);
    savedData.total = totalBalance;
    zustandFilter.updatePartialData(location.pathname, { ...savedData });
  }

  const searchTableAndAdd = (
    parentId: number,
    data: IBalanceSheetWithTransaction[],
    tableData: IBalanceSheetWithTransaction[]
  ): IBalanceSheetWithTransaction[] => {
    return tableData.map((item) => {
      if (item.id === parentId) {
        if (item.children) {
          data.forEach((x) => {
            item.children?.splice(item.children.length - 1, 0, {
              ...x,
              iconColor: item.children[0].iconColor,
              children: []
            });
          });
          if (data.length < 100) {
            item.children?.pop();
          } else {
            item.children[item.children?.length - 1].skip = item.children?.filter(
              (val) => !val.hasMore
            ).length;
          }
        }
        return { ...item };
      } else if (item.children) {
        const childItem = searchTableAndAdd(parentId, data, item.children);
        return { ...item, children: childItem };
      } else {
        return { ...item };
      }
    });
  };

  const filterColumns = () => {
    if (shownColumns.length === 3) {
      return columns;
    } else {
      return columns.filter((item) => {
        if (
          item.title &&
          (item.title.toString().includes('Debit') || item.title.toString().includes('Credit'))
        ) {
          if (shownColumns.includes('O') && item.title && item.title.toString().includes('Start')) {
            return true;
          }
          if (shownColumns.includes('T') && item.title && item.title.toString().includes('Trans')) {
            return true;
          }
          if (shownColumns.includes('C') && item.title && item.title.toString().includes('End')) {
            return true;
          }
        } else {
          return true;
        }
      });
    }
  };

  return (
    <div ref={tableRef}>
      <Table
        rowKey={'id'}
        dataSource={tableData}
        scroll={{ x: 1500, y: '75vh' }}
        rowClassName={'cursor-pointer'}
        columns={shownColumns.length > 0 ? filterColumns() : columns}
        expandable={{
          onExpand: async (expanded, record) => {
            const savedData = {} as IBalanceSheetSaveData;
            if (expanded) {
              if (locationId !== undefined) {
                setExpandedRows((prev) => {
                  const newRow = [...prev, record.id];
                  savedData.expandedRows = newRow;
                  return newRow;
                });
                const data = await getSubBalanceList(record, locationId);
                if (data) savedData.tableData = data.tableData;
              }
            } else {
              setExpandedRows((prev) => {
                const newRow = prev.filter((key) => key !== record.id);
                savedData.expandedRows = newRow;
                return newRow;
              });
            }

            zustandFilter.updatePartialData(location.pathname, { ...savedData });
          },
          expandedRowKeys: expandedRows
        }}
        onRow={(record) => {
          return {
            onClick: async () => {
              if (!record.hasMore) {
                const savedData = {} as IBalanceSheetSaveData;
                if (expandedRows.find((key) => key === record.id)) {
                  setExpandedRows((prev) => {
                    const newRow = prev.filter((key) => key !== record.id);
                    savedData.expandedRows = newRow;
                    return newRow;
                  });
                } else {
                  if (locationId !== undefined) {
                    setExpandedRows((prev) => {
                      const newRow = [...prev, record.id];
                      savedData.expandedRows = newRow;
                      return newRow;
                    });
                    const data = await getSubBalanceList(record, locationId);
                    if (data) savedData.tableData = data.tableData;
                  }
                }

                zustandFilter.updatePartialData(location.pathname, { ...savedData });
              }
            }
          };
        }}
        summary={() => (
          <Table.Summary fixed={true}>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
              <Table.Summary.Cell index={1}></Table.Summary.Cell>
              {shownColumns.includes('O') && (
                <>
                  <Table.Summary.Cell index={2} className="font-bold">
                    {nepaliNumberFormatter(total.debitStart)}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={3} className="font-bold">
                    {nepaliNumberFormatter(total.creditStart)}
                  </Table.Summary.Cell>
                </>
              )}
              {shownColumns.includes('T') && (
                <>
                  <Table.Summary.Cell index={4} className="font-bold">
                    {nepaliNumberFormatter(total.debitTransaction)}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={5} className="font-bold">
                    {nepaliNumberFormatter(total.creditTransaction)}
                  </Table.Summary.Cell>
                </>
              )}
              {shownColumns.includes('C') && (
                <>
                  <Table.Summary.Cell index={6} className="font-bold">
                    {nepaliNumberFormatter(total.debitEnd)}
                  </Table.Summary.Cell>

                  <Table.Summary.Cell index={7} className="font-bold">
                    {nepaliNumberFormatter(total.creditEnd)}
                  </Table.Summary.Cell>
                </>
              )}
            </Table.Summary.Row>
          </Table.Summary>
        )}
        pagination={false}
      />
    </div>
  );
}

export default TableColumn;
