import { DeleteOutlined } from '@ant-design/icons';
import { LoadScript, GoogleMap, MarkerF, Polyline } from '@react-google-maps/api';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Form, Input, Button, PageHeader, message, Checkbox, Select, Divider, Spin } from 'antd';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import AppContent from '../../../components/Common/Content/Content';
import { checkAccess } from '../../../routes/acl';
import { get_location_list } from '../../../services/locations/queries';

import { update_routes_mutation } from '../../../services/routes/mutations';
import { get_routes_details } from '../../../services/routes/queries';
import { IRoutes, IInitallocation, IUSerlocation } from '../../../services/routes/types';
import { get_user_routes_details } from '../../../services/users/queries';
import ReuseChannel from '../../channel/Reuse';
import { calcCrow } from '../utilities';
import { ILocations } from '../../../services/locations/types';
import CustomDeleteIcon from '../../../components/Common/CustomIcons/CustomDeleteIcon';
import { useFilterStore } from '@/store/zustand';
import { ListPage } from '@/constants/list.enum';

const { Option } = Select;
const containerStyle = {
  // width: '100%',
  height: '400px'
  // marginBottom: '20px'
};

interface LatLng {
  lat: number;
  lng: number;
}

const Update = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [form] = Form.useForm();
  const [marker, setMarkerPosition] = useState<LatLng[]>([]);
  const [path, setPathPosition] = useState<LatLng[]>([]);
  const [initialLocationsList, setInitialLocationsList] = useState<ILocations[]>([]);
  const [activeInitialPosition, setActiveInitialPosition] = useState<LatLng>();
  const [isLoading, setIsloading] = useState<boolean>(true);
  let activeIndex = 0;

  const zustandFilter = useFilterStore();

  const { data: routeDetails } = useQuery(['routes'], async () => {
    const response = await get_routes_details(parseInt(id as string));
    getUserLocations();
    const initialLocations = await get_location_list();
    if (response.path) {
      if (response.path.path) {
        setPathPosition(response.path.path);
        setActiveInitialPosition({
          lat: response.path.path[0].lat,
          lng: response.path.path[0].lng
        });
      }
    }

    setInitialLocationsList(initialLocations.data.results);
    // console.log('Initial locations-->', initialLocations.data.results);
    form.setFieldsValue(response);
    setIsloading(false);
    return response;
  });

  const getUserLocations = async () => {
    const userLocations = await get_user_routes_details(parseInt(id as string));
    const modifiedMarkers: LatLng[] = userLocations.map((value: IUSerlocation) => {
      return {
        lng: value.longitude,
        lat: value.latitude
      };
    });
    setMarkerPosition(modifiedMarkers);
    setIsloading(false);
  };

  const setInitialPath = (value: number) => {
    const index = initialLocationsList.findIndex((val) => val.id == value);
    path[0] = {
      lng: initialLocationsList[index].longitude,
      lat: initialLocationsList[index].latitude
    };
    setActiveInitialPosition({
      lng: initialLocationsList[index].longitude,
      lat: initialLocationsList[index].latitude
    });
    setPathPosition([...path]);
    setIsloading(false);
  };

  const options = {
    strokeColor: '#145da0',
    strokeOpacity: 0.8,
    strokeWeight: 4,
    clickable: false,
    icons: null
    // paths: [...path],
    // zIndex: 1
  };
  const breadcrumbItems = [
    {
      label: 'Routes',
      link: '/routes'
    },
    {
      label: 'Details',
      link: `/routes/${id}`
    }
  ];
  const updateRoutesMutation = useMutation(update_routes_mutation, {
    onSuccess: (data: any) => {
      message.success('Routes updated successfully');
      zustandFilter.removeData(ListPage.ROUTE);
      navigate('/routes');
    },
    onError: (e: any) => {
      message.error(`${e.response.data.message}`, 5);
    }
  });

  const removePath = (index: number) => {
    const newPath = [...path];
    if (index == 0) {
      message.error('Cannot remove initial point.');
    } else if (index == path.length - 1) {
      newPath.splice(index, 1);
      setPathPosition([...newPath]);
    } else {
      const distanceCalculation = calcCrow(path[index - 1], path[index + 1]);
      if (distanceCalculation > 500) {
        message.error(
          'The distance between the previous point and next point is more than 500 meters.'
        );
      } else {
        newPath.splice(index, 1);
        setPathPosition([...newPath]);
      }
    }
  };
  const onFinish = (values: IRoutes) => {
    setIsloading(true);
    values = { ...routeDetails, ...values };
    if (!values.addressIds) values.addressIds = [];
    values.path = JSON.stringify({ path });
    updateRoutesMutation.mutate(values);
    setIsloading(false);
  };

  const onClick = (e: google.maps.MapMouseEvent) => {
    if (e?.latLng) {
      const lat = e.latLng.lat();
      const lng = e.latLng.lng();
      if (!activeInitialPosition) {
        message.error('Please set the initial path');
        return;
      }
      if (path.length < 2) {
        setPathPosition([...path, { lat, lng }]);
        return;
      }
      const distanceCalculation = calcCrow(path[path.length - 1], { lat, lng });
      if (distanceCalculation > 500) {
        message.error(
          'The distance between the new point and previous point is more than 500 meters.'
        );
      } else {
        setPathPosition([...path, { lat, lng }]);
      }
    }
  };
  const onDragEnd = (e: google.maps.MapMouseEvent) => {
    if (e?.latLng) {
      const lat = e.latLng.lat();
      const lng = e.latLng.lng();
      const updatedPath = [...path];
      if (activeIndex == 0) {
        message.error('Cannot edit initial point.');
      }
      if (activeIndex > 0) {
        const distanceCalculation = calcCrow(path[activeIndex - 1], { lat, lng });
        if (distanceCalculation > 500) {
          message.error(
            'The distance between the new point and previous point is more than 500 meters.'
          );
        } else {
          updatedPath[activeIndex] = { lat, lng };
        }
      }
      setPathPosition([...updatedPath]);
    }
  };
  const onMouseDown = (e: google.maps.MapMouseEvent) => {
    if (e?.latLng) {
      const lat = e.latLng.lat();
      const lng = e.latLng.lng();
      activeIndex = path.findIndex((val) => val.lat == lat && val.lng == lng);
    }
  };
  const [responsize, setResponsize] = useState(false);
  useEffect(() => {
    if (window.innerWidth <= 650) {
      // setCollapsed(true);
      setResponsize(true);
    } else {
      setResponsize(false);
    }
    function handleResize() {
      if (window.innerWidth <= 650) {
        // setCollapsed(true);
        setResponsize(true);
      } else {
        setResponsize(false);
      }
    }
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  return (
    <Spin spinning={isLoading}>
      <AppContent breadcrumbItems={breadcrumbItems}>
        <Form
          form={form}
          initialValues={{ isActive: false }}
          onFinish={onFinish}
          layout="vertical"
          validateTrigger={'onChange'}
          autoComplete="off">
          <PageHeader
            title="Routes Information"
            style={{
              padding: '8px 0px'
            }}
          />

          <div className={responsize ? 'grid grid-cols-1' : 'grid grid-cols-3 gap-5 mb-5'}>
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: 'Please add name!' }]}>
              <Input />
            </Form.Item>
            <Form.Item label="Initial Location:">
              <Select onChange={(val) => setInitialPath(val)}>
                {initialLocationsList?.map((value: ILocations) => (
                  <Option key={value.id} value={value.id}>
                    {value.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Active?" name="isActive" valuePropName="checked">
              <Checkbox defaultChecked={true}></Checkbox>
            </Form.Item>
          </div>

          <div className={responsize ? 'grid grid-cols-1 gap-3' : 'grid grid-cols-2 '}>
            <div>
              <LoadScript googleMapsApiKey={'AIzaSyATJbgY-H04JRS-GO4GaKRHPPDHS0p2ENA'}>
                <GoogleMap
                  center={
                    path.length > 0
                      ? path[path.length - 1]
                      : {
                          lat: 27.7172,
                          lng: 85.324
                        }
                  }
                  zoom={13}
                  options={{
                    fullscreenControl: false,
                    streetViewControl: false,
                    mapTypeControl: false
                  }}
                  onClick={onClick}
                  mapContainerStyle={containerStyle}>
                  {activeInitialPosition && (
                    <MarkerF
                      // icon={{
                      //   path: 'M10.453 14.016l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406zM12 2.016q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z',
                      //   fillColor: 'green',
                      //   fillOpacity: 0.6,
                      //   strokeWeight: 0,
                      //   rotation: 0,
                      //   scale: 2,
                      //   anchor: new google.maps.Point(15, 30)
                      // }}
                      position={activeInitialPosition}
                    />
                  )}
                  {marker.map((value, index) => (
                    <MarkerF key={index} position={value} />
                  ))}
                  {path.length > 1 && (
                    <Polyline
                      visible={true}
                      editable={true}
                      path={path}
                      options={options}
                      onMouseDown={onMouseDown}
                      onMouseUp={onDragEnd}
                    />
                  )}
                </GoogleMap>
              </LoadScript>
            </div>
            <div
              className="card ml-5"
              style={{
                // width: 300
                height: 400,
                overflow: 'auto'
              }}>
              <PageHeader subTitle="List of Path:" />

              {activeInitialPosition &&
                path.map((value, index) => (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }}
                    key={index}>
                    <div>
                      {`${index + 1}. Latitude: ${Math.round(value.lat * 100) / 100}, Longitude: ${
                        Math.round(value.lng * 100) / 100
                      }`}
                    </div>
                    <div>
                      <CustomDeleteIcon onClick={() => removePath(index)} />
                    </div>
                  </div>
                  // <div key={index} className="mb-2">
                  //   <Button type="default" htmlType="button" onClick={() => removePath(index)}>
                  //     {Math.round(value.lat * 100) / 100}, {Math.round(value.lng * 100) / 100}
                  //   </Button>
                  // </div>
                ))}
            </div>
          </div>

          <div className="flex justify-end mt-5">
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
            <Button
              type="default"
              className="ml-5"
              htmlType="button"
              onClick={() => navigate('/routes')}>
              Cancel
            </Button>
          </div>
        </Form>
        <Divider />
        {checkAccess('READ_CHANNEL') && <ReuseChannel slug={`routes_${id}`} />}
      </AppContent>
    </Spin>
  );
};

export default Update;
