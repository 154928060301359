import { get_product_list_ids } from '@/services/products/queries';
import LocalStore from '.';
import { IProductDetails, IProductType } from '../../services/products/types';
import { checkProductLocationHidden, isProductHidden } from '@/services/products/services';

function getTransactionError(event: Event, reject: (reason?: any) => void, type: string) {
  const instanceOfIndexDB = event.target instanceof IDBRequest;
  if (instanceOfIndexDB) {
    reject(event.target.error);
    return;
  }

  reject(new Error(`Failed to ${type} agents on indexDB`));
}

export default class ProductsDB extends LocalStore {
  /*Products Functions*/

  /*search for products by name, sku, description, id with limit*/
  static async searchProduct(name: string, limit = 10) {
    if (!ProductsDB.db) await ProductsDB.init();
    const lowerCaseString = name.toLowerCase();
    return new Promise((resolve: (value: IProductDetails[]) => void, reject) => {
      const filteredProducts: IProductDetails[] = [];
      const transaction = ProductsDB.db.transaction('Products', 'readonly');

      const objectStore = transaction.objectStore('Products');
      const cursorRequest = objectStore.index('ProductsSecondIndex').openCursor();
      cursorRequest.onsuccess = (event: any) => {
        const cursor = event.target.result;
        if (cursor) {
          if (
            cursor.value.name?.toLowerCase().includes(lowerCaseString) ||
            cursor.value.sku?.toLowerCase().includes(lowerCaseString) ||
            cursor.value.description?.toLowerCase().includes(lowerCaseString)
          ) {
            filteredProducts.push(cursor.value);
          }
          if (filteredProducts.length <= limit) cursor.continue();
        }
      };
      cursorRequest.onerror = (event: any) => {
        reject(event.target.error);
      };
      transaction.oncomplete = (event: any) => {
        resolve(filteredProducts);
      };
      transaction.onerror = (event: any) => {
        reject(event.target.error);
      };
    });
  }

  static async searchActiveProduct(name = '', limit = 10, locationId?: number) {
    if (!ProductsDB.db) await ProductsDB.init();
    return new Promise((resolve: (value: IProductDetails[]) => void, reject) => {
      const transaction = ProductsDB.db.transaction('Products', 'readonly');
      const objectStore = transaction.objectStore('Products');

      const lowercaseString = name.toLowerCase();
      const cursorRequest = objectStore.index('ProductsSecondIndex').openCursor();

      const filteredProducts: IProductDetails[] = [];
      cursorRequest.onsuccess = (event: any) => {
        const cursor = event.target.result;
        if (cursor) {
          const product = cursor.value as IProductDetails;
          const validCondition =
            product.name?.toLowerCase().includes(lowercaseString) ||
            product.sku?.toLowerCase().includes(lowercaseString) ||
            product.description?.toLowerCase().includes(lowercaseString);

          if (validCondition && !product.hidden) {
            // If not hidden globally, check location-specific hidden status
            const isHidden = checkProductLocationHidden(product, locationId);
            if (!isHidden) filteredProducts.push(product);
          }

          if (filteredProducts.length <= limit) cursor.continue();
        }
      };
      cursorRequest.onerror = (event: any) => {
        reject(event.target.error);
      };
      transaction.oncomplete = (event: any) => {
        resolve(filteredProducts);
      };
      transaction.onerror = (event: any) => {
        reject(event.target.error);
      };
    });
  }

  static async searchProductByCategory(name = '', limit = 10, categoryId?: number | null) {
    if (!ProductsDB.db) await ProductsDB.init();
    return new Promise((resolve: (value: IProductDetails[]) => void, reject) => {
      const transaction = ProductsDB.db.transaction('Products', 'readonly');
      const objectStore = transaction.objectStore('Products');

      const lowercaseString = name.toLowerCase();
      const cursorRequest = objectStore.index('ProductsSecondIndex').openCursor();

      const filteredProducts: IProductDetails[] = [];
      cursorRequest.onsuccess = (event: any) => {
        const cursor = event.target.result;
        if (cursor) {
          const product = cursor.value as IProductDetails;
          const validCondition =
            product.name?.toLowerCase().includes(lowercaseString) ||
            product.sku?.toLowerCase().includes(lowercaseString) ||
            product.description?.toLowerCase().includes(lowercaseString);

          if (validCondition) {
            const categoryCondition = categoryId ? product.categoryId === categoryId : true;

            if (categoryCondition) filteredProducts.push(product);
          }

          if (filteredProducts.length <= limit) cursor.continue();
        }
      };
      cursorRequest.onerror = (event: any) => {
        reject(event.target.error);
      };
      transaction.oncomplete = (event: any) => {
        resolve(filteredProducts);
      };
      transaction.onerror = (event: any) => {
        reject(event.target.error);
      };
    });
  }

  static async searchActiveProductByCategory(
    name = '',
    limit = 10,
    categoryId?: number | null,
    locationId?: number
  ) {
    if (!ProductsDB.db) await ProductsDB.init();
    return new Promise((resolve: (value: IProductDetails[]) => void, reject) => {
      const transaction = ProductsDB.db.transaction('Products', 'readonly');
      const objectStore = transaction.objectStore('Products');

      const lowercaseString = name.toLowerCase();
      const cursorRequest = objectStore.index('ProductsSecondIndex').openCursor();

      const filteredProducts: IProductDetails[] = [];
      cursorRequest.onsuccess = (event: any) => {
        const cursor = event.target.result;
        if (cursor) {
          const product = cursor.value as IProductDetails;
          const validCondition =
            product.name?.toLowerCase().includes(lowercaseString) ||
            product.sku?.toLowerCase().includes(lowercaseString) ||
            product.description?.toLowerCase().includes(lowercaseString);

          const categoryCondition = categoryId ? product.categoryId === categoryId : true;

          if (validCondition && categoryCondition && !product.hidden) {
            const isHidden = checkProductLocationHidden(product, locationId);
            if (!isHidden) filteredProducts.push(product);
          }

          if (filteredProducts.length <= limit) cursor.continue();
        }
      };
      cursorRequest.onerror = (event: any) => {
        reject(event.target.error);
      };
      transaction.oncomplete = (event: any) => {
        resolve(filteredProducts);
      };
      transaction.onerror = (event: any) => {
        reject(event.target.error);
      };
    });
  }

  static async searchProductByLocationHidden(
    name: string,
    limit = 10,
    location?: number,
    showHidden?: boolean
  ) {
    if (!ProductsDB.db) await ProductsDB.init();
    const lowerCaseString = name.toLowerCase();

    return new Promise((resolve: (value: any[]) => void, reject) => {
      const filteredProducts: any[] = [];
      const transaction = ProductsDB.db.transaction('Products', 'readonly');
      const objectStore = transaction.objectStore('Products');
      const cursorRequest = objectStore.index('ProductsSecondIndex').openCursor();

      cursorRequest.onsuccess = (event: any) => {
        const cursor = event.target.result;
        if (cursor) {
          const product = cursor.value as IProductType;
          const matchCondition =
            product?.name?.toLowerCase().includes(lowerCaseString) ||
            product?.sku?.toLowerCase().includes(lowerCaseString) ||
            product?.description?.toLowerCase().includes(lowerCaseString);

          const isHidden = location
            ? checkProductLocationHidden(product, location)
            : product?.hidden;

          // Check if hidden status matches the requested `hidden` parameter
          if (matchCondition && isHidden === showHidden) {
            filteredProducts.push(product);
          }

          if (filteredProducts.length <= limit) cursor.continue();
        }
      };
      cursorRequest.onerror = (event: any) => {
        reject(event.target.error);
      };
      transaction.oncomplete = (event: any) => {
        resolve(filteredProducts);
      };
      transaction.onerror = (event: any) => {
        reject(event.target.error);
      };
    });
  }

  static async getAllProducts() {
    if (!ProductsDB.db) await ProductsDB.init();

    return new Promise((resolve, reject) => {
      const transaction = ProductsDB.db.transaction('Products', 'readonly');
      transaction.oncomplete = (event: any) => {
        resolve('success');
      };
      transaction.onerror = (event: any) => {
        reject(event.target.error);
      };
      const objectStore = transaction.objectStore('Products');
      const request = objectStore.getAll();
      request.onsuccess = (event: any) => {
        resolve(event.target.result);
      };
      request.onerror = (event: any) => {
        reject(event.target.error);
      };
    });
  }
  static async removeAllProducts() {
    if (!ProductsDB.db) await ProductsDB.init();

    return new Promise((resolve, reject) => {
      const transaction = ProductsDB.db.transaction(['Products'], 'readwrite');
      transaction.oncomplete = (event) => {
        resolve('success');
      };
      transaction.onerror = (event: any) => {
        reject(event.target.error);
      };
      const objectStore = transaction.objectStore('Products');
      const objectStoreRequest = objectStore.clear();
      objectStoreRequest.onsuccess = (event: any) => {
        resolve(event.target.result);
      };
      objectStoreRequest.onerror = (event: any) => {
        reject(event.target.result);
      };
    });
  }
  static async addProducts(productsData: any[]) {
    if (!ProductsDB.db) await ProductsDB.init();

    return new Promise((resolve, reject) => {
      const transaction = ProductsDB.db.transaction('Products', 'readwrite');
      transaction.oncomplete = (event: any) => {
        resolve('success');
      };
      transaction.onerror = (event: any) => {
        reject(event.target.error);
      };
      const objectStore = transaction.objectStore('Products');
      productsData.forEach(async (product) => {
        const checkKey = objectStore.count(product.id);
        checkKey.onsuccess = async (event: any) => {
          if (checkKey.result == 0) {
            const request = objectStore.add(product);
            request.onerror = (event: any) => {
              reject(event.target.error);
            };
          } else {
            await ProductsDB.updateProduct(product);
          }
        };
      });
    });
  }
  static async getProduct(id: number | string) {
    if (!ProductsDB.db) await ProductsDB.init();

    return new Promise<IProductType | string>((resolve, reject) => {
      const transaction = ProductsDB.db.transaction('Products', 'readonly');
      transaction.oncomplete = (event: any) => {
        //  resolve('success');
      };
      transaction.onerror = (event: any) => {
        reject(event.target.error);
      };
      const objectStore = transaction.objectStore('Products');
      const request = objectStore.get(id);

      request.onsuccess = (event: any) => {
        resolve(event.target.result);
      };
      request.onerror = (event: any) => {
        reject(event.target.error);
      };
    });
  }
  static async getProductDetails(id: number | string) {
    if (!ProductsDB.db) await ProductsDB.init();

    return new Promise<IProductDetails>((resolve, reject) => {
      const transaction = ProductsDB.db.transaction('Products', 'readonly');
      transaction.oncomplete = (event: any) => {
        //  resolve('success');
      };
      transaction.onerror = (event: any) => {
        reject(event.target.error);
      };
      const objectStore = transaction.objectStore('Products');
      const request = objectStore.get(id);
      request.onsuccess = (event: any) => {
        resolve(event.target.result);
      };
      request.onerror = (event: any) => {
        reject(event.target.error);
      };
    });
  }
  static async updateProduct(product: any) {
    if (!ProductsDB.db) await ProductsDB.init();

    return new Promise((resolve, reject) => {
      const transaction = ProductsDB.db.transaction('Products', 'readwrite');
      transaction.oncomplete = (event: any) => {
        resolve('success');
      };
      transaction.onerror = (event: any) => {
        reject(event.target.error);
      };
      const objectStore = transaction.objectStore('Products');
      const request = objectStore.get(product.id);
      request.onsuccess = (event: any) => {
        if (event.target.result) {
          product.lowercaseName = product.name.toLowerCase();
          const requestUpdate = objectStore.put(product);
          requestUpdate.onerror = (event: any) => {
            reject(event.target.error);
          };
          requestUpdate.onsuccess = (event) => {
            resolve('updated data');
          };
        } else reject('Error, could not find id.');
      };
      request.onerror = (event: any) => {
        reject(event.target.error);
      };
    });
  }

  static async deleteProduct(id: number) {
    if (!ProductsDB.db) await ProductsDB.init();
    if (await ProductsDB.getProduct(id)) {
      return new Promise((resolve, reject) => {
        const transaction = ProductsDB.db.transaction('Products', 'readwrite');
        transaction.oncomplete = (event: any) => {
          resolve('success');
        };
        transaction.onerror = (event: any) => {
          reject(event.target.error);
        };
        const objectStore = transaction.objectStore('Products');
        const request = objectStore.delete(id);
        request.onsuccess = (event: any) => {
          resolve('deleted successfuly.');
          // if (event.target.result) resolve(event.target.result);
          // reject('Error, could not find id.');
        };
        request.onerror = (event: any) => {
          reject(event.target.error);
        };
      });
    } else {
      return new Promise((resolve, reject) => {
        resolve('id does not exist');
      });
    }
  }

  static async addProductsIfAbsent(productIds: number[]): Promise<void> {
    if (!ProductsDB.db) await ProductsDB.init();
    return new Promise((resolve, reject) => {
      const transaction = ProductsDB.db.transaction('Products', 'readwrite');
      transaction.onerror = (event) => getTransactionError(event, reject, 'search');

      const objectStore = transaction.objectStore('Products');
      const request = objectStore.getAll();

      request.onsuccess = async (event) => {
        const storedProducts = (event.target as IDBRequest).result as IProductType[];
        const storedProductsIds = storedProducts.map((product) => Number(product.id));
        const absentProductIds = productIds.filter(
          (id) => Boolean(id) && !storedProductsIds.includes(id)
        );

        if (absentProductIds.length) {
          const response = await get_product_list_ids(absentProductIds);
          const fetchedProducts = response.data.results;
          await ProductsDB.addProducts(fetchedProducts);
          resolve(undefined);
        } else {
          resolve(undefined);
        }
      };
    });
  }

  static async getProductBySKU(sku: string[]) {
    if (!ProductsDB.db) await ProductsDB.init();
    return new Promise<{ present: IProductDetails[]; absentSkus: string[] }>((resolve, reject) => {
      const transaction = ProductsDB.db.transaction('Products', 'readonly');
      transaction.onerror = (event) => getTransactionError(event, reject, 'getProductBySKU');

      const objectStore = transaction.objectStore('Products');
      const request = objectStore.index('ProductsSecondIndex').openCursor();

      const products: IProductDetails[] = [];

      request.onsuccess = (event) => {
        const cursor = (event.target as IDBRequest).result;
        if (cursor) {
          const product = cursor?.value as IProductDetails;
          if (sku.includes(product?.sku)) {
            products.push(product);
          }
          cursor.continue();
        } else {
          const absentSkus = sku.filter((sku) => !products.find((product) => product.sku === sku));
          resolve({ present: products, absentSkus });
        }
      };
    });
  }
}
