import ReactDataGrid, { InputNumber, ReadOnlyInput } from '@/components/Common/DataGrid';
import PreviewImageOnDemand from '@/components/Common/PreviewImageOnDemand';
import { ListPage } from '@/constants/list.enum';
import { SocketEvents, SystemNotificationType } from '@/constants/websocketConfig';
import { WebSocketContext } from '@/contexts/websocket.context';
import useCache from '@/hooks/useCache';
import { getImageFromServer } from '@/services/upload/queries';
import { IWooCommerceSocketData } from '@/services/web-sockets/types';
import { create_woo_products } from '@/services/woocommerce/mutation';
import { IProductRow } from '@/services/woocommerce/types';
import getErrorMessage from '@/utils/getError';
import { useMutation } from '@tanstack/react-query';
import { Button, message, Modal } from 'antd';
import { useContext, useEffect, useRef, useState } from 'react';
import { Column, DataGridHandle, RenderCellProps } from 'react-data-grid';
import { useNavigate } from 'react-router-dom';
import ProductSyncModal from '../../ProductSyncModal';
import { IProductDetails } from '@/services/products/types';

interface Props {
  rows: IProductRow[];
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setRows: React.Dispatch<React.SetStateAction<IProductRow[]>>;
}

function Grid({ rows, setIsLoading, setRows }: Props) {
  const navigate = useNavigate();

  const { get: getCacheMedias } = useCache(getImageFromServer);

  const [openSubmitModal, setOpenSubmitModal] = useState(false);
  const gridRef = useRef<DataGridHandle>(null);
  const { socket } = useContext(WebSocketContext);

  const updateWooProducts = useMutation(create_woo_products);
  const [wooData, setWooData] = useState<IWooCommerceSocketData | null>(null);
  const [modalVisible, setModalVisible] = useState(false);

  function getTotalItems() {
    const lastIndex = rows.length - 1;
    const lastRow = lastIndex >= 0 ? rows[lastIndex] : ({} as IProductRow);
    const isRowFilled = Boolean(lastRow.id && lastRow.name);

    // If last row is not filled, exclude it
    const updatedRows = isRowFilled ? rows : rows.slice(0, -1);
    return updatedRows.length;
  }

  const handleModalClose = () => {
    setModalVisible(false);
    if (!wooData) return;

    const totalItems = getTotalItems();
    const allProcessed =
      wooData?.messages.success.length + wooData?.messages.error.length === totalItems;

    const noFailures = wooData?.messages.error.length === 0;

    if (allProcessed && noFailures) {
      navigate(ListPage.WOO_PRODUCT_HISTORY);
    }
  };

  useEffect(() => {
    const handler = (data: { event: string; data: unknown }) => {
      if (data.event === SystemNotificationType.WOO_PRODUCTS) {
        setIsLoading(true);
        const response = data.data as IWooCommerceSocketData;
        setWooData(response);
        setModalVisible(true); // open modal when update starts
      }
    };

    socket?.on(SocketEvents.USER_NOTIFICATION, handler);
    return () => {
      socket?.off(SocketEvents.USER_NOTIFICATION, handler);
    };
  }, [socket?.connected]);

  const columnsWithActions: Column<IProductRow>[] = [
    {
      key: 'sn',
      name: '',
      frozen: true,
      width: 45,
      minWidth: 45,
      renderCell(props) {
        return <strong>{props.rowIdx + 1}</strong>;
      }
    },
    {
      key: 'name',
      name: 'Product',
      cellClass: 'p-0',
      minWidth: 200,
      headerCellClass: 'text-wrap-header',
      renderCell: (props) => renderInputs(props, 'readonly')
    },
    {
      key: 'unitName',
      name: 'Unit',
      cellClass: 'p-0',
      minWidth: 100,
      headerCellClass: 'text-wrap-header',
      renderCell: (props) => renderInputs(props, 'readonly')
    },
    {
      key: 'categoryName',
      name: 'Category',
      minWidth: 100,
      headerCellClass: 'text-wrap-header',
      cellClass: 'p-0',
      renderCell: (props) => renderInputs(props, 'readonly')
    },
    {
      key: 'sku',
      name: 'SKU',
      minWidth: 75,
      headerCellClass: 'text-wrap-header',
      cellClass: 'p-0',
      renderCell: (props) => renderInputs(props, 'readonly')
    },
    {
      key: 'imgId',
      name: 'Media',
      width: 45,
      headerCellClass: 'text-wrap-header',
      cellClass: 'p-0',
      renderCell: ({ row }) => {
        return (
          <PreviewImageOnDemand
            setParentLoading={setIsLoading}
            mediaId={row.imgId}
            fetcher={getCacheMedias}
          />
        );
      }
    },
    {
      key: 'limit_qty',
      name: 'Limit Qty',
      minWidth: 75,
      cellClass: 'p-0',
      headerCellClass: 'text-wrap-header',
      editable: true,
      renderCell: (props) => renderInputs(props, 'number')
    },
    {
      key: 'sell_price',
      name: 'Today Selling Price',
      minWidth: 100,
      cellClass: 'p-0',
      headerCellClass: 'text-wrap-header',
      editable: true,
      renderCell: (props) => renderInputs(props, 'number')
    }
  ];

  function renderInputs(props: RenderCellProps<IProductRow>, type: 'number' | 'readonly') {
    const columnKey = props.column.key as keyof IProductRow;
    const value = props.row[columnKey];

    switch (type) {
      case 'number':
        return (
          <InputNumber
            props={props}
            gridRef={gridRef}
            keyboard
            columns={columnsWithActions}
            onChange={(value) => props.onRowChange({ ...props.row, [columnKey]: value || 0 })}
          />
        );
      case 'readonly':
        return <ReadOnlyInput value={value} />;
    }
  }

  async function onFinish() {
    try {
      setIsLoading(true);
      if (rows.length === 0) {
        setIsLoading(false);
        return message.error('Please add product to woocommerce to proceed further');
      }

      const payload = rows.map((row) => ({
        productId: row.id as number,
        unitId: row.unitId,
        orderQtyLimit: row.limit_qty,
        sellPrice: row.sell_price
      }));

      await updateWooProducts.mutateAsync({ history: payload });
      message.success(`Processing started for ${payload.length} product(s)`);
    } catch (error) {
      setIsLoading(false);
      getErrorMessage(error, true);
    }
  }

  async function onRetry(failedProducts: IProductDetails[]) {
    try {
      setIsLoading(true);
      if (!failedProducts.length) {
        message.error('No failed products found.');
        return;
      }

      // Only get rows of failed products
      const failedProductIds = failedProducts.map((a) => Number(a.id)).filter(Boolean);
      const failedRows = rows.filter((row) => failedProductIds.includes(row.id as number));
      const payload = failedRows.map((row) => ({
        productId: row.id as number,
        unitId: row.unitId,
        orderQtyLimit: row.limit_qty,
        sellPrice: row.sell_price
      }));

      setWooData(null);

      await updateWooProducts.mutateAsync({ history: payload });
      message.success(`Processing started for ${payload.length} product(s)`);
    } catch (error) {
      getErrorMessage(error, true);
    } finally {
      setOpenSubmitModal(false);
    }
  }

  return (
    <div>
      <ProductSyncModal
        socketData={wooData}
        visible={modalVisible}
        getTotalItems={getTotalItems}
        onClose={handleModalClose}
        setLoading={setIsLoading}
        onRetry={onRetry}
        showTotalItems
      />

      <Modal visible={openSubmitModal} onCancel={() => setOpenSubmitModal(false)} onOk={onFinish}>
        Do you want to update this?
      </Modal>

      <div className="mt-2 space-y-2">
        <ReactDataGrid
          ref={gridRef}
          rows={rows}
          columns={columnsWithActions}
          onRowsChange={setRows}
          emptyRowFallback="No products have been added yet."
        />

        <div className="mt-2.5 flex justify-end gap-2.5">
          <Button type="primary" onClick={onFinish} disabled={!rows.length}>
            Update Products
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Grid;
