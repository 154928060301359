import { Form, Spin } from 'antd';
import { useState } from 'react';
import AppContent from '../../components/Common/Content/Content';
import TableFilter from '../../components/FliterTable';

import { get_product_list_ids } from '../../services/products/queries';
import {
  get_purchase_order_form_filter_v1,
  get_sell_order_form_filter_v1
} from '../../services/purchases/queries';
import ProductsDB from '../../store/localstorage/ProductsDB';
import { getUserData } from '../../utils/auth.utils';
import FullScreenModal from '../../components/Common/FullScreenModal';
import moment from 'moment';
import LocationSearchV2 from '@/components/Common/CustomSearch/Location';
import useWindowScrollRestoration from '@/hooks/useWindowScrollRestoration';

const EstimateVSDemandView = () => {
  const [form] = Form.useForm();
  const [data, setData] = useState<any[]>([]);

  const [isloading, setIsloading] = useState<boolean>(true);
  const { preferences } = getUserData();
  const preferenceLocationId = preferences?.preferences
    ? JSON.parse(preferences?.preferences)?.locationId
    : undefined;

  const onSearch = async (val: string) => {
    setIsloading(true);

    const searchParams = new URLSearchParams(val);
    const date = searchParams.get('date');

    searchParams.delete('date');
    searchParams.set('startDate', moment(date).utc().format());
    searchParams.set('endDate', moment(date).utc().add(1, 'days').format());
    searchParams.delete('skip');
    searchParams.delete('count');

    const updatedFilter = searchParams.toString();
    const response: any = await get_sell_order_form_filter_v1(updatedFilter);
    const response2: any = await get_purchase_order_form_filter_v1(updatedFilter);

    const searchProducts: any = {};
    for (let index = 0; index < response.data.data.length; index++) {
      const product: any = await ProductsDB.getProduct(response.data.data[index].productId);
      if (!product) {
        if (response.data.data[index].productId in searchProducts) {
          searchProducts[response.data.data[index].productId] = [
            ...searchProducts[response.data.data[index].productId],
            index
          ];
        } else {
          searchProducts[response.data.data[index].productId] = [index];
        }
      } else {
        response.data.data[index].productName = product.name;
      }
      // console.log('response2.data.data', response2.data.data);
      if (response2.data.data.length > 0) {
        const find = response2.data.data.find(
          (val: any) => val.productId === response.data.data[index].productId
        );
        if (find) {
          response.data.data[index].purchaseDemand = find.qty;
          response2.data.data = response2.data.data.filter(
            (val: any) => val.productId != response.data.data[index].productId
          );
        } else {
          response.data.data[index].purchaseDemand = 0;
        }
      } else {
        response.data.data[index].purchaseDemand = 0;
      }
    }
    if (response2.data.data.length > 0) {
      for (let index = 0; index < response2.data.data.length; index++) {
        const product: any = await ProductsDB.getProduct(response2.data.data[index].productId);
        if (!product) {
          if (response2.data.data[index].productId in searchProducts) {
            searchProducts[response2.data.data[index].productId] = [
              ...searchProducts[response2.data.data[index].productId],
              response.length
            ];
          } else {
            searchProducts[response2.data.data[index].productId] = [response.length];
          }
        } else {
          response2.data.data[index].productName = product.name;
        }
        response.data.data.push({
          ...response2.data.data[index],
          purchaseDemand: response2.data.data[index].qty,
          qty: 0
        });
      }
    }
    const searchProductslength = Object.entries(searchProducts).length;
    if (searchProductslength > 0) {
      const productsresponse = await get_product_list_ids([...Object.keys(searchProducts)]);
      for (const key in searchProducts) {
        const findproduct = productsresponse?.data?.results.find(
          (currProduct: any) => currProduct.id == key
        );
        for (let i = 0; i < searchProducts[key].length; i++) {
          response.data.data[searchProducts[key][i]].productName = findproduct?.name;
        }
        await ProductsDB.addProducts([findproduct]);
      }
    }
    setIsloading(false);
    setData(response.data.data);
    return response.data.data;
  };

  useWindowScrollRestoration({ totalLength: data.length });

  const breadcrumbItems = [
    {
      label: 'Purchase Demand Vs Estimated Stock'
    }
  ];

  return (
    <Spin spinning={isloading}>
      <AppContent
        breadcrumbItems={breadcrumbItems}
        withfilter={true}
        button={
          <>
            <div>
              <TableFilter
                onInitialLoad={({ data }) => {
                  if (data) {
                    setData(data);
                    setIsloading(false);
                  }
                }}
                defaultValues={{
                  locationId: preferenceLocationId ?? 1,
                  date: moment().format('YYYY-MM-DD')
                }}
                dateCustom={false}
                singleDate={true}
                initial={true}
                onSubmit={onSearch}
                timeVal={true}
                form={form}
                style={
                  'grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3  xl:grid-cols-4 gap-2 items-center justify-center'
                }
                outsidestyle={'flex flex-wrap justify-end items-end gap-2'}>
                <LocationSearchV2 hasParentFormItem={false} name="locationId" />
              </TableFilter>
            </div>
          </>
        }>
        <div className="flex justify-end">
          <FullScreenModal>
            <div className="grid grid-cols-2 sm:grid-cols-4 md:grid-cols-6 xl:grid-cols-8 gap-2 mt-2">
              {data &&
                data.map((record: any, ind: number) => {
                  let percentSold = '';
                  let percentOrdered = '';
                  let overflow = false;
                  if (record.purchaseDemand >= record.qty) {
                    if (record.purchaseDemand === 0) percentOrdered = '0%';
                    else percentOrdered = '100%';
                    percentSold = `${(record.qty / record.purchaseDemand) * 100}%`;
                    if (isNaN(record.qty / record.purchaseDemand)) percentSold = '0%';
                  } else {
                    overflow = true;
                    if (record.qty === 0) percentSold = '0%';
                    else percentSold = '100%';
                    percentOrdered = `${(record.purchaseDemand / record.qty) * 100}%`;
                    if (isNaN(record.qty / record.purchaseDemand)) percentOrdered = '0%';
                  }
                  return (
                    <div className="cardforestimatestock" key={ind}>
                      {/* <div>{record.id}</div> */}
                      <div className="font-bold">{record.productName}</div>
                      <div color="black" style={{ width: '100%' }}>
                        <div>Demand ({record.purchaseDemand})</div>
                        <div
                          style={{
                            height: '3px',
                            backgroundColor: 'blue',
                            width: percentOrdered,
                            borderRadius: '4px'
                          }}></div>
                        <div>
                          Stock ({record.qty}){overflow ? ' Overflow' : ''}
                        </div>
                        <div
                          style={{
                            height: '3px',
                            backgroundColor: overflow ? 'red' : 'green',
                            width: percentSold,
                            borderRadius: '4px'
                          }}></div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </FullScreenModal>
        </div>
        <div className="grid grid-cols-2 sm:grid-cols-4 md:grid-cols-6 xl:grid-cols-8 gap-2 mt-2">
          {data &&
            data.map((record: any, ind: number) => {
              let percentSold = '';
              let percentOrdered = '';
              let overflow = false;
              if (record.purchaseDemand >= record.qty) {
                if (record.purchaseDemand === 0) percentOrdered = '0%';
                else percentOrdered = '100%';
                percentSold = `${(record.qty / record.purchaseDemand) * 100}%`;
                if (isNaN(record.qty / record.purchaseDemand)) percentSold = '0%';
              } else {
                overflow = true;
                if (record.qty === 0) percentSold = '0%';
                else percentSold = '100%';
                percentOrdered = `${(record.purchaseDemand / record.qty) * 100}%`;
                if (isNaN(record.qty / record.purchaseDemand)) percentOrdered = '0%';
              }
              return (
                <div className="cardforestimatestock" key={ind}>
                  {/* <div>{record.id}</div> */}
                  <div className="font-bold">{record.productName}</div>
                  <div color="black" style={{ width: '100%' }}>
                    <div>Demand ({record.purchaseDemand})</div>
                    <div
                      style={{
                        height: '3px',
                        backgroundColor: 'blue',
                        width: percentOrdered,
                        borderRadius: '4px'
                      }}></div>
                    <div>
                      Stock ({record.qty}){overflow ? ' Overflow' : ''}
                    </div>
                    <div
                      style={{
                        height: '3px',
                        backgroundColor: overflow ? 'red' : 'green',
                        width: percentSold,
                        borderRadius: '4px'
                      }}></div>
                  </div>
                </div>
              );
            })}
        </div>
      </AppContent>
    </Spin>
  );
};

export default EstimateVSDemandView;
