import { AxiosResponse } from 'axios';
import http from '../../utils/http.utils';
import { IAuthResponse, ILoginRequest } from './types';

export const login_query = async (values: ILoginRequest) => {
  const response: AxiosResponse<IAuthResponse> = await http.store('auth/login', values);
  return response.data;
};

export const refresh_token_query = async (refreshToken: string) => {
  const response: AxiosResponse<{ accessToken: string }> = await http.store('auth/refresh', {
    refreshToken
  });
  return response.data;
};
