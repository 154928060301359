import moment from 'moment';
import { Menu, message, Tag } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { ColumnsType } from 'antd/lib/table';
import { EditFilled } from '@ant-design/icons';

import { get_financial_years } from '@/services/financial-year/queries';
import { IFinancialYear } from '@/services/financial-year/types';

import AppContent from '@/components/Common/Content/Content';
import CustomizeTable from '@/components/Common/CustomizeTable/CustomizeTable';
import CustomButton from '@/components/Common/CustomButton/CustomButton';
import { getUserData } from '@/utils/auth.utils';
import ActionDropdown from '@/components/Common/Dropdownactions';
import { FinancialYearDB } from '@/store/localstorage/FinancialYearDB';
import { DEFAULT_DATE_FORMAT } from '@/constants';
import { useEffect, useState } from 'react';
import { useFilterStore, useHydration } from '@/store/zustand';
import getErrorMessage from '@/utils/getError';
import { useFinancialYearStore } from '@/store/zustand/financial-year';

function FinancialYearList() {
  const userData = getUserData();
  const isSuperUser = userData.perms.includes('SUPER_ADMIN');
  const [data, setData] = useState<IFinancialYear[]>([]);

  const [isLoading, setIsLoading] = useState(true);
  const zustandFilter = useFilterStore();
  const zustandFinancialYear = useFinancialYearStore();
  const location = useLocation();
  const isHydrating = useHydration();

  useEffect(() => {
    (async function () {
      try {
        setIsLoading(true);
        if (!isHydrating) return;

        const currentState = zustandFilter.getState(location.pathname);
        if (currentState && currentState.data) {
          setData(currentState.data as IFinancialYear[]);
          setIsLoading(false);
          return;
        }

        const data = await get_financial_years();
        setData(data);
        FinancialYearDB.add(data);
        zustandFilter.updateState(location.pathname, { data });
        zustandFinancialYear.refreshFinancialYears(data);
      } catch (error) {
        message.error(getErrorMessage(error));
      } finally {
        setIsLoading(false);
      }
    })();
  }, [isHydrating]);

  const columns: ColumnsType<IFinancialYear> = [
    {
      title: 'S.N.',
      key: 'id',
      width: 5,
      render: (_, __, index) => <span>{index + 1}</span>
    },
    { title: 'Name', dataIndex: 'name', key: 'name', width: 15 },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      key: 'startDate',
      width: 20,
      render: (text) => moment(text).format(DEFAULT_DATE_FORMAT)
    },
    {
      title: 'End Date',
      dataIndex: 'endDate',
      key: 'endDate',
      width: 20,
      render: (text) => moment(text).format(DEFAULT_DATE_FORMAT)
    },

    {
      title: 'Created At',
      width: 20,
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text) => moment(text).format(DEFAULT_DATE_FORMAT)
    },
    {
      title: 'Status',
      width: 7,
      dataIndex: 'isClosed',
      key: 'paymentStatus',
      render: (isClosed: boolean) => {
        return <Tag color={isClosed ? 'red' : 'green'}>{isClosed ? 'Closed' : 'Open'}</Tag>;
      }
    }
  ];

  if (isSuperUser) {
    columns.push({
      title: 'Actions',
      key: 'actions',
      width: 6,
      fixed: 'right',
      render: (_, record) => {
        const menuItems = [
          {
            key: 'menu-view',
            label: (
              <Link to={`/accounts/financial-year/${record.id}`} className="flex justify-center">
                <EditFilled
                  style={{
                    transform: 'scale(1.1)'
                  }}
                />
              </Link>
            )
          }
        ];

        return <ActionDropdown menu={<Menu items={menuItems} />} />;
      }
    });
  }

  return (
    <AppContent
      breadcrumbItems={[
        { label: 'Accounts', link: '/accounts' },
        { label: 'FinancialYear', link: '/accounts/financial-year' }
      ]}
      button={
        <>
          {isSuperUser && (
            <CustomButton
              text="Add"
              backgroundColor="#1890ff"
              Linkto="/accounts/financial-year/new"
            />
          )}
        </>
      }>
      <CustomizeTable
        allowScrollPersist
        columns={columns}
        data={data || []}
        usersLoading={isLoading}
        notshowPagination={true}
        customScroll={{ x: 1000, y: '75vh' }}
        tableName={'financial-list'}
      />
    </AppContent>
  );
}

export default FinancialYearList;
