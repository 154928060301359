import { Form, Input, Menu, Select, Spin, TableProps, Tooltip } from 'antd';
import { ColumnsType, SorterResult } from 'antd/lib/table/interface';
import { useState } from 'react';
import { ConvertObjectToURL } from '@/utils/converturl';
import TableCell from '@/components/Common/CustomizeTable/CustomCell';
import TableFilter from '@/components/FliterTable';
import AppContent from '@/components/Common/Content/Content';
import { get_session_list_filter } from '@/services/pos-session/queries';
import { IPosSession, IPosSessionResponse } from '@/services/pos-session/types';
import UsersDB from '@/store/localstorage/UsersDB';
import { get_user_list_ids } from '@/services/users/queries';
import moment from 'moment';
import { convertLocalToUTCString, convertUTCStringtoLocalString } from '@/utils/convertToUTC';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';
import { checkAccess } from '@/routes/acl';
import { PrinterOutlined } from '@ant-design/icons';
import ActionDropdown from '@/components/Common/Dropdownactions';
import { getCashSettlementPrintData } from '@/components/Common/InvoicePrint/CashSettlementPrint/services';
import { CustomModal } from '@/components/Common/CustomModal';
import CashSettlementPrint from '@/components/Common/InvoicePrint/CashSettlementPrint/CashSettlementPrint';
import { ICashSettlementPrint } from '@/services/invoice/types';
import { get_report } from '@/services/report/queries';
import { DEFAULT_DATE_FORMAT } from '@/constants';
import GenericTable from '@/components/Common/CustomizeTable';
import UserSearchV2 from '@/components/Common/CustomSearch/Users';

const SessionList = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [form] = Form.useForm();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(100);
  const [isPosCashSettlementModalOpen, setIsPosCashSettlementModalOpen] = useState<boolean>(false);
  const [cashSettlementPrintData, setCashSettlementPrintData] =
    useState<ICashSettlementPrint>(Object);
  const [sessionList, setSessionList] = useState<IPosSession>({ results: [], total: 0 });
  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({});
  const handleChange: TableProps<any>['onChange'] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<any>);
  };

  const breadcrumbItems = [{ label: 'Session', link: '/session' }];

  const onSubmitFilter = async (val: string) => {
    const data = await getInfo(val);
    setPage(1);
    setSize(100);
    return data;
  };

  const getInfo = async (filter = '') => {
    setIsLoading(true);
    const response = await get_session_list_filter(filter);
    for (let ind = 0; ind < response.results.length; ind++) {
      let userDetails = await UsersDB.getUser(response.results[ind].userId);
      if (!userDetails) {
        const allUsers = await get_user_list_ids([
          ...new Set(
            response.results.map((val) => {
              return val.userId;
            })
          )
        ]);
        await UsersDB.addUsers(allUsers.data.results);
        userDetails = await UsersDB.getUser(response.results[ind].userId);
      }

      response.results[ind].userName = userDetails.name;
    }

    setSessionList({ results: response.results, total: response.total });
    setIsLoading(false);
    return response;
  };

  const onPagination = async (pageNo = 1, totalSize = 100, isSize = false) => {
    setIsLoading(true);
    const values = form.getFieldsValue();
    values.endDate = convertLocalToUTCString(values.endDate);
    values.startDate = convertLocalToUTCString(values.startDate);
    delete values.dateCustom;
    delete values.startDateNepali;
    delete values.endDateNepali;
    if (isSize) {
      values.skip = 0;
      values.count = totalSize;
      setPage(1);
      setSize(totalSize);
    } else {
      values.skip = (pageNo - 1) * totalSize;
      values.count = totalSize;
      setPage(pageNo);
    }
    const url = ConvertObjectToURL(values);
    return await getInfo(url);
  };

  const handlePosCashSettlementPrint = async (
    sessionId: number,
    userId: number,
    startTime: string,
    endTime: string
  ) => {
    // Fetch Pos Closing Report
    const values = {
      startDate: convertLocalToUTCString(startTime),
      endDate: convertLocalToUTCString(endTime),
      // paymentType: 'cash',
      userId: userId,
      page: 1,
      size: -1
    };
    const report = await get_report(values, 49);
    const printData = await getCashSettlementPrintData(sessionId, report.data);
    setCashSettlementPrintData(printData);
    setIsPosCashSettlementModalOpen(true);
  };

  const columns: ColumnsType<IPosSessionResponse> = [
    {
      title: 'S.N',
      key: 'SN',
      width: 5,
      render: (txt, record, index) => {
        return <TableCell>{(page - 1) * size + (index + 1)}</TableCell>;
      }
    },
    {
      title: 'User',
      key: 'userName',
      width: 15,
      sorter: (a, b) => (a.userName && b.userName ? a.userName.localeCompare(b.userName) : 0),
      sortOrder: sortedInfo.columnKey === 'userName' ? sortedInfo.order : null,
      render: (a, record) => <TableCell>{record.userName}</TableCell>
    },
    {
      title: 'Start Time',
      key: 'startTime',
      width: 15,
      sorter: (a, b) => moment(a.startTime).unix() - moment(b.startTime).unix(),
      sortOrder: sortedInfo.columnKey === 'startTime' ? sortedInfo.order : null,
      render: (a, record) => {
        return (
          <>
            <TableCell>
              {convertUTCStringtoLocalString(record.startTime, DEFAULT_DATE_FORMAT)}
            </TableCell>
          </>
        );
      }
    },
    {
      title: 'End Time',
      key: 'endTime',
      width: 15,
      sorter: (a, b) =>
        a.endTime && b.endTime ? moment(a.endTime).unix() - moment(b.endTime).unix() : 0,
      sortOrder: sortedInfo.columnKey === 'endTime' ? sortedInfo.order : null,
      render: (a, record) => {
        return (
          <>
            <TableCell>
              {record.endTime
                ? convertUTCStringtoLocalString(record.endTime, DEFAULT_DATE_FORMAT)
                : ''}
            </TableCell>
          </>
        );
      }
    },
    {
      title: 'Total Amount',
      key: 'totalAmount',
      width: 15,
      dataIndex: 'totalAmount',
      sorter: (a, b) => a.totalAmount - b.totalAmount,
      sortOrder: sortedInfo.columnKey === 'totalAmount' ? sortedInfo.order : null,
      render: (totalAmount) => (
        <TableCell className="highlight-number">{nepaliNumberFormatter(totalAmount)}</TableCell>
      )
    },
    {
      title: 'Action',
      key: 'actions',
      width: 5,
      fixed: 'right',
      render: (a, record) => {
        const menuItems: (
          | {
              key: string;
              label: JSX.Element;
              onClick?: undefined;
            }
          | {
              key: string;
              label: JSX.Element;
              onClick: () => void;
            }
        )[] = [];

        if (checkAccess('ADMIN')) {
          menuItems.push({
            key: '1',
            label: (
              <Tooltip title="Print" color="blue">
                <PrinterOutlined style={{ transform: 'scale(1.4)', width: '100%' }} />
              </Tooltip>
            ),
            onClick: async () => {
              await handlePosCashSettlementPrint(
                record.id,
                record.userId,
                record.startTime,
                record.endTime
              );
            }
          });
        }

        const menu = <Menu items={menuItems} />;
        return record.endTime ? <ActionDropdown menu={menu} /> : '';
      }
    }
  ];

  return (
    <Spin spinning={isLoading}>
      <CustomModal
        title={'Cash Settlement'}
        isModalOpen={isPosCashSettlementModalOpen}
        setIsModalOpen={setIsPosCashSettlementModalOpen}
        footer={false}>
        <CashSettlementPrint
          sessionInfo={cashSettlementPrintData?.sessionInfo}
          paymentInfo={cashSettlementPrintData?.paymentInfo}
          moneyInfo={cashSettlementPrintData?.moneyInfo}
          locationDetails={cashSettlementPrintData.locationDetails}
          totalPayment={cashSettlementPrintData.totalPayment}
          sellTotal={cashSettlementPrintData.sellTotal}
          sellReturnTotal={cashSettlementPrintData.sellReturnTotal}
        />
      </CustomModal>
      <AppContent
        breadcrumbItems={breadcrumbItems}
        withfilter={false}
        button={
          <>
            <div>
              <TableFilter
                onInitialLoad={({ data, pagination }) => {
                  setPage(pagination.page);
                  setSize(pagination.size);

                  if (data) {
                    setSessionList(data);
                    setIsLoading(false);
                  }
                }}
                defaultValues={{
                  dateCustom: [moment(0, 'HH'), moment(0, 'HH').add(1, 'days')],
                  value: '',
                  skip: 0,
                  count: 100
                }}
                initial={true}
                onSubmit={onSubmitFilter}
                form={form}
                style={
                  'grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3  xl:grid-cols-4 gap-2 items-center justify-center'
                }
                styleforbuttons={'flex justify-end items-center'}>
                <Form.Item name="isOpen" label="Session Status">
                  <Select placeholder="Select session status" allowClear={true}>
                    <Select.Option value={true}>Open</Select.Option>
                    <Select.Option value={false}>Closed</Select.Option>
                  </Select>
                </Form.Item>
                <UserSearchV2 hasParentFormItem={false} name={'userId'} />
                <Form.Item name="value" label="Search">
                  <Input placeholder="Search" />
                </Form.Item>
              </TableFilter>
            </div>
            {/* </div> */}
          </>
        }>
        <GenericTable
          form={form}
          columns={columns}
          data={sessionList.results}
          hideDefaultPagination
          scroll={{ x: 600, y: '75vh' }}
          generateSummary
          pagination={{
            page: page,
            total: sessionList.total,
            size: size,
            onPagination
          }}
          tableName={'session-list'}
          toSort={handleChange}
        />
      </AppContent>
    </Spin>
  );
};

export default SessionList;
