import axios from 'axios';
import { API_URL, JWT_TOKEN } from '../constants/config';
import { globalSignOut } from './auth.utils';
import { getRefreshTokenFromStorage, setLocalStorage } from './storage.utils';

let refreshPromise: Promise<string> | null = null;

export const getRefreshedToken = async (): Promise<string> => {
  if (refreshPromise) return refreshPromise;

  refreshPromise = (async () => {
    try {
      const refreshToken = getRefreshTokenFromStorage();
      if (!refreshToken) throw new Error('No refresh token available');

      const { data } = await axios.post(`${API_URL}auth/refresh`, { refreshToken });
      setLocalStorage(JWT_TOKEN, data.accessToken);
      return data.accessToken;
    } catch (error) {
      globalSignOut();
      throw error;
    } finally {
      refreshPromise = null;
    }
  })();

  return refreshPromise;
};
