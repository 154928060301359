import { useContext, useState } from 'react';
import { Button, Checkbox, Form, Input, message } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { Navigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';

import { AuthContext } from '../../contexts/auth.context';
import { login_query } from '../../services/auth/queries';
import { ILoginRequest } from '../../services/auth/types';
import { getLocalStorage, setLocalStorage } from '../../utils/storage.utils';
import { JWT_REFRESH_TOKEN, JWT_TOKEN, USER_INFO } from '../../constants/config';

import './login.css';

const Login = () => {
  const { setProfileData } = useContext(AuthContext);
  const registerMutation = useMutation(login_query);
  const [isLoading, setIsLoading] = useState(false);
  const token = getLocalStorage(JWT_TOKEN);

  if (token) {
    return <Navigate to="/sell" replace />;
  }

  const onFinish = (values: ILoginRequest) => {
    setIsLoading(true);
    registerMutation.mutate(values, {
      onError: () => {
        message.error('Incorrect Credentials. Try again');
        setIsLoading(false);
      },
      onSuccess: async (data) => {
        // Set user information in local storage
        setLocalStorage(USER_INFO, {
          ...data.user,
          perms: data.permissions,
          preferences: data.preferences,
          global: data.settings
        });

        setLocalStorage(JWT_TOKEN, data.token.accessToken);
        setLocalStorage(JWT_REFRESH_TOKEN, data.token.refreshToken);

        setProfileData?.(data.user);

        const channel = new BroadcastChannel('auth-channel');
        channel.postMessage({ action: 'signin' });
        channel.close();
        setIsLoading(false);
      }
    });
  };

  return (
    <div className="h-screen flex justify-center items-center login-bg-wrapper">
      <Form
        className="formdiv"
        name="login"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 25 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        disabled={isLoading}
        autoComplete="off">
        <p className="text-orange-40 text-lg my-4">Login</p>
        <Form.Item
          name="email"
          rules={[{ required: true, message: 'Please input your email!' }]}
          className="emailinput">
          <Input className="inputemail" placeholder="Email" suffix={<UserOutlined />} />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: 'Please input your password!' }]}
          className="passwordinput">
          <Input.Password
            className="inputpassword"
            placeholder="Password"
            autoComplete="current-password"
          />
        </Form.Item>
        <div className="flex justify-evenly divclass">
          <Form.Item name="remember" valuePropName="checked">
            <Checkbox className="w-100 text-white">Remember me</Checkbox>
          </Form.Item>
          <Form.Item>
            {/* <p className="text-white">Forgot your password?</p> */}
            <a>Forgot your password?</a>
          </Form.Item>
        </div>
        <Form.Item>
          <Button
            loading={isLoading}
            type="primary"
            htmlType="submit"
            className="bg-indigo-600 button">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Login;
