import { AxiosResponse } from 'axios';
import http from '@/utils/http.utils';
import { ILeafyItem, ILeafyItemList, ILeafyPurchaseWastage } from './types';

export const get_leafy_purchase_wastage_list = async (filter = '') => {
  const response: AxiosResponse<ILeafyItemList> = await http.get('leafy-items/?' + filter);
  return response.data;
};

export const get_leafy_purchase_wastage_details = async (id: number) => {
  const response: AxiosResponse<ILeafyItem> = await http.get(`leafy-items/${id}`);
  return response.data;
};

export const get_leafy_purchase_wastage_total = async (params: {
  startDate: string;
  endDate: string;
  locationId: number;
}) => {
  const response: AxiosResponse<ILeafyPurchaseWastage> = await http.get(
    'leafy-items/purchase-and-wastage',
    params
  );
  return response.data;
};
