const Tests = () => {
  //const handleAdd = async () => {};

  // const socket = websocket(SocketNameSpaces.NOTIFICATION);
  // socket.on('USER_NOTIFICATION', (...data) => {
  //   console.log('Args', data);
  // });
  return <>{/* <Button onClick={handleAdd}>Add</Button> */}</>;
};

export default Tests;
