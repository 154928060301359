import moment from 'moment';

import { convert_string_to_nepali_date_object } from '@/utils/nepaliDateConverter';
import { addTransactionForExportAll } from '../trial-balance/trial-transaction.service';

import {
  IPnLResponseData,
  ITrialBalanceResponseData,
  IAccountTypeResponseData,
  IPnLTableData,
  IPnLTableWithTransactionData
} from '@/services/accounts/types';

import {
  addLocationIfAbsent,
  calculateStartAndEndBalance,
  flattenData,
  flattenDataExportAll,
  getExportData,
  getSumAndCalculateTotal
} from '@/services/accounts/services';

import {
  get_account_latest_history,
  get_account_list_by_location,
  get_child_from_parent_list,
  get_profit_loss_list
} from '@/services/accounts/queries';

interface CommonProps {
  locationId: number | string;
  parentIds?: number[];
  date: { startDate: string; endDate: string };
}

interface Props extends CommonProps {
  currentBalanceSortBy: string;
  skip?: number;
  count?: number;
}

interface IProfitLossOptions extends Props {
  data: IPnLResponseData[];
  currentFinancialStart: string;
}

interface IFlattenData extends CommonProps {
  valueExport: number;
  currentData: IPnLTableData[];
}

export const addTransactionToProfitLoss = async (options: Props) => {
  const {
    locationId,
    date: { startDate, endDate },
    currentBalanceSortBy,
    parentIds,
    skip,
    count
  } = options;

  // Get profit loss list
  const response = await get_profit_loss_list(
    locationId,
    startDate,
    endDate,
    currentBalanceSortBy,
    parentIds,
    skip,
    count
  );

  const results = await getSumAndCalculateTotal<IPnLResponseData>(
    startDate,
    endDate,
    response.results
  );
  return { results, count: response.total };
};

export const addTransactionToProfitLossChildren = async (options: Props) => {
  const {
    locationId,
    date: { startDate, endDate },
    currentBalanceSortBy,
    parentIds,
    skip,
    count
  } = options;

  // Get profit loss list
  const response = await get_child_from_parent_list(
    locationId,
    startDate,
    endDate,
    currentBalanceSortBy,
    parentIds,
    skip,
    count
  );

  const results = await getSumAndCalculateTotal<ITrialBalanceResponseData>(
    startDate,
    endDate,
    response.results
  );

  return { results, count: response.total };
};

export async function getProfitLossBalanceData<T>({
  locationId,
  data,
  ...rest
}: IProfitLossOptions) {
  if (locationId === '') {
    return await addLocationIfAbsent<T>({
      data: data as any,
      allowChildren: true
    });
  }

  // Get child from parent list
  const { results: responseChildResults } = await addTransactionToProfitLossChildren({
    locationId,
    date: rest.date,
    currentBalanceSortBy: rest.currentBalanceSortBy,
    parentIds: rest.parentIds,
    skip: rest.skip,
    count: rest.count
  });

  const results = await calculateStartAndEndBalance<typeof responseChildResults>(
    rest.date.startDate,
    rest.parentIds as number[],
    responseChildResults,
    rest.currentFinancialStart
  );

  const responseData = results.map((item) => {
    return { ...item, children: [] };
  });

  return responseData as unknown as T;
}

export async function getFlattenData(options: IFlattenData) {
  const { date, valueExport, locationId, parentIds } = options;

  if (valueExport === 1) {
    if (locationId !== undefined) {
      const exportAllData = await getExportAllData({
        date,
        locationId,
        parentIds
      });

      return flattenDataExportAll<IAccountTypeResponseData>(exportAllData).map((item) => {
        return {
          ...item,
          transaction: {
            ...item.transaction,
            totalCredit: item.transaction ? item.transaction.totalCredit || 0 : 0,
            totalDebit: item.transaction ? item.transaction.totalDebit || 0 : 0
          },
          date: moment(item.createdAt).format('YYYY-MM-DD'),
          miti: convert_string_to_nepali_date_object(item.createdAt).format('MM/DD/YYYY')
        };
      });
    }
  } else {
    return flattenData<IPnLTableWithTransactionData>(options.currentData)
      .filter((val) => !val.hasMore)
      .map((item) => {
        return {
          ...item,
          transaction: {
            ...item.transaction,
            totalCredit: item.transaction ? item.transaction.totalCredit || 0 : 0,
            totalDebit: item.transaction ? item.transaction.totalDebit || 0 : 0
          },
          date: moment(item.createdAt).format('MM/DD/YYYY'),
          miti: convert_string_to_nepali_date_object(item.createdAt).format('MM/DD/YYYY')
        };
      });
  }
}

async function getExportAllData(options: CommonProps) {
  const {
    date: { startDate, endDate },
    parentIds
  } = options;

  let locationId = options.locationId;

  if (locationId === '') {
    // API Call to get total Accounts
    let allAccounts = await get_account_list_by_location(0, 1);
    const totalAccounts = allAccounts.total;

    // Send Total Count to fetch all data
    allAccounts = await get_account_list_by_location(0, totalAccounts);
    const ids = allAccounts.results.map((val) => val.id);

    allAccounts.results = await addTransactionForExportAll(
      allAccounts.results,
      startDate,
      endDate,
      ids
    );

    const start = moment(startDate).format('YYYY-MM-DD');
    // const end = moment(endDate).add(1, 'day').format('YYYY-MM-DD');
    const startBalanceData = await get_account_latest_history(start, ids);
    // const endBalanceData = await get_account_latest_history(end, ids);
    const updatedAllAccounts = allAccounts.results.map((val) => {
      const selectedStartData = startBalanceData.results.find(
        (valStart) => val.id === valStart.accountId
      );
      const startBal = selectedStartData ? selectedStartData.balance : 0;
      let transactionTotalDebit = 0;
      let transactionTotalCredit = 0;
      if (val.transaction) {
        transactionTotalDebit = val.transaction.totalDebit;
        transactionTotalCredit = val.transaction.totalCredit;
      }
      const endBal = startBal + transactionTotalDebit - transactionTotalCredit;
      return {
        ...val,
        startBalance: startBal,
        endBalance: endBal
      };
    });
    const top = updatedAllAccounts
      .filter((val) => parentIds?.includes(val.id))
      .sort((a, b) => b.balance - a.balance);
    const exportData = getExportData(updatedAllAccounts, top);
    return exportData;
  } else {
    locationId = typeof locationId === 'string' ? parseInt(locationId) : locationId;
    let allAccounts = await get_account_list_by_location(0, 1, locationId);
    const totalAccounts = allAccounts.total;
    allAccounts = await get_account_list_by_location(0, totalAccounts, locationId);
    const ids = allAccounts.results.map((val) => {
      return val.id;
    });

    allAccounts.results = await addTransactionForExportAll(
      allAccounts.results,
      startDate,
      endDate,
      ids
    );

    const start = moment(startDate).format('YYYY-MM-DD');
    const startBalanceData = await get_account_latest_history(start, ids);
    // const endBalanceData = await get_account_latest_history(end, ids);
    const updatedAllAccounts = allAccounts.results
      .filter((value) => value.locationId)
      .map((val) => {
        const selectedStartData = startBalanceData.results.find(
          (valStart) => val.id === valStart.accountId
        );
        const startBal = selectedStartData ? selectedStartData.balance : 0;
        let transactionTotalDebitX = 0;
        let transactionTotalCreditX = 0;
        if (val.transaction) {
          transactionTotalDebitX = val.transaction.totalDebit;
          transactionTotalCreditX = val.transaction.totalCredit;
        }
        const endBal = startBal + transactionTotalDebitX - transactionTotalCreditX;
        return {
          ...val,
          startBalance: startBal,
          endBalance: endBal
        };
      });

    const top = updatedAllAccounts
      .filter((val) => parentIds?.includes(val.parentId))
      .sort((a, b) => b.balance - a.balance);

    const exportData = getExportData(updatedAllAccounts, top);
    return exportData;
  }
}
