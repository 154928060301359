import moment from 'moment';

import AppContent from '@/components/Common/Content/Content';
import TableFilter from '@/components/FliterTable';
import { ConvertObjectToURL } from '@/utils/converturl';
import getError from '@/utils/getError';
import handlePagination from '@/utils/handlePagination';
import { Form, Input, message } from 'antd';
import { useState } from 'react';
import { get_purchase_agent_bill_cancel_list } from '@/services/purchases/queries';
import Table from './Table';
import UsersDB from '@/store/localstorage/UsersDB';
import { get_user_list_ids } from '@/services/users/queries';
import { createEmptyPromise } from '@/utils/createEmptyPromise';
import { getImagesFromServer } from '@/services/upload/queries';
import { IPurchaseAgentBillCancel } from '@/services/purchases/types';
import AgentSearchV2 from '@/components/Common/CustomSearch/Agents';
import { AgentsDB } from '@/store/localstorage/AgentsDB';
import { get_agent_list_ids } from '@/services/agents/queries';
import AgentBillRangeSearch from '@/components/Common/CustomSearch/BillRange';

function AgentBillCancelList() {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(true);
  const [pagination, setPagination] = useState({ page: 1, size: 100 });
  const [data, setData] = useState({ total: 0, results: [] as IPurchaseAgentBillCancel[] });

  async function onSubmitFilter(filter: string) {
    const data = await getInfo(filter);
    setPagination({ page: 1, size: 100 });
    return data;
  }

  const agentId = Form.useWatch('agentId', form);

  async function getInfo(filter = '') {
    try {
      const params = new URLSearchParams(filter);
      const billRangeId = params.get('billRangeId');

      params.delete('billRangeId');
      billRangeId && params.set('billRangeIds[]', billRangeId);

      const updatedFilter = params.toString();

      setIsLoading(true);
      const response = await get_purchase_agent_bill_cancel_list(updatedFilter);
      const absentUsers = [] as number[];
      const absentAgents = [] as number[];

      for (const item of response.results) {
        const agentId = item.__billRange__.agentId;

        if (item.createdBy) {
          const createdUser = await UsersDB.getUser(item.createdBy);
          if (!createdUser) absentUsers.push(item.createdBy);
          item.createdUser = createdUser?.name;
        }

        if (agentId) {
          const agent = await AgentsDB.getAgent(agentId);
          if (!agent) absentAgents.push(agentId);
          item.agent = agent?.user?.name;
        }
      }

      const usersPromise =
        absentUsers.length > 0
          ? get_user_list_ids([...new Set(absentUsers)])
          : createEmptyPromise({ data: { total: 0, results: [] } });

      const agentsPromise =
        absentAgents.length > 0
          ? get_agent_list_ids([...new Set(absentAgents)])
          : createEmptyPromise({ total: 0, results: [] });

      const allMediaId = response.results.map((item) => item.mediaId).filter(Boolean) as number[];
      const allMedias =
        allMediaId.length > 0 ? getImagesFromServer(allMediaId) : createEmptyPromise(null);

      const [users, medias, agents] = await Promise.all([usersPromise, allMedias, agentsPromise]);
      await UsersDB.addUsers(users.data.results);
      await AgentsDB.addAgents(agents.results);

      for (const item of response.results) {
        const agentId = item.__billRange__.agentId;

        if (item?.createdBy && absentUsers.includes(item.createdBy)) {
          const createdUser = await UsersDB.getUser(item.createdBy);
          item.createdUser = createdUser?.name;
        }

        if (agentId && absentAgents.includes(agentId)) {
          const agent = await AgentsDB.getAgent(agentId);
          item.agent = agent?.user?.name;
        }

        const media = medias?.find((m) => m?.id === item?.mediaId);
        if (media) item.mediaURL = media.url;
      }

      setData(response);
      return response;
    } catch (error) {
      message.error(getError(error) || 'Failed to get cancelled agent bill');
    } finally {
      setIsLoading(false);
    }
  }

  const onPagination = async (page = 1, size = 100, isSize = false) => {
    setIsLoading(true);
    const { values, ...pagination } = handlePagination({
      formValues: form.getFieldsValue(),
      page,
      size,
      isSize
    });

    setPagination((prev) => {
      prev.page = pagination.page;
      if (isSize) prev.size = pagination.size;
      return prev;
    });

    const url = ConvertObjectToURL(values);
    return await getInfo(url);
  };

  return (
    <AppContent
      breadcrumbItems={[
        { label: 'Purchase', link: '/purchase' },
        { label: 'Agent Bill Range', link: '/purchases/agents-bill-range' },
        { label: 'Cancelled Agent Bill', link: '/purchases/agents-bill-cancel' }
      ]}
      withfilter
      button={
        <TableFilter
          initial
          form={form}
          onInitialLoad={({ data, pagination }) => {
            setPagination(pagination);

            if (data) {
              setData(data);
              setIsLoading(false);
            }
          }}
          onSubmit={onSubmitFilter}
          defaultValues={{
            dateCustom: [moment(0, 'HH'), moment(0, 'HH').add(1, 'days')],
            agentId: '',
            value: '',
            skip: 0,
            count: 100
          }}
          styleforbuttons={'flex justify-end items-center'}
          style={'grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3  xl:grid-cols-6 gap-2'}>
          <AgentSearchV2
            showAll
            name={'agentId'}
            hasParentFormItem={false}
            onSelect={() => {
              form.setFieldValue('billRangeId', undefined);
            }}
          />
          {agentId && (
            <AgentBillRangeSearch
              hasParentFormItem={false}
              name={'billRangeId'}
              selectedAgentId={agentId}
            />
          )}
          <Form.Item name={'billRangeId'} label={'Bill Range'}>
            <Input placeholder={'Enter Bill Range Id'} />
          </Form.Item>
        </TableFilter>
      }>
      <Table
        form={form}
        data={data}
        isLoading={isLoading}
        pagination={pagination}
        onPagination={onPagination}
      />
    </AppContent>
  );
}

export default AgentBillCancelList;
