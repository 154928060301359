import ExcelJS from 'exceljs';

export interface IExportNestedColumn<G> {
  formatByComma?: boolean;
  title: string;
  width?: number;
  height?: number;
  style?: { background?: string; color?: string };
  dataIndex: keyof G | '';
  children?: IExportNestedColumn<G>[];
  showDashIfAbsent?: boolean;
  alignment?: {
    horizontal?: 'left' | 'center' | 'right';
    vertical?: 'top' | 'bottom' | 'middle';
    wrapText?: boolean;
  };
}

interface Props<T> {
  data: T[];
  columns: IExportNestedColumn<T>[];
  workSheet: ExcelJS.Worksheet;
  startRow: number;
  header?: { bgColor?: string; borderStyle?: Partial<ExcelJS.Borders> };
}

function getColSpan<T>(header: IExportNestedColumn<T>): number {
  if (!header.children || !header.children.length) return 1;
  return header.children.reduce((span, child) => span + getColSpan(child), 0);
}

export function flattenHeaders<T>(headers: IExportNestedColumn<T>[]): IExportNestedColumn<T>[] {
  return headers.flatMap((header) => {
    if (header.children) {
      return flattenHeaders(header.children);
    }
    return [header];
  });
}

export function findMaxDepth<T>(columns: IExportNestedColumn<T>[], currentDepth = 1): number {
  let maxDepth = currentDepth;
  // console.log('Checking depth at current level:', currentDepth);

  for (const column of columns) {
    if (column.children && column.children.length > 0) {
      // console.log(`Column "${column.title}" has children, going deeper...`);
      const childDepth = findMaxDepth(column.children, currentDepth + 1);
      maxDepth = Math.max(maxDepth, childDepth);
    }
  }

  return maxDepth;
}

export function addNestedRows<T>({ data, columns, workSheet, startRow, header }: Props<T>) {
  const flattenColumns = flattenHeaders(columns);
  const maxDepth = findMaxDepth(columns);

  const INITIAL_START_ROW = startRow;
  const MAX_COL_ROW = INITIAL_START_ROW + maxDepth - 1;
  const DATA_START_ROW = MAX_COL_ROW + 1;

  // Assign Headers
  function processHeaders<T>(
    columns: IExportNestedColumn<T>[],
    startRow: number,
    startCol: number
  ) {
    const currentRow = startRow;
    let currentCol = startCol;

    columns.forEach((column) => {
      const colSpan = getColSpan(column);
      const finalColumn = currentCol + colSpan - 1;

      // Merge cells vertically if there are no children
      if (!column.children) {
        const rowSpan = MAX_COL_ROW - currentRow;
        workSheet.mergeCells(currentRow, currentCol, currentRow + rowSpan, finalColumn);

        // Add Row Height
        workSheet.getRow(currentRow).height = column?.height || 45;
      } else {
        workSheet.mergeCells(currentRow, currentCol, currentRow, finalColumn);
        workSheet.getCell(currentRow, finalColumn).font = { bold: true };
        if (column.height) {
          workSheet.getRow(currentRow).height = column.height;
        }
      }

      // Set header text
      const cell = workSheet.getCell(currentRow, currentCol);
      cell.value = column.title;
      if (header?.borderStyle) cell.border = header.borderStyle;

      cell.alignment = { horizontal: 'center', vertical: 'middle', wrapText: true };

      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: column?.style?.background || header?.bgColor || 'FFE7E6E6' } // ARGB format, where 'FF' is for full opacity
      };

      cell.font = { color: { argb: column?.style?.color || 'FF000000' } };

      // Process children if they exist
      if (column.children) {
        processHeaders(column.children, currentRow + 1, currentCol);
      }

      currentCol += colSpan; // Move to next header position
    });
  }

  processHeaders(columns, INITIAL_START_ROW, 1);
  workSheet.columns = flattenColumns.map((header) => {
    const { style, ...rest } = header;
    return {
      ...rest,
      key: header.dataIndex as string,
      width: header.width || 15
    };
  });

  data.forEach((item, rowIndex) => {
    const currentRow = workSheet.getRow(DATA_START_ROW + rowIndex);
    flattenColumns.forEach((column, colIndex) => {
      const key = column.dataIndex as keyof T;
      const value = item[key] as any;
      const finalValue = column.showDashIfAbsent ? value || '-' : value;

      const cell = currentRow.getCell(colIndex + 1);
      cell.value = finalValue;
      if (header?.borderStyle) cell.border = header.borderStyle;

      const horizontal = column.alignment?.horizontal || 'left';
      const vertical = column.alignment?.vertical || 'middle';
      const wrapText = column.alignment?.wrapText || true;
      cell.alignment = { horizontal, vertical, wrapText };
      if (column.formatByComma) cell.numFmt = '#,##,##0.00';
    });
  });
}

export async function exportExcelBuffer(buffer: ExcelJS.Buffer, fileName = 'default') {
  const blob = new Blob([buffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  });

  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = `${fileName}.xlsx`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
