import { Button, Form, Menu, Spin, Table, Tooltip, message } from 'antd';
import { useState, useRef } from 'react';
import TableFilter from '@/components/FliterTable';
import { LocationSearch } from '@/components/Common/LocationSearch/LocationSearch';
import AppContent from '@/components/Common/Content/Content';
import moment from 'moment';
import { getUserData } from '@/utils/auth.utils';
import {
  get_product_list_ids,
  get_total_sold_products,
  get_units_list
} from '@/services/products/queries';
import ProductsDB from '@/store/localstorage/ProductsDB';
import UnitsDB from '@/store/localstorage/UnitsDB';
import { numberDecimalFormatter } from '@/utils/numberFormatter';
import { ExportColumnType, exportExcel } from '@/utils/exportExcel';
import { CustomModal } from '@/components/Common/CustomModal';
import CustomTable from '@/components/Common/CustomResuableInvoice/CustomTable';
import { useReactToPrint } from 'react-to-print';
import ActionDropdown from '@/components/Common/Dropdownactions';
import LocationSearchV2 from '@/components/Common/CustomSearch/Location';

const ProductsSoldTotal = () => {
  const printPDFRef = useRef<any>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [totalPurchaseData, setTotalPurchaseData] = useState<any[]>([]);
  const [form] = Form.useForm();
  const { preferences } = getUserData();
  const preferenceLocationId = preferences?.preferences
    ? JSON.parse(preferences?.preferences)?.locationId
    : undefined;

  const [openmodalforExport, setOpenModalforexport] = useState<boolean>(false);

  const breadcrumbItems = [{ label: 'Products Total Sold' }];

  const onSubmitFilter = async (val: string) => {
    setIsLoading(true);
    const response = await get_total_sold_products(val);
    // console.log('response', response);
    const fetchProduct: any = {};
    if (response.data.results) {
      for (let ind = 0; ind < response.data.results.length; ind++) {
        const { baseUnitQuantity, productId } = response.data.results[ind];
        const product: any = await ProductsDB.getProduct(productId);
        if (!product) {
          fetchProduct[productId] = ind;
        } else {
          // console.log('Product', product);
          let defaultUnit = product.productUnits.find((curr: any) => curr.isDefault === true);
          if (!defaultUnit) {
            defaultUnit = product.productUnits[0];
          }
          // console.log('Default Unit-->', defaultUnit);
          let findUnit: any = await UnitsDB.getUnit(defaultUnit.unitId);
          if (!findUnit) {
            const allUnits = await get_units_list();
            await UnitsDB.addUnits(allUnits);
            findUnit = await UnitsDB.getUnit(defaultUnit.unitId);
          }
          (response.data.results[ind].quantity = numberDecimalFormatter(
            parseFloat(baseUnitQuantity) / findUnit.baseUnitMultiplier
          )),
            (response.data.results[ind].productName = product.name);
          response.data.results[ind].unit = findUnit.shortName;
        }
      }
    }
    if (Object.keys(fetchProduct).length > 0) {
      const response2: any = await get_product_list_ids(Object.keys(fetchProduct));
      for (let ind = 0; ind < response2?.data?.results.length; ind++) {
        const { id, productUnits, name } = response2.data.results[ind];
        let defaultUnit = productUnits.find((curr: any) => curr.isDefault === true);
        if (!defaultUnit) {
          defaultUnit = productUnits[0];
        }
        // console.log('Default Unit-->', defaultUnit);
        let findUnit: any = await UnitsDB.getUnit(defaultUnit.unitId);
        if (!findUnit) {
          const allUnits = await get_units_list();
          await UnitsDB.addUnits(allUnits);
          findUnit = await UnitsDB.getUnit(defaultUnit.unitId);
        }
        (response.data.results[fetchProduct[id]].quantity = numberDecimalFormatter(
          parseFloat(response.data.results[fetchProduct[id]].baseUnitQuantity) /
            findUnit.baseUnitMultiplier
        )),
          (response.data.results[fetchProduct[id]].productName = name);
        response.data.results[fetchProduct[id]].unit = findUnit.shortName;
      }
      // console.log('findproduct-->', findproduct);
      ProductsDB.addProducts(response2.data.results);
    }
    // console.log('dfdsfsdf', response.data.results);
    setTotalPurchaseData(response.data.results);
    setIsLoading(false);
    return response.data.results;
  };

  const columns = [
    {
      title: 'S.N.',
      render: (a: any, b: any, c: any) => <div className="text-left ml-3">{c + 1}</div>,
      width: 2
    },
    { title: 'Product', dataIndex: 'productName', width: 10 },
    { title: 'Quantity', dataIndex: 'quantity', width: 10 },
    { title: 'Unit', dataIndex: 'unit', width: 8 }
  ];

  const handleExport = () => {
    setIsLoading(true);
    try {
      const columns: ExportColumnType[] = [
        {
          title: 'S.N.',
          dataIndex: 'SN',
          width: 50
        },
        {
          title: 'Product',
          dataIndex: 'productName',
          width: 300
        },
        {
          title: 'Quantity',
          dataIndex: 'quantity',
          width: 150
        },
        {
          title: 'Unit',
          dataIndex: 'unit',
          width: 100
        }
      ];
      if (totalPurchaseData.length === 0) {
        message.error('No Data to Export');
        return;
      }
      const dataUpdated = totalPurchaseData.map((item: any, index: number) => {
        return {
          ...item,
          SN: index + 1
        };
      });
      exportExcel(columns, dataUpdated, 'Total Sold Products List');
    } catch (err: any) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const columsforPrint: any = [
    {
      label: 'Name',
      dataIndex: 'productName',
      render: (text: string) => {
        return <div className="ml-2">{text}</div>;
      }
    },
    {
      label: 'Quantity',
      dataIndex: 'quantity',
      render: (text: string) => {
        return <div className="text-center">{text}</div>;
      }
    },
    {
      label: 'Unit',
      dataIndex: 'unit',
      render: (text: string) => {
        return <div className="text-center">{text}</div>;
      }
    }
  ];

  const handlePDFExport = useReactToPrint({
    content: () => printPDFRef.current
  });

  return (
    <Spin spinning={isLoading}>
      <CustomModal
        footer={false}
        isModalOpen={openmodalforExport}
        setIsModalOpen={setOpenModalforexport}
        title="Total Product Sold List View For PDF print">
        <div style={{ maxHeight: '80vh', overflow: 'scroll' }}>
          <CustomTable
            columns={columsforPrint}
            datas={totalPurchaseData}
            reff={printPDFRef}
            title={'Product Sold Total'}
          />
          <div className="flex justify-end mt-3">
            <Button type="primary" onClick={handlePDFExport}>
              Print Pdf
            </Button>
          </div>
        </div>
      </CustomModal>
      <AppContent
        breadcrumbItems={breadcrumbItems}
        withfilter={true}
        button={
          <>
            <div>
              <TableFilter
                form={form}
                onInitialLoad={({ data }) => {
                  if (data) {
                    setTotalPurchaseData(data);
                    setIsLoading(false);
                  }
                }}
                defaultValues={{
                  dateCustom: [moment(0, 'HH'), moment(0, 'HH').add(1, 'days')],
                  locationId: preferenceLocationId ? preferenceLocationId : ''
                }}
                initial={true}
                onSubmit={onSubmitFilter}
                style={
                  'grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3  xl:grid-cols-4 gap-2 items-center justify-center'
                }
                // outsidestyle="flex justify-end gap-2 items-end"
                styleforbuttons={'flex justify-end items-center'}
                buttons={
                  <>
                    {/* <CustomButton text="Export" backgroundColor="#1890ff" onClick={handleExport} />
                    <CustomButton
                      text="PDF Export"
                      backgroundColor="#1890ff"
                      onClick={() => setOpenModalforexport(true)}
                    /> */}
                    <div>
                      <ActionDropdown
                        button={true}
                        menu={
                          <Menu
                            items={[
                              {
                                key: '1',
                                label: (
                                  <Tooltip title="Export Excel" color="blue">
                                    <div className="text-center">Excel</div>
                                  </Tooltip>
                                ),
                                onClick: () => {
                                  handleExport();
                                }
                              },
                              {
                                key: '2',
                                label: (
                                  <Tooltip title="Export PDF" color="blue">
                                    <div className="text-center">PDF</div>
                                  </Tooltip>
                                ),
                                onClick: () => {
                                  setOpenModalforexport(true);
                                }
                              }
                            ]}
                          />
                        }
                      />
                    </div>
                  </>
                }>
                <LocationSearchV2 hasParentFormItem={false} name={'locationId'} showAll />
              </TableFilter>
            </div>
          </>
        }>
        <Table
          size="small"
          scroll={{ x: '40rem' }}
          columns={columns}
          dataSource={totalPurchaseData}
          pagination={false}
        />
      </AppContent>
    </Spin>
  );
};

export default ProductsSoldTotal;
