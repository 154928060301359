import CustomSearch, { SelectProps } from '..';
import { get_woo_products_list_only } from '@/services/woocommerce/queries';
import { IWooCommerceProduct } from '@/services/woocommerce/types';

import { WooCommerceProductsDB } from '@/store/localstorage/WooCommerceProductsDB';

type MultipleProps =
  | {
      defaultValue?: number;
      isMultiple?: false;
      setSelected?: (selected?: IWooCommerceProduct) => void;
      onSelect?: (selected: number) => void;
    }
  | {
      defaultValue?: number[];
      isMultiple: true;
      setSelected?: (selected?: IWooCommerceProduct[]) => void;
      onSelect?: (selected: number[]) => void;
    };

type Props = SelectProps & MultipleProps;

function ProductByWooCommerce(props: Props) {
  async function getProducts(skip = 0, count = 10, value = '', filter = '') {
    const page = count === 0 ? 1 : Math.floor(skip / count) + 1;
    return get_woo_products_list_only(page, count, value, filter);
  }

  return (
    <CustomSearch
      path={{ name: 'name', value: 'wooProductId' }}
      formFor="woo-product"
      addCallback={WooCommerceProductsDB.add}
      serverCallback={getProducts}
      dbSearchById={(id) => {
        const intId = Number(id);
        return WooCommerceProductsDB.getByID(intId);
      }}
      dbSearchCallback={WooCommerceProductsDB.search}
      {...props}
    />
  );
}

export default ProductByWooCommerce;
