import React, { useContext, useState, useEffect } from 'react';
import { useMutation } from '@tanstack/react-query';
import { get_tasks_list_filter } from '../../../services/tasks/queries';
import {
  archive_tasks,
  update_tasks_log,
  update_tasks_mutation
} from '../../../services/tasks/mutations';
import AppContent from '../../../components/Common/Content/Content';
import { AuthContext } from '../../../contexts/auth.context';
import { Button, Form, Menu, message, Modal, Select, Spin, Tag, Tooltip } from 'antd';
import './list.less';
import { checkAccess } from '../../../routes/acl';
import TableFilter from '../../../components/FliterTable';
import CustomizeTable from '../../../components/Common/CustomizeTable/CustomizeTable';
import CustomButton from '../../../components/Common/CustomButton/CustomButton';
import { useLocation, useNavigate } from 'react-router-dom';
import DiscussionChatModal from '../../../components/Common/DiscussionChatModal';
import { convertLocalToUTCString } from '../../../utils/convertToUTC';
import { ConvertObjectToURL } from '../../../utils/converturl';
import ActionDropdown from '../../../components/Common/Dropdownactions';
import TableCell from '../../../components/Common/CustomizeTable/CustomCell';
import { UsersSearch } from '../../../components/Common/UsersSearch';
import { getUserData } from '../../../utils/auth.utils';
import { ITasks } from '../../../services/tasks/types';
import CustomUpdateIcon from '../../../components/Common/CustomIcons/CustomUpdateIcon';
import { CustomDatePresets } from '../../sqlsource/report/utils/datePresets';
import { DEFAULT_DATE_FORMAT } from '@/constants';
import UserSearchV2 from '@/components/Common/CustomSearch/Users';
import { useFilterStore } from '@/store/zustand';

const color = {
  Rejected: 'red',
  Pending: '#B7950B',
  'In progress': 'green',
  Done: '#5D6D7E'
};

const backgroundColor = {
  Rejected: '#F9EBEA',
  Pending: '#FEF9E7',
  'In progress': '#EAFAF1',
  Done: '#EAECEE'
};

const colorCode: any = {
  Packed: 'blue',
  pending: 'blue',
  Delivered: 'green',
  delivered: 'green',
  Assigned: '#ffad46',
  assigned: '#ffad46',
  Dispatched: 'purple',
  dispatched: 'purple'
};

const TasksList = () => {
  const [form] = Form.useForm();
  const { profileData } = useContext(AuthContext);
  const [tableData, setTableData] = useState<ITasks[]>([]);
  const navigate = useNavigate();
  const { Option } = Select;
  const updateTaskLog = useMutation(update_tasks_log);
  const [selectedProducts, setselectedProducts] = useState<any>([]);
  const loggedInUser = getUserData();

  const location = useLocation();
  const zustandFilter = useFilterStore();

  const [loading, setIsloading] = useState<boolean>(true);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [updateTask, setUpdateTask] = useState<any>();
  const [ismodalLoading, setismodalLoading] = useState<any>(false);

  async function getInfo(filter = '') {
    setIsloading(true);
    const response = await get_tasks_list_filter(0, 1000, filter);
    setTableData(response.data.results);
    setIsloading(false);
    return response.data.results;
  }

  const archiveTaskMutation = useMutation(archive_tasks, {
    onSuccess: () => {
      message.success('Task archived successfully');
      setIsloading(false);
      zustandFilter.refetchData(location.pathname, getInfo);
    },
    onError: (e: any) => {
      message.error(e.message);
      setIsloading(false);
    }
  });

  const handleOk = () => {
    setIsUpdateModalOpen(false);
  };

  const handleCancel = () => {
    setIsUpdateModalOpen(false);
  };

  const selectOptions = ['pending', 'delivered', 'assigned', 'dispatched'];

  const [responsize, setResponsize] = useState(false);
  useEffect(() => {
    if (window.innerWidth <= 650) {
      // setCollapsed(true);
      setResponsize(true);
    } else {
      setResponsize(false);
    }
    function handleResize() {
      if (window.innerWidth <= 650) {
        // setCollapsed(true);
        setResponsize(true);
      } else {
        setResponsize(false);
      }
    }
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  const breadcrumbItems = [
    {
      label: 'Tasks',
      link: '/tasks'
    }
  ];

  const columns = [
    {
      title: 'ID',
      key: 'id',
      // sorter: (a, b) => a.id - b.id,
      // sortOrder: sortedInfo.columnKey === 'id' ? sortedInfo.order : null,
      width: 30,
      render: (record: any) => {
        return <TableCell>{record.id}</TableCell>;
      }
    },
    {
      title: 'Reference',
      width: 100,
      key: 'Reference',
      className: 'invoice',
      // sorter: (a, b) => a.referenceNumber.localeCompare(b.referenceNumber),
      // sortOrder: sortedInfo.columnKey === 'referenceNumber' ? sortedInfo.order : null,
      render: (record: any) => {
        return <TableCell>{record.reference}</TableCell>;
      }
    },
    {
      title: 'Title',
      width: 100,
      key: 'Title',
      // sorter: (a, b) => a.userName.localeCompare(b.userName),
      // sortOrder: sortedInfo.columnKey === 'userName' ? sortedInfo.order : null,
      render: (record: any) => {
        return <TableCell>{record.name}</TableCell>;
      }
    },
    {
      title: 'Description',
      width: 100,
      key: 'description',
      // sorter: (a, b) => a.userName.localeCompare(b.userName),
      // sortOrder: sortedInfo.columnKey === 'userName' ? sortedInfo.order : null,
      render: (record: any) => {
        return <TableCell>{record.description}</TableCell>;
      }
    },

    {
      title: 'Due Date',
      width: 100,
      key: 'Due Date',
      render: (record: any) => {
        return (
          <TableCell>{convertLocalToUTCString(record.dueDate, DEFAULT_DATE_FORMAT)}</TableCell>
        );
      }
    },
    {
      title: 'Status',
      width: 100,
      key: 'status',
      render: (record: any) => {
        return (
          <Tag
            color={colorCode[record.status]}
            onClick={() => {
              if (record.status != 'Delivered') {
                setUpdateTask(record);
                setIsUpdateModalOpen(true);
              }
            }}>
            {record.status}
          </Tag>
        );
      }
    },
    {
      title: 'Actions',
      key: 'actions',
      width: 55,
      fixed: 'right',
      render: (record: any) => {
        const confirmFunction = () => {
          // deleteSellMutation.mutate(record.id);
        };
        const menuItems = [
          {
            key: '1',
            label: (
              <Tooltip title="Archive" color="blue">
                <div
                  onClick={async () => {
                    setIsloading(true);
                    await archiveTaskMutation.mutateAsync([record.id]);
                  }}>
                  Archive
                </div>
              </Tooltip>
            )
          }
        ];
        if (checkAccess('READ_CHANNEL')) {
          menuItems.push({
            key: '4',
            label: (
              <DiscussionChatModal
                slug="task"
                id={record.id}
                rreference="other"
                // fromSellorPurahcaseid={record.id}
              />
            )
          });
        }
        if (checkAccess('UPDATE_LOCATION')) {
          menuItems.push({
            key: '2',
            label: <CustomUpdateIcon link={`/tasks/${record.id}`} />
          });
        }
        const menu = <Menu items={menuItems} />;

        return <ActionDropdown menu={menu} />;
      }
    }
  ];

  const onSubmitFilter = async () => {
    setIsloading(true);
    const values = form.getFieldsValue();
    const filter = ConvertObjectToURL({
      startDate: convertLocalToUTCString(values.startDate),
      endDate: convertLocalToUTCString(values.endDate),
      userId: values.userId,
      status: values.status,
      isArchive: values.isArchive
    });
    return await getInfo(filter);
  };

  const updateTasksMutation = useMutation(update_tasks_mutation, {
    onSuccess: () => {
      message.success('Task updated successfully');
      if (profileData) {
        updateTaskLog.mutate({
          taskId: parseInt(updateTask.id as string),
          userId: profileData.id,
          operation: 'Task Details updated'
        });
      }
      setismodalLoading(false);
      handleCancel();
      zustandFilter.refetchData(location.pathname, getInfo);
    }
  });

  const handleStatusUpdate = async () => {
    setismodalLoading(true);
    delete updateTask.createdAt;
    delete updateTask.deletedAt;
    updateTasksMutation.mutate(updateTask);
  };

  const archiveSelectedProducts = async () => {
    setIsloading(true);
    const ids: string[] | number[] = selectedProducts.map((curr: any) => curr.id);
    await archiveTaskMutation.mutateAsync(ids);
  };
  // console.log('tabledata', tableData);
  // console.log('State-->', state);
  // console.log('update Task', updateTask);
  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      setselectedProducts(selectedRows);
    }
  };
  return (
    <>
      {updateTask && (
        <Spin spinning={ismodalLoading}>
          <Modal
            title="UpdateTaskStatus"
            visible={isUpdateModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={false}>
            <div className="grid grid-cols-2">
              <Select
                placeholder="Select Task Status"
                style={{ borderRadius: '10px' }}
                allowClear
                disabled={ismodalLoading}
                value={updateTask.status}
                onChange={(value) => {
                  setUpdateTask({
                    ...updateTask,
                    status: value
                  });
                }}
                dropdownMatchSelectWidth={false}>
                {selectOptions.map((value) => {
                  return (
                    <Select.Option value={value} key={value}>
                      {value}
                    </Select.Option>
                  );
                })}
              </Select>
            </div>
            <div className="flex justify-end gap-1 mt-3">
              <Button
                type="primary"
                style={{ borderRadius: '9px' }}
                onClick={handleStatusUpdate}
                disabled={ismodalLoading}>
                Update
              </Button>
              <Button style={{ borderRadius: '9px' }} onClick={handleCancel}>
                Cancel
              </Button>
            </div>
          </Modal>
        </Spin>
      )}
      <AppContent
        breadcrumbItems={breadcrumbItems}
        withfilter={true}
        button={
          <>
            <div>
              <TableFilter
                form={form}
                onInitialLoad={({ data }) => {
                  if (data) {
                    setTableData(data);
                    setIsloading(false);
                  }
                }}
                defaultValues={{
                  dateCustom: [...CustomDatePresets.Today],
                  value: '',
                  userId: loggedInUser.id,
                  isArchive: 0,
                  skip: 0,
                  count: 100
                }}
                initial={true}
                onSubmit={onSubmitFilter}
                styleforbuttons={'flex items-center justify-end'}
                style={
                  'grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3  xl:grid-cols-4 gap-2 items-center justify-center'
                }
                buttons={
                  <>
                    {checkAccess('CREATE_SELL') && (
                      <div>
                        <CustomButton
                          onClick={() => navigate('/tasks/new')}
                          text="Add"
                          backgroundColor="#1890ff"
                        />
                      </div>
                    )}
                    {selectedProducts.length > 0 && (
                      <CustomButton
                        onClick={() => archiveSelectedProducts()}
                        text="Archive Selected"
                        backgroundColor="#1890ff"
                      />
                    )}
                  </>
                }>
                <UserSearchV2 hasParentFormItem={false} name={'userId'} />
                <Form.Item name="status" label="Select Status">
                  <Select
                    placeholder="Select Task Status"
                    style={{ borderRadius: '10px' }}
                    allowClear
                    dropdownMatchSelectWidth={false}>
                    <Option value={''} key={'none'}>
                      All
                    </Option>
                    {selectOptions.map((value) => {
                      return (
                        <Select.Option value={value} key={value}>
                          {value}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                <Form.Item name="isArchive" label="Select">
                  <Select
                    placeholder="Select archive Status"
                    style={{ borderRadius: '10px' }}
                    allowClear
                    dropdownMatchSelectWidth={false}>
                    <Select.Option value={1}>Archived</Select.Option>
                    <Select.Option value={0}>Not Archived</Select.Option>
                  </Select>
                </Form.Item>
              </TableFilter>
            </div>
          </>
        }>
        <CustomizeTable
          form={form}
          columns={columns}
          data={tableData}
          notshowPagination={true}
          usersLoading={loading}
          customScroll={{ x: 1000, y: '65vh' }}
          rowSelection={rowSelection}
          tableName={'tasks-list'}
        />
      </AppContent>
    </>
  );
};

// Put the thing into the DOM!
export default TasksList;

function addOrUpdateState(newState: any, item: any, status: any) {
  const pendingSection = newState.find((item: any) => item.name === status);
  if (pendingSection) {
    pendingSection.tasks.push({
      id: JSON.stringify(item.id),
      title: item.name,
      description: item.description,
      dueDate: item.dueDate,
      status: item.status,
      assignee: item.assignee,
      reference: item.reference,
      referenceId: item.referenceId
    });
  } else {
    newState.push({
      name: status,
      tasks: [
        {
          id: JSON.stringify(item.id),
          title: item.name,
          description: item.description,
          dueDate: item.dueDate,
          status: item.status,
          assignee: item.assignee,
          reference: item.reference,
          referenceId: item.referenceId
        }
      ]
    });
  }
}
