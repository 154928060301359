import { Form, Image, Input, Menu, Spin, TableProps, Tooltip } from 'antd';
import { ColumnsType, SorterResult } from 'antd/lib/table/interface';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { IDailyRateTableData } from '@/services/daily-rate/types';
import { get_daily_rate_list_filter } from '@/services/daily-rate/queries';
import { ConvertObjectToURL } from '@/utils/converturl';
import CustomizeTable from '@/components/Common/CustomizeTable/CustomizeTable';
import CustomButton from '@/components/Common/CustomButton/CustomButton';
import { checkAccess } from '@/routes/acl';
import TableFilter from '@/components/FliterTable';
import AppContent from '@/components/Common/Content/Content';
import TableCell from '@/components/Common/CustomizeTable/CustomCell';
import moment from 'moment';
import { optionalDateSorter } from '@/utils/sorter.utils';
import { convertUTCStringtoLocalString } from '@/utils/convertToUTC';

import ActionDropdown from '@/components/Common/Dropdownactions';
import { EditOutlined } from '@ant-design/icons';
import { DEFAULT_DATE_FORMAT } from '@/constants';
import { getImagesFromServer } from '@/services/upload/queries';

const DailyRateList = () => {
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(100);
  const [rateList, setRateList] = useState<IDailyRateTableData[]>([]);
  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({});
  const handleChange: TableProps<any>['onChange'] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<any>);
  };

  const breadcrumbItems = [{ label: 'Daily Rate', link: '/daily-rate' }];

  const onSubmitFilter = async (val: string) => {
    const data = await getInfo(val);
    setPage(1);
    setSize(100);
    return data;
  };

  const getInfo = async (filter = '') => {
    setIsLoading(true);
    const response = await get_daily_rate_list_filter(filter);

    const data = response.results;
    const mediaIdsPerDay = data.map((val) => [val.b2bMediaId, val.outletMediaId]);
    const allMediaIds = mediaIdsPerDay.flat().filter((val) => !!val) as number[];
    const uniqueMediaIds = Array.from(new Set(allMediaIds));

    const allImages = await getImagesFromServer(uniqueMediaIds);
    const tableData = data.map((dailyRate) => {
      const b2bImageURL = allImages.find((val) => val.id === dailyRate.b2bMediaId)?.url || '';
      const outletImageURL = allImages.find((val) => val.id === dailyRate.outletMediaId)?.url || '';

      return { ...dailyRate, b2bImageURL, outletImageURL };
    });

    setRateList(tableData);
    setIsLoading(false);
    return tableData;
  };

  const onPagination = async (pageNo = 1, totalSize = 100, isSize = false) => {
    setIsLoading(true);
    const values = form.getFieldsValue();
    if (isSize) {
      values.skip = 0;
      values.count = totalSize;
      setPage(1);
      setSize(totalSize);
    } else {
      values.skip = (pageNo - 1) * totalSize;
      values.count = totalSize;
      setPage(pageNo);
    }
    const url = ConvertObjectToURL(values);
    return await getInfo(url);
  };

  const columns: ColumnsType<IDailyRateTableData> = [
    {
      title: 'S.N',
      key: 'SN',
      width: 5,
      render: (txt, record, index) => {
        return <TableCell>{(page - 1) * size + (index + 1)}</TableCell>;
      }
    },
    {
      title: 'Preview',
      key: 'image',
      children: [
        {
          title: 'B2B',
          dataIndex: 'b2bImageURL',
          width: 10,
          render: (img) => {
            if (!img) return;
            return (
              <TableCell>
                <Image width={100} src={img} />
              </TableCell>
            );
          }
        },
        {
          title: 'Outlet',
          dataIndex: 'outletImageURL',
          width: 10,
          render: (img) => {
            if (!img) return;
            return (
              <TableCell>
                <Image width={100} src={img} />
              </TableCell>
            );
          }
        }
      ]
    },
    {
      title: 'Date',
      key: 'date',
      width: 15,
      sorter: (a, b) => optionalDateSorter(a.date, b.date),
      sortOrder: sortedInfo.columnKey === 'date' ? sortedInfo.order : null,
      render: (a, record) => {
        return (
          <>
            <TableCell>{convertUTCStringtoLocalString(record.date, 'YYYY-MM-DD')}</TableCell>
          </>
        );
      }
    },
    {
      title: 'Created Date',
      key: 'createdAt',
      width: 15,
      sorter: (a, b) => optionalDateSorter(a.createdAt, b.createdAt),
      sortOrder: sortedInfo.columnKey === 'createdAt' ? sortedInfo.order : null,
      render: (a, record) => {
        return (
          <>
            <TableCell>
              {convertUTCStringtoLocalString(record.createdAt, DEFAULT_DATE_FORMAT)}
            </TableCell>
          </>
        );
      }
    },
    {
      title: 'Action',
      key: 'actions',
      width: 5,
      fixed: 'right',
      render: (a, record) => {
        const menuItems: {
          key: string;
          label: JSX.Element;
          onClick?: () => void;
        }[] = [];

        if (checkAccess('UPDATE_DAILY_RATE')) {
          menuItems.push({
            key: '1',
            label: (
              <Tooltip title="Update" color="blue">
                <Link to={`/daily-rate/${record.id}`}>
                  <EditOutlined style={{ transform: 'scale(1.4)', width: '100%' }} />
                </Link>
              </Tooltip>
            )
          });
        }

        const menu = <Menu items={menuItems} />;
        return <ActionDropdown menu={menu} />;
      }
    }
  ];

  return (
    <Spin spinning={isLoading}>
      <AppContent
        breadcrumbItems={breadcrumbItems}
        withfilter={false}
        button={
          <>
            <div>
              <TableFilter
                onInitialLoad={({ data, pagination }) => {
                  setPage(pagination.page);
                  setSize(pagination.size);

                  if (data) {
                    setRateList(data);
                    setIsLoading(false);
                  }
                }}
                defaultValues={{
                  dateCustom: [moment(0, 'HH'), moment(0, 'HH').add(1, 'days')],
                  value: '',
                  skip: 0,
                  count: 100
                }}
                initial={true}
                onSubmit={onSubmitFilter}
                form={form}
                style={
                  'grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3  xl:grid-cols-4 gap-2 items-center justify-center'
                }
                styleforbuttons={'flex justify-end items-center'}
                buttons={
                  <>
                    {checkAccess('CREATE_DAILY_RATE') && (
                      <div>
                        <CustomButton
                          onClick={() => navigate('/daily-rate/new')}
                          text="Add"
                          backgroundColor="#1890ff"
                          Linkto="/daily-rate/new"
                        />
                      </div>
                    )}
                  </>
                }>
                <Form.Item name="value" label="Search">
                  <Input placeholder="Search" />
                </Form.Item>
              </TableFilter>
            </div>
            {/* </div> */}
          </>
        }>
        <CustomizeTable
          form={form}
          columns={columns}
          data={rateList}
          notshowPagination={true}
          customScroll={{ x: 600, y: '75vh' }}
          paginationDatas={{
            page: page,
            total: rateList.length,
            size: size,
            onPagination
          }}
          toSort={handleChange}
          tableName={'daily-rate-list'}
        />
      </AppContent>
    </Spin>
  );
};

export default DailyRateList;
