import { useEffect, useRef, useState } from 'react';

interface Props {
  initialState?: boolean;
  closeOnTriggered?: boolean;
  onClickOutside?: () => void;
}

function useToggle({ initialState = false, closeOnTriggered = true, ...handlers }: Props) {
  const [isOpen, setIsOpen] = useState(initialState);
  const parentRef = useRef<HTMLDivElement | null>(null);
  const triggerRef = useRef<HTMLElement | null>(null);

  const onToggle = () => setIsOpen((prev) => !prev);
  const onOpen = (value: boolean) => setIsOpen(value);

  function handleKeyboardEvent(key: string) {
    if (isOpen && key === 'Enter') {
      setTimeout(() => {
        triggerRef.current?.click();
        if (closeOnTriggered) setIsOpen(false);
      }, 100);
    }
    if (key === 'Escape') onToggle();
  }

  useEffect(() => {
    function handleGlobalKeyDown(event: KeyboardEvent) {
      handleKeyboardEvent(event.key);
    }

    document.addEventListener('keydown', handleGlobalKeyDown);
    return () => document.removeEventListener('keydown', handleGlobalKeyDown);
  }, [isOpen]);

  useEffect(() => {
    const parentElement = parentRef.current;
    if (!parentElement) return;

    function handleKeyDown(event: KeyboardEvent) {
      event.stopPropagation();
      const target = event.target as HTMLElement;
      const isDropdownOpen = target.closest('.ant-select-open');

      handlers.onClickOutside?.();

      if (isDropdownOpen) return;
      handleKeyboardEvent(event.key);
    }

    parentElement.addEventListener('keydown', handleKeyDown, true);
    return () => parentElement.removeEventListener('keydown', handleKeyDown, true);
  }, [isOpen]);

  return { isOpen, parentRef, triggerRef, onOpen, onToggle };
}

export default useToggle;
