import { IProductDetails } from '@/services/products/types';
import { sync_woo_products_metadata } from '@/services/woocommerce/mutation';
import { formatToGrid } from '@/services/woocommerce/services';
import { IProductRow } from '@/services/woocommerce/types';
import getErrorMessage from '@/utils/getError';
import { SocketEvents, SystemNotificationType } from '@/constants/websocketConfig';
import { WebSocketContext } from '@/contexts/websocket.context';
import { IWooCommerceSocketData } from '@/services/web-sockets/types';

import { useMutation } from '@tanstack/react-query';
import { Button, message, Modal, PageHeader } from 'antd';
import { useEffect, useRef, useState, useContext } from 'react';
import { Column, DataGridHandle, RenderCellProps } from 'react-data-grid';
import { emptyRow } from '../constant';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import ProductSearchForNonForm from '@/components/Common/CustomSearch/Products/ProductSearchFoNonForm';
import ReactDataGrid, { ReadOnlyInput } from '@/components/Common/DataGrid';
import { useNavigate } from 'react-router-dom';
import { ListPage } from '@/constants/list.enum';
import { get_woo_all_products_list } from '@/services/woocommerce/queries';
import CustomErrorModal from '@/components/Common/CustomErrorModal';
import ProductsDB from '@/store/localstorage/ProductsDB';
import { getProductById } from '@/services';
import { useFilterStore } from '@/store/zustand';
import { getImageFromServer } from '@/services/upload/queries';
import useCache from '@/hooks/useCache';
import PreviewImageOnDemand from '@/components/Common/PreviewImageOnDemand';
import ProductSyncModal from '../ProductSyncModal';

interface Props {
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

function SyncMetadata({ setIsLoading }: Props) {
  const navigate = useNavigate();
  const zustandFilter = useFilterStore();
  const gridRef = useRef<DataGridHandle>(null);
  const [rows, setRows] = useState<IProductRow[]>([]);
  const [openSubmitModal, setOpenSubmitModal] = useState(false);

  const syncProductsMetadata = useMutation(sync_woo_products_metadata);
  const { get: getCacheMedias } = useCache(getImageFromServer);

  const { socket } = useContext(WebSocketContext);
  const [wooData, setWooData] = useState<IWooCommerceSocketData | null>(null);
  const [modalVisible, setModalVisible] = useState(false);

  function getTotalItems() {
    const lastIndex = rows.length - 1;
    const lastRow = lastIndex >= 0 ? rows[lastIndex] : ({} as IProductRow);
    const isRowFilled = Boolean(lastRow.id && lastRow.name);

    // If last row is not filled, exclude it
    const updatedRows = isRowFilled ? rows : rows.slice(0, -1);
    return updatedRows.length;
  }

  const handleModalClose = () => {
    setModalVisible(false);
    if (!wooData) return;

    const totalItems = getTotalItems();
    const allProcessed =
      wooData?.messages.success.length + wooData?.messages.error.length === totalItems;

    const noFailures = wooData?.messages.error.length === 0;

    if (allProcessed && noFailures) {
      navigate(ListPage.WOO_PRODUCT_HISTORY);
      zustandFilter.resetState(ListPage.WOO_PRODUCT_HISTORY);
    }
  };

  useEffect(() => {
    if (rows.length === 0) {
      addEmptyRow();
    }
  }, []);

  useEffect(() => {
    const handler = (data: { event: string; data: unknown }) => {
      if (data.event === SystemNotificationType.WOO_PRODUCTS) {
        setIsLoading(true);
        const response = data.data as IWooCommerceSocketData;
        setWooData(response);
        setModalVisible(true); // open modal when update starts
      }
    };

    socket?.on(SocketEvents.USER_NOTIFICATION, handler);
    return () => {
      socket?.off(SocketEvents.USER_NOTIFICATION, handler);
    };
  }, [socket?.connected]);

  function addEmptyRow() {
    setRows((prevRows) => [...prevRows, emptyRow]);
  }

  const deleteRow = (productId?: number) => {
    setRows((prev) => {
      const filteredRows = prev.filter((row) => row.id !== productId);
      return filteredRows;
    });
  };

  async function onDeleteProduct(event: React.MouseEvent, props: RenderCellProps<IProductRow>) {
    event.stopPropagation();
    if (props.row.id === undefined) {
      return deleteRow();
    }

    Modal.confirm({
      title: 'Delete Product',
      content: (
        <div>
          Are you sure you want to remove <span className="text-red-500">{props.row.name}</span>{' '}
          product from this list?
        </div>
      ),
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: () => {
        deleteRow(props.row.id);
      }
    });
  }

  async function onProductChange(
    productId: number,
    product: IProductDetails,
    props: RenderCellProps<IProductRow>
  ) {
    try {
      setIsLoading(true);
      if (!productId) {
        return message.error('Please select a product first.');
      }

      const defaultUnit = product.productUnits.find((u) => u.isDefault);
      const currentUnitId = defaultUnit?.unitId;

      if (!currentUnitId) {
        message.error('Selected product has no default unit.');
        props.onRowChange({ ...props.row, name: '', id: undefined });
        return;
      }

      const { rows } = await formatToGrid([product]);
      const productRow = rows[0];
      productRow.priority = props.rowIdx + 1;

      props.onRowChange(productRow);

      const lastRow = rows[rows.length - 1];
      const isLastRowFull = Boolean(lastRow.id && lastRow.name);
      if (isLastRowFull || props.rowIdx === rows.length - 1) {
        addEmptyRow();
      }
    } catch (error) {
      getErrorMessage(error, true);
    } finally {
      setIsLoading(false);
    }
  }

  const columnsWithActions: Column<IProductRow>[] = [
    {
      key: 'sn',
      name: '',
      frozen: true,
      width: 45,
      minWidth: 45,
      renderCell(props) {
        return <strong>{props.rowIdx + 1}</strong>;
      }
    },
    {
      key: 'delete',
      name: '',
      frozen: true,
      width: 45,
      minWidth: 45,
      cellClass: 'p-0',
      renderCell(props) {
        return (
          <div
            onClick={(event) => onDeleteProduct(event, props)}
            className="flex justify-center items-center cursor-pointer h-full">
            <DeleteOutlined style={{ fontSize: 16 }} className="!text-red-500 [&>svg]:!mt-0" />
          </div>
        );
      }
    },
    {
      key: 'name',
      name: 'Product',
      minWidth: 200,
      cellClass: 'p-0',
      headerCellClass: 'text-wrap-header',
      renderCell(props) {
        return (
          <div onClick={(e) => e.stopPropagation()} className="w-full">
            <ProductSearchForNonForm
              className="w-full"
              allowClear={false}
              defaultValue={props.row.id}
              onSelect={async (productId, product) => {
                const doesExist = rows.some((row) => row.id === productId);
                if (doesExist) {
                  message.destroy();
                  message.error('Product already added');
                  props.onRowChange(emptyRow);
                  throw new Error('Product already added');
                }

                await onProductChange(productId, product, props);
              }}
            />
          </div>
        );
      }
    },
    {
      key: 'unitName',
      name: 'Unit',
      cellClass: 'p-0',
      minWidth: 100,
      headerCellClass: 'text-wrap-header',
      renderCell: (props) => <ReadOnlyInput value={props.row.unitName} />
    },
    {
      key: 'categoryName',
      name: 'Category',
      minWidth: 100,
      headerCellClass: 'text-wrap-header',
      cellClass: 'p-0',
      renderCell: (props) => <ReadOnlyInput value={props.row.categoryName} />
    },
    {
      key: 'sku',
      name: 'SKU',
      minWidth: 75,
      headerCellClass: 'text-wrap-header',
      cellClass: 'p-0',
      renderCell: (props) => <ReadOnlyInput value={props.row.sku} />
    },
    {
      key: 'imgId',
      name: 'Media',
      width: 45,
      headerCellClass: 'text-wrap-header',
      cellClass: 'p-0',
      renderCell: ({ row }) => {
        return (
          <PreviewImageOnDemand
            setParentLoading={setIsLoading}
            mediaId={row.imgId}
            fetcher={getCacheMedias}
          />
        );
      }
    }
  ];

  function handleNewRow() {
    const lastIndex = rows.length - 1;
    const lastRow = lastIndex >= 0 ? rows[lastIndex] : ({} as IProductRow);
    const isRowFilled = Boolean(lastRow.id && lastRow.name);

    if (!isRowFilled && rows.length > 0) {
      message.destroy();
      message.error('Please fill current row before adding new one');
      return;
    }

    addEmptyRow();

    requestAnimationFrame(() => {
      if (gridRef.current?.element) {
        gridRef.current.element.scrollTo({
          top: gridRef.current.element.scrollHeight,
          behavior: 'smooth'
        });
      }
    });
  }

  function handleSubmitClick(type: 'check' | 'save') {
    const lastIndex = rows.length - 1;
    const lastRow = lastIndex >= 0 ? rows[lastIndex] : ({} as IProductRow);
    const isRowFilled = Boolean(lastRow.id && lastRow.name);

    // If last row is not filled, exclude it
    const updatedRows = isRowFilled ? rows : rows.slice(0, -1);
    if (updatedRows.length === 0) {
      message.destroy();
      message.error('Please fill atleast one row');
      return;
    }

    const someRowEmpty = updatedRows.some((row) => !row.id || !row.name);
    if (someRowEmpty) {
      message.destroy();
      message.error('Please fill all rows or remove empty rows');
      return;
    }

    setOpenSubmitModal(type === 'check');
    return updatedRows;
  }

  async function onLoadWooCommerce() {
    try {
      setIsLoading(true);
      const wooProducts = await get_woo_all_products_list();
      if (!wooProducts) {
        return CustomErrorModal({
          message: 'No Products Found!. You have to manually add products to update their details.'
        });
      }

      const allProductIds = wooProducts.map((a) => a.erpProdId).filter(Boolean);
      await ProductsDB.addProductsIfAbsent(allProductIds);

      const productList: IProductDetails[] = [];
      for (const productId of allProductIds) {
        const product = await getProductById(productId);
        productList.push(product);
      }

      const { rows } = await formatToGrid(productList);
      setRows(rows);
    } catch (error) {
      getErrorMessage(error, true);
    } finally {
      setIsLoading(false);
    }
  }

  async function onFinish() {
    try {
      setIsLoading(true);
      const updatedRows = handleSubmitClick('save');
      if (!updatedRows) {
        setIsLoading(false);
        return;
      }

      const payload = updatedRows.map((row) => ({
        productId: row.id as number,
        unitId: row.unitId
      }));

      await syncProductsMetadata.mutateAsync({ products: payload });
      message.success(`Processing started for ${payload.length} product(s)`);
    } catch (error) {
      setIsLoading(false);
      getErrorMessage(error, true);
    }
  }

  async function onRetry(failedProducts: IProductDetails[]) {
    try {
      setIsLoading(true);
      if (!failedProducts.length) {
        message.error('No failed products found.');
        return;
      }

      // Only get rows of failed products
      const failedProductIds = failedProducts.map((a) => Number(a.id)).filter(Boolean);
      const failedRows = rows.filter((row) => failedProductIds.includes(row.id as number));
      const payload = failedRows.map((row) => ({
        productId: row.id as number,
        unitId: row.unitId
      }));

      setWooData(null);
      await syncProductsMetadata.mutateAsync({ products: payload });
      message.success(`Processing started for ${payload.length} product(s)`);
    } catch (error) {
      getErrorMessage(error, true);
    } finally {
      setOpenSubmitModal(false);
    }
  }

  return (
    <div>
      <Modal visible={openSubmitModal} onCancel={() => setOpenSubmitModal(false)} onOk={onFinish}>
        Do you want to sync metadata for these products?
      </Modal>

      <ProductSyncModal
        socketData={wooData}
        visible={modalVisible}
        getTotalItems={getTotalItems}
        onClose={handleModalClose}
        setLoading={setIsLoading}
        onRetry={onRetry}
        showTotalItems
      />

      <div className="mt-2">
        <PageHeader
          subTitle="Sync Metadata"
          style={{ padding: '8px 0' }}
          extra={
            <div>
              <Button onClick={onLoadWooCommerce} type="primary" className="!rounded-md">
                Load WooCommerce Products
              </Button>
            </div>
          }
        />
        <p>
          <strong>Note:</strong> The given products' metadata will be updated. They must have a
          default <code>unitId</code>. You can also load products available in woocommerce to update
          their metadata in one click.
        </p>
      </div>

      <div className="mt-2 space-y-2">
        <ReactDataGrid
          ref={gridRef}
          rows={rows}
          columns={columnsWithActions}
          onRowsChange={setRows}
          emptyRowFallback="No products have been added yet."
        />

        <Button type="dashed" onClick={handleNewRow} block style={{ width: '100%' }}>
          <PlusOutlined /> Add field
        </Button>

        <div className="mt-2.5 flex justify-end gap-2.5">
          <Button type="primary" onClick={() => handleSubmitClick('check')} disabled={!rows.length}>
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
}

export default SyncMetadata;
