import CustomUpdateIcon from '@/components/Common/CustomIcons/CustomUpdateIcon';
import GenericTable from '@/components/Common/CustomizeTable';
import ActionDropdown from '@/components/Common/Dropdownactions';
import { DEFAULT_DATE_FORMAT } from '@/constants';
import { checkAccess, isAdmin } from '@/routes/acl';
import { ILeafyItem, ILeafyItemList } from '@/services/leafy-items/types';
import { find_locationId_preference } from '@/store/localstorage/preferences';
import { convertUTCStringtoLocalString } from '@/utils/convertToUTC';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';
import { FormInstance, Menu, TableProps, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { SorterResult } from 'antd/lib/table/interface';
import { useState } from 'react';
import ExportLeafy from './ExportLeafy';

interface Props {
  isLoading?: boolean;
  data: ILeafyItemList;
  form: FormInstance;
  pagination: { page: number; size: number };
  fullExportURL: string;
  filterBy: 'exact' | 'range';
  getInfo: (filter?: string, isExport?: boolean) => Promise<ILeafyItemList | undefined>;
  onPagination: (
    page?: number,
    size?: number,
    isSize?: boolean
  ) => Promise<{ data: ILeafyItemList | undefined; fullExportURL: string }>;
}

function Table({ isLoading, data, pagination, form, fullExportURL, filterBy, ...handlers }: Props) {
  const [sortedInfo, setSortedInfo] = useState<SorterResult<ILeafyItem>>({});
  const locationId = find_locationId_preference();
  const uniqueLocationId = Array.from(new Set(data.result.map((u) => u.locationId)));

  const showUpdate = uniqueLocationId.length === 1 ? uniqueLocationId[0] === locationId : true;
  const isActionShown = checkAccess('UPDATE_LEAFY_ITEM') && showUpdate;

  const handleChange: TableProps<ILeafyItem>['onChange'] = (_, __, s) => {
    setSortedInfo(s as SorterResult<ILeafyItem>);
  };

  const columns: ColumnsType<ILeafyItem> = [
    {
      title: 'S.N.',
      key: 'id',
      width: 5,
      render: (_, __, index) => (pagination.page - 1) * pagination.size + index + 1
    },
    {
      title: 'Date',
      key: 'date',
      dataIndex: 'date',
      width: 10,
      sorter: (a, b) => a.date.localeCompare(b.date),
      sortOrder: sortedInfo.columnKey === 'date' ? sortedInfo.order : null
    },
    {
      title: 'Location',
      key: 'location',
      dataIndex: 'location',
      width: 20,
      sorter: (a, b) => a.location.localeCompare(b.location),
      sortOrder: sortedInfo.columnKey === 'location' ? sortedInfo.order : null
    },
    {
      title: 'Purchase',
      children: [
        {
          title: 'System',
          key: 'systemPurchaseTotal',
          dataIndex: 'systemPurchaseTotal',
          width: 10,
          sorter: (a, b) => a.systemPurchaseTotal - b.systemPurchaseTotal,
          sortOrder: sortedInfo.columnKey === 'systemPurchaseTotal' ? sortedInfo.order : null,
          render: (v) => nepaliNumberFormatter(v)
        },
        {
          title: 'User',
          key: 'userPurchaseTotal',
          dataIndex: 'userPurchaseTotal',
          width: 10,
          sorter: (a, b) => a.userPurchaseTotal - b.userPurchaseTotal,
          sortOrder: sortedInfo.columnKey === 'userPurchaseTotal' ? sortedInfo.order : null,
          render: (v) => nepaliNumberFormatter(v)
        },
        {
          title: 'Status',
          key: 'status',
          width: 10,
          render: (_, record) => {
            const isChanged = record.userPurchaseTotal !== record.systemPurchaseTotal;
            return <Tag color={isChanged ? 'red' : 'green'}>{isChanged ? 'Changed' : 'Same'}</Tag>;
          }
        }
      ]
    },
    {
      title: 'Wastage',
      children: [
        {
          title: 'System',
          key: 'systemWastageTotal',
          dataIndex: 'systemWastageTotal',
          width: 10,
          sorter: (a, b) => a.systemWastageTotal - b.systemWastageTotal,
          sortOrder: sortedInfo.columnKey === 'systemWastageTotal' ? sortedInfo.order : null,
          render: (v) => nepaliNumberFormatter(v)
        },
        {
          title: 'User',
          key: 'userWastageTotal',
          dataIndex: 'userWastageTotal',
          width: 10,
          sorter: (a, b) => a.userWastageTotal - b.userWastageTotal,
          sortOrder: sortedInfo.columnKey === 'userWastageTotal' ? sortedInfo.order : null,
          render: (v) => nepaliNumberFormatter(v)
        },
        {
          title: 'Status',
          key: 'status',
          width: 10,
          render: (_, record) => {
            const isChanged = record.userWastageTotal !== record.systemWastageTotal;
            return <Tag color={isChanged ? 'red' : 'green'}>{isChanged ? 'Changed' : 'Same'}</Tag>;
          }
        }
      ]
    },
    {
      title: 'Created By',
      key: 'createdBy',
      width: 15,
      dataIndex: 'createdByName',
      sorter: (a, b) => a.createdByName.localeCompare(b.createdByName),
      sortOrder: sortedInfo.columnKey === 'createdByName' ? sortedInfo.order : null
    },
    {
      title: 'Last Updated',
      key: 'lastUpdated',
      width: 15,
      dataIndex: 'updatedAt',
      sorter: (a, b) => a.updatedAt.localeCompare(b.updatedAt),
      sortOrder: sortedInfo.columnKey === 'lastUpdated' ? sortedInfo.order : null,
      render: (_, record) => convertUTCStringtoLocalString(record.updatedAt, DEFAULT_DATE_FORMAT)
    }
  ];

  if (isActionShown) {
    columns.push({
      title: 'Actions',
      key: 'actions',
      width: 6,
      fixed: 'right',
      render: (record: ILeafyItem) => {
        if (!isAdmin() && record.locationId !== locationId) return null;
        return (
          <ActionDropdown
            menu={
              <Menu
                items={[
                  {
                    key: 'update',
                    label: <CustomUpdateIcon link={`/leafy-items/details/${record.id}`} />
                  }
                ]}
              />
            }
          />
        );
      }
    });
  }

  return (
    <div>
      <GenericTable
        form={form}
        columns={columns}
        data={data.result}
        isLoading={isLoading}
        scroll={{ x: isActionShown ? 1600 : 1300, y: '75vh' }}
        hideDefaultPagination
        buttons={
          <ExportLeafy
            data={data}
            filterBy={filterBy}
            fullExportURL={fullExportURL}
            getInfo={handlers.getInfo}
          />
        }
        generateSummary
        summaryClassName="text-left"
        tableName={'leafy-item-list'}
        pagination={{
          ...pagination,
          total: data.total,
          onPagination: handlers.onPagination,
          scrollToTop: true
        }}
        toSort={handleChange}
      />
    </div>
  );
}

export default Table;
