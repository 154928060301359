import AppContent from '@/components/Common/Content/Content';
import { get_hr_shift_list } from '@/services/hr/queries';
import { IHRShiftList } from '@/services/hr/types';
import { ConvertObjectToURL } from '@/utils/converturl';
import getErrorMessage from '@/utils/getError';
import handlePagination from '@/utils/handlePagination';
import { Form, Input } from 'antd';
import { useState } from 'react';
import Table from './Table';
import TableFilter from '@/components/FliterTable';
import moment from 'moment';
import CustomButton from '@/components/Common/CustomButton/CustomButton';
import { checkAccess } from '@/routes/acl';
import UserSearchV2 from '@/components/Common/CustomSearch/Users';
import { ShiftsDB } from '@/store/localstorage/ShiftDB';
import { HRDatePresets } from '@/pages/sqlsource/report/utils/datePresets';

function HRShiftList() {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(true);
  const [pagination, setPagination] = useState({ page: 1, size: 100 });
  const [data, setData] = useState<IHRShiftList>({ total: 0, results: [] });

  async function onSubmitFilter(filter: string) {
    const data = await getInfo(filter);
    setPagination({ page: 1, size: 100 });
    return data;
  }

  async function getInfo(filter = '') {
    try {
      setIsLoading(true);
      const response = await get_hr_shift_list(filter);
      await ShiftsDB.addShifts(response.results);
      setData(response);
      return response;
    } catch (error) {
      getErrorMessage(error, true);
    } finally {
      setIsLoading(false);
    }
  }

  const onPagination = async (page = 1, size = 100, isSize = false) => {
    setIsLoading(true);
    const { values, ...pagination } = handlePagination({
      formValues: form.getFieldsValue(),
      page,
      size,
      isSize
    });

    setPagination((prev) => {
      prev.page = pagination.page;
      if (isSize) prev.size = pagination.size;
      return prev;
    });

    const url = ConvertObjectToURL(values);
    return await getInfo(url);
  };

  return (
    <div>
      <AppContent
        breadcrumbItems={[{ label: 'HR', link: '/hr' }, { label: 'Shift' }]}
        withfilter
        button={
          <TableFilter
            initial
            form={form}
            datePresets={HRDatePresets}
            onInitialLoad={({ data, pagination }) => {
              setPagination(pagination);

              if (data) {
                setData(data);
                setIsLoading(false);
              }
            }}
            buttons={
              <>
                {checkAccess('CREATE_HR_SHIFT') && (
                  <CustomButton text="Add" backgroundColor="#1890ff" Linkto="/hr/shift/new" />
                )}
              </>
            }
            onSubmit={onSubmitFilter}
            defaultValues={{
              dateCustom: [moment(0, 'HH'), moment(0, 'HH').add(1, 'days')],
              value: '',
              skip: 0,
              count: 100,
              userId: ''
            }}
            styleforbuttons={'flex justify-end items-center'}>
            <UserSearchV2 hasParentFormItem={false} name="userId" label="Employee" showAll />
            <Form.Item name="value" label="Name">
              <Input />
            </Form.Item>
          </TableFilter>
        }>
        <Table
          form={form}
          data={data}
          isLoading={isLoading}
          pagination={pagination}
          onPagination={onPagination}
        />
      </AppContent>
    </div>
  );
}

export default HRShiftList;
