import { DatePicker, Form, Input, InputNumber, TableProps } from 'antd';
import { RangePickerProps } from 'antd/lib/date-picker';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { CustomDatePresets } from '../utils/datePresets';
import CustomReport from './report';
import { SorterResult } from 'antd/lib/table/interface';
import { nepaliNumberFormatter, numberDecimalFormatter } from '@/utils/numberFormatter';
import {
  convert_nepali_to_english_date,
  convert_string_to_nepali_date_string
} from '@/utils/nepaliDateConverter';
import { find_date_preference } from '@/store/localstorage/preferences';
import { NepaliDatePicker } from 'nepali-datepicker-reactjs';
import { getUserData } from '@/utils/auth.utils';
import LocationSearchV2 from '@/components/Common/CustomSearch/Location';
import ProductSearchV2 from '@/components/Common/CustomSearch/Products';
import { DEFAULT_DATE_FORMAT } from '@/constants';
import { IOpeningStockReport } from '@/services/report/types';

const { RangePicker } = DatePicker;

const OpeningStockReport = () => {
  const [form] = Form.useForm();
  const [sortedInfo, setSortedInfo] = useState<SorterResult<IOpeningStockReport>>({});
  const handleChange: TableProps<IOpeningStockReport>['onChange'] = (_, __, sorter) => {
    setSortedInfo(sorter as SorterResult<IOpeningStockReport>);
  };
  const [nepaliType] = useState(find_date_preference());
  const { preferences } = getUserData();
  const preferenceLocationId = preferences?.preferences
    ? JSON.parse(preferences?.preferences)?.locationId
    : undefined;

  useEffect(() => {
    const nepaliDateStart = convert_string_to_nepali_date_string(
      CustomDatePresets.Today[0].format('YYYY-MM-DD HH:mm')
    );
    const nepaliDateEnd = convert_string_to_nepali_date_string(
      CustomDatePresets.Today[0].format('YYYY-MM-DD HH:mm')
    );
    form.setFieldValue('constraints', {
      page: 1,
      size: 100,
      locationId: preferenceLocationId ? preferenceLocationId : '',
      productId: '',
      reasonId: '',
      dateCustom: [...CustomDatePresets.Today],
      startDate: CustomDatePresets.Today[0].format('YYYY-MM-DD HH:mm'),
      endDate: CustomDatePresets.Today[1].format('YYYY-MM-DD HH:mm'),
      startDateNepali: nepaliDateStart,
      endDateNepali: nepaliDateEnd
    });
  }, []);

  const breadcrumbItems = [
    { label: 'Reports', link: '/reports' },
    { label: 'Opening Stock Report', link: '/reports/opening-stock' }
  ];

  const onChange: RangePickerProps['onChange'] = (dates, dateStrings) => {
    if (dateStrings.length == 2) {
      form.setFieldValue(['constraints', 'startDate'], dateStrings[0]);
      form.setFieldValue(['constraints', 'endDate'], dateStrings[1]);
    }
  };
  const columns: ColumnsType<IOpeningStockReport> = [
    {
      title: 'S.N',
      key: 'SN',
      render: (text, record, index) => {
        return <div color="black">{index + 1}</div>;
      },
      width: 50
    },
    {
      title: 'Product',
      key: 'product_name',
      sorter: (a, b) => a.product_name.localeCompare(b.product_name),
      sortOrder: sortedInfo.columnKey === 'product_name' ? sortedInfo.order : null,
      render: (record) => {
        return <div color="black">{record.product_name}</div>;
      },
      width: 150
    },
    {
      title: 'Location',
      key: 'location_name',
      sorter: (a, b) => a.location_name.localeCompare(b.location_name),
      sortOrder: sortedInfo.columnKey === 'location_name' ? sortedInfo.order : null,
      render: (record) => {
        return <div color="black">{record.location_name}</div>;
      },
      width: 100
    },
    {
      title: 'Unit',
      key: 'unit_name',
      sorter: (a, b) => a.unit_name.localeCompare(b.unit_name),
      sortOrder: sortedInfo.columnKey === 'unit_name' ? sortedInfo.order : null,
      render: (record) => {
        return <div>{record.unit_name}</div>;
      },
      width: 100
    },
    {
      title: 'Base Unit Quantity',
      key: 'base_unit_quantity',
      sorter: (a, b) => a.base_unit_quantity - b.base_unit_quantity,
      sortOrder: sortedInfo.columnKey === 'base_unit_quantity' ? sortedInfo.order : null,
      render: (record) => {
        return (
          <div className="text-right mr-2">{numberDecimalFormatter(record.base_unit_quantity)}</div>
        );
      },
      width: 100
    },
    {
      title: 'Quantity',
      key: 'quantity',
      dataIndex: 'quantity',
      sorter: (a, b) => parseFloat(a.quantity) - parseFloat(b.quantity),
      sortOrder: sortedInfo.columnKey === 'quantity' ? sortedInfo.order : null,
      render: (quantity: number) => {
        return <div className="text-right mr-2">{numberDecimalFormatter(quantity)}</div>;
      },
      width: 100
    },

    {
      title: 'Unit Purchase Price',
      key: 'unit_price',
      sorter: (a, b) => parseFloat(a.unit_price) - parseFloat(b.unit_price),
      sortOrder: sortedInfo.columnKey === 'unit_price' ? sortedInfo.order : null,
      render: (record) => {
        return <div className="text-right mr-2">{nepaliNumberFormatter(record.unit_price)}</div>;
      },
      width: 100
    },

    {
      title: 'Lot',
      key: 'lot_id',
      sorter: (a, b) => a.lot_id - b.lot_id,
      sortOrder: sortedInfo.columnKey === 'lot_id' ? sortedInfo.order : null,
      render: (record) => {
        return <div className="text-right mr-2">{numberDecimalFormatter(record.lot_id)}</div>;
      },
      width: 100
    },
    {
      title: 'Grade',
      key: 'grade',
      sorter: (a, b) => a.grade.localeCompare(b.grade),
      sortOrder: sortedInfo.columnKey === 'grade' ? sortedInfo.order : null,
      render: (a, record) => {
        return <div className="text-right mr-2">{record.grade}</div>;
      },
      width: 100
    },
    {
      title: 'Total Amount',
      key: 'total_amount',
      className: 'highlight',
      dataIndex: 'total_amount',
      sorter: (a, b) => parseFloat(a.total_amount) - parseFloat(b.total_amount),
      sortOrder: sortedInfo.columnKey === 'total_amount' ? sortedInfo.order : null,
      render: (total_amount) => {
        return <div className="text-right mr-2">{nepaliNumberFormatter(total_amount)}</div>;
      },
      width: 100
    },
    {
      title: 'Created By',
      key: 'created_by',
      sorter: (a, b) => a.created_at.localeCompare(b.created_at),
      sortOrder: sortedInfo.columnKey === 'created_by' ? sortedInfo.order : null,
      dataIndex: 'created_by',
      render: (createdBy: string) => {
        return <div className="text-right mr-2">{createdBy}</div>;
      },
      width: 100
    },
    {
      title: 'Created At',
      key: 'created_at',
      sorter: (a, b) => a.created_at.localeCompare(b.created_at),
      sortOrder: sortedInfo.columnKey === 'created_at' ? sortedInfo.order : null,
      dataIndex: 'created_at',
      render: (createdAt) => {
        return (
          <div className="text-right mr-2">{moment(createdAt).format(DEFAULT_DATE_FORMAT)}</div>
        );
      },
      width: 100
    }
  ];

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    // Can not select days before today and today
    return current && current > moment().endOf('day');
  };

  const onChangeNepali = (val: string, isStart: boolean) => {
    if (isStart)
      form.setFieldValue(['constraints', 'startDate'], convert_nepali_to_english_date(val));
    else form.setFieldValue(['constraints', 'endDate'], convert_nepali_to_english_date(val));
  };

  const locationId = Form.useWatch(['constraints', 'locationId'], form);

  return (
    <CustomReport
      toSort={handleChange}
      breadcrumbItems={breadcrumbItems}
      state={{ id: 57, name: 'Opening Stock Report' }}
      columnsData={columns}
      form={form}
      toDownload={true}>
      <Form.Item label={'page'} name={['constraints', 'page']} hidden={true}>
        <InputNumber controls={false} />
      </Form.Item>
      <Form.Item label={'size'} name={['constraints', 'size']} hidden={true}>
        <InputNumber controls={false} />
      </Form.Item>
      <LocationSearchV2 showAll hasParentFormItem={false} name={['constraints', 'locationId']} />
      <ProductSearchV2
        showAll
        hasParentFormItem={false}
        name={['constraints', 'productId']}
        locationId={locationId}
      />

      <Form.Item
        label={'Pick a date'}
        name={['constraints', 'dateCustom']}
        rules={[{ required: true, message: 'Please select date!' }]}
        hidden={nepaliType}>
        <RangePicker
          showTime
          ranges={CustomDatePresets}
          disabledDate={disabledDate}
          onChange={onChange}
          format={'YYYY-MM-DD HH:mm'}
          allowClear={false}
          className="w-full"
        />
      </Form.Item>
      <Form.Item label={'Start Date'} name={['constraints', 'startDate']} hidden>
        <Input />
      </Form.Item>
      <Form.Item label={'End Date'} name={['constraints', 'endDate']} hidden>
        <Input />
      </Form.Item>
      <Form.Item label={'Select Start Date'} name={['startDateNepali']} hidden={!nepaliType}>
        <NepaliDatePicker
          onChange={(val) => onChangeNepali(val, true)}
          options={{ calenderLocale: 'ne', valueLocale: 'en' }}
        />
      </Form.Item>
      <Form.Item label={'Select End Date'} name={['endDateNepali']} hidden={!nepaliType}>
        <NepaliDatePicker
          onChange={(val) => onChangeNepali(val, false)}
          options={{ calenderLocale: 'ne', valueLocale: 'en' }}
        />
      </Form.Item>
    </CustomReport>
  );
};

export default OpeningStockReport;
