import { useEffect } from 'react';

function useIgnoreGlobalTab() {
  useEffect(() => {
    const handleTab = (e: KeyboardEvent) => {
      if (e.key === 'Tab') {
        e.preventDefault();
      }
    };

    // Add event listener when component mounts
    document.addEventListener('keydown', handleTab);

    // Cleanup when component unmounts
    return () => {
      document.removeEventListener('keydown', handleTab);
    };
  }, []);
}

export default useIgnoreGlobalTab;
