import { Button, Form, Input, Menu, Spin, Table, TableProps, Tooltip, message } from 'antd';
import moment from 'moment';
import { useRef, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { ColumnsType, SorterResult } from 'antd/lib/table/interface';
import { getUserData } from '@/utils/auth.utils';
import CustomizeTable from '@/components/Common/CustomizeTable/CustomizeTable';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';
import { convertLocalToUTCString, convertUTCStringtoLocalString } from '@/utils/convertToUTC';
import { ConvertObjectToURL } from '@/utils/converturl';
import AppContent from '@/components/Common/Content/Content';
import TableCell from '@/components/Common/CustomizeTable/CustomCell';
import {
  get_account_details,
  get_journal_details_ids,
  get_journal_lines_list
} from '@/services/accounts/queries';
import { AccountType } from '@/services/accounts/enums';
import {
  IJournalLinesViewResponseTableData,
  IJournalListResponseData
} from '@/services/accounts/types';
import LocationsDB from '@/store/localstorage/LocationsDB';
import { get_location_list_for_ids } from '@/services/locations/queries';
import ActionDropdown from '@/components/Common/Dropdownactions';
import { ExportColumnType, exportExcel } from '@/utils/exportExcel';
import { CustomModal } from '@/components/Common/CustomModal';
import CustomTable from '@/components/Common/CustomResuableInvoice/CustomTable';
import { useReactToPrint } from 'react-to-print';
import { get_sell_lists_v2 } from '@/services/sell/queries';
import { get_purchase_list_filter } from '@/services/purchases/queries';
import { get_customer_vendor_by_userId } from '@/services/users/queries';
import AccountFilterTable from '@/components/AccountFilterTable/v2';
import { DEFAULT_DATE_FORMAT } from '@/constants';
import LocationSearchV2 from '@/components/Common/CustomSearch/Location';
import AccountSearchV2 from '@/components/Common/CustomSearch/Accounts/AccountSearchV2';

interface ISavedData {
  lines: IJournalLinesViewResponseTableData[];
  total: { totalCredit: number; totalDebit: number };
  exportData: IJournalLinesViewResponseTableData[];
}

const AccountsView = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [form] = Form.useForm();
  const { id } = useParams();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(100);
  const printPDFRef = useRef<any>();
  const [openModalForPdfExport, setOpenModalForPdfExport] = useState<boolean>(false);
  const [exportData, setExportData] = useState<IJournalLinesViewResponseTableData[]>([]);
  const [lines, setLines] = useState<IJournalLinesViewResponseTableData[]>([]);
  const [total, setTotal] = useState<{ totalCredit: number; totalDebit: number }>({
    totalCredit: 0,
    totalDebit: 0
  });
  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({});
  const handleChange: TableProps<any>['onChange'] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<any>);
  };

  const location = useLocation();

  const { preferences } = getUserData();
  const preferenceLocationId = preferences?.preferences
    ? JSON.parse(preferences?.preferences)?.locationId
    : undefined;

  const breadcrumbItems = [
    {
      label: 'Accounts',
      link: '/accounts'
    },
    {
      label: 'View',
      link: id ? '/accounts/view/:id' : '/accounts/view'
    }
  ];

  const onSubmitFilter = async (val: string) => {
    const data = await getInfo(val);
    setPage(1);
    setSize(100);
    return data;
  };

  const getInfo = async (filter = '') => {
    setIsLoading(true);
    const formAccountId = form.getFieldValue(['accountId']);
    let accountId;

    const savedData = {} as ISavedData;

    if (id) {
      accountId = id;
    } else if (formAccountId) {
      accountId = formAccountId;
    }

    if (accountId) {
      let totalCredit = 0;
      let totalDebit = 0;
      const responseAccountDetails = await get_account_details(parseInt(accountId));
      let tableData = [];
      if (responseAccountDetails) {
        const lines = form.getFieldsValue();
        const urlParams = new URLSearchParams(filter);
        const params = Object.fromEntries(urlParams);
        lines.skip = params.skip;
        lines.count = params.count;
        lines.endDate = convertLocalToUTCString(lines.endDate);
        lines.startDate = convertLocalToUTCString(lines.startDate);
        delete lines.dateCustom;
        delete lines.startDateNepali;
        delete lines.endDateNepali;
        const valuesForJournal = ConvertObjectToURL(lines);
        const responseJournal = await get_journal_lines_list(valuesForJournal);

        let allJournals: IJournalListResponseData[] = [];
        if (responseJournal.results.length > 0) {
          const Journals = await get_journal_details_ids([
            ...new Set(
              responseJournal.results.map((value) => {
                return value.journalId;
              })
            )
          ]);
          allJournals = Journals.results;
        }

        tableData = responseJournal.results.map((value) => {
          const journal = allJournals.find((journal) => journal.id == value.journalId);
          totalCredit += value.credit;
          totalDebit += value.debit;
          return {
            ...value,
            journalName: journal?.description,
            locationName: '',
            redirectId: value.accountId,
            ledgerType: 'JOURNAL'
          };
        });

        if (responseAccountDetails.type === AccountType.USER) {
          const getCustomerVendor = await get_customer_vendor_by_userId(
            responseAccountDetails.referenceId
          );

          if (getCustomerVendor.customer) {
            lines.customerId = getCustomerVendor.customer.id;
            const values = ConvertObjectToURL(lines);
            const responseSell = (await get_sell_lists_v2(values)).data;
            // console.log('responseSell', responseSell);
            if (responseSell.results.length > 0) {
              responseSell.results.forEach((sell) => {
                tableData.push({
                  ...sell,
                  journalName: `${sell.financialReference} (Sell)`,
                  debit: 0,
                  credit: sell.totalAmount,
                  locationName: '',
                  redirectId: sell.id,
                  ledgerType: 'SELL'
                });
                totalCredit += sell.totalAmount;
                if (sell.payments) {
                  if (sell.payments.length > 0) {
                    sell.payments.forEach((payment) => {
                      tableData.push({
                        ...payment,
                        journalName: `${payment.reference} (Payment)`,
                        debit: payment.amount,
                        credit: 0,
                        locationId: sell.locationId,
                        locationName: '',
                        redirectId: sell.id,
                        ledgerType: 'SELL'
                      });
                      totalDebit += payment.amount;
                    });
                  }
                }
              });
            }
          }

          if (getCustomerVendor.vendor) {
            lines.vendorId = getCustomerVendor.vendor.id;
            const values = ConvertObjectToURL(lines);
            const responsePurchase = (await get_purchase_list_filter(values)).data;
            if (responsePurchase.results.length > 0) {
              responsePurchase.results.forEach((purchase) => {
                tableData.push({
                  ...purchase,
                  journalName: `${purchase.financialReference} (Purchase)`,
                  debit: purchase.totalAmount,
                  credit: 0,
                  locationName: '',
                  redirectId: purchase.id,
                  ledgerType: 'PURCHASE'
                });
                totalDebit += purchase.totalAmount;

                if (purchase.payments) {
                  if (purchase.payments.length > 0) {
                    purchase.payments.forEach((payment) => {
                      tableData.push({
                        ...payment,
                        journalName: `${payment.reference} (Payment)`,
                        debit: 0,
                        credit: payment.amount,
                        locationId: purchase.locationId,
                        locationName: '',
                        redirectId: purchase.id,
                        ledgerType: 'PURCHASE'
                      });
                      totalCredit += payment.amount;
                    });
                  }
                }
              });
            }
          }
        }
        console.log('tableData', tableData);
        for (let i = 0; i < tableData.length; i++) {
          if (tableData[i].locationId) {
            let locationDetails = await LocationsDB.getLocation(tableData[i].locationId);
            if (!locationDetails) {
              const allLocations = await get_location_list_for_ids([
                ...new Set(
                  tableData
                    .filter((value) => value.locationId !== null)
                    .map((value) => {
                      return value.locationId;
                    })
                )
              ]);
              await LocationsDB.addLocations(allLocations);
              locationDetails = await LocationsDB.getLocation(tableData[i].locationId);
            }

            tableData[i].locationName = locationDetails.name;
          }
        }

        tableData = tableData.sort(
          (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix()
        );

        const dataUpdated = tableData.map((item, index) => {
          return {
            ...item,
            SN: index + 1,
            createdAt: convertUTCStringtoLocalString(item.createdAt, DEFAULT_DATE_FORMAT)
          };
        });

        setLines(tableData);
        setExportData(dataUpdated);
        setTotal({ totalCredit: totalCredit, totalDebit: totalDebit });

        savedData.lines = tableData;
        savedData.total = { totalCredit: totalCredit, totalDebit: totalDebit };
        savedData.exportData = dataUpdated;
      }
    }
    setIsLoading(false);
    return accountId ? savedData : undefined;
  };

  const onPagination = async (pageNo = 1, totalSize = 100, isSize = false) => {
    setIsLoading(true);
    const values = form.getFieldsValue();
    values.endDate = convertLocalToUTCString(values.endDate);
    values.startDate = convertLocalToUTCString(values.startDate);
    delete values.dateCustom;
    delete values.startDateNepali;
    delete values.endDateNepali;
    if (isSize) {
      values.skip = 0;
      values.count = totalSize;
      setPage(1);
      setSize(totalSize);
    } else {
      values.skip = (pageNo - 1) * totalSize;
      values.count = totalSize;
      setPage(pageNo);
    }
    const url = ConvertObjectToURL(values);
    return await getInfo(url);
  };

  const columns: ColumnsType<IJournalLinesViewResponseTableData> = [
    {
      title: 'S.N',
      key: 'SN',
      width: 5,
      render: (txt, record, index) => {
        return <TableCell>{(page - 1) * size + (index + 1)}</TableCell>;
      }
    },
    {
      title: 'Journal',
      key: 'journalName',
      width: 15,
      sorter: (a, b) =>
        a.journalName && b.journalName ? a.journalName.localeCompare(b.journalName) : 0,
      sortOrder: sortedInfo.columnKey === 'journalName' ? sortedInfo.order : null,
      render: (a, record) => (
        <TableCell>
          {record.ledgerType === 'JOURNAL' && (
            <Link
              to={`/accounts/journal-lines?accountId=${
                record.accountId
              }&startDate=${form.getFieldValue(['startDate'])}&endDate=${form.getFieldValue([
                'endDate'
              ])}`}
              target="_blank">
              {record.journalName}
            </Link>
          )}
          {record.ledgerType === 'SELL' && (
            <Link to={`/sell/${record.redirectId}`} target="_blank">
              {record.journalName}
            </Link>
          )}
          {record.ledgerType === 'PURCHASE' && (
            <Link to={`/purchase/${record.redirectId}`} target="_blank">
              {record.journalName}
            </Link>
          )}
        </TableCell>
      )
    },
    {
      title: 'Debit',
      key: 'debit',
      width: 15,
      sorter: (a, b) => a.debit - b.debit,
      sortOrder: sortedInfo.columnKey === 'debit' ? sortedInfo.order : null,
      render: (a, record) => (
        <TableCell className="text-right">{nepaliNumberFormatter(record.debit)}</TableCell>
      )
    },
    {
      title: 'Credit',
      key: 'credit',
      width: 15,
      sorter: (a, b) => a.credit - b.credit,
      sortOrder: sortedInfo.columnKey === 'credit' ? sortedInfo.order : null,
      render: (a, record) => (
        <TableCell className="text-right">{nepaliNumberFormatter(record.credit)}</TableCell>
      )
    },
    {
      title: 'Location',
      key: 'locationName',
      width: 15,
      sorter: (a, b) =>
        a.locationName && b.locationName ? a.locationName.localeCompare(b.locationName) : 0,
      sortOrder: sortedInfo.columnKey === 'locationName' ? sortedInfo.order : null,
      render: (a, record) => {
        return (
          <>
            <TableCell>{record.locationName}</TableCell>
          </>
        );
      }
    },
    {
      title: 'Created Date',
      key: 'createdAt',
      width: 15,
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      sortOrder: sortedInfo.columnKey === 'createdAt' ? sortedInfo.order : null,
      render: (a, record) => {
        return (
          <>
            <TableCell>
              {convertUTCStringtoLocalString(record.createdAt, DEFAULT_DATE_FORMAT)}
            </TableCell>
          </>
        );
      }
    }
  ];

  const columsforPrint = [
    {
      label: 'Journal',
      dataIndex: 'journalName',
      width: 100,
      render: (text: string) => {
        return <TableCell>{text}</TableCell>;
      }
    },
    {
      label: 'Debit',
      dataIndex: 'debit',
      width: 100,
      render: (text: number) => {
        return <TableCell>{nepaliNumberFormatter(text)}</TableCell>;
      }
    },
    {
      label: 'Credit',
      dataIndex: 'credit',
      width: 100,
      render: (text: number) => {
        return <TableCell>{nepaliNumberFormatter(text)}</TableCell>;
      }
    },
    {
      label: 'Location',
      dataIndex: 'locationName',
      width: 150,
      render: (text: string) => {
        return <TableCell>{text}</TableCell>;
      }
    },
    {
      label: 'Created Date',
      dataIndex: 'createdAt',
      width: 130,
      render: (text: string) => {
        return <TableCell>{text}</TableCell>;
      }
    }
  ];

  const handleExcel = async () => {
    setIsLoading(true);
    try {
      const columns: ExportColumnType[] = [
        {
          title: 'S.N',
          dataIndex: 'SN',
          width: 50
        },
        {
          title: 'Journal',
          dataIndex: 'journalName',
          width: 220
        },
        {
          title: 'Debit',
          dataIndex: 'debit',
          width: 100
        },
        {
          title: 'Credit',
          dataIndex: 'credit',
          width: 100
        },
        {
          title: 'Location',
          dataIndex: 'locationName',
          width: 150
        },
        {
          title: 'Created Date',
          dataIndex: 'createdAt',
          width: 130
        }
      ];
      if (lines.length === 0) {
        message.error('No Data to Export');
        return;
      }

      exportExcel(columns, exportData, 'Accounts Journal');
      setIsLoading(false);
    } catch (err: any) {
      setIsLoading(false);
      console.log(err);
    }
  };

  const handlePDFExport = useReactToPrint({
    content: () => printPDFRef.current
  });

  return (
    <Spin spinning={isLoading}>
      <CustomModal
        footer={false}
        isModalOpen={openModalForPdfExport}
        setIsModalOpen={setOpenModalForPdfExport}
        title="PDF print">
        <div className="flex justify-end mb-3">
          <Button type="primary" onClick={handlePDFExport}>
            Print Pdf
          </Button>
        </div>
        <div style={{ maxHeight: '80vh', overflow: 'scroll' }}>
          <CustomTable
            columns={columsforPrint}
            datas={exportData}
            reff={printPDFRef}
            title={'Accounts Journal'}
          />
        </div>
      </CustomModal>
      <AppContent
        breadcrumbItems={breadcrumbItems}
        withfilter={true}
        button={
          <>
            <div>
              <AccountFilterTable
                showFilterInitial
                onParentLoading={setIsLoading}
                onInitialLoad={({ data, pagination }) => {
                  setPage(pagination.page);
                  setSize(pagination.size);

                  if (data) {
                    const typedData = data as ISavedData;
                    if (typedData.exportData) setExportData(typedData.exportData);
                    if (typedData.total) setTotal(typedData.total);
                    if (typedData.lines) setLines(typedData.lines);
                    setIsLoading(false);
                  }
                }}
                defaultValues={{
                  value: '',
                  skip: 0,
                  count: 100,
                  accountId: id ? parseInt(id) : null,
                  locationId: preferenceLocationId ? preferenceLocationId : 1
                }}
                initial={false}
                onSearch={onSubmitFilter}
                form={form}
                buttonParentStyle={'flex justify-end items-center'}>
                <LocationSearchV2 hasParentFormItem={false} name={'locationId'} />
                <AccountSearchV2
                  required
                  name={'accountId'}
                  type={AccountType.USER}
                  hasParentFormItem={false}
                />

                <Form.Item name="value" label="Search">
                  <Input placeholder="Search" />
                </Form.Item>
              </AccountFilterTable>
            </div>
            {/* </div> */}
          </>
        }>
        <div className="flex justify-end items-center mb-4">
          <div>
            <ActionDropdown
              button={true}
              menu={
                <Menu
                  items={[
                    {
                      key: '1',
                      label: (
                        <Tooltip title="Export Excel" color="blue">
                          <div className="text-center">Excel</div>
                        </Tooltip>
                      ),
                      onClick: () => {
                        handleExcel();
                      }
                    },
                    {
                      key: '2',
                      label: (
                        <Tooltip title="Export PDF" color="blue">
                          <div className="text-center">PDF</div>
                        </Tooltip>
                      ),
                      onClick: () => {
                        try {
                          if (lines.length === 0) {
                            message.error('No Data to Export');
                            setIsLoading(false);
                            return;
                          }
                          setOpenModalForPdfExport(true);
                        } catch (err: any) {
                          console.log(err);
                        }
                      }
                    }
                  ]}
                />
              }
            />
          </div>
        </div>
        <CustomizeTable
          form={form}
          columns={columns}
          data={lines}
          notshowPagination={true}
          customScroll={{ x: 600, y: '75vh' }}
          paginationDatas={{
            page: page,
            total: lines.length,
            size: size,
            onPagination
          }}
          toSort={handleChange}
          tableSummary={
            <Table.Summary fixed={true}>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}></Table.Summary.Cell>
                <Table.Summary.Cell index={1}>Total</Table.Summary.Cell>
                <Table.Summary.Cell index={2} className="text-right mr-2 text-xs">
                  {nepaliNumberFormatter(total.totalDebit)}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={3} className="text-right mr-2 text-xs">
                  {nepaliNumberFormatter(total.totalCredit)}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={4}></Table.Summary.Cell>
                <Table.Summary.Cell index={5}></Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          }
        />
      </AppContent>
    </Spin>
  );
};

export default AccountsView;
