import { useEffect, useState } from 'react';
import { SelectProps } from './../index';
import useDebounce from '@/hooks/useDebounce';
import ProductsDB from '@/store/localstorage/ProductsDB';
import { Button, Divider, Form, message, Select } from 'antd';
import { get_product_active_list_only, get_product_list_only } from '@/services/products/queries';
import { IProductDetails } from '@/services/products/types';
import getErrorMessage from '@/utils/getError';

type Props = SelectProps & {
  categoryId: number | null;
  onSelect?: (value: string | number) => void;
  setSelected?: (selected?: IProductDetails) => void;
  showHidden?: boolean;
  locationId?: number;
};

function ProductbyCategory({
  count = 10,
  showAll = false,
  allowClear = false,
  placeHolder,
  notFoundContent = <div>No Content</div>,
  dropdownMatchSelectWidth = false,
  defaultActiveFirstOption = false,
  onSelect,
  categoryId,
  showHidden,
  locationId,
  ...props
}: Props) {
  const [skip, setSkip] = useState(0);
  const [isMore, setIsMore] = useState(true);

  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchValue = useDebounce(searchValue, 500);

  const [data, setData] = useState<IProductDetails[]>([]);
  const [isCacheResponse, setIsCacheResponse] = useState(false);

  async function fetchProducts(skip = 0, count = 1000, value = '', filter = '') {
    const params = new URLSearchParams(filter);
    if (locationId) params.set('locationId', locationId.toString());
    if (categoryId) params.set('categoryId', categoryId.toString());

    const updatedFilter = params.toString();
    return showHidden
      ? get_product_list_only(skip, count, value, updatedFilter)
      : get_product_active_list_only(skip, count, value, updatedFilter);
  }

  async function fetchProductsViaIndexDB(name = '', limit = 10) {
    return showHidden
      ? ProductsDB.searchProduct(name, limit)
      : ProductsDB.searchActiveProduct(name, limit, locationId);
  }

  useEffect(() => {
    searchInIndexDB(debouncedSearchValue);
  }, [debouncedSearchValue, categoryId]);

  async function searchInIndexDB(value: string) {
    try {
      let products = await fetchProductsViaIndexDB(value, 100);
      if (categoryId) {
        products = products.filter((val) => val.categoryId === categoryId);
      }

      if (!products || products.length == 0) {
        message.info('Cannot find any product with that name in cache, searching in server...');
        searchInServer(value, 'initial');
        return;
      }

      setData(products);
      setIsCacheResponse(true);
    } catch (error) {
      getErrorMessage(error, true);
    }
  }

  async function searchInServer(value: string, type: 'initial' | 'more') {
    try {
      const currentSkip = type === 'initial' ? 0 : skip;
      const results = await fetchProducts(currentSkip, count, value);

      const isValidResponse = results && results.length > 0;

      if (!isValidResponse) {
        message.error('Cannot find any product with that name!');
        setIsMore(false);
        type === 'initial' && setData([]);
      }

      if (isValidResponse) {
        type === 'initial' ? setData(results) : setData((prev) => [...prev, ...results]);
        ProductsDB.addProducts(results);

        setIsMore(results.length >= count);
        type === 'more' && setSkip(currentSkip + count);
      }

      if (type === 'initial') {
        setIsCacheResponse(false);
        setSkip(count);
      }
    } catch (error) {
      getErrorMessage(error, true);
    }
  }

  function handleChange(value: number) {
    onSelect?.(value);

    const product = data.find((current) => {
      return current.id === value;
    });

    const hasDefaultUnit = product?.productUnits?.find((unit) => unit.isDefault);
    if (!hasDefaultUnit && product) {
      message.warning('This product does not have a default unit', 5);
    }

    props.setSelected?.(product);
  }

  const options = data?.map((value) => {
    return (
      <Select.Option key={value.id} value={value.id}>
        {value.name}
      </Select.Option>
    );
  });

  const selectMenu = (
    <Select
      showSearch
      allowClear={allowClear}
      filterOption={false}
      disabled={props.disabled}
      placeholder={placeHolder || 'Search product...'}
      defaultActiveFirstOption={defaultActiveFirstOption}
      onSearch={(val) => setSearchValue(val)}
      onClear={() => setSearchValue('')}
      notFoundContent={notFoundContent}
      onChange={handleChange}
      dropdownMatchSelectWidth={dropdownMatchSelectWidth}
      dropdownRender={(menu) => {
        return (
          <>
            {menu}
            <Divider style={{ margin: '8px 0' }} />
            {isCacheResponse ? (
              <div className="flex flex-col" style={{ padding: '0 8px 4px' }}>
                <Button
                  type="text"
                  style={{ color: 'blue', width: '100%' }}
                  onClick={() => searchInServer(searchValue, 'initial')}>
                  {'Pull More & Sync'}
                </Button>
              </div>
            ) : (
              <div className="flex flex-col" style={{ padding: '0 8px 4px' }}>
                {isMore ? (
                  <Button
                    type="text"
                    style={{ color: 'blue', width: '100%' }}
                    onClick={() => searchInServer(searchValue, 'more')}>
                    {'Get More...'}
                  </Button>
                ) : (
                  <div style={{ width: '100%', textAlign: 'center' }}>No more data...</div>
                )}
              </div>
            )}
          </>
        );
      }}>
      {showAll && <Select.Option value="">All</Select.Option>}
      {options}
    </Select>
  );

  if (props.hasParentFormItem) return selectMenu;
  return (
    <Form.Item
      label={props.label || 'Product'}
      name={props.name}
      rules={[{ required: props.required || false, message: 'Please select product!' }]}>
      {selectMenu}
    </Form.Item>
  );
}

export default ProductbyCategory;
