import { useMutation, useQuery } from '@tanstack/react-query';
import { Button, Form, message, Popover, Select } from 'antd';
import AppContent from '@/components/Common/Content/Content';
import { USER_INFO } from '@/constants/config';
import { get_location_list } from '@/services/locations/queries';
import { update_preference_user_mutation } from '@/services/users/mutations';
import {
  find_date_preference,
  find_locationId_preference,
  find_persist_scroll_preference
} from '@/store/localstorage/preferences';
import { getUserData } from '@/utils/auth.utils';
import { setLocalStorage } from '@/utils/storage.utils';
import { IPreferencesResponse, IUserPreferences } from '@/services/settings/types';

import { useFilterStore } from '@/store/zustand';
import { useState } from 'react';
import LocationSearchV2 from '@/components/Common/CustomSearch/Location';
import { InfoCircleOutlined } from '@ant-design/icons';

interface FormValues {
  locationId: number;
  nepaliDate: boolean;
  persistScrollPagination: boolean;
}

const Preferences = () => {
  const [form] = Form.useForm<FormValues>();
  const userData = getUserData();

  const [currentLocationId, setCurrentLocationId] = useState(find_locationId_preference);

  useQuery(['locations'], async () => {
    const response = await get_location_list();
    const locationdId = find_locationId_preference();

    form.setFieldsValue({
      locationId: typeof locationdId === 'number' ? locationdId : response.data.results[0].id,
      nepaliDate: find_date_preference(),
      persistScrollPagination: find_persist_scroll_preference()
    });

    return response;
  });

  const zustandFilter = useFilterStore();

  const updatePreferencesMutation = useMutation(update_preference_user_mutation, {
    onSuccess: async ({ data }: { data: IPreferencesResponse }) => {
      setLocalStorage(USER_INFO, {
        ...userData,
        preferences: {
          ...userData.preferences,
          preferences: data.preferences
        }
      });

      message.success('Preference updated successfully');
    }
  });

  const onFinish = async (values: FormValues) => {
    if ('preferences' in userData && userData.preferences && userData.preferences.preferences) {
      const prefUpdate: IUserPreferences = JSON.parse(userData.preferences.preferences);
      updatePreferencesMutation.mutate({
        id: userData.id,
        value: JSON.stringify({
          ...prefUpdate,
          locationId: values.locationId,
          nepaliDate: values.nepaliDate,
          persistScrollPagination: values.persistScrollPagination
        })
      });
    } else {
      updatePreferencesMutation.mutate({
        id: userData.id,
        value: JSON.stringify({
          locationId: values.locationId,
          nepaliDate: values.nepaliDate
        })
      });
    }

    if (typeof currentLocationId === 'number' && currentLocationId !== values.locationId) {
      await zustandFilter.resetAll();
    }

    setCurrentLocationId(values.locationId);
  };
  return (
    <AppContent breadcrumbItems={[{ label: 'Preferences' }]}>
      <Form form={form} layout={'vertical'} onFinish={onFinish}>
        <div className="grid gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
          <LocationSearchV2 name="locationId" label="Location" hasParentFormItem={false} />
          <Form.Item name="nepaliDate" label="Date Type">
            <Select
              placeholder="Select a location!"
              style={{ borderRadius: '10px' }}
              allowClear
              dropdownMatchSelectWidth={false}>
              <Select.Option value={false} key={'1'}>
                English
              </Select.Option>
              <Select.Option value={true} key={'2'}>
                Nepali
              </Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            className="label-w-full"
            name="persistScrollPagination"
            label={
              <div className="flex justify-between items-center w-full">
                <div>Persist Scroll?</div>
                <div className="block">
                  <Popover
                    overlayClassName="w-[400px]"
                    title="Persist Scroll?"
                    content={
                      <span>
                        Select <strong>Yes</strong> to keep the scroll position when paginating. By
                        default, it will reset to the top when you change page or size.
                      </span>
                    }>
                    <InfoCircleOutlined style={{ color: '#1890ff', cursor: 'pointer' }} />
                  </Popover>
                </div>
              </div>
            }>
            <Select placeholder="Select" allowClear dropdownMatchSelectWidth={false}>
              <Select.Option value={true}>Yes</Select.Option>
              <Select.Option value={false}>No</Select.Option>
            </Select>
          </Form.Item>
        </div>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            style={{
              color: 'green',
              border: '1px solid green',
              borderRadius: '9px',
              backgroundColor: 'transparent',
              marginTop: '25px'
            }}>
            Save
          </Button>
        </Form.Item>
      </Form>
    </AppContent>
  );
};

export default Preferences;
