import React, { useState } from 'react';
import AppContent from '@/components/Common/Content/Content';
import TableFilter from '@/components/FliterTable';
import moment from 'moment';
import { Form, Menu, Spin, message } from 'antd';
import CustomizeTable from '@/components/Common/CustomizeTable/CustomizeTable';
import { ColumnsType, TableProps } from 'antd/lib/table';
import { SorterResult } from 'antd/lib/table/interface';
import TableCell from '@/components/Common/CustomizeTable/CustomCell';
import { convertLocalToUTCString, convertUTCStringtoLocalString } from '@/utils/convertToUTC';
import { checkAccess } from '@/routes/acl';
import { Link } from 'react-router-dom';
import DiscussionChatModal from '@/components/Common/DiscussionChatModal';
import ActionDropdown from '@/components/Common/Dropdownactions';
import { ILogsResult, ISystemLogs } from '@/services/logs/types';
import { ILogEvents } from '@/services/logs/events/types';
import { get_log_events_details, get_log_events_ids } from '@/services/logs/events/queries';
import { User } from '@/services/users/types';
import { ConvertObjectToURL } from '@/utils/converturl';
import { get_logs_list } from '@/services/logs/queries';
import CustomErrorModal from '@/components/Common/CustomErrorModal';
import UsersDB from '@/store/localstorage/UsersDB';
import { get_user_list_ids } from '@/services/users/queries';

import CustomViewIcon from '@/components/Common/CustomIcons/CustomViewIcon';
import { optionalNumberSorter, optionalStringSorter } from '@/utils/sorter.utils';
import CopyButton from '@/components/Common/CopyButton';
import { DEFAULT_DATE_FORMAT } from '@/constants';
import UserSearchV2 from '@/components/Common/CustomSearch/Users';
import EventSearchV2 from '@/components/Common/CustomSearch/Events';

const LogsList: React.FC = () => {
  const [form] = Form.useForm();

  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({});
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [logsList, setLogsList] = useState<ILogsResult>({ total: 0, results: [] });

  const getInfo = async (filter = '') => {
    setIsLoading(true);
    const logList = await get_logs_list(filter);
    const fetchEventName = new Map<number, number[]>();
    const searchUsers = new Map<number, number[]>();

    for (let index = 0; index < logList.results.length; index++) {
      const events = logList.results[index];
      console.log('events', events);
      if (events.eventsId) {
        if (fetchEventName.has(events.eventsId)) {
          fetchEventName.get(events.eventsId)?.push(index);
        } else {
          fetchEventName.set(events.eventsId, [index]);
        }
      }

      if (events.userId) {
        const eventUser: User = await UsersDB.getUser(events.userId);
        if (!eventUser) {
          if (searchUsers.has(events.userId)) {
            searchUsers.get(events.userId)?.push(index);
          } else {
            searchUsers.set(events.userId, [index]);
          }
        } else {
          events.userName = eventUser.name;
        }
      }

      if (events.responseBody) {
        events.invoice = JSON.parse(events.responseBody).invoice;
        events.printCount = JSON.parse(events.responseBody).printCount;
      } else {
        events.invoice = '';
        events.printCount = undefined;
      }
    }
    const fetchEventNameLength = fetchEventName.size;
    if (fetchEventNameLength > 0) {
      const fetchEventList = await get_log_events_ids(Array.from(fetchEventName.keys()));
      for (const entry of Array.from(fetchEventName.entries())) {
        const key = entry[0];
        const value = entry[1];

        const eventName = fetchEventList?.results.find((curEvent: any) => curEvent.id == key);
        if (!eventName) continue;

        for (let index = 0; index < value.length; index++) {
          const element = value[index];
          logList.results[element].eventsName = eventName?.url;
        }
      }
    }
    const searchUserslength = searchUsers.size;
    if (searchUserslength > 0) {
      const userList = await get_user_list_ids(Array.from(searchUsers.keys()));

      for (const entry of Array.from(searchUsers.entries())) {
        const key = entry[0];
        const value = entry[1];

        const user = userList?.data?.results.find((currUser: any) => currUser.id == key);

        if (!user) continue;
        for (let index = 0; index < value.length; index++) {
          const element = value[index];
          logList.results[element].userName = user?.name;
        }
        await UsersDB.addUsers([user]);
      }
    }
    setLogsList(logList);
    setIsLoading(false);
    return logList;
  };

  const onSubmitFilter = async (val: string) => {
    const values = form.getFieldsValue();

    const dateDiff = moment(values.endDate).diff(values.startDate, 'days');
    if (dateDiff > 30) {
      CustomErrorModal({
        message: `${dateDiff} days selected.Please navigate to Backup to view data more than 30 days!`
      });
      setIsLoading(false);
      return;
    }

    const event = form.getFieldValue(['eventsId']);
    const user = form.getFieldValue(['userId']);
    if (!event && !user) {
      message.warning('Event or User is required!');
      setLogsList({ total: 0, results: [] });
      setIsLoading(false);
      return;
    }

    const data = await getInfo(val);
    setPage(1);
    setSize(10);
    return data;
  };

  const handleChange: TableProps<any>['onChange'] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<any>);
  };
  const onPagination = async (pageNo = 1, totalSize = 10, isSize = false) => {
    setIsLoading(true);
    const values = form.getFieldsValue();
    values.endDate = convertLocalToUTCString(values.endDate);
    values.startDate = convertLocalToUTCString(values.startDate);
    delete values.dateCustom;
    delete values.startDateNepali;
    delete values.endDateNepali;
    if (isSize) {
      values.skip = 0;
      values.count = totalSize;
      setPage(1);
      setSize(totalSize);
    } else {
      values.skip = (pageNo - 1) * totalSize;
      values.count = totalSize;
      setPage(pageNo);
    }
    const url = ConvertObjectToURL(values);
    return await getInfo(url);
  };

  const breadcrumbItems = [
    {
      label: 'Logs',
      link: '/logs'
    }
  ];
  const columns: ColumnsType<ISystemLogs> = [
    {
      title: 'S.N',
      key: 'SN',
      width: 2,
      sorter: (a, b) => a.id - b.id,
      sortOrder: sortedInfo.columnKey === 'id' ? sortedInfo.order : null,
      render: (txt, record, index) => {
        return <TableCell>{(page - 1) * size + (index + 1)}</TableCell>;
      }
    },
    {
      title: 'Date',
      key: 'createdAt',
      width: 6,
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      sortOrder: sortedInfo.columnKey === 'createdAt' ? sortedInfo.order : null,
      render: (record) => {
        return (
          <>
            <TableCell>
              {convertUTCStringtoLocalString(record.createdAt, DEFAULT_DATE_FORMAT)}
            </TableCell>
          </>
        );
      }
    },

    {
      title: 'Event',
      key: 'eventsName',
      width: 5,
      sorter: (a, b) => {
        return a.eventsName.localeCompare(b.eventsName);
      },
      sortOrder: sortedInfo.columnKey === 'eventsName' ? sortedInfo.order : null,

      render: (record: ISystemLogs) => {
        return <TableCell>{record.eventsName}</TableCell>;
      }
    },

    {
      title: 'User',
      key: 'user',
      width: 3,

      render: (record: ISystemLogs) => {
        return <TableCell>{record.userName}</TableCell>;
      }
    },

    {
      title: 'Method',
      key: 'method',
      width: 3,
      sorter: (a, b) => {
        return a.method.localeCompare(b.method);
      },
      sortOrder: sortedInfo.columnKey === 'method' ? sortedInfo.order : null,

      render: (record: ISystemLogs) => {
        return <TableCell>{record.method}</TableCell>;
      }
    },

    {
      title: 'URL',
      key: 'url',
      width: 8,
      sorter: (a, b) => {
        return a.url.localeCompare(b.url);
      },
      sortOrder: sortedInfo.columnKey === 'url' ? sortedInfo.order : null,

      render: (record: ISystemLogs) => {
        return <TableCell>{record.url}</TableCell>;
      }
    },
    {
      title: 'Status Code',
      key: 'statusCode',
      width: 3,
      sorter: (a, b) => a.statusCode - b.statusCode,
      sortOrder: sortedInfo.columnKey === 'statusCode' ? sortedInfo.order : null,
      render: (record: ISystemLogs) => {
        return <TableCell>{record.statusCode}</TableCell>;
      }
    },
    {
      title: 'Invoice Number',
      key: 'invoice',
      width: 8,
      className: 'invoice',
      sorter: (a, b) => {
        return optionalStringSorter(a.invoice, b.invoice);
      },
      sortOrder: sortedInfo.columnKey === 'invoice' ? sortedInfo.order : null,
      render: (record: ISystemLogs) => {
        return (
          <TableCell>
            {record.invoice}
            {record.invoice ? <CopyButton text={record.invoice} /> : null}
          </TableCell>
        );
      }
    },
    {
      title: 'Print Count',
      key: 'printCount',
      width: 3,
      sorter: (a, b) => {
        return optionalNumberSorter(a.printCount, b.printCount);
      },
      sortOrder: sortedInfo.columnKey === 'printCount' ? sortedInfo.order : null,
      render: (record: ISystemLogs) => {
        return <TableCell>{record.printCount}</TableCell>;
      }
    },
    {
      title: 'Content Length',
      key: 'contentLength',
      width: 3,
      sorter: (a, b) => a.contentLength - b.contentLength,
      sortOrder: sortedInfo.columnKey === 'contentLength' ? sortedInfo.order : null,
      render: (record: ISystemLogs) => {
        return <TableCell>{record.contentLength}</TableCell>;
      }
    },
    {
      title: 'Response Time',
      key: 'responseTime',
      width: 3,
      sorter: (a, b) => a.responseTime - b.responseTime,
      sortOrder: sortedInfo.columnKey === 'responseTime' ? sortedInfo.order : null,
      render: (record: ISystemLogs) => {
        return <TableCell>{record.responseTime}</TableCell>;
      }
    },

    {
      title: 'Start Time',
      key: 'startTime',
      width: 4,
      sorter: (a, b) => {
        return a.startTime.localeCompare(b.startTime);
      },
      sortOrder: sortedInfo.columnKey === 'startTime' ? sortedInfo.order : null,

      render: (record: ISystemLogs) => {
        return (
          <TableCell>
            {' '}
            {convertUTCStringtoLocalString(record.startTime, DEFAULT_DATE_FORMAT)}
          </TableCell>
        );
      }
    },
    {
      title: 'End Time',
      key: 'endTime',
      width: 4,
      sorter: (a, b) => {
        return a.endTime.localeCompare(b.endTime);
      },
      sortOrder: sortedInfo.columnKey === 'endTime' ? sortedInfo.order : null,

      render: (record: ISystemLogs) => {
        return (
          <TableCell>
            {' '}
            {convertUTCStringtoLocalString(record.endTime, DEFAULT_DATE_FORMAT)}
          </TableCell>
        );
      }
    },

    {
      title: 'Actions',
      key: 'actions',
      width: 3,
      fixed: 'right',
      render: (record, arr, index) => {
        const menuItems: (
          | {
              key: string;
              label: JSX.Element;
              onClick?: undefined;
            }
          | {
              key: string;
              label: JSX.Element;
              onClick: () => void;
            }
        )[] = [];

        menuItems.push({
          key: '1',
          label: <CustomViewIcon link={`/logs/view/${record.id}`} />
        });

        if (checkAccess('READ_CHANNEL')) {
          menuItems.push({
            key: '2',
            label: <DiscussionChatModal slug="logs" id={record.id} />
          });
        }

        const menu = <Menu items={menuItems} />;
        return <ActionDropdown menu={menu} />;
      }
    }
  ];

  return (
    <Spin spinning={isLoading}>
      <AppContent
        breadcrumbItems={breadcrumbItems}
        button={
          <TableFilter
            onInitialLoad={({ data, pagination }) => {
              setPage(pagination.page);
              setSize(pagination.size);

              if (data) {
                setLogsList(data);
                setIsLoading(false);
              }
            }}
            defaultValues={{
              dateCustom: [moment(0, 'HH'), moment(0, 'HH').add(1, 'days')],
              value: '',
              skip: 0,
              count: 10
            }}
            initial={false}
            onSubmit={onSubmitFilter}
            form={form}
            styleforbuttons={'flex justify-end items-center'}
            style={
              'grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3  xl:grid-cols-4 gap-2 items-center justify-center'
            }
            buttons={<></>}>
            <EventSearchV2 hasParentFormItem={false} name={'eventsId'} label={'Event'} />
            <UserSearchV2 hasParentFormItem={false} name={'userId'} label={'User'} />
          </TableFilter>
        }>
        <CustomizeTable
          form={form}
          columns={columns}
          data={logsList.results}
          //usersLoading={isLoading}
          notshowPagination={true}
          customScroll={{ x: 1750, y: '75vh' }}
          paginationDatas={{
            page: page,
            total: logsList.total,
            size: size,
            onPagination
          }}
          tableName={'logs-list'}
          toSort={handleChange}
        />
      </AppContent>
    </Spin>
  );
};

export default LogsList;
