function getCharFromNumber(num: number) {
  return String.fromCharCode(65 + num);
}

export function getExcelColumnName(num: number) {
  if (num < 1) throw new Error('Column number must be 1 or greater');

  let columnName = '';
  while (num > 0) {
    num--;
    columnName = String.fromCharCode((num % 26) + 65) + columnName;
    num = Math.floor(num / 26);
  }

  return columnName;
}

export default getCharFromNumber;
