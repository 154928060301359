import React, { useContext, useState, useEffect } from 'react';
import { useMutation } from '@tanstack/react-query';
import {
  get_archived_tasks_filter,
  get_archived_tasks_list_filter
} from '@/services/tasks/queries';
import { update_tasks_log } from '@/services/tasks/mutations';
import AppContent from '@/components/Common/Content/Content';
import { AuthContext } from '@/contexts/auth.context';
import { Form, Menu, Select, Spin } from 'antd';

import { checkAccess } from '@/routes/acl';
import TableFilter from '@/components/FliterTable';
import CustomizeTable from '@/components/Common/CustomizeTable/CustomizeTable';
import CustomButton from '@/components/Common/CustomButton/CustomButton';
import { useNavigate } from 'react-router-dom';
import DiscussionChatModal from '@/components/Common/DiscussionChatModal';
import { convertLocalToUTCString } from '@/utils/convertToUTC';
import { ConvertObjectToURL } from '@/utils/converturl';
import ActionDropdown from '@/components/Common/Dropdownactions';
import TableCell from '@/components/Common/CustomizeTable/CustomCell';
import { ITasks } from '@/services/tasks/types';
import CustomUpdateIcon from '@/components/Common/CustomIcons/CustomUpdateIcon';
import { CustomDatePresets } from '../../sqlsource/report/utils/datePresets';
import { DEFAULT_DATE_FORMAT } from '@/constants';
import getErrorMessage from '@/utils/getError';
import handlePagination from '@/utils/handlePagination';

const ArchivedTaskList = () => {
  const [form] = Form.useForm();
  const [tableData, setTableData] = useState<{ count: number; results: ITasks[] }>({
    count: 0,
    results: []
  });

  const [pagination, setPagination] = useState({ page: 1, size: 100 });

  const navigate = useNavigate();
  const [loading, setLoading] = useState<any>(true);

  const selectOptions = ['Packed', 'Dispatched', 'Delivered', 'Assigned'];

  const breadcrumbItems = [{ label: 'Archived Tasks', link: '/tasks/archived' }];

  const columns = [
    {
      title: 'ID',
      key: 'id',
      width: 50,
      render: (record: any) => {
        return <TableCell>{record.id}</TableCell>;
      }
    },
    {
      title: 'Reference',
      width: 100,
      key: 'Reference',
      className: 'invoice',
      // sorter: (a, b) => a.referenceNumber.localeCompare(b.referenceNumber),
      // sortOrder: sortedInfo.columnKey === 'referenceNumber' ? sortedInfo.order : null,
      render: (record: any) => {
        return <TableCell>{record.reference}</TableCell>;
      }
    },
    {
      title: 'Title',
      width: 100,
      key: 'Title',
      // sorter: (a, b) => a.userName.localeCompare(b.userName),
      // sortOrder: sortedInfo.columnKey === 'userName' ? sortedInfo.order : null,
      render: (record: any) => {
        return <TableCell>{record.name}</TableCell>;
      }
    },
    {
      title: 'Description',
      width: 100,
      key: 'description',
      // sorter: (a, b) => a.userName.localeCompare(b.userName),
      // sortOrder: sortedInfo.columnKey === 'userName' ? sortedInfo.order : null,
      render: (record: any) => {
        return <TableCell>{record.description}</TableCell>;
      }
    },
    {
      title: 'Due Date',
      width: 100,
      key: 'Due Date',
      // sorter: (a, b) => a.userName.localeCompare(b.userName),
      // sortOrder: sortedInfo.columnKey === 'userName' ? sortedInfo.order : null,
      render: (record: any) => {
        return (
          <TableCell>
            {/* {(record.dueDate)} */}
            {convertLocalToUTCString(record.dueDate, DEFAULT_DATE_FORMAT)}
          </TableCell>
        );
      }
    },
    {
      title: 'Actions',
      key: 'actions',
      width: 50,
      fixed: 'right',
      render: (record: any) => {
        const confirmFunction = () => {
          // deleteSellMutation.mutate(record.id);
        };
        const menuItems = [];
        if (checkAccess('READ_CHANNEL')) {
          menuItems.push({
            key: '4',
            label: (
              <DiscussionChatModal
                slug="task"
                id={record.id}
                rreference="other"
                // fromSellorPurahcaseid={record.id}
              />
            )
          });
        }
        if (checkAccess('UPDATE_LOCATION')) {
          menuItems.push({
            key: '1',
            label: <CustomUpdateIcon link={`/tasks/${record.id}`} />
          });
        }
        const menu = <Menu items={menuItems} />;

        return <ActionDropdown menu={menu} />;
      }
    }
  ];

  async function onSubmitFilter(filter: string) {
    const data = await getInfo(filter);
    setPagination({ page: 1, size: 100 });
    return data;
  }

  async function getInfo(filter = '') {
    try {
      setLoading(true);
      const response = await get_archived_tasks_filter(filter);
      setTableData(response);
      return response;
    } catch (error) {
      getErrorMessage(error, true);
    } finally {
      setLoading(false);
    }
  }

  const onPagination = async (page = 1, size = 100, isSize = false) => {
    setLoading(true);
    const { values, ...pagination } = handlePagination({
      formValues: form.getFieldsValue(),
      page,
      size,
      isSize
    });

    setPagination((prev) => {
      prev.page = pagination.page;
      if (isSize) prev.size = pagination.size;
      return prev;
    });

    const url = ConvertObjectToURL(values);
    return await getInfo(url);
  };

  return (
    <>
      <Spin spinning={loading}>
        <AppContent
          breadcrumbItems={breadcrumbItems}
          withfilter={true}
          button={
            <>
              <div>
                <TableFilter
                  form={form}
                  onInitialLoad={({ data, pagination }) => {
                    setPagination(pagination);

                    if (data) {
                      setTableData(data);
                      setLoading(false);
                    }
                  }}
                  defaultValues={{
                    dateCustom: [...CustomDatePresets.Today],
                    value: '',
                    skip: 0,
                    count: 100
                  }}
                  initial={true}
                  onSubmit={onSubmitFilter}
                  styleforbuttons={'flex items-center justify-end'}
                  style={
                    'grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3  xl:grid-cols-4 gap-2 items-center justify-center'
                  }
                  buttons={
                    <>
                      {checkAccess('CREATE_SELL') && (
                        <CustomButton
                          onClick={() => navigate(`/tasks/new`)}
                          text="Add"
                          backgroundColor="#1890ff"
                          // marginTop="0px"
                          // Linkto="/tasks/new"
                        />
                      )}
                    </>
                  }>
                  <Form.Item name="status" label="Select Status">
                    <Select
                      placeholder="Select Task Status"
                      style={{ borderRadius: '10px' }}
                      allowClear
                      dropdownMatchSelectWidth={false}>
                      {selectOptions.map((value) => {
                        return (
                          <Select.Option value={value} key={value}>
                            {value}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </TableFilter>
              </div>
              {/* </div> */}
            </>
          }>
          <CustomizeTable
            form={form}
            columns={columns}
            data={tableData.results}
            notshowPagination={true}
            customScroll={{ x: 1000, y: '75vh' }}
            tableName={'tasks-archived-list'}
            paginationDatas={{
              page: pagination.page,
              total: tableData.count,
              size: pagination.size,
              onPagination
            }}
          />
        </AppContent>
      </Spin>
    </>
  );
};

// Put the thing into the DOM!
export default ArchivedTaskList;

function addOrUpdateState(newState: any, item: any, status: any) {
  const pendingSection = newState.find((item: any) => item.name === status);
  if (pendingSection) {
    pendingSection.tasks.push({
      id: JSON.stringify(item.id),
      title: item.name,
      description: item.description,
      dueDate: item.dueDate,
      status: item.status,
      assignee: item.assignee,
      reference: item.reference,
      referenceId: item.referenceId
    });
  } else {
    newState.push({
      name: status,
      tasks: [
        {
          id: JSON.stringify(item.id),
          title: item.name,
          description: item.description,
          dueDate: item.dueDate,
          status: item.status,
          assignee: item.assignee,
          reference: item.reference,
          referenceId: item.referenceId
        }
      ]
    });
  }
}
