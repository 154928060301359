import { Button, Form, message, Select, Spin, TableProps } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { useEffect, useState, useRef } from 'react';
import AppContent from '@/components/Common/Content/Content';
import TableFilter from '@/components/FliterTable';
import useDebounce from '@/hooks/useDebounce';
import { get_location_list } from '@/services/locations/queries';
import { get_product_list_ids } from '@/services/products/queries';
import { get_sell_order_form_filter_v2 } from '@/services/purchases/queries';
import { get_report_string } from '@/services/report/queries';
import ProductsDB from '@/store/localstorage/ProductsDB';
import { SorterResult } from 'antd/lib/table/interface';
import { getUserData } from '@/utils/auth.utils';
import { ExportColumnType, exportExcel } from '@/utils/exportExcel';
import CustomButton from '@/components/Common/CustomButton/CustomButton';
import { useReactToPrint } from 'react-to-print';
import { CustomModal } from '@/components/Common/CustomModal';
import CustomTable from '@/components/Common/CustomResuableInvoice/CustomTable';
import FullScreenModal from '@/components/Common/FullScreenModal';
import { ConvertObjectToURL } from '@/utils/converturl';
import { IProductType } from '@/services/products/types';
import LocationSearchV2 from '@/components/Common/CustomSearch/Location';
import useWindowScrollRestoration from '@/hooks/useWindowScrollRestoration';

const ProductSellOrderView = () => {
  const printPDFRef = useRef<any>();
  const [form] = Form.useForm();
  const [data, setData] = useState<any[]>([]);

  const [isloading, setIsloading] = useState<boolean>(true);
  const { preferences } = getUserData();
  const preferenceLocationId = preferences?.preferences
    ? JSON.parse(preferences?.preferences)?.locationId
    : undefined;
  const [openmodalforExport, setOpenModalforexport] = useState<boolean>(false);

  const onSearch = async () => {
    setIsloading(true);
    const response = await get_sell_order_form_filter_v2({
      locationId: form.getFieldValue('locationId'),
      startDate: moment(form.getFieldValue(['date']))
        .utc()
        .format(),
      endDate: moment(form.getFieldValue(['date']))
        .utc()
        .add(1, 'days')
        .format()
    });

    let newfilter = ConvertObjectToURL({
      locationId: form.getFieldValue('locationId'),
      startDate: moment(form.getFieldValue(['date']))
        .utc()
        .format(),
      endDate: moment(form.getFieldValue(['date']))
        .utc()
        .add(1, 'days')
        .format()
    });

    newfilter += '&page=1&size=1000';
    const responseSold: any = (await get_report_string(newfilter, 36)).data;
    // console.log(responseSold);
    const searchProducts: any = {};
    for (let index = 0; index < response.data.data.length; index++) {
      const product: any = await ProductsDB.getProduct(response.data.data[index].productId);
      if (!product) {
        // product = await get_product_details(purchaseDetails.lines[index].productId);
        // await ProductsDB.addProducts([product]);
        if (response.data.data[index].productId in searchProducts) {
          searchProducts[response.data.data[index].productId] = [
            ...searchProducts[response.data.data[index].productId],
            index
          ];
        } else {
          searchProducts[response.data.data[index].productId] = [index];
        }

        // if(responseSold)
      } else {
        response.data.data[index].productName = product.name;
      }
      if (responseSold.data.length > 0) {
        const find = responseSold.data.find(
          (val: any) => val.product_id === response.data.data[index].productId
        );
        if (find) {
          response.data.data[index].soldAmount = find.quantity;
          responseSold.data = responseSold.data.filter(
            (val: any) => val.product_id != response.data.data[index].productId
          );
        } else {
          response.data.data[index].soldAmount = 0;
        }
      } else {
        response.data.data[index].soldAmount = 0;
      }
    }
    // for (let index = 0; index < responseSold.data.length; index++) {
    //   console.log(response.data.data);
    //   response.data.data.push({
    //     productId: responseSold.data[index].product_id,
    //     ordered: 0,
    //     soldAmount: responseSold.data[index].qty
    //   });
    //   const product: any = await ProductsDB.getProduct(responseSold.data[index].product_id);
    //   if (!product) {
    //     if (response.data.data[response.data.data.length - 1].productId in searchProducts) {
    //       searchProducts[response.data.data[response.data.data.length - 1].productId] = [
    //         ...searchProducts[response.data.data[response.data.data.length - 1].productId],
    //         response.data.data.length - 1
    //       ];
    //     } else {
    //       searchProducts[response.data.data[response.data.data.length - 1].productId] = [
    //         index
    //       ];
    //     }
    //   } else {
    //     response.data.data[response.data.data.length - 1].productName = product.name;
    //   }
    // }

    const searchProductslength = Object.entries(searchProducts).length;
    if (searchProductslength > 0) {
      const productsresponse = await get_product_list_ids([...Object.keys(searchProducts)]);
      for (const key in searchProducts) {
        const findproduct = productsresponse?.data?.results.find(
          (currProduct: IProductType) => currProduct.id == key
        );
        if (findproduct) {
          for (let i = 0; i < searchProducts[key].length; i++) {
            response.data.data[searchProducts[key][i]].productName = findproduct?.name;
          }
          await ProductsDB.addProducts([findproduct]);
        }
      }
    }
    setIsloading(false);
    setData(response.data.data);
    return response.data.data;
  };

  useWindowScrollRestoration({ totalLength: data.length });

  const breadcrumbItems = [
    {
      label: 'Order vs Estimated Stock'
    }
  ];

  const handleExport = () => {
    setIsloading(true);
    try {
      const columns: ExportColumnType[] = [
        {
          title: 'ID',
          width: 50,
          dataIndex: 'id'
        },
        {
          title: 'Product Name(SKU)',
          dataIndex: 'productName',

          width: 200
        },
        {
          title: 'Order Vs Estimated Stock',
          width: 250,
          dataIndex: 'OrderedVsSold'
        },
        {
          title: 'Total',
          width: 100,
          dataIndex: 'totalAmount'
        }
      ];
      if (data?.length === 0) {
        message.error('No Data to Export');
        return;
      }
      const dataUpdated = data?.map((item: any, index: number) => {
        return {
          ...item,
          OrderedVsSold: `Order: ${item.qty} | Stock: ${item.soldAmount}`
        };
      });
      exportExcel(columns, dataUpdated, 'Order Vs Estimated Stock');
    } catch (err: any) {
      console.log(err);
    } finally {
      setIsloading(false);
    }
  };

  const handlePDFExport = useReactToPrint({
    content: () => printPDFRef.current
  });

  const columsforPrint: any = [
    {
      label: 'Product Name',
      dataIndex: 'productName',
      render: (text: string) => {
        return <div className="text-center">{text}</div>;
      }
    },
    {
      label: 'Order Vs Estimated',
      dataIndex: 'OrderedVsSold',
      render: (text: string) => {
        return <div className="text-center">{text}</div>;
      }
    },
    {
      label: 'Total Amount(NPR)',
      dataIndex: 'totalAmount',
      render: (text: string) => {
        return <div className="text-center">{text}</div>;
      }
    }
  ];

  // console.log('data', data.todaysOrders);

  return (
    <Spin spinning={isloading}>
      <CustomModal
        footer={false}
        isModalOpen={openmodalforExport}
        setIsModalOpen={setOpenModalforexport}
        title="Order Vs Estimated Stock View For PDF print">
        <div style={{ maxHeight: '80vh', overflow: 'scroll' }}>
          <CustomTable
            columns={columsforPrint}
            datas={data || []}
            reff={printPDFRef}
            title={'Order Vs Estimated Stock'}
          />
          <div className="flex justify-end mt-3">
            <Button type="primary" onClick={handlePDFExport}>
              Print Pdf
            </Button>
          </div>
        </div>
      </CustomModal>
      <AppContent
        breadcrumbItems={breadcrumbItems}
        withfilter={true}
        button={
          <TableFilter
            defaultValues={{
              locationId: preferenceLocationId ? preferenceLocationId : 1,
              date: moment().format('YYYY-MM-DD')
            }}
            onInitialLoad={({ data }) => {
              if (data) {
                setData(data);
                setIsloading(false);
              }
            }}
            dateCustom={false}
            singleDate={true}
            initial={true}
            onSubmit={onSearch}
            form={form}
            timeVal={true}
            style={
              'grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3  xl:grid-cols-4 gap-2 items-center justify-center'
            }
            styleforbuttons="flex items-center justify-end">
            <LocationSearchV2 hasParentFormItem={false} name={'locationId'} />
          </TableFilter>
        }>
        <div className="flex justify-end ">
          <CustomButton text="Excel Export" backgroundColor="#1890ff" onClick={handleExport} />
          <CustomButton
            text="PDF Export"
            backgroundColor="#1890ff"
            onClick={() => setOpenModalforexport(true)}
          />
          <FullScreenModal>
            <div className="grid grid-cols-2 sm:grid-cols-4 md:grid-cols-6 xl:grid-cols-8 gap-2 mt-2">
              {data &&
                data.map((record: any, ind: number) => {
                  let percentSold = '';
                  let percentOrdered = '';
                  let overflow = false;
                  if (record.soldAmount >= record.qty) {
                    overflow = true;
                    if (record.soldAmount === 0) percentOrdered = '0%';
                    else percentOrdered = '100%';
                    percentSold = `${(record.qty / record.soldAmount) * 100}%`;
                    if (isNaN(record.qty / record.soldAmount)) percentSold = '0%';
                  } else {
                    if (record.qty === 0) percentSold = '0%';
                    else percentSold = '100%';
                    percentOrdered = `${(record.soldAmount / record.qty) * 100}%`;
                    if (isNaN(record.qty / record.soldAmount)) percentOrdered = '0%';
                  }
                  return (
                    <div className="cardforestimatestock" key={ind}>
                      {/* <div>{record.id}</div> */}
                      <div className="font-bold">{record.productName}</div>
                      <div color="black" style={{ width: '100%' }}>
                        <div>
                          Order ({record.soldAmount}) {overflow ? ' overFlow' : ''}
                        </div>
                        <div
                          style={{
                            height: '3px',
                            backgroundColor: overflow ? 'red' : 'green',
                            width: percentOrdered,
                            borderRadius: '4px'
                          }}></div>
                        <div>Estimated Stock ({record.qty})</div>
                        <div
                          style={{
                            height: '3px',
                            backgroundColor: 'blue',
                            width: percentSold,
                            borderRadius: '4px'
                          }}></div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </FullScreenModal>
        </div>

        <div className="grid grid-cols-2 sm:grid-cols-4 md:grid-cols-6 xl:grid-cols-8 gap-2 mt-2">
          {data &&
            data.map((record: any, ind: number) => {
              let percentSold = '';
              let percentOrdered = '';
              let overflow = false;
              if (record.soldAmount >= record.qty) {
                overflow = true;
                if (record.soldAmount === 0) percentOrdered = '0%';
                else percentOrdered = '100%';
                percentSold = `${(record.qty / record.soldAmount) * 100}%`;
                if (isNaN(record.qty / record.soldAmount)) percentSold = '0%';
              } else {
                if (record.qty === 0) percentSold = '0%';
                else percentSold = '100%';
                percentOrdered = `${(record.soldAmount / record.qty) * 100}%`;
                if (isNaN(record.qty / record.soldAmount)) percentOrdered = '0%';
              }
              return (
                <div className="cardforestimatestock" key={ind}>
                  {/* <div>{record.id}</div> */}
                  <div className="font-bold">{record.productName}</div>
                  <div color="black" style={{ width: '100%' }}>
                    <div>
                      Order ({record.soldAmount}) {overflow ? ' overFlow' : ''}
                    </div>
                    <div
                      style={{
                        height: '3px',
                        backgroundColor: overflow ? 'red' : 'green',
                        width: percentOrdered,
                        borderRadius: '4px'
                      }}></div>
                    <div>Estimated Stock ({record.qty})</div>
                    <div
                      style={{
                        height: '3px',
                        backgroundColor: 'blue',
                        width: percentSold,
                        borderRadius: '4px'
                      }}></div>
                  </div>
                </div>
              );
            })}
        </div>
      </AppContent>
    </Spin>
  );
};

export default ProductSellOrderView;
