import CopyButton from '@/components/Common/CopyButton';
import CustomizeTable from '@/components/Common/CustomizeTable/CustomizeTable';
import { DEFAULT_DATE_FORMAT } from '@/constants';
import { IPurchaseAgentBillCancel } from '@/services/purchases/types';
import { convertUTCStringtoLocalString } from '@/utils/convertToUTC';
import { FormInstance, TableProps, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { SorterResult } from 'antd/lib/table/interface';
import { useState } from 'react';
import { Link } from 'react-router-dom';

interface Props {
  form: FormInstance;
  isLoading: boolean;
  data: { total: number; results: IPurchaseAgentBillCancel[] };
  pagination: { page: number; size: number };
  onPagination: (page?: number, size?: number, isSize?: boolean) => Promise<unknown>;
}

function Table({ data, pagination, isLoading, onPagination, form }: Props) {
  const [sortedInfo, setSortedInfo] = useState<SorterResult<IPurchaseAgentBillCancel>>({});

  const handleChange: TableProps<IPurchaseAgentBillCancel>['onChange'] = (_, __, s) => {
    setSortedInfo(s as SorterResult<IPurchaseAgentBillCancel>);
  };

  const columns: ColumnsType<IPurchaseAgentBillCancel> = [
    {
      title: 'S.N.',
      key: 'id',
      width: 2,
      render: (_, __, index) => (pagination.page - 1) * pagination.size + index + 1
    },
    {
      title: 'Agent',
      dataIndex: 'agent',
      key: 'agent',
      width: 8,
      sortOrder: sortedInfo.columnKey === 'agent' ? sortedInfo.order : null,
      sorter: (a, b) => a.agent.localeCompare(b.agent)
    },
    {
      title: 'Bill ID',
      dataIndex: 'billRangeId',
      key: 'billRangeId',
      width: 3,
      render: (id: number) => (
        <div className="space-x-2">
          <Link to={`/purchases/agents-bill-range/view/${id}`}>{id}</Link>
          <CopyButton text={id.toString()} />
        </div>
      )
    },
    {
      title: 'Bill Number',
      dataIndex: 'billNumber',
      key: 'billNumber',
      width: 3,
      sortOrder: sortedInfo.columnKey === 'billNumber' ? sortedInfo.order : null,
      sorter: (a, b) => a.billNumber - b.billNumber
    },
    {
      title: 'Created By',
      dataIndex: 'createdUser',
      key: 'createdUser',
      width: 5,
      sortOrder: sortedInfo.columnKey === 'createdUser' ? sortedInfo.order : null,
      sorter: (a, b) => a.createdUser.localeCompare(b.createdUser)
    },
    {
      title: 'Media',
      dataIndex: 'mediaURL',
      key: 'mediaURL',
      width: 2,
      render: (url: string) => {
        if (!url) return '';
        return (
          <a href={url} target="_blank" rel="noreferrer">
            View
          </a>
        );
      }
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 6,
      render: (text: string) => convertUTCStringtoLocalString(text, DEFAULT_DATE_FORMAT),
      sortOrder: sortedInfo.columnKey === 'createdAt' ? sortedInfo.order : null,
      sorter: (a, b) => a.createdAt.localeCompare(b.createdAt)
    },
    {
      title: 'Updated At',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      width: 6,
      render: (text: string) => convertUTCStringtoLocalString(text, DEFAULT_DATE_FORMAT),
      sortOrder: sortedInfo.columnKey === 'updatedAt' ? sortedInfo.order : null,
      sorter: (a, b) => a.updatedAt.localeCompare(b.updatedAt)
    },
    {
      title: 'Reason',
      dataIndex: 'reason',
      key: 'reason',
      width: 15,
      render: (text: string) => {
        return (
          <Tooltip title={text} className="text-ellipsis">
            {text}
          </Tooltip>
        );
      }
    }
  ];

  return (
    <CustomizeTable
      form={form}
      columns={columns}
      data={data.results}
      usersLoading={isLoading}
      tableName="agent-bill-range-cancel-list"
      toSort={handleChange}
      notshowPagination={true}
      customScroll={{ x: 1450, y: '75vh' }}
      paginationDatas={{
        page: pagination.page,
        total: data.total,
        size: pagination.size,
        onPagination
      }}
    />
  );
}

export default Table;
