import CustomGreenButton from '@/components/Common/CustomButton/CustomGreenButton';
import LocationSearchV2 from '@/components/Common/CustomSearch/Location';
import TableFilter from '@/components/FliterTable';
import { CustomDatePresets } from '@/pages/sqlsource/report/utils/datePresets';
import { Form, FormInstance, Input, Select } from 'antd';
import { useNavigate } from 'react-router-dom';

interface Props {
  form: FormInstance;
  onInitialLoad: (data?: unknown) => void;
  setPagination: React.Dispatch<React.SetStateAction<{ page: number; size: number }>>;
  onSubmit: (values: any) => Promise<unknown>;
}

function Filter({ form, ...handlers }: Props) {
  const navigate = useNavigate();

  return (
    <TableFilter
      initial
      form={form}
      onInitialLoad={({ data, pagination }) => {
        handlers.setPagination(pagination);
        handlers.onInitialLoad(data);
      }}
      onSubmit={handlers.onSubmit}
      styleforbuttons="flex items-center justify-end"
      style="grid sm:grid-cols-1 md:grid-cols-3 gap-2"
      defaultValues={{
        dateCustom: [...CustomDatePresets.Today],
        skip: 0,
        count: 100,
        value: '',
        archiveStatus: 'ACTIVE',
        locationId: ''
      }}
      buttons={
        <CustomGreenButton
          text="Add Agents"
          Linkto="/users/agents/new"
          onClick={() => navigate('/users/agents/new')}
        />
      }>
      <LocationSearchV2 hasParentFormItem={false} name={'locationId'} label="Location" />

      <Form.Item label="Search" name="value">
        <Input placeholder="Search" />
      </Form.Item>

      <Form.Item label="Status" name="archiveStatus">
        <Select allowClear>
          <Select.Option value={'ALL'}>All</Select.Option>
          <Select.Option value={'ACTIVE'}>Active</Select.Option>
          <Select.Option value={'INACTIVE'}>Inactive</Select.Option>
        </Select>
      </Form.Item>
    </TableFilter>
  );
}

export default Filter;
