import { AxiosResponse } from 'axios';
import http from '@/utils/http.utils';
import { ILeafyItem, ILeafyPayload } from './types';

export const create_leafy_items_mutation = async (values: ILeafyPayload) => {
  const response: AxiosResponse<ILeafyItem> = await http.store('leafy-items', values);
  return response.data;
};

export const update_leafy_items_mutation = async (id: number, values: ILeafyPayload) => {
  const response: AxiosResponse<ILeafyItem> = await http.update('leafy-items/' + id, values);
  return response.data;
};
