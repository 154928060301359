import moment from 'moment';
import AppContent from '@/components/Common/Content/Content';
import { getUser } from '@/services';
import { get_outlet_pricing_by_date, get_price_analysis_id } from '@/services/products/queries';
import { useQuery } from '@tanstack/react-query';
import { Descriptions, message, PageHeader, Spin } from 'antd';
import { useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { IRow } from '../grid.types';
import { formatPriceAnalysistoGridRow } from '../services';
import UpdatePriceAnalysisGrid from './Grid';
import { IOutletPricingByDate } from '@/services/products/types';
import useBeforeUnloadListener from '@/hooks/useBeforeUnloadListener';
import CustomInfoModal from '@/components/Common/CustomInfoModal';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';

function UpdatePriceAnalysis() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [rows, setRows] = useState<IRow[]>([]);
  const [isUpdating, setIsUpdating] = useState(false);
  const [yesterdayPriceAnalysis, setYesterdayPriceAnalysis] = useState<IOutletPricingByDate>();

  const [totalForecastValue, setTotalForecastValue] = useState({ b2b: 0, outlet: 0 });

  if (!id) {
    return <Navigate to="/price-analysis" replace />;
  }

  useBeforeUnloadListener();

  const breadcrumbs = [{ label: 'Price Analysis', link: '/price-analysis' }, { label: 'Update' }];

  async function getInfo() {
    const data = await get_price_analysis_id(Number(id));
    const { createdBy, date } = data.analysis;

    const isAllowedToEdit = moment(date).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD');
    if (!isAllowedToEdit) {
      message.error('You can only edit price analysis for today');
      navigate('/price-analysis');
      return;
    }

    if (createdBy) {
      const userDetails = await getUser(createdBy);
      data.analysis.createdUser = userDetails?.name;
    }

    const prevDate = moment(date).subtract(1, 'days').format('YYYY-MM-DD');
    const yesterdayPriceAnalysis = await get_outlet_pricing_by_date(prevDate);
    setYesterdayPriceAnalysis(yesterdayPriceAnalysis);

    // Get Latest Opening Stock
    const { rows: updatedRows, isStockChanged } = await formatPriceAnalysistoGridRow(data, {
      latestStock: true
    });

    if (isStockChanged) {
      CustomInfoModal({
        width: 600,
        title: 'Opening Stock Changed',
        message: (
          <div>
            <p>
              Since this price analysis was created, opening stock of some products have changed.
            </p>
            <p>
              As a result, the latest opening stock has been updated. Don't forget to update the
              price analysis.
            </p>
          </div>
        )
      });
    }

    // Get Total Forecast Value
    const forecastedValue = updatedRows.reduce(
      (acc, row) => {
        return { b2b: acc.b2b + row.b2bForecast, outlet: acc.outlet + row.outletForecast };
      },
      { b2b: 0, outlet: 0 }
    );

    updatedRows.sort((a, b) => a.productName.trim().localeCompare(b.productName.trim()));
    setRows(updatedRows);
    setTotalForecastValue(forecastedValue);
    return data.analysis;
  }

  const { data, isLoading, isFetching } = useQuery(['priceAnalysis', id], getInfo);

  return (
    <Spin spinning={isLoading || isFetching || isUpdating}>
      <AppContent breadcrumbItems={breadcrumbs}>
        <Descriptions
          bordered
          column={{
            lg: 3,
            md: 2,
            sm: 1,
            xs: 1
          }}>
          <Descriptions.Item label="Date">{data?.date}</Descriptions.Item>
          <Descriptions.Item label="Created By">{data?.createdUser}</Descriptions.Item>
        </Descriptions>

        <div className="mt-2">
          <PageHeader
            subTitle="Product Price Analysis"
            style={{ padding: '8px 0' }}
            extra={
              <div className="flex gap-2 flex-wrap">
                <span>
                  <strong>B2B:</strong> {nepaliNumberFormatter(totalForecastValue.b2b)},
                </span>
                <span>
                  <strong>Outlet:</strong> {nepaliNumberFormatter(totalForecastValue.outlet)}
                </span>
              </div>
            }
          />
          <UpdatePriceAnalysisGrid
            date={data?.date}
            rows={rows}
            id={Number(id)}
            setRows={setRows}
            setIsLoading={setIsUpdating}
            priceAnalysis={yesterdayPriceAnalysis}
            setTotalForecastValue={setTotalForecastValue}
          />
        </div>
      </AppContent>
    </Spin>
  );
}

export default UpdatePriceAnalysis;
