import { IProductIdentifierCount, IProductIdentifierSumAmount } from '@/services/products/types';
import { LineType } from '@/services/report/enums';
import { getDeepCopy } from '@/utils';
import getGroupedData from '@/utils/getGroupedData';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';
import { ColumnsType } from 'antd/lib/table';
import { ISumReportRow } from '.';

function processLineSum(data: IProductIdentifierSumAmount[]) {
  const categorySorted = getDeepCopy(data).sort((a, b) =>
    a.categoryName.localeCompare(b.categoryName)
  );
  const categoryGrouped = getGroupedData(categorySorted, 'categoryName');

  const updatedRows = Object.keys(categoryGrouped).map((category) => {
    const rows = categoryGrouped[category];

    // Convert array of rows into a single object with location names as keys
    const tableRow = rows.reduce((acc, row) => {
      acc[row.locationName] = parseFloat(row.sumTotalAmount);
      return acc;
    }, {} as Record<string, number>);

    return { description: category, isHeader: false, ...tableRow };
  }) as Array<ISumReportRow>;

  return updatedRows;
}

export function processIdentifier(data: IProductIdentifierSumAmount[], identifier: string) {
  const processData = processLineSum(data);

  const summedData = processData.reduce((acc, row) => {
    for (const key in row) {
      if (key !== 'description' && key !== 'isHeader') {
        acc[key] = (acc[key] || 0) + (row[key] as number);
      }
    }
    return acc;
  }, {} as Record<string, number>);

  const description = identifier === LineType.ADJUSTMENT ? `${identifier} (Rs)` : identifier;

  // Create the header row
  const headerRow = { description, isHeader: true, ...summedData };
  const finalData = [headerRow, ...processData] as Array<ISumReportRow>;
  return finalData;
}

export function generateColumns(columnsHeader: string[]) {
  if (columnsHeader.length === 0) {
    return [
      { title: 'DESCRIPTION', dataIndex: 'description', key: 'description', align: 'left' }
    ] as ColumnsType<ISumReportRow>;
  }

  const headers = columnsHeader.map((key) => ({
    title: key.toUpperCase(),
    dataIndex: key,
    key,
    align: key === 'description' ? 'left' : 'right',
    render: (text, record) => {
      const skipDesc = [`${LineType.ADJUSTMENT} (%)`, 'Gross Profit/Loss'];
      if (skipDesc.includes(record.description)) {
        return <strong className="!font-bold">{text}</strong>;
      }

      const value = key === 'description' ? text : text ? nepaliNumberFormatter(text) : '';
      if (!record.isHeader) return value;

      return <strong className="!font-bold">{value}</strong>;
    }
  })) as ColumnsType<ISumReportRow>;

  return headers;
}

export function calculateWastage(headerColumns: string[], data: Record<LineType, ISumReportRow[]>) {
  const wastage = data[LineType.ADJUSTMENT] || [];
  const transferIn = data[LineType.TRANSFER_IN] || [];
  const purchase = data[LineType.PURCHASE] || [];

  const wastageHeader = wastage.find((w) => w.isHeader);
  const transferInHeader = transferIn.find((w) => w.isHeader);
  const purchaseHeader = purchase.find((w) => w.isHeader);

  const wastagePercentage = headerColumns.reduce((acc, column) => {
    const wastageValue = wastageHeader?.[column] || 0;
    const transferInValue = transferInHeader?.[column] || 0;
    const purchaseValue = purchaseHeader?.[column] || 0;

    if (column === 'description') {
      acc[column] = `${LineType.ADJUSTMENT} (%)`;
      return acc;
    }

    const denominator = purchaseValue + transferInValue;
    const value = nepaliNumberFormatter(denominator !== 0 ? (wastageValue / denominator) * 100 : 0);

    acc[column] = `${value} %`;

    return acc;
  }, {} as Record<string, string>);
  return { isHeader: true, ...wastagePercentage } as ISumReportRow;
}

export function calculateGrossProfit(
  headerColumns: string[],
  data: Record<LineType, ISumReportRow[]>
) {
  const sales = data[LineType.SALE] || [];
  const salesReturn = data[LineType.SALE_RETURN] || [];
  const transferIn = data[LineType.TRANSFER_IN] || [];
  const purchase = data[LineType.PURCHASE] || [];

  const salesHeader = sales.find((w) => w.isHeader);
  const salesReturnHeader = salesReturn.find((w) => w.isHeader);
  const transferInHeader = transferIn.find((w) => w.isHeader);
  const purchaseHeader = purchase.find((w) => w.isHeader);

  const grossProfitLoss = headerColumns.reduce((acc, column) => {
    const saleValue = salesHeader?.[column] || 0;
    const saleReturnValue = salesReturnHeader?.[column] || 0;
    const transferInValue = transferInHeader?.[column] || 0;
    const purchaseValue = purchaseHeader?.[column] || 0;

    if (column === 'description') {
      acc[column] = 'Gross Profit/Loss';
      return acc;
    }

    const value = saleValue - saleReturnValue - transferInValue - purchaseValue;
    const formattedValue = nepaliNumberFormatter(Math.abs(value));

    acc[column] = value >= 0 ? formattedValue : `(${formattedValue})`;

    return acc;
  }, {} as Record<string, string>);
  return { isHeader: false, ...grossProfitLoss } as ISumReportRow;
}

export function calculateNoOfTransaction(headerColumns: string[], data: IProductIdentifierCount[]) {
  const noOfTransaction = headerColumns.reduce((acc, column) => {
    if (column === 'description') {
      acc[column] = 'No. of Transaction';
      return acc;
    }

    const currentColData = data.find((d) => d.locationName === column);
    const value = currentColData?.identifierCount ? parseFloat(currentColData?.identifierCount) : 0;
    acc[column] = value;
    return acc;
  }, {} as Record<string, number | string>);

  return { isHeader: true, ...noOfTransaction } as ISumReportRow;
}

export function calculateAvgBasketValue(
  headerColumns: string[],
  data: Record<LineType, ISumReportRow[]>,
  dataCount: IProductIdentifierCount[]
) {
  const sales = data[LineType.SALE] || [];
  const salesHeader = sales.find((w) => w.isHeader);

  const avgBasketValue = headerColumns.reduce((acc, column) => {
    const saleValue = salesHeader?.[column] || 0;

    if (column === 'description') {
      acc[column] = 'Avg Basket Value';
      return acc;
    }

    const currentColData = dataCount.find((d) => d.locationName === column);
    const count = currentColData?.identifierCount ? parseFloat(currentColData?.identifierCount) : 0;
    acc[column] = count === 0 ? 0 : saleValue / count;

    return acc;
  }, {} as Record<string, string | number>);
  return { isHeader: false, ...avgBasketValue } as ISumReportRow;
}
