export enum SocketEvents {
  USER_NOTIFICATION = 'USER_NOTIFICATION',
  SYSTEM_NOTIFICATION = 'SYSTEM_NOTIFICATION'
}

export enum SocketNameSpaces {
  NOTIFICATION = 'ws/global-notification'
}

export enum SystemNotificationType {
  NEW_CUSTOMER = 'NEW_CUSTOMER',
  UPDATE_CUSTOMER = 'UPDATE_CUSTOMER',
  NEW_VENDOR = 'NEW_VENDOR',
  UPDATE_VENDOR = 'UPDATE_VENDOR',
  NEW_PRODUCT = 'NEW_PRODUCT',
  UPDATE_PRODUCT = 'UPDATE_PRODUCT',
  LOTS_UPDATE = 'LOTS_UPDATE',
  NEW_USER = 'NEW_USER',
  UPDATE_USER = 'UPDATE_USER',
  NEW_UNIT = 'NEW_UNIT',
  UPDATE_UNIT = 'UPDATE_UNIT',
  LOTS_ZERO = 'LOTS_ZERO',
  WOO_PRODUCTS = 'WOO_PRODUCTS'
}
