import ExcelJS from 'exceljs';

export interface ExportColumnType {
  title: string;
  dataIndex: string;
  isNumber?: boolean;
}

const borderStyle: Partial<ExcelJS.Borders> = {
  top: { style: 'thin', color: { argb: 'FF000000' } },
  left: { style: 'thin', color: { argb: 'FF000000' } },
  bottom: { style: 'thin', color: { argb: 'FF000000' } },
  right: { style: 'thin', color: { argb: 'FF000000' } }
};

const style: ExcelJS.Fill = {
  type: 'pattern',
  pattern: 'solid',
  fgColor: { argb: 'f2f2f2' }
};

export const exportExcelAutoWidth = async (
  columns: ExportColumnType[],
  rows: any[],
  name: string
) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet(name);

  // Define columns for ExcelJS
  const excelColumns: Partial<ExcelJS.Column>[] = columns.map((column) => ({
    header: column.title,
    key: column.dataIndex,
    width: column.title.length,
    style: {
      alignment: { vertical: 'middle', horizontal: 'left' }
    }
  }));

  worksheet.columns = excelColumns;

  // Add data to worksheet
  rows.forEach((item) => {
    const row: { [key: string]: any } = {};
    columns.forEach((column) => {
      const value = item[column.dataIndex];
      row[column.dataIndex] = value;

      // Calculate the max length for each column
      const columnLength = value ? value.toString().length : 10; // default length if no data
      const columnDef = worksheet.getColumn(column.dataIndex);
      columnDef.width = Math.max(columnDef?.width || 0, columnLength + 5);

      if (column?.isNumber) {
        columnDef.numFmt = '#,##,##0.00';
        columnDef.alignment = { horizontal: 'right' };
      }
    });
    worksheet.addRow(row);
  });

  worksheet.getColumn(1).eachCell((cell) => {
    cell.fill = style;
  });

  // Add header style: fill,  font, height
  worksheet.getRow(1).eachCell((cell) => {
    cell.fill = style;
    cell.font = { bold: true };
  });

  // Save the workbook to a file
  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  });

  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = `${name}.xlsx`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export function fillWorkSheet(
  rows: any[],
  columns: { label: string; dataIndex: string }[],
  worksheet: ExcelJS.Worksheet
) {
  rows.forEach((row) => {
    const rowCell = worksheet.addRow(row);
    columns.forEach((column, colIndex) => {
      const value = row[column.dataIndex];
      const cell = rowCell.getCell(colIndex + 1);

      cell.border = borderStyle;

      // Calculate the max length for each column
      const columnLength = value ? value.toString().length : 10; // default length if no data
      const columnDef = worksheet.getColumn(column.dataIndex);
      columnDef.width = Math.max(columnDef?.width || 0, columnLength + 5);
    });
  });

  worksheet.getColumn(1).eachCell((cell) => {
    cell.border = borderStyle;
  });

  // Add header style: fill,  font, height
  worksheet.getRow(1).eachCell((cell) => {
    cell.fill = style;
    cell.font = { bold: true };
  });
}
