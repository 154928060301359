import { RcFile } from 'antd/lib/upload';
import ExcelJS from 'exceljs';
import { OpeningStockExcelColumns } from './column.export';
import {
  IOpeningStockCheckResponse,
  IOpeningStockClientRow,
  OpeningStockHistoryLine
} from '@/services/stock/types';
import roundNumber from '@/utils/round.utils';
import ProductsDB from '@/store/localstorage/ProductsDB';
import { getProductById, getUnit } from '@/services';

export async function checkColumns(file: RcFile) {
  const buffer = await file.arrayBuffer();
  const workbook = new ExcelJS.Workbook();
  await workbook.xlsx.load(buffer);

  const sheet = workbook.worksheets[0];
  if (!sheet) {
    throw new Error('No sheets found in the uploaded file.');
  }

  const fileHeaders = sheet.columns.map((col) => col?.values?.[1] || '').filter(Boolean);
  const predefinedHeaders = OpeningStockExcelColumns.map((col) => col.title);
  const incorrectColumns: string[] = [];

  predefinedHeaders.forEach((expectedHeader, index) => {
    const actualHeader = fileHeaders[index] || 'MISSING COLUMN';
    if (actualHeader !== expectedHeader) {
      incorrectColumns.push(
        `Column ${index + 1} should be '${expectedHeader}' instead of '${actualHeader}'`
      );
    }
  });

  return { errors: incorrectColumns, sheet };
}

export function extractFile(sheet: ExcelJS.Worksheet): IOpeningStockClientRow[] {
  if (!sheet) {
    throw new Error('No sheets found in the uploaded file.');
  }

  const extractedData = sheet
    .getRows(2, sheet.rowCount - 1)
    ?.map((row, index) => {
      const quantity = Number(row.getCell(6).value) || 0; // col5 -> Quantity
      const qtyAvailable = Number(row.getCell(8).value) || 0; // col7 -> Qty Available
      const wastage = row.getCell(9).value ? Number(row.getCell(9).value) || 0 : 0; // col8 -> Wastage

      let unaccountedWastage = 0;

      if (quantity < qtyAvailable) {
        const remainingQty = roundNumber(qtyAvailable - quantity);
        const remainingQtyAfterWastage = roundNumber(remainingQty - wastage);

        if (remainingQtyAfterWastage > 0) {
          unaccountedWastage = remainingQtyAfterWastage;
        }
      }

      return {
        key: index,
        productName: row.getCell(3).text, // col2 -> Product Name
        unitName: row.getCell(5).text, // col4 -> Unit Name
        unitPrice: Number(row.getCell(7).value) || 0, // col6 -> Unit Price
        quantity,
        qtyAvailable,
        accountedWastage: wastage,
        unaccountedWastage
      };
    })
    .filter((row) => row.quantity > 0 && row.unitPrice > 0);

  return extractedData || [];
}

export async function convertResponseToRows(data: IOpeningStockCheckResponse['data']) {
  const { openingStockHistory } = data;

  const allProductIds = Array.from(new Set(openingStockHistory.map((h) => h.productId)));
  await ProductsDB.addProductsIfAbsent(allProductIds);

  const rows = [] as OpeningStockHistoryLine[];

  for (const history of openingStockHistory) {
    const product = await getProductById(history.productId);
    const unit = await getUnit(history.unitId);

    rows.push({ ...history, productName: product.name, unitName: unit.name });
  }

  // Sort rows by productName
  rows.sort((a, b) => a.productName.localeCompare(b.productName));
  return rows;
}
