import React, { useState } from 'react';
import { Form, Spin } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import AppContent from '@/components/Common/Content';
import TableFilter from '@/components/FliterTable';
import moment from 'moment';
import TableCell from '@/components/Common/CustomizeTable/CustomCell';
import { get_customer_list_ids, get_wallet_payments } from '@/services/users/queries';
import { IWalletPaymentsListData, IWalletPaymentsListResponse } from '@/services/users/types';
import { convertUTCStringtoLocalString } from '@/utils/convertToUTC';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';
import CustomersDB from '@/store/localstorage/CustomerDB';
import { CustomModal } from '@/components/Common/CustomModal';
import ReusableSellView from '../../sell/view/ReusableView';
import { DEFAULT_DATE_FORMAT } from '@/constants';
import GenericTable from '@/components/Common/CustomizeTable';
import CustomerSearchByUserIdV2 from '@/components/Common/CustomSearch/Customer/CustomerSearchByUserId';
import handlePagination from '@/utils/handlePagination';
import { ConvertObjectToURL } from '@/utils/converturl';

const WalletPayments: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [walletPaymentsList, setwalletPaymentsList] = useState<IWalletPaymentsListResponse>({
    total: 0,
    results: []
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [id, setId] = useState(0);
  const [form] = Form.useForm();
  const [pagination, setPagination] = useState({ page: 1, size: 100 });

  const columns: ColumnsType<IWalletPaymentsListData> = [
    {
      title: 'S.N',
      key: 'sn',
      width: 10,
      render: (record: IWalletPaymentsListData) => {
        return <TableCell> {record.id}</TableCell>;
      }
    },
    {
      title: 'Payment ID',
      key: 'paymentId',
      width: 15,
      render: (record: IWalletPaymentsListData) => {
        return (
          <TableCell>
            <a
              onClick={() => {
                setIsModalOpen(true);
                setId(record.payment.againstId);
              }}>
              {record.paymentId}
            </a>
          </TableCell>
        );
      }
    },
    {
      title: 'Customer',
      key: 'customer',
      width: 50,
      render: (record: IWalletPaymentsListData) => {
        return <TableCell>{record.userName}</TableCell>;
      }
    },
    {
      title: 'Amount',
      key: 'amount',
      width: 50,
      dataIndex: 'amount',
      render: (amount: number) => {
        return (
          <div style={{ color: amount < 0 ? 'red' : 'green' }}>
            <TableCell>{nepaliNumberFormatter(amount)}</TableCell>
          </div>
        );
      }
    },
    {
      title: 'Date',
      key: 'date',
      width: 50,
      render: (record: IWalletPaymentsListData) => {
        return (
          <TableCell>
            {convertUTCStringtoLocalString(record.createdAt, DEFAULT_DATE_FORMAT)}
          </TableCell>
        );
      }
    }
  ];

  const breadcrumbItems = [{ label: 'Wallet Payments', link: '/wallet-payments' }];

  const onSubmitFilter = async (val: string) => {
    const response = await getInfo(val);
    setPagination({ page: 1, size: 100 });
    return response;
  };

  const getInfo = async (filter = '') => {
    setIsLoading(true);
    const response = await get_wallet_payments(filter);
    const searchUsers: any = {};
    for (let index = 0; index < response.data.results.length; index++) {
      const { customerId } = response.data.results[index];
      //   console.log('customerId', customerId);
      if (!customerId) continue;
      const user: any = await CustomersDB.getCustomer(customerId);
      if (!user) {
        // user = await get_customer_details(customerId);
        // user = { ...user.customer, user: user.user.user };
        // await CustomersDB.getCustomeromers([user]);
        if (customerId in searchUsers) {
          searchUsers[customerId] = [...searchUsers[customerId], index];
        } else {
          searchUsers[customerId] = [index];
        }
      } else {
        response.data.results[index].userName = user.user.name;
        response.data.results[index].userData = user;
      }
    }
    // console.log('Search User Length', searchUsers);
    const searchUserslength = Object.entries(searchUsers).length;
    if (searchUserslength > 0) {
      const customerresponse = await get_customer_list_ids([...Object.keys(searchUsers)]);
      for (const key in searchUsers) {
        const customerUser = customerresponse?.data?.results.find(
          (currCustomer: any) => currCustomer.id == key
        );
        for (let i = 0; i < searchUsers[key].length; i++) {
          response.data.results[searchUsers[key][i]].userName = customerUser?.user.name;
          response.data.results[searchUsers[key][i]].userData = customerUser!;
        }
        await CustomersDB.addCustomers([customerUser]);
      }
    }

    setwalletPaymentsList(response.data);
    setIsLoading(false);
    return response.data;
  };

  const onPagination = async (page = 1, size = 100, isSize = false) => {
    setIsLoading(true);
    const { values, ...pagination } = handlePagination({
      formValues: form.getFieldsValue(),
      page,
      size,
      isSize
    });

    setPagination((prev) => {
      prev.page = pagination.page;
      if (isSize) prev.size = pagination.size;
      return prev;
    });

    const url = ConvertObjectToURL(values);
    return await getInfo(url);
  };

  return (
    <Spin spinning={isLoading}>
      <CustomModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} title={'Sell View'}>
        <ReusableSellView id={id} />
      </CustomModal>
      <AppContent
        breadcrumbItems={breadcrumbItems}
        withfilter={true}
        button={
          <>
            <TableFilter
              form={form}
              onInitialLoad={({ data, pagination }) => {
                setPagination(pagination);

                if (data) {
                  setwalletPaymentsList(data);
                  setIsLoading(false);
                }
              }}
              defaultValues={{
                dateCustom: [moment(0, 'HH'), moment(0, 'HH').add(1, 'days')],
                userId: ''
              }}
              initial={true}
              onSubmit={onSubmitFilter}>
              <CustomerSearchByUserIdV2 hasParentFormItem={false} name={'userId'} showAll />
            </TableFilter>
            {/* </div> */}
          </>
        }>
        <GenericTable
          form={form}
          hideDefaultPagination
          scroll={{ x: 1000, y: '75vh' }}
          columns={columns}
          data={walletPaymentsList ? walletPaymentsList.results : []}
          tableName={'customerwallet-list'}
          generateSummary
          pagination={{ ...pagination, total: walletPaymentsList.total, onPagination }}
        />
      </AppContent>
    </Spin>
  );
};

export default WalletPayments;
