import moment from 'moment';
import { removeFalsy } from './removeUndefined';

export function deepEqual(obj1: Record<string, unknown>, obj2: Record<string, unknown>) {
  const cleanObj1 = removeFalsy(obj1);
  const cleanObj2 = removeFalsy(obj2);

  // Return false if the objects have different number of keys
  if (Object.keys(cleanObj1).length !== Object.keys(cleanObj2).length) {
    return false;
  }

  // Check if all keys and values are equal
  for (const key in cleanObj1) {
    if (!(key in cleanObj2)) {
      return false;
    }

    const val1 = cleanObj1[key];
    const val2 = cleanObj2[key];

    // Check if the types are the same
    if (typeof val1 !== typeof val2) {
      return false;
    }

    // Handle nested objects
    if (typeof val1 === 'object' && val1 !== null && !moment.isMoment(val1)) {
      // Check against type
      if (typeof val2 !== 'object' || val2 === null || moment.isMoment(val2)) {
        return false;
      }

      // Make recursive call
      if (!deepEqual(val1 as Record<string, unknown>, val2 as Record<string, unknown>)) {
        return false;
      }

      continue; // Move to the next key
    }

    // Handle Arrays
    if (Array.isArray(val1)) {
      // If not an array or not the same length, return false
      if (!Array.isArray(val2) || val1.length !== val2.length) {
        return false;
      }

      for (let i = 0; i < val1.length; i++) {
        if (val1[i] !== val2[i]) {
          return false;
        }
      }

      continue; // Move to the next key
    }

    // Handle Moment objects
    if (moment.isMoment(val1)) {
      if (!moment.isMoment(val2) || !val1.isSame(val2)) {
        return false;
      }

      continue; // Move to the next key
    }

    // Handle primitive values (number, string, boolean, etc.)
    if (val1 !== val2) {
      return false;
    }
  }

  return true;
}
