import { Button, DatePicker, Form, message, Select, Spin, TableProps } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import AppContent from '../../../components/Common/Content/Content';
import CustomizeTable from '../../../components/Common/CustomizeTable/CustomizeTable';
import TableFilter from '../../../components/FliterTable';
import useDebounce from '../../../hooks/useDebounce';
import { get_location_list } from '../../../services/locations/queries';
import {
  get_product_list,
  get_product_list_ids,
  get_units_list
} from '../../../services/products/queries';
import LocationsDB from '../../../store/localstorage/LocationsDB';
import ProductsDB from '../../../store/localstorage/ProductsDB';
import httpUtils from '../../../utils/http.utils';
import { SorterResult } from 'antd/lib/table/interface';
import UnitsDB from '../../../store/localstorage/UnitsDB';
import CustomButton from '../../../components/Common/CustomButton/CustomButton';
import { CustomModal } from '../../../components/Common/CustomModal';
import FullScreenModal from '../../../components/Common/FullScreenModal';

const SellOrderCurrentStockView = () => {
  const [form] = Form.useForm();
  const [allInformation, setAllInformation] = useState<any>();
  const [isLoading, setIsLoading] = useState(true);
  const [selectedLocation, setselectedLocation] = useState<number>();
  const [searchValue, setSearchValue] = useState<string>('');

  const debouncedSearchValue = useDebounce(searchValue, 500);
  useEffect(() => {
    searchLocation(debouncedSearchValue);
  }, [debouncedSearchValue]);
  const [locationSearch, setLocationSearch] = useState<any>([]);
  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({});
  const handleChange: TableProps<any>['onChange'] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<any>);
  };

  const searchLocation = async (value: any) => {
    let response;
    try {
      response = await get_location_list(0, 5, value);
    } catch (e) {
      console.log(e);
    }
    if (!response || response.data.results.length == 0) {
      message.error('Cannot find any location with that name!');
      setLocationSearch([]);
    } else {
      setLocationSearch(response.data.results);
    }
  };
  const getCurrentStock = async (locationId: string) => {
    const response = await httpUtils.get(`/data/25?page=1&size=10000&` + locationId);
    return response;
  };
  const getSaleOrder = async (locationId: string) => {
    const response = await httpUtils.get(
      `/data/26?page=1&size=1000&startDate=${moment()
        .subtract(100, 'days')
        .format('YYYY-MM-DD')}&endDate=${moment().add(1, 'days').format('YYYY-MM-DD')}&` +
        locationId
    );
    return response;
  };

  const onSearch = async (val: string) => {
    setIsLoading(true);
    const currentResponse = (await getCurrentStock(val)).data;
    const orderResponse = (await getSaleOrder(val)).data;
    const searchProducts: any = {};
    for (let index = 0; index < currentResponse.data.length; index++) {
      const findOrder = orderResponse.data.find(
        (val: any) => val.product_id == currentResponse.data[index].product_id
      );
      if (findOrder) currentResponse.data[index].order = findOrder.quantity_ordered;
      else currentResponse.data[index].order = 0;
      const product: any = await ProductsDB.getProduct(currentResponse.data[index].product_id);
      if (!product) {
        // product = await get_product_details(purchaseDetails.lines[index].productId);
        // await ProductsDB.addProducts([product]);
        if (currentResponse.data[index].product_id in searchProducts) {
          searchProducts[currentResponse.data[index].product_id] = [
            ...searchProducts[currentResponse.data[index].product_id],
            index
          ];
        } else {
          searchProducts[currentResponse.data[index].product_id] = [index];
        }

        // if(responseSold)
      } else {
        let findUnit = product.productUnits.find((curr: any) => curr.isDefault == true);
        if (!findUnit) {
          findUnit = product.productUnits[0];
        }
        // console.log('Product', product);
        // console.log('Find Unit->', findUnit);
        let unitInfo: any = await UnitsDB.getUnit(findUnit.unitId);
        // console.log('unit id', findUnit.unitId);
        if (!unitInfo) {
          const allUnits = await get_units_list();
          await UnitsDB.addUnits(allUnits);
          unitInfo = await UnitsDB.getUnit(findUnit.unitId);
        }
        currentResponse.data[index].productName = product.name;
        currentResponse.data[index].baseUnitMultiplier = unitInfo.baseUnitMultiplier;
        currentResponse.data[index].unitshortName = unitInfo.shortName;
      }
    }
    const searchProductslength = Object.entries(searchProducts).length;
    if (searchProductslength > 0) {
      const productsresponse = await get_product_list_ids([...Object.keys(searchProducts)]);
      for (const key in searchProducts) {
        const findproduct = productsresponse?.data?.results.find(
          (currProduct: any) => currProduct.id == key
        );
        if (findproduct) {
          let findUnit = findproduct.productUnits.find((curr: any) => curr.isDefault == true);
          // console.log('Find Unit->', findUnit);
          if (!findUnit) {
            findUnit = findproduct.productUnits[0];
          }
          let unitInfo: any = await UnitsDB.getUnit(findUnit.unitId);
          // console.log('unit id', findUnit.unitId);
          if (!unitInfo) {
            const allUnits = await get_units_list();
            await UnitsDB.addUnits(allUnits);
            unitInfo = await UnitsDB.getUnit(findUnit.unitId);
          }
          for (let i = 0; i < searchProducts[key].length; i++) {
            currentResponse.data[searchProducts[key][i]].productName = findproduct.name;
            currentResponse.data[searchProducts[key][i]].baseUnitMultiplier =
              unitInfo.baseUnitMultiplier;
            currentResponse.data[searchProducts[key][i]].unitshortName = unitInfo.shortName;
          }
          await ProductsDB.addProducts([findproduct]);
        }
      }
    }
    setAllInformation(currentResponse);
    setIsLoading(false);
  };
  const breadcrumbItems = [
    {
      label: 'Sale Order Vs. Current Stock'
    }
  ];
  const options = locationSearch.map((value: any) => (
    <Select.Option key={value.id} value={value.id}>
      {value.name}
    </Select.Option>
  ));

  const columns: ColumnsType<any> = [
    // {
    //   title: 'ID',
    //   width: 50,

    //   key: 'id',
    //   render: (record: any) => {
    //     return <div color="black">{record.productid}</div>;
    //   }
    // },
    {
      title: 'Product Name(SKU)',
      key: 'productName',
      sorter: (a, b) => a.productName.localeCompare(b.productName),
      sortOrder: sortedInfo.columnKey === 'productName' ? sortedInfo.order : null,
      width: 200,
      render: (record: any) => {
        return <div color="black">{record.productName}</div>;
      }
    },

    // {
    //   title: 'Location',
    //   key: 'locationId',
    //   render: (record: any) => {
    //     return <div color="black">{record.locationId}</div>;
    //   }
    // },
    // {
    //   title: 'Stock',
    //   key: 'qty_available',
    //   width: 100,
    //   sorter: (a, b) => a.qty_available - b.qty_available,
    //   sortOrder: sortedInfo.columnKey === 'qty_available' ? sortedInfo.order : null,
    //   render: (record: any) => {
    //     return <div color="black">{record.qty_available}</div>;
    //   }
    // },
    // {
    //   title: 'Order',
    //   width: 100,
    //   key: 'order',
    //   sorter: (a, b) => a.order - b.order,
    //   sortOrder: sortedInfo.columnKey === 'order' ? sortedInfo.order : null,
    //   render: (record: any) => {
    //     return <div color="black">{record.order}</div>;
    //   }
    // },
    {
      title: 'Stock Vs Order',
      key: 'StockVsOrder',
      width: 100,
      render: (record: any) => {
        // console.log('Record', record);
        const checkpercent =
          (parseInt(record.order as string) / parseInt(record.qty_available as string)) * 100;
        // console.log(
        //   (parseInt(record.order as string) / parseInt(record.qty_available as string)) * 100
        // );
        return (
          <div color="black">
            <div color="black" style={{ width: '100%' }}>
              <div>
                Stock ({parseInt(record.qty_available as string) / record.baseUnitMultiplier}{' '}
                {record.unitshortName})
              </div>
              <div
                style={{
                  height: '5px',
                  backgroundColor: 'blue',
                  width: `${parseInt(record.qty_available) > 0 ? 100 : 0}%`,
                  borderRadius: '5px'
                }}></div>
              <div>
                Ordered ({parseInt(record.order as string) / record.baseUnitMultiplier}{' '}
                {record.unitshortName})
              </div>
              <div
                style={{
                  height: '5px',
                  backgroundColor: 'red',
                  width: `${isNaN(checkpercent) ? 0 : checkpercent}%`,
                  borderRadius: '5px'
                }}></div>
            </div>
          </div>
        );
      }
    }
  ];

  return (
    <Spin tip="Loading..." spinning={isLoading}>
      <AppContent
        // withfilter={true}
        breadcrumbItems={breadcrumbItems}
        withfilter={true}
        button={
          <>
            {/* <div className="flex flex-wrap items-center justify-end"> */}
            <div>
              {locationSearch.length > 0 && (
                <TableFilter
                  allowSaved={false}
                  defaultValues={{
                    // dateCustom: [moment(), moment().add(1, 'days')],
                    locationId: locationSearch[0].id
                  }}
                  dateCustom={false}
                  singleDate={false}
                  initial={true}
                  onSubmit={onSearch}
                  form={form}
                  // outsidestyle="flex justify-center items-center gap-2"
                  styleforbuttons="flex items-center justify-end">
                  <Form.Item
                    name={['locationId']}
                    label="Location"
                    rules={[
                      {
                        required: true,
                        message: 'Please choose location!'
                      }
                    ]}>
                    <Select
                      showSearch
                      placeholder={'search location'}
                      defaultActiveFirstOption={false}
                      showArrow={false}
                      filterOption={false}
                      onSelect={(val: number) => setselectedLocation(val)}
                      dropdownMatchSelectWidth={false}
                      onSearch={(val) => {
                        setSearchValue(val);
                      }}
                      notFoundContent={null}>
                      {options}
                    </Select>
                  </Form.Item>
                </TableFilter>
              )}
            </div>
            {/* </div> */}
          </>
        }>
        <CustomizeTable
          columns={columns}
          data={allInformation?.data}
          // usersLoading={isLoading}
          // notshowPagination={true}
          // toshowcustomPagintion={true}
          // onPagination={onPagination}
          // paginationDatas={{
          //   page: page,
          //   total: allpurchaseList.total,
          //   size: size,
          //   onPagination
          // }}
          toSort={handleChange}
        />
      </AppContent>
    </Spin>
  );
};

export default SellOrderCurrentStockView;
