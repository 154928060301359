import { Modal, Tabs } from 'antd';
import type { IWooCommerceMap } from '@/services/web-sockets/types';
import UpdateProgressSection from './progress/UpdateProgressSection';
import { useEffect, useState } from 'react';
import { IProductDetails } from '@/services/products/types';

type Props = {
  wooDataMap: Partial<IWooCommerceMap>;
  visible: boolean;
  onClose: () => void;
  getTotalItems: () => number;
  setLoading: (loading: boolean) => void;
  onRetry?: (failedProducts: IProductDetails[]) => Promise<void>;
};

export default function MultiProductSyncModal({
  wooDataMap,
  visible,
  onClose,
  getTotalItems,
  setLoading,
  onRetry
}: Props) {
  const hasStockData = Boolean(wooDataMap['stock-update']);
  const hasProductData = Boolean(wooDataMap['product-create-update']);

  const [activeTab, setActiveTab] = useState<'create' | 'stock'>('create');
  useEffect(() => {
    if (hasStockData) {
      setActiveTab('stock');
    }
  }, [hasStockData]);

  if (!hasProductData && !hasStockData) return null;

  return (
    <Modal
      title="WooCommerce Sync Progress"
      visible={visible}
      onCancel={onClose}
      footer={null}
      maskClosable={false}
      closable={true}
      width={700}>
      <Tabs activeKey={activeTab} onChange={(key) => setActiveTab(key as 'create' | 'stock')}>
        {hasProductData && (
          <Tabs.TabPane tab="Creating Products" key="create">
            <UpdateProgressSection
              dataKey="product-create-update"
              wooDataMap={wooDataMap}
              visible={visible}
              setLoading={setLoading}
              getTotalItems={getTotalItems}
              onClose={onClose}
              onRetry={onRetry}
              showTotalItems={false}
            />
          </Tabs.TabPane>
        )}
        {hasStockData && (
          <Tabs.TabPane tab="Updating Stock" key="stock">
            <UpdateProgressSection
              dataKey="stock-update"
              wooDataMap={wooDataMap}
              visible={visible}
              setLoading={setLoading}
              getTotalItems={getTotalItems}
              onClose={onClose}
              onRetry={onRetry}
              showTotalItems={true}
            />
          </Tabs.TabPane>
        )}
      </Tabs>
    </Modal>
  );
}
