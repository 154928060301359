import moment from 'moment';
import { find_date_preference } from '@/store/localstorage/preferences';
import { convert_nepali_to_english_date } from '@/utils/nepaliDateConverter';
import useFormInstance from 'antd/lib/form/hooks/useFormInstance';
import DatePicker, { RangePickerProps } from 'antd/lib/date-picker';
import { Form, Input } from 'antd';
import { NepaliDatePicker } from 'nepali-datepicker-reactjs';
import { useFinancialYearStore } from '@/store/zustand/financial-year';
import { useEffect, useState } from 'react';
import { FinancialDatePresets } from '@/pages/sqlsource/report/utils/datePresets';
import { convertLocalToUTCString } from '@/utils/convertToUTC';

type Props = {
  hasHours: boolean;
  open?: boolean;
  setOpen?: (open: boolean) => void;
  dateType: 'range' | 'single';
};

function AccountDateForm({ open, hasHours, dateType, setOpen }: Props) {
  const form = useFormInstance();
  const nepaliType = find_date_preference();

  const [currentFinancialPreset, setCurrentFinancialPreset] =
    useState<Record<string, [moment.Moment, moment.Moment]>>();

  const format = hasHours ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD';
  const { selectedFinancialYear } = useFinancialYearStore();

  useEffect(() => {
    if (!selectedFinancialYear) return;
    const { startDate, endDate, isCurrent } = selectedFinancialYear;
    const filteredPresets = Object.entries(FinancialDatePresets)
      .filter(([, [presetStart, presetEnd]]) => {
        const presetStartDate = moment(presetStart);
        const presetEndDate = moment(presetEnd);

        const isValidPresetStartDate =
          presetStartDate.isSameOrAfter(startDate) && presetEndDate.isSameOrBefore(endDate);

        return isValidPresetStartDate;
      })
      .reduce((obj, [key, value]) => {
        obj[key] = value;
        return obj;
      }, {} as Record<string, [moment.Moment, moment.Moment]>);

    if (isCurrent) {
      filteredPresets['Current financial year'] = [
        moment(startDate).startOf('day'),
        moment(endDate).endOf('day')
      ];
    }

    setCurrentFinancialPreset(filteredPresets);
  }, [selectedFinancialYear]);

  function onChangeSingle(value: string, type: 'english' | 'nepali') {
    const formValue = type === 'english' ? value : convert_nepali_to_english_date(value);
    form.setFieldValue(['date'], formValue);
  }

  const onChangeNepali = (val: string, isStart: boolean) => {
    if (isStart) form.setFieldValue(['startDate'], convert_nepali_to_english_date(val));
    else form.setFieldValue(['endDate'], convert_nepali_to_english_date(val));
  };

  const onChange: RangePickerProps['onChange'] = (dates) => {
    if (dates && dates?.length == 2) {
      const dateStrings = dates.map((date) =>
        convertLocalToUTCString(date?.clone() as moment.Moment)
      );
      form.setFieldValue(['startDate'], dateStrings[0]);
      form.setFieldValue(['endDate'], dateStrings[1]);
    }
  };

  const onDisabledDate = (date: moment.Moment | null, skipTodayCheck = false) => {
    if (!selectedFinancialYear) return false;

    // To disable dates outside the financial year range and today
    const isEndLater = moment(selectedFinancialYear.endDate).isAfter(moment());
    const startDate = moment(selectedFinancialYear.startDate).startOf('day');

    // If end date is later than today, then set end date to today
    const endDate =
      isEndLater && !skipTodayCheck
        ? moment().endOf('day')
        : moment(selectedFinancialYear.endDate).endOf('day');

    return date ? date.isBefore(startDate) || date.isAfter(endDate) : false;
  };

  return (
    <>
      <Form.Item
        label={'Select Date'}
        name={['dateSingleNepali']}
        hidden={dateType !== 'single' || !nepaliType}>
        <NepaliDatePicker
          className="w-full"
          onChange={(val) => onChangeSingle(val, 'nepali')}
          options={{ calenderLocale: 'ne', valueLocale: 'en' }}
        />
      </Form.Item>

      <Form.Item
        label={'Select Date'}
        name={['dateSingle']}
        hidden={dateType !== 'single' || nepaliType}>
        <DatePicker
          showTime={hasHours}
          onChange={(_, date) => onChangeSingle(date, 'english')}
          format={'YYYY-MM-DD'}
          className="w-full"
          disabledDate={(date) => onDisabledDate(date, false)}
          allowClear={false}
        />
      </Form.Item>

      <Form.Item
        label={'Select Start Date'}
        name={['startDateNepali']}
        hidden={dateType !== 'range' || !nepaliType}>
        <NepaliDatePicker
          className="w-full"
          onChange={(val) => onChangeNepali(val, true)}
          options={{ calenderLocale: 'ne', valueLocale: 'en' }}
        />
      </Form.Item>

      <Form.Item
        label={'Select End Date'}
        name={['endDateNepali']}
        hidden={dateType !== 'range' || !nepaliType}>
        <NepaliDatePicker
          className="w-full"
          onChange={(val) => onChangeNepali(val, false)}
          options={{ calenderLocale: 'ne', valueLocale: 'en' }}
        />
      </Form.Item>

      <Form.Item
        label={'Select Date'}
        name={['dateCustom']}
        hidden={dateType !== 'range' || nepaliType}>
        <DatePicker.RangePicker
          format={format}
          showTime={hasHours}
          allowClear={false}
          className="w-full"
          open={open}
          ranges={selectedFinancialYear?.isCurrent ? currentFinancialPreset : undefined}
          onChange={onChange}
          onOpenChange={setOpen}
          disabledDate={onDisabledDate}
        />
      </Form.Item>

      <Form.Item label={'Date'} name={['date']} hidden>
        <Input />
      </Form.Item>
      <Form.Item label={'Start Date'} name={['startDate']} hidden>
        <Input />
      </Form.Item>
      <Form.Item label={'End Date'} name={['endDate']} hidden>
        <Input />
      </Form.Item>
    </>
  );
}

export default AccountDateForm;
