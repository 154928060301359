import { IUnits } from '@/services/products/types';
import { Form, InputNumber } from 'antd';
import { Rule } from 'antd/lib/form';
import useFormInstance from 'antd/lib/form/hooks/useFormInstance';
import { NamePath } from 'antd/lib/form/interface';
import React, { useMemo } from 'react';

interface IProps {
  name: NamePath;
  onChange?: () => void;
  autofocus?: boolean;
  onPressEnter?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  minValue?: number;
  rules?: Rule[];
  disabled?: boolean;
  autoFocusRef?: React.Ref<HTMLInputElement>;
  allUnits?: IUnits[];
  label?: string;
  unitIdPath?: NamePath;
}
const ReusableSingleQuantity = ({
  onChange,
  autofocus = true,
  name,
  label = 'Quantity',
  onPressEnter,
  minValue = 0.1,
  rules = [{ required: true, message: 'Please add a Valid Quantity!' }],
  disabled = false,
  autoFocusRef,
  allUnits,
  unitIdPath = 'unitId'
}: IProps) => {
  const form = useFormInstance();
  const unitId = Form.useWatch(unitIdPath, form);

  const unitDetails = useMemo(() => allUnits?.find((val) => val.id === unitId), [allUnits, unitId]);
  const precision = unitDetails?.allowDecimal ? 3 : 0;

  return (
    <Form.Item label={label} name={name} rules={rules}>
      <InputNumber
        controls={false}
        ref={autoFocusRef}
        min={minValue}
        precision={unitDetails ? precision : 3}
        onChange={onChange}
        onPressEnter={onPressEnter}
        autoFocus={autofocus}
        disabled={disabled}
      />
    </Form.Item>
  );
};

export default ReusableSingleQuantity;
