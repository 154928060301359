import CustomSearch, { SelectProps } from '..';
import ProductsDB from '@/store/localstorage/ProductsDB';
import { IProductDetails } from '@/services/products/types';
import { get_product_active_list_only, get_product_list_only } from '@/services/products/queries';
import { message } from 'antd';

type MultipleProps =
  | {
      defaultValue?: number;
      isMultiple?: false;
      setSelected?: (selected?: IProductDetails) => void;
      onSelect?: (selected: number) => void;
    }
  | {
      defaultValue?: number[];
      isMultiple: true;
      setSelected?: (selected?: IProductDetails[]) => void;
      onSelect?: (selected: number[]) => void;
    };

type Props = SelectProps & MultipleProps & { showHidden?: boolean; locationId?: number };

function ProductSearchV2({ setSelected, showHidden = false, locationId, ...props }: Props) {
  async function fetchProducts(skip = 0, count = 1000, value = '', filter = '') {
    const params = new URLSearchParams(filter);
    if (locationId) params.set('locationId', locationId.toString());

    const updatedFilter = params.toString();
    return showHidden
      ? get_product_list_only(skip, count, value, filter)
      : get_product_active_list_only(skip, count, value, updatedFilter); // Location is also needed to determine if product is active/hidden
  }

  async function fetchProductsViaIndexDB(name = '', limit = 10) {
    return showHidden
      ? ProductsDB.searchProduct(name, limit)
      : ProductsDB.searchActiveProduct(name, limit, locationId);
  }

  return (
    <CustomSearch
      formFor="product"
      setSelected={(product) => {
        if (product && !Array.isArray(product)) {
          const currentProduct = product as IProductDetails;
          const hasDefaultUnit = currentProduct.productUnits?.find((unit) => unit.isDefault);
          if (!hasDefaultUnit) {
            message.warning('This product does not have a default unit', 5);
          }
        }

        setSelected?.(product);
      }}
      addCallback={ProductsDB.addProducts}
      serverCallback={fetchProducts}
      dbSearchById={ProductsDB.getProductDetails}
      dbSearchCallback={fetchProductsViaIndexDB}
      {...props}
    />
  );
}

export default ProductSearchV2;
