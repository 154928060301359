import { useEffect } from 'react';

export interface UseFirstLoadEffectOptions {
  key: string;
  callback: () => void;
  deps?: React.DependencyList;
  enabled?: boolean; // default: true
}

export function useFirstLoadEffect(options: UseFirstLoadEffectOptions) {
  const { key, callback, deps = [], enabled = true } = options;

  const SESSION_KEY = `first_load_ran_${key}`;
  const TAB_COUNT_KEY = `tab_count_${key}`;

  useEffect(() => {
    if (!enabled) return;

    const hasRun = sessionStorage.getItem(SESSION_KEY) === 'true';
    const currentTabCount = parseInt(localStorage.getItem(TAB_COUNT_KEY) || '0');

    // Increment tab count
    localStorage.setItem(TAB_COUNT_KEY, String(currentTabCount + 1));

    const cleanup = () => {
      const count = parseInt(localStorage.getItem(TAB_COUNT_KEY) || '1');
      const newCount = Math.max(count - 1, 0);
      localStorage.setItem(TAB_COUNT_KEY, String(newCount));

      if (newCount === 0) {
        localStorage.removeItem(SESSION_KEY);
      }
    };

    window.addEventListener('beforeunload', cleanup);

    if (!hasRun) {
      sessionStorage.setItem(SESSION_KEY, 'true');
      localStorage.setItem(SESSION_KEY, 'true');
      callback();
    }

    return () => {
      window.removeEventListener('beforeunload', cleanup);
      cleanup();
    };
  }, [enabled, key, ...deps]);
}

export function clearFirstLoadEffectKeys() {
  const prefixes = ['first_load_ran_', 'tab_count_'];

  prefixes.forEach((prefix) => {
    Object.keys(localStorage).forEach((key) => {
      if (key.startsWith(prefix)) {
        localStorage.removeItem(key);
      }
    });

    Object.keys(sessionStorage).forEach((key) => {
      if (key.startsWith(prefix)) {
        sessionStorage.removeItem(key);
      }
    });
  });
}
