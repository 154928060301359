import { Button, DatePicker, Form, FormInstance, InputNumber, Select } from 'antd';

import { FormValues } from '.';
import { LineType } from '@/services/report/enums';
import ProductSearchV2 from '@/components/Common/CustomSearch/Products';
import LocationSearchV2 from '@/components/Common/CustomSearch/Location';
import UnitSearch, { IProductUnitId } from '@/components/Common/CustomSearch/Units';
import { useState } from 'react';
import { IProductDetails } from '@/services/products/types';

interface Props {
  form: FormInstance<FormValues>;
  isLoading: boolean;
  initialValues: FormValues;
  onSearch: (values: FormValues) => void;
}

function FilterForm({ isLoading, form, initialValues, ...handler }: Props) {
  const [productUnitIds, setProductUnitIds] = useState<IProductUnitId[]>([]);
  const locationId = Form.useWatch(['locationId'], form);

  function onProductChange(productDetails?: IProductDetails) {
    if (!productDetails) {
      form.setFieldValue('unitId', 'default');
      return setProductUnitIds([]);
    }

    const units = productDetails.productUnits.map((value) => ({
      id: value.unitId,
      isDefault: value.isDefault
    }));

    setProductUnitIds(units);
    form.setFieldValue('unitId', 'default');
  }

  return (
    <Form
      form={form}
      layout="vertical"
      autoComplete="off"
      disabled={isLoading}
      validateTrigger="onChange"
      initialValues={initialValues}
      onFinish={handler.onSearch}>
      <div className="grid grid-cols-4 gap-3 ">
        <Form.Item label={'page'} name={['page']} hidden>
          <InputNumber controls={false} />
        </Form.Item>

        <Form.Item label={'size'} name={['size']} hidden>
          <InputNumber controls={false} />
        </Form.Item>

        <Form.Item
          name={'identifier'}
          label="Transaction Type"
          rules={[{ required: true, message: 'Please select a transaction type' }]}>
          <Select placeholder="Select a transaction type">
            {Object.keys(LineType).map((key) => (
              <Select.Option key={key} value={LineType[key as keyof typeof LineType]}>
                {LineType[key as keyof typeof LineType]}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <ProductSearchV2
          hasParentFormItem={false}
          showAll
          name={'productId'}
          locationId={locationId ? Number(locationId) : undefined}
          setSelected={(product) => {
            const singleProduct = Array.isArray(product) ? product[0] : product;
            onProductChange(singleProduct);
          }}
        />
        <UnitSearch
          hasParentFormItem={false}
          name={'unitId'}
          showAllWithDefaultUnit
          selectedProductUnitIds={productUnitIds}
        />
        <LocationSearchV2 hasParentFormItem={false} showAll name={['locationId']} />

        <Form.Item
          label={'First Date'}
          name={'firstDate'}
          rules={[{ required: true, message: 'Please provide date' }]}>
          <DatePicker format={'YYYY-MM-DD'} className="w-full" allowClear={false} />
        </Form.Item>

        <Form.Item
          label={'Second Date'}
          name={'secondDate'}
          rules={[{ required: true, message: 'Please provide comparision date' }]}>
          <DatePicker format={'YYYY-MM-DD'} className="w-full" allowClear={false} />
        </Form.Item>

        {/* // Buttons for submitting and downloading the form */}
        <div className="flex mt-7 gap-1">
          <Form.Item>
            <Button type="primary" htmlType="submit" style={{ borderRadius: '6px' }}>
              Search
            </Button>
          </Form.Item>
        </div>
      </div>
    </Form>
  );
}

export default FilterForm;
