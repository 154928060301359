import { DatePicker, Form, Input, InputNumber, TableProps } from 'antd';
import { useEffect, useState } from 'react';
import type { ColumnsType } from 'antd/es/table';
import { CustomDatePresets } from '../utils/datePresets';
import { RangePickerProps } from 'antd/lib/date-picker';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { CustomModal } from '@/components/Common/CustomModal';
import CustomReport from './report';
import { nepaliNumberFormatter, numberDecimalFormatter } from '@/utils/numberFormatter';
import { convertUTCStringtoLocalString } from '@/utils/convertToUTC';
const { RangePicker } = DatePicker;
import { SorterResult } from 'antd/lib/table/interface';
import { find_date_preference } from '@/store/localstorage/preferences';
import {
  convert_nepali_to_english_date,
  convert_string_to_nepali_date_string
} from '@/utils/nepaliDateConverter';
import { NepaliDatePicker } from 'nepali-datepicker-reactjs';
import { getUserData } from '@/utils/auth.utils';
import ReusablePurchaseView from '../../../purchases/view/ReusableView';
import VendorSearchV2 from '@/components/Common/CustomSearch/Vendors';
import LocationSearchV2 from '@/components/Common/CustomSearch/Location';
import { DEFAULT_DATE_FORMAT } from '@/constants';
import ProductCategorySearchV2 from '@/components/Common/CustomSearch/ProductCategory';
import ProductbyCategory from '@/components/Common/CustomSearch/Products/ProductsByCategory';

const ProductPurchase = () => {
  const [form] = Form.useForm();
  // const { data: locationsList } = useQuery(['locations'], async () => get_location_list());
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [id, setId] = useState(0);
  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({});
  const [categoryId, setCategoryId] = useState<number | null>(null);

  const locationId = Form.useWatch(['constraints', 'locationId'], form);

  const handleChange: TableProps<any>['onChange'] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<any>);
  };

  const [nepaliType] = useState(find_date_preference());
  const { preferences } = getUserData();
  const preferenceLocationId = preferences?.preferences
    ? JSON.parse(preferences?.preferences)?.locationId
    : undefined;
  useEffect(() => {
    const nepaliDateStart = convert_string_to_nepali_date_string(
      CustomDatePresets.Today[0].format('YYYY-MM-DD HH:mm')
    );
    const nepaliDateEnd = convert_string_to_nepali_date_string(
      CustomDatePresets.Today[0].format('YYYY-MM-DD HH:mm')
    );
    form.setFieldValue('constraints', {
      page: 1,
      size: 100,
      customerId: '',
      vendorId: '',
      productId: '',
      categoryId: '',
      locationId: preferenceLocationId ? preferenceLocationId : '',
      dateCustom: [...CustomDatePresets.Today],
      startDate: CustomDatePresets.Today[0].format('YYYY-MM-DD HH:mm'),
      endDate: CustomDatePresets.Today[1].format('YYYY-MM-DD HH:mm'),
      startDateNepali: nepaliDateStart,
      endDateNepali: nepaliDateEnd
    });
  }, []);

  const breadcrumbItems = [
    {
      label: 'Reports',
      link: '/reports'
    },
    {
      label: 'Product Purchase Report',
      link: '/reports/product-purchase'
    }
  ];
  const onChange: RangePickerProps['onChange'] = (dates, dateStrings) => {
    if (dateStrings.length == 2) {
      form.setFieldValue(['constraints', 'startDate'], dateStrings[0]);
      form.setFieldValue(['constraints', 'endDate'], dateStrings[1]);
    }
  };
  const columns2: ColumnsType<any> = [
    {
      title: 'S.N',
      key: 'SN',
      width: 15,
      // sorter: (a, b) => a.id - b.id,
      // sortOrder: sortedInfo.columnKey === 'id' ? sortedInfo.order : null,
      render: (text, record, index) => {
        return <div color="black">{index + 1}</div>;
      }
    },
    {
      title: 'Product ID',
      key: 'product_id',
      width: 20,
      render: (record) => <Link to={`/products/${record.product_id}`}>{record.product_id}</Link>
    },
    {
      title: 'Category',
      dataIndex: 'category_name',
      width: 30,
      render: (text: string) => <div>{text}</div>
    },
    {
      title: 'Name',
      dataIndex: 'product_name',
      width: 100,
      render: (text: string) => <div>{text}</div>
    },
    {
      title: 'Location',
      dataIndex: 'location_name',
      width: 60,
      render: (text: string) => <div>{text}</div>
    },
    {
      title: 'Sold',
      key: 'qty_sold',
      width: 30,
      render: (record) => (
        <div>
          {nepaliNumberFormatter(record.qty_sold)} {record.unit}
        </div>
      )
    },
    {
      title: 'Sold Avg Price',
      key: 'qty_sold_price',
      width: 30,
      render: (record) => <div>{nepaliNumberFormatter(record.qty_sold_price)}</div>
    },
    {
      title: 'Returned Sell',
      key: 'qty_returned_sell',
      width: 30,
      render: (record) => (
        <div>
          {nepaliNumberFormatter(record.qty_returned_sell)} {record.unit}
        </div>
      )
    },
    {
      title: 'Returned Sell Avg Price',
      key: 'qty_returned_sell_price',
      width: 30,
      render: (record) => <div>{nepaliNumberFormatter(record.qty_returned_sell_price)}</div>
    },
    {
      title: 'Date',
      key: 'date',
      width: 40,
      render: (record) => (
        <div>{convertUTCStringtoLocalString(record.date, DEFAULT_DATE_FORMAT)}</div>
      )
    },
    {
      title: 'Purchased',
      key: 'qty_purchased',
      width: 30,
      render: (record) => (
        <div>
          {nepaliNumberFormatter(record.qty_purchased)} {record.unit}
        </div>
      )
    },
    {
      title: 'Purchased Avg Price',
      key: 'qty_purchased_price',
      width: 30,
      render: (record) => <div>{nepaliNumberFormatter(record.qty_purchased_price)}</div>
    },
    {
      title: 'Returned Purchased',
      key: 'qty_returned_purchase',
      width: 30,
      render: (record) => (
        <div>
          {nepaliNumberFormatter(record.qty_returned_purchase)} {record.unit}
        </div>
      )
    },
    {
      title: 'Returned Purchased Avg Price',
      key: 'qty_returned_purchase_price',
      width: 30,
      render: (record) => <div>{nepaliNumberFormatter(record.qty_returned_purchase_price)}</div>
    },
    {
      title: 'Transferred',
      key: 'qty_transferred',
      width: 30,
      render: (record) => (
        <div>
          {nepaliNumberFormatter(record.qty_transferred)} {record.unit}
        </div>
      )
    },
    {
      title: 'Transferred Avg Price',
      key: 'qty_transferred_price',
      width: 30,
      render: (record) => <div>{nepaliNumberFormatter(record.qty_transferred_price)}</div>
    },
    {
      title: 'Transferred Out',
      key: 'qty_transferred_out',
      width: 30,
      render: (record) => (
        <div>
          {nepaliNumberFormatter(record.qty_transferred_out)} {record.unit}
        </div>
      )
    },
    {
      title: 'Transferred Out Avg Price',
      key: 'qty_transferred_out_price',
      width: 30,
      render: (record) => <div>{nepaliNumberFormatter(record.qty_transferred_out_price)}</div>
    },
    {
      title: 'Qty Adjusted',
      key: 'qty_adjusted',
      width: 30,
      render: (record) => (
        <div>
          {nepaliNumberFormatter(record.qty_adjusted)} {record.unit}
        </div>
      )
    },
    {
      title: 'Qty Adjusted Avg Price',
      key: 'qty_adjusted_price',
      width: 30,
      render: (record) => <div>{nepaliNumberFormatter(record.qty_adjusted_price)}</div>
    }
  ];

  const columns3: ColumnsType<any> = [
    {
      title: 'S.N',
      key: 'SN',
      width: 15,
      // sorter: (a, b) => a.id - b.id,
      // sortOrder: sortedInfo.columnKey === 'id' ? sortedInfo.order : null,
      render: (text, record, index) => {
        return <div color="black">{index + 1}</div>;
      }
    },
    {
      title: 'Product ID',
      key: 'product_id',
      width: 20,
      render: (record) => <Link to={`/products/${record.product_id}`}>{record.product_id}</Link>
    },
    {
      title: 'Category',
      dataIndex: 'category_name',
      width: 30,
      render: (text: string) => <div>{text}</div>
    },
    {
      title: 'Name',
      dataIndex: 'product_name',
      width: 100,
      render: (text: string) => <div>{text}</div>
    },
    {
      title: 'Location',
      dataIndex: 'location_name',
      width: 60,
      render: (text: string) => <div>{text}</div>
    },
    {
      title: 'Sold',
      key: 'qty_sold',
      width: 30,
      render: (record) => (
        <div>
          {nepaliNumberFormatter(record.qty_sold)} {record.unit}
        </div>
      )
    },
    {
      title: 'Returned Sell',
      key: 'qty_returned_sell',
      width: 30,
      render: (record) => (
        <div>
          {nepaliNumberFormatter(record.qty_returned_sell)} {record.unit}
        </div>
      )
    },
    {
      title: 'Purchased',
      key: 'qty_purchased',
      width: 30,
      render: (record) => (
        <div>
          {nepaliNumberFormatter(record.qty_purchased)} {record.unit}
        </div>
      )
    },
    {
      title: 'Returned Purchased',
      key: 'qty_returned_purchase',
      width: 30,
      render: (record) => (
        <div>
          {nepaliNumberFormatter(record.qty_returned_purchase)} {record.unit}
        </div>
      )
    },
    {
      title: 'Transferred',
      key: 'qty_transferred',
      width: 30,
      render: (record) => (
        <div>
          {nepaliNumberFormatter(record.qty_transferred)} {record.unit}
        </div>
      )
    },
    {
      title: 'Transferred Out',
      key: 'qty_transferred_out',
      width: 30,
      render: (record) => (
        <div>
          {nepaliNumberFormatter(record.qty_transferred_out)} {record.unit}
        </div>
      )
    },
    {
      title: 'Qty Adjusted',
      key: 'qty_adjusted',
      width: 30,
      render: (record) => (
        <div>
          {nepaliNumberFormatter(record.qty_adjusted)} {record.unit}
        </div>
      )
    }
  ];

  const columns: ColumnsType<any> = [
    {
      title: 'S.N',
      key: 'SN',
      width: 25,
      // sorter: (a, b) => a.id - b.id,
      // sortOrder: sortedInfo.columnKey === 'id' ? sortedInfo.order : null,
      render: (text, record, index) => {
        return <div color="black">{index + 1}</div>;
      }
    },
    {
      title: 'Id',
      key: 'id',
      width: 25,
      dataIndex: 'product_id',
      sorter: (a, b) => a.id - b.id,
      sortOrder: sortedInfo.columnKey === 'id' ? sortedInfo.order : null
    },
    {
      title: 'Category',
      key: 'category_name',
      dataIndex: 'category_name',
      width: 50,
      sorter: (a, b) => a.category_name.localeCompare(b.category_name),
      sortOrder: sortedInfo.columnKey === 'category_name' ? sortedInfo.order : null
    },
    {
      title: 'Product',

      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null,
      render: (record) => {
        return <div>{record.name}</div>;
      },
      width: 100
    },

    {
      title: 'SKU',

      key: 'sku',
      sorter: (a, b) => a.sku.localeCompare(b.sku),
      sortOrder: sortedInfo.columnKey === 'sku' ? sortedInfo.order : null,
      render: (record) => {
        return <div color="black">{record.sku}</div>;
      },
      width: 50
    },
    {
      title: 'Supplier',
      key: 'vendor_name',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a.vendor_name.localeCompare(b.vendor_name),
      sortOrder: sortedInfo.columnKey === 'vendor_name' ? sortedInfo.order : null,

      render: (record) => {
        return <div>{record.vendor_name}</div>;
      },
      width: 100
    },
    {
      title: 'Reference No.',
      key: 'reference_number',
      className: 'invoice',
      render: (record: any) => (
        <a
          onClick={() => {
            setIsModalOpen(true);
            setId(record.purchase_id);
          }}>
          {record.reference_number}
        </a>
      ),
      width: 100
    },
    {
      title: 'Date',

      key: 'date',
      sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
      sortOrder: sortedInfo.columnKey === 'date' ? sortedInfo.order : null,
      render: (record) => {
        return <div>{convertUTCStringtoLocalString(record.date, DEFAULT_DATE_FORMAT)}</div>;
      },
      width: 100
    },
    {
      title: 'Quantity',

      key: 'quantity',
      sorter: (a, b) => a.quantity - b.quantity,
      sortOrder: sortedInfo.columnKey === 'quantity' ? sortedInfo.order : null,
      render: (record) => {
        return <div>{numberDecimalFormatter(record.quantity)}</div>;
      },
      width: 50
    },
    {
      title: 'Unit',
      key: 'unit',
      // render: (text: number) => <div>{text && nepaliNumberFormatter(text)}</div>,
      sorter: (a, b) => a.unit_name.localCompare(b.unit_name),
      sortOrder: sortedInfo.columnKey === 'unit' ? sortedInfo.order : null,
      render: (record) => {
        return <div>{record.unit_name}</div>;
      },
      width: 40
    },
    {
      title: 'Unit Purchase Price',

      key: 'unit_price',
      sorter: (a, b) => a.unit_price - b.unit_price,
      sortOrder: sortedInfo.columnKey === 'unit_price' ? sortedInfo.order : null,
      render: (record) => {
        return <div className="text-right mr-2">{nepaliNumberFormatter(record.unit_price)}</div>;
      },
      width: 50
    },
    {
      title: 'Subtotal',
      className: 'highlight',
      key: 'total',
      sorter: (a, b) => a.total - b.total,
      sortOrder: sortedInfo.columnKey === 'total' ? sortedInfo.order : null,
      render: (record) => {
        return <div className="text-right mr-2">{nepaliNumberFormatter(record.total)}</div>;
      },
      width: 50
    }
  ];

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    return current && current > moment().endOf('day');
  };

  const onChangeNepali = (val: string, isStart: boolean) => {
    if (isStart)
      form.setFieldValue(['constraints', 'startDate'], convert_nepali_to_english_date(val));
    else form.setFieldValue(['constraints', 'endDate'], convert_nepali_to_english_date(val));
  };

  return (
    <CustomReport
      breadcrumbItems={breadcrumbItems}
      state={{ id: [17, 10, 39], name: 'Product Purchase Report' }}
      form={form}
      toSort={handleChange}
      footerData={{ sell: true, purchase: true }}
      columnsData={[columns, columns2, columns3]}>
      <CustomModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} title={'Sell View'}>
        {/* <ReusableSellView id={id} /> */}
        <ReusablePurchaseView id={id} />
      </CustomModal>
      <Form.Item label={'page'} name={['constraints', 'page']} hidden={true}>
        <InputNumber controls={false} />
      </Form.Item>
      <Form.Item label={'size'} name={['constraints', 'size']} hidden={true}>
        <InputNumber controls={false} />
      </Form.Item>
      <Form.Item label={'size'} name={['constraints', 'identifier']} hidden={true}>
        <InputNumber controls={false} />
      </Form.Item>

      <VendorSearchV2
        showAll
        hasParentFormItem={false}
        name={['constraints', 'vendorId']}
        label="Select suppliers"
      />

      <ProductCategorySearchV2
        showAll
        hasParentFormItem={false}
        name={['constraints', 'categoryId']}
        onSelect={(val: number | string) => {
          val === '' ? setCategoryId(null) : setCategoryId(val as number);
          form.setFieldValue(['constraints', 'productId'], '');
        }}
      />
      <ProductbyCategory
        categoryId={categoryId}
        hasParentFormItem={false}
        showAll
        locationId={locationId}
        name={['constraints', 'productId']}
      />
      <LocationSearchV2 showAll hasParentFormItem={false} name={['constraints', 'locationId']} />

      <Form.Item
        label={'Pick a date'}
        name={['constraints', 'dateCustom']}
        rules={[
          {
            required: true,
            message: `Please select date!`
          }
        ]}
        hidden={nepaliType}>
        <RangePicker
          showTime
          ranges={CustomDatePresets}
          disabledDate={disabledDate}
          onChange={onChange}
          format={'YYYY-MM-DD HH:mm'}
          allowClear={false}
        />
      </Form.Item>
      <Form.Item label={'Start Date'} name={['constraints', 'startDate']} hidden>
        <Input />
      </Form.Item>
      <Form.Item label={'End Date'} name={['constraints', 'endDate']} hidden>
        <Input />
      </Form.Item>
      <Form.Item label={'Select Start Date'} name={['startDateNepali']} hidden={!nepaliType}>
        <NepaliDatePicker
          onChange={(val) => onChangeNepali(val, true)}
          options={{ calenderLocale: 'ne', valueLocale: 'en' }}
        />
      </Form.Item>
      <Form.Item label={'Select End Date'} name={['endDateNepali']} hidden={!nepaliType}>
        <NepaliDatePicker
          onChange={(val) => onChangeNepali(val, false)}
          options={{ calenderLocale: 'ne', valueLocale: 'en' }}
        />
      </Form.Item>
    </CustomReport>
  );
};

export default ProductPurchase;
