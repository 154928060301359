import { create } from 'zustand';
import moment from 'moment';
import { createJSONStorage, devtools, persist, StateStorage } from 'zustand/middleware';
import { PageState, FilterStore } from './types';
import { set, del, createStore, entries, clear } from 'idb-keyval';
import getErrorMessage from '@/utils/getError';
import { normalizePath, parseFilter, parseState } from './services';
import { useEffect, useState } from 'react';
import { ConvertObjectToURL } from '@/utils/converturl';

export const zustandStore = {
  dbName: 'ERPData',
  storeName: 'ZustandStore'
};

const VERSION = 1;

function createStorage(): StateStorage {
  const store = createStore(zustandStore.dbName, zustandStore.storeName);
  return {
    getItem: async (): Promise<string | null> => {
      try {
        const storeValues = await entries(store); // Get all keys from the store
        const allStates: Record<string, PageState> = {};

        for (const [key, value] of storeValues) {
          allStates[key as string] = value;
        }

        if (Object.keys(allStates).length === 0) return null;
        const returnData = {
          state: { states: allStates, lastModifiedPage: undefined },
          version: VERSION
        };

        return JSON.stringify(returnData);
      } catch (error) {
        getErrorMessage(error, true);
        return null;
      }
    },
    setItem: async (_, value): Promise<void> => {
      try {
        const { states, lastModifiedPage } = parseState(value);
        if (!lastModifiedPage) {
          // console.log(states);
          return;
        }

        const pageState = states[lastModifiedPage];
        if (!pageState) {
          await del(lastModifiedPage, store);
        } else {
          await set(lastModifiedPage, pageState, store);
        }
      } catch (error) {
        getErrorMessage(error, true);
      }
    },
    removeItem: async (name): Promise<void> => {
      await del(name, store);
    }
  };
}

export const useFilterStore = create<FilterStore>()(
  devtools(
    persist(
      (set, get) => ({
        states: {},
        async refetchData(page, func) {
          const normalizedPage = normalizePath(page);
          const pageState = get().states[normalizedPage];

          const filter = pageState?.filter;
          if (!filter) return;

          try {
            const stringParams = ConvertObjectToURL(filter);
            const data = await func(stringParams); // Assuming `func` is async and returns data.

            // Update the state with the new data
            set(
              (state) => {
                return {
                  lastModifiedPage: normalizedPage,
                  states: {
                    ...state.states,
                    [normalizedPage]: {
                      ...state.states[normalizedPage],
                      data,
                      lastSyncedAt: moment().toISOString()
                    }
                  }
                };
              },
              undefined,
              'refetchData'
            );

            return data; // Return the fetched data
          } catch (error) {
            getErrorMessage(error, true);
            return;
          }
        },
        updateFilterType(page, type) {
          const normalizedPage = normalizePath(page);
          set(
            (state) => ({
              lastModifiedPage: normalizedPage,
              states: {
                ...state.states,
                [normalizedPage]: { ...state.states[normalizedPage], filterType: type }
              }
            }),
            undefined,
            'updateFilterType'
          );
        },
        updateState(page, state) {
          const normalizedPage = normalizePath(page);
          const { filter, scrollPosition, data, resetScrollWhenData = false } = state;
          const currentState = get().states[normalizedPage] || {};

          if (filter) {
            const parsedFilter = parseFilter(filter.params);
            currentState.filter = parsedFilter.filter;
            currentState.pagination = parsedFilter.pagination;
            currentState.filterType = filter.type;
          }

          if (scrollPosition) {
            currentState.scrollPosition = scrollPosition;
          }

          if (data) {
            currentState.data = data;
            currentState.lastSyncedAt = moment().toISOString();

            if (resetScrollWhenData) currentState.scrollPosition = undefined;
          }

          set(
            (state) => {
              return {
                lastModifiedPage: normalizedPage,
                states: {
                  ...state.states,
                  [normalizedPage]: { ...currentState }
                }
              };
            },
            undefined,
            'updateState'
          );
        },

        updatePartialData: (page, data) =>
          set(
            (state) => {
              const normalizedPage = normalizePath(page);
              const pageState = state.states[normalizedPage];

              // Ensure that stored data is an object before updating
              if (!pageState || typeof pageState.data !== 'object' || pageState.data === null) {
                return state;
              }

              return {
                lastModifiedPage: normalizedPage,
                states: {
                  ...state.states,
                  [normalizedPage]: {
                    ...pageState,
                    data: { ...pageState.data, ...data },
                    lastSyncedAt: moment().toISOString()
                  }
                }
              };
            },
            undefined,
            'updatePartialData'
          ),

        setScrollPosition: (page, scrollPosition) =>
          set(
            (state) => {
              const normalizedPage = normalizePath(page);
              const pageState = state.states[normalizedPage];
              if (!pageState?.data) return state;

              return {
                lastModifiedPage: normalizedPage,
                states: {
                  ...state.states,
                  [normalizedPage]: { ...pageState, scrollPosition }
                }
              };
            },
            undefined,
            'setScrollPosition'
          ),

        getState: (page: string): PageState | undefined => {
          const normalizedPage = normalizePath(page);
          const states = get().states[normalizedPage];
          const filter = states?.filter ?? {};

          // Convert dateSingle and dateCustom to moment
          if (filter.dateSingle && typeof filter.dateSingle === 'string') {
            states.filter.dateSingle = moment(filter.dateSingle);
          }

          if (filter.dateCustom && Array.isArray(filter.dateCustom)) {
            states.filter.dateCustom = filter.dateCustom.map((date) => moment(date));
          }

          return states;
        },

        resetState: (page: string) => {
          const normalizedPage = normalizePath(page);

          set(
            (state) => {
              const newStates = { ...state.states };
              delete newStates[normalizedPage];
              return { states: newStates, lastModifiedPage: normalizedPage };
            },
            undefined,
            'resetState'
          );
        },

        resetAll: async () => {
          try {
            const store = createStore(zustandStore.dbName, zustandStore.storeName);
            await clear(store);
            set({ states: {}, lastModifiedPage: undefined }, undefined, 'resetAll');
          } catch (error) {
            getErrorMessage(error, true);
          }
        },
        removeData: (page, removeScrollPosition = false) => {
          const normalizedPage = normalizePath(page);

          set(
            (state) => {
              const pageState = state.states[normalizedPage];
              if (!pageState) return state;

              delete pageState.data;
              if (removeScrollPosition) delete pageState.scrollPosition;
              delete pageState.lastSyncedAt;

              return {
                lastModifiedPage: normalizedPage,
                states: { ...state.states, [normalizedPage]: { ...pageState } }
              };
            },
            undefined,
            'removeData'
          );
        },

        getCurrentFilterType(page) {
          const normalizedPage = normalizePath(page);
          const pageState = get().states[normalizedPage];
          return pageState?.filterType || 'default';
        }
      }),
      {
        name: 'filter-store',
        storage: createJSONStorage(createStorage),
        version: VERSION,
        migrate(persistedState, version) {
          if (version !== VERSION) {
            console.log(`Migrating from version ${version} to ${VERSION}`);
          }
          return persistedState;
        }
      }
    ),
    {
      name: 'ZustandStore',
      enabled: process.env.NODE_ENV === 'development'
    }
  )
);

export const useHydration = () => {
  const [isHydrated, setIsHydrated] = useState(() => {
    return useFilterStore.persist.hasHydrated();
  });

  useEffect(() => {
    // Subscribe to the completion of hydration
    const unsub = useFilterStore.persist.onFinishHydration(() => {
      setIsHydrated(true);
    });

    return unsub;
  }, []);

  return isHydrated;
};
