import { Form, Input, message, Spin } from 'antd';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import AppContent from '@/components/Common/Content/Content';
import { convertLocalToUTCString, convertUTCStringtoLocalString } from '@/utils/convertToUTC';
import { IAccountTypeResponseData, ICustomViewAccountTableData } from '@/services/accounts/types';
import { getLedgerInfo, getParamsInfo } from './services.detailed-ledger';
import AccountFilterTable from '@/components/AccountFilterTable/v2';

import DetailedLedgerTable from './Table';
import { ConvertObjectToURL } from '@/utils/converturl';
import LedgerExport from './LedgerExport';

import { fetchReferenceAndRedirect } from './services/reference.service';
import CustomErrorModal from '@/components/Common/CustomErrorModal';
import getErrorMessage from '@/utils/getError';
import LocationSearchV2 from '@/components/Common/CustomSearch/Location';
import AccountSearchV2 from '@/components/Common/CustomSearch/Accounts/AccountSearchV2';
import isAxiosError from '@/utils/isAxiosError';
import { useFinancialYearStore } from '@/store/zustand/financial-year';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';

interface GetIntoOptions {
  filter: string;
  pageNo?: number;
  isResetPageRunningBalance?: boolean;
  isExport?: boolean;
}

type RecordType = { [key: number]: number };

interface ISavedData {
  fullExportURL?: string;
  lines: { total: number; data: ICustomViewAccountTableData[] };
  closingBalance: RecordType;
  selectedAccount: string;
  openingBalance: number;
  currentClosingBalance: number;
  total: { credit: number; debit: number };
  hasRunningBalance: boolean;
  pageRunningBalance: RecordType;
  dateRange: { startDate: string; endDate: string };
}

function DetailedLedgerList() {
  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState(true);
  const [fullExportURL, setFullExportURL] = useState(''); // store full export URL
  const [pagination, setPagination] = useState({ page: 1, size: 100 });
  const [lines, setLines] = useState({
    total: 0,
    data: [] as ICustomViewAccountTableData[]
  });

  const [locationId, setLocationId] = useState();
  const [closingBalance, setClosingBalance] = useState<RecordType>({});
  const [pageRunningBalance, setPageRunningBalance] = useState<RecordType>({});
  const [selectedAccount, setSelectedAccount] = useState('--');
  const [openingBalance, setOpeningBalance] = useState(0);
  const [currentClosingBalance, setCurrentClosingBalance] = useState(0);
  const [total, setTotal] = useState({ credit: 0, debit: 0 });
  const [dateRange, setDateRange] = useState({ startDate: '', endDate: '' });

  const zustandFinancialYear = useFinancialYearStore();

  const [hasRunningBalance, setHasRunningBalance] = useState(false);
  const [filterSelectedAccount, setFilterSelectedAccount] = useState<IAccountTypeResponseData>();

  // GET search params
  const [searchParams] = useSearchParams();
  const accountIdParam = searchParams.get('accountId');

  async function onSubmitFilter(filter: string) {
    const { startDate, endDate, params } = getParamsInfo(filter);

    const startDateLocal = convertUTCStringtoLocalString(startDate);
    const endDateLocal = convertUTCStringtoLocalString(endDate);

    // Set search params for export
    const dateRange = { startDate: startDateLocal, endDate: endDateLocal };
    setDateRange(dateRange);

    const data = await getInfo({ filter });
    params.set('count', data.total.toString());
    setFullExportURL(params.toString());
    setPagination({ page: 1, size: 100 });
    if (data.savedData) {
      return { ...data.savedData, fullExportURL, dateRange };
    }
  }

  async function getInfo({
    filter,
    pageNo = 1,
    isExport = false,
    isResetPageRunningBalance = false
  }: GetIntoOptions) {
    try {
      setIsLoading(true);
      setHasRunningBalance(false);
      setOpeningBalance(0);
      const { accountId } = getParamsInfo(filter);
      const currentFinancialStart = zustandFinancialYear?.currentFinancialYear?.startDate as string;

      const { total, data, balance, selectedAccount, runningBalanceCurrAcc, currentBalance } =
        await getLedgerInfo({
          filter,
          pageNo,
          currentFinancialStart,
          isResetPageRunningBalance,
          closingBalance,
          pageRunningBalance
        });

      let tableData = data;
      const savedData = {} as ISavedData;

      if (accountId) {
        if (tableData.length > 0) {
          setClosingBalance((prev) => {
            prev[pageNo] = balance?.closing || 0;
            savedData.closingBalance = prev;
            return prev;
          });

          const openingBalanceRowData = {
            accountName: 'Opening Balance',
            runningBalance: balance?.opening || 0,
            rows: 1,
            createdAt: '',
            isBold: true
          };

          const closingBalanceRowData = {
            accountName: 'Closing Balance',
            runningBalance: balance?.closing || 0,
            rows: 1,
            createdAt: '',
            isBold: true
          };

          tableData = [
            openingBalanceRowData,
            ...tableData,
            closingBalanceRowData
          ] as ICustomViewAccountTableData[];
        }

        setHasRunningBalance(true);
        setCurrentClosingBalance(currentBalance?.closingBalance || 0);
        setSelectedAccount(selectedAccount?.name || '--');

        savedData.hasRunningBalance = true;
        savedData.currentClosingBalance = currentBalance?.closingBalance || 0;
        savedData.selectedAccount = selectedAccount?.name || '--';

        const newBalance =
          tableData.length === 0 ? currentBalance?.openingBalance : balance?.opening;

        setOpeningBalance(newBalance || 0);
        savedData.openingBalance = newBalance || 0;
      }

      const transaction = { credit: 0, debit: 0 };
      tableData.forEach((value: any) => {
        transaction.credit += value.credit > 0 ? value.credit : 0;
        transaction.debit += value.debit > 0 ? value.debit : 0;
      });
      savedData.total = transaction;

      if (isExport) {
        return { total, data: tableData, transaction, savedData };
      }

      setTotal(transaction);
      setPageRunningBalance((prev) => {
        prev[pageNo] = runningBalanceCurrAcc || 0;
        savedData.pageRunningBalance = prev;
        return prev;
      });

      setLines({ total, data: tableData });
      savedData.lines = { total, data: tableData };
      setIsLoading(false);
      return { total, data: tableData, transaction, savedData };
    } catch (error) {
      message.error(getErrorMessage(error), 5);
      return { total: 0, data: [], transaction: { credit: 0, debit: 0 } };
    } finally {
      setIsLoading(false);
    }
  }

  const redirectTo = async (journalId: number) => {
    try {
      setIsLoading(true);
      const data = await fetchReferenceAndRedirect(journalId);
      if (!data) {
        throw new Error('Cannot redirect! Page not found.');
      }

      const { origin, pathname } = window.location;
      let baseURL = `${origin}`;
      if (pathname) baseURL = `${baseURL}${pathname}`;

      window.open(`${baseURL}#${data.link}${data.redirectId}`, '_blank');
    } catch (error) {
      if (isAxiosError(error)) return;
      CustomErrorModal({ message: 'Cannot redirect! Page not found.' });
    } finally {
      setIsLoading(false);
    }
  };

  const handleLocationChange = () => {
    if (filterSelectedAccount) {
      const isLocationSame =
        locationId === filterSelectedAccount.locationId ||
        filterSelectedAccount.locationId === null;

      if (!isLocationSame) {
        form.resetFields(['accountId']);
      }
    } else {
      form.resetFields(['accountId']);
    }

    setLocationId(form.getFieldValue(['locationId']));
  };

  async function onPagination(pageNo = 1, totalSize = 100, isSize = false) {
    let resetPageRunBal = false;
    const values = form.getFieldsValue();
    values.endDate = convertLocalToUTCString(values.endDate);
    values.startDate = convertLocalToUTCString(values.startDate);
    delete values.dateCustom;
    delete values.startDateNepali;
    delete values.endDateNepali;

    if (isSize) {
      values.skip = 0;
      values.count = totalSize;
      setPagination((prev) => {
        prev.page = 1;
        prev.size = totalSize;
        return prev;
      });

      setPageRunningBalance(() => {
        return {};
      });
      resetPageRunBal = true;
      pageNo = 1;
    } else {
      values.skip = (pageNo - 1) * totalSize;
      values.count = totalSize;
      setPagination((prev) => {
        prev.page = pageNo;
        return prev;
      });
    }

    const url = ConvertObjectToURL(values);
    const { savedData } = await getInfo({
      pageNo,
      isResetPageRunningBalance: resetPageRunBal,
      filter: url
    });

    return { ...savedData, fullExportURL, dateRange };
  }

  return (
    <Spin spinning={isLoading}>
      <AppContent
        withfilter
        breadcrumbItems={[{ label: 'Detailed Ledger', link: '/accounts/detailed-ledger' }]}
        button={
          <div>
            <AccountFilterTable
              form={form}
              showFilterInitial
              onParentLoading={(isLoading) => setIsLoading(isLoading)}
              onInitialLoad={({ pagination, data }) => {
                setPagination(pagination);

                if (data) {
                  const typedData = data as ISavedData;
                  if (typedData.fullExportURL) setFullExportURL(typedData.fullExportURL);
                  if (typedData.lines) setLines(typedData.lines);
                  if (typedData.closingBalance) setClosingBalance(typedData.closingBalance);
                  if (typedData.selectedAccount) setSelectedAccount(typedData.selectedAccount);
                  if (typedData.openingBalance) setOpeningBalance(typedData.openingBalance);
                  if (typedData.currentClosingBalance)
                    setCurrentClosingBalance(typedData.currentClosingBalance);
                  if (typedData.total) setTotal(typedData.total);
                  if (typedData.hasRunningBalance)
                    setHasRunningBalance(typedData.hasRunningBalance);
                  if (typedData.pageRunningBalance)
                    setPageRunningBalance(typedData.pageRunningBalance);

                  if (typedData.dateRange) setDateRange(typedData.dateRange);

                  setIsLoading(false);
                }
              }}
              defaultValues={{
                value: '',
                skip: 0,
                count: 100,
                locationId: '',
                accountId: accountIdParam ? parseInt(accountIdParam) : undefined
              }}
              onSearch={onSubmitFilter}
              buttonParentStyle="flex justify-end items-center">
              <LocationSearchV2
                hasParentFormItem={false}
                name={'locationId'}
                showAll
                onOptionClick={handleLocationChange}
              />

              <AccountSearchV2
                name={'accountId'}
                locationId={locationId}
                hasParentFormItem={false}
                setSelected={setFilterSelectedAccount}
              />

              <Form.Item name="value" label="Search">
                <Input placeholder="Search" />
              </Form.Item>
            </AccountFilterTable>
          </div>
        }>
        <div className="flex justify-between items-center">
          <div>
            <div className="font-bold text-lg">
              {selectedAccount} : <span>{nepaliNumberFormatter(openingBalance)}</span>
            </div>
          </div>

          <LedgerExport
            total={total}
            data={lines.data}
            page={pagination.page}
            getInfo={getInfo}
            dateRange={dateRange}
            setIsLoading={setIsLoading}
            fullExportURL={fullExportURL}
            closingBalance={closingBalance}
            selectedAccount={selectedAccount}
            opening={openingBalance}
          />
        </div>

        <DetailedLedgerTable
          form={form}
          data={lines.data}
          total={total}
          redirectTo={redirectTo}
          pagination={{ ...pagination, total: lines.total }}
          onPagination={onPagination}
          isRunningBalance={hasRunningBalance}
          currentClosingBalance={currentClosingBalance}
        />
      </AppContent>
    </Spin>
  );
}

export default DetailedLedgerList;
