import { IUnits } from '@/services/products/types';
import { Form, FormListFieldData, InputNumber } from 'antd';
import { Rule } from 'antd/lib/form';
import useFormInstance from 'antd/lib/form/hooks/useFormInstance';
import { NamePath } from 'antd/lib/form/interface';
import React, { useMemo } from 'react';

interface IProps {
  restField: Partial<FormListFieldData>;
  onChangeData: () => void;
  name: number;
  autofocus?: boolean;
  onPressEnterData?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  minValue?: number;
  rules?: Rule[];
  disabled?: boolean;
  autoFocusRef?: React.Ref<HTMLInputElement>;
  allUnits?: IUnits[];
  unitIdPath?: NamePath;
}
const ReusableQuantity = ({
  restField,
  onChangeData,
  autofocus = true,
  name,
  unitIdPath = ['lines', name, 'unitId'],
  onPressEnterData,
  minValue = 0.1,
  rules = [{ required: true, message: 'Please add a Valid Quantity!' }],
  disabled = false,
  autoFocusRef,
  allUnits
}: IProps) => {
  const form = useFormInstance();
  const unitId = Form.useWatch(unitIdPath, form);

  const unitDetails = useMemo(() => allUnits?.find((val) => val.id === unitId), [allUnits, unitId]);
  const precision = unitDetails?.allowDecimal ? 3 : 0;

  return (
    <Form.Item {...restField} label="Quantity" name={[name, 'quantity']} rules={rules}>
      <InputNumber
        controls={false}
        ref={autoFocusRef}
        min={minValue}
        precision={unitDetails ? precision : 3}
        onChange={onChangeData}
        onPressEnter={onPressEnterData}
        autoFocus={autofocus}
        disabled={disabled}
      />
    </Form.Item>
  );
};

export default ReusableQuantity;
