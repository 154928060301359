import GenericTable from '@/components/Common/CustomizeTable';

import { FormInstance, Menu, TableProps, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { SorterResult } from 'antd/lib/table/interface';
import { useState } from 'react';

import { IWooCommerceOrder, IWooCommerceOrderResponse } from '@/services/woocommerce/types';
import { convertUTCStringtoLocalString } from '@/utils/convertToUTC';
import { DEFAULT_DATE_FORMAT } from '@/constants';
import CustomViewIcon from '@/components/Common/CustomIcons/CustomViewIcon';
import ActionDropdown from '@/components/Common/Dropdownactions';
import { OrderStatus, OrderStatusColor } from '@/services/woocommerce/enum';

interface Props {
  isLoading?: boolean;
  data: IWooCommerceOrderResponse;
  form: FormInstance;
  pagination: { page: number; size: number };
  onPagination: (
    page?: number,
    size?: number,
    isSize?: boolean
  ) => Promise<{ data: IWooCommerceOrderResponse | undefined }>;
}

function Table({ isLoading, data, pagination, form, onPagination }: Props) {
  const [sortedInfo, setSortedInfo] = useState<SorterResult<IWooCommerceOrder>>({});
  const fallbackTotal = getTotalCount(data, pagination);

  function getTotalCount(
    data: IWooCommerceOrderResponse,
    pagination: { page: number; size: number }
  ): number {
    const { page, size } = pagination;
    const itemsCount = data.results?.length ?? 0;

    if (page === 1 && itemsCount === 0) return 0;
    if (page > 1 && itemsCount === 0) return (page - 1) * size;

    return (page - 1) * size + itemsCount * 2;
  }

  const handleChange: TableProps<IWooCommerceOrder>['onChange'] = (_, __, s) => {
    setSortedInfo(s as SorterResult<IWooCommerceOrder>);
  };

  const columns: ColumnsType<IWooCommerceOrder> = [
    {
      title: 'S.N.',
      key: 'id',
      width: 50,
      render: (_, __, index) => (pagination.page - 1) * pagination.size + index + 1
    },
    {
      title: 'Order Details',
      children: [
        {
          title: 'Order ID',
          dataIndex: 'id',
          key: 'id',
          sorter: (a, b) => a.id - b.id,
          width: 100
        },
        {
          title: 'Customer Name',
          dataIndex: 'billing',
          key: 'customer_name',
          render: (billing) => `${billing.first_name} ${billing.last_name}`,
          width: 200,
          sorter: (a, b) => a.billing.first_name.localeCompare(b.billing.first_name),
          sortOrder: sortedInfo.columnKey === 'customer_name' ? sortedInfo.order : null
        },
        {
          title: 'Total Items',
          key: 'total_items',
          render: (b, record) => record.line_items.length,
          width: 100,
          sorter: (a, b) => a.line_items.length - b.line_items.length,
          sortOrder: sortedInfo.columnKey === 'total_items' ? sortedInfo.order : null
        },
        {
          title: 'Status',
          dataIndex: 'status',
          key: 'status',
          render: (status: OrderStatus) => (
            <Tag color={OrderStatusColor[status] || 'default'}>{status.replace('-', ' ')}</Tag>
          ),
          width: 150
        },
        {
          title: 'Order Date',
          dataIndex: 'date_created',
          key: 'date_created',
          sorter: (a, b) => a.date_created.localeCompare(b.date_created),
          sortOrder: sortedInfo.columnKey === 'date_created' ? sortedInfo.order : null,
          render: (date) => convertUTCStringtoLocalString(date, DEFAULT_DATE_FORMAT),
          width: 200
        }
      ]
    },
    {
      title: 'Pricing & Taxes',
      children: [
        {
          title: 'Includes Tax?',
          dataIndex: 'prices_include_tax',
          key: 'prices_include_tax',
          render: (value) => (value ? 'Yes' : 'No'),
          width: 120,
          sorter: (a) => (a ? 1 : -1),
          sortOrder: sortedInfo.columnKey === 'prices_include_tax' ? sortedInfo.order : null
        },
        {
          title: 'Discount Total',
          dataIndex: 'discount_total',
          key: 'discount_total',
          width: 120,
          sorter: (a, b) => parseFloat(a.discount_total) - parseFloat(b.discount_total),
          sortOrder: sortedInfo.columnKey === 'discount_total' ? sortedInfo.order : null
        },
        {
          title: 'Discount Tax',
          dataIndex: 'discount_tax',
          key: 'discount_tax',
          width: 120,
          sorter: (a, b) => parseFloat(a.discount_tax) - parseFloat(b.discount_tax),
          sortOrder: sortedInfo.columnKey === 'discount_tax' ? sortedInfo.order : null
        },
        {
          title: 'Shipping Total',
          dataIndex: 'shipping_total',
          key: 'shipping_total',
          width: 120,
          sorter: (a, b) => parseFloat(a.shipping_total) - parseFloat(b.shipping_total),
          sortOrder: sortedInfo.columnKey === 'shipping_total' ? sortedInfo.order : null
        },
        {
          title: 'Shipping Tax',
          dataIndex: 'shipping_tax',
          key: 'shipping_tax',
          width: 120,
          sorter: (a, b) => parseFloat(a.shipping_tax) - parseFloat(b.shipping_tax),
          sortOrder: sortedInfo.columnKey === 'shipping_tax' ? sortedInfo.order : null
        },
        {
          title: 'Cart Tax',
          dataIndex: 'cart_tax',
          key: 'cart_tax',
          width: 120,
          sorter: (a, b) => parseFloat(a.cart_tax) - parseFloat(b.cart_tax),
          sortOrder: sortedInfo.columnKey === 'cart_tax' ? sortedInfo.order : null
        },
        {
          title: 'Total',
          dataIndex: 'total',
          key: 'total',
          sorter: (a, b) => parseFloat(a.total) - parseFloat(b.total),
          width: 120,
          sortOrder: sortedInfo.columnKey === 'total' ? sortedInfo.order : null
        },
        {
          title: 'Total Tax',
          dataIndex: 'total_tax',
          key: 'total_tax',
          width: 120,
          sorter: (a, b) => parseFloat(a.total_tax) - parseFloat(b.total_tax),
          sortOrder: sortedInfo.columnKey === 'total_tax' ? sortedInfo.order : null
        }
      ]
    },
    {
      title: 'Shipping Details',
      children: [
        {
          title: 'Shipping Address',
          dataIndex: 'shipping',
          key: 'shipping',
          render: (shipping) =>
            `${shipping.address_1}${shipping.address_2 ? `, ${shipping.address_2}` : ''}, ${
              shipping.city
            }`,
          width: 300
        },
        {
          title: 'Phone',
          dataIndex: 'shipping',
          key: 'shipping_phone',
          render: (shipping) => shipping.phone || 'N/A',
          width: 150
        },
        {
          title: 'Email',
          dataIndex: 'billing',
          key: 'billing_email',
          render: (billing) => billing.email || 'N/A',
          width: 200,
          sorter: (a, b) => (a.billing.email || '').localeCompare(b.billing.email || ''),
          sortOrder: sortedInfo.columnKey === 'billing_email' ? sortedInfo.order : null
        }
      ]
    },
    {
      title: 'Payment Method',
      dataIndex: 'payment_method_title',
      key: 'payment_method',
      width: 180,
      sorter: (a, b) => (a.payment_method_title || '').localeCompare(b.payment_method_title || ''),
      sortOrder: sortedInfo.columnKey === 'payment_method' ? sortedInfo.order : null
    },
    {
      title: 'Paid Date',
      dataIndex: 'date_paid',
      key: 'payment_method',
      width: 180,
      sorter: (a, b) => (a.date_paid || '').localeCompare(b.date_paid || ''),
      sortOrder: sortedInfo.columnKey === 'payment_method' ? sortedInfo.order : null,
      render: (date) => (date ? convertUTCStringtoLocalString(date, DEFAULT_DATE_FORMAT) : '')
    },
    {
      title: 'Actions',
      key: 'actions',
      width: 100,
      fixed: 'right',
      render: (record: IWooCommerceOrder) => {
        const menuItems: (
          | { key: string; label: JSX.Element; onClick?: undefined }
          | { key: string; label: JSX.Element; onClick: () => void }
        )[] = [
          {
            key: '7',
            label: <CustomViewIcon link={`/woo-commerce/orders/${record.id}`} />
          }
        ];

        const menu = <Menu items={menuItems} />;
        return <ActionDropdown menu={menu} />;
      }
    }
  ];

  return (
    <div>
      <GenericTable
        form={form}
        columns={columns}
        data={data.results}
        isLoading={isLoading}
        scroll={{ x: 2200, y: '75vh' }}
        hideDefaultPagination
        showPager={false}
        tableName={'woo-orders-list'}
        generateSummary
        summaryClassName="text-left"
        excludeSummaryByKeys={['id']}
        pagination={{
          ...pagination,
          total: data?.totalCount || fallbackTotal,
          onPagination,
          scrollToTop: true,
          showSizeChanger: false
        }}
        toSort={handleChange}
      />
    </div>
  );
}

export default Table;
