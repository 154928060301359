import {
  Button,
  Checkbox,
  Form,
  Input,
  InputNumber,
  PageHeader,
  Select,
  Spin,
  message
} from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AppContent from '@/components/Common/Content/Content';
import { AccountType } from '@/services/accounts/enums';
import { AccountsTypeSearch } from '@/components/Common/AccountsTypeSearch';
import { IAccountTypeResponseData, IUpdateAccounts } from '@/services/accounts/types';
import { useMutation, useQuery } from '@tanstack/react-query';
import { get_account_details } from '@/services/accounts/queries';
import { update_account_type_mutation } from '@/services/accounts/mutations';
import { LocationSearch } from '@/components/Common/LocationSearch/LocationSearch';
import { BankSearch } from '@/components/Common/BankSearch';
import { UsersSearchForAccount } from '@/components/Common/UsersSearchForAccount';
import { useFilterStore } from '@/store/zustand';
import { ListPage } from '@/constants/list.enum';

const DetailsAccounts = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { id } = useParams();
  const [parentAccountList, setParentAccountList] = useState<IAccountTypeResponseData[]>([]);
  const [locationId, setLocationId] = useState<number>();
  const [accountType, setAccountType] = useState<AccountType>();

  const zustandFilter = useFilterStore();

  const breadcrumbItems = [
    { label: 'Accounts', link: '/accounts' },
    { label: 'Details', link: '/accounts/:id' }
  ];

  useQuery(['accountList', id], async () => await getData());

  useEffect(() => {
    setInitialValues();
  }, []);

  const setInitialValues = () => {
    form.setFieldValue(['balance'], 0);
  };

  const getData = async () => {
    if (id) {
      const data = await get_account_details(parseInt(id));
      //console.log('Data', data);
      form.setFieldsValue({
        name: data.name,
        balance: data.balance ? data.balance : 0,
        accountType: data.type,
        locationId: data.locationId ? data.locationId : null,
        referenceId: data.referenceId ? data.referenceId : null,
        isSubLedger: data.isSubLedger
      });
      setAccountType(data.type as AccountType);
      handleLocationChange();
      form.setFieldValue(['parentId'], data.parentId ? data.parentId : null);
      setIsLoading(false);
    }
  };

  const onFinish = (values: IUpdateAccounts) => {
    setIsLoading(true);
    //console.log('Values', values);
    if (id) values.id = parseInt(id);
    if (!values.parentId) values.parentId = null;
    updateAccountsMutation.mutate(values);
  };

  const updateAccountsMutation = useMutation(update_account_type_mutation, {
    onSuccess: () => {
      setIsLoading(false);
      zustandFilter.removeData(ListPage.ACCOUNTS);
      message.success('Account updated successfully');
      navigate('/accounts');
    },
    onError: (e: any) => {
      setIsLoading(false);
      message.error(`${e.response.data.message}`);
    }
  });

  const handleLocationChange = () => {
    // console.log('locationId', form.getFieldValue(['locationId']));
    form.resetFields(['parentId']);
    setLocationId(form.getFieldValue(['locationId']));
  };

  return (
    <Spin spinning={isLoading}>
      <AppContent breadcrumbItems={breadcrumbItems} withfilter={false}>
        <Form
          form={form}
          initialValues={{ isSubLedger: false }}
          onFinish={onFinish}
          disabled={isLoading}
          layout="vertical"
          validateTrigger={'onChange'}
          autoComplete="off">
          <PageHeader
            title="Accounts Information"
            style={{
              padding: '8px 0px'
            }}
          />
          <div className={'grid grid-cols-1 sm:grid-cols-4 gap-5 mb-5'}>
            <Form.Item
              label="Account Name"
              name="name"
              rules={[{ required: true, message: 'Please add Account Name!' }]}>
              <Input />
            </Form.Item>
            <Form.Item label="Balance" name="balance">
              <InputNumber controls={false} defaultValue={0} disabled={true} />
            </Form.Item>
            <AccountsTypeSearch
              formData={{ formName: 'parentId', label: 'Parent Account' }}
              isAll={false}
              disabled
              accountList={parentAccountList}
              setAccountList={setParentAccountList}
              required={true}
              locationId={locationId}
              excludedAccount={id ? parseInt(id) : null}
            />
            <Form.Item
              label="Account Type"
              name="accountType"
              rules={[{ required: true, message: 'Please add Account Type!' }]}>
              <Select
                disabled
                placeholder="Select account type"
                onSelect={(val: AccountType) => setAccountType(val)}>
                {(Object.keys(AccountType) as Array<keyof typeof AccountType>)
                  .filter(
                    (value) =>
                      value !== AccountType.TRANSFER_INITIATE &&
                      value !== AccountType.TRANSFER_RECEIVE &&
                      value !== AccountType.CUSTOMER_PARENT &&
                      value !== AccountType.USER_PARENT &&
                      value !== AccountType.VENDOR_PARENT
                  )
                  .map((key) => (
                    <Select.Option key={key} value={key}>
                      {AccountType[key]}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
            {accountType === AccountType.OTHER && (
              <LocationSearch
                disabled
                formData={{ formLabel: 'Location', formName: 'locationId' }}
                required={false}
                notAll={true}
                onSelect={() => handleLocationChange()}
                allowClear={true}
              />
            )}
            {accountType === AccountType.BANK && (
              <BankSearch
                disabled
                formData={{ formLabel: 'Bank', formName: 'referenceId' }}
                required={true}
                isAll={false}
              />
            )}
            {accountType === AccountType.USER && (
              <UsersSearchForAccount
                disabled
                formData={{ label: 'User', formName: 'referenceId' }}
                required={true}
                isAll={false}
              />
            )}
            <Form.Item name="isSubLedger" label="Account is Sub-Ledger?" valuePropName="checked">
              <Checkbox />
            </Form.Item>
          </div>
          <div className="flex justify-end mt-5">
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={isLoading}>
                Update
              </Button>
            </Form.Item>
            <Button
              type="default"
              className="ml-5"
              htmlType="button"
              onClick={() => navigate('/accounts')}>
              Cancel
            </Button>
          </div>
        </Form>
      </AppContent>
    </Spin>
  );
};

export default DetailsAccounts;
