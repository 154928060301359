import AppContent from '@/components/Common/Content/Content';
import CustomErrorModal from '@/components/Common/CustomErrorModal';
import { ListPage } from '@/constants/list.enum';
import { getCategoryById, getLocationByList } from '@/services';
import { get_leafy_purchase_wastage_details } from '@/services/leafy-items/queries';
import { useFilterStore } from '@/store/zustand';
import getErrorMessage from '@/utils/getError';
import isAxiosError from '@/utils/isAxiosError';
import { Button, Descriptions, Form, InputNumber, message, Modal, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { update_leafy_items_mutation } from '@/services/leafy-items/mutation';
import { ILeafyItem } from '@/services/leafy-items/types';
import { find_leafy_category, find_locationId_preference } from '@/store/localstorage/preferences';
import { isAdmin } from '@/routes/acl';

interface FormValues {
  purchaseTotal: number;
  wastageTotal: number;
}

function LeafyItemsUpdate() {
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm<FormValues>();
  const navigate = useNavigate();

  const zustandFilter = useFilterStore();
  const locationId = find_locationId_preference();
  const leafyCategoryId = find_leafy_category();

  const [data, setData] = useState<ILeafyItem>();

  const params = useParams();
  const leafyId = Number(params.id);
  if (isNaN(leafyId)) return <Navigate to={ListPage.LEAFY_ITEMS} />;

  useEffect(() => {
    if (isNaN(leafyId)) return;
    if (!locationId) {
      message.error(
        'Default Preference has not been set! Please set it in Settings/Preferences first.'
      );
      navigate(ListPage.LEAFY_ITEMS);
      return;
    }

    if (!leafyCategoryId) {
      message.error('Please set default leafy category in Global Settings');
      navigate(ListPage.GLOBAL_SETTINGS);
      return;
    }

    getInfo(leafyCategoryId);
  }, [leafyId]);

  async function getInfo(leafyCategoryId: number) {
    try {
      setIsLoading(true);
      const details = await get_leafy_purchase_wastage_details(leafyId);

      // Validate Details
      if (!isAdmin() && details.locationId !== locationId) {
        message.error('You do not have access to this data.');
        navigate(ListPage.LEAFY_ITEMS);
        return;
      }

      const location = await getLocationByList(details.locationId);
      const category = await getCategoryById(leafyCategoryId);
      form.setFieldsValue({
        purchaseTotal: details.userPurchaseTotal,
        wastageTotal: details.userWastageTotal
      });

      const data = { ...details, location: location.name, category: category.name };
      setData(data);
    } catch (error) {
      getErrorMessage(error, true);
    } finally {
      setIsLoading(false);
    }
  }

  async function onUpdate() {
    await form.validateFields();
    setIsModalOpen(true);
  }

  function onConfirmation() {
    setIsModalOpen(false);
    form.submit();
  }

  async function onFinish(values: FormValues) {
    try {
      setIsLoading(true);
      const locationId = data?.locationId as number;
      const date = data?.date as string;

      await update_leafy_items_mutation(leafyId, { ...values, locationId, date });
      zustandFilter.removeData(ListPage.LEAFY_ITEMS);
      message.success('Updated successfully');
      navigate(ListPage.LEAFY_ITEMS);
    } catch (error) {
      if (isAxiosError(error)) return;
      CustomErrorModal({ message: getErrorMessage(error) });
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Spin spinning={isLoading}>
      <Modal visible={isModalOpen} onCancel={() => setIsModalOpen(false)} onOk={onConfirmation}>
        <p>Are you sure you want to update?</p>
      </Modal>

      <AppContent
        breadcrumbItems={[{ label: 'Leafy Items', link: '/leafy-items' }, { label: 'Update' }]}>
        <Descriptions
          bordered
          column={1}
          labelStyle={{ width: '150px' }}
          size="small"
          title="Purchase and Wastage"
          style={{ marginBottom: '16px' }}>
          <Descriptions.Item label="Date">{data?.date}</Descriptions.Item>
          <Descriptions.Item label="Location">{data?.location}</Descriptions.Item>
          <Descriptions.Item label="Category">{data?.category}</Descriptions.Item>
        </Descriptions>

        <Form form={form} layout="vertical" onFinish={onFinish}>
          <div className="grid sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4">
            <Form.Item
              label="Purchase Amount"
              name="purchaseTotal"
              rules={[{ required: true, message: 'Please enter purchase amount' }]}>
              <InputNumber
                min={0}
                style={{ width: '100%' }}
                addonBefore="Rs"
                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              />
            </Form.Item>

            <Form.Item
              label="Wastage Amount"
              name="wastageTotal"
              rules={[{ required: true, message: 'Please enter wastage amount' }]}>
              <InputNumber
                min={0}
                addonBefore="Rs"
                style={{ width: '100%' }}
                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              />
            </Form.Item>
          </div>

          <div className="flex justify-end mt-4">
            <Button type="primary" onClick={onUpdate} disabled={isLoading}>
              Submit
            </Button>
          </div>
        </Form>
      </AppContent>
    </Spin>
  );
}

export default LeafyItemsUpdate;
