import { Button, Form, Input, message, Modal, PageHeader, Spin } from 'antd';
import { UploadFile } from 'antd/es/upload';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AppContent from '@/components/Common/Content/Content';
import PictureListByForm from '@/components/Common/PictureList/PictureListByForm';
import CustomErrorModal from '@/components/Common/CustomErrorModal';
import getErrorMessage from '@/utils/getError';
import { uploadImagesToServer } from '@/services/upload/queries';
import isAxiosError from '@/utils/isAxiosError';
import { useFilterStore } from '@/store/zustand';
import { ListPage } from '@/constants/list.enum';
import { IFeatureRequestPayload } from '@/services/supports/types';
import { create_feature_request_mutation } from '@/services/supports/mutation';

interface FormValues {
  description: string;
  medias?: UploadFile[];
}

function FeatureRequestCreate() {
  const [form] = Form.useForm<FormValues>();
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  const zustandFilter = useFilterStore();

  async function onFinish(values: FormValues) {
    try {
      setIsLoading(true);
      const payload: IFeatureRequestPayload = {
        description: values.description,
        imgIds: []
      };

      if (values.medias && values.medias.length > 0) {
        const medias = await uploadImagesToServer(values.medias);
        const mediaIds = medias.map((media) => media?.id).filter(Boolean) as number[];

        payload.imgIds = mediaIds;
      }

      await create_feature_request_mutation(payload);
      zustandFilter.resetState(ListPage.FEATURE_REQUEST);
      message.success('Feature requested. Wait for developer approval.');
      navigate(ListPage.FEATURE_REQUEST);
    } catch (error) {
      if (isAxiosError(error)) return;
      CustomErrorModal({ message: getErrorMessage(error) });
    } finally {
      setIsLoading(false);
    }
  }

  function onConfirmation() {
    setIsModalOpen(false);
    form.submit();
  }

  async function onCreate() {
    await form.validateFields();
    setIsModalOpen(true);
  }

  return (
    <Spin spinning={isLoading}>
      <Modal visible={isModalOpen} onCancel={() => setIsModalOpen(false)} onOk={onConfirmation}>
        <p>Are you sure you want to request this new feature?</p>
      </Modal>

      <AppContent
        breadcrumbItems={[
          { label: 'Feature Request', link: '/support/feature-request' },
          { label: 'Create' }
        ]}>
        <PageHeader title="Request New Feature" style={{ padding: '8px 0' }} />
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item
            name="description"
            label="Feature"
            rules={[{ required: true, message: 'Please provide detailed feature description' }]}>
            <Input.TextArea placeholder="Provide detailed feature description" rows={4} />
          </Form.Item>

          <div className="mt-4">
            <PictureListByForm name={'medias'} label="Attachments" showStyle />
          </div>

          <div className="flex justify-end mt-4">
            <Button type="primary" onClick={onCreate} disabled={isLoading}>
              Request Feature
            </Button>
          </div>
        </Form>
      </AppContent>
    </Spin>
  );
}

export default FeatureRequestCreate;
