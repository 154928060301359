export const API_URL = `${process.env.REACT_APP_REST_API_HOST}`;
export const JWT_TOKEN = '@fresh-token';
export const JWT_REFRESH_TOKEN = '@fresh-refresh-token';
export const USER_INFO = '@fresh-user';
export const HAS_INITIAL_SYNC = '@fresh-has-initial-sync';
export const TINY_MCE_EDITOR_KEY = `${process.env.REACT_APP_TINY_MCE_EDITOR_KEY}`;
export const GOOGLE_MAPS_KEY = `${process.env.REACT_APP_GOOGLE_MAPS_KEY}`;
export const FRONT_VERSION = `${process.env.REACT_APP_VERSION}`;

export const FINANCIAL_YEAR_PERSIST = '@financial-year';
export const LAST_FEATURE_SHOWCASE = '@fresh-lastFeatureShowcase';
