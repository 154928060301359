import React, { useEffect, useState } from 'react';
import { Button, Input, Layout, Modal, Tooltip } from 'antd';
import { MenuOutlined, WechatOutlined, SearchOutlined } from '@ant-design/icons';

import moment from 'moment';
const { Header } = Layout;
import { useNavigate } from 'react-router-dom';
import useWindowDimensions from '../hooks/useWindowDimension';

import { CustomModal } from '../components/Common/CustomModal';
import { SearchForMenuModal } from '../components/Common/CustomModal/SearchForMenuModal';
import MenuDB from '../store/localstorage/MenuDB';
import { IMenuList } from '../services/settings/types';

import { useGlobalContext } from '@/contexts/global.context';

import NotificationV2 from './notification/v2';
import { useQuery } from '@tanstack/react-query';
import { get_hr_self_calendar_data } from '@/services/hr/queries';
import { check_in_out_attendance_mutation } from '@/services/hr/mutations';
import CustomErrorModal from '@/components/Common/CustomErrorModal';
import getErrorMessage from '@/utils/getError';
import useBroadcast from '@/hooks/useBroadcast';
import { hrEventColors } from '@/pages/hr/calendar/constant';
import isAxiosError from '@/utils/isAxiosError';
import {
  useFinancialYearPersistHydration,
  useFinancialYearStore
} from '@/store/zustand/financial-year';
import LatestFeatureShowCase from '@/components/LatestFeature';

import UserProfile from '@/components/Common/UserProfile/UserProfile';

interface IHeaderWrapperProps {
  // eslint-disable-next-line no-unused-vars
  collapsed: boolean;
  showHamburger: boolean;
  setshowDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  refetchMenu: () => void;
  isSecondaryMenu?: boolean;
  showSubMenuHamburger?: boolean;
}

const NotificationsComponent = React.memo(() => {
  return <NotificationV2 />;
});

NotificationsComponent.displayName = 'NotificationComponent';

const HeaderWrapper = ({
  showHamburger,
  setshowDrawer,
  collapsed,
  refetchMenu,
  isSecondaryMenu = false,
  showSubMenuHamburger
}: IHeaderWrapperProps) => {
  const { width } = useWindowDimensions();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [searchMenuItems, setSearchMenuItems] = useState<IMenuList[]>([]);

  const { attendanceStatus, handleAttendance } = useBroadcast();
  const [isCheckModal, setIsCheckModal] = useState(false);
  const { setShowSecondaryDrawer, showInfo } = useGlobalContext();

  const [isModalOpenSearchMenu, setIsModalOpenSearchMenu] = useState(false);

  const [currentStatus, setCurrentStatus] = useState<'holiday' | 'leave' | 'shift'>();
  const eventColor = currentStatus ? hrEventColors[currentStatus] : null;

  const { getFinancialYears, financialYearList, syncToday } = useFinancialYearStore();
  const isHydrated = useFinancialYearPersistHydration();

  useEffect(() => {
    if (isHydrated) {
      financialYearList.length === 0 ? getFinancialYears() : syncToday();
    }
  }, [isHydrated]);

  const { isLoading } = useQuery(['today-hr_details'], async () => {
    const startDate = moment().startOf('day').toISOString();
    const endDate = moment().endOf('day').toISOString();

    const params = new URLSearchParams({ startDate, endDate, archiveStatus: 'ACTIVE' });
    const response = await get_hr_self_calendar_data(params.toString());

    if (response.holidays.length > 0) {
      setCurrentStatus('holiday');
    } else if (response.leaves.length > 0) {
      setCurrentStatus('leave');
    } else if (response.shifts.length > 0) {
      setCurrentStatus('shift');
    }

    // Handle Attendance Status
    if (response.attendances.length === 0) {
      handleAttendance('out');
      return;
    }

    const attendance = response.attendances[0];
    handleAttendance(attendance.endDate ? 'out' : 'in');
  });

  async function checkAttendance(type: 'in' | 'out') {
    try {
      setIsCheckModal(false);
      setLoading(true);
      await check_in_out_attendance_mutation(type);
      handleAttendance(type);
    } catch (error) {
      if (isAxiosError(error)) return;
      CustomErrorModal({ message: getErrorMessage(error) });
    } finally {
      setLoading(false);
    }
  }

  const handleSearchMenu = async () => {
    const menuItems = await MenuDB.getAllMenuItem();
    setSearchMenuItems(menuItems);
    setIsModalOpenSearchMenu(true);
  };

  return (
    <Header
      className={`site-layout-background ${showHamburger ? '!px-4 md:!px-8' : ''}`}
      style={{
        position: 'fixed',
        margin: '0px',
        zIndex: 1000,
        width:
          showHamburger || showInfo
            ? '100vw'
            : collapsed
            ? `${width - 75 + 5}px`
            : `${width - 220 + 5}px`,
        transition: 'all 0.1s linear',
        backgroundColor: eventColor?.light,
        borderBottom: eventColor ? `2px solid ${eventColor.dark}` : 'none'
      }}>
      <Modal
        title="Attendance"
        visible={isCheckModal}
        width="500px"
        onOk={() => checkAttendance(attendanceStatus === 'in' ? 'out' : 'in')}
        onCancel={() => setIsCheckModal(false)}>
        <p>Are you sure you want to check {attendanceStatus === 'in' ? 'out' : 'in'}?</p>
      </Modal>

      <CustomModal
        title={''}
        isModalOpen={isModalOpenSearchMenu}
        setIsModalOpen={setIsModalOpenSearchMenu}
        style={{ overflowX: 'auto' }}
        width={'50%'}
        footer={false}>
        <SearchForMenuModal
          searchMenuItems={searchMenuItems}
          handleItemClick={() => setIsModalOpenSearchMenu(false)}
        />
      </CustomModal>
      <LatestFeatureShowCase />
      <div
        className={`w-full h-full ${
          showHamburger || showInfo ? 'flex justify-between' : 'flex justify-end'
        } items-center`}>
        <div className="flex items-center gap-4 relative">
          {isSecondaryMenu && (showSubMenuHamburger || showInfo) && (
            <Button
              type="primary"
              className="rounded-sm"
              onClick={() => setShowSecondaryDrawer((prev) => !prev)}>
              <MenuOutlined className="scale-125" />
            </Button>
          )}

          {(showHamburger || showInfo) && (
            <div>
              <MenuOutlined className="scale-125" onClick={() => setshowDrawer((prev) => !prev)} />
            </div>
          )}
        </div>
        <div className="flex justify-center items-center gap-5">
          <div onClick={handleSearchMenu}>
            {width < 900 ? (
              <Tooltip title="search">
                <Button shape="circle" icon={<SearchOutlined />} />
              </Tooltip>
            ) : (
              <Input
                placeholder="Search for Menu..."
                prefix={<SearchOutlined className="site-form-item-icon" />}
                allowClear={true}
                readOnly={true}
              />
            )}
          </div>
          <Button
            loading={loading || isLoading}
            onClick={() => setIsCheckModal(true)}
            type={attendanceStatus === 'in' ? 'default' : 'primary'}
            danger={attendanceStatus === 'in'}>
            {attendanceStatus === 'in' ? 'Check Out' : 'Check In'}
          </Button>

          <Tooltip title="Create Channel" color="green">
            <WechatOutlined
              onClick={() => navigate('/channel/new')}
              style={{ transform: 'scale(1.4)' }}
            />
          </Tooltip>
          <NotificationsComponent />
          <UserProfile isHamburgerShown={showHamburger} onMenuRefetch={refetchMenu} />
        </div>
      </div>
    </Header>
  );
};

export default HeaderWrapper;
