import { storesToCreate } from './storeList.constant';
import { IndexNames, StoreNames } from './storeList.enum';

const newVersion = 9;

export default class LocalStore {
  static db: IDBDatabase;
  static async init() {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open('ERPDB', newVersion);

      request.onsuccess = (event: any) => {
        LocalStore.db = event.target.result;
        resolve('success');
      };
      request.onupgradeneeded = (event: any) => {
        LocalStore.db = event.target.result;

        storesToCreate.forEach(({ name, keyPath, index, indexKey }) => {
          if (!LocalStore.db.objectStoreNames.contains(name)) {
            const store = LocalStore.db.createObjectStore(name, { keyPath });
            if (index && indexKey) {
              store.createIndex(index, indexKey, { unique: false });
            }
          }
        });
      };
      request.onerror = (event: any) => {
        reject(event.target.error);
      };
      request.onblocked = (event: any) => {
        this.db.close();
        reject('Blocked!');
      };
    });
  }
  static async delete() {
    return new Promise((resolve, reject) => {
      const DBDeleteRequest = window.indexedDB.deleteDatabase('ERPDB');

      DBDeleteRequest.onblocked = async (event) => {
        console.log('Database blocked, retrying....');
        LocalStore.db.close();
        await LocalStore.delete();
        // reject('Database blocked');
      };
      DBDeleteRequest.onerror = (event) => {
        console.log('Database couldnt be deleted');
        reject('Database couldnt be deleted');
      };
      DBDeleteRequest.onsuccess = (event) => {
        console.log('Database deleted successfully');
        resolve('Database deleted successfully');
      };
    });
  }

  static async clearAllStores(maxRetries = 3, retryDelay = 1000) {
    return new Promise((resolve, reject) => {
      if (!LocalStore.db) {
        reject(new Error('Database not initialized'));
        return;
      }

      const storeNames = Array.from(LocalStore.db.objectStoreNames);
      if (storeNames.length === 0) {
        resolve('No stores to clear');
        return;
      }

      const clearStoresPromises = storeNames.map((storeName) =>
        LocalStore.clearStore(storeName as StoreNames, maxRetries, retryDelay)
      );

      Promise.all(clearStoresPromises)
        .then(() => resolve('All stores cleared successfully'))
        .catch((error) => reject(new Error(`Failed to clear all stores: ${error.message}`)));
    });
  }

  static async clearStore(storeName: StoreNames, maxRetries = 3, retryDelay = 1000) {
    return new Promise((resolve, reject) => {
      if (!LocalStore.db) {
        reject(new Error('Database not initialized'));
        return;
      }

      if (!LocalStore.db.objectStoreNames.contains(storeName)) {
        reject(new Error(`Store "${storeName}" does not exist`));
        return;
      }

      const clearStoreWithRetry = (retriesLeft: number) => {
        const transaction = LocalStore.db.transaction(storeName, 'readwrite');
        const store = transaction.objectStore(storeName);
        const request = store.clear();

        request.onsuccess = () => {
          resolve(`Store "${storeName}" cleared successfully`);
        };

        request.onerror = (event) => {
          const error = (event.target as IDBRequest).error;
          console.error(`Error clearing store "${storeName}":`, error);

          if (retriesLeft > 0) {
            console.warn(`Retrying clearing store "${storeName}". Retries left: ${retriesLeft}`);
            setTimeout(() => {
              clearStoreWithRetry(retriesLeft - 1);
            }, retryDelay);
          } else {
            reject(new Error(`Failed to clear store "${storeName}" after ${maxRetries} retries`));
          }
        };

        transaction.onerror = (event) => {
          const error = (event.target as IDBTransaction).error;
          console.error(`Transaction error for store "${storeName}":`, error);
          reject(new Error(`Transaction failed for store "${storeName}"`));
        };
      };

      clearStoreWithRetry(maxRetries);
    });
  }
}

export function getTransactionError(event: Event, reject: (reason?: any) => void, type: string) {
  const instanceOfIndexDB = event.target instanceof IDBRequest;
  if (instanceOfIndexDB) {
    reject(event.target.error);
    return;
  }

  reject(new Error(`Failed to ${type} on indexDB`));
}
