import {
  Alert,
  Button,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  PageHeader,
  Select,
  Spin
} from 'antd';
import { UploadFile } from 'antd/es/upload';
import moment from 'moment';
import { useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import AppContent from '@/components/Common/Content/Content';
import PictureListByForm from '@/components/Common/PictureList/PictureListByForm';
import CustomErrorModal from '@/components/Common/CustomErrorModal';
import getErrorMessage from '@/utils/getError';

import { useQuery } from '@tanstack/react-query';
import { getFileListFromMediaIds } from '@/services/upload/services';
import isAxiosError from '@/utils/isAxiosError';
import { useFilterStore } from '@/store/zustand';
import { ListPage } from '@/constants/list.enum';
import { get_feature_request_detail } from '@/services/supports/queries';
import { EFeatureRequestProgress } from '@/services/supports/enum';
import { IFeatureRequestPayload } from '@/services/supports/types';
import {
  update_feature_request_dev_mutation,
  update_feature_request_user_mutation
} from '@/services/supports/mutation';
import { uploadImagesAndGetIds } from '@/services/upload/queries';
import { isAdmin } from '@/routes/acl';
import { getUserData } from '@/utils/auth.utils';

interface FormValues {
  description: string;
  medias?: UploadFile[];
  status: EFeatureRequestProgress;
  systemMessage: string;
  completedAt: string;
  completedAtMoment: moment.Moment;
}

function UpdateFeatureRequest() {
  const [form] = Form.useForm<FormValues>();
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  const isCurrentAdmin = isAdmin();
  const userData = getUserData();

  const params = useParams();
  const featureRequestID = Number(params.id);

  const zustandFilter = useFilterStore();

  if (isNaN(featureRequestID) || featureRequestID <= 0)
    return <Navigate to={ListPage.FEATURE_REQUEST} />;

  const {
    isLoading: isDetailsLoading,
    isFetching,
    data
  } = useQuery(['get_feature_request_details', featureRequestID], async () => {
    const details = await get_feature_request_detail(featureRequestID);

    form.setFieldsValue({
      description: details.description,
      status: details.status,
      systemMessage: details.systemMessage
    });

    if (details.completedAt) {
      form.setFieldsValue({
        completedAt: details.completedAt,
        completedAtMoment: moment(details.completedAt)
      });
    }

    if (details.imgIds.length > 0) {
      const medias = await getFileListFromMediaIds(details.imgIds);
      form.setFieldValue('medias', medias);
    }

    return details;
  });

  async function onFinish(values: FormValues) {
    try {
      setIsLoading(true);
      const payload: IFeatureRequestPayload = {
        description: values.description,
        imgIds: []
      };

      if (values.medias && values.medias.length > 0) {
        const mediaIds = await uploadImagesAndGetIds(values.medias);
        payload.imgIds = mediaIds;
      }

      if (!isCurrentAdmin) {
        await update_feature_request_user_mutation(featureRequestID, payload);
      } else {
        await update_feature_request_dev_mutation(featureRequestID, {
          ...payload,
          status: values.status,
          systemMessage: values.systemMessage,
          completedAt: values.completedAt
        });
      }

      zustandFilter.removeData(ListPage.FEATURE_REQUEST);
      message.success('Feature request updated.');
      navigate(ListPage.FEATURE_REQUEST);
    } catch (error) {
      if (isAxiosError(error)) return;
      CustomErrorModal({ message: getErrorMessage(error) });
    } finally {
      setIsLoading(false);
    }
  }

  function onConfirmation() {
    setIsModalOpen(false);
    form.submit();
  }

  async function onUpdate() {
    await form.validateFields();
    setIsModalOpen(true);
  }

  return (
    <Spin spinning={isLoading || isDetailsLoading || isFetching}>
      <Modal visible={isModalOpen} onCancel={() => setIsModalOpen(false)} onOk={onConfirmation}>
        <p>Are you sure you want to update this feature request?</p>
      </Modal>

      <AppContent
        breadcrumbItems={[
          { label: 'Feature Request', link: '/support/feature-request' },
          { label: 'Update' }
        ]}>
        <PageHeader title="Update Feature Request" style={{ padding: '8px 0' }} />
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item
            name="description"
            label="Feature"
            rules={[{ required: true, message: 'Please provide detailed feature description' }]}>
            <Input.TextArea placeholder="Provide detailed feature description" rows={4} />
          </Form.Item>

          <div className="mt-4">
            <PictureListByForm name={'medias'} label="Attachments" showStyle />
          </div>

          {isCurrentAdmin && (
            <div className="mt-2 space-y-4">
              <Alert
                message="For Developers only"
                description="Developer can update feature request status and system message"
              />
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 md:grid-cols-3 lg:grid-cols-4">
                <Form.Item name="status" label="Status">
                  <Select
                    placeholder="Select Status"
                    allowClear
                    dropdownMatchSelectWidth={false}
                    disabled={!isCurrentAdmin}>
                    {Object.keys(EFeatureRequestProgress).map((key) => {
                      const value =
                        EFeatureRequestProgress[key as keyof typeof EFeatureRequestProgress];

                      return (
                        <Select.Option value={value} key={key}>
                          {value}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>

                <Form.Item
                  name="systemMessage"
                  label="System Message"
                  rules={[{ required: isCurrentAdmin, message: 'Please provide system message' }]}>
                  <Input placeholder="Provide system message" disabled={!isCurrentAdmin} />
                </Form.Item>

                <Form.Item name="completedAt" label="Completed At" hidden>
                  <Input />
                </Form.Item>

                <Form.Item name="completedAtMoment" label="Completed At">
                  <DatePicker
                    showTime
                    format={'YYYY-MM-DD HH:mm'}
                    disabled={!isCurrentAdmin}
                    className="w-full"
                    onChange={(date) => form.setFieldValue('completedAt', date?.utc().format())}
                  />
                </Form.Item>
              </div>
            </div>
          )}

          {(userData?.id === data?.requestedBy || isCurrentAdmin) && (
            <div className="flex justify-end mt-4">
              <Button type="primary" onClick={onUpdate} disabled={isLoading}>
                Update Feature
              </Button>
            </div>
          )}
        </Form>
      </AppContent>
    </Spin>
  );
}

export default UpdateFeatureRequest;
