import { io } from 'socket.io-client';
import { API_URL } from '../constants/config';
import { getTokenFromStorage } from './storage.utils';
import { globalSignOut, isTokenExpired } from './auth.utils';
import { AuthErrorEnum } from '@/services/auth/types';
import { getRefreshedToken } from './refreshTokenManger';

export const configSocket = async (namespace: string) => {
  let token = getTokenFromStorage();

  // Refresh token if expired or about to expire
  if (!token || isTokenExpired(token)) {
    const newAccessToken = await getRefreshedToken();
    if (!newAccessToken) {
      return;
    }

    token = newAccessToken;
  }

  const socket = io(`${API_URL}${namespace}`, {
    auth: { token }
  });

  // Handle authentication errors (e.g., expired or invalid token)
  socket.on('connect_error', async (err) => {
    console.error('WebSocket connection error:', err.message);

    if (err.message === AuthErrorEnum.ACCESS_TOKEN) {
      console.log('WebSocket: Invalid Access Token, trying to refresh...');
      const newAccessToken = await getRefreshedToken();
      if (newAccessToken) {
        token = newAccessToken;
        socket.auth = { token }; // Update token
        socket.connect(); // Reconnect with new token
      } else {
        console.error('WebSocket: Unable to refresh token, signing out...');
        globalSignOut();
        socket.disconnect();
      }
    }

    if (
      err.message === AuthErrorEnum.REFRESH_TOKEN_EXPIRED ||
      err.message === AuthErrorEnum.REFRESH_TOKEN
    ) {
      console.error('WebSocket: Refresh token expired or invalid, signing out...');
      globalSignOut();
      socket.disconnect();
    }
  });

  return socket;
};

export const configFonepaySocket = (url: string) => {
  const socket = new WebSocket(url);

  return socket;
};
