import { getImageFromServer } from '@/services/upload/queries';
import { cn } from '@/utils';
import getErrorMessage from '@/utils/getError';
import { LoadingOutlined } from '@ant-design/icons';
import { Image, message } from 'antd';
import { useState } from 'react';

interface Props {
  mediaId: number;
  fetcher?: (id: number) => Promise<string>;
  noMediaContent?: JSX.Element;
  setParentLoading?: React.Dispatch<React.SetStateAction<boolean>>;
}

function PreviewImageOnDemand({
  mediaId,
  fetcher = getImageFromServer,
  noMediaContent,
  setParentLoading
}: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [isPreviewVisible, setPreviewVisible] = useState(false);
  const [media, setMedia] = useState('');

  if (!mediaId) {
    if (noMediaContent) return noMediaContent;
    return <div className="flex justify-center items-center">-</div>;
  }

  async function handlePreviewMedia() {
    try {
      setIsLoading(true);
      setParentLoading?.(true);
      const media = await fetcher(mediaId);
      if (!media) {
        message.error('Media not found.');
        return;
      }

      setMedia(media);
      setPreviewVisible(true);
    } catch (error) {
      getErrorMessage(error, true);
    } finally {
      setIsLoading(false);
      setParentLoading?.(false);
    }
  }

  return (
    <div className="flex justify-center items-center">
      <div
        className={cn('cursor-pointer flex justify-center items-center', {
          'pointer-events-none text-gray-200 ': isLoading
        })}
        onClick={handlePreviewMedia}>
        {isLoading ? <LoadingOutlined /> : <span className="underline text-blue-500">View</span>}
      </div>
      <Image
        key={`media-${mediaId}`}
        preview={{ visible: isPreviewVisible, onVisibleChange: setPreviewVisible }}
        alt={`media-${mediaId}`}
        src={media}
        style={{ display: 'none' }}
      />
    </div>
  );
}

export default PreviewImageOnDemand;
