import { Button, Menu, message, Tooltip } from 'antd';
import { ISumReportRow } from '.';
import { excelExportSumReport } from './export';
import ActionDropdown from '@/components/Common/Dropdownactions';

interface Props {
  excelColumns: string[];
  data: ISumReportRow[];
  isDisabled?: boolean;
  date: string;
}

function ExportSumReport({ excelColumns, data, isDisabled, date }: Props) {
  const handleExport = () => {
    const isDataEmpty = data.length === 0;
    if (isDataEmpty) {
      message.error('No data to export');
      return;
    }

    excelExportSumReport(excelColumns, data, date);
  };

  return (
    <div className="flex justify-end">
      <div>
        <ActionDropdown
          marginLeft="0px"
          button={true}
          buttonJSX={
            <Button type="primary" className="!rounded-md" disabled={isDisabled}>
              Export
            </Button>
          }
          menu={
            <Menu
              items={[
                {
                  key: '1',
                  label: (
                    <Tooltip title="Export Excel" color="blue">
                      <div className="text-center">Excel</div>
                    </Tooltip>
                  ),
                  onClick: handleExport
                }
              ]}
            />
          }
        />
      </div>
    </div>
  );
}

export default ExportSumReport;
