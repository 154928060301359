import { IAverageSellReport } from '@/services/report/types';
import { convertUTCStringtoLocalString } from '@/utils/convertToUTC';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';

export const excelExportColumns = [
  { title: 'Location', dataIndex: 'location_name' },
  { title: 'Date', dataIndex: 'date' },
  { title: 'Total Sells', dataIndex: 'no_of_sells' },
  { title: 'Total Amount', dataIndex: 'total_amount' },
  { title: 'Total Return', dataIndex: 'total_return_amount' },
  { title: 'Avg Transaction', dataIndex: 'avg_transaction_amount' }
];

export const tableExportColumns = excelExportColumns.map((field) => ({
  label: field.title,
  dataIndex: field.dataIndex,
  render: (text: string) => {
    return <div className="text-center">{text}</div>;
  }
}));

export function getUpdatedData(data: IAverageSellReport[]) {
  return data.map((item) => {
    return {
      ...item,
      date: convertUTCStringtoLocalString(item.date, 'YYYY-MM-DD'),
      total_amount: nepaliNumberFormatter(parseFloat(item.total_amount)),
      total_return_amount: nepaliNumberFormatter(parseFloat(item.total_return_amount)),
      avg_transaction_amount: nepaliNumberFormatter(parseFloat(item.avg_transaction_amount)),
      net_amount: nepaliNumberFormatter(parseFloat(item.net_amount))
    };
  });
}
