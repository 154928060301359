import CustomUpdateIcon from '@/components/Common/CustomIcons/CustomUpdateIcon';
import CustomViewIcon from '@/components/Common/CustomIcons/CustomViewIcon';
import GenericTable from '@/components/Common/CustomizeTable';
import ActionDropdown from '@/components/Common/Dropdownactions';
import { DEFAULT_DATE_FORMAT } from '@/constants';
import { checkAccess } from '@/routes/acl';
import { IPayroll, IPayrollList } from '@/services/hr/types';
import { convertUTCStringtoLocalString } from '@/utils/convertToUTC';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';
import { FormInstance, Menu, TableProps } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { SorterResult } from 'antd/lib/table/interface';
import { useState } from 'react';
import { Link } from 'react-router-dom';

interface Props {
  isLoading?: boolean;
  data: IPayrollList;
  form: FormInstance;
  pagination: { page: number; size: number };
  onPagination: (
    page?: number,
    size?: number,
    isSize?: boolean
  ) => Promise<IPayrollList | undefined>;
}

function Table({ isLoading, data, pagination, form, onPagination }: Props) {
  const [sortedInfo, setSortedInfo] = useState<SorterResult<IPayroll>>({});

  const handleChange: TableProps<IPayroll>['onChange'] = (_, __, s) => {
    setSortedInfo(s as SorterResult<IPayroll>);
  };

  const columns: ColumnsType<IPayroll> = [
    {
      title: 'S.N.',
      key: 'id',
      width: 5,
      render: (_, __, index) => (pagination.page - 1) * pagination.size + index + 1
    },
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
      width: 10,
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null,
      render: (name: string, record) => <Link to={`/hr/payroll/${record.id}`}>{name}</Link>
    },
    {
      title: 'Employee',
      key: 'employeeName',
      width: 15,
      dataIndex: 'employeeName',
      sorter: (a, b) => a.employeeName.localeCompare(b.employeeName),
      sortOrder: sortedInfo.columnKey === 'employeeName' ? sortedInfo.order : null
    },
    {
      title: 'Total Amount',
      key: 'totalAmount',
      width: 8,
      dataIndex: 'totalAmount',
      sorter: (a, b) => a.totalAmount - b.totalAmount,
      sortOrder: sortedInfo.columnKey === 'totalAmount' ? sortedInfo.order : null,
      render: (totalAmount) => {
        return nepaliNumberFormatter(totalAmount);
      }
    },
    {
      title: 'Last Updated',
      key: 'lastUpdated',
      width: 10,
      dataIndex: 'updatedAt',
      sorter: (a, b) => a.updatedAt.localeCompare(b.updatedAt),
      sortOrder: sortedInfo.columnKey === 'lastUpdated' ? sortedInfo.order : null,
      render: (_, record) => convertUTCStringtoLocalString(record.updatedAt, DEFAULT_DATE_FORMAT)
    },
    {
      title: 'Actions',
      key: 'actions',
      width: 4,
      fixed: 'right',
      render: (record: IPayroll) => {
        const menuItems: (
          | { key: string; label: JSX.Element; onClick?: undefined }
          | { key: string; label: JSX.Element; onClick: () => void }
        )[] = [
          {
            key: 'view',
            label: <CustomViewIcon link={`/hr/payroll/${record.id}`} />
          }
        ];

        if (checkAccess('UPDATE_HR_PAYROLL')) {
          menuItems.push({
            key: 'update',
            label: <CustomUpdateIcon link={`/hr/payroll/details/${record.id}`} />
          });
        }

        const menu = <Menu items={menuItems} />;
        return <ActionDropdown menu={menu} />;
      }
    }
  ];

  return (
    <div>
      <GenericTable
        form={form}
        columns={columns}
        data={data.results}
        isLoading={isLoading}
        scroll={{ x: 1000 }}
        hideDefaultPagination
        tableName={'hr-payroll-list'}
        pagination={{ ...pagination, total: data.total, onPagination, scrollToTop: true }}
        toSort={handleChange}
      />
    </div>
  );
}

export default Table;
