import { convertUTCStringtoLocalString } from '@/utils/convertToUTC';
import { nepaliNumberFormatter, numberDecimalFormatter } from '@/utils/numberFormatter';

export const tableExportColumns = [
  {
    label: 'Financial Reference',
    dataIndex: 'financial_reference',
    render: (text: string) => <div className="text-center">{text}</div>
  },
  {
    label: 'Category',
    dataIndex: 'category_name',
    render: (text: string) => <div className="text-center">{text}</div>
  },
  {
    label: 'Product',
    dataIndex: 'name',
    render: (text: string) => <div className="text-center">{text}</div>
  },
  {
    label: 'SKU',
    dataIndex: 'sku',
    render: (text: string) => <div className="text-center">{text}</div>
  },
  {
    label: 'Customer',
    dataIndex: 'customer_name',
    render: (text: string) => <div className="text-center">{text}</div>
  },
  {
    label: 'Contact ID',
    dataIndex: 'phone',
    render: (text: string) => <div className="text-center">{text}</div>
  },
  {
    label: 'Invoice No.',
    dataIndex: 'reference_number',
    render: (text: string) => <div className="text-center">{text}</div>
  },
  {
    label: 'Date',
    dataIndex: 'date',
    render: (text: string) => <div className="text-center">{text}</div>
  },
  {
    label: 'Quantity',
    dataIndex: 'quantity',
    render: (text: string) => <div className="text-center">{text}</div>
  },
  {
    label: 'Unit',
    dataIndex: 'unit_name',
    render: (text: string) => <div className="text-center">{text}</div>
  },
  {
    label: 'Unit Price',
    dataIndex: 'unit_price',
    render: (text: string) => <div className="text-center">{text}</div>
  },
  {
    label: 'Total',
    dataIndex: 'total_amount',
    render: (text: string) => <div className="text-center">{text}</div>
  }
];

export const excelExportColumns = tableExportColumns.map((column) => ({
  title: column.label,
  dataIndex: column.dataIndex
}));

export function getUpdatedData(data: any[]) {
  return data.map((record: any) => {
    return {
      ...record,
      date: record.date ? convertUTCStringtoLocalString(record.date) : '',
      quantity: numberDecimalFormatter(record.quantity),
      unit_price: nepaliNumberFormatter(record.unit_price),
      total_amount: nepaliNumberFormatter(record.total_amount)
    };
  }) as any;
}
