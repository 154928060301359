import moment from 'moment';
import { useState } from 'react';
import { TableProps } from 'antd/es/table';
import { SorterResult } from 'antd/lib/table/interface';

import { ColumnsType } from 'antd/lib/table';

import { ExtendedInitialValues } from '.';
import { convertUTCStringtoLocalString } from '@/utils/convertToUTC';
import { WithReportPaginationProps } from '@/components/Common/Report/withReportPagination';
import ExportCurrent from '@/components/Common/ExportCurrent';
import ExportAllData from '@/components/Common/ExportAll';
import { nepaliNumberFormatter, numberDecimalFormatter } from '@/utils/numberFormatter';
import { excelExportColumns, getUpdatedData, tableExportColumns } from './column.export';
import { DEFAULT_DATE_FORMAT } from '@/constants';
import GenericTable from '@/components/Common/CustomizeTable';
import { IProductSell } from '@/services/report/types';
import { CustomModal } from '@/components/Common/CustomModal';
import ReusableSellView from '@/pages/sell/view/ReusableView';

interface Props
  extends Pick<WithReportPaginationProps, 'onPagination' | 'onChange' | 'pagination'> {
  data: { total: number; data: IProductSell[] };
  footer: { totalAmount: number; totalQty: string };
  onSearch: (values: ExtendedInitialValues) => void;
  onSearchAll: () => Promise<IProductSell[]>;
}

function TableData({ data, pagination, footer, ...handlers }: Props) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [id, setId] = useState(0);
  const [sortedInfo, setSortedInfo] = useState<SorterResult<IProductSell>>({});

  const handleChange: TableProps<IProductSell>['onChange'] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<IProductSell>);
  };

  const columns: ColumnsType<IProductSell> = [
    {
      title: 'S.N',
      key: 'SN',
      width: 25,
      render: (text, record, index) => {
        return <div color="black">{(pagination.page - 1) * pagination.size + (index + 1)}</div>;
      }
    },
    {
      title: 'Financial Reference',
      dataIndex: 'financial_reference',
      key: 'financial_reference',
      sorter: (a, b) => a.financial_reference.localeCompare(b.financial_reference),
      sortOrder: sortedInfo.columnKey === 'financial_reference' ? sortedInfo.order : null,
      width: 100
    },
    {
      title: 'Category',
      key: 'category_name',
      dataIndex: 'category_name',
      width: 50,
      sorter: (a, b) => a.category_name.localeCompare(b.category_name),
      sortOrder: sortedInfo.columnKey === 'category_name' ? sortedInfo.order : null
    },
    {
      title: 'Product',
      key: 'name',
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null,
      width: 100
    },

    {
      title: 'SKU',
      key: 'sku',
      dataIndex: 'sku',
      sorter: (a, b) => a.sku.localeCompare(b.sku),
      sortOrder: sortedInfo.columnKey === 'sku' ? sortedInfo.order : null,
      width: 50
    },
    {
      title: 'Customer Name',
      key: 'customer_name',
      sorter: (a, b) => a.customer_name.localeCompare(b.customer_name),
      sortOrder: sortedInfo.columnKey === 'customer_name' ? sortedInfo.order : null,
      dataIndex: 'customer_name',
      width: 100
    },
    {
      title: 'Contact ID',
      key: 'phone',
      sorter: (a, b) => a.phone.localeCompare(b.phone),
      sortOrder: sortedInfo.columnKey === 'phone' ? sortedInfo.order : null,
      dataIndex: 'phone',
      width: 70
    },
    {
      title: 'Invoice No.',
      key: 'reference_number',
      className: 'invoice',
      sorter: (a, b) => a.reference_number.localeCompare(b.reference_number),
      sortOrder: sortedInfo.columnKey === 'reference_number' ? sortedInfo.order : null,
      render: (record) => {
        return (
          <a
            color="black"
            onClick={() => {
              setIsModalOpen(true);
              setId(record.sell_id);
            }}>
            {record.reference_number}
          </a>
        );
      },
      width: 100
    },
    {
      title: 'Date',
      key: 'date',
      sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
      sortOrder: sortedInfo.columnKey === 'date' ? sortedInfo.order : null,
      render: (record) => (
        <div className="text-center">
          {record.date && convertUTCStringtoLocalString(record.date, DEFAULT_DATE_FORMAT)}
        </div>
      ),
      width: 100
    },
    {
      title: 'Quantity',
      key: 'quantity',
      sorter: (a, b) => parseFloat(a.quantity) - parseFloat(b.quantity),
      sortOrder: sortedInfo.columnKey === 'quantity' ? sortedInfo.order : null,
      render: (record) => {
        return <div className="text-right mr-2">{numberDecimalFormatter(record.quantity)}</div>;
      },
      width: 50
    },
    {
      title: 'Unit',
      key: 'unit',
      sorter: (a, b) => a.unit_name.localeCompare(b.unit_name),
      sortOrder: sortedInfo.columnKey === 'unit' ? sortedInfo.order : null,
      render: (record) => {
        return <div>{record.unit_name}</div>;
      },
      width: 40
    },
    {
      title: 'Unit Price',
      key: 'unit_price',
      dataIndex: 'unit_price',
      sorter: (a, b) => parseFloat(a.unit_price) - parseFloat(b.unit_price),
      sortOrder: sortedInfo.columnKey === 'unit_price' ? sortedInfo.order : null,
      render: (unit_price) => {
        return <div className="text-right mr-2">{nepaliNumberFormatter(unit_price)}</div>;
      },
      width: 50
    },
    {
      title: 'Total',
      key: 'total_amount',
      className: 'highlight',
      dataIndex: 'total_amount',
      sorter: (a, b) => parseFloat(a.total_amount) - parseFloat(b.total_amount),
      sortOrder: sortedInfo.columnKey === 'total_amount' ? sortedInfo.order : null,
      render: (total_amount) => {
        return <div className="text-right mr-2">{nepaliNumberFormatter(total_amount)}</div>;
      },
      width: 50
    }
  ];

  const updatedData = getUpdatedData(data.data);

  return (
    <div>
      <CustomModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} title={'Sell View'}>
        <ReusableSellView id={id} />
      </CustomModal>

      <GenericTable
        columns={columns}
        data={data.data}
        hideDefaultPagination={true}
        rowKey={'row_key'}
        toSort={handleChange}
        generateSummary
        excludeSummaryByKeys={['phone', 'sku']}
        scroll={{ x: 1750, y: '75vh' }}
        buttons={
          <>
            <ExportAllData
              title="Sell Report (All)"
              columns={tableExportColumns}
              excelColumns={excelExportColumns}
              getInfo={handlers.onSearchAll}
            />

            <ExportCurrent
              data={{ total: data.total, results: updatedData }}
              columns={tableExportColumns}
              excelColumns={excelExportColumns}
              title="Sell Report"
            />
          </>
        }
        footer={() =>
          `Total Amount: ${nepaliNumberFormatter(footer.totalAmount)}, Total Quantity: ${
            footer.totalQty
          }`
        }
        pagination={{
          page: pagination.page,
          total: data.total,
          size: pagination.size,
          onPagination: (pageNo, currentSize) => {
            currentSize == pagination.size
              ? handlers.onPagination(handlers.onSearch, pageNo)
              : handlers.onPagination(handlers.onSearch, pageNo, currentSize);
          }
        }}
      />
    </div>
  );
}

export default TableData;
