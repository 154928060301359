import { useState } from 'react';
import { Collapse, message, PageHeader, Spin } from 'antd';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';

import withReportPagination, {
  PaginatedReportValues,
  WithReportPaginationProps
} from '@/components/Common/Report/withReportPagination';
import { download_report, get_report } from '@/services/report/queries';
import { check_date_cap, check_export_report_date_cap } from '@/utils/common';
import { showReportConditionalDownloadMessage } from '@/utils/reportConditionalDownloadMessage';

import { getUpdatedData } from './column.export';
import AppContent from '@/components/Common/Content/Content';
import { IProductSell } from '@/services/report/types';

import FilterForm from './Form';
import TableData from './Table';
import getErrorMessage from '@/utils/getError';
import { getUnit } from '@/services';
import useCache from '@/hooks/useCache';
import { convertTotalQtyString } from '@/utils/convertTotalQtyString';

export type ExtendedInitialValues = PaginatedReportValues & {
  constraints: {
    customerId: string | number;
    categoryId: string;
    productIds?: string;
    locationId: string | number;
  };
};

function MultiProductReportBase({
  form,
  initialValues,
  pagination,
  ...handlers
}: WithReportPaginationProps) {
  const breadcrumbItems = [
    { label: 'Reports', link: '/reports' },
    { label: 'Multi Product Sell Report' }
  ];

  const [isEmpty, setIsEmpty] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [fullExportURL, setFullExportURL] = useState<unknown>(null);

  const [footer, setFooter] = useState({ totalAmount: 0, totalQty: '0' });
  const { get: getUnitDetails } = useCache(getUnit);

  const [data, setData] = useState({
    total: 0,
    data: [] as IProductSell[]
  });

  const state = { id: 83, name: 'Product Sell Report' };
  const extendedInitialValues = {
    ...initialValues,
    constraints: {
      ...initialValues.constraints,
      customerId: '',
      locationId: '',
      categoryId: '',
      productIds: undefined
    }
  };

  const calculateTotalDetail = async (response: IProductSell[]) => {
    let total = 0;
    const totalQty: Record<string, number> = {};

    for (const data of response) {
      total += parseFloat(data.total_amount);
      const unitInfo = await getUnitDetails(data.unit_id);

      totalQty[unitInfo.shortName] =
        (totalQty[unitInfo.shortName] || 0) + parseFloat(data.quantity);
    }

    const qtyString = convertTotalQtyString(totalQty);
    setFooter({ totalAmount: total, totalQty: qtyString });
  };

  async function onSearch(values: ExtendedInitialValues) {
    try {
      setIsLoading(true);
      const constraints = { ...values.constraints };
      const hasDateCap = check_date_cap(constraints.startDate, constraints.endDate);
      if (hasDateCap) {
        await onDownload();
        return;
      }

      const updatedConstraints = await handlers.getConstraint();
      const { data } = await get_report(updatedConstraints, state.id);

      const actualData = data.data as IProductSell[];

      // Generate unique id for each row
      for (const d of actualData) {
        const rowKey = `${d.sell_id}-${d.product_id}`;
        d.row_key = rowKey;
      }

      await calculateTotalDetail(actualData);

      // Get all constraints excluding page, size
      const payload = updatedConstraints;
      payload.size = data.count;

      setFullExportURL(payload);
      setData({ total: data.count, data: actualData });
      setIsEmpty(!data.length);
      return actualData;
    } catch (error) {
      getErrorMessage(error, true);
    } finally {
      setIsLoading(false);
    }
  }

  async function onSearchAll() {
    try {
      setIsLoading(true);
      if (!fullExportURL) {
        message.error('No URL specified. Please search first.');
        return;
      }
      const { data } = await get_report(fullExportURL, state.id);
      return getUpdatedData(data.data);
    } catch (error) {
      getErrorMessage(error, true);
    } finally {
      setIsLoading(false);
    }
  }

  async function onDownload() {
    try {
      setIsLoading(true);
      if (isEmpty) {
        showReportConditionalDownloadMessage();
        return;
      }

      await form.validateFields();
      const constraints = await handlers.getConstraint();
      if (!constraints) return;

      const hasDateCap = check_export_report_date_cap(constraints.startDate, constraints.endDate);

      if (!hasDateCap) return;
      message.info('Report is being generated. Download will start soon.');
      await download_report({ ...constraints, customReportQueueName: state.name }, state.id);
    } catch (error) {
      getErrorMessage(error, true);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Spin spinning={isLoading}>
      <AppContent breadcrumbItems={breadcrumbItems}>
        <Collapse>
          <CollapsePanel header="Filters" key="1">
            <FilterForm
              form={form}
              isLoading={isLoading}
              initialValues={extendedInitialValues}
              setIsEmpty={setIsEmpty}
              onSearch={onSearch}
              onDownload={onDownload}
              onChange={handlers.onChange}
              onDisabledDate={handlers.onDisabledDate}
              onChangeNepali={handlers.onChangeNepali}
            />
          </CollapsePanel>
        </Collapse>
        <PageHeader subTitle="Table" style={{ padding: '8px 0px' }} />
        <TableData
          data={data}
          footer={footer}
          pagination={pagination}
          onSearch={onSearch}
          onSearchAll={onSearchAll}
          onChange={handlers.onChange}
          onPagination={handlers.onPagination}
        />
      </AppContent>
    </Spin>
  );
}

const MultiProductSellReport = withReportPagination(MultiProductReportBase);
MultiProductSellReport.displayName = 'MultiProductSellReport';

export default MultiProductSellReport;
